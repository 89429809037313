import React, { FunctionComponent } from 'react';

import { Icon, Tooltip } from '@ryan/components';

import './InfoTooltip.scss';

interface IInfoTooltipProps {
  children: React.ReactNode;
}

const InfoTooltip: FunctionComponent<IInfoTooltipProps> = props => {
  const { children } = props;
  return (
    <Tooltip
      content={children}
      placement="top"
      renderTarget={({ open, ...props }) => (
        <button
          aria-expanded={open}
          aria-haspopup="true"
          className="info-tooltip"
          type="button"
          {...props}
        >
          <Icon name="information" />
        </button>
      )}
    />
  );
};

export default InfoTooltip;
