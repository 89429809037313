import { IUpcomingDataRequests } from 'interfaces';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

import { Card, DonutChart, Icon } from '@ryan/components';

import './DataRequestUpcomingSummaryCard.scss';

export interface IDataRequestUpcomingSummaryCardProps {
  loading?: boolean;
  values: IUpcomingDataRequests;
  t: TFunction;
}

// Assumes Permission.DataRequestsView
const DataRequestUpcomingSummaryCard: FunctionComponent<IDataRequestUpcomingSummaryCardProps> =
  ({ loading = false, values, t }) => (
    <Card
      className="dr-upcoming-summary-card"
      role="region"
      title={t('dataRequest.upcomingCard.title')}
    >
      {loading ? (
        <div className="center-icon">
          <Icon className="loading-spin" name="loading" />
        </div>
      ) : (
        <DonutChart
          data={[
            {
              value: values.overDue,
              color: '#CC2936',
              description: t('dataRequest.upcomingCard.overdue')
            },
            {
              value: values.dueTwoWeeks,
              color: '#EDAE49',
              description: t('dataRequest.upcomingCard.withinFourteenDays'),
              inverseLegendColor: true
            },
            {
              value: values.dueOneMonth,
              color: '#0A8287',
              description: t('dataRequest.upcomingCard.withinThirtyDays')
            }
          ]}
          dataDescription={t('dataRequest.requests')}
        />
      )}
    </Card>
  );

export default DataRequestUpcomingSummaryCard;
