import { IActivity } from 'interfaces';
import { formatDate } from 'utils/formatDate';

import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';

import './ActivityModified.scss';

type Case = {
  key: string;
  label: string;
  type: 'string' | 'date' | ((datum: any) => ReactNode);
  clamp?: boolean;
};

type EditedField = {
  newValue: ReactNode;
  oldValue: ReactNode;
  type: string;
};

interface IActivityModifiedProps {
  activity: IActivity;
  cases: Case[];
}

function getFormatter(_case: Case): (value: any) => ReactNode {
  if (typeof _case.type === 'function') {
    return _case.type;
  }

  switch (_case.type) {
    case 'string':
      return _case.clamp
        ? (value: string) =>
            value !== '""' ? <div className="line-clamp">{value}</div> : ''
        : (value: string) => (value !== '""' ? value : '');
    case 'date':
      return (value: string) => (value ? formatDate(value) : '');
  }
}

export const ActivityModified: FunctionComponent<
  IActivityModifiedProps
> = props => {
  const { activity, cases } = props;
  const { modifiedInformation } = activity.snapShot;
  const { t: getTextToDisplay } = useTranslation();
  function getEditedValue(prop: EditedField): ReactNode {
    switch (prop.type) {
      case 'Jurisdictions':
        return (
          <td>
            {getTextToDisplay(
              'activity.dataRequest.edited.jurisdictionsEdited'
            )}
          </td>
        );
      case 'DateRange':
        return (
          <td>
            {getTextToDisplay('activity.dataRequest.edited.dateRangesEdited')}
          </td>
        );
      default:
        return (
          <td>
            {prop.newValue}
            <del>{prop.oldValue}</del>
          </td>
        );
    }
  }

  if (
    modifiedInformation !== null &&
    modifiedInformation.length &&
    cases.some(c => modifiedInformation.some(m => m.key === c.key))
  ) {
    return (
      <div className="activity-modified">
        <table>
          <tbody>
            {modifiedInformation.map(modified => {
              const _case = cases.find(c => c.key === modified.key);
              if (_case) {
                const formatter = getFormatter(_case);
                if (_case.key === 'StatusWithSubStatus') {
                  const newStatusWithSubStatus = JSON.parse(modified.newValue);
                  const oldStatusWithSubStatus = JSON.parse(modified.oldValue);
                  const newTranslatedValue = `${
                    newStatusWithSubStatus.Status !== 0
                      ? getTextToDisplay(
                          `status.${newStatusWithSubStatus.Status}`
                        )
                      : ''
                  } ${
                    newStatusWithSubStatus.SubStatus !== 0
                      ? '- ' +
                        getTextToDisplay(
                          `dataRequest.subStatus.${
                            SubStatusEnums[newStatusWithSubStatus.SubStatus]
                          }`
                        )
                      : ''
                  }`;
                  const oldTranslatedValue = `${
                    oldStatusWithSubStatus.Status !== 0
                      ? getTextToDisplay(
                          `status.${oldStatusWithSubStatus.Status}`
                        )
                      : ''
                  } ${
                    oldStatusWithSubStatus.SubStatus !== 0
                      ? '- ' +
                        getTextToDisplay(
                          `dataRequest.subStatus.${
                            SubStatusEnums[oldStatusWithSubStatus.SubStatus]
                          }`
                        )
                      : ''
                  }`;

                  return (
                    <tr key={modified.key}>
                      <th>{_case.label}</th>
                      <td>
                        {newTranslatedValue}
                        <del>{oldTranslatedValue}</del>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={modified.key}>
                      <th>{_case.label}</th>
                      {getEditedValue({
                        newValue: formatter(modified.newValue),
                        oldValue: formatter(modified.oldValue),
                        type: modified.key
                      })}
                    </tr>
                  );
                }
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};
