import React, { FunctionComponent } from 'react';

import {
  BreadcrumbLink,
  Button,
  EButtonVariant,
  Breadcrumbs as RCBreadcrumbs
} from '@ryan/components';

import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import IBreadcrumb from './IBreadcrumb';

import './Breadcrumbs.scss';

const Breadcrumbs: FunctionComponent<{ crumbs: IBreadcrumb[] }> = ({
  crumbs
}) => {
  const { isUnsavedChanges, setBlockNavigation, setTargetUrl } =
    useUnsavedChanges();

  const handleNavigation = (url: string) => (event: React.MouseEvent) => {
    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(url);
      event.preventDefault();
    }
  };

  return (
    <RCBreadcrumbs>
      {crumbs.map((crumb: any) => (
        <div
          className="breadcrumb-link-wrapper"
          key={crumb.to}
          onClick={handleNavigation(crumb.to)}
        >
          {isUnsavedChanges ? (
            <Button
              className="ry-breadcrumb"
              role="link"
              variant={EButtonVariant.LINK}
            >
              {crumb.label}
            </Button>
          ) : (
            <BreadcrumbLink key={crumb.to} to={crumb.to}>
              {crumb.label}
            </BreadcrumbLink>
          )}
        </div>
      ))}
    </RCBreadcrumbs>
  );
};

export default Breadcrumbs;
