import image from 'images/403.svg';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './ErrorPage.scss';

const Error403: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="error-page error-page__403">
      <img alt="" src={image} />
      <h2>{t('errorPage.403.header')}</h2>
      <p>{t('errorPage.403.content')}</p>
    </div>
  );
};

export default Error403;
