import { IUserContext } from 'contexts/UserContext';
import { EntityType, Permission } from 'interfaces';

const ENTITY_TYPES_ACCOUNT = [
  EntityType.Milestone,
  EntityType.Project,
  EntityType.DataRequest,
  EntityType.File,
  EntityType.Contract,
  EntityType.Invoice,
  EntityType.SavingsSummary,
  EntityType.Task,
  EntityType.Learning,
  EntityType.Team,
  EntityType.AccessControl
];

const ENTITY_TYPES_DATA_AND_FILES = [
  EntityType.DataRequest,
  EntityType.File,
  EntityType.Contract,
  EntityType.Invoice
];

const ENTITY_TYPES_ENGAGEMENT = [
  EntityType.DataRequest,
  EntityType.Milestone,
  EntityType.File,
  EntityType.Contract,
  EntityType.Invoice,
  EntityType.SavingsSummary,
  EntityType.Task,
  EntityType.Learning,
  EntityType.Team,
  EntityType.Project,
  EntityType.AccessControl
];

const ENTITY_TYPES_MAP_PERMISSIONS: { [key: string]: Permission | true } = {
  [EntityType.DataRequest]: Permission.DataRequestsView,
  [EntityType.Milestone]: Permission.TimelinesView,
  [EntityType.File]: true,
  [EntityType.Contract]: Permission.ContractsView,
  [EntityType.SavingsSummary]: Permission.SavingsSummaryView,
  [EntityType.Invoice]: Permission.InvoicesView,
  [EntityType.Task]: Permission.TasksView,
  [EntityType.Learning]: Permission.LearningsView,
  [EntityType.Team]: true,
  [EntityType.AccessControl]: true,
  [EntityType.Project]: true
};

/**
 * Gets the applicable entityTypes for a context (page)
 * that the user has permission to see.
 */
export default function getEntityTypes(
  ps: IUserContext['permissionService'],
  forEngagements: boolean,
  forDataAndFiles: boolean
) {
  let entityTypes = ENTITY_TYPES_ACCOUNT;
  if (forEngagements) {
    entityTypes = ENTITY_TYPES_ENGAGEMENT;
  } else if (forDataAndFiles) {
    entityTypes = ENTITY_TYPES_DATA_AND_FILES;
  }

  return entityTypes.filter(entityType => {
    const mixed = ENTITY_TYPES_MAP_PERMISSIONS[entityType];
    return mixed === true || ps.hasPermission(mixed);
  });
}
