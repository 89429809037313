import { TTaskOverviewProps } from './TaskOverview.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, EButtonVariant } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../../../../hooks';
import { Permission } from '../../../../../../interfaces';
import { IconEnums } from '../../../../../../utils/enums/IconEnums';
import Attachments from '../../../../../FileAttachments/Attachments';
import { getCommentButtonTextPath } from '../../Worklist.utils';

import './TaskOverview.scss';

const TaskOverview = ({
  isDisabled,
  onOpenTaskCommentDrawer,
  taskDetails
}: TTaskOverviewProps) => {
  const ROOT_TO_TEXT = 'table.expandedRow.worklist.taskExpandedRow.overview';
  const {
    attachments,
    commentCount,
    description,
    engagementDisplayNameShort,
    engagementGuid,
    isEngagementReadOnly,
    title
  } = taskDetails;

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const { activeView, permissionService } = useUser();

  const hasComments = commentCount > 0;
  const isReadOnlyByPermissions = !permissionService.hasPermission(
    Permission.TasksContribute
  );
  const isReadOnlyByState = isAppReadOnly || isEngagementReadOnly;

  return (
    <div className="task-overview">
      <h2>{engagementDisplayNameShort}</h2>
      <h3>{title}</h3>

      {description && (
        <>
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.description`)}</h4>
          <p>{description}</p>
        </>
      )}

      {attachments.length > 0 && (
        <Attachments
          attachments={attachments}
          disableDownload={activeView.isExecutiveView}
          engagementGuid={engagementGuid}
        />
      )}
      <div className="task-overview__actions">
        <Button
          disabled={isDisabled}
          icon={hasComments ? IconEnums.COMMENT_ALERT : IconEnums.COMMENT}
          onClick={onOpenTaskCommentDrawer}
          size={EButtonSizes.SMALL}
          variant={EButtonVariant.TEXT}
        >
          {getTextToDisplay(
            `${ROOT_TO_TEXT}.${getCommentButtonTextPath(
              isReadOnlyByState || isReadOnlyByPermissions,
              hasComments
            )}`,
            { count: commentCount }
          )}
        </Button>
      </div>
    </div>
  );
};

export default TaskOverview;
