import { ISummaryPerCurrency } from 'interfaces';
import i18n from 'utils/i18n';

export function mapLanguageToCurrency(lang: string) {
  switch (lang) {
    case 'en':
    case 'en-US':
      return 'USD';
    case 'en-gb':
      return 'GBR';
    case 'en-CA':
    case 'fr-CA':
      return 'CAD';
    case 'de':
    case 'fr':
    case 'nl':
    case 'pt':
      return 'EUR';
    case 'hu':
      return 'HUF';
    default:
      return undefined;
  }
}

/**
 * Reorders currency list for display.
 */
export function orderCurrenciesForDisplay(
  currencies: ISummaryPerCurrency[],
  language: string
): ISummaryPerCurrency[] {
  // place language based currency first
  const languageCurrencyCode = mapLanguageToCurrency(language);
  const reorderedCurrencies = [...currencies];

  if (languageCurrencyCode) {
    const languageCurrencyIndex = reorderedCurrencies.findIndex(
      currency => currency.currencyCode === languageCurrencyCode
    );

    if (languageCurrencyIndex > -1) {
      const languageCurrency = reorderedCurrencies.splice(
        languageCurrencyIndex,
        1
      );
      reorderedCurrencies.unshift(languageCurrency[0]);
    }
  }

  return reorderedCurrencies;
}

function ifIncluded(
  summaries: ISummaryPerCurrency[],
  currencyCode?: string | null
) {
  return currencyCode && summaries.some(s => s.currencyCode === currencyCode)
    ? currencyCode
    : null;
}

export default function getDefaultCurrency(
  summaries?: ISummaryPerCurrency[] | null,
  activeCurrency?: string | null
) {
  // If no summaries, nothing to select.
  if (!summaries) {
    return null;
  }

  // If activeCurrency exists in summaries, don't change it.
  // If language's currency exists in summaries, use it.
  // Otherwise, return first in list.
  return (
    ifIncluded(summaries, activeCurrency) ||
    ifIncluded(summaries, mapLanguageToCurrency(i18n.language)) ||
    summaries[0].currencyCode
  );
}
