import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import classnames from 'classnames';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, Card, EButtonSizes, EButtonVariant } from '@ryan/components';

import ResetPasswordModal from './ResetPasswordModal';

import './SecurityCard.scss';

interface ISecurityCardState {
  loading: boolean;
  showDaysUntilExpired: boolean;
  passwordExpirationInDays: number;
  openResetPasswordModal: boolean;
}

type ISecurityCardProps = WithTranslation;

class SecurityCard extends Component<ISecurityCardProps, ISecurityCardState> {
  readonly state: ISecurityCardState = {
    loading: false,
    showDaysUntilExpired: false,
    passwordExpirationInDays: 0,
    openResetPasswordModal: false
  };

  fetchSecuritySettings() {
    this.setState({ loading: true });

    try {
      ApiService.getPasswordExpirationDays().then(response => {
        this.setState({
          showDaysUntilExpired: true,
          passwordExpirationInDays: response.data
        });
      });
    } catch {
      pushServerErrorToast();
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.fetchSecuritySettings();
  }

  render() {
    const { showDaysUntilExpired, passwordExpirationInDays } = this.state;
    const { t } = this.props;

    return (
      <Card className="security-card" title={t('Security')}>
        <div className="security-card__reset-password">
          {showDaysUntilExpired && passwordExpirationInDays <= 14 && (
            <div
              className={classnames('security-card__expiration-days', {
                'security-card__expiration-days--expiring':
                  passwordExpirationInDays <= 3
              })}
            >
              <Trans i18nKey="security.passwordExpires">
                <b />
                {{
                  passwordExpirationInDays,
                  unit: passwordExpirationInDays === 1 ? 'day' : 'days'
                }}
              </Trans>
            </div>
          )}
          <div className="security-card__reset-password-button">
            <Button
              icon="lock"
              onClick={() => this.setState({ openResetPasswordModal: true })}
              size={EButtonSizes.LARGE}
              text={t('Reset Password')}
              variant={EButtonVariant.TEXT}
            />
          </div>
        </div>
        <ResetPasswordModal
          onClose={() => this.setState({ openResetPasswordModal: false })}
          open={this.state.openResetPasswordModal}
        />
      </Card>
    );
  }
}

export default withTranslation()(SecurityCard);
