import {
  IRenderContent,
  IRenderTarget,
  TAccountHierarchyProps
} from './AccountHierarchy.interfaces';
import { useUser } from 'hooks';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Tooltip } from '@ryan/components';

import './AccountHierarchy.scss';

const AccountHierarchy: React.FC<TAccountHierarchyProps> = ({ hierarchy }) => {
  const ROOT_TO_TEXT = 'accountHierarchy';
  const { permissionService } = useUser();
  const { t: getTextToDisplay } = useTranslation();

  const isRyanUserView = permissionService.isRyan();

  const renderContent: IRenderContent = ({
    contentHierarchy,
    contentLabel
  }) => (
    <>
      {contentLabel && <label>{contentLabel}</label>}
      <div className="account-hierarchy__accounts">
        {contentHierarchy.map((account, index) => (
          <div
            className="account-hierarchy__accounts__account"
            key={account.accountGuid}
          >
            {index !== 0 && <Icon name="arrow-nested" />}
            {account.name}
          </div>
        ))}
      </div>
    </>
  );

  const renderTarget: IRenderTarget = (
    toolTipRenderTargetProps,
    stackCount
  ) => (
    <div
      className="account-hierarchy-tooltip-target"
      {...toolTipRenderTargetProps}
    >
      <Button variant="text">
        <Icon name={`stacks-${stackCount}`} />
      </Button>
    </div>
  );

  return (
    <Tooltip
      className="account-hierarchy"
      content={renderContent({
        ...(isRyanUserView && {
          contentLabel: getTextToDisplay(`${ROOT_TO_TEXT}.accountHierarchy`)
        }),
        contentHierarchy: hierarchy
      })}
      placement="top"
      renderTarget={props => renderTarget(props, Math.min(hierarchy.length, 9))}
    />
  );
};

export default AccountHierarchy;
