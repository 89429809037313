import {
  ICustomView,
  ICustomViewCreateType,
  ICustomViewSummary
} from 'interfaces';

const KEY = 'Switcher';

interface RecentViewsCache {
  userGuid: string;
  recentViews: ICustomViewSummary[];
}

export function loadRecentViews(
  userGuid: string,
  views: ICustomViewSummary[]
): ICustomViewSummary[] {
  // Check for cached values
  const json = localStorage.getItem(KEY);

  if (json) {
    try {
      // Parse
      const cache: RecentViewsCache = JSON.parse(json);

      // Check that cache is for this user
      if (cache.userGuid === userGuid) {
        // return views that are still valid and accessible
        return cache.recentViews.reduce<ICustomViewSummary[]>(
          (recentViews, cachedView) => {
            const validView = views.find(
              freshView =>
                cachedView.customViewGuid === freshView.customViewGuid
            );
            return validView ? [...recentViews, validView] : recentViews;
          },
          []
        );
      }

      clearCache();
    } catch {
      // swallow
    }
  }

  return [];
}

export function storeRecentViews(
  userGuid: string,
  recentViews: ICustomViewSummary[]
) {
  // Remove duplicates
  const seen: { [key: string]: true } = {};
  recentViews = recentViews.filter(view => {
    const keys = [view.customViewGuid];

    if (view.accountView !== null) {
      const { account, includeSubsidiaries } = view.accountView;
      keys.push(`${account.accountGuid}:${includeSubsidiaries}`);
    }

    if (keys.some(k => seen[k])) {
      return false;
    } else {
      keys.forEach(k => {
        seen[k] = true;
      });
      return true;
    }
  });

  // Don't show one-time-use
  recentViews = recentViews.filter(
    v => v.createType !== ICustomViewCreateType.CustomOneTimeUse
  );

  // Limit 5
  recentViews = recentViews.slice(0, 5);

  // Remove filters and engagements fields if passed an ICustomView object
  // instead of ICustomViewSummary. Filters and engagements attached to large
  // views will exceed localStorage data size quota.
  recentViews = recentViews.map(view => {
    const { filters, engagements, ...updatedView } = view as ICustomView;
    return updatedView as ICustomViewSummary;
  });

  const cache: RecentViewsCache = { userGuid, recentViews };

  localStorage.setItem(KEY, JSON.stringify(cache));

  return recentViews;
}

export function clearCache() {
  localStorage.removeItem(KEY);
}
