import React, { FunctionComponent } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import { TabManager as RCTabManager, Tabs as RCTabs } from '@ryan/components';

import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { Tab, getTabKey } from './Tab';

interface Props {
  tabs: Tab[];
  onTabChangeHandler?: (key: string) => void;
}

export const Tabs: FunctionComponent<Props> = ({
  onTabChangeHandler,
  tabs
}) => {
  const history = useHistory();
  const enabledTabs = tabs.filter(tab => tab.enabled !== false);
  const { isUnsavedChanges, setBlockNavigation, setTargetUrl } =
    useUnsavedChanges();

  if (enabledTabs.length <= 1) {
    return null;
  }

  const labels = enabledTabs.map(tab => ({
    tabKey: getTabKey(tab),
    text: tab.label
  }));

  const activeTab = tabs.find(tab => {
    const paths = Array.isArray(tab.path) ? tab.path : [tab.path];
    return paths.some(
      path => matchPath(history.location.pathname, path) !== null
    );
  });

  const activeTabKey = activeTab ? getTabKey(activeTab) : '';

  const handleTabChange = (url: string) => {
    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(url);
    } else {
      history.push(url);
    }
  };

  return (
    <RCTabManager
      activeTabKey={activeTabKey}
      onTabChange={key => {
        if (!!onTabChangeHandler) {
          onTabChangeHandler(key as string);
        }
        handleTabChange(key as string);
      }}
    >
      <RCTabs tabs={labels} />
      <hr />
    </RCTabManager>
  );
};

export default Tabs;
