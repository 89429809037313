import React, { Component, createRef } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  MentionsValue,
  pushToast
} from '@ryan/components';

import { makeOnSuggestionsRequested } from '../../components/Comments/CommentInput/CommentInput';
import Modal from '../../components/Modal';
import { IDataRequestV2, Status } from '../../interfaces';
import ApiService from '../../services/ApiService';
import CommentInput from '../Comments/CommentInput';

interface IDataRequestCompleteModalProps extends WithTranslation {
  dataRequest: IDataRequestV2;
  onClose: (updatedDataRequest?: IDataRequestV2) => void;
}

interface IDataRequestCompleteModalState {
  comment: MentionsValue;
  isValid: boolean;
}

const maxLengthComment = 250;

export class DataRequestCompleteModal extends Component<
  IDataRequestCompleteModalProps,
  IDataRequestCompleteModalState
> {
  private modalContentRef = createRef<HTMLDivElement>();

  readonly state = {
    comment: new MentionsValue(),
    isValid: true
  };

  handleChange = (comment: MentionsValue) => {
    const isValid = comment.length <= maxLengthComment;
    this.setState({ comment, isValid });
  };

  handleCancel = () => {
    this.setState({ comment: new MentionsValue() });
    this.props.onClose();
  };

  handleComplete = async () => {
    const { dataRequest, onClose, t } = this.props;
    const { comment, isValid } = this.state;

    if (!isValid) return;

    try {
      const { data: updatedDataRequest } =
        await ApiService.updatePartialDataRequest({
          queueItemGuid: dataRequest.queueItemGuid,
          subStatus: null,
          status: Status.Complete
        });

      comment.length &&
        (await ApiService.createDataRequestV2Comment(
          dataRequest.engagementGuid,
          dataRequest.queueItemGuid,
          comment.toJSON().text
        ));

      const { title } = updatedDataRequest;
      pushToast({
        type: 'success',
        title: t('dataRequest.completeModal.success.title'),
        content: (
          <Trans i18nKey="dataRequest.completeModal.success.content">
            <b>{{ title }}</b> has been marked as complete.
          </Trans>
        )
      });

      this.setState({ comment: new MentionsValue() });
      onClose(updatedDataRequest as IDataRequestV2);
    } catch (error) {
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('serverError.content')
      });
      onClose();
    }
  };

  render() {
    const { t, dataRequest } = this.props;
    const { comment, isValid } = this.state;
    const validationProps = {
      feedback: t('dataRequest.completeModal.comment.maxLength', {
        length: maxLengthComment
      }),
      invalid: !isValid
    };

    return (
      <Modal
        onClose={this.handleCancel}
        open
        title={t('dataRequest.completeModal.title')}
      >
        <div ref={this.modalContentRef}>
          <CommentInput
            {...(!isValid ? validationProps : {})}
            boundingParentRef={this.modalContentRef}
            label={t('dataRequest.completeModal.comment.label')}
            makeOnSuggestionsRequestedCallback={() =>
              makeOnSuggestionsRequested(
                dataRequest.engagementGuid,
                user => user.canBeMentionedInDRComment
              )
            }
            onChange={this.handleChange}
            value={comment}
          />
          <ButtonGroup>
            <Button
              disabled={!isValid}
              onClick={this.handleComplete}
              text={t('dataRequest.completeModal.submit')}
              variant={EButtonVariant.PRIMARY}
            />
            <Button
              onClick={this.handleCancel}
              text={t('Cancel')}
              variant={EButtonVariant.SECONDARY}
            />
          </ButtonGroup>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(DataRequestCompleteModal);
