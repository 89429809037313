import { FormikProps } from 'formik';
import { ReportSectionStatus } from 'interfaces';

export function getPanelStatus(
  keys: string[],
  formik: FormikProps<any>
): ReportSectionStatus {
  if (keys.some(key => key in formik.touched && key in formik.errors)) {
    return ReportSectionStatus.Error;
  }

  if (keys.every(key => key in formik.touched)) {
    return ReportSectionStatus.Complete;
  }

  return ReportSectionStatus.Normal;
}

export function panelHasNoErrors(
  keys: string[],
  formik: FormikProps<any>
): boolean {
  keys.forEach(key => {
    formik.setFieldTouched(key, true, true);
  });

  if (keys.every(key => !(key in formik.errors))) {
    return true;
  }

  return false;
}
