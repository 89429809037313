import { IFormatToOverflowMenu } from '../IOverflowMenu';

export const formatToOverflowMenu: IFormatToOverflowMenu = user => {
  const {
    canCurrentUserEdit: isActiveUserWithEditAccess = false,
    canCurrentUserApproveNurConversion:
      isActiveUserWithNewUserApprovalAccess = false,
    isAssociationEditable: isUserAssociationEditable = false,
    isUserBigFour = false,
    isGhosted: isUserGhosted = true
  } = user;

  return {
    isActiveUserWithEditAccess,
    isActiveUserWithNewUserApprovalAccess,
    isUserAssociationEditable,
    isUserBigFour,
    isUserGhosted,
    userActivateDate: user.activateDate,
    userDeactivateDate: user.deactivateDate,
    userGuid: user.userGuid,
    userMemberGuid: user.memberGuid,
    userTypeId: user.userTypeId
  };
};
