import { Comments, NewComment } from 'components/Comments';
import Drawer from 'components/Drawer';
import { IComment, IMilestone } from 'interfaces';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

import { MentionsValue } from '@ryan/components';

import './MilestoneDrawer.scss';

interface IMilestoneDrawerProps {
  t: TFunction;
  milestone: IMilestone | null;

  comments: IComment[] | null;
  commentsLoading: boolean;

  canComment: boolean;

  onClose: () => void;

  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;
  onEditComment: (comment: IComment) => void;
  onEditCommentChange: (commentText: MentionsValue) => void;
  onEditCommentSave: () => void;
  onEditCommentCancel: () => void;

  onDeleteComment: (deletecomment: IComment) => void;

  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
  onNewCommentChange: (commentText: MentionsValue) => void;
  onNewCommentSave: () => void;
}

export const MilestoneDrawer: FunctionComponent<IMilestoneDrawerProps> =
  props => {
    const {
      t,
      milestone,
      comments,
      commentsLoading,
      canComment,
      onClose,
      editComment,
      editCommentText,
      editCommentLoading,
      onEditComment,
      onEditCommentChange,
      onEditCommentSave,
      onEditCommentCancel,
      onDeleteComment,
      newCommentText,
      newCommentLoading,
      onNewCommentChange,
      onNewCommentSave
    } = props;

    if (milestone !== null) {
      return (
        <Drawer className="milestone-drawer" onClose={onClose} open>
          <Drawer.Header>
            <h4 className="ry-h4">{milestone.engagementDisplayNameShort}</h4>
            <h2 className="ry-h2">{milestone.title}</h2>
          </Drawer.Header>
          <Drawer.Body>
            <Comments
              canComment={canComment}
              comments={comments}
              editComment={editComment}
              editCommentLoading={editCommentLoading}
              editCommentText={editCommentText}
              engagementGuid={milestone.engagementGuid}
              loading={commentsLoading}
              onDelete={onDeleteComment}
              onEdit={onEditComment}
              onEditCancel={onEditCommentCancel}
              onEditChange={onEditCommentChange}
              onEditSave={onEditCommentSave}
            />
          </Drawer.Body>
          {canComment && (
            <Drawer.Footer>
              <NewComment
                commentText={newCommentText}
                engagementGuid={milestone.engagementGuid}
                loading={newCommentLoading}
                onChange={onNewCommentChange}
                onSave={onNewCommentSave}
                t={t}
              />
            </Drawer.Footer>
          )}
        </Drawer>
      );
    }
    return null;
  };

export default MilestoneDrawer;
