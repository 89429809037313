import { INotification } from 'interfaces';

/**
 * `Array.prototype.sort` compare function for sorting notifications by
 * `activitySummary.createDate`.
 */
const notificationCreateDateCompare = (a: INotification, b: INotification) =>
  a.activitySummary.createDate < b.activitySummary.createDate
    ? 1
    : a.activitySummary.createDate > b.activitySummary.createDate
    ? -1
    : 0;

/**
 * Sorts a set of notifications. Currently sorted by
 * `activitySummary.createDate` in descending order.
 */
export default function sortNotifications(
  notifications: INotification[] = []
): INotification[] {
  return [...notifications].sort(notificationCreateDateCompare);
}
