import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant } from '@ryan/components';

import {
  IPropertyTaxDocumentType,
  IPropertyTaxSiteClass
} from '../../../../../../../interfaces/IPropertyTaxSiteClass';
import {
  DataRequestDocumentTypeEnum,
  DataRequestTypeGuidEnum
} from '../../../../../../../utils/enums/DataRequestsEnums';
import RecordsModal from '../../../../../../RecordsModal/RecordsModal';
import {
  DataRequestSelectionTypes,
  IDataRequestDescriptionDetailsProps,
  IRenderSelections
} from './DataRequestOverviewDetailsByType.interfaces';

import './DataRequestOverviewDetailsByType.scss';

const DataRequestOverviewDetailsByType = ({
  dataRequestDetailsByType
}: IDataRequestDescriptionDetailsProps) => {
  const ROOT_TO_TEXT =
    'table.expandedRow.worklist.dataRequestExpandedRow.overview.detailsByType';

  const {
    AUDIT_WORKPAPERS,
    DOCUMENT_IMAGES,
    ERP_DATA,
    SITE_INFORMATION,
    TAX_RETURNS,
    OTHER
  } = DataRequestTypeGuidEnum;
  const {
    dateRanges,
    documentTypes,
    engagementDisplayNameLong,
    erpSystem,
    jurisdictions,
    otherDocumentType,
    otherErpSystem,
    otherTaxEngine,
    propertyTaxDocumentTypes,
    propertyTaxSiteClasses,
    taxEngine,
    title,
    typeGuid
  } = dataRequestDetailsByType;

  const { t: getTextToDisplay } = useTranslation();

  const [recordsModalData, setRecordsModalData] = useState<{
    data: string[];
    modalTitle: string;
  } | null>(null);

  const isShowDateRanges = dateRanges.length > 0;
  const isShowDocumentImages = typeGuid === DOCUMENT_IMAGES;
  const isShowErpData = typeGuid === ERP_DATA;
  const isShowJurisdictions =
    [AUDIT_WORKPAPERS, TAX_RETURNS].includes(
      typeGuid as DataRequestTypeGuidEnum
    ) ||
    (typeGuid === OTHER && jurisdictions.length > 0) ||
    (typeGuid === SITE_INFORMATION && jurisdictions.length > 0);

  const isShowSiteInfo = typeGuid === SITE_INFORMATION;

  const renderSelections: IRenderSelections = ({
    modalTitle,
    selections,
    setRecordsModalDataCallback,
    viewSelections
  }) => {
    return selections.length < 3 ? (
      selections.map(selection => <span key={selection}>{selection}</span>)
    ) : (
      <Button
        onClick={() =>
          setRecordsModalDataCallback({ data: selections, modalTitle })
        }
        variant={EButtonVariant.LINK}
      >
        {viewSelections}
      </Button>
    );
  };

  /**
   *
   * @classDesc Map data to translation (WB Release 2024.5.3 (Jan 13, 2025): this will be needed once we can translate
   * items that can be created by users in the Admin Screen. For now it is only translating Jurisdictions)
   */
  const mapToTranslation = (
    data: string[] | IPropertyTaxSiteClass[] | IPropertyTaxDocumentType[],
    dataType: DataRequestSelectionTypes
  ) => {
    switch (dataType) {
      case DataRequestSelectionTypes.DocumentTypes:
        return data.map(documentTypeName =>
          documentTypeName === 'Other'
            ? (otherDocumentType as string)
            : documentTypeName
        );
      case DataRequestSelectionTypes.Jurisdictions:
        return data.map(jurisdictionGuid =>
          getTextToDisplay(`jurisdictions.${jurisdictionGuid}.name`)
        );
      case DataRequestSelectionTypes.PropertyTaxSiteClasses:
        const siteClasses = data as IPropertyTaxSiteClass[];
        return siteClasses.map(siteClass => siteClass.siteClassName);
      case DataRequestSelectionTypes.PropertyTaxDocumentTypes:
        const documentTypes = data as IPropertyTaxDocumentType[];
        return documentTypes.map(documentType => documentType.documentTypeName);
      default:
        return data;
    }
  };

  return (
    <div
      className={classNames('data-request-overview-details-by-type', {
        'data-request-overview-details-by-type--not-empty':
          isShowDateRanges ||
          isShowDocumentImages ||
          isShowErpData ||
          isShowJurisdictions
      })}
    >
      {isShowDocumentImages && (
        <div className="data-request-overview-details-by-type__document-images">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.documentTypes`)}</h4>
          {renderSelections({
            modalTitle: 'documentTypes',
            selections: mapToTranslation(
              documentTypes,
              DataRequestSelectionTypes.DocumentTypes
            ) as string[],
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}

      {isShowErpData && (
        <div className="data-request-overview-details-by-type__epr-data">
          <div>
            <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.erpSystem`)}</h4>
            <span>
              {otherErpSystem ||
                (erpSystem.name &&
                  getTextToDisplay(
                    `erpSystems.${erpSystem.erpSystemGuid}.name`
                  )) ||
                '-'}
            </span>
          </div>
          <div>
            <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.taxEngine`)}</h4>
            <span>{otherTaxEngine || taxEngine?.name || '-'}</span>
          </div>
        </div>
      )}

      {isShowSiteInfo && propertyTaxSiteClasses && (
        <div className="data-request-overview-details-by-type__site-classes">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.propertyTaxSiteClasses`)}</h4>
          {renderSelections({
            modalTitle: 'propertyTaxSiteClasses',
            selections: mapToTranslation(
              propertyTaxSiteClasses,
              DataRequestSelectionTypes.PropertyTaxSiteClasses
            ) as string[],
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}
      {isShowSiteInfo && propertyTaxDocumentTypes && (
        <div className="data-request-overview-details-by-type__propertyTaxDocumentTypes">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.documentTypes`)}</h4>
          {renderSelections({
            modalTitle: 'documentTypes',
            selections: mapToTranslation(
              propertyTaxDocumentTypes,
              DataRequestSelectionTypes.PropertyTaxDocumentTypes
            ) as string[],
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}

      {isShowDateRanges && (
        <div className="data-request-overview-details-by-type__date-ranges">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.dateRanges`)}</h4>
          {renderSelections({
            modalTitle: 'dateRanges',
            selections: dateRanges,
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}

      {isShowJurisdictions && (
        <div className="data-request-overview-details-by-type__jurisdictions">
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.jurisdictions`)}</h4>
          {renderSelections({
            modalTitle: 'jurisdictions',
            selections: mapToTranslation(
              jurisdictions,
              DataRequestSelectionTypes.Jurisdictions
            ) as string[],
            setRecordsModalDataCallback: setRecordsModalData,
            get viewSelections() {
              return getTextToDisplay(`${ROOT_TO_TEXT}.viewSelections`, {
                count: this.selections.length
              });
            }
          })}
        </div>
      )}

      {recordsModalData && (
        <RecordsModal
          data={recordsModalData.data}
          dataRequestTitle={title}
          engagementDisplayName={engagementDisplayNameLong}
          modalTitle={getTextToDisplay(
            `dataRequest.recordsModal.${recordsModalData.modalTitle}`
          )}
          onClose={() => {
            setRecordsModalData(null);
          }}
        />
      )}
    </div>
  );
};

export default DataRequestOverviewDetailsByType;
