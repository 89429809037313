import { ICustomViewSummary } from 'interfaces';

interface Props {
  activeView: ICustomViewSummary;
}

export default function switcherDidUpdate(prevProps: Props, props: Props) {
  const prevCustomViewGuid = prevProps.activeView.customViewGuid;
  const { customViewGuid } = props.activeView;
  return prevCustomViewGuid !== customViewGuid;
}
