import * as WorklistMineInterfaces from '../../../routes/Worklist/WorklistMine/WorklistMine.interfaces';
import * as DataRequestModalsInterfaces from './DataRequestModals.interfaces';

export const mapAssignmentToAssignDataUploadRequest: DataRequestModalsInterfaces.IMapAssignmentToVariousDataRequestModals =
  ({
    attachments,
    engagementDisplayNameShort,
    engagementGuid,
    queueItemGuid,
    title
  }) => ({
    attachments,
    engagementDisplayNameShort,
    engagementGuid,
    queueItemGuid,
    title
  });

export const mapAssignmentToCompleteDataRequest: DataRequestModalsInterfaces.IMapAssignmentToVariousDataRequestModals =
  ({ engagementGuid, queueItemGuid }) => ({ engagementGuid, queueItemGuid });

export const mapAssignmentToDeleteDataRequest: DataRequestModalsInterfaces.IMapAssignmentToVariousDataRequestModals =
  ({ engagementGuid, queueItemGuid, title }) => ({
    engagementGuid,
    queueItemGuid,
    title
  });

export const mapAssignmentToEditDataRequest: DataRequestModalsInterfaces.IMapAssignmentToVariousDataRequestModals =
  ({
    accountName,
    assignedTo,
    attachments,
    description,
    dueDate,
    engagementDisplayNameShort,
    engagementGuid,
    queueItemGuid,
    title,
    ...rest
  }) => {
    const {
      dateRanges,
      dataSpecs,
      defaultFolderGuid,
      documentTypes,
      engagementDisplayNameLong,
      erpSystem,
      jurisdictions,
      otherDocumentType,
      otherErpSystem,
      otherTaxEngine,
      taxEngine,
      typeGuid
    } = rest as WorklistMineInterfaces.TWorklistDataRequestItem;

    // TODO: Table Refactor: Workaround during refactor WIP. Still referencing IWorklist which is old
    const { dataType, subTypeName } = rest as any;

    return {
      accountName,
      assignedTo: {
        fullName: assignedTo.name,
        memberGuid: assignedTo.userGuid
      },
      attachments,
      dataRequestTypeGuid: typeGuid,
      dataRequestTypeName: dataType || subTypeName, // TODO: Table Refactor: Pending remaining refactor on worklist
      dataSpecs,
      dateRange:
        dateRanges && dateRanges.length > 0
          ? dateRanges.map(dateRange => ({
              dateEnd: new Date(dateRange.dateEnd),
              dateStart: new Date(dateRange.dateStart)
            }))
          : null, // TODO: Table Refactor: Modal should be able to handle empty list
      defaultFolderGuid,
      description,
      documentTypes,
      dueDate,
      engagementDisplayNameLong,
      engagementDisplayNameShort,
      engagementGuid,
      erpSystem,
      jurisdictions,
      queueItemGuid,
      otherDocumentType,
      otherErpSystem,
      otherTaxEngine,
      taxEngine: taxEngine,
      title
    };
  };

export const mapAssignmentToReopenDataRequest: DataRequestModalsInterfaces.IMapAssignmentToVariousDataRequestModals =
  ({ engagementGuid, queueItemGuid }) => ({ engagementGuid, queueItemGuid });
