import { CommentDeleteModal } from 'components/Comments';
import { EventEmitter } from 'events';
import { IComment, IMilestone, Permission } from 'interfaces';
import ApiService from 'services/ApiService';
import history from 'services/history';
import getMentionValue from 'utils/getMentionValue';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { MentionsValue } from '@ryan/components';

import { WithUser, withUser } from '../UserContext';
import MilestoneDrawer from './MilestoneDrawer';
import { MilestoneDrawerContext } from './MilestoneDrawerContext';

const EMPTY_COMMENT = new MentionsValue();

interface IMilestoneDrawerProviderProps extends WithTranslation, WithUser {
  children: React.ReactNode;
}

interface IMilestoneDrawerProviderState {
  milestone: IMilestone | null;
  comments: IComment[] | null;
  commentsLoading: boolean;
  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;
  deleteComment: IComment | null;
  deleteCommentLoading: Promise<any> | null;
  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
}

export class MilestoneDrawerProvider extends Component<
  IMilestoneDrawerProviderProps,
  IMilestoneDrawerProviderState
> {
  private events = new EventEmitter();

  readonly state: IMilestoneDrawerProviderState = {
    milestone: null,
    comments: null,
    commentsLoading: false,
    editComment: null,
    editCommentText: EMPTY_COMMENT,
    editCommentLoading: null,
    deleteComment: null,
    deleteCommentLoading: null,
    newCommentText: EMPTY_COMMENT,
    newCommentLoading: null
  };

  get canComment() {
    const { isAppReadOnly, permissionService: ps } = this.props;
    const { milestone } = this.state;
    return (
      milestone !== null &&
      !milestone.isEngagementReadOnly &&
      !isAppReadOnly &&
      ps.hasPermission(Permission.TimelinesContribute)
    );
  }

  handleOpen = async (milestone: IMilestone) => {
    this.setState({
      milestone,
      commentsLoading: true
    });

    try {
      const response = await ApiService.getMilestoneComments(
        milestone.engagementGuid,
        milestone.engagementMilestoneGuid
      );
      this.setState({ comments: response.data });
    } catch {
      pushServerErrorToast();
    }

    this.setState({ commentsLoading: false });
  };

  handleClose = () => {
    const { editCommentLoading, newCommentLoading, deleteComment } = this.state;
    if (!editCommentLoading && !newCommentLoading && !deleteComment) {
      history.replace({
        ...history.location,
        hash: ''
      });

      this.setState({
        milestone: null,
        comments: null,
        editComment: null,
        editCommentText: EMPTY_COMMENT,
        newCommentText: EMPTY_COMMENT,
        newCommentLoading: null
      });
    }
  };

  /**
   * Create a new comment.
   */

  handleNewCommentChange = (newCommentText: MentionsValue) => {
    this.setState({ newCommentText });
  };

  handleNewCommentSave = async () => {
    const { milestone, newCommentText } = this.state;
    const { text } = newCommentText.toJSON();

    if (milestone && text.length > 0) {
      const newCommentLoading = ApiService.createMilestoneComment(
        milestone.engagementGuid,
        milestone.engagementMilestoneGuid,
        text
      );

      this.setState({ newCommentLoading });

      try {
        const response = await newCommentLoading;

        // TODO: Remove this check once BE fix is found. Sometimes the response from the API returns as a 204 with no content.
        // When this happens, the page will break as the date formatter cannot handle undefined dates. If there is an empty "successful"
        // response, we will refetch the comments from the backend to prevent this error and maintain full functionality for the user.
        if (
          !response.data ||
          (typeof response.data === 'object' &&
            Object.keys(response.data).length === 0)
        ) {
          const response = await ApiService.getMilestoneComments(
            milestone.engagementGuid,
            milestone.engagementMilestoneGuid
          );

          this.setState({
            comments: response.data,
            newCommentText: EMPTY_COMMENT
          });
        } else {
          this.setState(({ comments }) => ({
            comments: comments && comments.concat([response.data]),
            newCommentText: EMPTY_COMMENT
          }));
        }

        this.events.emit('commentAdded', {
          engagementGuid: milestone.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }

      this.setState({ newCommentLoading: null });
    }
  };

  /**
   * Edit a comment.
   */

  handleEditComment = (editComment: IComment) => {
    if (this.canComment) {
      this.setState({
        editComment,
        editCommentText: getMentionValue(editComment)
      });
    }
  };

  handleEditCommentChange = (editCommentText: MentionsValue) => {
    if (this.canComment) {
      this.setState({ editCommentText });
    }
  };

  handleEditCommentSave = async () => {
    const { editComment, editCommentText, milestone } = this.state;
    const { text } = editCommentText.toJSON();

    if (this.canComment && editComment) {
      // if empty string, delete instead of edit
      if (text.length === 0) {
        this.handleDeleteCommentRequest(editComment);
        return;
      }

      const editCommentLoading = ApiService.updateMilestoneComment(
        milestone!.engagementGuid,
        milestone!.engagementMilestoneGuid,
        editComment.commentGuid,
        text
      );

      this.setState({ editCommentLoading });

      try {
        const { data: updatedComment } = await editCommentLoading;
        this.setState(({ comments }) => ({
          comments:
            comments &&
            comments.map(c =>
              c.commentGuid === updatedComment.commentGuid ? updatedComment : c
            ),
          editComment: null,
          editCommentText: EMPTY_COMMENT
        }));
        this.events.emit('commentEdited', {
          engagementGuid: milestone?.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }

      this.setState({ editCommentLoading: null });
    }
  };

  handleEditCommentCancel = () => {
    this.setState({ editComment: null, editCommentText: EMPTY_COMMENT });
  };

  /**
   * Delete a comment.
   */

  handleDeleteCommentRequest = (deleteComment: IComment) => {
    if (this.canComment) {
      this.setState({ deleteComment });
    }
  };

  handleDeleteCommentConfirm = async () => {
    const { milestone, deleteComment } = this.state;

    if (this.canComment && deleteComment) {
      const deleteCommentLoading = ApiService.deleteMilestoneComment(
        milestone!.engagementGuid,
        milestone!.engagementMilestoneGuid,
        deleteComment.commentGuid
      );
      this.setState({ deleteCommentLoading });

      try {
        await deleteCommentLoading;
        this.setState(prevState => {
          const { comments, editComment } = prevState;
          const deletedEditComment =
            editComment &&
            editComment.commentGuid === deleteComment.commentGuid;

          return {
            comments:
              comments &&
              comments.filter(c => c.commentGuid !== deleteComment.commentGuid),
            deleteComment: null,
            editComment: deletedEditComment ? null : editComment,
            editCommentText: deletedEditComment
              ? EMPTY_COMMENT
              : prevState.editCommentText
          };
        });
        this.events.emit('commentRemoved', {
          engagementGuid: milestone?.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }
      this.setState({ deleteCommentLoading: null });
    }
  };

  handleDeleteCommentcancel = () => {
    this.setState({ deleteComment: null });
  };

  render() {
    const { t, children } = this.props;

    const {
      milestone,
      comments,
      commentsLoading,
      editComment,
      editCommentText,
      editCommentLoading,
      deleteComment,
      deleteCommentLoading,
      newCommentText,
      newCommentLoading
    } = this.state;

    return (
      <>
        <MilestoneDrawerContext.Provider
          value={{
            milestoneDrawerEvents: this.events,
            onMilestoneDrawerOpen: this.handleOpen
          }}
        >
          {children}
        </MilestoneDrawerContext.Provider>

        <MilestoneDrawer
          canComment={this.canComment}
          comments={comments}
          commentsLoading={commentsLoading}
          editComment={editComment}
          editCommentLoading={editCommentLoading}
          editCommentText={editCommentText}
          milestone={milestone}
          newCommentLoading={newCommentLoading}
          newCommentText={newCommentText}
          onClose={this.handleClose}
          onDeleteComment={this.handleDeleteCommentRequest}
          onEditComment={this.handleEditComment}
          onEditCommentCancel={this.handleEditCommentCancel}
          onEditCommentChange={this.handleEditCommentChange}
          onEditCommentSave={this.handleEditCommentSave}
          onNewCommentChange={this.handleNewCommentChange}
          onNewCommentSave={this.handleNewCommentSave}
          t={t}
        />

        <CommentDeleteModal
          loading={deleteCommentLoading}
          onCancel={this.handleDeleteCommentcancel}
          onConfirm={this.handleDeleteCommentConfirm}
          open={deleteComment !== null}
          t={t}
        />
      </>
    );
  }
}

export default withTranslation()(withUser(MilestoneDrawerProvider));
