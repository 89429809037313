import { TTaskStatusProps } from './TaskStatus.interfaces';

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, EButtonSizes, EButtonVariant, Icon } from '@ryan/components';

import { useUser } from '../../../../../../hooks';
import { Permission, UserType } from '../../../../../../interfaces';
import { IconEnums } from '../../../../../../utils/enums/IconEnums';
import { formatDate } from '../../../../../../utils/formatDate';
import { isPastDue } from '../../../../../../utils/isPastDue';
import StatusIcon from '../../../../../StatusIcon/StatusIcon';

import './TaskStatus.scss';

const TaskStatus = ({
  isDisabled,
  onLinkToMilestone,
  onOpenFollowersModal,
  onToggleFollow,
  taskStatus
}: TTaskStatusProps) => {
  const ROOT_TO_TEXT = 'table.expandedRow.worklist.taskExpandedRow.status';
  const {
    createdBy,
    createdDate,
    dueDate,
    engagementGuid,
    engagementMilestoneGuid,
    engagementMilestoneTitle,
    followerCount,
    isFollowing,
    statusId
  } = taskStatus;

  const { t: getTextToDisplay } = useTranslation();
  const {
    permissionService,
    user: { profile: activeUserProfile }
  } = useUser();

  const hasTasksEditPermission = permissionService.hasPermission(
    Permission.TasksEdit
  );
  const isActiveUserRyan = permissionService.isRyan();
  const isActiveUserClientOnClientCreatedTask =
    permissionService.isClient() && createdBy.userType === UserType.Client;

  const details = [
    {
      detail:
        createdBy.userGuid === activeUserProfile.memberGuid
          ? getTextToDisplay(`${ROOT_TO_TEXT}.createdByYou`)
          : createdBy.name,
      label: 'createdBy'
    },
    {
      detail: formatDate(createdDate),
      label: 'creationDate'
    },
    {
      label: 'followers'
    }
  ];

  return (
    <div className="task-status">
      <div
        className={classNames('well', {
          'task-status--past-due': isPastDue(new Date(dueDate), new Date())
        })}
      >
        <StatusIcon status={statusId} />

        <div className="task-status__details">
          <ul>
            {details.map(({ detail, label }) => (
              <li key={label}>
                <label>{getTextToDisplay(`${ROOT_TO_TEXT}.${label}`)}</label>

                {label !== 'followers' ? (
                  <span>{detail}</span>
                ) : (
                  <>
                    <Button
                      disabled={isDisabled}
                      onClick={onToggleFollow}
                      size={EButtonSizes.SMALL}
                      variant={EButtonVariant.TEXT}
                    >
                      {getTextToDisplay(
                        `${ROOT_TO_TEXT}.${isFollowing ? 'unfollow' : 'follow'}`
                      )}
                    </Button>
                    •
                    <Button
                      disabled={isDisabled}
                      onClick={onOpenFollowersModal}
                      size={EButtonSizes.SMALL}
                      variant={EButtonVariant.TEXT}
                    >
                      {getTextToDisplay(`${ROOT_TO_TEXT}.followersWithCount`, {
                        count: followerCount
                      })}
                    </Button>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {permissionService.hasPermission(Permission.TimelinesView) && (
        <>
          {engagementMilestoneTitle && (
            <div className="task-status__linked-to">
              <Icon name={IconEnums.LINK} />
              {getTextToDisplay(`${ROOT_TO_TEXT}.linkedTo`)}
              <Link
                to={`/app/project/${engagementGuid}/overview/milestones/${engagementMilestoneGuid}`}
              >
                {engagementMilestoneTitle}
              </Link>
            </div>
          )}

          {!engagementMilestoneTitle &&
            hasTasksEditPermission &&
            (isActiveUserRyan || isActiveUserClientOnClientCreatedTask) && (
              <Button
                className="task-status__link-milestone"
                icon="link"
                onClick={onLinkToMilestone}
                size={EButtonSizes.SMALL}
                variant={EButtonVariant.TEXT}
              >
                {getTextToDisplay(`${ROOT_TO_TEXT}.addLink`)}
              </Button>
            )}
        </>
      )}
    </div>
  );
};

export default TaskStatus;
