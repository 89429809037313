import { IAccount } from 'interfaces';
import { escapeRegexCharacters } from 'utils/escapeRegexCharacters';

function filterAccountsRecurse(re: RegExp, accounts: IAccount[]): IAccount[] {
  return accounts.reduce((filtered, account) => {
    const filteredChildren = filterAccountsRecurse(re, account.accounts);
    if (re.test(account.name) || filteredChildren.length > 0) {
      filtered.push({ ...account, accounts: filteredChildren });
    }
    return filtered;
  }, [] as IAccount[]);
}

let cache: [string, IAccount[], IAccount[]] | null = null;

export default function filterAccounts(
  query: string,
  accounts: IAccount[]
): IAccount[] {
  if (query === '') {
    return accounts;
  }

  if (cache && cache[0] === query && cache[1] === accounts) {
    return cache[2];
  }

  const re = new RegExp(escapeRegexCharacters(query), 'i');
  const results = filterAccountsRecurse(re, accounts);
  cache = [query, accounts, results];
  return results;
}
