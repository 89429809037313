import {
  EngagementStatus,
  IAccount,
  IEngagement,
  IEngagementAddUsersRequest,
  IEngagementMemberStatus,
  IEngagementRequest,
  IEngagementSummary,
  IEngagementTimeline,
  IGhostUserRequest,
  IPagedDataResponse,
  IParentPracticeArea,
  IProjectOverviewTableSearch,
  IProjectsCount,
  ITeamManagerEngagementsSearchRequest,
  IUserEngagementsTableSearch,
  IUserSummary
} from 'interfaces';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

//
//
// Engagement
//
//

export async function getEngagement(
  this: ApiBase,
  guid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IEngagement>(`/api/engagements/${guid}`, {
    cancelToken
  });
}

export async function updateEngagement(
  this: ApiBase,
  guid: string,
  params: IEngagementRequest
) {
  return unwrapCommandResponse<IEngagement>(
    this.server.put(`/api/engagements/${guid}`, params)
  );
}

export async function publishEngagement(
  this: ApiBase,
  guid: string,
  params: IEngagementRequest
) {
  return unwrapCommandResponse<IEngagement>(
    this.server.post(`/api/engagements/${guid}/publish`, params)
  );
}

/**
 * GET all Engagements for a user.
 * @param userGuid: the user to search by
 * @param params: The standard parameters for table functionality
 */
export async function getEngagementsForUser(
  this: ApiBase,
  userGuid: string,
  params: IUserEngagementsTableSearch,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IEngagement>>(
    `/api/users/${userGuid}/engagements/search`,
    { cancelToken, params }
  );
}

/**
 * GET all Engagements for a user for a active view.
 */
export async function getEngagementsForCustomViewByUser(
  this: ApiBase,
  customViewGuid: string,
  userGuid: string,
  params: IProjectOverviewTableSearch,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IEngagement>>(
    `/api/customViews/${customViewGuid}/users/${userGuid}/engagements/search`,
    { cancelToken, params }
  );
}

export async function getEngagementsForCustomViewByUserWithFilters(
  this: ApiBase,
  customViewGuid: string,
  userGuid: string,
  request: IProjectOverviewTableSearch,
  cancelToken?: CancelToken
) {
  return this.server.post<IPagedDataResponse<IEngagement>>(
    `/api/customViews/${customViewGuid}/users/${userGuid}/engagements/search`,
    request
  );
}

/**
 * GET all Engagements that logged in user can manage (Big4, EA).
 */
export async function getEngagementsForTeamManager(
  this: ApiBase,
  params: ITeamManagerEngagementsSearchRequest,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IEngagementMemberStatus>>(
    `/api/engagements/teammanager`,
    { cancelToken, params }
  );
}

/**
 * Manage a single users membership from one or more engagements (Add, Remove, and/or ghost)
 */
export async function manageRyanUserEngagements(
  this: ApiBase,
  userGuid: string,
  request: {
    userGuid: string;
    employeeUserId: string;
    userEngagementSettings: {
      [key: string]: {
        engagementGuid: string;
        isMember: boolean;
        isGhosted: boolean;
      };
    };
  }
) {
  return this.server.put(`/api/users/${userGuid}/manage/engagements`, request);
}

export async function addRyanUsersToEngagement(
  this: ApiBase,
  engagementGuid: string,
  userList: IEngagementAddUsersRequest
) {
  return this.server.put(
    `/api/engagements/${engagementGuid}/users`,
    userList.assignmentStates
  );
}

export async function removeRyanUserFromEngagement(
  this: ApiBase,
  userGuid: string,
  engagementGuid: string
) {
  return this.server.delete(
    `/api/engagements/${engagementGuid}/users/${userGuid}`
  );
}

/**
 * PUT user settings for ghosting
 */
export async function manageGhostRyanUserOnEngagement(
  this: ApiBase,
  body: IGhostUserRequest
) {
  return this.server.put<boolean>(
    `/api/engagements/${body.engagementGuid}/users/ghost`,
    body
  );
}

/**
 * GET autocomplete results for Engagements
 * Autocomplete can be done based on customView, accountGuid, or even the combination depending on what is passed to BED
 * For IsUserGhosted -- We pass the follow of what engagements we want to grab based on their ghosted status
 */
export async function engagementAutocomplete(
  this: ApiBase,
  searchTerm: string,
  customViewGuid?: string | null,
  accountGuid?: string | null
) {
  return this.server.get<IEngagementSummary[]>(`/api/engagement/autocomplete`, {
    params: {
      customViewGuid,
      accountGuid,
      searchTerm,
      engagementStatus: EngagementStatus.Active,
      isUserGhosted: false
      // limit
    }
  });
}

/**
 * GET autocomplete results for Engagement Members
 * This will grab members on the engagement based off the following rules coming from BED:
 * 1 - If Ryan they can see all projects
 * 2 - If Client/Third party they can only see published projects
 */
export async function engagementMembersAutocomplete(
  this: ApiBase,
  engagementGuid: string,
  searchTerm: string
) {
  return this.server.get<IUserSummary[]>(
    `/api/engagements/${engagementGuid}/members/autocomplete`,
    {
      params: {
        searchTerm
        // PortalUsersOnly boolean
        // IncludeGhosted boolean
        // SearchTerm string
        // Limit integer
      }
    }
  );
}

//
//
// Timelines
//
//

/**
 * GET Timeline dates
 */
export async function getTimelineYears(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<number[]>(
    `/api/customViews/${customViewGuid}/engagements/timelines/years`,
    { cancelToken }
  );
}

export async function getTimelineParentPracticeAreas(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IParentPracticeArea[]>(
    `/api/customViews/${customViewGuid}/engagements/timelines/parentpracticeareas`,
    { cancelToken }
  );
}

/**
 * GET Timeline dates for custom view
 */
export async function getTimelines(
  this: ApiBase,
  params: {
    customViewGuid: string;
    parentPracticeAreaGuids?: string; // comma-delimited
    isActive?: string | number; // 0 | 1 | '0' | '1';
    alternateBeginDate?: string;
    projectedEndDate?: string;
    searchTerm?: string;
    itemsPerPage?: number;
    pageNumber?: number;
    sort?: string;
  },
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IEngagementTimeline>>(
    `/api/customViews/${params.customViewGuid}/engagements/timelines/search`,
    { cancelToken, params }
  );
}

//
//
// Projects Overview
//
//

/**
 * GET Engagements Parent Practice Area Counts
 */
export async function getProjectsOverviewRollup(
  this: ApiBase,
  customViewGuid: string
) {
  return this.server.get<IProjectsCount[]>(
    `/api/customViews/${customViewGuid}/practiceareacounts/`
  );
}

//
//
// Projects Last Updated
//
//

/**
 * GET Last Updated Engagements
 */
export async function getLastUpdatedProjects(
  this: ApiBase,
  customViewGuid: string
) {
  return this.server.get<IEngagement[]>(
    `/api/customViews/${customViewGuid}/engagements/updated`
  );
}

/**
 * GET Non Target Accounts
 */
export async function getThirdPartyClientAccountsAutocomplete(
  this: ApiBase,
  params: {
    searchTerm?: string;
    accountGuid?: string;
  }
) {
  return this.server.get<IAccount[]>(`/api/accounts/client/autocomplete`, {
    params
  });
}

/**
 * GET Accounts with non-ghosted engagements associated with current user under custom view guid
 */
export async function getRelevantAccountsForUserWithEngagementsByGuid(
  this: ApiBase,
  customViewGuid: string
) {
  return this.server.get<IAccount[]>(
    `/api/accounts/getrelevantaccounts/${customViewGuid}`
  );
}

type TRequestData = {
  currencyGuid: string;
  engagementGuid: string;
};

export async function updateProjectCurrency(
  this: ApiBase,
  requestData: TRequestData
) {
  return this.server.put('api/engagements/currency', requestData);
}
