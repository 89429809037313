/**
 * Generates an avatar profile image based on user interface fields.
 */
export default function getAvatarUrl(
  user?: {
    avatarUrl: string | null;
    userAvatarDocumentGuid: string | null;
  } | null
): string | undefined {
  if (user) {
    const baseUrl = user.avatarUrl;

    if (baseUrl) {
      const cacheBuster = user.userAvatarDocumentGuid;
      return `${baseUrl}${cacheBuster ? `?${cacheBuster}` : ''}`;
    }
  }
}
