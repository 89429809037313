import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Checkbox,
  EButtonVariant,
  pushToast
} from '@ryan/components';

import Modal from '../../components/Modal';
import EngagementContext from '../../contexts/EngagementContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import { IDataRequestV2, Permission } from '../../interfaces';
import ApiService from '../../services/ApiService';
import pushServerErrorToast from '../../utils/pushServerErrorToast';

import './DataRequestDeleteModal.scss';

interface IDataRequestDeleteModalProps extends WithTranslation, WithUser {
  dataRequest: IDataRequestV2;
  onClose: (deleted: boolean) => void;
}

interface IDataRequestDeleteModalState {
  deleteFiles: boolean;
  submitPromise: Promise<any> | null;
}

export class DataRequestDeleteModal extends Component<
  IDataRequestDeleteModalProps,
  IDataRequestDeleteModalState
> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  readonly state: IDataRequestDeleteModalState = {
    deleteFiles: false,
    submitPromise: null
  };

  handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ deleteFiles: e.target.checked });
  };

  handleCancel = () => {
    this.props.onClose(false);
  };

  handleDelete = async () => {
    const { t, dataRequest, onClose } = this.props;
    const { title, queueItemGuid } = dataRequest;
    const { deleteFiles } = this.state;

    this.setState({
      submitPromise: ApiService.deleteDataRequest(
        dataRequest.engagementGuid,
        queueItemGuid,
        deleteFiles
      )
        .then(() => {
          pushToast({
            type: 'success',
            title: t('dataRequest.deleteModal.success.title'),
            content: t('dataRequest.deleteModal.success.content', { title })
          });
          onClose(true);
        })
        .catch(() => {
          pushServerErrorToast();
          onClose(false);
        })
    });
  };

  render() {
    const { t, permissionService } = this.props;
    const { deleteFiles, submitPromise } = this.state;
    return (
      <Modal
        className="data-request-delete-modal"
        onClose={this.handleCancel}
        open
        title={t('dataRequest.deleteModal.title')}
      >
        <p>{t('dataRequest.deleteModal.content')}</p>
        {permissionService.hasPermission(Permission.FilesWrite) && (
          <Checkbox
            checked={deleteFiles}
            label={t('dataRequest.deleteModal.deleteFiles')}
            onChange={this.handleCheckbox}
            value="delete-files"
          />
        )}
        <ButtonGroup>
          <Button
            loading={submitPromise}
            negative
            onClick={this.handleDelete}
            text={t('Yes, Delete')}
          />
          <Button
            disabled={submitPromise !== null}
            onClick={this.handleCancel}
            text={t('No, Cancel')}
            variant={EButtonVariant.SECONDARY}
          />
        </ButtonGroup>
      </Modal>
    );
  }
}

export default withTranslation()(withUser(DataRequestDeleteModal));
