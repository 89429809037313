import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  ITableColumn,
  Icon,
  makeTableCheckboxFilter
} from '@ryan/components';

import Empty from '../../../components/Empty';
import Table from '../../../components/Table';
import TableEmpty from '../../../components/TableEmpty/TableEmpty';
import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  EngagementStatus,
  IEngagement,
  ITableState
} from '../../../interfaces';
import { formatBytes } from '../../../utils/formatBytes';

import './Engagements.scss';

type Sort = { id: string; desc: boolean } | null | undefined;

interface IEngagmentsProps extends WithUser {
  engagements: IEngagement[];
  filtered?: { [key: string]: any };
  loading: boolean;
  onEngagementSelected: (engagement: IEngagement) => void;
  onFilter: (filtered: ITableState['filtered']) => void;
  onPageChange: (page: number, pageSize: number) => void;
  onSortChange: (sorted: Sort) => void;
  page: number;
  pageSize: number;
  searchQuery: string | undefined;
  sorted: Sort;
  totalCount: number;
}

const Engagements: FunctionComponent<IEngagmentsProps> = ({
  engagements,
  filtered,
  loading,
  onEngagementSelected,
  onFilter,
  onPageChange,
  onSortChange,
  permissionService,
  page,
  pageSize,
  searchQuery,
  sorted,
  totalCount
}) => {
  const { t: getTextToDisplay } = useTranslation();

  const columns: ITableColumn<IEngagement>[] = useMemo(
    () => [
      {
        id: 'engagementDisplayNameLong',
        label: getTextToDisplay('Name'),
        render: row => (
          <Button
            className="files-tab__engagement bs bs--light bs--icon"
            onClick={() => onEngagementSelected(row)}
            variant={EButtonVariant.LINK}
          >
            <Icon name="folder-open" />
            <b>{row.engagementDisplayNameLong}</b>
            <small>{row.accountName}</small>
          </Button>
        ),
        sortable: true,
        width: '50%'
      },
      {
        id: 'engagementId',
        label: getTextToDisplay('Code'),
        render: row => (
          <span className="ry-table__td__engagement-id">
            {row.engagementId}
          </span>
        ),
        sortable: true,
        width: '10%'
      },
      {
        filter: makeTableCheckboxFilter([
          {
            label: getTextToDisplay('Active'),
            value: EngagementStatus.Active.toString()
          },
          {
            label: getTextToDisplay('Inactive'),
            value: EngagementStatus.InActive.toString()
          }
        ]),
        filterActive: (value: string[]) => value.length > 0,
        id: 'status',
        label: getTextToDisplay('projectOverview.columns.isActive'),
        render: row => (
          <div className="projects-overview__pills">
            <div className={`pill ${row.isActive ? 'active' : 'inactive'}`}>
              {getTextToDisplay(row.isActive ? 'Active' : 'Inactive')}
            </div>
          </div>
        )
      },
      {
        id: 'totalFileCount',
        label: getTextToDisplay('Total Files'),
        render: row => <div>{row.totalFileCount}</div>,
        sortable: true
      },
      {
        id: 'totalFileSize',
        label: getTextToDisplay('Size'),
        render: row => <div>{formatBytes(row.totalFileSize)}</div>,
        sortable: true
      },
      {
        id: 'action',
        label: '',
        align: 'right',
        width: '7rem',
        render: row => (
          <div className="file-directory__actions">
            <Button
              className="files-button"
              icon="chevron-right"
              onClick={e => {
                e.preventDefault();
                onEngagementSelected(row);
              }}
              size={EButtonSizes.SMALL}
              variant={EButtonVariant.TEXT}
            />
          </div>
        )
      }
    ],
    [getTextToDisplay, onEngagementSelected]
  );

  let tableColumns: ITableColumn<IEngagement>[];

  if (permissionService.isRyan()) {
    tableColumns = columns;
  } else {
    tableColumns = columns.filter(column => column.id !== 'engagementId');
  }

  return (
    <Table<IEngagement>
      className="files-tab__table"
      columns={tableColumns}
      data={engagements}
      filtered={filtered}
      loading={loading}
      loadingCount={5}
      onFilterChange={onFilter}
      onPageChange={onPageChange}
      onSortChange={onSortChange}
      page={page}
      pageSize={pageSize}
      renderEmpty={() => (
        <TableEmpty searchQuery={searchQuery}>
          <Empty icon="folder-open">
            {getTextToDisplay('file.noFilesExist')}
          </Empty>
        </TableEmpty>
      )}
      rowId="engagementGuid"
      sorted={sorted}
      totalCount={totalCount}
    />
  );
};

export default withUser(Engagements);
