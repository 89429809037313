import AutocompleteAjax, {
  IAutocompleteAjaxProps
} from 'components/AutocompleteAjax';
import { useStateMounted } from 'hooks';
import { IPracticeArea } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import { escapeRegexCharacters } from 'utils/escapeRegexCharacters';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { autocompleteHighlight } from '@ryan/components';

type IPracticeAutocompleteProps = Omit<
  IAutocompleteAjaxProps<IPracticeArea>,
  'getOptionValue' | 'onFetchOptions' | 'renderOption'
> & {
  excludeParentPracticeAreas?: boolean;
};

/**
 * Parses the value string to be used for the option field.
 */
const getOptionValue = (practiceArea: IPracticeArea) =>
  practiceArea.practiceArea;

/**
 * Renders a parent practice area option.
 */
const renderOption = (
  practiceArea: IPracticeArea,
  { query }: { query: string }
) => autocompleteHighlight(practiceArea.practiceArea, query);

/**
 * Array compare function to sort practice areas alphabetically.
 */
const sortPracticeAreas = (area1: IPracticeArea, area2: IPracticeArea) => {
  if (area1.practiceArea < area2.practiceArea) {
    return -1;
  }

  if (area1.practiceArea > area2.practiceArea) {
    return 1;
  }

  return 0;
};

/**
 * Autocomplete field for searching ALL parent practice areas. Passes an
 * `IPracticeArea` object argument on call of `onChange`.
 */
const PracticeAutocomplete: React.FC<IPracticeAutocompleteProps> = ({
  disabled,
  excludeParentPracticeAreas,
  ...rest
}) => {
  const { t } = useTranslation();
  const sourceRef = useRef<CancelTokenSource>();
  const [practiceAreas, setPracticeAreas] = useState<IPracticeArea[]>([]);
  const [loading, setLoading] = useStateMounted(false);

  // fetch all practice areas on mount
  useEffect(() => {
    const fetchPracticeAreas = async () => {
      sourceRef.current = ApiService.CancelToken.source();
      setLoading(true);

      try {
        let { data: practiceAreas } = await ApiService.getAllPracticeAreas(
          sourceRef.current.token
        );

        // filter out parent practice areas if needed
        if (excludeParentPracticeAreas) {
          practiceAreas = practiceAreas.filter(
            practiceArea => !practiceArea.isParent
          );
        }

        setPracticeAreas(practiceAreas.sort(sortPracticeAreas));
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPracticeAreas();

    return () => {
      // cancel an ongoing request
      sourceRef.current?.cancel(
        'cancelling previous PracticeAutocomplete fetch'
      );
    };
  }, [excludeParentPracticeAreas, setLoading]);

  /**
   * Returns a list of practice areas matching the query string.
   */
  const handleFetchOptions = useCallback(
    async (query: string) => {
      const regExp = new RegExp(escapeRegexCharacters(query), 'gi');
      return practiceAreas.filter(practiceArea =>
        regExp.test(practiceArea.practiceArea)
      );
    },
    [practiceAreas]
  );

  return (
    <AutocompleteAjax<IPracticeArea>
      disabled={loading || practiceAreas.length === 0 || disabled}
      getOptionValue={getOptionValue}
      label={t('Practice')}
      loading={loading}
      onFetchOptions={handleFetchOptions}
      renderOption={renderOption}
      {...rest}
    />
  );
};

export default PracticeAutocomplete;
