import {
  INotification,
  INotificationDismissConfig,
  IPagedDataParams,
  IPagedDataResponse
} from 'interfaces';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';

import ApiBase from './ApiBase';

/**
 * Get notifications for user. Always comes pre-sorted by descending
 * `activitySummary.createDate`.
 */
export async function getNotifications(
  this: ApiBase,
  params: IPagedDataParams = {}
) {
  return this.server.get<IPagedDataResponse<INotification>>(
    `/api/notifications`,
    { params }
  );
}

/**
 * Dismiss notifications by group or by `notificationGuid`. Omitting the config
 * will dismiss ALL notifications.
 *
 * @returns A Promise resolving to the total number of notifications remaining.
 */
export async function dismissNotifications(
  this: ApiBase,
  config: INotificationDismissConfig = {}
) {
  return unwrapCommandResponse<number>(
    this.server.put(`/api/notifications/dismiss`, config)
  );
}
