import {
  IAccountSavings,
  IAttachmentUpdates,
  IEngagementSavingsHistory,
  IEngagementSavingsHistoryRequest,
  IEngagementSavingsSummary,
  IEngagementSavingsSummaryDetails,
  IEngagementSavingsSummaryParams,
  IHistoricalSavingsFormValidated,
  IHistoricalSavingsSummary,
  ISavingsSummaryEntry,
  ISavingsSummaryEntryRequest,
  ISavingsSummaryRollup,
  IPagedDataResponse as Paged
} from 'interfaces';
import { getHistoricalSavingsEngagementName } from 'routes/Projects/SavingsSummaryOverview/HistoricalSavingsEngagementName';
import getISODateString from 'utils/getDateString';
import { isTempId } from 'utils/tempId';
import { transformResponse } from 'utils/transformResponse';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

//
//
// Savings Summaries
//
//

/**
 * Get engagement savings summaries for a custom view.
 */
export async function getCustomViewSavingsSummaries(
  this: ApiBase,
  customViewGuid: string,
  params: IEngagementSavingsSummaryParams,
  cancelToken?: CancelToken
) {
  return this.server.get<Paged<IEngagementSavingsSummary>>(
    `/api/customViews/${customViewGuid}/savingsSummaries/search`,
    { cancelToken, params, transformResponse }
  );
}

/**
 * Get engagement savings summaries for an account.
 */
export async function getEngagementSavingsSummary(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IEngagementSavingsSummary>(
    `/api/engagements/${engagementGuid}/savingsSummaries/overview`,
    { cancelToken, transformResponse }
  );
}

/**
 * Get engagement savings summaries details for an account.
 */
export async function getEngagementSavingsSummaryDetails(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken,
  isEdit?: boolean
) {
  return this.server.get<IEngagementSavingsSummaryDetails>(
    `/api/engagements/${engagementGuid}/savingsSummaries/details${
      isEdit ? '?edit=true' : ''
    }`,
    { cancelToken, transformResponse }
  );
}

//
//
// Savings Entries
//
//

export async function getEngagementSavingsSummaryLatestEntry(
  this: ApiBase,
  engagementGuid: string
) {
  return this.server.get<ISavingsSummaryEntry>(
    `/api/engagements/${engagementGuid}/savingsSummaries/latest`,
    { transformResponse }
  );
}

export async function saveSavingsSummaryEntry(
  this: ApiBase,
  entry: ISavingsSummaryEntry,
  attachments: IAttachmentUpdates = {
    addUploaded: [],
    addExisting: [],
    deleteAttachments: []
  }
) {
  const { engagementGuid } = entry;

  if (!engagementGuid) {
    throw new ReferenceError('Entry must have an engagementGuid.');
  }

  const entryRequest: ISavingsSummaryEntryRequest = {
    asOfDate: getISODateString(entry.asOfDate),
    isBaseline: entry.isBaseline,
    categories: entry.categories
      // Remove shouldDeletePermanently that are not saved in the DB
      .filter(category => {
        const isNew = isTempId(category.savingsSummaryEntryCategoryGuid);
        return !(isNew && category.shouldDeletePermanently);
      })
      // Replace temp guids with null
      .map(category => {
        const isNew = isTempId(category.savingsSummaryEntryCategoryGuid);
        return {
          ...category,
          savingsSummaryEntryCategoryGuid: isNew
            ? null
            : category.savingsSummaryEntryCategoryGuid
        };
      }),
    commentText: entry.commentText,
    attachments
  };

  return entry.savingsSummaryGuid
    ? this.server.put<ISavingsSummaryEntry>(
        `/api/engagements/${engagementGuid}/savingsSummaries/${entry.savingsSummaryGuid}`,
        entryRequest,
        { transformResponse }
      )
    : this.server.post<ISavingsSummaryEntry>(
        `/api/engagements/${engagementGuid}/savingsSummaries`,
        entryRequest,
        { transformResponse }
      );
}

//
//
// Savings History
//
//

export async function getEngagementSavingsHistory(
  this: ApiBase,
  engagementGuid: string,
  params: IEngagementSavingsHistoryRequest,
  cancelToken?: CancelToken
) {
  const response = await this.server.get<IEngagementSavingsHistory>(
    `/api/engagements/${engagementGuid}/savingsSummaries/history`,
    { cancelToken, params, transformResponse }
  );

  response.data.entries.map(entry => {
    //

    // flag updated categories
    entry.categories.forEach(category => {
      category.isUpdated =
        category.potentialAdded !== 0 ||
        category.potentialPassed !== 0 ||
        category.potentialAdvancedOut !== 0 ||
        category.submittedAdded !== 0 ||
        category.submittedPassed !== 0 ||
        category.submittedAdvancedOut !== 0 ||
        category.approvedAdded !== 0 ||
        category.approvedPassed !== 0 ||
        category.approvedAdvancedOut !== 0 ||
        category.receivedAdded !== 0 ||
        category.receivedPassed !== 0;
    });

    // flag entry's update sources
    const updated = entry.categories.filter(c => c.isUpdated);
    entry.updateSource = [
      updated.some(c => c.isEditable), // 1 DXP (Internal)
      updated.some(c => !c.isEditable) // 2 PPT (External)
    ].reduce((bits, flag, index) => (flag ? (1 << index) | bits : bits), 0);

    return entry;
  });

  return response;
}

//
//
// Historical Savings
//
//

export async function getHistoricalSavingsSummaries(
  this: ApiBase,
  customViewGuid: string,
  params: {
    searchTerm?: string;
    itemsPerPage?: number;
    pageNumber?: number;
    sort?: string;
  },
  cancelToken?: CancelToken
) {
  return this.server.get<Paged<IHistoricalSavingsSummary>>(
    `/api/customViews/${customViewGuid}/historicalSavingsSummaries/search`,
    { cancelToken, params, transformResponse }
  );
}

export async function autocompleteHistoricalSavingsServices(
  this: ApiBase,
  searchTerm: string
) {
  return this.server.get<string[]>(
    '/api/historicalSavingsSummaries/serviceTypes/autocomplete',
    { params: { searchTerm } }
  );
}

export async function autocompleteHistoricalSavingsPractices(
  this: ApiBase,
  searchTerm: string
) {
  return this.server.get<string[]>(
    '/api/historicalSavingsSummaries/practiceAreas/autocomplete',
    { params: { searchTerm } }
  );
}

function historicalSavingsBody(savings: IHistoricalSavingsFormValidated) {
  return {
    accountGuid: savings.account.accountGuid,
    accountStatus: savings.accountStatus,
    inactiveAccountName: savings.inactiveAccountName,
    practiceName: savings.practiceName,
    serviceTypeName: savings.serviceTypeName,
    jurisdictionGuid: savings.jurisdiction.jurisdictionGuid,
    isoNumericCode: savings.country.isoNumericCode,
    periodStartDate: getISODateString(savings.periodStartDate),
    periodEndDate: getISODateString(savings.periodEndDate),
    engagementName: getHistoricalSavingsEngagementName(savings),
    isoCurrencyCode: savings.isoCurrencyCode,
    asOfDate: getISODateString(savings.asOfDate),
    totalSavings: savings.totalSavings
  };
}

/**
 * Creates a new historical savings summary.
 */
export async function createHistoricalSavings(
  this: ApiBase,
  savings: IHistoricalSavingsFormValidated
) {
  return this.server.post<IHistoricalSavingsSummary>(
    '/api/historicalsavingssummaries',
    historicalSavingsBody(savings)
  );
}

/**
 * Updates an existing historical savings entry.
 */
export async function updateHistoricalSavings(
  this: ApiBase,
  historicalSavingsSummaryGuid: string,
  savings: IHistoricalSavingsFormValidated
) {
  return this.server.put<IHistoricalSavingsSummary>(
    `api/historicalSavingsSummaries/${historicalSavingsSummaryGuid}`,
    historicalSavingsBody(savings)
  );
}

/**
 * Delete a historical savings summary.
 */
export async function deleteHistoricalSavings(
  this: ApiBase,
  historicalSavingsSummaryGuid: string
) {
  return this.server.delete(
    `api/historicalSavingsSummaries/${historicalSavingsSummaryGuid}`
  );
}

//
//
// Savings Totals
//
//

/**
 * Gets the savings totals for a custom view, sum of project savings
 * and external savings ("outside of Ryan.com"), grouped by currency.
 */
export async function getCustomViewSavingsTotals(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IAccountSavings[]>(
    `/api/customViews/${customViewGuid}/savingsSummaries/totals`,
    { cancelToken, transformResponse }
  );
}

/**
 * Get saving summaries rollup for a custom view.
 */
export async function getCustomViewSavingsSummaryRollup(
  this: ApiBase,
  customVIewGuid: string,
  startDate?: Date,
  endDate?: Date,
  cancelToken?: CancelToken
) {
  return this.server.get<ISavingsSummaryRollup>(
    `/api/customViews/${customVIewGuid}/savingssummaries/rollup`,
    { cancelToken, params: { endDate, startDate } }
  );
}

/**
 * Get saving summaries rollup for an engagement.
 */
export async function getEngagementSavingsSummaryRollup(
  this: ApiBase,
  engagementGuid: string,
  startDate?: Date,
  endDate?: Date,
  cancelToken?: CancelToken
) {
  return this.server.get<ISavingsSummaryRollup>(
    `/api/engagements/${engagementGuid}/savingssummaries/rollup`,
    { cancelToken, params: { endDate, startDate } }
  );
}

export type TGetSupportedISOCurrencyCodes = {
  currencyGuid: string;
  isoAlphaCode: string;
  isVisible: boolean;
  ryanCurrencyName: string;
};

export async function getSupportedISOCurrencyCodes(
  this: ApiBase,
  cancelToken?: CancelToken
) {
  return this.server.get<TGetSupportedISOCurrencyCodes[]>(
    'api/currency/supported',
    { cancelToken }
  );
}
