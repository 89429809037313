import AutocompleteAjax from 'components/AutocompleteAjax';
import Datepicker from 'components/Datepicker';
import Modal from 'components/Modal';
import SelectLanguage from 'components/SelectLanguage';
import filterAccounts from 'components/Switcher/filterAccounts';
import { useUser } from 'contexts/UserContext';
import { IAccount, IModalProps, UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import flattenAccountTree from 'utils/flattenAccountTree';
import { formatDate } from 'utils/formatDate';
import {
  formikAutocompleteAjaxProps,
  formikCheckboxProps,
  formikDatepickerProps,
  formikFieldProps,
  useFormik,
  yup
} from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import standardizeUSDate from 'utils/standardizeUSDate';

import addDays from 'date-fns/addDays';
import maxDate from 'date-fns/max';
import startOfDay from 'date-fns/startOfDay';
import ENV from 'env';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Dropdown,
  EButtonVariant,
  IOption,
  Message,
  Radio,
  RadioGroup,
  TextInput,
  autocompleteHighlight,
  pushToast
} from '@ryan/components';

import './NewUserRequestModal.scss';

/**
 * Accounts array sort function.
 */
const sortAccountsAlphabetically = (account1: IAccount, account2: IAccount) => {
  if (account1.name < account2.name) return -1;
  if (account1.name > account2.name) return 1;
  return 0;
};

type INewUserRequestValues = {
  email: string;
  firstName: string;
  lastName: string;
  account: IAccount | null;
  languagePreference: string;
  activationDate: Date | null;
  userType: string;
  thirdPartyType: string;
  clientAccount: IAccount | null;
  ensureThirdPartyAccess: boolean;
  obtainedPermissionForThirdParty: boolean;
};

const initialValues: INewUserRequestValues = {
  email: '',
  firstName: '',
  lastName: '',
  account: null,
  languagePreference: '',
  activationDate: null,
  userType: '',
  thirdPartyType: '',
  clientAccount: null,
  ensureThirdPartyAccess: false,
  obtainedPermissionForThirdParty: false
};

export type INewUserRequestModalProps = IModalProps & {
  onNewUserRequest: () => void;
};

/**
 * Renders a modal with the "New User Request" form.
 */
const NewUserRequestModal: React.FC<INewUserRequestModalProps> = ({
  open,
  onClose,
  onNewUserRequest
}) => {
  const { t } = useTranslation();
  const {
    user: { accountTree: userAccountTree, profile: userProfile }
  } = useUser();
  const startOfTodayRef = useRef(startOfDay(new Date()));

  // default minumum date for deferred user activation
  const startOfTomorrowRef = useRef(addDays(startOfTodayRef.current, 1));

  // require deferred activation date in form
  const [activationDateRequired, setActivationDateRequired] = useState(false);

  // minimum allowed deferred activation date
  const [minimumActivationDate, setMinimumActivationDate] = useState(
    startOfTomorrowRef.current
  );

  // the form submit request Promise
  const [request, setRequest] = useState<Promise<unknown> | null>(null);

  /**
   * Generates the message to display on user request error response.
   */
  const buildStatusMessage = (userStatus: string, email: string) => {
    let messageContentType: string;
    let titleType: string;

    switch (userStatus) {
      case 'Denied':
        titleType = 'titleDenied';
        messageContentType = 'denied';
        break;
      case 'Deactivated':
        titleType = 'titleDeactivated';
        messageContentType = 'deactivated';
        break;
      case 'Complete':
        titleType = 'titleActive';
        messageContentType = 'active';
        break;
      default:
        titleType = 'titlePending';
        messageContentType = 'pending';
    }

    return (
      <Message type={userStatus === 'Denied' ? 'error' : 'info'}>
        <b>{t(`newUserModal.existing.${titleType}`)}</b>
        <br />
        {t(`newUserModal.existing.${messageContentType}`, { email })}
      </Message>
    );
  };

  // form configuration
  const formik = useFormik<INewUserRequestValues>({
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .required(t('newUserModal.validation.email.empty'))
        .email(t('newUserModal.validation.email.valid')),
      firstName: yup.string().required(t('newUserModal.validation.firstName')),
      lastName: yup.string().required(t('newUserModal.validation.lastName')),
      account: yup
        .object<IAccount>()
        .nullable()
        .required(t('newUserModal.validation.accountGuid')),
      languagePreference: yup
        .string()
        .required(t('newUserModal.validation.languagePreference')),
      activationDate: yup
        .date()
        .nullable()
        .test(
          'activationDate',
          t('newUserModal.validation.activationDate', {
            minDate: formatDate(minimumActivationDate)
          }),
          (activationDate: Date | null) =>
            !activationDateRequired ||
            (!!activationDate && activationDate >= minimumActivationDate)
        ),
      userType: yup.string().test(
        'userType',
        t('newUserModal.validation.userType'),
        (value: string) =>
          // non-Ryan users will not fill-in this field
          userProfile.userTypeId !== UserType.Ryan || !!value
      ),
      thirdPartyType: yup.string().when('userType', {
        is: `${UserType.ThirdParty}`,
        then: yup.string().required(t('newUserModal.validation.thirdParty'))
      }),
      clientAccount: yup
        .object<IAccount>()
        .nullable()
        .when('userType', {
          is: `${UserType.ThirdParty}`,
          then: yup
            .object<IAccount>()
            .required(t('newUserModal.validation.thirdParty'))
        }),
      ensureThirdPartyAccess: yup.boolean().when('userType', {
        is: `${UserType.ThirdParty}`,
        then: yup
          .boolean()
          .oneOf([true], t('newUserModal.validation.thirdParty'))
      }),
      obtainedPermissionForThirdParty: yup.boolean().when('userType', {
        is: `${UserType.ThirdParty}`,
        then: yup
          .boolean()
          .oneOf([true], t('newUserModal.validation.thirdParty'))
      })
    }),
    initialValues,
    onSubmit: async values => {
      const trimmedEmail = values.email.trim();

      try {
        const responsePromise = ApiService.requestNewUser({
          email: trimmedEmail,
          firstName: values.firstName,
          lastName: values.lastName,
          accountGuid: values.account!.accountGuid,
          languagePreference: values.languagePreference,
          activationDate: values?.activationDate?.toISOString(),
          userType:
            // NOTE: using enum int value for client but mapping third party
            // int values (as string) back to their third party type key names -
            // confirmed with backend that this is working so leaving it as is
            values.userType === `${UserType.Client}` || !values.userType
              ? UserType.Client
              : UserType[values.thirdPartyType as keyof typeof UserType],
          clientAccountGuid: values.clientAccount?.accountGuid
        });
        setRequest(responsePromise);
        const { status } = await responsePromise;

        if (status === 200) {
          pushToast({
            type: 'success',
            title: t('newUserModal.successToast.requested.title'),
            content: t('newUserModal.successToast.requested.content', {
              name: `${values.firstName} ${values.lastName}`,
              ryanPlatform: ENV.RYAN_PLATFORM
            })
          });

          // trigger modal close
          onClose();
          onNewUserRequest();
        }
      } catch (error: any) {
        switch (error?.response?.status) {
          case 500:
            pushToast({
              type: 'error',
              title: t('newUserModal.errorToast.requested')
            });
            break;
          case 400:
            setEmailError({
              email: trimmedEmail,
              message: buildStatusMessage(error.response.data[0], trimmedEmail)
            });
            break;
        }
      } finally {
        setRequest(null);
      }
    }
  });

  // formik function refs for use in hooks
  const formikResetFormRef = useRef(formik.resetForm);
  const formikSetFieldErrorRef = useRef(formik.setFieldError);
  const formikSetFieldValueRef = useRef(formik.setFieldValue);
  const formikValidateFormRef = useRef(formik.validateForm);
  const formikValuesActivationDateRef = useRef(formik.values.activationDate);

  // applies feedback messages when the request returns an error
  const [emailError, setEmailError] = useState<{
    email: string;
    message: React.ReactNode | null;
  }>({
    email: '',
    message: null
  });

  // first and last names that are autofilled based on a known email address;
  // disables the name fields if they are autofilled
  const [autoFillFirstName, setAutofillFirstName] = useState<string | null>(
    null
  );
  const [autofillLastName, setAutofillLastName] = useState<string | null>(null);

  // options list for third-party type dropdown
  const optionsThirdPartyType: IOption[] = useMemo(
    () => [
      {
        value: '',
        label: t('newUserModal.form.placeholder'),
        disabled: true
      },
      ...[
        UserType.ClientRepresentativeAppraiser,
        UserType.ClientRepresentativeAttorney,
        UserType.TaxAgencyRepresentative
      ].map(userType => ({
        value: `${userType}`,
        label: t(`userTypes.${userType}`)
      }))
    ],
    [t]
  );

  // controls visibility of third-party user-specific fields
  const showThirdPartyFields =
    formik.values.userType === `${UserType.ThirdParty}`;

  // flag indicating a user type has been selected; controls whether account
  // values can be updated/cleared when switching between user types
  const userTypeHasBeenSelectedRef = useRef(false);

  /**
   * Callback fired on email field blur. Updates name fields if email is tied to
   * a known new user.
   */
  const handleEmailBlur = async (email: string) => {
    const response = email
      ? await ApiService.getExistingUserByEmail({
          email
        })
      : null;

    // if a matching user is found, autofill and disable name fields
    if (response !== null && response?.data !== null) {
      const { firstName, lastName } = response.data;
      formik.setFieldValue('firstName', firstName || '');
      formik.setFieldValue('lastName', lastName || '');
      setAutofillFirstName(firstName);
      setAutofillLastName(lastName);
    } else if (autoFillFirstName || autofillLastName) {
      // if blurring after names have been autofilled and no results found
      // (email has changed), clear existing autofill values in name fields and
      // re-enable them by clearing state
      formik.setFieldValue('firstName', '');
      formik.setFieldValue('lastName', '');
      setAutofillFirstName(null);
      setAutofillLastName(null);
    }
  };

  /**
   * Callback fired on account autocomplete search.
   */
  const handleFetchAccounts = useCallback(
    async (searchQuery: string) =>
      flattenAccountTree(filterAccounts(searchQuery, userAccountTree))
        .filter(account => !account.isProspect)
        .sort(sortAccountsAlphabetically),
    [userAccountTree]
  );

  /**
   * Checks if a given date is after today.
   */
  const isFutureDate = useCallback(
    (date?: Date | null | string): boolean =>
      !!date && startOfDay(new Date(date)) > startOfTodayRef.current,
    []
  );

  // update formik refs
  useEffect(() => {
    formikResetFormRef.current = formik.resetForm;
    formikSetFieldErrorRef.current = formik.setFieldError;
    formikSetFieldValueRef.current = formik.setFieldValue;
    formikValidateFormRef.current = formik.validateForm;
    formikValuesActivationDateRef.current = formik.values.activationDate;
  }, [formik]);

  // reset form and form state on modal close
  useEffect(() => {
    if (!open) {
      setAutofillFirstName(null);
      setAutofillLastName(null);
      formikResetFormRef.current();
      userTypeHasBeenSelectedRef.current = false;
    }
  }, [open]);

  // update accounts when switching between user types
  useEffect(() => {
    if (
      userTypeHasBeenSelectedRef.current &&
      formik.values.userType === `${UserType.Client}`
    ) {
      // clear both account fields if switching back to client type as account
      // tree for account field is different based on user-type
      formikSetFieldValueRef.current('account', null);
      formikSetFieldValueRef.current('clientAccount', null);
    } else if (formik.values.userType === `${UserType.ThirdParty}`) {
      // clear account field anytime third-party user type is selected as it
      // uses a unique account tree
      formikSetFieldValueRef.current('account', null);
    }

    // update user type selection flag
    if (formik.values.userType) {
      userTypeHasBeenSelectedRef.current = true;
    }
  }, [formik.values.userType]);

  // reset email errors on email value change
  useEffect(() => {
    if (emailError.email && emailError.email !== formik.values.email) {
      setEmailError({
        email: '',
        message: null
      });
    }
  }, [emailError, formik.values.email]);

  // revalidate form on email error update
  useEffect(() => {
    formikValidateFormRef.current();
  }, [emailError]);

  // update minimum allowed activation date on account update
  useEffect(() => {
    const account = formik.values.account;
    const clientAccount = formik.values.clientAccount;

    // update minimum allowed date to max value of accounts' minimum allowed
    // request date; ensure it is a future date (default to tomorrow otherwise)
    const accountActivationDates: Date[] = [
      account?.minimumNewUserRequestDate,
      clientAccount?.minimumNewUserRequestDate
    ]
      .filter((date): date is string => !!date)
      .map(date => standardizeUSDate(date));
    const minAllowedAccountDate =
      accountActivationDates.length > 0
        ? maxDate(accountActivationDates)
        : null;
    setMinimumActivationDate(
      maxDate([startOfTomorrowRef.current, minAllowedAccountDate || 0])
    );

    // require deferred activation date if in account activation date is defined
    // and in the future
    const requiresFutureActivation = isFutureDate(minAllowedAccountDate);
    setActivationDateRequired(requiresFutureActivation);

    // update activation date if account's minimum activation date is in the
    // future or later than currently defined activation date
    // NOTE: using a ref for `activationDate` form value so that when user
    // manually clears an activation date for an account that has a minimum
    // activation date, they see the field error
    if (
      minAllowedAccountDate &&
      requiresFutureActivation &&
      (!formikValuesActivationDateRef.current ||
        formikValuesActivationDateRef.current < minAllowedAccountDate)
    ) {
      formikSetFieldValueRef.current('activationDate', minAllowedAccountDate);
      formikSetFieldErrorRef.current('activationDate', '');
    }
  }, [formik.values.account, formik.values.clientAccount, isFutureDate]);

  return (
    <Modal
      className="newuser-request"
      onClose={onClose}
      open={open}
      title={t('newUserModal.title')}
    >
      {emailError.email && emailError.message}
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        {userProfile.userTypeId === UserType.Ryan && (
          <RadioGroup
            {...formikFieldProps('userType', formik)}
            label={t('newUserModal.form.userType')}
            onChange={(_, value) => {
              formik.setFieldValue('userType', value);
            }}
          >
            <Radio label={t('Client')} value={`${UserType.Client}`} />
            <Radio label={t('Third Party')} value={`${UserType.ThirdParty}`} />
          </RadioGroup>
        )}
        {showThirdPartyFields && (
          <Dropdown
            {...formikFieldProps('thirdPartyType', formik)}
            label={t('newUserModal.form.thirdPartyType')}
            options={optionsThirdPartyType}
          />
        )}
        <TextInput
          {...formikFieldProps('email', formik)}
          label={t('newUserModal.form.email')}
          onBlur={e => handleEmailBlur(e.target.value)}
        />
        <div className="row">
          <div className="col-lg-6">
            <TextInput
              {...formikFieldProps('firstName', formik)}
              disabled={!!autoFillFirstName}
              label={t('newUserModal.form.firstName')}
              value={autoFillFirstName || formik.values.firstName}
            />
          </div>
          <div className="col-lg-6">
            <TextInput
              {...formikFieldProps('lastName', formik)}
              disabled={!!autofillLastName}
              label={t('newUserModal.form.lastName')}
              value={autofillLastName || formik.values.lastName}
            />
          </div>
        </div>
        {showThirdPartyFields && (
          <AutocompleteAjax<IAccount>
            {...formikAutocompleteAjaxProps('account', formik)}
            getOptionValue={account => account.name}
            label={t('newUserModal.form.usersCompany')}
            onFetchOptions={query =>
              ApiService.getThirdPartyClientAccountsAutocomplete({
                searchTerm: query
              })
                .then(response =>
                  response.data.sort(sortAccountsAlphabetically)
                )
                .catch(() => {
                  pushServerErrorToast();
                  return [];
                })
            }
            renderOption={(account, { query }) =>
              autocompleteHighlight(account.name, query)
            }
          />
        )}
        {!showThirdPartyFields && (
          <AutocompleteAjax<IAccount>
            {...formikAutocompleteAjaxProps('account', formik)}
            getOptionValue={account => account.name}
            label={
              showThirdPartyFields
                ? t('newUserModal.form.usersCompany')
                : t('newUserModal.form.company')
            }
            onFetchOptions={handleFetchAccounts}
            renderOption={(account, { query }) =>
              autocompleteHighlight(account.name, query)
            }
          />
        )}
        <SelectLanguage {...formikFieldProps('languagePreference', formik)} />
        {showThirdPartyFields && (
          <AutocompleteAjax<IAccount>
            {...formikAutocompleteAjaxProps('clientAccount', formik)}
            getOptionValue={account => account.name}
            label={t('newUserModal.form.clientAccount')}
            onFetchOptions={handleFetchAccounts}
            renderOption={(account, { query }) =>
              autocompleteHighlight(account.name, query)
            }
          />
        )}
        <Datepicker
          {...formikDatepickerProps('activationDate', formik)}
          helperText={
            activationDateRequired
              ? t('newUserModal.form.activationDateHelper')
              : undefined
          }
          label={t(
            'newUserModal.form.activationDate',
            activationDateRequired ? {} : { context: 'optional' }
          )}
          minDate={minimumActivationDate}
        />
        {showThirdPartyFields && (
          <>
            <Checkbox
              {...formikCheckboxProps('ensureThirdPartyAccess', formik)}
              label={t('newUserModal.form.ensureThirdPartyAccess')}
              value="ensureThirdPartyAccess"
            />
            <Checkbox
              {...formikCheckboxProps(
                'obtainedPermissionForThirdParty',
                formik
              )}
              label={t('newUserModal.form.obtainedPermissionForThirdParty')}
              value="obtainedPermissionForThirdParty"
            />
          </>
        )}
        <ButtonGroup>
          <Button onClick={onClose} variant={EButtonVariant.SECONDARY}>
            {t('newUserModal.cancel')}
          </Button>
          <Button
            disabled={
              !!emailError.email && emailError.email === formik.values.email
            }
            loading={request}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          >
            {t('newUserModal.request')}
          </Button>
        </ButtonGroup>
      </form>
    </Modal>
  );
};

export default NewUserRequestModal;
