import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Button,
  Card,
  EButtonSizes,
  EButtonVariant,
  Icon
} from '@ryan/components';

import { useUser } from '../../contexts/UserContext';
import { IAddress, IUser, UserType } from '../../interfaces';
import history from '../../services/history';
import formatAddress from '../../utils/formatAddress';
import getAvatarUrl from '../../utils/getAvatarUrl';
import {
  EnumsUserInfoCard,
  IRenderAddress,
  IRenderProfileSummaryDescription,
  IUserInfoCardProps,
  formatToUserInfoCard
} from './utils';

import './UserInfoCard.scss';

const UserInfoCard: React.FC<IUserInfoCardProps> = ({
  cardTitle,
  isIncludeEditButton,
  renderAdditionalOptions,
  user
}) => {
  const { t: textToDisplay } = useTranslation();
  const { isAppReadOnly, permissionService } = useUser();
  const {
    cityState,
    companyName,
    directDialNumber,
    emailAddress,
    fullName,
    guid,
    mailingAddress,
    mobilePhoneNumber,
    officeAddress,
    officePhoneNumber,
    title,
    typeId,
    ...passthroughProps
  } = formatToUserInfoCard(user);

  const avatarProps = { ...passthroughProps } as Omit<
    IUser,
    'firstName' | 'lastName'
  > & { firstName: string; lastName: string };

  const displayedOfficeNumber =
    typeId === UserType.Ryan && directDialNumber
      ? directDialNumber
      : officePhoneNumber;
  const isDisplayEditButton =
    isIncludeEditButton && permissionService.canEditClientProfile(user);

  const renderAddress: IRenderAddress = (address, iconName) => {
    const formattedAddress = formatAddress(address as IAddress);
    return (
      <React.Fragment>
        {formattedAddress && (
          <a
            className="ry-link"
            href={encodeURI(
              `https://www.google.com/maps/place/${formattedAddress}`
            )}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon name={iconName} />
            <div className="user-info-card__newline">{formattedAddress}</div>
          </a>
        )}
      </React.Fragment>
    );
  };

  const renderProfileSummaryDescription: IRenderProfileSummaryDescription = ({
    hasCardTitle,
    summaryCityState,
    summaryCompanyName,
    summaryFullName,
    summaryTitle
  }) => {
    const descriptionDetails = hasCardTitle
      ? [summaryFullName, summaryTitle]
      : [summaryTitle, summaryCompanyName];

    return (
      <div className="user-info-card__profile-summary__description">
        <h3 className="user-info-card__profile-summary__description__name">
          {descriptionDetails[0]}
        </h3>

        {descriptionDetails[1] && <h4>{descriptionDetails[1]}</h4>}

        {summaryCityState && <h4>{summaryCityState}</h4>}
      </div>
    );
  };

  return (
    <Card className="user-info-card" title={cardTitle || fullName}>
      {renderAdditionalOptions && renderAdditionalOptions()}

      <div className="user-info-card__profile-summary">
        <Avatar
          {...((avatarProps.isGhosted || !avatarProps.hasDxpAccess) && {
            variant: avatarProps.isGhosted ? 'ghost' : 'inverse'
          })}
          firstName={avatarProps.firstName}
          lastName={avatarProps.lastName}
          profileImageSrc={getAvatarUrl({
            avatarUrl: avatarProps.avatarUrl,
            userAvatarDocumentGuid: avatarProps.userAvatarDocumentGuid
          })}
        />

        {renderProfileSummaryDescription({
          hasCardTitle: cardTitle !== undefined,
          summaryCityState: cityState,
          summaryCompanyName: companyName,
          summaryFullName: fullName,
          summaryTitle: title
        })}
      </div>

      <ul>
        {officeAddress && (
          <li>{renderAddress(officeAddress, EnumsUserInfoCard.LOCATION)}</li>
        )}

        {typeId === UserType.Client && mailingAddress && (
          <li>
            {renderAddress(mailingAddress, EnumsUserInfoCard.MAILING_ADDRESS)}
          </li>
        )}

        {emailAddress && (
          <li>
            <a className="ry-link" href={`mailto:${emailAddress}`}>
              <Icon name={EnumsUserInfoCard.MAIL} />
              <span>{emailAddress}</span>
            </a>
          </li>
        )}

        {displayedOfficeNumber && (
          <li>
            <a className="ry-link" href={`tel:${displayedOfficeNumber}`}>
              <Icon name={EnumsUserInfoCard.PHONE} />
              <span>{displayedOfficeNumber}</span>
            </a>
          </li>
        )}

        {mobilePhoneNumber && (
          <li>
            <a className="ry-link" href={`tel:${mobilePhoneNumber}`}>
              <Icon name={EnumsUserInfoCard.MOBILE_DEVICE} />
              <span>{mobilePhoneNumber}</span>
            </a>
          </li>
        )}
      </ul>

      {isDisplayEditButton && (
        <Button
          className="user-info-card__edit"
          disabled={isAppReadOnly}
          icon="pencil"
          onClick={() => {
            history.push(`/app/profile/${guid}`, { selectedUser: user });
          }}
          size={EButtonSizes.SMALL}
          text={textToDisplay('userInfoCard.editProfile')}
          variant={EButtonVariant.PRIMARY}
        />
      )}
    </Card>
  );
};

export default UserInfoCard;
