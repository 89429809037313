import { CancelToken } from 'axios';

import {
  DSSDocument,
  IAudienceUser,
  IPagedDataResponse,
  IProjectTimeTravel,
  IReportEngagement,
  IReportLogo,
  IReportOptions,
  IReportRequest,
  ISearchTerm
} from '../../interfaces';
import ApiBase from './ApiBase';

//
//
// Logos
//
//

export async function getCustomViewReportLogos(
  this: ApiBase,
  customViewGuid: string
) {
  return this.server.get<IReportLogo[]>(
    `/api/customViews/${customViewGuid}/reports/logos`
  );
}

export async function uploadReportLogoFile(
  this: ApiBase,
  customViewGuid: string,
  file: DSSDocument
) {
  return this.server.post(
    `/api/customViews/${customViewGuid}/reports/logos`,
    file
  );
}

export async function deleteReportLogo(
  this: ApiBase,
  reportRequestLogoGuid: string
) {
  return this.server.delete(`/api/reports/logos/${reportRequestLogoGuid}`);
}

//
//
// Projects and Historical Projects
//
//

export async function getReportOptions(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IReportOptions>(
    `/api/customViews/${customViewGuid}/reports`,
    { cancelToken }
  );
}

export async function getEngagementsForCustomViewAndTimeTravel(
  this: ApiBase,
  customViewGuid: string,
  params: IProjectTimeTravel,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IReportEngagement>>(
    `/api/customViews/${customViewGuid}/reports/engagements`,
    { cancelToken, params }
  );
}

//
//
// Audience
//
//

export async function getAudienceWithPermissions(
  this: ApiBase,
  customViewGuid: string,
  params: ISearchTerm
) {
  return this.server.get<IAudienceUser[]>(
    `/api/customViews/${customViewGuid}/reports/audience`,
    { params }
  );
}

//
//
// Generate Report
//
//

export async function createReportRequest(
  this: ApiBase,
  request: IReportRequest,
  cancelToken?: CancelToken
) {
  return this.server.post(
    `/api/customViews/${request.customViewGuid}/reports`,
    request,
    { cancelToken }
  );
}
