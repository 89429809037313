import {
  ISavingsSummaryEntry,
  ISavingsSummaryEntryCategory,
  ISavingsSummaryEntryCategoryInitials
} from 'interfaces';
import { createTempId } from 'utils/tempId';

/**
 * Safe Math for Decimals
 */

export function sum(...nums: number[]) {
  const result = nums.reduce((result, num) => result + num * 100, 0) / 100;
  return result === 0 ? 0 : result; // no negative zeroes (-0)
}

/**
 * Stuff
 */

interface Category {
  savingsSummaryEntryCategoryGuid: string;
}

export const isSameCategory = (a: Category) => (b: Category) =>
  a.savingsSummaryEntryCategoryGuid === b.savingsSummaryEntryCategoryGuid;

export const isNotSameCategory = (a: Category) => (b: Category) =>
  !isSameCategory(a)(b);

/**
 * Create and Update Entry
 */

/**
 * Creates a new, empty category with the name provided.
 * If a guid is not provided, an integer is used.
 */
export function createCategory(
  from: string | ISavingsSummaryEntryCategoryInitials
): ISavingsSummaryEntryCategory {
  //

  let savingsSummaryEntryCategoryGuid: string;
  let name: string;
  let isEditable: boolean;
  if (typeof from === 'string') {
    savingsSummaryEntryCategoryGuid = createTempId();
    name = from;
    isEditable = true;
  } else {
    savingsSummaryEntryCategoryGuid = from.savingsSummaryEntryCategoryGuid;
    name = from.name;
    isEditable = from.isEditable;
  }

  return {
    savingsSummaryEntryCategoryGuid,
    savingsSummaryGuid: null,

    name,
    isDeleted: false,
    isEditable,

    potentialAdded: 0,
    potentialPassed: 0,
    potentialToSubmitted: 0,
    potentialToApproved: 0,
    potentialToReceived: 0,

    submittedAdded: 0,
    submittedPassed: 0,
    submittedToApproved: 0,
    submittedToReceived: 0,

    approvedAdded: 0,
    approvedPassed: 0,
    approvedToReceived: 0,

    receivedAdded: 0,
    receivedPassed: 0
  };
}

/**
 * Creates a new, empty category for an entry.
 * Returns the updated entry.
 */
export function createEntryCategory(
  entry: ISavingsSummaryEntry,
  name: string
): ISavingsSummaryEntry {
  return {
    ...entry,
    categories: [...entry.categories, createCategory(name)]
  };
}

/**
 * Updates an existing category for an entry.
 * Returns the updated entry.
 */
export function updateEntryCategory(
  entry: ISavingsSummaryEntry,
  savingsSummaryEntryCategoryGuid: string,
  updates: Partial<ISavingsSummaryEntryCategory>
): ISavingsSummaryEntry {
  return {
    ...entry,
    categories: entry.categories.map(c =>
      c.savingsSummaryEntryCategoryGuid === savingsSummaryEntryCategoryGuid
        ? { ...c, ...updates }
        : c
    )
  };
}

/**
 * For an entry, returns whether a category existed on previous entries.
 */
export function isExistingCategory(
  entry: ISavingsSummaryEntry,
  savingsSummaryEntryCategoryGuid: string
): boolean {
  return (
    entry.lastCategories !== null &&
    entry.lastCategories.some(
      c => c.savingsSummaryEntryCategoryGuid === savingsSummaryEntryCategoryGuid
    )
  );
}

/**
 * Entry Sums
 */

const getPotentialChange = (category: ISavingsSummaryEntryCategory) =>
  sum(
    category.potentialAdded,
    -category.potentialPassed,
    -category.potentialToSubmitted,
    -category.potentialToApproved,
    -category.potentialToReceived
  );

const getSubmittedChange = (category: ISavingsSummaryEntryCategory) =>
  sum(
    category.potentialToSubmitted,
    category.submittedAdded,
    -category.submittedPassed,
    -category.submittedToApproved,
    -category.submittedToReceived
  );

const getApprovedChange = (category: ISavingsSummaryEntryCategory) =>
  sum(
    category.potentialToApproved,
    category.submittedToApproved,
    category.approvedAdded,
    -category.approvedPassed,
    -category.approvedToReceived
  );

const getReceivedChange = (category: ISavingsSummaryEntryCategory) =>
  sum(
    category.potentialToReceived,
    category.submittedToReceived,
    category.approvedToReceived,
    category.receivedAdded,
    -category.receivedPassed
  );

const isPotentialUpdated = (category: ISavingsSummaryEntryCategory) =>
  category.potentialAdded !== 0 ||
  category.potentialPassed !== 0 ||
  category.potentialToSubmitted !== 0 ||
  category.potentialToApproved !== 0 ||
  category.potentialToReceived !== 0;

const isSubmittedUpdated = (category: ISavingsSummaryEntryCategory) =>
  category.submittedAdded !== 0 ||
  category.submittedPassed !== 0 ||
  category.submittedToApproved !== 0 ||
  category.submittedToReceived !== 0;

const isApprovedUpdated = (category: ISavingsSummaryEntryCategory) =>
  category.approvedAdded !== 0 ||
  category.approvedPassed !== 0 ||
  category.approvedToReceived !== 0;

const isReceivedUpdated = (category: ISavingsSummaryEntryCategory) =>
  category.receivedAdded !== 0 || category.receivedPassed !== 0;

export interface IEntryCategorySums {
  values: ISavingsSummaryEntryCategory;

  potentialChange: number;
  submittedChange: number;
  approvedChange: number;
  receivedChange: number;
  summaryChange: number;

  potentialTotal: number;
  submittedTotal: number;
  approvedTotal: number;
  receivedTotal: number;
  summaryTotal: number;

  potentialUpdated: boolean;
  submittedUpdated: boolean;
  approvedUpdated: boolean;
  receivedUpdated: boolean;
}

export interface IEntrySums {
  categories: IEntryCategorySums[];

  potentialTotal: number;
  potentialChange: number;
  potentialAdded: number;
  potentialPassed: number;
  potentialAdvancedOut: number;

  submittedTotal: number;
  submittedChange: number;
  submittedAdded: number;
  submittedPassed: number;
  submittedAdvancedOut: number;
  submittedAdvancedIn: number;

  approvedTotal: number;
  approvedChange: number;
  approvedAdded: number;
  approvedPassed: number;
  approvedAdvancedOut: number;
  approvedAdvancedIn: number;

  receivedTotal: number;
  receivedChange: number;
  receivedAdded: number;
  receivedPassed: number;
  receivedAdvancedIn: number;

  summaryTotal: number;
  summaryChange: number;
  summaryAdded: number;
  summaryPassed: number;
}

export function getEntrySums(entry: ISavingsSummaryEntry): IEntrySums {
  /**
   * Category Totals
   */
  const categories: IEntryCategorySums[] = entry.categories.map(category => {
    let initials = {
      potential: 0,
      submitted: 0,
      approved: 0,
      received: 0
    };

    if (entry.lastCategories) {
      const match = entry.lastCategories.find(
        c =>
          c.savingsSummaryEntryCategoryGuid ===
          category.savingsSummaryEntryCategoryGuid
      );

      if (match) {
        initials = {
          potential: match.potential,
          submitted: match.submitted,
          approved: match.approved,
          received: match.received
        };
      }
    }

    const potentialChange = getPotentialChange(category);
    const submittedChange = getSubmittedChange(category);
    const approvedChange = getApprovedChange(category);
    const receivedChange = getReceivedChange(category);

    const potentialTotal = sum(initials.potential, potentialChange);
    const submittedTotal = sum(initials.submitted, submittedChange);
    const approvedTotal = sum(initials.approved, approvedChange);
    const receivedTotal = sum(initials.received, receivedChange);

    const potentialUpdated = isPotentialUpdated(category);
    const submittedUpdated = isSubmittedUpdated(category);
    const approvedUpdated = isApprovedUpdated(category);
    const receivedUpdated = isReceivedUpdated(category);

    return {
      values: category,

      potentialChange,
      submittedChange,
      approvedChange,
      receivedChange,
      summaryChange: sum(
        potentialChange,
        submittedChange,
        approvedChange,
        receivedChange
      ),

      potentialTotal,
      submittedTotal,
      approvedTotal,
      receivedTotal,
      summaryTotal: sum(
        potentialTotal,
        submittedTotal,
        approvedTotal,
        receivedTotal
      ),

      potentialUpdated,
      submittedUpdated,
      approvedUpdated,
      receivedUpdated
    };
  });

  /**
   * Grand Totals
   */

  let potentialTotal = 0;
  let potentialChange = 0;
  let potentialAdded = 0;
  let potentialPassed = 0;
  let potentialAdvancedOut = 0;

  let submittedTotal = 0;
  let submittedChange = 0;
  let submittedAdded = 0;
  let submittedPassed = 0;
  let submittedAdvancedOut = 0;
  let submittedAdvancedIn = 0;

  let approvedTotal = 0;
  let approvedChange = 0;
  let approvedAdded = 0;
  let approvedPassed = 0;
  let approvedAdvancedOut = 0;
  let approvedAdvancedIn = 0;

  let receivedTotal = 0;
  let receivedChange = 0;
  let receivedAdded = 0;
  let receivedPassed = 0;
  let receivedAdvancedIn = 0;

  let summaryTotal = 0;
  let summaryChange = 0;
  let summaryAdded = 0;
  let summaryPassed = 0;

  for (let i = 0, category; i < categories.length; i++) {
    category = categories[i];

    const { isDeleted } = category.values;

    // Potential
    potentialTotal = sum(
      potentialTotal,
      isDeleted ? 0 : category.potentialTotal
    );
    potentialChange = sum(
      potentialChange,
      isDeleted ? -category.potentialTotal : category.potentialChange
    );
    potentialAdded = sum(potentialAdded, category.values.potentialAdded);
    potentialPassed = sum(
      potentialPassed,
      isDeleted ? -category.potentialTotal : -category.values.potentialPassed
    );
    potentialAdvancedOut = sum(
      potentialAdvancedOut,
      -category.values.potentialToApproved,
      -category.values.potentialToReceived,
      -category.values.potentialToSubmitted
    );

    // Submitted
    submittedTotal = sum(
      submittedTotal,
      isDeleted ? 0 : category.submittedTotal
    );
    submittedChange = sum(
      submittedChange,
      isDeleted ? -category.submittedTotal : category.submittedChange
    );
    submittedAdded = sum(submittedAdded, category.values.submittedAdded);
    submittedPassed = sum(
      submittedPassed,
      isDeleted ? -category.submittedTotal : -category.values.submittedPassed
    );
    submittedAdvancedOut = sum(
      submittedAdvancedOut,
      -category.values.submittedToApproved,
      -category.values.submittedToReceived
    );
    submittedAdvancedIn = sum(
      submittedAdvancedIn,
      category.values.potentialToSubmitted
    );

    // Approved
    approvedTotal = sum(approvedTotal, isDeleted ? 0 : category.approvedTotal);
    approvedChange = sum(
      approvedChange,
      isDeleted ? -category.approvedTotal : category.approvedChange
    );
    approvedAdded = sum(approvedAdded, category.values.approvedAdded);
    approvedPassed = sum(
      approvedPassed,
      isDeleted ? -category.approvedTotal : -category.values.approvedPassed
    );
    approvedAdvancedOut = sum(
      approvedAdvancedOut,
      -category.values.approvedToReceived
    );
    approvedAdvancedIn = sum(
      approvedAdvancedIn,
      category.values.potentialToApproved,
      category.values.submittedToApproved
    );

    // Received
    receivedTotal = sum(receivedTotal, isDeleted ? 0 : category.receivedTotal);
    receivedChange = sum(
      receivedChange,
      isDeleted ? -category.receivedTotal : category.receivedChange
    );
    receivedAdded = sum(receivedAdded, category.values.receivedAdded);
    receivedPassed = sum(
      receivedPassed,
      isDeleted ? -category.receivedTotal : -category.values.receivedPassed
    );
    receivedAdvancedIn = sum(
      receivedAdvancedIn,
      category.values.potentialToReceived,
      category.values.submittedToReceived,
      category.values.approvedToReceived
    );

    // Totals
    summaryTotal = sum(summaryTotal, isDeleted ? 0 : category.summaryTotal);
    summaryChange = sum(
      summaryChange,
      isDeleted ? -category.summaryTotal : category.summaryChange
    );
    summaryAdded = sum(
      summaryAdded,
      category.values.potentialAdded,
      category.values.submittedAdded,
      category.values.approvedAdded,
      category.values.receivedAdded
    );
    summaryPassed = sum(
      summaryPassed,
      isDeleted ? -category.potentialTotal : -category.values.potentialPassed,
      isDeleted ? -category.submittedTotal : -category.values.submittedPassed,
      isDeleted ? -category.approvedTotal : -category.values.approvedPassed,
      isDeleted ? -category.receivedTotal : -category.values.receivedPassed
    );
  }

  return {
    categories,

    potentialTotal,
    potentialChange,
    potentialAdded,
    potentialPassed,
    potentialAdvancedOut,

    submittedTotal,
    submittedChange,
    submittedAdded,
    submittedPassed,
    submittedAdvancedOut,
    submittedAdvancedIn,

    approvedTotal,
    approvedChange,
    approvedAdded,
    approvedPassed,
    approvedAdvancedOut,
    approvedAdvancedIn,

    receivedTotal,
    receivedChange,
    receivedAdded,
    receivedPassed,
    receivedAdvancedIn,

    summaryTotal,
    summaryChange,
    summaryAdded,
    summaryPassed
  };
}
