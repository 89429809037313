/**
 * Custom { transformResponse } axios example
 * https://github.com/axios/axios/blob/6a4a85c57fcaba912eee61b87ba34d07323bc60c/examples/transform-response/index.html
 */
import axios, { AxiosTransformer } from 'axios';

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

function forEachPrimitive(
  transform: (data: Primitive, key?: string) => any
): AxiosTransformer {
  return function recursive(data: any, key?: string): any {
    if (typeof data === 'object' && data !== null) {
      // transform each item in array
      if (Array.isArray(data)) {
        return data.map(item => recursive(item));
      }

      // transform each key in object
      return Object.keys(data).reduce((object, key) => {
        object[key] = recursive(data[key], key);
        return object;
      }, {} as any);
    }

    return transform(data as Primitive, key);
  };
}

/**
 * RegExp to test a string for a ISO 8601 Date spec
 *  YYYY-MM-DD
 *  YYYY-MM-DDThh:mmTZD
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
const ISO_8601 =
  /^\d{4}-\d\d-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?$/i;

const transformDates = forEachPrimitive((data: Primitive, key?: string) => {
  // HOTFIX: User may enter a value (data) that looks like a date.
  //    This is causing issues when we do not want to transform the value
  //    (I.E DisplayName of a folder or file).
  const filterKeysWithName = /Name/g;
  if (key && filterKeysWithName.test(key)) {
    return data;
  }

  // transform strings if in ISO format
  if (typeof data === 'string' && ISO_8601.test(data)) {
    // ISODATE (without time)
    if (data.length === 10) {
      const result = data.match(/^(\d{4})-(\d{2})-(\d{2})$/);
      if (result) {
        return new Date(
          parseInt(result[1], 10),
          parseInt(result[2], 10) - 1,
          parseInt(result[3], 10)
        );
      }
    }

    return new Date(Date.parse(data));
  }

  return data;
});

const transformCommandResponse: AxiosTransformer = (data: any) => {
  if (
    typeof data === 'object' &&
    'isSuccessful' in data &&
    'errors' in data &&
    'response' in data
  ) {
    return data.response;
  }

  return data;
};

/**
 *
 */

export const transformResponse = ([] as AxiosTransformer[]).concat(
  axios.defaults.transformResponse || [],
  transformCommandResponse,
  transformDates
);

export const transformResponseWithoutDates = ([] as AxiosTransformer[]).concat(
  axios.defaults.transformResponse || [],
  transformCommandResponse
);
