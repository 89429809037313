import { IActivity } from 'interfaces';

/**
 * Finds a Modified entry from a list by property name.
 */
export function findModifiedByKey(activity: IActivity, key: string) {
  const { modifiedInformation } = activity.snapShot;
  if (modifiedInformation !== null && modifiedInformation.length) {
    const match = modifiedInformation.find(m => m.key === key);
    if (match) {
      return match;
    }
  }

  throw new ReferenceError();
}
