import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import { useUser } from '../../../contexts/UserContext';
import { Feature, IFolder } from '../../../interfaces';
import Modal from '../../Modal';

import './FolderDeleteModal.scss';

interface IFolderDeleteModalProps extends WithTranslation {
  folder: IFolder | null;
  loading: Promise<any> | null;
  onSubmit: () => void;
  onCancel: () => void;
}

export const FolderDeleteModal: FunctionComponent<IFolderDeleteModalProps> = ({
  folder,
  loading,
  onSubmit,
  onCancel,
  t: getTextToDisplay
}) => {
  const { isFeatureToggled } = useUser();

  const isLockedFilesFunctionalityVisible = isFeatureToggled(
    Feature.LockedFiles
  );
  const areLockedFilesInsideFolder = (folder?.lockedFilesCount || 0) > 0;

  if (folder) {
    return (
      <Modal
        onClose={onCancel}
        open
        title={
          isLockedFilesFunctionalityVisible && areLockedFilesInsideFolder
            ? getTextToDisplay(
                'file.batchDeleteModal.lockedFiles.confirmationModalTitle'
              )
            : getTextToDisplay(
                folder.totalFileCount > 0
                  ? 'folder.deleteModal.title'
                  : 'folder.deleteModal.zeroFileTitle',
                {
                  folderName: folder.folderName,
                  totalFileCount: folder.totalFileCount
                }
              )
        }
      >
        {isLockedFilesFunctionalityVisible && areLockedFilesInsideFolder ? (
          <p>
            {getTextToDisplay(
              'file.batchDeleteModal.lockedFiles.confirmationModalDescription'
            )}
          </p>
        ) : (
          <p>
            {getTextToDisplay('folder.deleteModal.allowed.content1')}
            <br />
            {getTextToDisplay('folder.deleteModal.allowed.content2')}
          </p>
        )}
        <ButtonGroup>
          <Button
            loading={loading}
            negative
            onClick={onSubmit}
            text={
              isLockedFilesFunctionalityVisible && areLockedFilesInsideFolder
                ? getTextToDisplay(
                    'file.batchDeleteModal.lockedFiles.confirmationModalSubmitAction'
                  )
                : getTextToDisplay('Yes, Delete')
            }
          />
          <Button
            disabled={loading !== null}
            onClick={onCancel}
            text={getTextToDisplay('No, Cancel')}
            variant={EButtonVariant.SECONDARY}
          />
        </ButtonGroup>
      </Modal>
    );
  }

  return null;
};

export default withTranslation()(FolderDeleteModal);
