import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import { formatDate } from '../../../../utils/formatDate';
import Currency from '../../../Currency/Currency';
import {
  IRenderAdditionalDetailsSection,
  IUpdateSavingsBucketSummaryProps
} from './utils';

import './UpdateSavingsBucketSummary.scss';

const UpdateSavingsBucketSummary: React.FC<IUpdateSavingsBucketSummaryProps> =
  ({
    bucketName,
    savingsCurrencyCode,
    savingsLastAsOfDate,
    savingsSummaryValues
  }) => {
    const { t: textToDisplay } = useTranslation();

    const additionalDetailsSection = [
      { label: 'changeTotal', value: savingsSummaryValues.totalAddedPassed },
      { label: 'movedOutTotal', value: savingsSummaryValues.totalAdvancedOut },
      { label: 'movedInTotal', value: savingsSummaryValues.totalAdvancedIn }
    ];
    const displayBucketName = textToDisplay(`savings.${bucketName}`);
    const isNewSavingsAmountNegative = savingsSummaryValues.finalAmount < 0;
    const ROOT_TO_TEXT =
      'modal.updateSavingsBucketModal.updateSavingsBucketSummary';

    const renderAdditionalDetailsSectionDefaultProps = {
      currencyCode: savingsCurrencyCode,
      rootToText: ROOT_TO_TEXT,
      textToDisplayCallback: textToDisplay
    };

    const renderAdditionalDetailsSection: IRenderAdditionalDetailsSection = ({
      currencyCode,
      currencyValue,
      index,
      label,
      rootToText,
      textToDisplayCallback
    }) => (
      <section key={index}>
        <label>{textToDisplayCallback(`${rootToText}.${label}`)}</label>
        <div>
          <Currency
            currencyCode={currencyCode}
            includePositiveSigning
            value={currencyValue}
          />
        </div>
      </section>
    );

    return (
      <div className="update-savings-bucket-summary">
        <div className="update-savings-bucket-summary__breakdown">
          <section className="update-savings-bucket-summary__breakdown__current">
            <label>
              {textToDisplay(`${ROOT_TO_TEXT}.bucketAmountAsOf`, {
                bucketName: displayBucketName,
                date: savingsLastAsOfDate
                  ? formatDate(savingsLastAsOfDate)
                  : textToDisplay(`${ROOT_TO_TEXT}.lastEntry`)
              })}
            </label>

            <div>
              <Currency
                currencyCode={savingsCurrencyCode}
                value={savingsSummaryValues.initialAmount}
              />
            </div>
          </section>

          <section className="update-savings-bucket-summary__breakdown__changes">
            <label>
              {textToDisplay(`${ROOT_TO_TEXT}.changesInBucket`, {
                bucketName: displayBucketName
              })}
            </label>

            <div
              className={classNames({
                negative: savingsSummaryValues.totalChanges < 0,
                positive: savingsSummaryValues.totalChanges > 0
              })}
            >
              <Currency
                currencyCode={savingsCurrencyCode}
                includeArrow
                value={savingsSummaryValues.totalChanges}
              />
            </div>

            <div className="update-savings-bucket-summary__breakdown__changes__addtional-details">
              {additionalDetailsSection.map((additionalDetail, index) =>
                renderAdditionalDetailsSection({
                  ...renderAdditionalDetailsSectionDefaultProps,
                  currencyValue: additionalDetail.value,
                  index,
                  label: additionalDetail.label
                })
              )}
            </div>
          </section>

          <section
            className={classNames(
              'update-savings-bucket-summary__breakdown__updated',
              {
                'update-savings-bucket-summary__breakdown__updated--error':
                  isNewSavingsAmountNegative
              }
            )}
          >
            <label>
              {textToDisplay(`${ROOT_TO_TEXT}.updatedBucketAmount`, {
                bucketName: displayBucketName
              })}
            </label>

            <div>
              <Currency
                currencyCode={savingsCurrencyCode}
                value={savingsSummaryValues.finalAmount}
              />
              {isNewSavingsAmountNegative && <Icon name="alert" />}
            </div>
          </section>
        </div>

        {isNewSavingsAmountNegative && (
          <div className="update-savings-bucket-summary__error">
            <Icon name="error" />
            {textToDisplay(`${ROOT_TO_TEXT}.negativeAmountError`)}
          </div>
        )}
      </div>
    );
  };

export default UpdateSavingsBucketSummary;
