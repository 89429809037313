export default class EngagementAccessError extends Error {
  constructor() {
    super(
      `User is either accessing an engagement that they do not have access to` +
        ` (API should have returned 403 when fetching the engagement)` +
        ` or is missing the associated account` +
        ` (API should have included the engagement's account in identity call).`
    );
  }
}
