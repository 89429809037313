import { ICustomViewType } from 'interfaces';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import CustomViewContext, { CustomViewRole } from '../CustomViewContext';

import './CustomViewWell.scss';

/**
 * Renders information well containing current view type and role of custom view
 * bring created/edited.
 */
const CustomViewWell: React.FC = () => {
  const { t } = useTranslation();
  const { hasExecutiveAccess, viewType, role } = useContext(CustomViewContext);
  const isDynamic = viewType === ICustomViewType.Dynamic;
  const isExecutiveView = role === CustomViewRole.Executive;

  return viewType !== null || role !== null ? (
    <div className="custom-view-well well">
      {viewType !== null && (
        <>
          <label className="ry-label">{t('customView.viewType.label')}</label>
          <div>
            <Icon aria-hidden name={isDynamic ? 'venn-diagram' : 'picklist'} />
            {t(
              isDynamic
                ? 'customView.viewType.dynamic.label'
                : 'customView.viewType.static.label'
            )}
          </div>
        </>
      )}
      {hasExecutiveAccess && role !== null && (
        <>
          <label className="ry-label">{t('customView.role.label')}</label>
          <div>
            {t(
              isExecutiveView
                ? 'customView.role.executive.label'
                : 'customView.role.personal.label'
            )}
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default CustomViewWell;
