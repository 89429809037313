import debounce from 'lodash.debounce';

import classnames from 'classnames';
import { isAfter, isBefore, subMonths, subYears } from 'date-fns';
import ENV from 'env';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withSize } from 'react-sizeme';

import {
  Button,
  Dropdown,
  EButtonSizes,
  EButtonVariant,
  Icon,
  SplitButton
} from '@ryan/components';

import Currency from '../../../components/Currency/Currency';
import Datepicker from '../../../components/Datepicker';
import DocumentTitle from '../../../components/DocumentTitle';
import Attachments from '../../../components/FileAttachments/Attachments';
import InfoTooltip from '../../../components/InfoTooltip';
import EditCurrencyModal from '../../../components/Modal/EditCurrencyModal/EditCurrencyModal';
import EngagementContext from '../../../contexts/EngagementContext';
import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  IEngagement,
  IEngagementSavingsHistory,
  IEngagementSavingsHistoryEntry,
  Permission
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import { TGetSupportedISOCurrencyCodes } from '../../../services/ApiService/ApiSavings';
import { formatCurrency } from '../../../utils/formatCurrency';
import { formatDate } from '../../../utils/formatDate';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';

import './SavingsHistory.scss';

const MOBILE_BREAKPOINT = 343; // 375-(16*2)

enum TimeframeFilter {
  AsOf = 'asof',
  Custom = 'custom'
}

interface ISavingsHistoryProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps<{ engagementGuid: string }> {
  size: { width: number };
  engagement: IEngagement;
}

interface ISavingsHistoryState {
  asOfDate: Date;
  endDate: Date;
  expandedRows: { [key: string]: boolean };
  history: {
    data: IEngagementSavingsHistory;
    endDate: Date;
    isEntireHistory: boolean;
    startDate: Date | null;
    timeframe: TimeframeFilter;
  } | null;
  isEditProjectCurrencyModalOpen: boolean;
  isoCurrencyCodeOptions: TGetSupportedISOCurrencyCodes[];
  loading: boolean;
  startDate: Date;
  timeframe: TimeframeFilter;
}

export class SavingsHistory extends Component<
  ISavingsHistoryProps,
  ISavingsHistoryState
> {
  static contextType = EngagementContext;

  private source?: CancelTokenSource;

  private debounceFetchHistory = debounce(this.fetchHistory, 1000);

  private today = new Date();
  private loadingTimeout: NodeJS.Timeout | undefined;

  constructor(props: ISavingsHistoryProps) {
    super(props);

    this.state = {
      asOfDate: this.today,
      endDate: this.today,
      expandedRows: {},
      history: null,
      isEditProjectCurrencyModalOpen: false,
      isoCurrencyCodeOptions: [],
      loading: false,
      startDate: subYears(this.today, 3),
      timeframe: TimeframeFilter.AsOf
    };
  }

  componentDidMount() {
    const mainElement = document.querySelector('main');
    mainElement!.style.setProperty('position', 'relative');
    mainElement!.style.setProperty('padding-bottom', '0');

    this.handleResizeEvent();
    window.addEventListener('resize', this.handleResizeEvent);

    this.fetchEntireHistory();
  }

  componentDidUpdate(prevProps: ISavingsHistoryProps) {
    if (
      prevProps.engagement.engagementGuid !==
      this.props.engagement.engagementGuid
    ) {
      this.fetchEntireHistory();
      return;
    }

    if (
      prevProps.engagement.projectCurrency !==
      this.props.engagement.projectCurrency
    ) {
      this.fetchHistory(true);
    }
  }

  componentWillUnmount() {
    const mainElement = document.querySelector('main');
    mainElement!.style.removeProperty('position');
    mainElement!.style.removeProperty('padding-bottom');

    window.removeEventListener('resize', this.handleResizeEvent);

    this.source?.cancel();
  }

  fetchEntireHistory() {
    const { engagement } = this.props;

    this.setState(
      {
        timeframe: TimeframeFilter.AsOf,
        asOfDate: this.today,
        startDate: engagement.alternateBeginDate
          ? new Date(engagement.alternateBeginDate)
          : subMonths(this.today, 1),
        endDate: this.today
      },
      () => {
        this.fetchHistory(true);
      }
    );
  }

  handleResizeEvent() {
    const savingsFooterElementHeight = document.querySelector<HTMLElement>(
      '.savings-history__footer'
    )!.offsetHeight;

    document
      .querySelector<HTMLElement>('.savings-history__spacer')!
      .style.setProperty(
        'height',
        `calc(4rem + ${savingsFooterElementHeight}px)`
      );
  }

  async fetchISOCurrencyCodes() {
    const { data } = await ApiService.getSupportedISOCurrencyCodes();

    this.setState({ isoCurrencyCodeOptions: data });
  }

  async fetchHistory(isEntireHistory = false) {
    const { match } = this.props;
    const { timeframe } = this.state;
    const isAsOf = timeframe === TimeframeFilter.AsOf;
    const startDate = isAsOf ? null : this.state.startDate;
    const endDate = isAsOf ? this.state.asOfDate : this.state.endDate;

    this.loadingTimeout = setTimeout(() => {
      this.setState({ loading: true, history: null });
    }, 1000);

    // refresh cancel token
    this.source?.cancel();
    this.source = ApiService.CancelToken.source();

    try {
      const response = await ApiService.getEngagementSavingsHistory(
        match.params.engagementGuid,
        { startDate, endDate },
        this.source.token
      );

      const { entries, isoCurrencyCode } = response.data;

      if (
        this.state.isoCurrencyCodeOptions.length === 0 &&
        (!isoCurrencyCode || (isoCurrencyCode && entries.length === 0))
      ) {
        await this.fetchISOCurrencyCodes();
      }

      clearTimeout(this.loadingTimeout);
      this.setState({
        history: {
          timeframe,
          startDate,
          endDate,
          data: response.data,
          isEntireHistory
        },
        loading: false
      });
      return response;
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
        throw error;
      }
    }
  }

  handleTimeframeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      timeframe: e.target.value as TimeframeFilter
    });
    this.debounceFetchHistory();
  };

  handleAsOfDateChange = (_e: unknown, value: Date | null) => {
    if (value) {
      this.setState({ asOfDate: value });
      this.debounceFetchHistory();
    }
  };

  handleStartDateChange = (_e: unknown, value: Date | null) => {
    if (value) {
      this.setState({ startDate: value });
      this.debounceFetchHistory();
    }
  };

  handleEndDateChange = (_e: unknown, value: Date | null) => {
    if (value) {
      this.setState({ endDate: value });
      this.debounceFetchHistory();
    }
  };

  handleCreateEntry = () => {
    const {
      history,
      match: {
        params: { engagementGuid }
      }
    } = this.props;
    history.push(
      `/app/project/${engagementGuid}/savings-summary-entry/create`,
      { from: 'project' }
    );
  };

  handleEditEntry = (entry: IEngagementSavingsHistoryEntry) => {
    const {
      history,
      match: {
        params: { engagementGuid }
      }
    } = this.props;
    if (entry.isLatest) {
      history.push(
        `/app/project/${engagementGuid}/savings-summary-entry/update`,
        { from: 'project' }
      );
    }
  };

  handleCategoryExpand = (savingsSummaryEntryCategoryGuid: string) => {
    this.setState(({ expandedRows }) => ({
      expandedRows: {
        ...expandedRows,
        [savingsSummaryEntryCategoryGuid]:
          !expandedRows[savingsSummaryEntryCategoryGuid]
      }
    }));
  };

  isEngagementStartInRange() {
    const { history } = this.state;
    if (history) {
      const engagementStartDate = history.data.startDate;
      if (engagementStartDate) {
        return (
          isBefore(engagementStartDate, history.endDate) &&
          (history.startDate === null ||
            isAfter(engagementStartDate, history.startDate))
        );
      }
    }
    return false;
  }

  render() {
    const {
      t,
      size: { width }
    } = this.props;

    return (
      <div className="savings-history">
        <DocumentTitle title={t('savings.history.title')} />
        {width <= MOBILE_BREAKPOINT
          ? this.renderMobile()
          : this.renderDesktop()}
        <div className="savings-history__spacer"></div>
        <p className="savings-history__footer">
          *{t('savings.tooltips.total')}
        </p>

        {this.state.isEditProjectCurrencyModalOpen && (
          <EditCurrencyModal
            engagementGuid={this.state.history!.data.engagementGuid}
            isoCurrencyCode={this.state.history!.data.isoCurrencyCode || ''}
            isoCurrencyCodeOptions={this.state.isoCurrencyCodeOptions}
            onClose={() => {
              this.setState({ isEditProjectCurrencyModalOpen: false });
            }}
            onSubmit={() => {
              this.setState({ isEditProjectCurrencyModalOpen: false });
              this.context.refreshEngagement();
            }}
            projectName={this.state.history!.data.engagementDisplayNameShort}
          />
        )}
      </div>
    );
  }

  renderAttachments(entry: IEngagementSavingsHistoryEntry) {
    const {
      activeView,
      engagement: { engagementGuid, isUserGhosted }
    } = this.props;
    const { history } = this.state;

    if (!history) {
      return null;
    }

    const {
      data: { attachmentsMap }
    } = history;
    return attachmentsMap[entry.savingsSummaryGuid] ? (
      <Attachments
        attachments={attachmentsMap[entry.savingsSummaryGuid]}
        disableDownload={isUserGhosted || activeView.isExecutiveView}
        engagementGuid={engagementGuid}
      />
    ) : null;
  }

  renderMobile() {
    const { t } = this.props;
    const { history, loading } = this.state;

    return (
      <div>
        <h2 className="ry-h2">{t('savings.history.title')}</h2>
        <p>{t('savings.history.mobile1')}</p>
        <p>{t('savings.history.mobile2')}</p>
        <hr />
        <form>
          {this.renderTimeframeDropdown()}
          {this.renderTimeframeDatepickers()}
        </form>

        {loading ? (
          <section>{this.renderLoadingSpinner()}</section>
        ) : (
          history && (
            <>
              {/* Summary */}
              <section className="savings-history__mobile-summary">
                <div className="savings-history__mobile-label">
                  {this.renderSummaryLabel()}
                </div>
                <div className="well">
                  <table className="savings-history__mobile-table">
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            {t('savings.potential')}
                            <InfoTooltip>
                              {t('savings.tooltips.potential')}
                            </InfoTooltip>
                          </div>
                          {this.renderSummaryPill(
                            history.data.totals.potentialChange,
                            history.data.isoCurrencyCode
                          )}
                        </td>
                        <td>
                          <div>
                            {t('savings.submitted')}
                            <InfoTooltip>
                              {t('savings.tooltips.submitted')}
                            </InfoTooltip>
                          </div>
                          {this.renderSummaryPill(
                            history.data.totals.submittedChange,
                            history.data.isoCurrencyCode
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {t('savings.approved')}
                            <InfoTooltip>
                              {t('savings.tooltips.approved')}
                            </InfoTooltip>
                          </div>
                          {this.renderSummaryPill(
                            history.data.totals.approvedChange,
                            history.data.isoCurrencyCode
                          )}
                        </td>
                        <td>
                          <div>
                            {t('savings.received')}
                            <InfoTooltip>
                              {t('savings.tooltips.received')}
                            </InfoTooltip>
                          </div>
                          {this.renderSummaryPill(
                            history.data.totals.receivedChange,
                            history.data.isoCurrencyCode
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="savings-history__mobile-total"
                          colSpan={2}
                        >
                          <hr />
                          <div>{t('savings.history.totalChanges')}</div>
                          {this.renderSummaryPill(
                            history.data.totals.totalChange,
                            history.data.isoCurrencyCode
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              {/* Entries */}
              <section className="savings-history__mobile-entries">
                {history.data.entries.length === 0 ? (
                  t('savings.history.empty', {
                    context: history.isEntireHistory ? 'entirely' : undefined
                  })
                ) : (
                  <ul>
                    {history.data.entries.map(entry => (
                      <li key={entry.savingsSummaryGuid}>
                        <div className="savings-history__mobile-label">
                          {this.renderEntryLabel(entry)}
                        </div>
                        <div
                          className={classnames({
                            'savings-history__entry': true,
                            'savings-history__entry--only-external':
                              entry.updateSource === 2,
                            'savings-history__entry--with-external':
                              entry.updateSource === 3
                          })}
                        >
                          {this.renderEntryDescription(history.data, entry)}
                          <table className="savings-history__mobile-table">
                            <tbody>
                              <tr>
                                <td>
                                  <label className="ry-label">
                                    {t('savings.potential')}
                                  </label>
                                  {this.renderEntryTotalAndChange(
                                    entry.totals.potential,
                                    entry.totals.potentialChange,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                                <td>
                                  <label className="ry-label">
                                    {t('savings.submitted')}
                                  </label>
                                  {this.renderEntryTotalAndChange(
                                    entry.totals.submitted,
                                    entry.totals.submittedChange,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label className="ry-label">
                                    {t('savings.approved')}
                                  </label>
                                  {this.renderEntryTotalAndChange(
                                    entry.totals.approved,
                                    entry.totals.approvedChange,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                                <td>
                                  <label className="ry-label">
                                    {t('savings.received')}
                                  </label>
                                  {this.renderEntryTotalAndChange(
                                    entry.totals.received,
                                    entry.totals.receivedChange,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="savings-history__mobile-total"
                                  colSpan={2}
                                >
                                  <hr />
                                  <label className="ry-label">
                                    {t('Total')}
                                  </label>
                                  {this.renderEntryTotalAndChange(
                                    entry.totals.total,
                                    entry.totals.totalChange,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {this.renderAttachments(entry)}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </section>

              {/* Kickoff */}
              {history.data.entries.length > 0 &&
                this.isEngagementStartInRange() && (
                  <section className="savings-history__mobile-kickoff">
                    <div className="savings-history__mobile-label">
                      {t('Kickoff')}
                    </div>
                    <div className="savings-history__kickoff">
                      {this.renderKickoffText(history.data)}
                    </div>
                  </section>
                )}
            </>
          )
        )}
      </div>
    );
  }

  renderDesktop() {
    const { t, isAppReadOnly, permissionService: ps, engagement } = this.props;
    const { history, expandedRows, loading } = this.state;
    const isEditDisabled = engagement.isReadOnly || isAppReadOnly;

    const hasEntries = (history && history.data.entries.length > 0) || false;
    const hasISOCurrency =
      (history && history.data.isoCurrencyCode != null) || false;

    return (
      <div>
        <div className="savings-history__overflow">
          <table className="savings-history__table">
            <tbody>
              {/* Header, Date Filter */}
              <tr className="savings-history__header">
                <td className="savings-history__table-left">
                  {t('savings.history.title')}
                </td>
                <td className="savings-history__table-content">
                  <div className="savings-history__header-form">
                    {this.renderTimeframeDropdown()}
                    {this.renderTimeframeDatepickers()}
                    <div className="savings-history__header-space" />
                    {history &&
                      ps.hasPermission(Permission.SavingsSummaryEdit) && (
                        <>
                          {!hasISOCurrency && (
                            <Button
                              disabled={isEditDisabled}
                              onClick={() => {
                                this.setState({
                                  isEditProjectCurrencyModalOpen: true
                                });
                              }}
                              text={t('savings.history.setCurrency')}
                              variant={EButtonVariant.PRIMARY}
                            />
                          )}

                          {hasISOCurrency && hasEntries && (
                            <Button
                              disabled={isEditDisabled}
                              icon="plus"
                              onClick={this.handleCreateEntry}
                              text={t('savings.history.newSavingsUpdate')}
                              variant={EButtonVariant.PRIMARY}
                            />
                          )}

                          {hasISOCurrency && !hasEntries && (
                            <SplitButton
                              block
                              disabled={isEditDisabled}
                              onClick={this.handleCreateEntry}
                              options={[
                                {
                                  disabled: isEditDisabled,
                                  icon: 'pencil',
                                  label: t(
                                    'savings.history.editProjectCurrency'
                                  ),
                                  onClick: () => {
                                    this.setState({
                                      isEditProjectCurrencyModalOpen: true
                                    });
                                  }
                                }
                              ]}
                              text={t('savings.history.newSavingsUpdate')}
                              variant={EButtonVariant.PRIMARY}
                            />
                          )}
                        </>
                      )}
                  </div>
                </td>
              </tr>

              {/* Summary */}
              {loading ? (
                <tr>
                  <td colSpan={2}>{this.renderLoadingSpinner()}</td>
                </tr>
              ) : (
                history && (
                  <tr>
                    <td className="savings-history__table-left">
                      {this.renderSummaryLabel()}
                    </td>
                    <td className="savings-history__table-content">
                      <div className="savings-history__summary">
                        <table className="savings-history__summary-table">
                          {/* Summary - Headers */}
                          <thead>
                            <tr className="savings-history__summary-headers">
                              <th />
                              <th>
                                {t('savings.potential')}
                                <InfoTooltip>
                                  {t('savings.tooltips.potential')}
                                </InfoTooltip>
                              </th>
                              <th>
                                {t('savings.submitted')}
                                <InfoTooltip>
                                  {t('savings.tooltips.submitted')}
                                </InfoTooltip>
                              </th>
                              <th>
                                {t('savings.approved')}
                                <InfoTooltip>
                                  {t('savings.tooltips.approved')}
                                </InfoTooltip>
                              </th>
                              <th>
                                {t('savings.received')}
                                <InfoTooltip>
                                  {t('savings.tooltips.received')}
                                </InfoTooltip>
                              </th>
                              <th className="savings-history__summary-divider" />
                              <th>{t('savings.history.totalChanges')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Summary - Total Changes */}
                            <tr className="savings-history__summary-changes">
                              <td />
                              {[
                                history.data.totals.potentialChange,
                                history.data.totals.submittedChange,
                                history.data.totals.approvedChange,
                                history.data.totals.receivedChange
                              ].map((value, i) => (
                                <td key={i}>
                                  {this.renderSummaryPill(
                                    value,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              ))}
                              <td
                                className="savings-history__summary-divider"
                                rowSpan={5}
                              />
                              <td className="savings-history__summary-total">
                                {this.renderSummaryPill(
                                  history.data.totals.totalChange,
                                  history.data.isoCurrencyCode
                                )}
                              </td>
                            </tr>

                            {/* Summary - Added */}
                            <tr>
                              <td>{t('savings.added')}</td>
                              {[
                                history.data.totals.potentialAdded,
                                history.data.totals.submittedAdded,
                                history.data.totals.approvedAdded,
                                history.data.totals.receivedAdded
                              ].map((value, i) => (
                                <td key={i}>
                                  {this.renderSignedValue(
                                    value,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              ))}
                              <td className="savings-history__summary-total">
                                {this.renderSignedValue(
                                  history.data.totals.totalAdded,
                                  history.data.isoCurrencyCode
                                )}
                              </td>
                            </tr>

                            {/* Summary - Passed */}
                            <tr>
                              <td>{t('savings.passed')}</td>
                              {[
                                history.data.totals.potentialPassed,
                                history.data.totals.submittedPassed,
                                history.data.totals.approvedPassed,
                                history.data.totals.receivedPassed
                              ].map((value, i) => (
                                <td key={i}>
                                  {this.renderSignedValue(
                                    value,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              ))}
                              <td className="savings-history__summary-total">
                                {this.renderSignedValue(
                                  history.data.totals.totalPassed,
                                  history.data.isoCurrencyCode
                                )}
                              </td>
                            </tr>

                            {/* Summary - Advanced Out */}
                            <tr>
                              <td>
                                {t('savings.advancedOut')}
                                <InfoTooltip>
                                  {t('savings.tooltips.advancedOut')}
                                </InfoTooltip>
                              </td>
                              {[
                                history.data.totals.potentialAdvancedOut,
                                history.data.totals.submittedAdvancedOut,
                                history.data.totals.approvedAdvancedOut
                              ].map((value, i) => (
                                <td key={i}>
                                  {this.renderSignedValue(
                                    value,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              ))}
                              <td />
                              <td />
                            </tr>

                            {/* Summary - Advanced In */}
                            <tr>
                              <td>
                                {t('savings.advancedIn')}
                                <InfoTooltip>
                                  {t('savings.tooltips.advancedIn')}
                                </InfoTooltip>
                              </td>
                              <td />
                              {[
                                history.data.totals.submittedAdvancedIn,
                                history.data.totals.approvedAdvancedIn,
                                history.data.totals.receivedAdvancedIn
                              ].map((value, i) => (
                                <td key={i}>
                                  {this.renderSignedValue(
                                    value,
                                    history.data.isoCurrencyCode
                                  )}
                                </td>
                              ))}
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
            <tbody className="savings-history__table-timeline">
              {/* Entries */}
              {history &&
                history.data.entries.map(entry => {
                  const expanded = expandedRows[entry.savingsSummaryGuid];
                  return (
                    <tr key={entry.savingsSummaryGuid}>
                      <td className="savings-history__table-left">
                        {this.renderEntryLabel(entry)}
                      </td>
                      <td className="savings-history__table-content">
                        <div
                          className={classnames({
                            'savings-history__entry': true,
                            'savings-history__entry--only-external':
                              entry.updateSource === 2,
                            'savings-history__entry--with-external':
                              entry.updateSource === 3
                          })}
                        >
                          <Icon
                            className="savings-history__entry-icon"
                            name="file-pencil"
                          />
                          {entry.isLatest &&
                            ps.hasPermission(Permission.SavingsSummaryEdit) && (
                              <Button
                                className="savings-history__entry-edit"
                                disabled={
                                  isEditDisabled ||
                                  !(
                                    entry.categories.length === 0 ||
                                    entry.categories.some(
                                      // TODO: Store PropertyPoint GUID in UI?
                                      category =>
                                        category.sourceApplicationGuid !==
                                        'b9e64f01-5e28-4397-bdb7-a1174f0b9125'
                                    )
                                  )
                                }
                                icon="pencil"
                                onClick={() => this.handleEditEntry(entry)}
                                size={EButtonSizes.SMALL}
                                text={t('Edit')}
                                variant={EButtonVariant.TEXT}
                              />
                            )}
                          {this.renderEntryDescription(history.data, entry)}
                          {entry.commentText && (
                            <div className="savings-history__entry-comment">
                              <div className="savings-history__entry-comment-title">
                                {t('savings.updateNotes')}
                              </div>
                              <p>{entry.commentText}</p>
                            </div>
                          )}
                          <table className="savings-history__entry-table">
                            <thead>
                              <tr>
                                <th />
                                <th>{t('savings.potential')}</th>
                                <th>{t('savings.submitted')}</th>
                                <th>{t('savings.approved')}</th>
                                <th>{t('savings.received')}</th>
                                <th>{t('Total')}</th>
                              </tr>
                            </thead>

                            {/* Categories */}
                            {entry.categories.map((category, i) => {
                              const expanded =
                                expandedRows[
                                  category.savingsSummaryEntryCategoryGuid
                                ];
                              return (
                                <tbody
                                  className={classnames({
                                    'savings-history__entry-row': true,
                                    'savings-history__entry-category': true,
                                    'savings-history__entry-category--deleted':
                                      category.isDeleted
                                  })}
                                  key={category.savingsSummaryEntryCategoryGuid}
                                >
                                  {/* Category - Totals, Changes */}
                                  <tr className="savings-history__entry-row-totals">
                                    <td>
                                      <button
                                        className="savings-history__entry-row-toggle"
                                        onClick={() =>
                                          this.handleCategoryExpand(
                                            category.savingsSummaryEntryCategoryGuid
                                          )
                                        }
                                      >
                                        <Icon
                                          name={
                                            expanded
                                              ? 'chevron-up'
                                              : 'chevron-down'
                                          }
                                        />
                                        <span className="savings-history__entry-category-name">
                                          {category.category}
                                        </span>
                                        {category.priorCategory && (
                                          <span className="savings-history__entry-category-previous-name">
                                            {t(
                                              'savings.history.previousCategoryName',
                                              {
                                                name: category.priorCategory
                                              }
                                            )}
                                          </span>
                                        )}
                                        {category.isDeleted && (
                                          <span className="savings-history__entry-category-deleted">
                                            {t(
                                              'savings.history.deletedCategory'
                                            )}
                                          </span>
                                        )}
                                      </button>
                                    </td>
                                    {[
                                      [
                                        category.potential,
                                        category.potentialChange
                                      ],
                                      [
                                        category.submitted,
                                        category.submittedChange
                                      ],
                                      [
                                        category.approved,
                                        category.approvedChange
                                      ],
                                      [
                                        category.received,
                                        category.receivedChange
                                      ],
                                      [category.total, category.totalChange]
                                    ].map(([total, change], j) => (
                                      <td key={j}>
                                        {this.renderCategoryTotalAndChange(
                                          total,
                                          change,
                                          history.data.isoCurrencyCode,
                                          expanded,
                                          i === 0
                                        )}
                                      </td>
                                    ))}
                                  </tr>

                                  {expanded && (
                                    <>
                                      {/* Category - Added */}
                                      <tr>
                                        <td>{t('savings.added')}</td>
                                        {[
                                          category.potentialAdded,
                                          category.submittedAdded,
                                          category.approvedAdded,
                                          category.receivedAdded,
                                          category.totalAdded
                                        ].map((value, i) => (
                                          <td key={i}>
                                            {this.renderSignedValue(
                                              value,
                                              history.data.isoCurrencyCode
                                            )}
                                          </td>
                                        ))}
                                      </tr>

                                      {/* Category - Passed */}
                                      <tr>
                                        <td>{t('savings.passed')}</td>
                                        {[
                                          category.potentialPassed,
                                          category.submittedPassed,
                                          category.approvedPassed,
                                          category.receivedPassed,
                                          category.totalPassed
                                        ].map((value, i) => (
                                          <td key={i}>
                                            {this.renderSignedValue(
                                              value,
                                              history.data.isoCurrencyCode
                                            )}
                                          </td>
                                        ))}
                                      </tr>

                                      {/* Category - Advanced Out */}
                                      <tr>
                                        <td>{t('savings.advancedOut')}</td>
                                        {[
                                          category.potentialAdvancedOut,
                                          category.submittedAdvancedOut,
                                          category.approvedAdvancedOut
                                        ].map((value, i) => (
                                          <td key={i}>
                                            {this.renderSignedValue(
                                              value,
                                              history.data.isoCurrencyCode
                                            )}
                                          </td>
                                        ))}
                                        <td />
                                        <td />
                                      </tr>

                                      {/* Category - Advanced In */}
                                      <tr>
                                        <td>{t('savings.advancedIn')}</td>
                                        <td />
                                        {[
                                          category.submittedAdvancedIn,
                                          category.approvedAdvancedIn,
                                          category.receivedAdvancedIn
                                        ].map((value, i) => (
                                          <td key={i}>
                                            {this.renderSignedValue(
                                              value,
                                              history.data.isoCurrencyCode
                                            )}
                                          </td>
                                        ))}
                                        <td />
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              );
                            })}

                            {/* Totals */}
                            <tbody className="savings-history__entry-totals">
                              {/* Totals - Totals, Changes  */}
                              <tr className="savings-history__entry-row-totals">
                                <td>
                                  <button
                                    className="savings-history__entry-row-toggle"
                                    onClick={() =>
                                      this.handleCategoryExpand(
                                        entry.savingsSummaryGuid
                                      )
                                    }
                                  >
                                    <Icon
                                      name={
                                        expanded ? 'chevron-up' : 'chevron-down'
                                      }
                                    />
                                    <div className="savings-history__entry-totals-label">
                                      {t('Total')}*
                                    </div>
                                    <div className="savings-history__entry-totals-changes-label">
                                      {entry.priorAsOfDate
                                        ? t('savings.entry.changesSince', {
                                            date: formatDate(
                                              entry.priorAsOfDate.toISOString()
                                            )
                                          })
                                        : t(
                                            'savings.entry.changesSinceProjectKickoff'
                                          )}
                                    </div>
                                  </button>
                                </td>
                                {[
                                  [
                                    entry.totals.potential,
                                    entry.totals.potentialChange
                                  ],
                                  [
                                    entry.totals.submitted,
                                    entry.totals.submittedChange
                                  ],
                                  [
                                    entry.totals.approved,
                                    entry.totals.approvedChange
                                  ],
                                  [
                                    entry.totals.received,
                                    entry.totals.receivedChange
                                  ],
                                  [entry.totals.total, entry.totals.totalChange]
                                ].map(([total, change], i) => (
                                  <td key={i}>
                                    {this.renderEntryTotalAndChange(
                                      total,
                                      change,
                                      history.data.isoCurrencyCode
                                    )}
                                  </td>
                                ))}
                              </tr>

                              {expanded && (
                                <>
                                  {/* Totals - Added */}
                                  <tr>
                                    <td>{t('savings.added')}</td>
                                    {[
                                      entry.totals.potentialAdded,
                                      entry.totals.submittedAdded,
                                      entry.totals.approvedAdded,
                                      entry.totals.receivedAdded,
                                      entry.totals.totalAdded
                                    ].map((value, i) => (
                                      <td key={i}>
                                        {this.renderSignedValue(
                                          value,
                                          history.data.isoCurrencyCode
                                        )}
                                      </td>
                                    ))}
                                  </tr>

                                  {/* Totals - Passed */}
                                  <tr>
                                    <td>{t('savings.passed')}</td>
                                    {[
                                      entry.totals.potentialPassed,
                                      entry.totals.submittedPassed,
                                      entry.totals.approvedPassed,
                                      entry.totals.receivedPassed,
                                      entry.totals.totalPassed
                                    ].map((value, i) => (
                                      <td key={i}>
                                        {this.renderSignedValue(
                                          value,
                                          history.data.isoCurrencyCode
                                        )}
                                      </td>
                                    ))}
                                  </tr>

                                  {/* Totals - Advanced Out */}
                                  <tr>
                                    <td>{t('savings.advancedOut')}</td>
                                    {[
                                      entry.totals.potentialAdvancedOut,
                                      entry.totals.submittedAdvancedOut,
                                      entry.totals.approvedAdvancedOut
                                    ].map((value, i) => (
                                      <td key={i}>
                                        {this.renderSignedValue(
                                          value,
                                          history.data.isoCurrencyCode
                                        )}
                                      </td>
                                    ))}
                                    <td />
                                    <td />
                                  </tr>

                                  {/* Totals - Advanced In */}
                                  <tr>
                                    <td>{t('savings.advancedIn')}</td>
                                    <td />
                                    {[
                                      entry.totals.submittedAdvancedIn,
                                      entry.totals.approvedAdvancedIn,
                                      entry.totals.receivedAdvancedIn
                                    ].map((value, i) => (
                                      <td key={i}>
                                        {this.renderSignedValue(
                                          value,
                                          history.data.isoCurrencyCode
                                        )}
                                      </td>
                                    ))}
                                    <td />
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                          {this.renderAttachments(entry)}
                        </div>
                      </td>
                    </tr>
                  );
                })}

              {/* Empty state */}
              {history && history.data.entries.length === 0 && (
                <tr>
                  <td />
                  <td className="savings-history__empty">
                    {t('savings.history.empty', {
                      context: history.isEntireHistory ? 'entirely' : undefined
                    })}
                  </td>
                </tr>
              )}

              {/* Kickoff */}
              {history &&
                history.data.entries.length !== 0 &&
                this.isEngagementStartInRange() && (
                  <tr>
                    <td className="savings-history__table-left savings-history__table-left--oneline">
                      {t('Kickoff')}
                    </td>
                    <td className="savings-history__table-content">
                      <div className="savings-history__kickoff">
                        <Icon
                          className="savings-history__kickoff-icon"
                          name="add-circle"
                        />
                        {this.renderKickoffText(history.data)}
                      </div>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderLoadingSpinner() {
    return (
      <div className="center-icon">
        <Icon className="loading-spin" name="loading" />
      </div>
    );
  }

  renderTimeframeDropdown() {
    const { t } = this.props;
    const { timeframe } = this.state;
    return (
      <Dropdown
        label={t('savings.history.timeframe')}
        onChange={this.handleTimeframeChange}
        options={[
          {
            label: t('savings.history.totalAsOf'),
            value: TimeframeFilter.AsOf
          },
          {
            label: t('savings.history.dateRange'),
            value: TimeframeFilter.Custom
          }
        ]}
        value={timeframe}
      />
    );
  }

  renderTimeframeDatepickers() {
    const { t } = this.props;
    const { timeframe, asOfDate, startDate, endDate } = this.state;
    const isAsOf = timeframe === TimeframeFilter.AsOf;

    return (
      <>
        {!isAsOf && (
          <Datepicker
            label={t('Start Date')}
            maxDate={endDate || undefined}
            onChange={this.handleStartDateChange}
            value={startDate}
          />
        )}
        <Datepicker
          label={t(isAsOf ? 'As of' : 'End Date')}
          maxDate={this.today}
          minDate={isAsOf ? undefined : startDate}
          onChange={
            isAsOf ? this.handleAsOfDateChange : this.handleEndDateChange
          }
          value={isAsOf ? asOfDate : endDate}
        />
      </>
    );
  }

  renderSummaryLabel() {
    const { t } = this.props;
    const { history } = this.state;
    if (history) {
      return (
        <>
          {t('savings.history.changeOverviewByStage')}
          <b>
            {history.timeframe === TimeframeFilter.AsOf
              ? t('Kickoff')
              : formatDate(history.startDate!.toISOString())}
            {' - '}
            {formatDate(history.endDate.toISOString())}
          </b>
        </>
      );
    }
    return null;
  }

  renderSummaryPill(value: number, currencyCode: string | null) {
    return (
      <div
        className={classnames({
          'savings-history__summary-pill': true,
          'savings-history__summary-pill--up': value > 0,
          'savings-history__summary-pill--down': value < 0
        })}
      >
        <Currency
          currencyCode={currencyCode}
          includeArrow="left"
          value={value}
        />
      </div>
    );
  }

  renderEntryLabel(entry: IEngagementSavingsHistoryEntry) {
    const { t } = this.props;
    const asOfDate = formatDate(entry.asOfDate.toISOString());

    if (entry.isBaseline) {
      return (
        <>
          <Trans i18nKey="savings.history.startingBaseline">
            <b />
            {{ asOfDate }}
          </Trans>
          <InfoTooltip>
            {t('savings.history.startingBaselineTooltip', {
              ryanPlatform: ENV.RYAN_PLATFORM
            })}
          </InfoTooltip>
        </>
      );
    }

    return (
      <>
        {t('savings.savingsAsOf')}
        <b>{asOfDate}</b>
      </>
    );
  }

  renderEntryDescription(
    historyData: IEngagementSavingsHistory,
    entry: IEngagementSavingsHistoryEntry
  ) {
    const {
      i18n,
      t,
      user: {
        profile: { userGuid }
      }
    } = this.props;
    return (
      <div className="savings-history__entry-description">
        <Trans i18nKey="savings.history.entryDescription">
          <i />
          <b />
          {{
            engagement: historyData.engagementDisplayNameShort
          }}
          {{
            total: formatCurrency(
              entry.totals.total,
              i18n.language,
              historyData.isoCurrencyCode
            )
          }}
          {{
            updateDate: entry.updateDate
              ? formatDate(entry.updateDate.toISOString())
              : formatDate(entry.createDate.toISOString())
          }}
          {{
            updatedByUser: entry.updatedBy
              ? entry.updatedBy === userGuid
                ? t('You')
                : entry.updatedByName
              : entry.createdBy === userGuid
              ? t('You')
              : entry.createdByName
          }}
        </Trans>
      </div>
    );
  }

  renderEntryTotalAndChange(
    total: number,
    change: number,
    currencyCode: string | null
  ) {
    return (
      <>
        <Currency
          className="savings-history__entry-totals-total"
          currencyCode={currencyCode}
          value={total}
        />
        <Currency
          className={classnames({
            'savings-history__entry-row-change': true,
            'savings-history__entry-row-change--up': change > 0,
            'savings-history__entry-row-change--down': change < 0
          })}
          currencyCode={currencyCode}
          includeArrow="left"
          value={change}
        />
      </>
    );
  }

  renderCategoryTotalAndChange(
    total: number,
    change: number,
    currencyCode: string | null,
    expanded: boolean,
    includeSymbol: boolean
  ) {
    return (
      <>
        <Currency
          className="savings-history__entry-row-total"
          currencyCode={currencyCode}
          includeSymbol={includeSymbol}
          value={total}
        />
        {expanded && (
          <Currency
            className={classnames({
              'savings-history__entry-row-change': true,
              'savings-history__entry-row-change--up': change > 0,
              'savings-history__entry-row-change--down': change < 0
            })}
            currencyCode={currencyCode}
            includeArrow="left"
            value={change}
          />
        )}
      </>
    );
  }

  renderSignedValue(value: number, currencyCode: string | null) {
    return (
      <Currency
        currencyCode={currencyCode}
        includePositiveSigning
        value={value}
      />
    );
  }

  renderKickoffText(historyData: IEngagementSavingsHistory) {
    // prettier-ignore
    return (
      <Trans i18nKey="savings.history.kickoffText">
        <i />
        {{ engagement: historyData.engagementDisplayNameShort }}
      </Trans>
    );
  }
}

export default withTranslation()(withUser(withSize()(SavingsHistory)));
