import { useContext } from 'react';
import { withContext } from 'utils/withContext';
import { IUserContext, UserContext } from './UserContext';

/**
 * Context Consumer, aliased for convenience.
 */
export const UserConsumer = UserContext.Consumer;

/**
 * HOC to wrap a component to receive WithUser.
 */
export type WithUser = IUserContext;
export const withUser = withContext(UserContext, 'withUser');

/**
 * Hook
 */
export const useUser = () => useContext(UserContext);
