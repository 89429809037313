import {
  DirectoryItemType,
  IDirectoryFile,
  IDirectoryItem,
  IFolder
} from 'interfaces';

export default function mapDirectoryItem<R>({
  directoryItem,
  handleMapForFile,
  handleMapForFolder
}: {
  directoryItem: IDirectoryItem;
  handleMapForFolder?: (folder: IFolder) => R;
  handleMapForFile?: (folder: IDirectoryFile) => R;
}) {
  return directoryItem.type === DirectoryItemType.File
    ? handleMapForFile && handleMapForFile(directoryItem.item as IDirectoryFile)
    : handleMapForFolder && handleMapForFolder(directoryItem.item as IFolder);
}
