import React, { FunctionComponent } from 'react';

export function withContext<C>(
  Context: React.Context<C>,
  name = 'withContext'
) {
  return function <P extends C>(WrappedComponent: React.ComponentType<P>) {
    const EnhancedComponent: FunctionComponent<Omit<P, keyof C>> & {
      WrappedComponent: React.ComponentType<P>;
    } = props => (
      <Context.Consumer>
        {context => <WrappedComponent {...(props as any)} {...context} />}
      </Context.Consumer>
    );

    EnhancedComponent.displayName = `${name}(${
      WrappedComponent.displayName || 'Component'
    })`;
    EnhancedComponent.WrappedComponent = WrappedComponent;
    return EnhancedComponent;
  };
}
