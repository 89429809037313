import { getFormattedUserInfo } from '../../../../utils/';
import { IFormatToUserConversionModal } from '../IUserConversionModal';

export const formatToUserConversionModal: IFormatToUserConversionModal =
  user => {
    return {
      isActiveUserWithEditAccess: user.canCurrentUserEdit || false,
      isActiveUserWithNewUserApprovalAccess:
        user.canCurrentUserApproveNurConversion || false,
      userEmail: user.email,
      userFirstName: user.firstName || '',
      userFullName: getFormattedUserInfo('fullName', user),
      userGuid: user.userGuid,
      userLastName: user.lastName || '',
      userMemberGuid: user.memberGuid,
      userNewUserRequestDate:
        user.newUserRequestDate || new Date().toISOString(),
      userType: user.userTypeId
    };
  };
