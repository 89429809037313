import { EButtonSizes, EButtonVariant } from '@ryan/components';

import { IconEnums } from '../../../utils/enums/IconEnums';
import { IColumnTemplateMapper, IGetCommentProps } from './columns.interfaces';

export const columnTemplateMapper: IColumnTemplateMapper = ({
  columnTemplate,
  columnsToRenderMap,
  getTextToDisplayCallback,
  rootToText
}) =>
  columnTemplate.map(({ isCommonColumn, ...column }) => {
    const rootPath = isCommonColumn ? 'table.columns.common' : rootToText;

    return {
      ...column,
      label: column.label
        ? getTextToDisplayCallback(`${rootPath}.${column.label}`)
        : '',
      render: columnsToRenderMap[column.id as keyof typeof columnsToRenderMap]
    };
  });

export const getCommentProps: IGetCommentProps = ({
  commentCount,
  isReadOnlyByPermissions,
  isReadOnlyByState
}) => {
  const buttonProps = {
    'aria-haspopup': true,
    className: 'comment-button',
    size: EButtonSizes.SMALL,
    variant: EButtonVariant.TEXT
  };
  const hasComments = commentCount > 0;
  const icon = hasComments ? IconEnums.COMMENT_ALERT : IconEnums.COMMENT;
  const tooltipProps = { placement: 'top' as any };

  let path = hasComments ? 'commentsWithCount' : 'addAComment';

  if (isReadOnlyByState || isReadOnlyByPermissions) {
    path = 'viewCommentsWithCount';
  }

  return {
    buttonProps,
    icon,
    path,
    tooltipProps
  };
};
