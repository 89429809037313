import { formatDate } from 'utils/formatDate';
import { getLocale } from 'utils/i18n';

import classnames from 'classnames';
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  getDate,
  getDaysInMonth,
  parseISO
} from 'date-fns';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withSize } from 'react-sizeme';

import { Icon } from '@ryan/components';

import './ScrollingCalendar.scss';

/**
 * Switch to mobile layout (skip rendering calendar) at
 * magic number 736, which is 768px minus default padding and margins.
 * @todo Magic number could depend on props.months.
 */
export const SCOLLING_CALENDAR_MIN_WIDTH = 736;

function getOffset(
  startTime: number | Date,
  offsetTime: number | Date,
  months: number
) {
  return (
    (differenceInCalendarMonths(offsetTime, startTime) +
      (getDate(offsetTime) - 1) / (getDaysInMonth(offsetTime) - 1)) *
    (100 / months)
  );
}

interface IScrollingCalendarProps extends WithTranslation {
  size: { width: number };
  loading: boolean;
  startDate: Date;
  months: number;
  onBackOneMonth?: () => void;
  onForwardOneMonth?: () => void;

  render: (props: {
    isMobile: boolean;
    getOffsets: (
      startTime: Date | string | number,
      endTime: Date | string | number
    ) => {
      left: number;
      right: number;
      isVisible: boolean;
      isOverflowingLeft: boolean;
      isOverflowingRight: boolean;
    };
  }) => React.ReactNode;
}

export class ScrollingCalendar extends Component<IScrollingCalendarProps> {
  getOffsets = (start: Date | number | string, end: Date | number | string) => {
    const { startDate: calendarStart, months } = this.props;
    const offsetStart = typeof start === 'string' ? parseISO(start) : start;
    const offsetEnd = typeof end === 'string' ? parseISO(end) : end;
    const left = getOffset(calendarStart, offsetStart, months);
    const right = 100 - getOffset(calendarStart, offsetEnd, months);
    const isOverflowingLeft = left < 0;
    const isOverflowingRight = right < 0;
    return {
      left: isOverflowingLeft ? 0 : left,
      right: isOverflowingRight ? 0 : right,
      isOverflowingLeft,
      isOverflowingRight,
      isVisible: left < 100 && right < 100
    };
  };

  render() {
    const { getOffsets } = this;
    const {
      size: { width },
      render
    } = this.props;

    if (width < SCOLLING_CALENDAR_MIN_WIDTH) {
      return render({ isMobile: true, getOffsets });
    }

    return this.renderCalendar();
  }

  renderCalendar() {
    const { getOffsets } = this;

    const {
      loading,
      startDate,
      months,
      onBackOneMonth,
      onForwardOneMonth,
      render,
      size: { width },
      i18n
    } = this.props;

    const monthPercent = 100 / months;
    const monthLabels = [];
    for (let i = 0, month = startDate; i < months; i++) {
      let label = format(month, 'MMM', { locale: getLocale(i18n.language) });
      // append year if beginning of chart or beginning of year
      if (i === 0 || month.getMonth() === 0) {
        label = `${label} '${month.getFullYear().toString().slice(-2)}`;
      }
      monthLabels.push(label.replace(/\./, ''));
      month = addMonths(month, 1);
    }

    const startTime = startDate.getTime();
    const nowDate = new Date();
    const now = nowDate.getTime();
    const hasNowLine =
      startTime <= now && now <= addMonths(startDate, months).getTime() - 1;
    const hasPrevAndNextButtons = onBackOneMonth && onForwardOneMonth;

    const nowLineOffset = getOffset(startTime, now, months);

    // We're cheating a bit here, but the label is ~60px wide.
    const labelBuffer = (30 / width) * 100; // Calculating half of the label width
    const labelOverflowsLeft = nowLineOffset - labelBuffer <= 0;
    const labelOverflowsRight = nowLineOffset + labelBuffer >= 100;
    const labelTransform = `translateX(${
      labelOverflowsLeft ? '-6px' : labelOverflowsRight ? '-96%' : '-50%'
    })`;

    return (
      <div
        className={classnames({
          'scrolling-calendar': true,
          'scrolling-calendar--navigable': hasPrevAndNextButtons,
          'scrolling-calendar--loading': loading
        })}
      >
        <div className="scrolling-calendar__header">
          {hasPrevAndNextButtons && (
            <button onClick={onBackOneMonth}>
              <Icon name="chevron-left" />
            </button>
          )}
          <div className="scrolling-calendar__header-months">
            {monthLabels.map((label, i) => (
              <div
                className="scrolling-calendar__header-month"
                key={i}
                style={{ width: `${monthPercent}%` }}
              >
                {label}
              </div>
            ))}
          </div>
          {hasPrevAndNextButtons && (
            <button onClick={onForwardOneMonth}>
              <Icon name="chevron-right" />
            </button>
          )}
        </div>
        <div className="scrolling-calendar__body">
          <div className="scrolling-calendar__background">
            {monthLabels.map((label, i) =>
              i === 0 ? null : (
                <div
                  className="scrolling-calendar__stripe"
                  key={i}
                  style={{ left: `${i * monthPercent}%` }}
                />
              )
            )}
            {hasNowLine && (
              <div
                className="scrolling-calendar__now-line"
                style={{ left: `${nowLineOffset}%` }}
              >
                <div
                  className="scrolling-calendar__now-line-label"
                  style={{ transform: labelTransform }}
                >
                  {formatDate(nowDate.toISOString())}
                </div>
              </div>
            )}
          </div>
          <div className="scrolling-calendar__content">
            {render({ isMobile: false, getOffsets })}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withSize()(ScrollingCalendar));
