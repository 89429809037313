import DocumentTitle from 'components/DocumentTitle';
import { IButtonLoadingState } from 'interfaces';
import ApiService from 'services/ApiService';
import { Formik, formikFieldProps, yup } from 'utils/forms';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, EButtonVariant, TextInput, pushToast } from '@ryan/components';

import './ForgotPassword.scss';

interface IForgotPasswordFormValues {
  email: string;
}

const initialValues: IForgotPasswordFormValues = {
  email: ''
};

interface IForgotPasswordProps extends WithTranslation {
  /**
   * Indicates a new user is using reset password page to create their initial
   * password. Updates component verbiage and endpoints.
   */
  newUser?: boolean;
}

/**
 * Initiates a forgotten password request to reset a user's password. Also used
 * to request a new password link for new users whose password setup link has
 * expired.
 */
class ForgotPassword extends Component<
  IForgotPasswordProps,
  IButtonLoadingState
> {
  static defaultProps = { newUser: false };

  private schema = yup.object({
    email: yup
      .string()
      .required(this.props.t('forgotPassword.validation.emailRequired'))
      .email(this.props.t('forgotPassword.validation.validEmail'))
  });

  readonly state = {
    loading: null
  };

  submitEmail = (values: IForgotPasswordFormValues) => {
    const { newUser, t } = this.props;
    const promise = (
      newUser
        ? ApiService.requestResetPasswordForNewUser(values.email)
        : ApiService.requestResetPassword(values.email)
    )
      .then(response => {
        if (response.status === 200) {
          pushToast({
            type: 'success',
            title: t(
              'forgotPassword.toast.success',
              newUser ? { context: 'newUser' } : {}
            )
          });
        }
      })
      .catch(() => {
        pushToast({
          type: 'error',
          title: t('forgotPassword.toast.error')
        });
      })
      .finally(() => {
        this.setState({ loading: null });
      });

    this.setState({ loading: promise });
  };

  render() {
    const { loading } = this.state;
    const { newUser, t } = this.props;
    return (
      <div className="ry-forgot-password">
        <DocumentTitle title={t('forgotPassword.title')} />

        <p>
          {t(
            'forgotPassword.directions',
            newUser ? { context: 'newUser' } : {}
          )}
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitEmail}
          validationSchema={this.schema}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <TextInput
                {...formikFieldProps('email', formik)}
                label="Email"
                placeholder={t('forgotPassword.inputPlaceholder')}
              />
              <Button
                loading={loading}
                text={t('Submit')}
                type="submit"
                variant={EButtonVariant.PRIMARY}
              />
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withTranslation()(ForgotPassword);
