import { DataRequestTypeGuidEnum } from '../../../../../../../utils/enums/DataRequestsEnums';
import { TDataRequestOverviewProps } from '../DataRequestOverview.interfaces';

export interface IDataRequestDescriptionDetailsProps {
  dataRequestDetailsByType: TDataRequestDetailsByType;
}

export interface IRenderSelections {
  ({
    modalTitle,
    selections,
    setRecordsModalDataCallback,
    viewSelections
  }: {
    modalTitle: string;
    selections:
      | IDataRequestDescriptionDetailsProps['dataRequestDetailsByType']['dateRanges']
      | IDataRequestDescriptionDetailsProps['dataRequestDetailsByType']['documentTypes']
      | IDataRequestDescriptionDetailsProps['dataRequestDetailsByType']['jurisdictions'];
    setRecordsModalDataCallback: React.Dispatch<
      React.SetStateAction<{ data: string[]; modalTitle: string } | null>
    >;
    viewSelections: string;
  }): JSX.Element | JSX.Element[];
}

type TDataRequestDetailsByType =
  TDataRequestOverviewProps['dataRequestDetails']['detailsByType'] & {
    engagementDisplayNameLong: TDataRequestOverviewProps['dataRequestDetails']['engagementDisplayNameLong'];
    title: TDataRequestOverviewProps['dataRequestDetails']['title'];
    typeGuid: DataRequestTypeGuidEnum;
  };

export enum DataRequestSelectionTypes {
  erpSystems = 'erpSystems',
  DocumentTypes = 'documentTypes',
  Jurisdictions = 'jurisdictions',
  PropertyTaxDocumentTypes = 'propertyTaxDocumentTypes',
  PropertyTaxSiteClasses = 'propertyTaxSiteClasses'
}
