import { EventEmitter } from 'events';

const ACTIVITY_EVENT = 'ACTIVITY_EVENT';

const events = new EventEmitter();

type Handler = (...args: any[]) => void;

const api = {
  subscribe(handler: Handler) {
    events.addListener(ACTIVITY_EVENT, handler);
  },
  unsubscribe(handler: Handler) {
    events.removeListener(ACTIVITY_EVENT, handler);
  },
  emit() {
    events.emit(ACTIVITY_EVENT);
  }
};

export default api;
