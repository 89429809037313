import { IAttachmentUpdates } from '../interfaces';
import { FolderSelection } from './IFolderSelection';
import { Status } from './Status';

export enum DataRequestStatusGroup {
  Outstanding = 1,
  Complete = 2
}

export interface IDataRequestRequest {
  title?: string;
  description?: string;
  engagementGuid?: string;
  assignedToUserGuid?: string;
  dueDate?: string;
  dataSpecs?: string;
  attachments?: IAttachmentUpdates;
  statusId?: Status;
  defaultFolder?: FolderSelection;
  comment?: string;
  isCurrentUserWatching?: boolean;
}
