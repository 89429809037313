import { useStateMounted } from 'hooks';
import {
  IDirectoryItem,
  IDirectoryResponse,
  IFolder,
  IFolderTree,
  ITableState
} from 'interfaces';
import qs from 'query-string';
import { DirectoryItemStatus } from 'routes/DataAndFiles/Files/Files';
import ApiService from 'services/ApiService';
import history from 'services/history';
import findFolder from 'utils/findFolder';
import getSortParam from 'utils/getSortParm';

import { AxiosResponse, CancelToken } from 'axios';
import { useCallback } from 'react';

/**
 * @todo move this file out of the Project route directory as it is used in
 * several routes/components.
 */

export interface IDirectoryState extends ITableState {
  engagementFolders: IFolderTree[] | null;
  folderPath: IFolder[];
  directoryItems: IDirectoryItem[];
  directoryItemsBySearch: string | null;
  totalFileCount: number;
  isTransferredFiles?: boolean;

  /**
   * Indicates user has requested to navigate to a new directory.
   */
  didDirectoryChange: boolean;
}

export function setHash(hash: Record<string, string | undefined>) {
  history.push({
    ...history.location,
    hash: qs.stringify({
      ...qs.parse(history.location.hash),
      ...hash
    })
  });
}

/**
 * @todo Convert this to a pure function. It is intentionally updating the
 * state argument, and should instead return a new, modified state object
 * that the caller can apply.
 */
export async function updateStateFoldersAndFolderPath(
  engagementGuid: string,
  folderGuid: string | any,
  state: IDirectoryState,
  cancelToken?: CancelToken
) {
  let isEngagementFoldersUpdated = false;
  // If the engagement's folders are not loaded, load them.
  // 1. May be mounting with uninitialized state.
  // 2. May have invalidated the loaded folders with an update.
  if (state.engagementFolders === null) {
    const resp = await ApiService.getEngagementFolders(
      engagementGuid,
      cancelToken
    );
    state.engagementFolders = resp.data;

    isEngagementFoldersUpdated = true;
  }

  // Do we have a selected folder?
  if (typeof folderGuid === 'string') {
    // If the folderPath does not match, compute it.
    // 1. May be mounting with uninitialized state, compute for first time.
    // 2. May have just selected a folder, recompute.
    const stateFolder = state.folderPath[state.folderPath.length - 1];

    if (stateFolder?.folderGuid !== folderGuid || isEngagementFoldersUpdated) {
      const match = findFolder(state.engagementFolders, folderGuid);
      if (match) {
        state.folderPath = match.path;
        state.page = 1;
      } else {
        // If folderGuid is invalid, clear selected folder.
        // This may happen if a user has a link to a deleted folder.
        return false;
      }
    } else if (state.searchQuery) {
      // If searchQuery is set, then we should clear the selected folder
      // because text search happens across all folders.
      return false;
    }
  } else {
    // No selected folder, so reset the state.
    // 1. May be mounting with uninitialized state.
    // 2. May have just navigated to the engagement root.
    state.folderPath = [];

    // Reset page to 1 if navigating back to engagement root.
    // NOTE: Reset only on initial navigation to engagement as pagination at the
    // root was not working otherwise as the page number was resetting to 1.
    if (state.didDirectoryChange) {
      state.page = 1;
    }
  }
  return true;
}

// parse function arguments from ApiService methods that could be called in
// `fetchDirectory`
type DirectoryFetchSearchArguments = Parameters<
  typeof ApiService.getEngagementDirectoryBySearch
>;

type DirectoryFetchSearchParams = DirectoryFetchSearchArguments[1];

type DirectoryFetchFolderArguments = Parameters<
  typeof ApiService.getEngagementDirectoryByFolder
>;

type DirectoryFetchFolderParams = DirectoryFetchFolderArguments[1];

async function fetchDirectory(
  engagementGuid: string,
  state: IDirectoryState & {
    engagementFolders: IFolderTree[];
    status: DirectoryItemStatus;
  },
  options: Pick<
    DirectoryFetchSearchParams | DirectoryFetchFolderParams,
    'documentGuid' | 'queueItemGuid'
  > = {},
  cancelToken?: CancelToken
): Promise<Partial<IDirectoryState & { totalArchivedFileCount: number }>> {
  const params: Omit<
    DirectoryFetchSearchParams | DirectoryFetchFolderParams,
    'folderGuid' | 'searchTerm'
  > = {
    ...options,
    sort: getSortParam(state.sorted),
    pageNumber: state.page,
    itemsPerPage: state.pageSize,
    filterGuids:
      state.filtered && state.filtered.filterGuids
        ? state.filtered.filterGuids
        : undefined
  };

  params.status = state.status;

  let response: AxiosResponse<IDirectoryResponse>;

  if (state.searchQuery) {
    response = await ApiService.getEngagementDirectoryBySearch(
      engagementGuid,
      { ...params, searchTerm: state.searchQuery },
      state.engagementFolders,
      cancelToken
    );
  } else if (state.isTransferredFiles) {
    response = await ApiService.getDataRequestTransferredFiles(
      engagementGuid,
      params.queueItemGuid!,
      { ...params },
      cancelToken
    );
  } else {
    const folder = state.folderPath[state.folderPath.length - 1];
    const folderGuid = folder ? folder.folderGuid : undefined;
    response = await ApiService.getEngagementDirectoryByFolder(
      engagementGuid,
      { ...params, folderGuid },
      state.engagementFolders,
      cancelToken
    );
  }

  return {
    directoryItems: response.data.results,
    directoryItemsBySearch: state.searchQuery || null,
    totalArchivedFileCount: response.data.archivedFileCount,
    totalCount: response.data.totalResults,
    totalFileCount: response.data.totalFileCount
  };
}

function last(fn: typeof fetchDirectory): typeof fetchDirectory {
  let lastPromise: ReturnType<typeof fetchDirectory> | null = null;

  return function (...args: Parameters<typeof fetchDirectory>) {
    return new Promise((resolve, reject) => {
      const promise = (lastPromise = fn(...args));
      promise
        .then(result => {
          if (promise === lastPromise) {
            resolve(result);
          }
        })
        .catch(error => {
          if (promise === lastPromise) {
            reject(error);
          }
        });
    });
  };
}

const lastFetchDirectory = last(fetchDirectory);

export { lastFetchDirectory as fetchDirectory };

/**
 * Instantiates an `IDirectoryState` state object and updater for use in
 * functional components.
 */
export function useDirectoryState(
  initialState: Partial<IDirectoryState>
): [
  IDirectoryState,
  (
    updates:
      | Partial<IDirectoryState>
      | ((prevState: IDirectoryState) => IDirectoryState)
  ) => void
] {
  const [directoryState, setDirectoryState] = useStateMounted<IDirectoryState>({
    didDirectoryChange: false,
    directoryItems: [],
    directoryItemsBySearch: null,
    engagementFolders: null,
    folderPath: [],
    loading: false,
    page: 1,
    pageSize: 10,
    totalCount: 0,
    totalFileCount: 0,
    ...initialState
  });

  /**
   * `directoryState` updater function that will always merge with previous
   * state.
   */
  const setDirectoryStateMerge = useCallback(
    (
      updates:
        | Partial<IDirectoryState>
        | ((prevState: IDirectoryState) => IDirectoryState)
    ) => {
      const updaterFunction =
        typeof updates === 'function'
          ? updates
          : (prevDirectoryState: IDirectoryState) => ({
              ...prevDirectoryState,
              ...updates
            });
      setDirectoryState(updaterFunction);
    },
    [setDirectoryState]
  );

  return [directoryState, setDirectoryStateMerge];
}
