import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import {
  SignalRLogger,
  datadogLogs,
  logger as defaultLogger
} from 'services/logger';

import ENV from 'env';

import { getUserManager } from './UserManager';

// Singleton
let hub: Promise<HubConnection> | null = null;

/**
 * Builds a SignalR connection tied to notifications.
 */
export function getSignalRHub(): Promise<HubConnection> {
  if (!hub) {
    // Create the hub.
    const connection = new HubConnectionBuilder()
      .withUrl(`${ENV.API_ROOT}/Notifications`, {
        accessTokenFactory: async () => {
          const user = await getUserManager().getUser();

          // close the connection if the user is no longer active
          if (!user || user.expired) {
            await connection.stop();
            return '';
          } else {
            return user.access_token;
          }
        }
      })
      .configureLogging(new SignalRLogger())
      .withAutomaticReconnect()
      .build();
    const logger = datadogLogs.getLogger('signalRLogger') || defaultLogger;

    // Connect the hub.
    hub = new Promise<HubConnection>((resolve, reject) => {
      let tries = 0;
      const start = async () => {
        try {
          await connection.start();
          logger.info('started SignalR connection');
          resolve(connection);
        } catch (error: any) {
          tries += 1;

          // try to reconnect if there is an error on start
          if (tries < 3) {
            logger.warn(
              `initial connection to SignalR failed ${tries} time${
                tries === 1 ? '' : 's'
              } - ${error.message}`
            );
            setTimeout(start, 5000);
          } else {
            logger.error(
              `could not start the connection to SignalR - ${error.message}`
            );
            reject('could not start the connection to SignalR');
          }
        }
      };

      // configure logging
      connection.onreconnecting(error => {
        if (error) {
          logger.warn(`connection lost - ${error.message}`);
        }
      });
      connection.onreconnected(connectionId => {
        logger.info(
          `connection reestablished with connectionId "${connectionId}"`
        );
      });
      connection.onclose(error => {
        if (error) {
          logger.error(`connection closed - ${error.message}`);
        }
      });

      // Start the connection.
      start();
    });
  }

  // Return the hub.
  return hub;
}

/**
 * Stops a SignalR connection if one has been established.
 */
export async function stopHubConnection(): Promise<void> {
  const connection = await hub;

  if (connection?.stop) {
    await connection.stop();
  }
}

/**
 * Resets the singleton. USE ONLY FOR UNIT TESTING!
 *
 * @ignore
 */
export function resetSignalRHub() {
  hub = null;
}
