import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import ApiService from '../../../services/ApiService';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import Modal from '../Modal';
import { IConfirmationModalProps } from './utils';

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  cancelTextToDisplay,
  confirmationMessage,
  isPositive,
  onClose,
  onSubmit,
  submitTextToDisplay,
  title
}) => {
  const confirmationRef = useRef(null);

  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);
  const { t: getTextToDisplay } = useTranslation();

  const handleSubmit = async (onSubmitCallback: any) => {
    setIsProcessingSubmit(true);

    try {
      await onSubmitCallback();
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      if (!confirmationRef.current) {
        return;
      }
      onClose();
    }
  };

  return (
    <Modal className="confirmation-modal" onClose={onClose} open title={title}>
      <p ref={confirmationRef}>{confirmationMessage}</p>

      <ButtonGroup>
        <Button
          disabled={isProcessingSubmit}
          onClick={onClose}
          text={
            cancelTextToDisplay ||
            getTextToDisplay('modal.confirmationModal.noCancel')
          }
        />
        <Button
          disabled={isProcessingSubmit}
          negative={!isPositive}
          onClick={() => {
            handleSubmit(onSubmit);
          }}
          text={
            submitTextToDisplay ||
            getTextToDisplay('modal.confirmationModal.yesDelete')
          }
          variant={EButtonVariant.PRIMARY}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default ConfirmationModal;
