import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Empty from '../Empty';

interface ITableEmptyProps {
  searchQuery: string | undefined;
  filterActive?: () => boolean;
}

/**
 * Component used to render either the standardized empty search/filter states for tables,
 * or a unique empty state if there is no data whatsoever for that table.
 */
const TableEmpty: FunctionComponent<ITableEmptyProps> = ({
  searchQuery,
  filterActive,
  children
}) => {
  const { t } = useTranslation();
  const isFilterActive = filterActive ? filterActive() : false;

  if (searchQuery) {
    return (
      <Empty icon="search">
        <p>
          <Trans i18nKey="Search Not Found">
            <b>{{ searchQuery }}</b> could not be found.
          </Trans>
        </p>
        <p> {t(isFilterActive ? 'No Filtered Results' : 'Adjust Search')}</p>
      </Empty>
    );
  }

  if (isFilterActive) {
    return (
      <Empty icon="search">
        <p>{t('No Results Found')}</p>
        <p>{t('Adjust Filter')}</p>
      </Empty>
    );
  }

  return <>{children}</>;
};

export default TableEmpty;
