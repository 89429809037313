export default {
  fd: 'File Deleted',
  zfa: 'Zipped Files Available',
  cpu: 'Contact Profile Update',
  nura: 'New User Request Approved',
  ur: 'User Reactivated',
  urm: 'User Role Modified',
  uae: 'User Added To Engagement',
  ua: 'User Activated',
  lc: 'Learning Comment',
  lcm: 'Learning Comment Modified',
  tc: 'Task Comment',
  tcm: 'Task Comment Modified',
  drc: 'Data Request Comment',
  drcm: 'Data Request Comment Modified',
  fc: 'File Comment',
  fcm: 'File Comment Modified',
  mc: 'Milestone Comment',
  mcm: 'Milestone Comment Modified',
  nurc: 'New User Request Created',
  nuar: 'New User Access Requested',
  fl: 'File Linked',
  fua: 'File Uploaded Ad Hoc',
  fuaobho: 'File Uploaded Ad Hoc On Behalf Of',
  f: 'File',
  d: 'Data Request',
  t: 'Task',
  l: 'Learning',
  i: 'Invoice',
  s: 'Savings Summary',
  c: 'Contract',
  m: 'Milestone',
  e: 'Engagement Overview'
} as { [index: string]: string };
