import Modal from 'components/Modal';
import { ISavingsSummaryEntry } from 'interfaces';
import { Formik, formikNumberInputProps, yup } from 'utils/forms';

import { TFunction } from 'i18next';
import React, { Component } from 'react';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  TextInput
} from '@ryan/components';

import { SavingsSummaryBucket } from './SavingsSummaryBucket';
import { updateEntryCategory } from './savingsSummaryHelpers';

const BUCKET_ADDED: {
  [key in SavingsSummaryBucket]:
    | 'potentialAdded'
    | 'submittedAdded'
    | 'approvedAdded'
    | 'receivedAdded';
} = {
  [SavingsSummaryBucket.Potential]: 'potentialAdded',
  [SavingsSummaryBucket.Submitted]: 'submittedAdded',
  [SavingsSummaryBucket.Approved]: 'approvedAdded',
  [SavingsSummaryBucket.Received]: 'receivedAdded'
};

interface IAddBucketModalProps {
  t: TFunction;
  entry: ISavingsSummaryEntry;
  savingsSummaryEntryCategoryGuid: string;
  bucket: SavingsSummaryBucket;
  onSubmit: (entry: ISavingsSummaryEntry) => void;
  onCancel: () => void;
}

interface IAddBucketModalValues {
  added: string;
}

export class AddBucketModal extends Component<IAddBucketModalProps> {
  private schema = yup.object({
    added: yup.number().min(0, 'Savings amount cannot be negative.')
  });

  getCategory() {
    const { entry, savingsSummaryEntryCategoryGuid } = this.props;
    return entry.categories.find(
      c => c.savingsSummaryEntryCategoryGuid === savingsSummaryEntryCategoryGuid
    );
  }

  getInitialValues(): IAddBucketModalValues {
    const { bucket } = this.props;
    const category = this.getCategory();
    if (!category) {
      throw new ReferenceError();
    }
    return { added: `${category[BUCKET_ADDED[bucket]]}` };
  }

  handleSubmit = (values: IAddBucketModalValues) => {
    const { entry, savingsSummaryEntryCategoryGuid, bucket, onSubmit } =
      this.props;
    onSubmit(
      updateEntryCategory(entry, savingsSummaryEntryCategoryGuid, {
        [BUCKET_ADDED[bucket]]: parseFloat(values.added)
      })
    );
  };

  render() {
    const { t, bucket, onCancel } = this.props;
    const category = this.getCategory();

    if (!category) {
      return null;
    }

    return (
      <Modal
        onClose={onCancel}
        open
        title={t('savings.entry.editModal.title', {
          bucket: t(`savings.${bucket}`)
        })}
      >
        <Formik
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          validationSchema={this.schema}
        >
          {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <p>
                {t('savings.entry.editModal.description', {
                  bucket: t(`savings.${bucket}`),
                  category: category.name
                })}
              </p>
              <TextInput
                {...formikNumberInputProps('added', formik)}
                icon="add-square"
                label={t('savings.entry.editModal.addLabel')}
              />
              <ButtonGroup>
                <Button
                  text={t('Done')}
                  type="submit"
                  variant={EButtonVariant.PRIMARY}
                />
                <Button
                  onClick={onCancel}
                  text={t('Cancel')}
                  variant={EButtonVariant.SECONDARY}
                />
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default AddBucketModal;
