import DocumentTitle from 'components/DocumentTitle';
import { IRole } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { ChangeEvent, Component, ComponentProps } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Button, Dropdown, EButtonVariant } from '@ryan/components';

import CreateRole from './CreateRole';
import EditRole from './EditRole';

import './ManageTeamRoles.scss';

type Option = ComponentProps<typeof Dropdown>['options'];

interface IManageTeamRolesProps extends WithTranslation, RouteComponentProps {
  // ...
}

interface IManageTeamRolesState {
  roles: IRole[] | null;
  editRole: IRole | null;
}

class ManageTeamRoles extends Component<
  IManageTeamRolesProps,
  IManageTeamRolesState
> {
  readonly state: IManageTeamRolesState = {
    roles: null,
    editRole: null
  };

  async fetchData() {
    try {
      const response = await ApiService.getRoles();
      this.setState({
        roles: response.data
      });
    } catch {
      pushServerErrorToast();
    }
  }

  getRoleDropdownOptions(): Option {
    const { t } = this.props;
    const { roles } = this.state;
    const placeholder = {
      value: '',
      label: t('manageRoles.selectRole'),
      disabled: true
    };

    if (roles) {
      return [
        placeholder,
        ...roles.map(role => ({
          value: role.roleGuid,
          label: `${role.roleName} (${t(`userTypes.${role.userTypes}`)})`,
          disabled: false
        }))
      ];
    }

    return [placeholder];
  }

  componentDidMount() {
    this.fetchData();
  }

  handleNewRole = () => {
    this.setState({ editRole: null });
    this.props.history.push('/app/roles/create');
  };

  handleNewRoleCreated = (role: IRole) => {
    this.setState({
      roles: [...(this.state.roles || []), role]
    });
  };

  handleSelectRole = (e: ChangeEvent<HTMLSelectElement>) => {
    const { roles } = this.state;
    if (roles) {
      const roleGuid = e.target.value;
      const role = roles.find(r => r.roleGuid === roleGuid);
      if (role) {
        this.setState({ editRole: role });
      }
    }
  };

  handleSave = (updatedRole: IRole) => {
    const { roles, editRole } = this.state;
    if (roles && editRole) {
      this.setState({
        roles: roles.map(r =>
          r.roleGuid === updatedRole.roleGuid ? updatedRole : r
        ),
        editRole: null
      });
    }
  };

  handleCancel = () => {
    this.setState({ editRole: null });
  };

  handleDelete = () => {
    const { roles, editRole } = this.state;
    if (roles && editRole) {
      this.setState({
        // remove the deleted role from the list of roles
        roles: roles.filter(r => r.roleGuid !== editRole.roleGuid),
        editRole: null
      });
    }
  };

  render() {
    const { t } = this.props;
    const { roles, editRole } = this.state;
    return (
      <div className="manage-team-roles">
        <DocumentTitle title={t('manageRoles.title')} />

        <h4 className="ry-h4">{t('All Accounts')}</h4>
        <h1 className="ry-h1">Manage Roles</h1>

        <Switch>
          <Route
            path="/app/roles/create"
            render={routeProps => (
              <CreateRole
                {...routeProps}
                onRoleCreated={this.handleNewRoleCreated}
              />
            )}
          />
          <Route
            render={() => (
              <>
                <h3 className="ry-h3">{t('manageRoles.title')}</h3>
                <p>{t('manageRoles.description')}</p>
                <div className="manage-team-roles__actions">
                  <Dropdown
                    disabled={roles === null}
                    onChange={this.handleSelectRole}
                    options={this.getRoleDropdownOptions()}
                    value={editRole ? editRole.roleGuid : ''}
                  />
                  <Button
                    icon="plus"
                    onClick={this.handleNewRole}
                    text={t('manageRoles.newRole')}
                    variant={EButtonVariant.TEXT}
                  />
                </div>
                {editRole && (
                  <EditRole
                    onCancel={this.handleCancel}
                    onDelete={this.handleDelete}
                    onSave={this.handleSave}
                    role={editRole}
                    roles={roles!}
                  />
                )}
              </>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withTranslation()(ManageTeamRoles);
