import { IDataRequest, IQueueItemWatcher, ITask } from 'interfaces';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

export async function addWatcherToQueue(
  this: ApiBase,
  engagementGuid: string,
  isCurrentUserWatching: boolean,
  queueItemGuid: string,
  cancelToken?: CancelToken
) {
  return unwrapCommandResponse<IQueueItemWatcher>(
    this.server.put(
      `api/engagements/${engagementGuid}/queueItem/${queueItemGuid}/watcher`,
      { cancelToken, isCurrentUserWatching }
    )
  );
}

/**
 * GET Queue Item Followers
 */
export async function getQueueItemFollowers(
  this: ApiBase,
  engagementGuid: string,
  queueItemGuid: string
) {
  return this.server.get(
    `/api/engagements/${engagementGuid}/queueitem/${queueItemGuid}/watchers`
  );
}

/**
 * GET Queue Item Followers when restricted from Data Requests
 */
export async function getQueueItemFollowersRestricted(
  this: ApiBase,
  engagementGuid: string,
  queueItemGuid: string
) {
  return this.server.get(
    `/api/engagements/${engagementGuid}/queueitem/${queueItemGuid}/watchers/restricted`
  );
}

/**
 * PUT Queue Item Followers
 */
export async function updateQueueItemFollowers(
  this: ApiBase,
  engagementGuid: string,
  queueItemGuid: string,
  followers: string[]
) {
  return this.server.put<IDataRequest | ITask>(
    `/api/engagements/${engagementGuid}/queueItem/${queueItemGuid}/watchers`,
    followers
  );
}
