import Modal from 'components/Modal';
import { Formik, FormikHelpers, formikFieldProps, yup } from 'utils/forms';

import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  TextInput
} from '@ryan/components';

import { FolderSelection, IFolder, IFolderTree } from '../../../interfaces';
import * as FolderValidationUtils from '../../../utils/folderValidation/folderValidation.utils';

export interface IFolderRenameModalProps {
  folder: IFolder | null; // modal closed if null
  folders: IFolderTree[] | null;
  loading: Promise<any> | null;
  onSubmit: (folderName: string) => any;
  onCancel: () => void;
}

interface IFormValues {
  folderName: string;
}

const FolderRenameModal: FunctionComponent<IFolderRenameModalProps> = ({
  folder,
  folders,
  loading,
  onSubmit,
  onCancel
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const schema = useMemo(
    () =>
      yup.object({
        folderName: yup
          .string()
          .test('folder-validation', '', function (value) {
            return FolderValidationUtils.validateFolderInput(
              {
                folderName: value,
                parentFolderGuid: (folder as IFolder)?.parentFolderGuid || null
              } as FolderSelection,
              folders,
              pathToText =>
                this.createError({
                  message: getTextToDisplay(pathToText) as string
                })
            ) as yup.ValidationError;
          })
      }),
    [getTextToDisplay, folder, folders]
  );

  async function handleSubmit(
    { folderName }: IFormValues,
    formik: FormikHelpers<IFormValues>
  ) {
    const folderNameError = await onSubmit(folderName);
    if (folderNameError) {
      formik.setFieldError('folderName', folderNameError);
    }
  }

  if (folder) {
    return (
      <Modal
        onClose={onCancel}
        open
        title={getTextToDisplay('folder.renameModal.title')}
      >
        <Formik
          initialValues={{ folderName: folder.folderName }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <TextInput
                {...formikFieldProps('folderName', formik)}
                label={getTextToDisplay('folder.renameModal.folderName.label')}
              />
              <ButtonGroup>
                <Button
                  disabled={formik.errors.folderName !== undefined}
                  loading={loading}
                  text={getTextToDisplay('Save')}
                  type="submit"
                  variant={EButtonVariant.PRIMARY}
                />
                <Button
                  disabled={loading !== null}
                  onClick={onCancel}
                  text={getTextToDisplay('Cancel')}
                  variant={EButtonVariant.SECONDARY}
                />
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  return null;
};

export default FolderRenameModal;
