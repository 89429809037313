import { IAccount } from 'interfaces';

export default function flattenAccountTree(
  accounts: IAccount[],
  flatAccounts: IAccount[] = []
) {
  for (let i = 0; i < accounts.length; i++) {
    flatAccounts.push(accounts[i]);
    flattenAccountTree(accounts[i].accounts, flatAccounts);
  }

  return flatAccounts;
}
