import Modal from 'components/Modal';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

export interface ICommentDeleteModalProps {
  t: TFunction;
  open: boolean;
  loading: Promise<any> | null;
  onConfirm: () => void;
  onCancel: () => void;
}

const CommentDeleteModal: FunctionComponent<ICommentDeleteModalProps> = ({
  loading,
  open,
  onConfirm,
  onCancel,
  t
}) => (
  <Modal onClose={onCancel} open={open} title={t('comment.deleteModal.title')}>
    <p>{t('comment.deleteModal.content')}</p>
    <ButtonGroup>
      <Button
        loading={loading}
        negative
        onClick={onConfirm}
        text={t('Yes, Delete')}
      />
      <Button
        disabled={loading !== null}
        onClick={onCancel}
        text={t('No, Cancel')}
        variant={EButtonVariant.SECONDARY}
      />
    </ButtonGroup>
  </Modal>
);

export default CommentDeleteModal;
