import { IFileUpload } from "interfaces/IFileUpload";
import ApiBase from "./ApiBase";

export async function uploadFiles(this: ApiBase,
    engagementGuid: string,
    request: IFileUpload,
) {
    return this.server.post<boolean>(
        `/api/file/engagements/${engagementGuid}/adhoc`,
        request
    );
}