import {
  IPagedDataResponse,
  IWorkspace,
  IWorkspaceConnection,
  IWorkspaceRequest,
  WorkspaceGroup,
  WorkspaceStatus
} from 'interfaces';
import {
  transformResponse,
  transformResponseWithoutDates
} from 'utils/transformResponse';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

function mapWorkspaceGroups(data: IPagedDataResponse<IWorkspace>) {
  return {
    ...data,
    results: data.results.map(workspace => ({
      ...workspace,
      group:
        workspace.status === WorkspaceStatus.Complete
          ? WorkspaceGroup.Complete
          : WorkspaceGroup.Outstanding
    }))
  };
}

function mapWorkspaceTemplates(data: IPagedDataResponse<IWorkspace>) {
  return data.results;
}

export async function getEngagementWorkspaces(
  this: ApiBase,
  engagementGuid: string,
  params: {
    pageNumber?: number;
    itemsPerPage?: number;
    sort?: string;
    searchTerm?: string;
    status?: WorkspaceStatus[];
  },
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IWorkspace>>(
    `api/engagements/${engagementGuid}/workspaces/search`,
    {
      cancelToken,
      params,
      transformResponse: [...transformResponseWithoutDates, mapWorkspaceGroups]
    }
  );
}

export async function getWorkspaceTemplates(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  const params = {
    itemsPerPage: 30, // We don't expect this many, but want to increase it beyond the default 10.
    isTemplate: true
  };
  return this.server.get<IWorkspace[]>(
    `api/engagements/${engagementGuid}/workspaces/search`,
    {
      cancelToken,
      params,
      transformResponse: [...transformResponse, mapWorkspaceTemplates]
    }
  );
}

export async function getWorkspaceConnections(
  this: ApiBase,
  cancelToken?: CancelToken
) {
  return this.server.get<IWorkspaceConnection[]>('api/connections', {
    cancelToken
  });
}

export async function createWorkspace(
  this: ApiBase,
  engagementGuid: string,
  request: IWorkspaceRequest
) {
  return this.server.post<IWorkspace>(
    `api/engagements/${engagementGuid}/workspaces`,
    request
  );
}

export async function updateWorkspace(
  this: ApiBase,
  engagementGuid: string,
  workspaceGuid: string,
  request: IWorkspaceRequest
) {
  return this.server.put<IWorkspace>(
    `api/engagements/${engagementGuid}/workspaces/${workspaceGuid}`,
    request
  );
}

export async function completeWorkspace(
  this: ApiBase,
  engagementGuid: string,
  workspaceGuid: string
) {
  return this.server.put<IWorkspace>(
    `api/engagements/${engagementGuid}/workspaces/${workspaceGuid}/complete`
  );
}

export async function reopenWorkspace(
  this: ApiBase,
  engagementGuid: string,
  workspaceGuid: string
) {
  return this.server.put<IWorkspace>(
    `api/engagements/${engagementGuid}/workspaces/${workspaceGuid}/reopen`
  );
}

export async function dismissWorkspace(
  this: ApiBase,
  engagementGuid: string,
  workspaceGuid: string
) {
  return this.server.delete(
    `api/engagements/${engagementGuid}/workspaces/${workspaceGuid}/dismiss`
  );
}

export async function deleteWorkspace(
  this: ApiBase,
  engagementGuid: string,
  workspaceGuid: string
) {
  return this.server.delete(
    `api/engagements/${engagementGuid}/workspaces/${workspaceGuid}`
  );
}

export async function revertWorkspace(
  this: ApiBase,
  engagementGuid: string,
  workspaceGuid: string
) {
  return this.server.put<IWorkspace>(
    `api/engagements/${engagementGuid}/workspaces/${workspaceGuid}/revert`
  );
}
