import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import useIsMounted from './useIsMounted';

/**
 * An analog to the state hook that will not update state in an unmounted
 * component. Use sparingly over standard state hook due to extra overhead.
 */
export default function useStateMounted<S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
  const isMounted = useIsMounted();
  const [state, setState] = useState<S>(initialState);
  const setStateIfMounted: Dispatch<SetStateAction<S>> = useCallback(
    (updatedState: S | ((prevState: S) => S)) => {
      if (isMounted()) {
        setState(updatedState);
      }
    },
    [isMounted]
  );

  return [state, setStateIfMounted];
}
