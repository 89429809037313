import { WithUser, withUser } from 'contexts/UserContext';
import { IComment } from 'interfaces';

import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { MentionsValue } from '@ryan/components';

import Empty from '../Empty';
import Comment, { ICommentProps } from './Comment';
import CommentSkeleton from './CommentSkeleton';
import groupCommentsByDate from './groupCommentsByDate';

import './Comments.scss';

export interface ICommentsProps extends WithTranslation, WithUser {
  engagementGuid: string;
  loading: boolean;
  comments: IComment[] | null;

  // Do we have permission to create or edit comments?
  // Assume we have permission to view, because otherwise we should not be rendered.
  canComment: boolean;

  editComment: IComment | null;
  editCommentText?: MentionsValue;
  editCommentLoading: Promise<any> | null;
  onEdit: (comment: IComment) => void;
  onEditChange: (commentText: MentionsValue) => void;
  onEditSave: () => void;
  onEditCancel: () => void;

  onDelete: (comment: IComment) => void;
}

export const Comments: FunctionComponent<ICommentsProps> = props => {
  const {
    t,
    i18n: { language },
    engagementGuid,
    permissionService: ps,
    loading,
    comments,
    canComment,
    editComment,
    editCommentText,
    editCommentLoading,
    onEdit,
    onEditChange,
    onEditSave,
    onEditCancel,
    onDelete
  } = props;

  return (
    <div className="comments">
      {loading && comments === null && (
        <div className="comments__day-group">
          <div className="comments__day-group-label">
            <span className="comments__day-group-label-text">
              <span className="ry-skeleton" />
            </span>
          </div>
          <CommentSkeleton />
          <CommentSkeleton />
          <CommentSkeleton />
        </div>
      )}

      {comments !== null && comments.length === 0 && (
        <Empty icon="comment">{t('comment.empty')}</Empty>
      )}

      {comments !== null &&
        comments.length > 0 &&
        groupCommentsByDate(comments, t, language).map(group => (
          <div className="comments__day-group" key={group.key}>
            <div className="comments__day-group-label">
              <b className="comments__day-group-label-text">{group.label}</b>
            </div>
            {group.comments.map(comment => {
              let editText: ICommentProps['editText'];

              if (
                editComment &&
                editComment.commentGuid === comment.commentGuid
              ) {
                editText = editCommentText;
              }

              return (
                <Comment
                  canEdit={canComment && ps.isUser(comment.createdBy)}
                  comment={comment}
                  editLoading={editCommentLoading}
                  editText={editText}
                  engagementGuid={engagementGuid}
                  key={comment.commentGuid}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onEditCancel={onEditCancel}
                  onEditChange={onEditChange}
                  onEditSave={onEditSave}
                  t={t}
                />
              );
            })}
          </div>
        ))}
    </div>
  );
};

export default withTranslation()(withUser(Comments));
