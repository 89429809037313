import moment from 'moment';

import i18n from 'i18next';

/**
 * Formats a date to `mm/dd/yyy` format. The time can optionally be added in
 * `HH:mm am/pm` format.
 */
export function formatDate(
  date: Date | string,
  withTime = false,
  format?: string
): string {
  const mutableLanguagesArr = i18n.languages?.slice();

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const formattedDate = format
    ? moment(date).format(format)
    : date.toLocaleDateString(mutableLanguagesArr, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });

  if (withTime) {
    const formattedTime = date
      .toLocaleTimeString(mutableLanguagesArr, {
        hour: 'numeric',
        minute: '2-digit'
      })
      .toLowerCase();

    return `${formattedDate}, ${formattedTime}`;
  }

  return formattedDate;
}

export function formatTime(date: Date | string): string {
  const mutableLanguagesArr = i18n.languages?.slice();

  if (typeof date === 'string') {
    date = new Date(date);
  }

  // i18n.languages is an array of locales starting with the chosen
  // preference, and the last one is the fallback
  // e.g. ['fr-CA', 'fr', 'en-US']

  const formattedTime = date
    .toLocaleTimeString(mutableLanguagesArr, {
      hour: 'numeric',
      minute: '2-digit'
    })
    .toLowerCase();

  return formattedTime;
}
