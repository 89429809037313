import {
  IDataRequestComment,
  IPagedDataResponse,
  ITask,
  ITaskCounts,
  ITaskValues,
  IUserSummary
} from 'interfaces';
import { transformResponse } from 'utils/transformResponse';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';

import { CancelToken } from 'axios';

import { formatDate } from '../../utils/formatDate';
import ApiBase from './ApiBase';

/**
 * Get a list of tasks attached to an account (All Task at the Projects Level)
 */
export async function getCustomViewTasks(
  this: ApiBase,
  customViewGuid: string,
  params: any,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<ITask>>(
    `/api/customViews/${customViewGuid}/tasks/search`,
    { cancelToken, params, transformResponse }
  );
}

/**
 * Get a list of tasks attached to one project/engagement
 */
export async function getEngagementTasks(
  this: ApiBase,
  engagementGuid: string,
  params: any,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<ITask>>(
    `/api/engagements/${engagementGuid}/tasks/search`,
    { cancelToken, params, transformResponse }
  );
}

/**
 * GET assignable users for Tasks on an Engagement
 */
export async function getEngagementTaskAssignableUsers(
  this: ApiBase,
  engagementGuid: string,
  query: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IUserSummary[]>(
    `/api/engagements/${engagementGuid}/tasks/assignableusers`,
    { cancelToken, params: { searchTerm: query } }
  );
}

/**
 * Create a task
 * @param task : The values for a task from a form
 */
export async function createTask(
  this: ApiBase,
  task: ITaskValues,
  cancelToken?: CancelToken
) {
  return this.server.post<ITask>(
    `/api/engagements/${task.engagementGuid}/tasks`,
    {
      ...task,
      dueDate: formatDate(task.dueDate!, false, 'YYYY/MM/DD')
    },
    { cancelToken, transformResponse }
  );
}

export async function getTask(
  this: ApiBase,
  engagementGuid: string,
  taskGuid: string
) {
  return this.server.get<ITask>(
    `/api/engagements/${engagementGuid}/tasks/${taskGuid}`,
    { transformResponse }
  );
}

/**
 * Update an already created task
 * @param task: The values for a task from a form
 * @param updates: Any of the changed values from the task form
 */
export async function updateTask(
  this: ApiBase,
  task: ITask,
  updates: ITaskValues,
  cancelToken?: CancelToken
) {
  return this.server.put<ITask>(
    `/api/engagements/${task.engagementGuid}/tasks/${task.queueItemGuid}`,
    {
      title: task.title,
      description: task.description,
      assignedToUserGuid: task.assignedToUserGuid,
      engagementMilestoneGuid: task.engagementMilestoneGuid,
      statusId: task.statusId,
      isCurrentUserWatching: task.isCurrentUserWatching,
      ...updates,
      dueDate: formatDate(updates.dueDate || task.dueDate, false, 'YYYY/MM/DD')
    },
    { cancelToken, transformResponse }
  );
}

export async function deleteTask(
  this: ApiBase,
  engagementGuid: string,
  taskGuid: string
) {
  return this.server.delete(
    `/api/engagements/${engagementGuid}/tasks/${taskGuid}`
  );
}

//
//
// Task Comments
//
//

/**
 * GET a Task's Comments
 */
export async function getTaskComments(
  this: ApiBase,
  engagementGuid: string,
  taskGuid: string
) {
  return this.server.get<IDataRequestComment[]>(
    `/api/engagements/${engagementGuid}/tasks/${taskGuid}/comments`
  );
}

/**
 * Create a Task Comment
 */
export async function createTaskComment(
  this: ApiBase,
  engagementGuid: string,
  taskGuid: string,
  comment: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.post(
      `/api/engagements/${engagementGuid}/tasks/${taskGuid}/comments`,
      { comment }
    )
  );
}

/**
 * Update a Task Comment
 */
export async function updateTaskComment(
  this: ApiBase,
  engagementGuid: string,
  taskCommentGuid: string,
  commentText: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.put(
      `/api/engagements/${engagementGuid}/tasks/comments/${taskCommentGuid}`,
      { comment: commentText }
    )
  );
}

/**
 * DELETE a Task Comment
 */
export async function deleteTaskComment(
  this: ApiBase,
  engagementGuid: string,
  taskCommentGuid: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.delete(
      `/api/engagements/${engagementGuid}/tasks/comments/${taskCommentGuid}`
    )
  );
}

//
//
// Dashboards
//
//

/**
 * Get task counts for all projects/engagements in an account
 * @param accountGuid: The account you are receiving the counts for
 */
export async function getAccountTaskCounts(
  this: ApiBase,
  accountGuid?: string
) {
  return this.server.get<ITaskCounts>(
    `/api/accounts/${accountGuid}/tasks/counts`
  );
}

/**
 * Get task counts for all projects/engagements in a CustomView
 * @param accountGuid: The account you are receiving the counts for
 */
export async function getCustomViewTaskCounts(
  this: ApiBase,
  customViewGuid?: string,
  cancelToken?: CancelToken
) {
  return this.server.get<ITaskCounts>(
    `/api/customViews/${customViewGuid}/tasks/counts`,
    { cancelToken }
  );
}

/**
 * Get task counts for a project/engagement
 * @param engagementGuid: The engagement/project you are receiving the counts for
 */
export async function getEngagementTaskCounts(
  this: ApiBase,
  engagementGuid?: string,
  cancelToken?: CancelToken
) {
  return this.server.get<ITaskCounts>(
    `/api/engagements/${engagementGuid}/tasks/counts`,
    { cancelToken }
  );
}
