import { EventEmitter } from 'events';
import { useEffect } from 'react';

export default function useEvent(
  ee: EventEmitter,
  event: string,
  handler: (...args: any[]) => void
) {
  useEffect(() => {
    ee.addListener(event, handler);
    return () => {
      ee.removeListener(event, handler);
    };
  }, [ee, event, handler]);
}
