import { EventEmitter } from 'events';
import getISODateString from 'utils/getDateString';

import { AxiosError } from 'axios';

import { DSSUploader, DSSUploaderStatus } from './DSSUploader';

export enum DSSManagerEventType {
  Uploading = 'uploading',
  Progress = 'progress',
  Uploaded = 'uploaded',
  Removed = 'removed',
  Error = 'error',
  Cleared = ''
}

export interface IDSSManagerOptions {
  multiple?: boolean;
  disabled?: boolean;
  expiration?: number; // milliseconds
  data?: { [key: string]: string | number };
  onChange?: (event: DSSManagerEventType, ...any: any[]) => void;
  onBeforeUpload?: (input: File[]) => Promise<File[]>;
}

//SUPER HACKY!!!
//Based on the language that comes in on the request header, a timestamp could not be tacked on to the DATE that came from here.
//So that we don't have any issues, we are going to quickly check, convert it to en-US if needed and use the same defaults as UI would send
//If the "DATE_NEVER" AKA ImmutableDate or "defaultExpiration" AKA DeleteDate change, please let a BED DEV know too!
const DATE_NEVER = '3000-01-01';

const defaultExpiration = 1000 * 60 * 60 * 24 * 14; // 2 weeks

const defaultData = {
  ApplicationGuid: '00000000-0000-0000-0000-000000000000',
  ImmutableDate: DATE_NEVER
};

export class DSSManager extends EventEmitter {
  private options: IDSSManagerOptions;
  private uploaders: DSSUploader[] = [];
  private uploadingPromise: Promise<any> | null = null;
  private uploadingPromiseResolve: ((value?: any) => void) | null = null;

  constructor(options: IDSSManagerOptions = {}) {
    super();
    this.options = options;
    if (options.onChange) {
      this.on('change', options.onChange);
    }
  }

  /**
   * Clears all uploads.
   */
  public clearUploads() {
    this.uploaders
      .map(uploader => uploader.file)
      .forEach(file => this.removeFile(file));
  }

  /**
   * Gets uploaded files. Excludes uploading, errored, or canceled files.
   * @returns DSSDocument[]
   */
  public getUploadedDocuments() {
    return this.uploaders
      .filter(
        uploader =>
          uploader.status === DSSUploaderStatus.Uploaded && uploader.dssDocument
      )
      .map(uploader => uploader.dssDocument!);
  }

  /**
   * Gets empty promise when uploading is current in progress (isUploading = true)
   * @returns Promise | null
   */
  public getUploadingPromise() {
    return this.uploadingPromise;
  }

  public getUploadsSnapshot() {
    return this.uploaders.map(uploader => ({
      file: uploader.file,
      status: uploader.status,
      progress: uploader.progress
    }));
  }

  /**
   * Handler for DSSUploader's onError.
   */
  private handleFileError = (uploader: DSSUploader, error: AxiosError) => {
    this.emit('change', DSSManagerEventType.Error, uploader.file, error);
  };

  /**
   * Handler for DSSUploader's onProgress.
   */
  private handleFileProgress = (uploader: DSSUploader, percent: number) => {
    this.emit('change', DSSManagerEventType.Progress, uploader.file, percent);
  };

  /**
   * Handler for DSSUploader's onSuccess.
   */
  private handleFileSuccess = (uploader: DSSUploader) => {
    // NOTE: Reset promise before emit in order for component re-renders to get latest state
    if (!this.isUploading()) {
      this.uploadingPromiseResolve && this.uploadingPromiseResolve();

      this.uploadingPromise = null;
      this.uploadingPromiseResolve = null;
    }

    this.emit(
      'change',
      DSSManagerEventType.Uploaded,
      uploader.file,
      uploader.dssDocument
    );
  };

  /**
   * Returns whether there is at least one file uploading.
   * @returns boolean
   */
  private isUploading() {
    return this.uploaders.some(
      uploader => uploader.status === DSSUploaderStatus.Uploading
    );
  }

  /**
   * Trash a file.
   * Could mean canceling an upload in progress
   * or deleting an upload that has completed.
   */
  public removeFile(file: File) {
    this.uploaders = this.uploaders.filter(uploader => {
      const match = uploader.file === file;
      if (match) {
        // Cancel the upload if in progress, and mark it canceled.
        uploader.cancel();

        // Fire event.
        // Using setTimeout to guarantee that this.uploaders
        // has been assigned when event is emitted.
        setTimeout(() => {
          this.emit(
            'change',
            DSSManagerEventType.Removed,
            uploader.file,
            uploader.dssDocument?.documentGuid
          );
        });
      }

      return !match;
    });
  }

  /**
   * Called when a files are selected by user.
   */
  public async uploadFiles(files: File[]) {
    const { expiration, data, onBeforeUpload } = this.options;

    if (onBeforeUpload) {
      files = await onBeforeUpload(files);
    }

    this.uploaders = this.uploaders.concat(
      files.map(file => {
        const offset =
          expiration !== undefined ? expiration : defaultExpiration;

        const uploader = new DSSUploader(file, {
          data: {
            ...defaultData,
            ...data,
            DeleteOnDate:
              offset === 0
                ? DATE_NEVER
                : getISODateString(new Date(Date.now() + offset))
          },
          onProgress: this.handleFileProgress,
          onSuccess: this.handleFileSuccess,
          onError: this.handleFileError
        });

        this.emit('change', DSSManagerEventType.Uploading, file);

        return uploader;
      })
    );

    if (this.isUploading()) {
      this.uploadingPromise = new Promise(resolve => {
        this.uploadingPromiseResolve = resolve;
      });
    }
  }
}
