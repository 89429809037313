/**
 * Import IE polyfills first.
 *  - AbortController polyfill
 *  - React polyfills
 *
 * @see https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill#supporting-internet-explorer
 */
import 'abortcontroller-polyfill';
import 'promise-polyfill/src/polyfill';

/**
 * Begin standard imports...
 */
import Analytics from 'services/Analytics';
import {
  getUserManager,
  handleUserExpired,
  handleUserUpdate
} from 'services/UserManager';
import { initLogger } from 'services/logger';

/**
 * Global styles need to be imported before any other component styles are
 * imported from the app.
 */
import './global.scss';

/**
 * Override Date.prototype.toISOString to send timezone offset when serializing
 * dates to JSON.
 */
import 'utils/Date.toISOString';

/**
 * Starts loading translation resources
 */
import 'utils/i18n';
import 'whatwg-fetch';

/**
 * React Imports
 */
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';

/**
 * Import App
 */
import App from './App';

// initialize Google Analytics
Analytics.initialize();

// initialize logger
initLogger();

// Login
const userManager = getUserManager();
userManager.events.addUserLoaded(handleUserUpdate);
userManager.events.addUserUnloaded(handleUserUpdate);
userManager.events.addAccessTokenExpired(handleUserExpired);
userManager.getUser().then(handleUserUpdate);

// Mount the app!
ReactDOM.render(
  <App userManager={userManager} />,
  document.getElementById('root')
);
