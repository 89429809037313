import TaskModal from 'components/Modal/TaskModal/TaskModal';

import React from 'react';

import CompleteTaskModal from '../../../components/TaskActions/CompleteTaskModal/CompleteTaskModal';
import DeleteTaskModal from '../../../components/TaskActions/DeleteTaskModal/DeleteTaskModal';
import ReassignTaskModal from '../../../components/TaskActions/ReassignTaskModal/ReassignTaskModal';
import EditTaskModal from '../../../components/TaskActions/TaskModal/TaskModal';
import { ITask } from '../../../interfaces';
import * as TaskModalsEnums from './TaskModals.enums';
import { TTaskModalsProps } from './TaskModals.interfaces';
import {
  mapAssignmentToCompleteTask,
  mapAssignmentToDeleteTask,
  mapAssignmentToEditTask,
  mapAssignmentToReasignTask
} from './TaskModals.utils';

const TaskModals = ({ assignment, modalAction, onClose }: TTaskModalsProps) => {
  const taskActionMap = {
    [TaskModalsEnums.EAction.COMPLETE]: mapAssignmentToCompleteTask,
    [TaskModalsEnums.EAction.DELETE]: mapAssignmentToDeleteTask,
    [TaskModalsEnums.EAction.EDIT]: mapAssignmentToEditTask,
    [TaskModalsEnums.EAction.REASSIGN]: mapAssignmentToReasignTask
  };

  const taskAction = taskActionMap[modalAction as keyof typeof taskActionMap];
  const task = taskAction ? taskAction(assignment) : null;

  return (
    <>
      {modalAction === TaskModalsEnums.EAction.COMPLETE && (
        <CompleteTaskModal onClose={onClose} task={task as ITask} />
      )}

      {modalAction === TaskModalsEnums.EAction.CREATE && (
        <TaskModal onClose={onClose} />
      )}

      {modalAction === TaskModalsEnums.EAction.DELETE && (
        <DeleteTaskModal
          onClose={(isDeleted: boolean) => {
            onClose(isDeleted ? ({} as ITask) : undefined);
          }}
          task={task as ITask}
        />
      )}

      {modalAction === TaskModalsEnums.EAction.EDIT && (
        <EditTaskModal onClose={onClose} task={task as ITask} />
      )}

      {modalAction === TaskModalsEnums.EAction.REASSIGN && (
        <ReassignTaskModal onClose={onClose} task={task as ITask} />
      )}
    </>
  );
};

export default TaskModals;
