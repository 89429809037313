export enum SubStatusEnums {
  DataNeeded = 1,
  DataDelivered,
  ReconciliationPending,
  ReconciliationApprovalNeeded,
  DPAAndSitusingPending,
  DPAAndSitusingApprovalNeeded,
  ReportPending,
  ReportDelivered,
  ConfigurationNeeded,
  SupportNeeded,
  ExecutionNeeded
}
