import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SideNaviagation } from '@ryan/components';

import { useUser } from '../../../contexts/UserContext';
import { Feature } from '../../../interfaces';
import { NavigationGroup } from '../../../interfaces/ISideNavigation';

const SideNavigationComponent: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const activeLink = location.pathname;
  const { executiveAccounts, isFeatureToggled, permissionService, user } =
    useUser();

  // check if user has executive accounts if they have no personal accounts
  const hasAccounts = !!user.accountTree.length || !!executiveAccounts?.length;

  const [SideNavigationData, SetSideNavigationData] = useState<
    NavigationGroup[]
  >([]);

  useEffect(() => {
    SetSideNavigationData(user?.navigationGroups);
  }, [user?.navigationGroups]);

  const SideNavigationDiv =
    permissionService.isRyan() || hasAccounts ? (
      <>
        <SideNaviagation
          activeLink={activeLink}
          navigate={(url: any) => {
            history.push(url);
          }}
          navigationGroups={SideNavigationData}
        ></SideNaviagation>
      </>
    ) : (
      <></>
    );
  return isFeatureToggled(Feature.LeftNav) ? SideNavigationDiv : null;
};
export default SideNavigationComponent;
