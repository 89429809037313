export enum ThirdStepFormEnums {
  YES = 'yes',
  NO = 'no'
}

export const InitialAttachments = {
  addUploaded: [],
  deleteAttachments: [],
  addExisting: []
};
