import React, { FunctionComponent } from 'react';
import { Card } from '@ryan/components';
import './TeamCardSkeleton.scss';

const TeamCard: FunctionComponent = () => (
  <Card className="team-card-skeleton">
    <div className="team-card-skeleton__user-title ry-skeleton" />
    <div className="team-card-skeleton__user-role">
      <div className="team-card-skeleton__user-avatar ry-skeleton" />
      <div className="team-card-skeleton__user-details">
        <div className="ry-skeleton" />
        <div className="ry-skeleton" />
        <div className="ry-skeleton" />
      </div>
    </div>
    <div className="">
      <div className="team-card-skeleton__user-contact">
        <div className="ry-skeleton" />
        <div className="ry-skeleton" />
      </div>
      <div className="team-card-skeleton__user-contact">
        <div className="ry-skeleton" />
        <div className="ry-skeleton" />
      </div>
      <div className="team-card-skeleton__user-contact">
        <div className="ry-skeleton" />
        <div className="ry-skeleton" />
      </div>
    </div>
  </Card>
);

export default TeamCard;
