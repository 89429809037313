import { useUser } from 'hooks';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IDataRequestV2 } from '../../../interfaces';
import DataRequestCompleteModal from '../../DataRequestActions/DataRequestCompleteModal';
import DataRequestDeleteModal from '../../DataRequestActions/DataRequestDeleteModal';
import DataRequestReopenModal from '../../DataRequestActions/DataRequestReopenModal';
import AssignDataUploadModal from '../DataRequestModal/AssignDataUploadModal/AssignDataUploadModal';
import { DataRequestModal } from '../DataRequestModal/DataRequestModal';
import UpdateDataRequestModal from '../DataRequestModal/UpdateDataRequestModal/UpdateDataRequestModal';
import * as DataRequestModalsEnums from './DataRequestModals.enums';
import { TDataRequestModalsProps } from './DataRequestModals.interfaces';
import * as DataRequestModalsUtils from './DataRequestModals.utils';

const DataRequestModals = ({
  assignment,
  modalAction,
  onClose
}: TDataRequestModalsProps) => {
  const { t, i18n, ready: tReady } = useTranslation();
  const userHook = useUser();

  const dataRequestActionMap = {
    [DataRequestModalsEnums.EAction.ASSIGN_DATA_UPLOAD]:
      DataRequestModalsUtils.mapAssignmentToAssignDataUploadRequest,
    [DataRequestModalsEnums.EAction.COMPLETE]:
      DataRequestModalsUtils.mapAssignmentToCompleteDataRequest,
    [DataRequestModalsEnums.EAction.DELETE]:
      DataRequestModalsUtils.mapAssignmentToDeleteDataRequest,
    [DataRequestModalsEnums.EAction.EDIT]:
      DataRequestModalsUtils.mapAssignmentToEditDataRequest,
    [DataRequestModalsEnums.EAction.REOPEN]:
      DataRequestModalsUtils.mapAssignmentToReopenDataRequest
  };

  const dataRequestAction =
    dataRequestActionMap[modalAction as keyof typeof dataRequestActionMap];
  const dataRequest = dataRequestAction ? dataRequestAction(assignment) : null;

  return (
    <>
      {modalAction === DataRequestModalsEnums.EAction.ASSIGN_DATA_UPLOAD && (
        <AssignDataUploadModal
          dataRequest={dataRequest as IDataRequestV2}
          onClose={(isUpdated?: boolean) => {
            onClose(isUpdated ? ({} as IDataRequestV2) : undefined);
          }}
          open
        />
      )}

      {modalAction === DataRequestModalsEnums.EAction.COMPLETE && (
        <DataRequestCompleteModal
          dataRequest={dataRequest as IDataRequestV2}
          onClose={updatedDataRequest => {
            onClose(updatedDataRequest);
          }}
        />
      )}

      {modalAction === DataRequestModalsEnums.EAction.CREATE && (
        <DataRequestModal
          {...{
            ...userHook,
            i18n,
            t,
            tReady
          }}
          isAccountSelect
          onClose={onClose}
        />
      )}

      {modalAction === DataRequestModalsEnums.EAction.DELETE && (
        <DataRequestDeleteModal
          dataRequest={dataRequest as IDataRequestV2}
          onClose={(isDeleted: boolean) => {
            onClose(isDeleted ? ({} as IDataRequestV2) : undefined);
          }}
        />
      )}

      {modalAction === DataRequestModalsEnums.EAction.EDIT && (
        <UpdateDataRequestModal
          dataRequest={dataRequest as IDataRequestV2}
          // HACK: Worklist: Partial updates for editing DR is disabled.
          //    Workaround: Enable edit DR to pull full DR on open
          isFetchCompleteDataRequest
          onClose={(updatedDataRequest?: IDataRequestV2) => {
            onClose(updatedDataRequest);
          }}
        />
      )}

      {modalAction === DataRequestModalsEnums.EAction.REOPEN && (
        <DataRequestReopenModal
          dataRequest={dataRequest as IDataRequestV2}
          onClose={(updatedDataRequest?: IDataRequestV2) => {
            onClose(updatedDataRequest);
          }}
        />
      )}
    </>
  );
};

export default DataRequestModals;
