export enum Feature {
  DisableGhosting = 'f645342f-55fa-4e5b-ab84-3eeba7d5dbc1',
  InternalFiles = '56e1030c-50b2-4b4c-b45a-45b21b6f1767',
  LeftNav = '5b6e538a-eb56-44bf-b177-40bf142b6eaa',
  LimitedCustomView = 'e9f2c28f-3885-4acb-bd48-f11ada635b48',
  LockedFiles = '92e393bb-d030-4552-b971-1e21efb8a654',
  ReleaseNotes = '60209b60-3fa2-49ac-8ca8-4d71c7172d64',
  UnpublishedProjectsReport = '397c805a-35e6-46ab-bce6-1b117edf0b22',
  WorkdayTeamDecouple = '147e5b44-7562-480a-be24-fb27f7b73040'
}

export interface IFeatureToggle {
  featureGuid: Feature;
  featureName: string;
  isEnabled: boolean;
  isSuperAdminEnabled: boolean;
}

// LimitedCustomView
export const MAX_SELECTED_COMPANIES = 300;
