import {
  Logger,
  LoggerConfiguration,
  LogsUserConfiguration,
  datadogLogs
} from '@datadog/browser-logs';
import { ILogger, LogLevel } from '@microsoft/signalr';
import ENV from 'env';
import { scrubSensitiveDataFromURL } from 'utils/scrubSensitiveDataFromURL';

export { datadogLogs } from '@datadog/browser-logs';

let defaultLoggerConfiguration: LoggerConfiguration = {
  level: 'info',
  handler: 'console',
  context: { service: 'portal-ui' }
};

// default initialization configuration - will not send logs to Datadog;
// updated configuration will be passed via ENV variable
let userConfig: LogsUserConfiguration = {
  clientToken: '',
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  sampleRate: 0
};

// parse initialization configuration from ENV variables
try {
  userConfig = JSON.parse(ENV.DATADOG_USER_CONFIG);
} catch {}

// parse default logger configuration from ENV variables
try {
  defaultLoggerConfiguration = JSON.parse(ENV.DATADOG_LOGGER_CONFIG);
} catch {}

/**
 * Initializes the logger.
 */
export const initLogger = () => {
  datadogLogs.init({
    ...userConfig,
    beforeSend: event => {
      event.view.url = scrubSensitiveDataFromURL(event.view.url);
    }
  });
};

/**
 * Creates a new logger with default configuration.
 */
export const createLogger: typeof datadogLogs.createLogger = (
  name,
  confOverrides = {}
) =>
  datadogLogs.createLogger(name, {
    ...defaultLoggerConfiguration,
    ...confOverrides,
    context: {
      ...(defaultLoggerConfiguration.context || {}),
      ...(confOverrides.context || {})
    }
  });

/**
 * Applies metadata to the global logging context.
 *
 * @param user The current user.
 * @param impersonatingGuid An impersonating user GUID.
 */
export const updateGlobalLoggingContext = (
  userGuid?: string | null,
  impersonatingGuid?: string | null
) => {
  if (userGuid) {
    datadogLogs.addLoggerGlobalContext('userGuid', userGuid);
  } else {
    datadogLogs.removeLoggerGlobalContext('userGuid');
  }

  if (impersonatingGuid) {
    datadogLogs.addLoggerGlobalContext(
      'impersonatingUserGuid',
      impersonatingGuid
    );
  } else {
    datadogLogs.removeLoggerGlobalContext('impersonatingUserGuid');
  }
};

/**
 * Default logger.
 */
export const logger = datadogLogs.createLogger(
  'defaultLogger',
  defaultLoggerConfiguration
);

/**
 * Logger for SignalR service. Implements Microsofts ILogger interface and
 * translates to appropriate Datadog logger call.
 */
export class SignalRLogger implements ILogger {
  public logger: Logger;

  constructor() {
    const signalRLogger = datadogLogs.getLogger('signalRLogger');
    this.logger = signalRLogger || createLogger('signalRLogger');
  }

  log(logLevel: LogLevel, message: string): void {
    let logMethod = this.logger.info;

    // map log level to logging method
    switch (logLevel) {
      case LogLevel.Trace:
      case LogLevel.Debug:
        logMethod = this.logger.debug;
        break;
      case LogLevel.Warning:
        logMethod = this.logger.warn;
        break;
      case LogLevel.Error:
      case LogLevel.Critical:
      case LogLevel.None:
        logMethod = this.logger.error;
        break;
      default:
        logMethod = this.logger.info;
    }

    logMethod.call(this.logger, message);
  }
}
