import classNames from 'classnames';
import React from 'react';
import Headroom from 'react-headroom';
import { WithSizeProps, withSize } from 'react-sizeme';
import Breadcrumbs, { IBreadcrumb } from 'components/Breadcrumbs';
import { SwitcherDesktop } from 'components/Switcher';

export interface ISubnavigationProps
  extends React.HTMLAttributes<HTMLDivElement>,
    WithSizeProps {
  crumbs?: IBreadcrumb[];
  positionFixed?: boolean;
  positionTop?: number;
  showAccountSwitcher?: boolean;
  showBreadcrumbs?: boolean;
}

/**
 * This is the sub-navigation with breadcrumbs and the desktop Switcher.
 * If not fixed, it uses Headroom to scroll away as you scroll down the page,
 * but returns as soon as you scroll up.
 * If fixed, the sub-navigation will remain fixed to the top of the page below
 * the navigation.
 */
const Subnavigation = withSize({ monitorHeight: true, monitorWidth: false })(
  ({
    crumbs = [],
    onSize,
    positionFixed = false,
    positionTop = 0,
    showAccountSwitcher = true,
    showBreadcrumbs = true,
    ...rest
  }: ISubnavigationProps) => {
    /**
     * Renders the subnavigation markup.
     */
    const renderSubnavigation = () => (
      <div
        {...rest}
        className={classNames({
          'container-fluid': true,
          'app-header__sub-nav': true,
          'app-header__sub-nav--fixed': positionFixed
        })}
        {...(positionFixed && positionTop
          ? { style: { top: `${positionTop}px` } }
          : {})}
      >
        <div className="row">
          <div className="col-12 col-md-8 ">
            {showBreadcrumbs && <Breadcrumbs crumbs={crumbs} />}
          </div>
          <div className="col-12 col-md-4 app-header__sub-nav-account-switcher">
            {showAccountSwitcher && <SwitcherDesktop />}
          </div>
        </div>
      </div>
    );

    // skip render if there is no subnav content
    if (!showBreadcrumbs && !showAccountSwitcher) return null;

    // render headroom if subnavigation is not fixed to top of page
    return !positionFixed ? (
      <Headroom
        {...(positionTop ? { style: { top: `${positionTop}px` } } : {})}
      >
        {renderSubnavigation()}
      </Headroom>
    ) : (
      renderSubnavigation()
    );
  }
);

Subnavigation.displayName = 'Subnavigation';
export default Subnavigation;
