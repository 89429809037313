import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import history from '../../services/history';
import { IEventWrapperPassthroughProps } from './utils';

import './EventWrapperPassthrough.scss';

const EventWrapperPassthrough: FunctionComponent<
  IEventWrapperPassthroughProps
> = ({ route, userManager }) => {
  const { t: getTextToDisplay } = useTranslation();
  const [isInitialized, setIsInitialized] = useState<boolean | null>(false);

  useEffect(() => {
    const getAuthorizedUser = async () => {
      const authorizedUser = await userManager.getUser();
      if (authorizedUser) {
        history.push(route);
      } else {
        document.body.className =
          `${document.body.className} public-event`.trim();
        setIsInitialized(true);
      }
    };

    getAuthorizedUser();
  }, [route, userManager]);

  return (
    <div>
      {getTextToDisplay(
        `eventWrapperPassthrough.${
          isInitialized ? 'unauthorized' : 'checkingAuthorization'
        }`
      )}
    </div>
  );
};

export default EventWrapperPassthrough;
