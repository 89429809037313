import { Comments, NewComment } from 'components/Comments';
import Drawer from 'components/Drawer';
import { IComment, ITask } from 'interfaces';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

import { MentionsValue } from '@ryan/components';

interface ITaskDrawerProps {
  t: TFunction;
  task: ITask | null;

  comments: IComment[] | null;
  commentsLoading: boolean;

  canComment: boolean;

  onClose: () => void;

  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;
  onEditComment: (comment: IComment) => void;
  onEditCommentChange: (commentText: MentionsValue) => void;
  onEditCommentSave: () => void;
  onEditCommentCancel: () => void;

  onDeleteComment: (deleteComment: IComment) => void;

  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
  onNewCommentChange: (commentText: MentionsValue) => void;
  onNewCommentSave: () => void;
}

export const TaskDrawer: FunctionComponent<ITaskDrawerProps> = props => {
  const {
    t,
    task,
    comments,
    commentsLoading,
    canComment,
    onClose,
    editComment,
    editCommentText,
    editCommentLoading,
    onEditComment,
    onEditCommentChange,
    onEditCommentSave,
    onEditCommentCancel,
    onDeleteComment,
    newCommentText,
    newCommentLoading,
    onNewCommentChange,
    onNewCommentSave
  } = props;

  if (task !== null) {
    return (
      <Drawer className="data-request-drawer" onClose={onClose} open>
        <Drawer.Header>
          <h4 className="ry-h4">{task.engagementDisplayNameShort}</h4>
          <h3 className="ry-h3">{task.title}</h3>
        </Drawer.Header>
        <Drawer.Body>
          <Comments
            canComment={canComment}
            comments={comments}
            editComment={editComment}
            editCommentLoading={editCommentLoading}
            editCommentText={editCommentText}
            engagementGuid={task.engagementGuid}
            loading={commentsLoading}
            onDelete={onDeleteComment}
            onEdit={onEditComment}
            onEditCancel={onEditCommentCancel}
            onEditChange={onEditCommentChange}
            onEditSave={onEditCommentSave}
          />
        </Drawer.Body>
        {canComment && (
          <Drawer.Footer>
            <NewComment
              commentText={newCommentText}
              engagementGuid={task.engagementGuid}
              loading={newCommentLoading}
              onChange={onNewCommentChange}
              onSave={onNewCommentSave}
              t={t}
            />
          </Drawer.Footer>
        )}
      </Drawer>
    );
  }

  return null;
};

export default TaskDrawer;
