import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ProtectedRouter from './ProtectedRouter';
import PublicLayoutContainer from './PublicLayoutContainer';

/**
 * The layout for pages to be protected behind user-authentication but uses the
 * same page body container as the "public" pages.
 */
const PublicLayoutToBeProtected: React.FC<RouteComponentProps> = ({
  location
}) => (
  <PublicLayoutContainer
    hideAppLinks={location?.pathname?.indexOf('/go-to/new-password') > -1}
  >
    <ProtectedRouter />
  </PublicLayoutContainer>
);

export default PublicLayoutToBeProtected;
