import React from 'react';
import { useTranslation } from 'react-i18next';

import { Drawer, IDrawerProps } from '@ryan/components';
import { IDrawerHeaderProps } from '@ryan/components/dist/lib/components/Drawer/DrawerHeader';

import './Drawer.scss';

const CompositeDrawer: React.FC<IDrawerProps> = props => {
  return <Drawer {...props} />;
};

const DrawerHeaderWithI18n: React.FC<IDrawerHeaderProps> = ({
  closeText,
  ...props
}) => {
  const { t } = useTranslation();
  return <Drawer.Header closeText={t('Close')} {...props} />;
};

export type CompositeCompoundDrawerComponent = typeof CompositeDrawer & {
  Header: typeof DrawerHeaderWithI18n;
  Body: typeof Drawer.Body;
  Footer: typeof Drawer.Footer;
};

(CompositeDrawer as CompositeCompoundDrawerComponent).Header =
  DrawerHeaderWithI18n;
(CompositeDrawer as CompositeCompoundDrawerComponent).Body = Drawer.Body;
(CompositeDrawer as CompositeCompoundDrawerComponent).Footer = Drawer.Footer;
const CompoundDrawer = CompositeDrawer as CompositeCompoundDrawerComponent;

export default CompoundDrawer;
