import React, { FunctionComponent, ReactNode } from 'react';

import './ActivityFeed.scss';

export interface IActivityFeedSkeletonProps {
  count?: number;
}

const ActivityFeedSkeleton: FunctionComponent<
  IActivityFeedSkeletonProps
> = props => {
  const { count } = props;
  const activities: ReactNode[] = [];

  for (let i = 0; i < count!; i++) {
    activities.push(
      <tr key={i}>
        <td className="activity-feed__dt">
          <div className="ry-skeleton" />
          <div className="ry-skeleton" />
          <div className="ry-skeleton" />
        </td>
        <td className="activity-feed__activity">
          <div className="activity">
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <table className="activity-feed activity-feed--skeleton">
      <tbody>{activities}</tbody>
    </table>
  );
};

ActivityFeedSkeleton.defaultProps = {
  count: 3
};

export default ActivityFeedSkeleton;
