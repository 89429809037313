import { EventEmitter } from 'events';

import { createContext } from 'react';

import { IDataRequestV2 } from '../../interfaces';

export interface IDataRequestDrawerContext {
  dataRequestDrawerEvents: EventEmitter;
  onDataRequestDrawerOpenHistory: (dataRequest: IDataRequestV2) => void;
  onDataRequestDrawerOpenComments: (dataRequest: IDataRequestV2) => void;
}

export const DataRequestDrawerContext =
  createContext<IDataRequestDrawerContext>({
    dataRequestDrawerEvents: new EventEmitter(),
    onDataRequestDrawerOpenHistory: () => {},
    onDataRequestDrawerOpenComments: () => {}
  });
