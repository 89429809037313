import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

import {
  ContractFileType,
  IButtonLoadingState,
  IContractFile,
  IModalProps
} from '../../interfaces';
import ApiService from '../../services/ApiService';
import Modal from '../Modal';

interface IContractFileUnpublishModalProps
  extends IModalProps,
    WithTranslation {
  contractFile: IContractFile;
  onClose: (isUpdated?: boolean) => void;
}

class ContractFileUnpublishModal extends Component<
  IContractFileUnpublishModalProps,
  IButtonLoadingState
> {
  readonly state = {
    loading: null
  };

  handleUnpublish = async () => {
    const { t, contractFile } = this.props;

    const promise = ApiService.unpublishContractFiles(
      contractFile.engagementGuid,
      [contractFile.engagementContractDocumentGuid]
    )
      .then(response => {
        let displayName;
        if (contractFile.name || contractFile.typeId) {
          if (contractFile.typeId === ContractFileType.Other) {
            displayName = contractFile.name;
          } else {
            displayName = `${t(`contracts.type.${contractFile.typeId}`)} ${
              contractFile.name
            }`;
          }
        }

        if (response.status === 200) {
          pushToast({
            type: 'success',
            title: t('contracts.modal.unpublish.success.title'),
            content: (
              <Trans i18nKey="contracts.modal.unpublish.success.content">
                <b>{{ displayName }}</b> has been published and is ready for
                client to view.
              </Trans>
            )
          });

          if (this.props.onClose) {
            this.props.onClose(true);
            this.resetState();
          }
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          pushToast({
            type: 'error',
            title: t('serverError.title'),
            content: t('serverError.content')
          });

          if (this.props.onClose) {
            this.props.onClose();
          }
        }
      });

    this.setState({ loading: promise });
  };

  resetState() {
    this.setState({ loading: null });
  }

  render() {
    const { t, open, onClose } = this.props;
    const { loading } = this.state;
    return (
      <Modal
        onClose={onClose}
        open={open}
        title={t('contracts.modal.unpublish.title')}
      >
        <p className="contract-file-modal__unpublish-description">
          {t('contracts.modal.unpublish.description')}
        </p>
        <ButtonGroup>
          <Button
            onClick={() => {
              onClose();
            }}
            text={t('contracts.modal.unpublish.cancel')}
            type="button"
          />
          <Button
            loading={loading}
            negative
            onClick={this.handleUnpublish}
            text={t('contracts.modal.unpublish.yes')}
            type="button"
          />
        </ButtonGroup>
      </Modal>
    );
  }
}

export default withTranslation()(ContractFileUnpublishModal);
