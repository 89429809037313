import { createContext } from 'react';
import { IContractFile, IDirectoryItem, IFile, IInvoice } from 'interfaces';

export interface IDownloadContext {
  onDownloadFiles: (files: IFile[], engagementGuid?: string) => void;
  onDownloadDirectoryItems: (
    directoryItems: IDirectoryItem[],
    engagementGuid: string
  ) => void;
  onDownloadContract: (contract: IContractFile) => void;
  onDownloadInvoice: (invoice: IInvoice) => void;
}

export const DownloadContext = createContext<IDownloadContext>({
  onDownloadFiles: () => {},
  onDownloadDirectoryItems: () => {},
  onDownloadContract: () => {},
  onDownloadInvoice: () => {}
});

export default DownloadContext;
