import React, { FunctionComponent } from 'react';
import { Card } from '@ryan/components';
import './DataRequestCardSkeleton.scss';

const DataRequestCardSkeleton: FunctionComponent = () => (
  <Card className="data-request-card data-request-card--skeleton">
    <div className="data-request-card__content">
      <div className="row">
        <div className="col-12 col-md-8 col-lg-7">
          {/* engagement name, data request title */}
          <div className="ry-skeleton sk-super-title" />
          <div className="ry-skeleton sk-title" />

          {/* description */}
          <div className="sk-p">
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
          </div>

          {/* specs */}
          <div className="sk-p">
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
          </div>

          <div className="data-request-card__comment-tablet">
            <hr />
            <div className="data-request-card__comment-tablet-flex">
              {/* buttons */}
              <div className="ry-skeleton" />
              <div className="ry-skeleton" />
            </div>
          </div>
        </div>
        <div className="data-request-card__aside col-12 col-md-4 offset-lg-1">
          {/* transfer data or actions button */}
          <div className="ry-skeleton sk-cta" />

          <div className="well">
            {/* status */}
            <div className="ry-skeleton sk-status" />
            <hr />
            <ul className="row labeled-list">
              <li className="col-12 col-sm-6">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
              <li className="col-12 col-sm-6">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
              <li className="col-12 col-sm-6">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
              <li className="col-12 col-sm-6">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
              <li className="col-12 col-sm-6">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="data-request-card__comment-mobile">
      <div className="data-request-card__comment-mobile-border">
        <div className="ry-skeleton" />
      </div>
      <div className="data-request-card__comment-mobile-border">
        <div className="ry-skeleton" />
      </div>
    </div>
  </Card>
);

export default DataRequestCardSkeleton;
