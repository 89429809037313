import * as yup from 'yup';

import { withFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  InfoWell,
  Multiselect,
  TextInput,
  Textarea
} from '@ryan/components';

import { withUser } from '../../../../../contexts/UserContext';
import { FolderSelection, IJurisdictions } from '../../../../../interfaces';
import ApiService from '../../../../../services/ApiService';
import * as FolderValidationUtils from '../../../../../utils/folderValidation/folderValidation.utils';
import { formikFieldProps } from '../../../../../utils/forms';
import pushServerErrorToast from '../../../../../utils/pushServerErrorToast';
import SelectFolder from '../../../../SelectFolder/SelectFolder';
import {
  IDataRequestFormProps,
  IDataRequestFormValues,
  IDataRequestInnerFormProps,
  IJurisdiction
} from './utils';

const FirstStepFormOther: React.FC<IDataRequestInnerFormProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);
  const [jurisdictionsFetching, setJurisdictionsFetching] = useState(false);

  const {
    dataSpecs,
    description,
    engagement,
    errors,
    folders,
    foldersFetching,
    handleSubmit,
    jurisdictions,
    jurisdictionsAll,
    isSubmitting,
    onNextStepClick,
    setDataSpecs,
    setFieldValue,
    setDescription,
    setJurisdictions,
    setJurisdictionsAll,
    setStatus,
    setTitle,
    setTouched,
    setTransferDestination,
    status,
    t: getTextToDisplay,
    title,
    transferDestination,
    values
  } = props;

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const transferDestinationError =
      transferDestination === null
        ? null
        : transferDestination?.folderName.trim().length === 0
        ? getTextToDisplay(
            'modal.dataRequestModal.fields.transferDestination.required'
          )
        : null;

    setStatus({
      ...status,
      transferDestinationError
    });

    setTouched({
      dataRequestTitle: true,
      dataSpecs: true
    });
    handleSubmit();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.value;
    setFieldValue('dataRequestTitle', selected);
    setTitle(selected);
  };

  const handleDataSpecsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const selected = e.target.value;
    setFieldValue('dataSpecs', selected);
    setDataSpecs(selected);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const selected = e.target.value;
    setFieldValue('descriptionOptional', selected);
    setDescription(selected);
  };

  const handleJurisdictionsChange = (data: string[]) => {
    setFieldValue('selectedJurisdictions', data);
    setJurisdictions(data);
  };
  const jurisdictionsDropdownProps = {
    ...formikFieldProps('selectedJurisdictions', props),
    label: getTextToDisplay(
      'modal.dataRequestModal.fields.jurisdictionsOptional.label'
    ),
    options: values.jurisdictions as IJurisdiction[],
    onChange: (data: any) => handleJurisdictionsChange(data),
    value: jurisdictions || values.selectedJurisdictions
  };

  const fetchJurisdictions = useCallback(
    async (engagementGuid: string) => {
      setJurisdictionsFetching(true);
      if (!jurisdictionsAll.length) {
        try {
          const response = await ApiService.getJurisdictionsV2(engagementGuid);
          const jurisdictionsData =
            response.data as unknown as IJurisdictions[];

          const jurisdictions = jurisdictionsData.map(
            (type: IJurisdictions) => ({
              isPreselected: type.isPreselected,
              label: type.name,
              value: type.jurisdictionGuid
            })
          );

          setJurisdictionsAll(jurisdictions as unknown as IJurisdictions[]);

          setFieldValue('jurisdictions', jurisdictions);

          const preselectedJurisdictions = jurisdictions
            .filter(jurisdiction => jurisdiction.isPreselected)
            .map(jurisdiction => jurisdiction.value);

          if (preselectedJurisdictions.length) {
            setFieldValue('selectedJurisdictions', preselectedJurisdictions);
            setJurisdictions(preselectedJurisdictions);
          }
        } catch (error) {
          setFieldValue('jurisdictions', []);
          pushServerErrorToast();
        }
      } else {
        setFieldValue('jurisdictions', jurisdictionsAll);
      }
      setJurisdictionsFetching(false);
    },
    [jurisdictionsAll, setFieldValue, setJurisdictions, setJurisdictionsAll]
  );

  useEffect(() => {
    if (title) {
      setFieldValue('dataRequestTitle', title);
    }
    if (dataSpecs) {
      setFieldValue('dataSpecs', dataSpecs);
    }
    if (description) {
      setFieldValue('descriptionOptional', description);
    }
    if (transferDestination) {
      setFieldValue('defaultFolder', transferDestination);
    }
  }, [
    dataSpecs,
    description,
    engagement,
    setFieldValue,
    title,
    transferDestination
  ]);

  useEffect(() => {
    if (engagement?.engagementGuid) {
      fetchJurisdictions(engagement?.engagementGuid);
    }
  }, [engagement, fetchJurisdictions]);

  const isValid =
    !isSubmitting &&
    !foldersFetching &&
    !jurisdictionsFetching &&
    !Boolean(status.transferDestinationError);

  return (
    <>
      {status.error}
      <h2 className="step-title">
        {getTextToDisplay('modal.dataRequestModal.steps.step-2.title')}
      </h2>
      <InfoWell
        accountName={engagement?.accountName || ''}
        dataRequestType={getTextToDisplay('dataRequest.dataTypes.other')}
        projectName={engagement?.engagementDisplayNameLong || ''}
      />
      <form autoComplete="off" ref={formRef}>
        <TextInput
          {...formikFieldProps('dataRequestTitle', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.requestTitle.label'
          )}
          onChange={e => handleTitleChange(e)}
        />
        <Multiselect
          {...jurisdictionsDropdownProps}
          disabled={jurisdictionsFetching}
          noOptionText={getTextToDisplay('dataRequest.noOptionsLabel')}
          notFoundLabel={getTextToDisplay('dataRequest.notFoundLabel')}
          placeholder={''}
          searchBoxPlaceholder={getTextToDisplay(
            'modal.dataRequestModal.search'
          )}
          selectAllLabel={getTextToDisplay(
            'modal.dataRequestModal.selectAllLabel'
          )}
          selectionLabel={getTextToDisplay('dataRequest.selectionLabel')}
          showAdditionalButtons={false}
        />
        <Textarea
          {...formikFieldProps('dataSpecs', props)}
          helperText={getTextToDisplay(
            'modal.dataRequestModal.fields.dataSpecs.helperText'
          )}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.dataSpecs.label'
          )}
          maxLength={600}
          onChange={e => handleDataSpecsChange(e)}
          value={dataSpecs}
        />
        <Textarea
          {...formikFieldProps('descriptionOptional', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.descriptionOptional.label'
          )}
          maxLength={600}
          onChange={e => handleDescriptionChange(e)}
          value={description}
        />
        <SelectFolder
          disabled={folders === null}
          feedback={status.transferDestinationError || errors.defaultFolder}
          folders={folders || []}
          invalid={!!status.transferDestinationError || !!errors.defaultFolder}
          isHideRyanInternal={true}
          label={getTextToDisplay(
            'dataRequest.modal.fields.defaultFolder.label'
          )}
          moveFileCount={2}
          onChange={(folder: FolderSelection) => {
            if (status.transferDestinationError) {
              setStatus({
                ...status,
                transferDestinationError: null
              });
            }
            if (folder?.folderGuid) {
              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            } else {
              const transferDestinationError =
                FolderValidationUtils.validateFolderInput(
                  folder,
                  folders
                ) as string;

              if (Boolean(transferDestinationError)) {
                setStatus({
                  ...status,
                  transferDestinationError: getTextToDisplay(
                    transferDestinationError
                  )
                });
              }

              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            }
          }}
          rootName={engagement ? engagement.engagementDisplayNameShort : ''}
          value={values.defaultFolder}
        />
        <ButtonGroup>
          <Button
            loading={status.loading}
            onClick={() => onNextStepClick(1)}
            text={getTextToDisplay('modal.dataRequestModal.back')}
            type="submit"
            variant={EButtonVariant.SECONDARY}
          />
          <Button
            disabled={!isValid}
            loading={status.loading}
            onClick={e => handleFormSubmit(e)}
            text={getTextToDisplay('modal.dataRequestModal.next')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const defaultValues: IDataRequestFormValues = {
  dataRequestTitle: '',
  dataSpecs: '',
  defaultFolder: null,
  engagement: null,
  jurisdictions: [],
  selectedJurisdictions: []
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: () => defaultValues,
    validateOnMount: false,

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: ({
      folders,
      t: getTextToDisplay
    }: IDataRequestFormProps) =>
      yup.object({
        dataRequestTitle: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.requestTitle.required'
            )
          )
          .max(
            50,
            getTextToDisplay('modal.dataRequestModal.fields.requestTitle.max')
          ),
        dataSpecs: yup
          .string()
          .required(
            getTextToDisplay('modal.dataRequestModal.fields.dataSpecs.required')
          )
          .max(
            600,
            getTextToDisplay('modal.dataRequestModal.fields.dataSpecs.max')
          ),
        descriptionOptional: yup
          .string()
          .max(
            600,
            getTextToDisplay(
              'modal.dataRequestModal.fields.descriptionOptional.max'
            )
          ),
        selectedJurisdictions: yup.array()
      }),

    handleSubmit: async (values, formik) => {
      formik.props.onNextStepClick(3);
    }
  })(withUser(FirstStepFormOther))
);
