import Datepicker from 'components/Datepicker';
import { RadioButton, RadioButtonGroup } from 'components/RadioButton';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  IReportBuilderSection,
  Permission as P,
  ReportSections
} from 'interfaces';
import {
  formikCheckboxProps,
  formikDatepickerProps,
  formikFieldProps
} from 'utils/forms';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Checkbox, Switch } from '@ryan/components';

import { ReportBuilderPanel } from './ReportBuilderPanel';
import { getPanelStatus } from './reportingHelpers';

enum SavingsDateType {
  AsOf = '1',
  Range = '2'
}

interface IContentSectionProps
  extends IReportBuilderSection,
    WithTranslation,
    WithUser {}

export class ContentSection extends Component<IContentSectionProps> {
  fieldsToValidate = ['contentSavingsStartDate', 'contentSavingsEndDate'];

  render() {
    const {
      t,
      permissionService: ps,
      formik,
      onToggle,
      selectedPanel
    } = this.props;

    const {
      selectedPublishedEngagements,
      audienceType,
      audienceOnBehalfOf,
      contentSavingsType,
      contentSavingsCustomDate
    } = formik.values;

    // Can only include content sections if user has permission.
    let canIncludeSavings = ps.hasPermission(P.SavingsSummaryView);
    let canIncludeDataRequests = ps.hasPermission(P.DataRequestsView);
    let canIncludeTasks = ps.hasPermission(P.TasksView);
    let canIncludeLearnings = ps.hasPermission(P.LearningsView);

    // If a client user was chosen, we will need to consider their permissions
    // as well. API returns the AND (user && audience).
    if (audienceType === '2' && audienceOnBehalfOf !== null) {
      canIncludeSavings = audienceOnBehalfOf.canReportIncludeSavings;
      canIncludeDataRequests = audienceOnBehalfOf.canReportIncludeDataRequests;
      canIncludeTasks = audienceOnBehalfOf.canReportIncludeTasks;
      canIncludeLearnings = audienceOnBehalfOf.canReportIncludeLearnings;
    }

    // "Full" Reports may contain all content sections.
    // We must have at least one selected engagement that was published before the report's end date.
    const isFullReport = selectedPublishedEngagements.some(
      e => !e.isPrePublished
    );

    return (
      <ReportBuilderPanel
        formik={formik}
        id={ReportSections.Content}
        onToggle={onToggle}
        selectedSection={selectedPanel}
        status={getPanelStatus(this.fieldsToValidate, formik)}
        title={t('reportBuilder.content.title')}
      >
        <div className="report-builder__content">
          <div className="helper-text">
            {t('reportBuilder.content.helperText1')}
          </div>
          <div className="helper-text">
            {canIncludeSavings
              ? t('reportBuilder.content.helperText2')
              : t('reportBuilder.content.helperText2NoSavings')}
          </div>

          {/* Savings */}
          {canIncludeSavings && (
            <div className="checkbox-panel">
              <Checkbox
                {...formikCheckboxProps('contentSavingsSummary', formik)}
                label={t('reportBuilder.content.savingsSummary.title')}
                value="contentSavingsSummary"
              />
              <p>{t('reportBuilder.content.savingsSummary.description')}</p>
              <div>
                <div className="switch">
                  <label>
                    {t('reportBuilder.content.customSavingsDate')}
                    <Switch
                      checked={contentSavingsCustomDate}
                      name="contentSavingsCustomDate"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </label>
                </div>
                {contentSavingsCustomDate && (
                  <>
                    <RadioButtonGroup
                      {...formikFieldProps('contentSavingsType', formik)}
                    >
                      <RadioButton
                        label={t('reportBuilder.timeframe.asOf')}
                        value={SavingsDateType.AsOf}
                      />
                      <RadioButton
                        label={t('reportBuilder.timeframe.dateRange')}
                        value={SavingsDateType.Range}
                      />
                    </RadioButtonGroup>
                    <div className="report-builder__date-range">
                      {contentSavingsType === SavingsDateType.Range && (
                        <Datepicker
                          {...formikDatepickerProps(
                            'contentSavingsStartDate',
                            formik
                          )}
                          label={t('reportBuilder.timeframe.startDate.label')}
                        />
                      )}
                      <Datepicker
                        {...formikDatepickerProps(
                          'contentSavingsEndDate',
                          formik
                        )}
                        label={
                          contentSavingsType === SavingsDateType.Range
                            ? t('reportBuilder.timeframe.endDate.label')
                            : t('reportBuilder.timeframe.asOf')
                        }
                        popperPlacement={
                          contentSavingsType === SavingsDateType.Range
                            ? 'bottom-end'
                            : 'bottom-start'
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {isFullReport && (
            <>
              {/* Team */}
              <div className="checkbox-panel">
                <Checkbox
                  {...formikCheckboxProps('contentProjectOverview', formik)}
                  label={t('reportBuilder.content.project.title')}
                  value="contentProjectOverview"
                />
                <p>{t('reportBuilder.content.project.description')}</p>
              </div>

              {/* Data Requests */}
              {canIncludeDataRequests && (
                <div className="checkbox-panel">
                  <Checkbox
                    {...formikCheckboxProps('contentDataRequests', formik)}
                    label={t('reportBuilder.content.dataRequests.title')}
                    value="contentDataRequests"
                  />
                  <p>{t('reportBuilder.content.dataRequests.description')}</p>
                </div>
              )}

              {/* Tasks */}
              {canIncludeTasks && (
                <div className="checkbox-panel">
                  <Checkbox
                    {...formikCheckboxProps('contentTasks', formik)}
                    label={t('reportBuilder.content.tasks.title')}
                    value="contentTasks"
                  />
                  <p>{t('reportBuilder.content.tasks.description')}</p>
                </div>
              )}

              {/* Learnings */}
              {canIncludeLearnings && (
                <div className="checkbox-panel">
                  <Checkbox
                    {...formikCheckboxProps('contentLearnings', formik)}
                    label={t('reportBuilder.content.learnings.title')}
                    value="contentLearnings"
                  />
                  <p>{t('reportBuilder.content.learnings.description')}</p>
                </div>
              )}
            </>
          )}
        </div>
      </ReportBuilderPanel>
    );
  }
}

export default withTranslation()(withUser(ContentSection));
