import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import { IDropdownProps } from './utils';

import './Dropdown.scss';

export const Dropdown = ({
  error,
  dropdownOptions,
  id,
  isDisabled,
  label,
  onChange,
  touched,
  value
}: IDropdownProps) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const { t: textToDisplay } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isRemoveOption = value?.label === 'Remove';

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="dropdown__label">{label}</div>
      <div
        aria-disabled={isDisabled}
        className={classNames('dropdown__selection-label', {
          error: touched && error
        })}
        onClick={() => !isDisabled && setIsDropdownShown(!isDropdownShown)}
        role="button"
      >
        {value?.label ? (
          <div
            className={classNames({
              'dropdown__selection-label__remove-option': isRemoveOption
            })}
          >
            {value.label}
          </div>
        ) : (
          <div className="dropdown__selection-label__placeholder">
            {textToDisplay('Select')}
          </div>
        )}

        <button
          aria-label="show-dropdown-button"
          className="dropdown__selection-label__caret-button"
          disabled={isDisabled}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            setIsDropdownShown(!isDropdownShown);
          }}
        >
          <Icon name={isDropdownShown ? 'chevron-up' : 'chevron-down'} />
        </button>
      </div>
      {touched && error && (
        <div className="ry-autocomplete__feedback">
          <Icon name={'error'} /> {error}
        </div>
      )}
      <ul
        className={classNames('dropdown__list', {
          'dropdown__list--show': isDropdownShown,
          'dropdown__list--hide': !isDropdownShown
        })}
        data-testid="dropdown-list"
        onMouseDown={event => event.preventDefault()}
      >
        {dropdownOptions.map((option, index) => {
          return (
            <li className="dropdown__list--show__option" key={`${id}_${index}`}>
              <button
                className={option.className}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  onChange(option.value);
                  setIsDropdownShown(false);
                }}
              >
                {option.label}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
