import ENV from 'env';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Icon, Switch, makeTableCheckboxFilter } from '@ryan/components';

import AccountHierarchy from '../../../components/AccountHierarchy/AccountHierarchy';
import Empty from '../../../components/Empty';
import SearchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import { PermissionService } from '../../../contexts/UserContext';
import {
  EngagementStatus,
  IHistoricalSavingsSummary,
  IReportBuilderSection,
  IReportEngagement,
  Permission,
  ReportSections
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import history from '../../../services/history';
import debouncedSearch from '../../../utils/debouncedSearch';
import getSortParam from '../../../utils/getSortParm';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import { ReportBuilderPanel } from './ReportBuilderPanel';
import { getPanelStatus, panelHasNoErrors } from './reportingHelpers';

interface IProjectsSectionProps extends IReportBuilderSection, WithTranslation {
  customViewGuid: string;
  userGuid: string;
  hasHistoricalProjects: boolean;
  permissionService: PermissionService;
}

interface IProjectsSectionState {
  /**
   * Published Projects
   */
  publishedProjectsLoading: boolean;
  publishedProjects: IReportEngagement[];
  publishedProjectsSearchTerm: string;
  publishedProjectsFiltered: {
    isActive: string[];
  };
  publishedProjectsSorted?: {
    id: string;
    desc: boolean;
  } | null;

  /**
   * Historical Savings Projects
   */
  historicalSavingsProjectsLoading: boolean;
  historicalSavingsProjects: IHistoricalSavingsSummary[] | null;
  historicalSavingsProjectsCount: number;
  historicalSavingsProjectsSearchTerm: string;
  historicalSavingsProjectsSorted?: {
    id: string;
    desc: boolean;
  } | null;
}

export class ProjectsSection extends Component<
  IProjectsSectionProps,
  IProjectsSectionState
> {
  fieldsToValidate = [
    'selectedPublishedEngagements',
    'selectedHistoricalSavingsEngagements'
  ];

  private _isMounted = false;

  private sourceEngagements?: CancelTokenSource;
  private sourceSavingsSummary?: CancelTokenSource;

  readonly state: IProjectsSectionState = {
    /**
     * Published Projects
     */
    publishedProjectsLoading: false,
    publishedProjects: [],
    publishedProjectsSearchTerm: '',
    publishedProjectsFiltered: {
      isActive: [
        EngagementStatus.Active.toString(),
        EngagementStatus.InActive.toString()
      ]
    },
    publishedProjectsSorted: {
      id: 'engagementDisplayNameLong',
      desc: false
    },

    /**
     * Historical Savings Projects
     */
    historicalSavingsProjectsLoading: false,
    historicalSavingsProjects: null,
    historicalSavingsProjectsCount: 0,
    historicalSavingsProjectsSearchTerm: '',
    historicalSavingsProjectsSorted: {
      id: 'engagementName',
      desc: false
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchPublishedProjects();
    this.fetchHistoricalSavingsPublishedProjects();
  }

  componentDidUpdate(prevProps: IProjectsSectionProps) {
    const prevValues = prevProps.formik.values;
    const { values } = this.props.formik;
    if (
      this.props.customViewGuid !== prevProps.customViewGuid ||
      values.timeframeType !== prevValues.timeframeType ||
      values.timeframeStartDate !== prevValues.timeframeStartDate ||
      values.timeframeEndDate !== prevValues.timeframeEndDate ||
      values.audienceType !== prevValues.audienceType ||
      values.audienceOnBehalfOf !== prevValues.audienceOnBehalfOf
    ) {
      this.fetchPublishedProjects();
      this.fetchHistoricalSavingsPublishedProjects();
    }
  }

  componentWillUnmount() {
    this.sourceEngagements?.cancel();
    this.sourceSavingsSummary?.cancel();
    this._isMounted = false;
  }

  /**
   * Published Projects
   */
  handlePublishProjectSearch = debouncedSearch(
    (publishedProjectsSearchTerm: string) => {
      this.setState({ publishedProjectsSearchTerm });
    },
    (publishedProjectsSearchTerm: string) => {
      this.fetchPublishedProjects({ publishedProjectsSearchTerm });
    }
  );

  fetchPublishedProjects(updates?: Partial<IProjectsSectionState>) {
    this.setState(
      { ...(updates as IProjectsSectionState), publishedProjectsLoading: true },
      async () => {
        const { customViewGuid, formik } = this.props;
        const {
          audienceType,
          audienceOnBehalfOf,
          timeframeStartDate,
          timeframeEndDate
        } = formik.values;
        const {
          publishedProjectsSearchTerm,
          publishedProjectsSorted,
          publishedProjectsFiltered
        } = this.state;

        try {
          this.sourceEngagements?.cancel();
          this.sourceEngagements = ApiService.CancelToken.source();

          const {
            data: { results: publishedProjects }
          } = await ApiService.getEngagementsForCustomViewAndTimeTravel(
            customViewGuid,
            {
              searchTerm: publishedProjectsSearchTerm,
              sort: getSortParam(publishedProjectsSorted),
              // There's no filter for active/inactive if neither are checked or if both are checked.
              // We only send something if only one is checked.
              isActive:
                publishedProjectsFiltered.isActive.length === 1
                  ? publishedProjectsFiltered.isActive[0]
                  : undefined,
              // We only care about an audience if the `audienceType` is "Client"
              // and we have a selected user.
              sharedWithUser:
                audienceType === '2' && audienceOnBehalfOf !== null
                  ? audienceOnBehalfOf.memberGuid
                  : undefined,
              endDate: timeframeEndDate || new Date(),
              startDate: timeframeStartDate,
              pageNumber: 1,
              itemsPerPage: 500
            },
            this.sourceEngagements.token
          );

          this.setState({ publishedProjects });

          // Update the selected engagements, if appropriate.
          const selectedProjects: IReportEngagement[] = [];

          // Helper to select engagements if present in results.
          const select = (engagementGuid: string) => {
            const engagement = publishedProjects.find(
              e => e.engagementGuid === engagementGuid
            );
            if (engagement) {
              selectedProjects.push(engagement);
            }
          };

          /**
           * If we navigated here from the Project page, then there may be an
           * engagementGuid behind the hash. We'll grab that and go ahead and
           * select the engagement for convenience. Then let's clear the hash.
           * @todo This won't work with inactive projects,
           * because the first fetch will filter them out!
           */
          const hashEngagementGuid = window.location.hash.substr(1);
          if (hashEngagementGuid) {
            select(hashEngagementGuid);
            history.push({ ...history.location, hash: '' });
          }

          // Otherwise, let's try to maintain any user selections.
          // If any of our previous selections exist in our new results, select them.
          formik.values.selectedPublishedEngagements.forEach(e => {
            select(e.engagementGuid);
          });

          // Update selected projects in form.
          formik.setFieldValue(
            'selectedPublishedEngagements',
            selectedProjects
          );
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({
              publishedProjectsLoading: false
            });
          }
        }
      }
    );
  }

  handlePublishedProjectsFilterChange = (publishedProjectsFiltered: any) => {
    this.fetchPublishedProjects({ publishedProjectsFiltered });
  };

  handlePublishedProjectsSelectChange = (selected: string[]) => {
    const { formik } = this.props;
    const { publishedProjects } = this.state;
    formik.setFieldValue(
      'selectedPublishedEngagements',
      publishedProjects.filter(
        project => selected.indexOf(project.engagementGuid) > -1
      )
    );
  };

  handlePublishedProjectsSort = (publishedProjectsSorted: any) => {
    this.fetchPublishedProjects({ publishedProjectsSorted });
  };

  /**
   * Historical Savings Projects
   */
  handleHistoricalSavingsProjectSearch = debouncedSearch(
    (historicalSavingsProjectsSearchTerm: string) => {
      this.setState({ historicalSavingsProjectsSearchTerm });
    },
    (historicalSavingsProjectsSearchTerm: string) => {
      this.fetchHistoricalSavingsPublishedProjects({
        historicalSavingsProjectsSearchTerm
      });
    }
  );

  fetchHistoricalSavingsPublishedProjects(
    updates?: Partial<IProjectsSectionState>
  ) {
    const { customViewGuid, permissionService } = this.props;

    if (
      permissionService &&
      !permissionService.hasPermission(Permission.SavingsSummaryView)
    ) {
      return;
    }
    this.setState(
      {
        ...(updates as IProjectsSectionState),
        historicalSavingsProjectsLoading: true
      },
      async () => {
        const {
          historicalSavingsProjectsSearchTerm,
          historicalSavingsProjectsSorted,
          historicalSavingsProjects,
          historicalSavingsProjectsCount
        } = this.state;

        try {
          this.sourceSavingsSummary?.cancel();
          this.sourceSavingsSummary = ApiService.CancelToken.source();

          const {
            data: { results, totalResults }
          } = await ApiService.getHistoricalSavingsSummaries(customViewGuid, {
            searchTerm: historicalSavingsProjectsSearchTerm,
            sort: getSortParam(historicalSavingsProjectsSorted),
            pageNumber: 1,
            itemsPerPage: 500
          });

          this.setState({
            historicalSavingsProjects: results,
            historicalSavingsProjectsCount: historicalSavingsProjects
              ? historicalSavingsProjectsCount
              : totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({ historicalSavingsProjectsLoading: false });
          }
        }
      }
    );
  }

  handleHistoricalSavingsProjectsSelectChange = (selected: string[]) => {
    const { formik } = this.props;
    const { historicalSavingsProjects } = this.state;
    formik.setFieldValue(
      'selectedHistoricalSavingsEngagements',
      historicalSavingsProjects?.filter(
        project => selected.indexOf(project.historicalSavingsSummaryGuid) > -1
      ) || []
    );
  };

  handleHistoricalSavingsProjectsSort = (
    historicalSavingsProjectsSorted: any
  ) => {
    this.fetchHistoricalSavingsPublishedProjects({
      historicalSavingsProjectsSorted
    });
  };

  handleNext = () => {
    const { formik, onNext } = this.props;
    if (onNext && panelHasNoErrors(this.fieldsToValidate, formik)) {
      onNext(0, formik);
    }
  };

  handleHistoricalSavingsSwitchToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { formik } = this.props;
    const { historicalSavingsProjects } = this.state;
    const isEnabled = e.target.checked;

    formik.setFieldValue('manageHistoricalSavingsEngagements', isEnabled);

    // Select all by default when enabled.
    // Clear selections when disabled.
    formik.setFieldValue(
      'selectedHistoricalSavingsEngagements',
      isEnabled ? historicalSavingsProjects || [] : []
    );
  };

  render() {
    const { t, formik, onToggle, selectedPanel, hasHistoricalProjects } =
      this.props;
    const {
      publishedProjectsLoading,
      publishedProjects,
      publishedProjectsSearchTerm,
      publishedProjectsFiltered,
      publishedProjectsSorted,

      historicalSavingsProjectsLoading,
      historicalSavingsProjectsSearchTerm,
      historicalSavingsProjects,
      historicalSavingsProjectsSorted
    } = this.state;
    const {
      selectedPublishedEngagements,
      manageHistoricalSavingsEngagements,
      selectedHistoricalSavingsEngagements
    } = formik.values;

    return (
      <ReportBuilderPanel
        formik={formik}
        id={ReportSections.Projects}
        onNext={this.handleNext}
        onToggle={onToggle}
        selectedSection={selectedPanel}
        status={getPanelStatus(this.fieldsToValidate, formik)}
        title={t('reportBuilder.projects.title')}
      >
        {/* Published Projects */}
        <div className="inner-panel report-builder__projects">
          <SearchInput
            loading={publishedProjectsLoading}
            onChange={this.handlePublishProjectSearch}
            value={publishedProjectsSearchTerm}
          />

          <Table<IReportEngagement, string>
            columns={[
              {
                id: 'accountHierarchy',
                label: '',
                align: 'center',
                width: '3rem',
                render: (row: IReportEngagement) => (
                  <AccountHierarchy hierarchy={row.accountHierarchy} />
                )
              },
              {
                id: 'engagementDisplayNameLong',
                label: t('reportBuilder.projects.columns.project'),
                sortable: true,
                render: (row: IReportEngagement) => (
                  <div className="bs">
                    <b>{row.engagementDisplayNameLong}</b>
                    <small>{row.accountName}</small>
                  </div>
                )
              },
              {
                id: 'isActive',
                label: t('projectOverview.columns.isActive'),
                render: (row: IReportEngagement) => (
                  <div
                    className={`pill ${row.isActive ? 'active' : 'inactive'}`}
                  >
                    {t(row.isActive ? 'Active' : 'Inactive')}
                  </div>
                ),
                filter: makeTableCheckboxFilter([
                  {
                    value: EngagementStatus.Active.toString(),
                    label: t('Active')
                  },
                  {
                    value: EngagementStatus.InActive.toString(),
                    label: t('Inactive')
                  }
                ]),
                filterActive: (value: string[]) => value.length > 0
              }
            ]}
            data={publishedProjects}
            filtered={publishedProjectsFiltered}
            loading={publishedProjectsLoading}
            loadingCount={5}
            onFilterChange={this.handlePublishedProjectsFilterChange}
            onSelectChange={this.handlePublishedProjectsSelectChange}
            onSortChange={this.handlePublishedProjectsSort}
            renderEmpty={() => (
              <Empty icon="project-hidden">
                {t('reportBuilder.projects.empty')}
              </Empty>
            )}
            rowClassName={(row: IReportEngagement) =>
              `stacks-${row.accountHierarchy.length}`
            }
            rowId="engagementGuid"
            selected={selectedPublishedEngagements.map(e => e.engagementGuid)}
            sorted={publishedProjectsSorted}
          />
        </div>

        {'selectedPublishedEngagements' in formik.touched &&
          'selectedPublishedEngagements' in formik.errors && (
            <div className="ry-text-input__feedback">
              <Icon name="error" />
              {formik.errors.selectedPublishedEngagements}
            </div>
          )}

        {/* Historical Savings Projects */}
        {hasHistoricalProjects &&
          (!this.props.permissionService ||
            this.props.permissionService.hasPermission(
              Permission.SavingsSummaryView
            )) && (
            <div className="report-builder__historical-projects-toggle">
              <div className="title-section">
                <label>
                  {t('reportBuilder.projects.historicalSavings.title', {
                    ryanPlatform: ENV.RYAN_PLATFORM
                  })}
                  <Switch
                    checked={formik.values.manageHistoricalSavingsEngagements}
                    onChange={this.handleHistoricalSavingsSwitchToggle}
                  />
                </label>
                <div className="sub-title">
                  {t('reportBuilder.projects.historicalSavings.subTitle', {
                    ryanPlatform: ENV.RYAN_PLATFORM
                  })}
                </div>
              </div>
            </div>
          )}

        {manageHistoricalSavingsEngagements && historicalSavingsProjects && (
          <>
            <div className="inner-panel report-builder__historical-projects">
              <SearchInput
                loading={historicalSavingsProjectsLoading}
                onChange={this.handleHistoricalSavingsProjectSearch}
                value={historicalSavingsProjectsSearchTerm}
              />

              <Table<IHistoricalSavingsSummary, string>
                columns={[
                  {
                    id: 'accountHierarchy',
                    label: '',
                    align: 'center',
                    width: '3rem',
                    render: (row: IHistoricalSavingsSummary) => (
                      <AccountHierarchy hierarchy={row.accountHierarchy} />
                    )
                  },
                  {
                    id: 'engagementName',
                    label: t('reportBuilder.projects.columns.project'),
                    sortable: true,
                    render: (row: IHistoricalSavingsSummary) => (
                      <div className="bs">
                        <b>{row.engagementName}</b>
                        <small>
                          {row.accountStatusId === 0
                            ? row.inactiveAccountName
                            : row.accountName}
                        </small>
                      </div>
                    )
                  },
                  {
                    id: 'isActive',
                    label: t('projectOverview.columns.isActive'),
                    align: 'center',
                    render: () => (
                      <div className="pill inactive">{t('Inactive')}</div>
                    )
                  }
                ]}
                data={historicalSavingsProjects}
                loading={historicalSavingsProjectsLoading}
                loadingCount={5}
                onSelectChange={
                  this.handleHistoricalSavingsProjectsSelectChange
                }
                onSortChange={this.handleHistoricalSavingsProjectsSort}
                renderEmpty={() => (
                  <Empty icon="project-hidden">
                    {t('reportBuilder.projects.empty')}
                  </Empty>
                )}
                rowClassName={(row: IHistoricalSavingsSummary) =>
                  `stacks-${row.accountHierarchy.length}`
                }
                rowId="historicalSavingsSummaryGuid"
                selected={selectedHistoricalSavingsEngagements.map(
                  e => e.historicalSavingsSummaryGuid
                )}
                sorted={historicalSavingsProjectsSorted}
              />
            </div>

            {'selectedPublishedEngagements' in formik.touched &&
              'selectedPublishedEngagements' in formik.errors && (
                <div className="ry-text-input__feedback">
                  <Icon name="error" />
                  {formik.errors.selectedPublishedEngagements}
                </div>
              )}
          </>
        )}
      </ReportBuilderPanel>
    );
  }
}

export default withTranslation()(ProjectsSection);
