import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

// NavLink props don't seem to be exported so will interpret
type NavLinkProps = Parameters<NavLink>[0];

export interface IGlobalHeaderTabLinkProps extends NavLinkProps {
  disabled?: boolean;
}

const GlobalHeaderTabLink: FunctionComponent<IGlobalHeaderTabLinkProps> = ({
  activeClassName,
  className,
  disabled,
  onClick,
  ...rest
}) => (
  <NavLink
    activeClassName={classNames(
      'ry-global-header__tab--active',
      activeClassName
    )}
    className={classNames(
      'ry-global-header__tab',
      { 'ry-global-header__tab--disabled': disabled },
      className as string
    )}
    onClick={disabled ? e => e.preventDefault() : onClick}
    {...rest}
  />
);

export default GlobalHeaderTabLink;
