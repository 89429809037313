export enum FilterType {
  All = 1,
  AssignedToMe,
  DataRequests,
  Milestones,
  Tasks,
  Files,
  Contracts,
  Invoices,
  Learnings,
  Team,
  Edits,
  Comments,
  Deleted,
  SavingsSummary,
  Projects,
  AccessControl
}

export interface FilterTypeGroups {
  default: FilterType[];
  byActivity: FilterType[];
  byAction: FilterType[];
}
