/**
 * Variables must start with `REACT_APP_`
 * https://create-react-app.dev/docs/adding-custom-environment-variables
 *
 * In local environments, variables flow from...
 * - .env, .env.local, .env.development, .env.development.local
 * -> env.ts (process.env)
 *
 * In deployed environments, variables flow from...
 * - Azure variable groups
 * -> Azure Release Pipeline scripts
 * -> portal-infrastructure docker-compose.yml
 * -> portal-ui server.js
 * -> portal-ui env.ts (window.env)
 *
 * To add environment variables:
 * 1. Add default value to .env file. Used in local development.
 * 2. Add variable entry to the `ENV` map (below).
 * 3. Add variable name to server.js. Used in docker deployments.
 * 4. In Azure, add value per environment in variable groups.
 *    Azure/ Pipelines/ Library/ Variable groups/ {Environment}
 *    https://docs.microsoft.com/en-us/azure/devops/pipelines/library/variable-groups?view=azure-devops&tabs=yaml
 * 5. In Azure, add variable to Release Pipeline scripts.
 *    Azure/ Pipelines/ Releases/ PORTAL-UI-CD/ Edit/ Tasks/ {Environment}/ Docker build task/ Script
 *    $env:REACT_APP_YOUR_VARIABLE='$(VARIABLE_GROUP_VARIABLE)'
 *    https://dev.azure.com/RyanITApps/ITApplications/_releaseDefinition?definitionId=42&_a=environments-editor-preview
 * 6. In Azure, add variable to portal-infrastructure/ docker-compose.yml
 *    https://dev.azure.com/RyanITApps/ITApplications/_git/portal-infrastructure?path=%2Fdocker-compose.yml
 */

declare let process: NodeJS.Process;

const win: Window & { env?: Record<string, string> } = window;

const ENV = {
  ADFS_CLIENT_ID: '',
  AMPLITUDE_API_KEY: '',
  API_ROOT: '',
  BLACKLIST_FILES: '',
  CONTACT_ADDRESS: '',
  CONTACT_EMAIL: '',
  CONTACT_PHONE: '',
  DATADOG_LOGGER_CONFIG: '',
  DATADOG_USER_CONFIG: '',
  DECISIONS_BASE_URL: '',
  GA_OPTION_SPEED_SAMPLE_RATE: '',
  GA_TRACKING_ID: '',
  IDENTITY_AUTHORITY: '',
  IS_MAINTENANCE_PAGE_ENABLED: '',
  MAX_FILE_SIZE: '',
  PING_URL: '',
  PORTAL_DOMAIN: '',
  RYAN_PLATFORM: '',
  SALESFORCE_CONTACT_TAB_URL: '',
  TAS_BASE_URL: '',
  URL_LEARN_CLIENT: '',
  URL_LEARN_RYAN: ''
};

Object.keys(ENV).forEach(key => {
  const name = `REACT_APP_${key}`;
  ENV[key as keyof typeof ENV] =
    (win.env && win.env[name]) || process.env[name] || '';
});

export default ENV;
