import Currency from 'components/Currency/Currency';
import FileAttachments from 'components/FileAttachments';
import InfoTooltip from 'components/InfoTooltip';
import {
  IAttachmentUpdates,
  ISavingsSummaryEntry,
  ISavingsSummaryEntryCategory
} from 'interfaces';
import isEqual from 'lodash.isequal';
import ApiService from 'services/ApiService';
import history from 'services/history';
import { formatDate } from 'utils/formatDate';
import { isTempId } from 'utils/tempId';

import classnames from 'classnames';
import React, { Component, ComponentProps } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  DropdownMenu,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  Icon,
  Textarea,
  pushToast
} from '@ryan/components';

import ConfirmationModal from '../../components/Modal/ConfirmationModal/ConfirmationModal';
import DeleteSavingsCategoryModal from '../../components/Modal/DeleteSavingsCategoryModal/DeleteSavingsCategoryModal';
import SavingsCategoryModal from '../../components/Modal/SavingsCategoryModal/SavingsCategoryModal';
import UpdateSavingsBucketModal from '../../components/Modal/UpdateSavingsBucketModal/UpdateSavingsBucketModal';
import UnsavedChangesContext from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { TGetSupportedISOCurrencyCodes } from '../../services/ApiService/ApiSavings';
import { SavingsCategoryBucketEnums } from '../../utils/enums/SavingsCategoryBucketEnums';
import { handleDefaultApiError } from '../../utils/handleApiError/handleApiError';
import AddBucketModal from './AddBucketModal';
import { SavingsSummaryBucket } from './SavingsSummaryBucket';
import {
  IEntryCategorySums,
  createEntryCategory,
  getEntrySums,
  isExistingCategory,
  isNotSameCategory,
  isSameCategory,
  updateEntryCategory
} from './savingsSummaryHelpers';

import './SavingsSummaryEdit.scss';

export interface ISavingsSummaryEditProps extends WithTranslation {
  defaultEntry: ISavingsSummaryEntry;
  isoCurrencyCodeOptions: TGetSupportedISOCurrencyCodes[];
  from?: 'project';
}

interface ISavingsSummaryEditState {
  // Initial entry asOfDate to track changes coming from external actions
  initialEntryAsOfDate: Date;
  entry: ISavingsSummaryEntry;
  // Whether the Totals section beneath the categories is expanded.
  totalsExpanded: boolean;
  // Holds the updates for attachments (adding new ones, deleting existing ones).
  attachmentUpdates: IAttachmentUpdates;
  // Active category with user focus.
  focusedCategoryGuid: string | null;
  // Opens a modal to edit a category's bucket.
  editBucket: {
    savingsSummaryEntryCategoryGuid: string;
    bucket: SavingsSummaryBucket;
  } | null;
  // Opens a modal to create a new category.
  createCategory: boolean;
  // Opens a modal to rename a category.
  renameCategory: ISavingsSummaryEntryCategory | null;
  // Opens a modal to confirm deletion of a category.
  deleteCategory: IEntryCategorySums | null;
  // Opens a modal to warn about uploaded files when trying to cancel.
  isAttachmentsInProgressModalOpen: boolean;
  // Promise for saving the entry.
  savePromise: Promise<any> | null;
  // Need to keep track of any changes in the entry form outside of categories/totals to determine if we can save
  hasFormError: boolean;
  isZeroSumCategoryConfirmationModalOpen: boolean;
}

export class SavingsSummaryEdit extends Component<
  ISavingsSummaryEditProps,
  ISavingsSummaryEditState
> {
  static defaultAttachmentUpdates = {
    addUploaded: [],
    addExisting: [],
    deleteAttachments: []
  };

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  constructor(props: ISavingsSummaryEditProps) {
    super(props);
    this.state = {
      attachmentUpdates: { ...SavingsSummaryEdit.defaultAttachmentUpdates },
      createCategory: false,
      deleteCategory: null,
      editBucket: null,
      entry: props.defaultEntry,
      focusedCategoryGuid: null,
      hasFormError: false,
      initialEntryAsOfDate: props.defaultEntry.asOfDate,
      isAttachmentsInProgressModalOpen: false,
      isZeroSumCategoryConfirmationModalOpen: false,
      renameCategory: null,
      savePromise: null,
      totalsExpanded: false
    };
  }

  componentDidUpdate(prevProps: ISavingsSummaryEditProps) {
    const {
      asOfDate: previousAsOfDate,
      isBaseline: previousIsBaseline,
      isoCurrencyCode: previousISOCurrencyCode
    } = prevProps.defaultEntry;

    const { asOfDate, isBaseline, isoCurrencyCode } = this.props.defaultEntry;

    if (
      previousAsOfDate === asOfDate &&
      previousIsBaseline === isBaseline &&
      previousISOCurrencyCode === isoCurrencyCode
    ) {
      return;
    }

    const updatedEntry = {
      ...this.state.entry,
      ...(previousAsOfDate !== asOfDate && { asOfDate }),
      ...(previousIsBaseline !== isBaseline && { isBaseline }),
      ...(previousISOCurrencyCode !== isoCurrencyCode && { isoCurrencyCode })
    };

    this.setState({ entry: updatedEntry });
  }

  componentWillUnmount() {
    this.context.setIsUnsavedChanges(false);
  }

  /**
   * Edit a category's bucket.
   */

  handleEditBucket =
    (savingsSummaryEntryCategoryGuid: string, bucket: SavingsSummaryBucket) =>
    () => {
      this.setState({
        editBucket: {
          savingsSummaryEntryCategoryGuid,
          bucket
        }
      });
    };

  handleEditBucketSubmit = (entry: ISavingsSummaryEntry) => {
    const { editBucket } = this.state;
    if (editBucket) {
      this.setState({
        entry,
        editBucket: null
      });
    }
    this.tryFocusCategory();
  };

  handleEditBucketCancel = () => {
    this.setState({
      editBucket: null
    });
    this.tryFocusCategory();
  };

  tryFocusCategory() {
    window.setTimeout(() => {
      const el = document.activeElement;
      if (el) {
        const categoryRow = el.closest('[data-category]');
        if (categoryRow) {
          const focusedCategoryGuid = categoryRow.getAttribute('data-category');
          if (focusedCategoryGuid) {
            this.setState({ focusedCategoryGuid });
          }
        }
      }
    }, 0);
  }

  /**
   * Create a new category.
   */

  handleCreateCategory = () => {
    this.setState({
      createCategory: true
    });
  };

  handleCreateCategorySubmit = (name: string) => {
    this.setState(({ entry }) => ({
      entry: createEntryCategory(entry, name),
      createCategory: false
    }));
  };

  /**
   * Rename a category.
   */

  handleRenameCategory = (category: IEntryCategorySums) => {
    const { entry } = this.state;
    const categoryGuid = category.values.savingsSummaryEntryCategoryGuid;
    const renameCategory = entry.categories.find(
      c => c.savingsSummaryEntryCategoryGuid === categoryGuid
    );

    if (renameCategory) {
      this.setState({
        renameCategory
      });
    }
  };

  handleRenameCategorySubmit = (name: string) => {
    this.setState(({ entry, renameCategory }) => {
      if (renameCategory) {
        return {
          entry: updateEntryCategory(
            entry,
            renameCategory.savingsSummaryEntryCategoryGuid,
            { name }
          ),
          renameCategory: null
        };
      }
      return null;
    });
  };

  /**
   * Reorder a category by moving it up or down in the list.
   */

  handleReorderCategory = (category: IEntryCategorySums, delta: number) => {
    this.setState(({ entry }) => {
      let movedCategory: [number, ISavingsSummaryEntryCategory] | null = null;

      // Remove selected category
      const categories = this.state.entry.categories.filter((c, i) => {
        if (
          c.savingsSummaryEntryCategoryGuid ===
          category.values.savingsSummaryEntryCategoryGuid
        ) {
          movedCategory = [i, c];
          return false;
        }
        return true;
      });

      // Insert at new index
      if (movedCategory) {
        categories.splice(
          Math.min(Math.max(movedCategory[0] + delta, 0), categories.length),
          0,
          movedCategory[1]
        );
      }

      return { entry: { ...entry, categories } };
    });
  };

  /**
   * Delete a category.
   */

  handleDeleteCategory = (deleteCategory: IEntryCategorySums) => {
    // If the category was just created, skip the confirmation modal.
    if (isTempId(deleteCategory.values.savingsSummaryEntryCategoryGuid)) {
      this.deleteCategory(deleteCategory);
    } else {
      this.setState({ deleteCategory });
    }
  };

  handleDeleteCategoryCancel = () => {
    this.setState({ deleteCategory: null });
  };

  handleDeleteCategorySubmit = () => {
    const { deleteCategory } = this.state;
    if (deleteCategory) {
      this.deleteCategory(deleteCategory);
    }
  };

  deleteCategory(categorySums: IEntryCategorySums) {
    const category = categorySums.values;
    this.setState(({ entry }) => {
      const newState = (categories: ISavingsSummaryEntryCategory[]) => ({
        entry: { ...entry, categories },
        deleteCategory: null
      });

      const clearedCategoryEntries = {
        approvedAdded: 0,
        approvedPassed: 0,
        approvedToReceived: 0,
        potentialAdded: 0,
        potentialPassed: 0,
        potentialToApproved: 0,
        potentialToReceived: 0,
        potentialToSubmitted: 0,
        receivedAdded: 0,
        receivedPassed: 0,
        submittedAdded: 0,
        submittedPassed: 0,
        submittedToApproved: 0,
        submittedToReceived: 0
      };
      const isDeleted = isSameCategory(category);
      const isNotDeleted = isNotSameCategory(category);

      // If the category existed in previous entries,
      // pass on all savings and mark category as deleted.
      if (entry.lastCategories) {
        const categoryInitial = entry.lastCategories.find(isDeleted);

        if (categoryInitial) {
          return newState(
            entry.categories.map(category =>
              isDeleted(category)
                ? {
                    ...category,
                    ...clearedCategoryEntries,
                    isDeleted: true
                  }
                : category
            )
          );
        }
      }

      // The category does not exist in the previous SS entry.
      // If the SS is new (has not been saved) and has no values, just remove it immediately.
      // Otherwise, we mark it as deleted so that it can be undone.
      const isNew = isTempId(category.savingsSummaryEntryCategoryGuid);
      const isUpdated =
        categorySums.potentialUpdated ||
        categorySums.submittedUpdated ||
        categorySums.approvedUpdated ||
        categorySums.receivedUpdated;

      return newState(
        isNew && !isUpdated
          ? entry.categories.filter(isNotDeleted)
          : entry.categories.map(category =>
              isDeleted(category)
                ? {
                    ...category,
                    ...clearedCategoryEntries,
                    shouldDeletePermanently: true
                  }
                : category
            )
      );
    });
  }

  handleUndoDelete = (category: IEntryCategorySums) => () => {
    const categoryGuid = category.values.savingsSummaryEntryCategoryGuid;

    this.setState(({ entry }) => {
      // If `shouldDeletePermanently` is true, then that means...
      // 1. We are editing an existing entry.
      // 2. This category was created on this entry and previously saved.
      // 3. This category was deleted during this session
      if (category.values.shouldDeletePermanently) {
        return {
          entry: updateEntryCategory(entry, categoryGuid, {
            shouldDeletePermanently: false
          })
        };
      }

      if (category.values.isDeleted) {
        return {
          entry: updateEntryCategory(entry, categoryGuid, { isDeleted: false })
        };
      }

      return null;
    });
  };

  /**
   * Toggle totals.
   */

  handleTotalsToggle = () => {
    this.setState(({ totalsExpanded }) => ({
      totalsExpanded: !totalsExpanded
    }));
  };

  /**
   * Update the comment.
   */

  handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      hasFormError: e.target.value.length > 2048,
      entry: { ...this.state.entry, commentText: e.target.value }
    });
  };

  /**
   * Attachment event callback for `FileAttachments` component.
   */
  handleFileAttachmentUpdate = (
    attachmentUpdates: IAttachmentUpdates
  ): void => {
    this.setState({ attachmentUpdates });
  };

  /**
   * Cancel
   */

  handleCancel = () => {
    const { from } = this.props;
    const { entry, attachmentUpdates } = this.state;

    // open confirmation modal if new files have been uploaded and queued for
    // attachment
    if (attachmentUpdates.addUploaded.length > 0) {
      this.setState({
        isAttachmentsInProgressModalOpen: true
      });
    } else {
      this.context.setIsUnsavedChanges(false);
      history.push(
        from === 'project'
          ? `/app/project/${entry.engagementGuid}/savings-history`
          : '/app/projects/savings-summary'
      );
    }
  };

  /**
   * Save!
   */

  handleSave = async (isAllowZeroSumCategories?: boolean) => {
    const { isoCurrencyCodeOptions, t } = this.props;
    const { entry, attachmentUpdates } = this.state;

    const currencyGuid = isoCurrencyCodeOptions.find(
      option => option.isoAlphaCode === entry.isoCurrencyCode
    )!.currencyGuid;

    if (!isAllowZeroSumCategories) {
      const { categories: entryCategories } = getEntrySums(entry);
      let hasZeroSumCategories = false;

      const filteredEntryCategories = entryCategories.filter(entryCategory =>
        isTempId(entryCategory.values.savingsSummaryEntryCategoryGuid)
      );

      for (let i = 0; i < filteredEntryCategories.length; i++) {
        const {
          approvedUpdated,
          potentialUpdated,
          receivedUpdated,
          submittedUpdated,
          summaryTotal
        } = filteredEntryCategories[i];

        if (
          !approvedUpdated &&
          !potentialUpdated &&
          !receivedUpdated &&
          !submittedUpdated &&
          summaryTotal === 0
        ) {
          hasZeroSumCategories = true;
          break;
        }
      }

      if (hasZeroSumCategories) {
        this.setState({ isZeroSumCategoryConfirmationModalOpen: true });
        return;
      }
    }

    this.setState({
      savePromise:
        // TODO: Refactor nested Api call with BED Team during refactor. Reduce to one call
        ApiService.updateProjectCurrency({
          currencyGuid,
          engagementGuid: entry.engagementGuid
        })
          .then(() => {
            return ApiService.saveSavingsSummaryEntry(entry, attachmentUpdates);
          })
          .then(() => {
            pushToast({
              content: t('savings.entry.successToastContent', {
                name: entry.engagementDisplayNameShort
              }),
              title: t('savings.entry.successToastTitle'),
              type: EMessageTypes.SUCCESS
            });

            history.push(
              `/app/project/${entry.engagementGuid}/savings-history`
            );
          })
          .catch(error => {
            if (error?.response?.status === 400) {
              pushToast({
                title: error.response.data.message,
                type: EMessageTypes.ERROR
              });
            } else {
              handleDefaultApiError(error);
            }
          })
    });
  };

  render() {
    const { defaultEntry, t } = this.props;
    const {
      attachmentUpdates,
      createCategory,
      deleteCategory,
      entry,
      hasFormError,
      initialEntryAsOfDate,
      isAttachmentsInProgressModalOpen,
      isZeroSumCategoryConfirmationModalOpen,
      renameCategory,
      savePromise,
      totalsExpanded
    } = this.state;

    const sums = getEntrySums(entry);

    // Has error and cannot be saved if any bucket is negative.
    const hasError = sums.categories.some(
      category =>
        category.potentialTotal < 0 ||
        category.submittedTotal < 0 ||
        category.approvedTotal < 0 ||
        category.receivedTotal < 0
    );

    // Determines if any editable values have been changed
    const isEdited =
      entry.asOfDate.getDate() !== initialEntryAsOfDate.getDate() ||
      entry.commentText !== defaultEntry.commentText ||
      !isEqual(entry.attachments, defaultEntry.attachments) ||
      !isEqual(
        attachmentUpdates,
        SavingsSummaryEdit.defaultAttachmentUpdates
      ) ||
      !isEqual(entry.categories, defaultEntry.categories) ||
      !isEqual(entry.lastCategories, defaultEntry.lastCategories);

    this.context.setIsUnsavedChanges(isEdited);

    return (
      <div className="savings-summary-edit">
        <table className="savings-summary-edit__table">
          <thead>
            <tr>
              <th className="savings-summary-edit__th ry-table__th">
                {t('savings.category')}
              </th>
              <th className="savings-summary-edit__th ry-table__th">
                {t('savings.potential')}{' '}
                <InfoTooltip>{t('savings.tooltips.potential')}</InfoTooltip>
              </th>
              <th className="savings-summary-edit__th ry-table__th">
                {t('savings.submitted')}{' '}
                <InfoTooltip>{t('savings.tooltips.submitted')}</InfoTooltip>
              </th>
              <th className="savings-summary-edit__th ry-table__th">
                {t('savings.approved')}{' '}
                <InfoTooltip>{t('savings.tooltips.approved')}</InfoTooltip>
              </th>
              <th className="savings-summary-edit__th ry-table__th">
                {t('savings.received')}{' '}
                <InfoTooltip>{t('savings.tooltips.received')}</InfoTooltip>
              </th>
              <th className="savings-summary-edit__th savings-summary-edit__th-summary savings-summary-edit__th--right ry-table__th">
                {t('Total')}
              </th>
              <th className="savings-summary-edit__th ry-table__th" />
            </tr>
          </thead>
          <tbody>
            {/* Categories */}
            {sums.categories.map(this.renderCategory)}

            {/* New Row Button */}
            <tr>
              <td className="savings-summary-edit__new-category" colSpan={7}>
                <button onClick={this.handleCreateCategory}>
                  <Icon name="plus" />
                  {t('savings.newCategory')}
                </button>
              </td>
            </tr>
          </tbody>

          <tbody className="savings-summary-edit__totals">
            {/* Totals, Changes */}
            <tr>
              <td>
                <button onClick={this.handleTotalsToggle} type="button">
                  <Icon name={totalsExpanded ? 'chevron-up' : 'chevron-down'} />
                  <span className="savings-summary-edit__totals-total">
                    {t('Total')}
                  </span>
                  <span className="savings-summary-edit__totals-change">
                    {entry.lastAsOfDate
                      ? t('savings.entry.changesSince', {
                          date: formatDate(entry.lastAsOfDate)
                        })
                      : t('savings.entry.changesSinceProjectKickoff')}
                  </span>
                </button>
              </td>

              {/* Potential */}
              {this.renderTotalAndChange(
                sums.potentialTotal,
                sums.potentialChange
              )}

              {/* Submitted */}
              {this.renderTotalAndChange(
                sums.submittedTotal,
                sums.submittedChange
              )}

              {/* Approved */}
              {this.renderTotalAndChange(
                sums.approvedTotal,
                sums.approvedChange
              )}

              {/* Received */}
              {this.renderTotalAndChange(
                sums.receivedTotal,
                sums.receivedChange
              )}

              {/* Summary */}
              {this.renderTotalAndChange(
                sums.summaryTotal,
                sums.summaryChange,
                true
              )}

              <td />
            </tr>
          </tbody>

          {/* Totals Expanded */}
          {totalsExpanded && (
            <tbody className="savings-summary-edit__totals-expanded">
              <tr>
                <td>{t('savings.added')}</td>
                {[
                  sums.potentialAdded,
                  sums.submittedAdded,
                  sums.approvedAdded,
                  sums.receivedAdded,
                  sums.summaryAdded
                ].map(this.renderTotalValue)}
                <td />
              </tr>
              <tr>
                <td>{t('savings.passed')}</td>
                {[
                  sums.potentialPassed,
                  sums.submittedPassed,
                  sums.approvedPassed,
                  sums.receivedPassed,
                  sums.summaryPassed
                ].map(this.renderTotalValue)}
                <td />
              </tr>
              <tr>
                <td>
                  {t('savings.advancedOut')}{' '}
                  <InfoTooltip>{t('savings.tooltips.advancedOut')}</InfoTooltip>
                </td>
                {[
                  sums.potentialAdvancedOut,
                  sums.submittedAdvancedOut,
                  sums.approvedAdvancedOut
                ].map(this.renderTotalValue)}
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  {t('savings.advancedIn')}{' '}
                  <InfoTooltip>{t('savings.tooltips.advancedIn')}</InfoTooltip>
                </td>
                <td />
                {[
                  sums.submittedAdvancedIn,
                  sums.approvedAdvancedIn,
                  sums.receivedAdvancedIn
                ].map(this.renderTotalValue)}
                <td />
                <td />
              </tr>
            </tbody>
          )}
        </table>

        <div className="savings-summary-edit__footer">
          <div className="row">
            <div className="col-5">
              <FileAttachments
                allowFileDownload={false}
                attachments={entry.attachments}
                engagementGuid={entry.engagementGuid}
                engagementName={entry.engagementDisplayNameShort}
                onAttachmentUpdate={this.handleFileAttachmentUpdate}
              />
              <Textarea
                label={t('savings.entry.updateNotes')}
                maxLength={2048}
                onChange={this.handleCommentChange}
                value={entry.commentText}
              />
            </div>
            <div className="col-7">
              {hasError && (
                <div className="savings-summary-edit__error-message">
                  <Icon name="error" />
                  {t('savings.entry.error')}
                </div>
              )}

              <ButtonGroup>
                <Button
                  disabled={hasError || hasFormError || !isEdited}
                  loading={savePromise}
                  onClick={() => {
                    this.handleSave();
                  }}
                  size={EButtonSizes.LARGE}
                  text={t('Save')}
                  variant={EButtonVariant.PRIMARY}
                />
                <Button
                  onClick={this.handleCancel}
                  size={EButtonSizes.LARGE}
                  text={t('Cancel')}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        {this.renderEditBucketModal()}

        {(createCategory || renameCategory) && (
          <SavingsCategoryModal
            categoryName={createCategory ? '' : renameCategory!.name}
            existingCategoryNames={entry.categories.map(
              category => category.name
            )}
            modalTitle={createCategory ? 'createNewCategory' : 'renameCategory'}
            onClose={() => {
              createCategory
                ? this.setState({ createCategory: false })
                : this.setState({ renameCategory: null });
            }}
            onSubmit={categoryName => {
              createCategory
                ? this.handleCreateCategorySubmit(categoryName)
                : this.handleRenameCategorySubmit(categoryName);
            }}
          />
        )}

        {deleteCategory && (
          <DeleteSavingsCategoryModal
            categoryName={deleteCategory.values.name}
            isExistingCategory={isExistingCategory(
              entry,
              deleteCategory.values.savingsSummaryEntryCategoryGuid
            )}
            onClose={this.handleDeleteCategoryCancel}
            onSubmit={this.handleDeleteCategorySubmit}
          />
        )}

        {isAttachmentsInProgressModalOpen && (
          <ConfirmationModal
            cancelTextToDisplay={t(
              'savings.entry.cancelWithAttachmentConfirmationModal.noGoBack'
            )}
            confirmationMessage={t(
              'savings.entry.cancelWithAttachmentConfirmationModal.description'
            )}
            onClose={() => {
              this.setState({ isAttachmentsInProgressModalOpen: false });
            }}
            onSubmit={() => {
              this.setState(
                prevState => ({
                  attachmentUpdates: {
                    ...prevState.attachmentUpdates,
                    addUploaded: []
                  }
                }),
                this.handleCancel
              );
            }}
            submitTextToDisplay={t(
              'savings.entry.cancelWithAttachmentConfirmationModal.yesCancel'
            )}
            title={t(
              'savings.entry.cancelWithAttachmentConfirmationModal.title'
            )}
          />
        )}

        {isZeroSumCategoryConfirmationModalOpen && (
          <ConfirmationModal
            cancelTextToDisplay={t(
              'savingsSummary.zeroSumCategoryConfirmationModal.cancel'
            )}
            confirmationMessage={t(
              'savingsSummary.zeroSumCategoryConfirmationModal.areYouSure'
            )}
            isPositive
            onClose={() => {
              this.setState({ isZeroSumCategoryConfirmationModalOpen: false });
            }}
            onSubmit={() => {
              this.handleSave(true);
            }}
            submitTextToDisplay={t(
              'savingsSummary.zeroSumCategoryConfirmationModal.save'
            )}
            title={t(
              'savingsSummary.zeroSumCategoryConfirmationModal.categoryWithoutSavings'
            )}
          />
        )}
      </div>
    );
  }

  handleCategoryFocus(categoryGuid: string, target: Element) {
    this.setState(({ focusedCategoryGuid }) =>
      focusedCategoryGuid !== categoryGuid &&
      target.hasAttribute('data-category-focus')
        ? { focusedCategoryGuid: categoryGuid }
        : null
    );
  }

  handleCategoryBlur(categoryGuid: string) {
    this.setState(({ focusedCategoryGuid }) =>
      focusedCategoryGuid === categoryGuid
        ? { focusedCategoryGuid: null }
        : null
    );
  }

  isCategoryActive = (categoryGuid: string) => {
    const { editBucket, renameCategory, deleteCategory, focusedCategoryGuid } =
      this.state;

    return (
      categoryGuid === focusedCategoryGuid ||
      categoryGuid === (editBucket || {}).savingsSummaryEntryCategoryGuid ||
      categoryGuid === (renameCategory || {}).savingsSummaryEntryCategoryGuid ||
      categoryGuid ===
        (deleteCategory?.values || {}).savingsSummaryEntryCategoryGuid
    );
  };

  renderCategory = (
    categorySums: IEntryCategorySums,
    index: number,
    categories: IEntryCategorySums[]
  ) => {
    const { t, defaultEntry } = this.props;
    const { entry } = this.state;
    const category = categorySums.values;
    const categoryGuid = category.savingsSummaryEntryCategoryGuid;
    const ogCategory = defaultEntry.categories.find(isSameCategory(category));
    const isActive = this.isCategoryActive(categoryGuid);
    const isOnly = entry.categories.filter(c => !c.isDeleted).length === 1;
    const isDeleted = category.shouldDeletePermanently || category.isDeleted;
    const hasError = categorySums.summaryTotal < 0;

    const actions: ComponentProps<typeof DropdownMenu>['options'] = [];

    actions.push({
      label: t('Rename'),
      icon: 'pencil',
      onClick: () => this.handleRenameCategory(categorySums)
    });

    if (index !== 0) {
      actions.push({
        label: t('Move Up'),
        icon: 'arrow-block-up',
        onClick: () => this.handleReorderCategory(categorySums, -1)
      });
    }

    if (index !== categories.length - 1) {
      actions.push({
        label: t('Move Down'),
        icon: 'arrow-block-down',
        onClick: () => this.handleReorderCategory(categorySums, 1)
      });
    }

    actions.push({
      label: t('Delete'),
      icon: 'trash',
      negative: true,
      disabled: isOnly,
      onClick: () => this.handleDeleteCategory(categorySums)
    });

    return (
      <tr
        className={classnames({
          'savings-summary-edit__category': true,
          'savings-summary-edit__category--readonly': !category.isEditable,
          'savings-summary-edit__category--active': isActive,
          'savings-summary-edit__category--deleted': isDeleted
        })}
        data-category={categoryGuid}
        key={categoryGuid}
        onBlur={() => this.handleCategoryBlur(categoryGuid)}
        onFocus={e => this.handleCategoryFocus(categoryGuid, e.target)}
      >
        {/* Name */}
        <td className="savings-summary-edit__category-name">
          <div className="savings-summary-edit__category-name-current">
            {category.name}
          </div>
          {ogCategory && ogCategory.name !== category.name && (
            <del>{ogCategory.name}</del>
          )}
        </td>

        {/* Potential */}
        {this.renderCategoryBucket(
          SavingsSummaryBucket.Potential,
          categorySums,
          categorySums.potentialTotal,
          categorySums.potentialUpdated,
          isDeleted
        )}

        {/* Submitted */}
        {this.renderCategoryBucket(
          SavingsSummaryBucket.Submitted,
          categorySums,
          categorySums.submittedTotal,
          categorySums.submittedUpdated,
          isDeleted
        )}

        {/* Approved */}
        {this.renderCategoryBucket(
          SavingsSummaryBucket.Approved,
          categorySums,
          categorySums.approvedTotal,
          categorySums.approvedUpdated,
          isDeleted
        )}

        {/* Received */}
        {this.renderCategoryBucket(
          SavingsSummaryBucket.Received,
          categorySums,
          categorySums.receivedTotal,
          categorySums.receivedUpdated,
          isDeleted
        )}

        {/* Summary */}
        <td
          className={classnames({
            'savings-summary-edit__category-summary': true,
            'savings-summary-edit__category-summary--error': hasError
          })}
        >
          <Icon className="savings-summary-edit__error-icon" name="alert" />
          <Currency
            currencyCode={entry.isoCurrencyCode}
            value={categorySums.summaryTotal}
          />
        </td>

        {/* Actions */}
        <td className="savings-summary-edit__category-actions">
          {isDeleted ? (
            <button
              className="savings-summary-edit__category-bucket-undo"
              data-category-focus
              onClick={this.handleUndoDelete(categorySums)}
            >
              <Icon
                className="savings-summary-edit__category-bucket-undo-icon"
                name="undo"
              />
            </button>
          ) : category.isEditable ? (
            <DropdownMenu
              options={actions}
              renderTarget={({ ref, open, onClick }) => (
                <button
                  aria-expanded={open}
                  aria-haspopup="menu"
                  className=""
                  data-category-focus
                  onClick={onClick}
                  ref={ref}
                  type="button"
                >
                  <Icon name="more-outline" />
                </button>
              )}
            />
          ) : (
            <InfoTooltip>{t('savings.entry.readonly')}</InfoTooltip>
          )}
        </td>
      </tr>
    );
  };

  renderCategoryBucket(
    bucket: SavingsSummaryBucket,
    category: IEntryCategorySums,
    value: number,
    edited: boolean,
    deleted: boolean
  ) {
    const { entry } = this.state;
    return (
      <td
        className={classnames({
          'savings-summary-edit__category-bucket': true,
          'savings-summary-edit__category-bucket--edited': edited,
          'savings-summary-edit__category-bucket--error': value < 0
        })}
      >
        {category.values.isEditable && !deleted ? (
          <button
            data-category-focus
            onClick={this.handleEditBucket(
              category.values.savingsSummaryEntryCategoryGuid,
              bucket
            )}
          >
            <Icon className="savings-summary-edit__error-icon" name="alert" />
            <Currency currencyCode={entry.isoCurrencyCode} value={value} />
            <Icon
              className="savings-summary-edit__category-bucket-edit-icon"
              name="pencil"
            />
          </button>
        ) : (
          <div className="savings-summary-edit__category-bucket-uneditable">
            <Currency currencyCode={entry.isoCurrencyCode} value={value} />
          </div>
        )}
      </td>
    );
  }

  renderTotalAndChange(
    totalValue: number,
    changeValue: number,
    isSummary = false
  ) {
    const { entry } = this.state;
    return (
      <td>
        {/* Total */}
        <div
          className={classnames({
            'savings-summary-edit__total': true,
            'savings-summary-edit__total--summary': isSummary,
            'savings-summary-edit__total--error': totalValue < 0
          })}
        >
          <Icon className="savings-summary-edit__error-icon" name="alert" />
          <Currency currencyCode={entry.isoCurrencyCode} value={totalValue} />
        </div>

        {/* Change */}
        <div
          className={classnames(
            'savings-summary-edit__change',
            `savings-summary-edit__change--${
              changeValue < 0 ? 'down' : changeValue > 0 ? 'up' : 'even'
            }`
          )}
        >
          <Currency
            currencyCode={entry.isoCurrencyCode}
            includeArrow
            value={changeValue}
          />
        </div>
      </td>
    );
  }

  renderTotalValue = (value: number, i: number) => {
    const { entry } = this.state;
    return (
      <td key={i}>
        <Currency
          currencyCode={entry.isoCurrencyCode}
          includePositiveSigning
          value={value}
        />
      </td>
    );
  };

  renderEditBucketModal() {
    const { t } = this.props;
    const { entry, editBucket } = this.state;
    if (editBucket) {
      const { savingsSummaryEntryCategoryGuid, bucket } = editBucket;
      const category = entry.categories.find(
        c =>
          c.savingsSummaryEntryCategoryGuid === savingsSummaryEntryCategoryGuid
      );

      const lastCategory =
        entry.lastCategories &&
        entry.lastCategories.find(
          c =>
            c.savingsSummaryEntryCategoryGuid ===
            savingsSummaryEntryCategoryGuid
        );

      if (category) {
        return lastCategory ? (
          <UpdateSavingsBucketModal
            bucketName={bucket as unknown as SavingsCategoryBucketEnums}
            lastSavingsForBucket={lastCategory[bucket]}
            onClose={this.handleEditBucketCancel}
            onSubmit={mappedFormikValues => {
              this.handleEditBucketSubmit(
                updateEntryCategory(
                  entry,
                  savingsSummaryEntryCategoryGuid,
                  mappedFormikValues
                )
              );
            }}
            savingsCategoryDetail={category}
            savingsCurrencyCode={entry.isoCurrencyCode}
            savingsLastAsOfDate={entry.lastAsOfDate}
          />
        ) : (
          <AddBucketModal
            bucket={bucket}
            entry={entry}
            onCancel={this.handleEditBucketCancel}
            onSubmit={this.handleEditBucketSubmit}
            savingsSummaryEntryCategoryGuid={savingsSummaryEntryCategoryGuid}
            t={t}
          />
        );
      }
    }
    return null;
  }
}

export default withTranslation()(SavingsSummaryEdit);
