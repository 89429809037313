import { Omit, isEqual, omit } from 'lodash';

import classNames from 'classnames';
import { parseISO, startOfDay } from 'date-fns';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { TFunction } from 'i18next';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  EMessageTypes,
  Message,
  Multiselect,
  TextInput,
  Textarea,
  pushToast
} from '@ryan/components';

import {
  FolderSelection,
  IAttachmentUpdates,
  IDataRequestV2,
  IDocumentTypes,
  IErpSystemTypes,
  IFolderTree,
  IJurisdiction,
  IPropertyTaxDocumentType,
  ITaxEngineTypes
} from '../../../../../interfaces';
import ApiService from '../../../../../services/ApiService';
import { ApiErrorResponseEnums } from '../../../../../utils/enums/ApiErrorResponseEnums';
import { DataRequestTypeGuidEnum } from '../../../../../utils/enums/DataRequestsEnums';
import * as FolderValidationUtils from '../../../../../utils/folderValidation/folderValidation.utils';
import { formatDate } from '../../../../../utils/formatDate';
import {
  FormikErrors,
  FormikProps,
  formikAutocompleteAjaxProps,
  formikDatepickerProps,
  formikFieldProps,
  withFormik,
  yup
} from '../../../../../utils/forms';
import { isFolderNameExist } from '../../../../../utils/isFolderNameExist';
import pushServerErrorToast from '../../../../../utils/pushServerErrorToast';
import {
  SystemAutocomplete,
  UserAutocomplete
} from '../../../../AutocompleteAjax';
import Datepicker from '../../../../Datepicker';
import ManageAttachments from '../../../../ManageAttachments';
import { ThirdStepFormEnums } from '../../../../Modal/DataRequestModal/ThirdStepForm/utils';
import MultiSelectDateRange from '../../../../MultiSelectDateRange/MultiSelectDateRange';
import { TMultiSelectDateRange } from '../../../../MultiSelectDateRange/utils';
import { RadioButton, RadioButtonGroup } from '../../../../RadioButton';
import SelectFolder from '../../../../SelectFolder/SelectFolder';
import {
  IEditFormProps,
  IEditFormValues,
  IUpdateDataRequestRequest,
  TDropdownOption
} from './utils';

import './EditForm.scss';

const maxLengthDescription = 600;
const maxLengthTitle = 50;
const otherDocumentTypeGuid = '201f57a1-8129-4644-bf0c-dd20df83208d';

const defaultValues: IEditFormValues = {
  assignedTo: null,
  attachments: {
    addUploaded: [],
    deleteAttachments: [],
    addExisting: []
  },
  dataSpecs: '',
  daterange: [{ startDate: null, endDate: null }],
  defaultFolder: null,
  description: '',
  documentTypes: [],
  dueDate: null,
  engagement: null,
  erpSystem: null,
  hasFormChanged: false,
  propertyTaxDocumentTypes: [],
  propertyTaxSiteClasses: [],
  shouldAttachFiles: ThirdStepFormEnums.NO,
  taxEngine: null,
  title: ''
};

const minDueDate = (dataRequest?: IDataRequestV2) => {
  const today = startOfDay(new Date());
  if (dataRequest) {
    const dueDate = startOfDay(parseISO(dataRequest.dueDate));
    if (dueDate < today) {
      return dueDate;
    }
  }
  return today;
};

const getDRTypeChecks = (dataRequestTypeGuid?: string) => {
  return {
    isDocumentImages:
      dataRequestTypeGuid === DataRequestTypeGuidEnum.DOCUMENT_IMAGES,
    isDataSpecsRendered:
      dataRequestTypeGuid === DataRequestTypeGuidEnum.DOCUMENT_IMAGES ||
      dataRequestTypeGuid === DataRequestTypeGuidEnum.OTHER ||
      dataRequestTypeGuid === DataRequestTypeGuidEnum.TAX_RETURNS ||
      dataRequestTypeGuid === DataRequestTypeGuidEnum.AUDIT_WORKPAPERS ||
      dataRequestTypeGuid === DataRequestTypeGuidEnum.SITE_INFORMATION,
    isERP: dataRequestTypeGuid === DataRequestTypeGuidEnum.ERP_DATA,
    isJurisdictionsRendered:
      dataRequestTypeGuid === DataRequestTypeGuidEnum.TAX_RETURNS ||
      dataRequestTypeGuid === DataRequestTypeGuidEnum.AUDIT_WORKPAPERS ||
      dataRequestTypeGuid === DataRequestTypeGuidEnum.OTHER ||
      dataRequestTypeGuid === DataRequestTypeGuidEnum.SITE_INFORMATION,
    isPropertyTax:
      dataRequestTypeGuid === DataRequestTypeGuidEnum.SITE_INFORMATION
  };
};

const findFolderByGuid = (
  folders: IFolderTree[],
  folderGuid: string
): IFolderTree | null => {
  for (const folder of folders) {
    if (folder.folderGuid === folderGuid) {
      return folder;
    } else if (folder.childFolders.length > 0) {
      const found = findFolderByGuid(folder.childFolders, folderGuid);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const checkAndSetDefaultFolder = (
  folders: IFolderTree[] | null,
  defaultFolderGuid?: string | null,
  defaultFolder?: FolderSelection | null
): FolderSelection | null => {
  if (defaultFolder && (defaultFolder.folderGuid || defaultFolder.folderName)) {
    return defaultFolder;
  } else if (defaultFolderGuid && folders) {
    const selectedFolder = findFolderByGuid(folders, defaultFolderGuid);
    if (selectedFolder) {
      return {
        folderGuid: selectedFolder.folderGuid,
        folderName: selectedFolder.folderName,
        parentFolderGuid: selectedFolder.parentFolderGuid,
        folderVisibleToUserTypes: selectedFolder.folderVisibleToUserTypes,
        archiveDate: selectedFolder.archiveDate
      };
    }
  }
  return null;
};

const normalizeDates = (
  data: Omit<IEditFormValues, 'shouldAttachFiles' | 'hasFormChanged'>
) => {
  return {
    ...data,
    daterange: data.daterange?.map((range: TMultiSelectDateRange) => ({
      ...range,
      startDate: range.startDate ? startOfDay(new Date(range.startDate)) : null,
      endDate: range.endDate ? startOfDay(new Date(range.endDate)) : null
    })),
    dueDate: data.dueDate ? startOfDay(new Date(data.dueDate)) : null
  };
};

const EditForm: React.FC<IEditFormProps & FormikProps<IEditFormValues>> = ({
  dataRequest,
  dirty,
  dss,
  engagementGuid,
  folders,
  handleSubmit,
  initialValues,
  isSubmitting,
  onCancel,
  setFieldValue,
  setFormikState,
  setValues,
  status,
  values,
  ...formikProps
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t: getTextToDisplay } = useTranslation();
  const [showAttachments, setShowAttachments] = useState(
    values.shouldAttachFiles === ThirdStepFormEnums.YES
  );
  const [documentTypes, setDocumentTypes] = useState<TDropdownOption[]>([]);
  const [jurisdictions, setJurisdictions] = useState<TDropdownOption[]>([]);
  const [propertyTaxSiteClasses, setPropertyTaxSiteClasses] = useState<
    TDropdownOption[]
  >([]);
  const [propertyTaxDocumentTypes, setPropertyTaxDocumentTypes] = useState<
    TDropdownOption[]
  >([]);
  const [preSelectedDocumentTypes, setPreSelectedDocumentTypes] = useState<{
    [key: string]: IPropertyTaxDocumentType[];
  }>({});
  const [isFolderReady, setIsFolderReady] = useState(false);

  const {
    isDataSpecsRendered,
    isDocumentImages,
    isERP,
    isJurisdictionsRendered,
    isPropertyTax
  } = getDRTypeChecks(dataRequest?.dataRequestTypeGuid);

  const formik = useMemo(
    () => ({
      dirty,
      handleSubmit,
      initialValues,
      isSubmitting,
      setFieldValue,
      setFormikState,
      setValues,
      status,
      values,
      ...formikProps
    }),
    [
      dirty,
      handleSubmit,
      initialValues,
      isSubmitting,
      setFieldValue,
      setFormikState,
      setValues,
      status,
      values,
      formikProps
    ]
  );

  const fetchDocumentTypes = useCallback(async () => {
    try {
      const response = await ApiService.getDocumentTypes();
      const documentTypesData = response.data as unknown as IDocumentTypes[];

      const documentTypes = documentTypesData
        .map((type: IDocumentTypes) => ({
          value: type.documentTypeGuid,
          label: type.name
        }))
        .sort((a, b) => {
          if (a.label === 'Other') return 1;
          if (b.label === 'Other') return -1;
          return 0;
        });

      setDocumentTypes(documentTypes);
    } catch (error) {
      pushServerErrorToast();
    }
  }, []);

  const fetchJurisdictions = useCallback(async () => {
    try {
      const response = await ApiService.getJurisdictionsV2(
        dataRequest?.engagementGuid || ''
      );
      const jurisdictionsData = response.data as unknown as IJurisdiction[];

      const jurisdictions = jurisdictionsData.map((type: IJurisdiction) => ({
        value: type.jurisdictionGuid,
        label: type.name
      }));
      setJurisdictions(jurisdictions);
    } catch (error) {
      pushServerErrorToast();
    }
  }, [dataRequest?.engagementGuid]);

  const fetchPropertyTaxSiteClasses = useCallback(async () => {
    try {
      const { data: siteClassesData } =
        await ApiService.getPropertyTaxSiteClasses();
      const mappedPropertyTaxSiteClasses: TDropdownOption[] = [];
      const propertyTaxDocumentTypesBySiteClass: {
        [key: string]: IPropertyTaxDocumentType[];
      } = {};
      siteClassesData.forEach(siteClass => {
        mappedPropertyTaxSiteClasses.push({
          value: siteClass.propertyTaxSiteClassGuid,
          label: siteClass.siteClassName
        });

        propertyTaxDocumentTypesBySiteClass[
          siteClass.propertyTaxSiteClassGuid
        ] = siteClass.propertyTaxDocumentTypes || [];
      });

      setPropertyTaxSiteClasses(mappedPropertyTaxSiteClasses);
      setPreSelectedDocumentTypes(propertyTaxDocumentTypesBySiteClass);
    } catch (error) {
      pushServerErrorToast();
    }
  }, []);

  const fetchPropertyTaxDocumentTypes = useCallback(async () => {
    try {
      const { data: documentTypesData } =
        await ApiService.getPropertyTaxDocumentTypes();
      const mappedPropertyTaxDocumentTypes: TDropdownOption[] = [];

      documentTypesData.forEach(docType => {
        mappedPropertyTaxDocumentTypes.push({
          value: docType.propertyTaxDocumentTypeGuid,
          label: docType.documentTypeName
        });
      });

      setPropertyTaxDocumentTypes(mappedPropertyTaxDocumentTypes);
    } catch (error) {
      pushServerErrorToast();
    }
  }, []);

  useEffect(() => {
    fetchDocumentTypes();
    fetchJurisdictions();
    if (isPropertyTax) {
      fetchPropertyTaxDocumentTypes();
      fetchPropertyTaxSiteClasses();
    }
  }, [
    fetchDocumentTypes,
    fetchJurisdictions,
    fetchPropertyTaxDocumentTypes,
    fetchPropertyTaxSiteClasses,
    isPropertyTax
  ]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const folderInitialized = useRef(false);

  useEffect(() => {
    if (folders && !isFolderReady && !folderInitialized.current) {
      const updatedDefaultFolder = checkAndSetDefaultFolder(
        folders,
        dataRequest?.defaultFolderGuid,
        dataRequest?.defaultFolder
      );

      if (updatedDefaultFolder) {
        setFieldValue('defaultFolder', updatedDefaultFolder, false);
        formik.resetForm({
          values: {
            ...formik.values,
            defaultFolder: updatedDefaultFolder
          }
        });
      }

      folderInitialized.current = true;
      setIsFolderReady(true);
    }
  }, [folders, dataRequest, setFieldValue, formik, isFolderReady]);

  useEffect(() => {
    if (isFolderReady) {
      setFormikState(prevState => ({
        ...prevState,
        dirty: false
      }));
    }
  }, [isFolderReady, setFormikState]);

  const handleFetchAssignableUsers = async (query: string) => {
    const response = await ApiService.getEngagementDataRequestAssignableUsers(
      engagementGuid || '',
      query
    );
    return response.data;
  };

  useEffect(() => {
    const formValuesWithoutShouldAttachFiles = omit(values, [
      'shouldAttachFiles',
      'hasFormChanged'
    ]);
    const initialValuesWithoutShouldAttachFiles = omit(initialValues, [
      'shouldAttachFiles',
      'hasFormChanged'
    ]);

    const normalizedFormValues = normalizeDates(
      formValuesWithoutShouldAttachFiles
    );
    const normalizedInitialValues = normalizeDates(
      initialValuesWithoutShouldAttachFiles
    );

    const hasFormChanged = !isEqual(
      normalizedFormValues,
      normalizedInitialValues
    );

    setFieldValue('hasFormChanged', hasFormChanged, false);
  }, [values, initialValues, setFieldValue]);

  const handleFetchErpSystemTypes = useCallback(async (query: string) => {
    return await ApiService.getErpSystemTypes(query).then(response => {
      return Array.isArray(response.data) ? response.data : [response.data];
    });
  }, []);

  const handleFetchTaxEngineTypes = useCallback(async (query: string) => {
    return await ApiService.getTaxEngineTypes(query).then(response => {
      return Array.isArray(response.data) ? response.data : [response.data];
    });
  }, []);

  const handleAttachFilesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setFieldValue('shouldAttachFiles', value, false);
    setShowAttachments(value === ThirdStepFormEnums.YES);

    if (value === ThirdStepFormEnums.NO) {
      dss.clearUploads();
      setFieldValue(
        'attachments',
        {
          addUploaded: [],
          addExisting: [],
          deleteAttachments: values.attachments.deleteAttachments
        },
        false
      );
    }
  };

  const handleDocumentTypesChange = (
    selected: string[] | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (Array.isArray(selected)) {
      const selectedDocumentTypes = selected.map(value => ({
        documentTypeGuid: value
      }));

      formik.setFieldValue('documentTypes', selectedDocumentTypes, false);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (selectedDocumentTypes.length > 0) {
          formik.setFieldError('documentTypes', undefined);
        } else {
          formik.setFieldError(
            'documentTypes',
            getTextToDisplay('modal.dataRequestModal.fields.documentTypes.min')
          );
        }

        formik.validateForm();
      }, 0);

      if (selected.includes(otherDocumentTypeGuid)) {
        formik.setFieldTouched('otherDocumentType', false);
      } else {
        formik.setFieldValue('otherDocumentType', null);
      }

      formik.setFieldTouched('documentTypes', true, false);
    }
  };

  const handlePropertyTaxSiteClassChange = (
    selected: string[] | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (Array.isArray(selected)) {
      formik.setFieldValue(
        'propertyTaxSiteClasses',
        selected.map(siteClassGuid => ({
          propertyTaxSiteClassGuid: siteClassGuid
        })),
        false
      );

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (selected.length > 0) {
          formik.setFieldError('propertyTaxSiteClasses', undefined);
        } else {
          formik.setFieldError(
            'propertyTaxSiteClasses',
            getTextToDisplay(
              'modal.dataRequestModal.fields.siteClasses.required'
            )
          );
        }

        formik.validateForm();
      }, 0);
      formik.setFieldTouched('propertyTaxSiteClasses', true, false);

      const docTypeGuidsToSelectFromSiteClass: string[] = selected.flatMap(
        siteClassGuid =>
          preSelectedDocumentTypes[siteClassGuid]?.map(
            docType => docType.propertyTaxDocumentTypeGuid
          ) || []
      );
      formik.setFieldValue(
        'propertyTaxDocumentTypes',
        Array.from(new Set(docTypeGuidsToSelectFromSiteClass)).map(
          docTypeGuid => ({
            propertyTaxDocumentTypeGuid: docTypeGuid
          })
        ),
        true
      );
      formik.setFieldTouched('propertyTaxDocumentTypes', true, false);
    }
  };

  const handlePropertyTaxDocumentTypesChange = (
    selected: string[] | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (Array.isArray(selected)) {
      const selectedPropertyTaxDocumentTypes = selected.map(value => ({
        propertyTaxDocumentTypeGuid: value
      }));

      formik.setFieldValue(
        'propertyTaxDocumentTypes',
        selectedPropertyTaxDocumentTypes,
        false
      );

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (selectedPropertyTaxDocumentTypes.length > 0) {
          formik.setFieldError('propertyTaxDocumentTypes', undefined);
        } else {
          formik.setFieldError(
            'propertyTaxDocumentTypes',
            getTextToDisplay(
              'modal.dataRequestModal.fields.propertyTaxDocumentTypes.required'
            )
          );
        }

        formik.validateForm();
      }, 0);
      formik.setFieldTouched('propertyTaxDocumentTypes', true, false);
    }
  };

  const handleJurisdictionsChange = (
    selected: string[] | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (Array.isArray(selected)) {
      const selectedJurisdictions = selected.map(value => ({
        jurisdictionGuid: value
      }));

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (
          dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.TAX_RETURNS ||
          dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.AUDIT_WORKPAPERS ||
          dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.SITE_INFORMATION
        ) {
          if (selectedJurisdictions.length > 0) {
            formik.setFieldError('jurisdictions', undefined);
          } else {
            formik.setFieldError(
              'jurisdictions',
              getTextToDisplay(
                'modal.dataRequestModal.fields.jurisdictions.required'
              )
            );
          }

          formik.validateForm();
        }
      }, 0);

      formik.setFieldValue('jurisdictions', selectedJurisdictions);
      formik.setFieldTouched('jurisdictions', true, false);
    }
  };

  const handleTransferDestinationChange = (folder: FolderSelection) => {
    if (formik.status?.transferDestinationError) {
      formik.setStatus({
        ...formik.status,
        transferDestinationError: null
      });
    }

    const trimmedFolderName = folder?.folderName?.trim() || '';

    if (trimmedFolderName.length > 0 && !folder?.folderGuid) {
      const result = isFolderNameExist(
        trimmedFolderName,
        folders,
        folder?.folderGuid,
        folder?.parentFolderGuid
      );

      if (result.hasExistingName) {
        const transferDestinationError = result.isArchived
          ? getTextToDisplay(
              'modal.dataRequestModal.fields.transferDestination.archived'
            )
          : getTextToDisplay(
              'modal.dataRequestModal.fields.transferDestination.exists'
            );

        formik.setStatus({
          ...formik.status,
          transferDestinationError
        });
        formik.setFieldValue('defaultFolder', folder);
        return;
      }
    }

    if (folder?.folderGuid) {
      formik.setFieldValue('defaultFolder', folder);
    } else {
      const transferDestinationError =
        FolderValidationUtils.validateFolderInput(folder, folders) as string;

      if (Boolean(transferDestinationError)) {
        formik.setStatus({
          ...formik.status,
          transferDestinationError: getTextToDisplay(transferDestinationError)
        });
      }

      formik.setFieldValue('defaultFolder', folder);
    }
  };

  const radioButtonGroupProps = {
    ...formikFieldProps('shouldAttachFiles', formik),
    onChange: handleAttachFilesChange
  };

  const documentTypesDropdownProps = {
    ...formikFieldProps('documentTypes', formik),
    disabled: !documentTypes.length,
    feedback: formik.touched.documentTypes
      ? formik.errors.documentTypes
      : undefined,
    invalid: !!(formik.touched.documentTypes && formik.errors.documentTypes),
    label: getTextToDisplay(
      'modal.dataRequestModal.fields.documentTypes.label'
    ),
    noOptionText: getTextToDisplay('dataRequest.noOptionsLabel'),
    notFoundLabel: getTextToDisplay('dataRequest.notFoundLabel'),
    onBlur: () => formik.setFieldTouched('documentTypes', true),
    onChange: handleDocumentTypesChange,
    options: documentTypes || [{ label: 'Select', value: 'Select' }],
    placeholder: getTextToDisplay('Select'),
    searchBoxPlaceholder: getTextToDisplay('Search'),
    selectAllLabel: getTextToDisplay('Select All'),
    selectionLabel: getTextToDisplay('dataRequest.selectionLabel'),
    showAdditionalButtons: false,
    value: formik.values.documentTypes?.map(docType => docType.documentTypeGuid)
  };

  const propertyTaxSiteClassesDropdownProps = {
    ...formikFieldProps('propertyTaxSiteClasses', formik),
    disabled: !propertyTaxSiteClasses.length,
    feedback: formik.touched.propertyTaxSiteClasses
      ? formik.errors.propertyTaxSiteClasses
      : undefined,
    invalid: !!(
      formik.touched.propertyTaxSiteClasses &&
      formik.errors.propertyTaxSiteClasses
    ),
    label: getTextToDisplay('modal.dataRequestModal.fields.siteClasses.label'),
    noOptionText: getTextToDisplay('dataRequest.noOptionsLabel'),
    notFoundLabel: getTextToDisplay('dataRequest.notFoundLabel'),
    onBlur: () => formik.setFieldTouched('propertyTaxSiteClasses', true),
    onChange: handlePropertyTaxSiteClassChange,
    options: propertyTaxSiteClasses || [{ label: 'Select', value: 'Select' }],
    placeholder: getTextToDisplay('Select'),
    searchBoxPlaceholder: getTextToDisplay('Search'),
    selectAllLabel: getTextToDisplay('Select All'),
    selectionLabel: getTextToDisplay('dataRequest.selectionLabel'),
    showAdditionalButtons: false,
    value: formik.values.propertyTaxSiteClasses?.map(
      siteClassType => siteClassType.propertyTaxSiteClassGuid
    )
  };

  const propertyTaxDocumentTypesDropdownProps = {
    ...formikFieldProps('propertyTaxDocumentTypes', formik),
    disabled: !propertyTaxDocumentTypes.length,
    feedback: formik.touched.propertyTaxDocumentTypes
      ? formik.errors.propertyTaxDocumentTypes
      : undefined,
    invalid: !!(
      formik.touched.propertyTaxDocumentTypes &&
      formik.errors.propertyTaxDocumentTypes
    ),
    label: getTextToDisplay(
      'modal.dataRequestModal.fields.propertyTaxDocumentTypes.label'
    ),
    noOptionText: getTextToDisplay('dataRequest.noOptionsLabel'),
    notFoundLabel: getTextToDisplay('dataRequest.notFoundLabel'),
    onBlur: () => formik.setFieldTouched('propertyTaxDocumentTypes', true),
    onChange: handlePropertyTaxDocumentTypesChange,
    options: propertyTaxDocumentTypes || [{ label: 'Select', value: 'Select' }],
    placeholder: getTextToDisplay('Select'),
    searchBoxPlaceholder: getTextToDisplay('Search'),
    selectAllLabel: getTextToDisplay('Select All'),
    selectionLabel: getTextToDisplay('dataRequest.selectionLabel'),
    showAdditionalButtons: false,
    value: formik.values.propertyTaxDocumentTypes?.map(
      docType => docType.propertyTaxDocumentTypeGuid
    )
  };

  const jurisdictionsDropdownProps = {
    ...formikFieldProps('jurisdictions', formik),
    disabled: !jurisdictions.length,
    feedback: formik.touched.jurisdictions
      ? formik.errors.jurisdictions
      : undefined,
    invalid: !!(formik.touched.jurisdictions && formik.errors.jurisdictions),
    label:
      dataRequest?.dataRequestTypeGuid === DataRequestTypeGuidEnum.OTHER
        ? getTextToDisplay(
            'modal.dataRequestModal.fields.jurisdictions.optional'
          )
        : getTextToDisplay('modal.dataRequestModal.fields.jurisdictions.label'),
    noOptionText: getTextToDisplay('dataRequest.noOptionsLabel'),
    notFoundLabel: getTextToDisplay('dataRequest.notFoundLabel'),
    onBlur: () => formik.setFieldTouched('jurisdictions', true),
    onChange: handleJurisdictionsChange,
    options: jurisdictions || [{ label: 'Select', value: 'Select' }],
    placeholder: getTextToDisplay('Select'),
    searchBoxPlaceholder: getTextToDisplay('Search'),
    selectAllLabel: getTextToDisplay('Select All'),
    selectionLabel: getTextToDisplay('dataRequest.selectionLabel'),
    showAdditionalButtons: false,
    value: formik.values.jurisdictions?.map(
      jurisdiction => jurisdiction.jurisdictionGuid
    )
  };

  const attachmentProps = {
    ...formikFieldProps('attachments', formik),
    attachments: dataRequest?.attachments || [],
    dss: dss,
    engagementGuid: dataRequest?.engagementGuid || '',
    engagementName: dataRequest?.engagementDisplayNameShort || '',
    onChange: (updates: IAttachmentUpdates) => {
      formik.setFieldValue('attachments', updates);

      if (updates.addUploaded.length > 0 || updates.addExisting.length > 0) {
        formik.setFieldError('attachments', undefined);
      }
    },
    value: formik.values.attachments
  };

  const isDisabled =
    !formik.dirty ||
    !isFolderReady ||
    !values.hasFormChanged ||
    Boolean(formik.status.transferDestinationError);
  return (
    <>
      {status?.error}
      <form
        autoComplete="off"
        className="edit-data-request-form"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <TextInput
          {...formikFieldProps('title', formik)}
          label={getTextToDisplay('dataRequest.modal.fields.title.label')}
        />
        {isERP && (
          <>
            <div className="ry-text-input">
              <SystemAutocomplete<IErpSystemTypes>
                {...formikAutocompleteAjaxProps('erpSystem', formik)}
                label={getTextToDisplay(
                  'modal.dataRequestModal.fields.erpSystemTypes.label'
                )}
                onBlur={() => formik.setFieldTouched('erpSystem', true)}
                onChange={value => {
                  formik.setFieldValue('erpSystem', value);
                  if (value?.name !== 'Other') {
                    formik.setFieldValue('otherErpSystem', null);
                  }
                }}
                onFetchOptions={handleFetchErpSystemTypes}
              />
            </div>
            {formik.values.erpSystem?.name === 'Other' && (
              <div className="ry-text-input">
                <TextInput
                  {...formikFieldProps('otherErpSystem', formik)}
                  label={getTextToDisplay(
                    'modal.dataRequestModal.fields.otherErpSystem.label'
                  )}
                />
              </div>
            )}
            <div className="ry-text-input">
              <SystemAutocomplete<ITaxEngineTypes>
                {...formikAutocompleteAjaxProps('taxEngine', formik)}
                label={getTextToDisplay(
                  'modal.dataRequestModal.fields.taxEngineTypes.label'
                )}
                onBlur={() => formik.setFieldTouched('taxEngine', true)}
                onChange={value => {
                  formik.setFieldValue('taxEngine', value);
                  if (value?.name !== 'Other') {
                    formik.setFieldValue('otherTaxEngine', null);
                  }
                }}
                onFetchOptions={handleFetchTaxEngineTypes}
              />
            </div>
            {formik.values.taxEngine?.name === 'Other' && (
              <div className="ry-text-input">
                <TextInput
                  {...formikFieldProps('otherTaxEngine', formik)}
                  label={getTextToDisplay(
                    'modal.dataRequestModal.fields.otherTaxEngine.label'
                  )}
                />
              </div>
            )}
          </>
        )}
        {isDocumentImages && (
          <>
            <div
              className={classNames(
                'edit-data-request-form__multiselect-field',
                {
                  'edit-data-request-form__multiselect-field--error':
                    formik.errors.documentTypes
                }
              )}
            >
              <Multiselect {...documentTypesDropdownProps} />
            </div>
            {formik.values.documentTypes?.some(
              docType => docType.documentTypeGuid === otherDocumentTypeGuid
            ) && (
              <div className="ry-text-input">
                <TextInput
                  {...formikFieldProps('otherDocumentType', formik)}
                  label={getTextToDisplay(
                    'modal.dataRequestModal.fields.otherDocumentType.label'
                  )}
                />
              </div>
            )}
          </>
        )}
        {isPropertyTax && (
          <>
            <div
              className={classNames(
                'edit-data-request-form__multiselect-field',
                {
                  'edit-data-request-form__multiselect-field--error':
                    formik.errors.propertyTaxSiteClasses
                }
              )}
            >
              <Multiselect {...propertyTaxSiteClassesDropdownProps} />
            </div>
            <div
              className={classNames(
                'edit-data-request-form__multiselect-field',
                {
                  'edit-data-request-form__multiselect-field--error':
                    formik.errors.propertyTaxDocumentTypes
                }
              )}
            >
              <Multiselect {...propertyTaxDocumentTypesDropdownProps} />
              <div
                style={{
                  position: 'relative',
                  top: '-30px',
                  color: '#636575',
                  fontSize: '0.875rem',
                  lineHeight: '1.5rem'
                }}
              >
                {getTextToDisplay(
                  'modal.dataRequestModal.fields.propertyTaxDocumentTypes.helperText'
                )}
              </div>
            </div>
          </>
        )}
        {isJurisdictionsRendered && (
          <div
            className={classNames('edit-data-request-form__multiselect-field', {
              'edit-data-request-form__multiselect-field--error':
                formik.errors.jurisdictions
            })}
          >
            <Multiselect {...jurisdictionsDropdownProps} />{' '}
          </div>
        )}
        {isDataSpecsRendered && (
          <Textarea
            {...formikFieldProps('dataSpecs', formik)}
            className="edit-data-request-form__textarea-field"
            helperText={getTextToDisplay(
              'dataRequest.modal.fields.dataSpecs.helperText'
            )}
            label={
              dataRequest?.dataRequestTypeGuid ===
              DataRequestTypeGuidEnum.SITE_INFORMATION
                ? getTextToDisplay(
                    'dataRequest.modal.fields.dataSpecs.optional'
                  )
                : getTextToDisplay('dataRequest.modal.fields.dataSpecs.label')
            }
            maxLength={maxLengthDescription}
          />
        )}
        <Textarea
          {...formikFieldProps('description', formik)}
          className="edit-data-request-form__textarea-field"
          label={
            dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART
              ? getTextToDisplay('Description')
              : getTextToDisplay('dataRequest.modal.fields.description.label')
          }
          maxLength={maxLengthDescription}
        />
        <SelectFolder
          disabled={!isFolderReady}
          feedback={
            formik.status?.transferDestinationError ||
            formik.errors.defaultFolder
          }
          folders={folders || []}
          invalid={
            !!formik.status?.transferDestinationError ||
            !!formik.errors.defaultFolder
          }
          isHideRyanInternal={true}
          label={getTextToDisplay(
            'dataRequest.modal.fields.defaultFolder.label'
          )}
          moveFileCount={2}
          onChange={handleTransferDestinationChange}
          rootName={dataRequest?.engagementDisplayNameShort || ''}
          value={formik.values.defaultFolder}
        />
        <div className="edit-data-request-form__date-ranges">
          <div className="edit-data-request-form__date-ranges__label">
            <label className="ry-label">
              {getTextToDisplay('dataRequest.recordsModal.dateRanges')}
            </label>
          </div>
          <MultiSelectDateRange
            {...formikDatepickerProps('daterange', formik)}
            daterange={
              formik.values.daterange as unknown as TMultiSelectDateRange[]
            }
            formik={formik}
            isRequired={true}
            name="daterange"
          />
        </div>
        <UserAutocomplete
          {...formikAutocompleteAjaxProps('assignedTo', formik)}
          engagementGuid={dataRequest?.engagementGuid}
          label={getTextToDisplay('dataRequest.modal.fields.assignedTo.label')}
          onFetchOptions={handleFetchAssignableUsers}
        />
        <Datepicker
          {...formikDatepickerProps('dueDate', formik)}
          label={getTextToDisplay('dataRequest.modal.fields.dueDate.label')}
          minDate={minDueDate(dataRequest)}
        />

        <label className="ry-label">
          {getTextToDisplay('modal.dataRequestModal.attachText')}
        </label>
        <div className="edit-data-request-form__attach-buttons-wrapper">
          <RadioButtonGroup {...radioButtonGroupProps} name="shouldAttachFiles">
            <RadioButton
              checked={values.shouldAttachFiles === ThirdStepFormEnums.YES}
              label={getTextToDisplay('modal.dataRequestModal.attachYes')}
              value={ThirdStepFormEnums.YES}
            />
            <RadioButton
              checked={values.shouldAttachFiles === ThirdStepFormEnums.NO}
              label={getTextToDisplay('modal.dataRequestModal.attachNo')}
              value={ThirdStepFormEnums.NO}
            />
          </RadioButtonGroup>
        </div>
        {showAttachments && (
          <ManageAttachments
            {...attachmentProps}
            errors={formik.errors}
            label={getTextToDisplay(
              'modal.dataRequestModal.fields.attachments.label'
            )}
            multiple
            queueItemGuidToFilterExistingItems={dataRequest?.queueItemGuid}
            showExisting={false}
            showLabel
          />
        )}
        {dataRequest?.attachments?.length ? (
          <ManageAttachments
            {...attachmentProps}
            isAttachingFiles={false}
            showExisting
            showLabel={false}
          />
        ) : null}
        <ButtonGroup>
          <Button
            disabled={isDisabled}
            loading={dss.getUploadingPromise() || formik.status?.loading}
            text={getTextToDisplay('Save')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
          <Button
            disabled={
              (dss.getUploadingPromise() || formik.status?.loading) !== null
            }
            onClick={onCancel}
            text={getTextToDisplay('Cancel')}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const validationSchema = (
  folders: IFolderTree[] | null,
  getTextToDisplay: TFunction,
  dataRequest?: IDataRequestV2
) => {
  const {
    isDocumentImages,
    isDataSpecsRendered,
    isERP,
    isJurisdictionsRendered
  } = getDRTypeChecks(dataRequest?.dataRequestTypeGuid);

  const baseSchema = yup.object().shape({
    assignedTo: yup
      .object()
      .nullable()
      .required(
        getTextToDisplay('dataRequest.modal.fields.assignedTo.required')
      ),
    attachments: yup
      .mixed()
      .test(
        'has-attachments',
        getTextToDisplay('modal.dataRequestModal.fields.attachments.required'),
        function (value) {
          const { shouldAttachFiles } = this.parent;
          value = value || {
            addUploaded: [],
            deleteAttachments: [],
            addExisting: []
          };

          if (shouldAttachFiles === ThirdStepFormEnums.YES) {
            const hasAttachments =
              (value?.addExisting?.length || 0) > 0 ||
              (value?.addUploaded?.length || 0) > 0;
            return hasAttachments;
          }

          return true;
        }
      ),
    daterange: yup
      .array()
      .of(
        yup.object().shape({
          startDate: yup
            .date()
            .nullable()
            .required(
              getTextToDisplay('multiSelectDateRange.startDateRequired')
            )
            .test(
              'is-before-end',
              getTextToDisplay('multiSelectDateRange.startDateAfterEndDate'),
              function (startDate) {
                const { endDate } = this.parent;
                return !endDate || (startDate && startDate <= endDate);
              }
            ),
          endDate: yup
            .date()
            .nullable()
            .required(getTextToDisplay('multiSelectDateRange.endDateRequired'))
            .min(
              yup.ref('startDate'),
              getTextToDisplay('multiSelectDateRange.endDateAfterStartDate')
            )
        })
      )
      .required(getTextToDisplay('multiSelectDateRange.dateRangeRequired')),
    description: yup
      .string()
      .max(
        maxLengthDescription,
        getTextToDisplay('dataRequest.modal.fields.description.maxLength', {
          length: maxLengthDescription
        })
      )
      .nullable()
      .test(
        'is-required',
        getTextToDisplay('dataRequest.modal.fields.description.required'),
        function (value) {
          if (
            dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART
          ) {
            return !!value;
          }

          return true;
        }
      ),
    dueDate: yup
      .date()
      .nullable()
      .required(getTextToDisplay('dataRequest.modal.fields.dueDate.required'))
      .min(
        minDueDate(dataRequest),
        dataRequest
          ? getTextToDisplay('dataRequest.modal.fields.dueDate.minCreated')
          : getTextToDisplay('dataRequest.modal.fields.dueDate.minToday')
      ),
    title: yup
      .string()
      .trim()
      .required(getTextToDisplay('dataRequest.modal.fields.title.required'))
      .max(
        maxLengthTitle,
        getTextToDisplay('dataRequest.modal.fields.title.maxLength', {
          length: maxLengthTitle
        })
      )
  });

  const documentImagesSchema = yup.object().shape({
    documentTypes: yup
      .array()
      .of(
        yup.object().shape({
          documentTypeGuid: yup
            .string()
            .required(
              getTextToDisplay(
                'modal.dataRequestModal.fields.documentTypes.required'
              )
            )
        })
      )
      .min(
        1,
        getTextToDisplay('modal.dataRequestModal.fields.documentTypes.min')
      )
      .required(
        getTextToDisplay('modal.dataRequestModal.fields.documentTypes.required')
      ),
    otherDocumentType: yup.string().when('documentTypes', {
      is: (docTypes: { documentTypeGuid: string }[] = []) =>
        Array.isArray(docTypes) &&
        docTypes.some(
          docType => docType.documentTypeGuid === otherDocumentTypeGuid
        ),
      then: yup
        .string()
        .trim()
        .max(
          50,
          getTextToDisplay(
            'modal.dataRequestModal.fields.otherDocumentType.maxLength',
            { count: maxLengthTitle }
          )
        )
        .required(
          getTextToDisplay(
            'modal.dataRequestModal.fields.otherDocumentType.required'
          )
        ),
      otherwise: yup.string().notRequired().nullable()
    })
  });

  const erpSchema = yup.object().shape({
    erpSystem: yup
      .object()
      .nullable()
      .required(
        getTextToDisplay(
          'modal.dataRequestModal.fields.erpSystemTypes.required'
        )
      ),
    taxEngine: yup
      .object()
      .nullable()
      .required(
        getTextToDisplay(
          'modal.dataRequestModal.fields.taxEngineTypes.required'
        )
      ),
    otherErpSystem: yup
      .string()
      .when('erpSystem', {
        is: (erpSystem: IErpSystemTypes) => erpSystem?.name === 'Other',
        then: yup
          .string()
          .trim()
          .max(
            maxLengthTitle,
            getTextToDisplay(
              'modal.dataRequestModal.fields.otherErpSystem.maxLength',
              { count: maxLengthTitle }
            )
          )
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.otherErpSystem.required'
            )
          ),
        otherwise: yup.string().nullable()
      })
      .nullable(),
    otherTaxEngine: yup
      .string()
      .when('taxEngine', {
        is: (taxEngine: ITaxEngineTypes) => taxEngine?.name === 'Other',
        then: yup
          .string()
          .trim()
          .max(
            maxLengthTitle,
            getTextToDisplay(
              'modal.dataRequestModal.fields.otherTaxEngine.maxLength',
              { count: maxLengthTitle }
            )
          )
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.otherTaxEngine.required'
            )
          ),
        otherwise: yup.string().nullable()
      })
      .nullable()
  });

  const jurisdictionsSchema = yup.object().shape({
    jurisdictions: yup.array().when([], {
      is: () =>
        dataRequest?.dataRequestTypeGuid ===
          DataRequestTypeGuidEnum.TAX_RETURNS ||
        dataRequest?.dataRequestTypeGuid ===
          DataRequestTypeGuidEnum.AUDIT_WORKPAPERS ||
        dataRequest?.dataRequestTypeGuid ===
          DataRequestTypeGuidEnum.SITE_INFORMATION,
      then: yup
        .array()
        .required(
          getTextToDisplay(
            'modal.dataRequestModal.fields.jurisdictions.required'
          )
        ),
      otherwise: yup
        .array()
        .notRequired()
        .when('submitAttempted', {
          is: true,
          then: (schema: { required: (arg0: any) => any }) =>
            schema.required(
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherDocumentType.required'
              )
            )
        })
    })
  });

  if (isDataSpecsRendered) {
    const baseField = yup.string().max(
      maxLengthDescription,
      getTextToDisplay('dataRequest.modal.fields.dataSpecs.maxLength', {
        length: maxLengthDescription
      })
    );

    if (
      dataRequest?.dataRequestTypeGuid ===
      DataRequestTypeGuidEnum.SITE_INFORMATION
    ) {
      return baseSchema.shape({
        dataSpecs: baseField
      });
    } else {
      return baseSchema.shape({
        dataSpecs: baseField.required(
          getTextToDisplay('modal.dataRequestModal.fields.dataSpecs.required')
        )
      });
    }
  }

  return yup.object().shape({
    ...baseSchema.fields,
    ...(isDocumentImages && documentImagesSchema.fields),
    ...(isERP && erpSchema.fields),
    ...(isJurisdictionsRendered && jurisdictionsSchema.fields)
  });
};

export default withTranslation()(
  withFormik<IEditFormProps, IEditFormValues>({
    mapPropsToValues: props => {
      const { dataRequest, folders } = props;
      const {
        isDocumentImages,
        isDataSpecsRendered,
        isERP,
        isJurisdictionsRendered,
        isPropertyTax
      } = getDRTypeChecks(dataRequest?.dataRequestTypeGuid);

      const defaultFolder = folders
        ? checkAndSetDefaultFolder(
            folders,
            dataRequest?.defaultFolderGuid,
            dataRequest?.defaultFolder
          )
        : null;

      let additionalValues = {};

      if (isDataSpecsRendered) {
        additionalValues = {
          ...additionalValues,
          dataSpecs: dataRequest?.dataSpecs || ''
        };
      }
      if (isDocumentImages) {
        additionalValues = {
          ...additionalValues,
          documentTypes: dataRequest?.documentTypes || [],
          otherDocumentType: dataRequest?.otherDocumentType || null
        };
      }
      if (isERP) {
        additionalValues = {
          ...additionalValues,
          erpSystem: dataRequest?.erpSystem || null,
          taxEngine: dataRequest?.taxEngine || null,
          otherErpSystem: dataRequest?.otherErpSystem || null,
          otherTaxEngine: dataRequest?.otherTaxEngine || null
        };
      }
      if (isJurisdictionsRendered) {
        additionalValues = {
          ...additionalValues,
          jurisdictions: dataRequest?.jurisdictions || []
        };
      }
      if (isPropertyTax) {
        additionalValues = {
          ...additionalValues,
          propertyTaxDocumentTypes: dataRequest?.propertyTaxDocumentTypes || [],
          propertyTaxSiteClasses: dataRequest?.propertyTaxSiteClasses || []
        };
      }
      if (dataRequest) {
        return {
          ...defaultValues,
          ...additionalValues,

          assignedTo: dataRequest.assignedTo || null,
          attachments: {
            addUploaded: [],
            deleteAttachments: [],
            addExisting: []
          },
          daterange: dataRequest.dateRange
            ? dataRequest.dateRange.map((range: TMultiSelectDateRange) => ({
                startDate: range.dateStart ? new Date(range.dateStart) : null,
                endDate: range.dateEnd ? new Date(range.dateEnd) : null
              }))
            : [{ startDate: null, endDate: null }],
          defaultFolder: defaultFolder,
          description: dataRequest.description || '',
          dueDate: dataRequest.dueDate ? parseISO(dataRequest.dueDate) : null,
          title: dataRequest.title || ''
        };
      }
      return defaultValues;
    },
    mapPropsToStatus: () => ({
      loading: null
    }),
    validationSchema: ({
      folders,
      t: getTextToDisplay,
      dataRequest
    }: IEditFormProps) =>
      validationSchema(folders, getTextToDisplay, dataRequest),
    validateOnChange: false,
    validateOnBlur: false,
    validate: async (values, { folders, t: getTextToDisplay, dataRequest }) => {
      let errors: FormikErrors<any> = {};

      try {
        await validateYupSchema(
          values,
          validationSchema(folders, getTextToDisplay, dataRequest),
          true
        );
      } catch (err) {
        errors = { ...yupToFormErrors(err) };
      }

      const dateRanges = values.daterange || [];
      const rangeOccurrences: Record<string, number[]> = {};
      dateRanges.forEach((range, index) => {
        if (range.startDate && range.endDate) {
          const rangeKey = `${startOfDay(
            range.startDate as Date
          ).getTime()}-${startOfDay(range.endDate as Date).getTime()}`;

          if (!rangeOccurrences[rangeKey]) {
            rangeOccurrences[rangeKey] = [];
          }

          rangeOccurrences[rangeKey].push(index);
        }
      });

      Object.values(rangeOccurrences).forEach(occurrences => {
        if (occurrences.length > 1) {
          occurrences.forEach(index => {
            if (!errors.daterange) {
              errors.daterange = [];
            }
            const daterangeErrors = errors.daterange as FormikErrors<any>[];

            daterangeErrors[index] =
              typeof daterangeErrors[index] === 'object'
                ? {
                    ...daterangeErrors[index],
                    range: getTextToDisplay(
                      'multiSelectDateRange.duplicateDateRange'
                    )
                  }
                : {
                    range: getTextToDisplay(
                      'multiSelectDateRange.duplicateDateRange'
                    )
                  };
          });
        }
      });

      const { isDocumentImages, isJurisdictionsRendered, isPropertyTax } =
        getDRTypeChecks(dataRequest?.dataRequestTypeGuid);

      if (isDocumentImages) {
        if (!values.documentTypes || values.documentTypes.length === 0) {
          errors.documentTypes = getTextToDisplay(
            'modal.dataRequestModal.fields.documentTypes.min'
          );
        } else {
          delete errors.documentTypes;
        }
        const isOtherSelected = values.documentTypes?.some(
          docType => docType.documentTypeGuid === otherDocumentTypeGuid
        );

        if (isOtherSelected && !values.otherDocumentType) {
          errors.otherDocumentType = getTextToDisplay(
            'modal.dataRequestModal.fields.otherDocumentType.required'
          );
        } else if ((values.otherDocumentType || '').length > maxLengthTitle) {
          errors.otherDocumentType = getTextToDisplay(
            'modal.dataRequestModal.fields.otherDocumentType.maxLength',
            { count: maxLengthTitle }
          );
        } else {
          delete errors.otherDocumentType;
        }
      } else {
        delete errors.otherDocumentType;
      }

      if (isJurisdictionsRendered) {
        const isJurisdictionRequiredDataRequestType =
          dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.TAX_RETURNS ||
          dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.AUDIT_WORKPAPERS ||
          dataRequest?.dataRequestTypeGuid ===
            DataRequestTypeGuidEnum.SITE_INFORMATION;

        if (isJurisdictionRequiredDataRequestType) {
          if (!values.jurisdictions || values.jurisdictions.length === 0) {
            errors.jurisdictions = getTextToDisplay(
              'modal.dataRequestModal.fields.jurisdictions.required'
            );
          }
        }
      }

      if (isPropertyTax) {
        if (
          !values.propertyTaxSiteClasses ||
          values.propertyTaxSiteClasses.length === 0
        ) {
          errors.propertyTaxSiteClasses = getTextToDisplay(
            'modal.dataRequestModal.fields.siteClasses.required'
          );
        } else {
          delete errors.propertyTaxSiteClasses;
        }

        if (
          !values.propertyTaxDocumentTypes ||
          values.propertyTaxDocumentTypes.length === 0
        ) {
          errors.propertyTaxDocumentTypes = getTextToDisplay(
            'modal.dataRequestModal.fields.propertyTaxDocumentTypes.required'
          );
        } else {
          delete errors.propertyTaxDocumentTypes;
        }
      }

      return errors;
    },
    handleSubmit: async (values, formik) => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length > 0) {
        formik.setTouched(
          Object.keys(errors).reduce(
            (acc, key) => ({ ...acc, [key]: true }),
            {}
          ),
          true
        );
        return;
      }
      const {
        setStatus,
        setSubmitting,
        props: {
          t: getTextToDisplay,
          dataRequest,
          isFetchCompleteDataRequest,
          onSubmitted
        }
      } = formik;

      const {
        isDocumentImages,
        isDataSpecsRendered,
        isERP,
        isJurisdictionsRendered,
        isPropertyTax
      } = getDRTypeChecks(dataRequest?.dataRequestTypeGuid);

      let folderField = {};
      if (values.defaultFolder?.folderGuid) {
        folderField = {
          defaultFolderGuid: values.defaultFolder.folderGuid
        };
      } else if (values.defaultFolder?.folderName) {
        folderField = {
          folder: {
            folderName: values.defaultFolder.folderName,
            folderGuid: null,
            parentFolderGuid: values.defaultFolder.parentFolderGuid || null
          }
        };
      }

      const dataRequestRequest: IUpdateDataRequestRequest = {
        assignedToUserGuid: values.assignedTo!.memberGuid,
        attachments: undefined,
        attachmentsToUpsert: values.attachments,
        dateRange: values.daterange?.map(range => ({
          dateStart: formatDate(range.startDate!, false, 'YYYY/MM/DD'),
          dateEnd: formatDate(range.endDate!, false, 'YYYY/MM/DD')
        })),
        description: values.description || null,
        dueDate: formatDate(values.dueDate!, false, 'YYYY/MM/DD'),
        title: values.title,
        ...folderField,
        ...(isDataSpecsRendered && {
          dataSpecs: values.dataSpecs || null
        }),
        ...(isDocumentImages && {
          documentTypes: values.documentTypes?.map(docType => ({
            documentTypeGuid: docType.documentTypeGuid
          })),
          otherDocumentType: values.otherDocumentType || null
        }),
        ...(isERP && {
          erpSystemGuid: values.erpSystem?.erpSystemGuid,
          taxEngineGuid: values.taxEngine?.taxEngineGuid,
          otherErpSystem: values.otherErpSystem || null,
          otherTaxEngine: values.otherTaxEngine || null
        }),
        ...(isJurisdictionsRendered && {
          jurisdictions: values.jurisdictions?.map(jurisdiction => ({
            jurisdictionGuid: jurisdiction.jurisdictionGuid
          }))
        }),
        ...(isPropertyTax && {
          propertyTaxSiteClasses: values.propertyTaxSiteClasses?.map(
            siteClassType => ({
              propertyTaxSiteClassGuid: siteClassType.propertyTaxSiteClassGuid,
              siteClassName: ''
            })
          ),
          propertyTaxDocumentTypes: values.propertyTaxDocumentTypes?.map(
            docType => ({
              propertyTaxDocumentTypeGuid: docType.propertyTaxDocumentTypeGuid
            })
          )
        })
      };

      // HACK: Worklist: Force complete DR payload on Edit (Start)
      let originalDataRequestDetails: any = null;
      if (isFetchCompleteDataRequest) {
        const { data } = await ApiService.getDataRequestV2(
          (dataRequest as IDataRequestV2).queueItemGuid
        );
        originalDataRequestDetails = {
          ...data,
          accountGuid: '00000000-0000-0000-0000-000000000000',
          assignedToEmail: (data as any).assignedTo.email,
          codeAssistance: data.codeAssistance || null,
          createdByEmail: null,
          createdImpersonatedByEmail: null,
          createdImpersonatedByName: null,
          dataSpecs: data.dataSpecs || null,
          defaultFolder: null,
          defaultFolderGuid: null,
          filesOrigin: (data as any).filesOrigin || null,
          justification: data.justification || null
        };
      }

      const dataRequestToSave = {
        ...(originalDataRequestDetails || dataRequest),
        ...(dataRequestRequest as unknown as IDataRequestV2)
      } as any;

      if (isFetchCompleteDataRequest) {
        dataRequestToSave.assignedTo = {
          ...dataRequestToSave.assignedTo,
          assignedToMemberGuid: dataRequestRequest.assignedToUserGuid
        };
        dataRequestToSave.createdByMember = {
          ...dataRequestToSave.createdByMember,
          createdByMemberGuid: dataRequestToSave.createdBy
        };

        delete dataRequestToSave.pastDueNotificationDate;
      }
      // HACK: Worklist: Force complete DR payload on Edit (End)

      const promise = ApiService.updateDataRequestV2(dataRequestToSave);

      setStatus({ loading: promise });

      const hasFormChanged = values.hasFormChanged;

      try {
        const response = await promise;
        const savedDataRequest = response.data;
        const { title } = savedDataRequest;

        hasFormChanged &&
          pushToast({
            type: EMessageTypes.SUCCESS,
            title: getTextToDisplay('dataRequest.modal.edit.success.title'),
            content: getTextToDisplay(
              'dataRequest.modal.edit.success.content',
              {
                title
              }
            )
          });

        setStatus({ loading: null });
        setSubmitting(false);
        onSubmitted(savedDataRequest as IDataRequestV2);
      } catch (error: any) {
        const { data, status } = error.response || {};

        let folderNameError: string | null = null;

        if (
          status === ApiErrorResponseEnums.BAD_REQUEST &&
          Array.isArray(data)
        ) {
          data.forEach(error => {
            if (error.propertyName === 'FolderName') {
              folderNameError = error.errorMessage;
            }
          });
        }

        if (folderNameError) {
          setStatus({
            loading: null,
            transferDestinationError: folderNameError
          });
          return;
        }

        setStatus({
          error: (
            <Message title={getTextToDisplay('serverError.title')} type="error">
              {getTextToDisplay('serverError.content')}
            </Message>
          ),
          loading: null
        });
      }
    }
  })(EditForm)
);
