import { IEngagementSummary } from './IEngagementSummary';

export enum InvoiceStatus {
  PastDue = 1,
  Outstanding,
  Closed
}

export interface IInvoice {
  invoiceId: number;
  invoiceGuid: string;
  engagementGuid: string;
  name: string;
  status: string;
  statusId: InvoiceStatus;
  amountDue: number;
  balance: number;
  invoiceNumber: string;
  paymentTerms: string;
  invoiceDate: Date;
  dueDate: Date | null;
  isoCurrencyCode: string;
  replaceInvoices: string | null;
  siteName: string;
  invoiceEngagements: IInvoiceEngagements[];
}

export interface IInvoiceEngagements extends IEngagementSummary {
  amountDue: number;
  balance: number;
  invoiceGuid: string;
  invoiceId: number;
  isGhosted: boolean;
  jurisdiction: string;
  serviceType: string;
  siteName: string;
  status: string;
  statusId: InvoiceStatus;
}
