export enum DataRequestTypeGuidEnum {
  AUDIT_WORKPAPERS = '9889ae4d-9ba4-42f7-8c80-7511c7a62fc7',
  DOCUMENT_IMAGES = '7dfb0b6c-33fe-413a-b63d-e37ac3f3a3c6',
  ERP_DATA = '1eee2294-7b12-4531-a3ca-44c9371beeb2',
  ORGANIZATIONAL_CHART = '1cfe5c6a-d8d1-4614-bc07-5c749908eff5',
  OTHER = 'b0fd0e76-5eef-4016-9391-824879f51465',
  SITE_INFORMATION = '588f7101-673d-47c6-839e-2b2377688061',
  TAX_RETURNS = '7c8d7c40-df16-4494-959d-b618cb486263'
}

export enum DataRequestDocumentTypeEnum {
  CONTRACTS = 'ee980e3a-0e2b-4723-8eb9-4d56177871d1',
  FIXED_ASSET_REQUISITION = '157c8de2-1742-49a9-b085-98b7ee86556a',
  INVOICES = '123f2870-f438-49b8-adff-780967a04683',
  OTHER = '201f57a1-8129-4644-bf0c-dd20df83208d',
  PURCHASE_ORDERS = 'a242f5fb-a835-43df-80b5-394a3bd5ce45'
}
