import { ActivityType, IDataRequestSummary } from 'interfaces';
import { formatThirdPartyVisibility } from 'utils/formatThirdPartyVisibility';

import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';
import { CommentActivity } from './CommentActivity';

export const FileActivity: FunctionComponent<IActivityProps> = props => {
  const {
    activity,
    isNotification,
    onCloseNotificationDrawer,
    showAccountName,
    showEngagementName,
    t,
    user
  } = props;

  const {
    createdBy,
    createdByName,
    engagementGuid,
    entityGuid,
    snapShot: { documentInformation, entitySummary }
  } = activity;

  const by = `by-${user.userGuid === createdBy ? 'you' : 'x'}`;

  // Context helper.
  function you(userGuid: string) {
    return user.userGuid === userGuid ? 'you' : 'x';
  }

  switch (activity.activityTypeId) {
    //

    /**
     * Files Added (ad hoc or linked)
     */
    case ActivityType.FileUploadedAdHoc:
    case ActivityType.FileUploadedAdHocOnBehalfOf:
      const documents = documentInformation;
      const document = documents[0];
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View File Upload')}
          icon="data-file-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.file.added.title')}
          to={`/app/data-request/${entityGuid}`}
        >
          <Trans
            count={documentInformation.length}
            i18nKey={`activity.file.added.${by}${
              document.onBehalfOfUserGuid !== null &&
              document.onBehalfOfUserGuid !== activity.createdBy
                ? `-behalf-${you(document.onBehalfOfUserGuid)}`
                : ''
            }`}
          >
            <b />
            {{ createdByName }}
            {{ behalfOfName: document.onBehalfOfUserName }}
            {{
              folderName: document.folderName
            }}
          </Trans>{' '}
        </AbstractActivity>
      );

    case ActivityType.FileLinkedToProject:
      const documentInfo = documentInformation;
      const doc = documentInfo[0];
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.file.viewProjectFiles')}
          icon="data-file-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.file.added.title')}
          to={
            doc.folderGuid
              ? `/app/project/${engagementGuid}/files#folderGuid=${doc.folderGuid}`
              : `/app/project/${engagementGuid}/files`
          }
        >
          <Trans
            count={documentInformation.length}
            i18nKey={`activity.file.added.${by}${
              doc.onBehalfOfUserGuid !== null &&
              doc.onBehalfOfUserGuid !== activity.createdBy
                ? `-behalf-${you(doc.onBehalfOfUserGuid)}`
                : ''
            }`}
          >
            <b />
            {{ createdByName }}
            {{ behalfOfName: doc.onBehalfOfUserName }}
            {{ folderName: doc.folderName }}
          </Trans>{' '}
        </AbstractActivity>
      );

    /**
     * Files Deleted
     */
    case ActivityType.FilesDeleted:
      const deletedDoc = documentInformation[0];
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.file.viewProjectFiles')}
          icon="data-file-delete"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.file.deleted.title')}
          to={
            deletedDoc.folderGuid
              ? `/app/project/${engagementGuid}/files#folderGuid=${deletedDoc.folderGuid}`
              : `/app/project/${engagementGuid}/files`
          }
        >
          <Trans
            count={documentInformation.length}
            i18nKey={`activity.file.deleted.${by}`}
          >
            <b />
            {{ createdByName }}
          </Trans>{' '}
        </AbstractActivity>
      );

    /**
     * File Visibility Edited
     */
    case ActivityType.FileVisibilityModified: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.file.viewProjectFiles')}
          icon="data-file-visible"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.file.visibilityEdited.title')}
          to={`/app/project/${engagementGuid}/files`}
        >
          <Trans i18nKey={`activity.file.visibilityEdited.${by}`}>
            <b />
            {{
              fileName:
                documentInformation !== null && documentInformation.length > 0
                  ? documentInformation[0].documentName
                  : '?'
            }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Visibility',
                label: t('Visibility'),
                type: (value: number) => formatThirdPartyVisibility(t, value)
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Commented
     */
    case ActivityType.FileUploadComment:
    case ActivityType.FileUploadCommentModified: {
      const { title } = entitySummary as IDataRequestSummary;
      const editedComment =
        activity.activityTypeId === ActivityType.FileUploadCommentModified;

      return (
        <CommentActivity
          {...props}
          editedComment={editedComment}
          link={`/app/data-request/${entityGuid}/#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.file.${
                editedComment ? 'commentEdited' : 'commented'
              }.${by}${mentionedYou ? '-mentioned-you' : ''}`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    /**
     * Zip Ready
     */
    case ActivityType.ZipReady: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          icon="data-file-download"
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.file.zipReady.title')}
        >
          {t('activity.file.zipReady.content')}
        </AbstractActivity>
      );
    }

    /**
     * File Shared
     */

    case ActivityType.FileShared: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.file.viewProjectFiles')}
          icon="data-file-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.file.fileShared.title')}
          to={`/app/project/${engagementGuid}/files`}
        >
          <Trans i18nKey={`activity.file.fileShared.${by}`}>
            <b />
            {{
              fileName:
                documentInformation !== null && documentInformation.length > 0
                  ? documentInformation[0].documentName
                  : '?'
            }}
            {{ createdByName }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
