import Empty from 'components/Empty';
import InfoTooltip from 'components/InfoTooltip';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import Table from 'components/Table';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import { useAppReadOnly } from 'contexts/UserContext';
import { useStateMounted } from 'hooks';
import {
  IEngagement,
  IWorkspace,
  Status,
  WorkspaceGroup,
  WorkspaceStatus
} from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import { formatDate } from 'utils/formatDate';
import getSortParam from 'utils/getSortParm';
import isTableFilterActive from 'utils/isTableFilterActive';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import useTable, {
  TableHookInitialState,
  TableHookOnFetch
} from 'utils/useTable';

import classnames from 'classnames';
import ENV from 'env';
import { TFunction } from 'i18next';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  ErrorBoundary,
  Icon,
  Tooltip,
  makeTableCheckboxFilter,
  pushToast
} from '@ryan/components';

import ConfirmationModal from '../../../components/Modal/ConfirmationModal/ConfirmationModal';
import Workspace from './Workspace';
import WorkspaceButton from './WorkspaceButton';
import WorkspaceModal from './WorkspaceModal';
import useWorkspaceConnections from './useWorkspaceConnections';
import userWorkspaceTemplates from './useWorkspaceTemplates';

import './Scheduling.scss';

function getWorkspaceStatusLabel(t: TFunction, status: WorkspaceStatus) {
  switch (status) {
    case WorkspaceStatus.Pending:
      return t('Pending');
    case WorkspaceStatus.InProgress:
      return t(`status.${Status.InProgress}`);
    case WorkspaceStatus.Complete:
      return t(`status.${Status.Complete}`);
    case WorkspaceStatus.ErrorCreate:
      return t('Error');
    case WorkspaceStatus.ErrorClone:
      return t('Error');
    case WorkspaceStatus.ErrorUpdate:
      return t('Error');
    case WorkspaceStatus.ErrorDelete:
      return t('Error');
    case WorkspaceStatus.ErrorRestore:
      return t('Error');
  }
}

// How often do we want to refresh the workspaces?
const REFRESH_INTERVAL = 15000;

const tableHookInitialState: TableHookInitialState<IWorkspace> = {
  query: {
    filtered: {
      status: []
    },
    page: 1,
    pageSize: 10,
    searchQuery: '',
    sorted: {
      id: 'title',
      desc: false
    }
  }
};

interface ISchedulingProps {
  engagement: IEngagement;
}

export const Scheduling: FunctionComponent<ISchedulingProps> = ({
  engagement
}) => {
  const { t } = useTranslation();
  const isAppReadOnly = useAppReadOnly();

  const [openModal, setOpenModal] = useState<null | {
    workspace?: IWorkspace;
    duplicate: boolean;
  }>(null);

  const [deleteModal, setDeleteModal] = useState<null | {
    workspace: IWorkspace;
  }>(null);

  const [expandedRows, setExpandedRows] = useState<Record<string, unknown>>({});

  // maintains data polling interval
  const pollIntervalRef = useRef<number | null>(null);

  // tracks completion of initial data request
  const [hasLoaded, setHasLoaded] = useStateMounted(false);

  const templates = userWorkspaceTemplates(engagement.engagementGuid);
  const connections = useWorkspaceConnections();
  const sourceRef = useRef<CancelTokenSource>();
  const onTableFetch = useCallback<TableHookOnFetch<IWorkspace>>(
    query => {
      // refresh cancel token
      sourceRef.current?.cancel();
      sourceRef.current = ApiService.CancelToken.source();
      return ApiService.getEngagementWorkspaces(
        engagement.engagementGuid,
        {
          searchTerm: query.searchQuery,
          sort: getSortParam(query.sorted),
          status:
            query.filtered?.status == null
              ? null
              : query.filtered?.status.join(','),
          pageNumber: query.page,
          itemsPerPage: query.pageSize
        },
        sourceRef.current.token
      )
        .then(({ data }) => ({
          data: data.results,
          totalCount: data.totalResults
        }))
        .catch(error => {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }

          throw error;
        })
        .finally(() => {
          // toggle flag indicating initial load has completed
          setHasLoaded(true);
        });
    },
    [engagement.engagementGuid, setHasLoaded]
  );
  const [workspaces, fetchWorkspaces] = useTable<IWorkspace>(
    tableHookInitialState,
    onTableFetch
  );

  const isDismissError = (status: WorkspaceStatus) => {
    return (
      status === WorkspaceStatus.ErrorCreate ||
      status === WorkspaceStatus.ErrorClone
    );
  };

  const isRevertError = (status: WorkspaceStatus) => {
    return (
      status === WorkspaceStatus.ErrorUpdate ||
      status === WorkspaceStatus.ErrorDelete ||
      status === WorkspaceStatus.ErrorRestore
    );
  };

  const getFilterOptions = useCallback(() => {
    const ErrorTypes: Array<number> = [];

    const isError = (item: number) =>
      getWorkspaceStatusLabel(t, item) === 'Error';

    const initialFilterItems = Object.keys(WorkspaceStatus)
      .map(key => parseInt(key, 10))
      .filter(i => !isNaN(i));

    initialFilterItems.forEach(
      value => isError(value) && ErrorTypes.push(value)
    );

    const finalFilterItems = initialFilterItems
      .filter(value => !isError(value))
      .map(value => {
        if (isError(value)) {
          ErrorTypes.push(value);
        }
        return {
          value: value.toString(),
          label: getWorkspaceStatusLabel(t, value)
        };
      })
      .concat([
        {
          value: ErrorTypes.join(','),
          label: t('Error')
        }
      ]);

    return finalFilterItems;
  }, [t]);

  const onCompleteWorkspace = useCallback(
    (workspace: IWorkspace) => {
      return ApiService.completeWorkspace(
        workspace.engagementGuid,
        workspace.workspaceGuid
      ).then(() => {
        pushToast({
          type: 'success',
          title: t('scheduling.completeWorkspaceSuccessTitle'),
          content: t('scheduling.completeWorkspaceSuccessBody', {
            title: workspace.title
          })
        });
        fetchWorkspaces({});
      });
    },
    [fetchWorkspaces, t]
  );

  const onDismissWorkspace = useCallback(
    (workspace: IWorkspace) => {
      return ApiService.dismissWorkspace(
        workspace.engagementGuid,
        workspace.workspaceGuid
      )
        .then(() => {
          fetchWorkspaces({});
        })
        .catch(error => {
          pushServerErrorToast();
          throw error;
        });
    },
    [fetchWorkspaces]
  );

  const onReopenWorkspace = useCallback(
    (workspace: IWorkspace) => {
      return ApiService.reopenWorkspace(
        workspace.engagementGuid,
        workspace.workspaceGuid
      ).then(() => {
        pushToast({
          type: 'success',
          title: t('scheduling.reopenWorkspaceSuccessTitle'),
          content: t('scheduling.reopenWorkspaceSuccessBody', {
            title: workspace.title
          })
        });
        fetchWorkspaces({});
      });
    },
    [fetchWorkspaces, t]
  );

  const onRevertWorkspace = useCallback(
    (workspace: IWorkspace) => {
      return ApiService.revertWorkspace(
        workspace.engagementGuid,
        workspace.workspaceGuid
      )
        .then(() => {
          fetchWorkspaces({});
        })
        .catch(error => {
          pushServerErrorToast();
          throw error;
        });
    },
    [fetchWorkspaces]
  );

  const columns = useMemo(
    () => [
      {
        id: 'title',
        label: t('Title'),
        sortable: true,
        render: (workspace: IWorkspace) => {
          return (
            <div>
              <b>{workspace.title}</b>
              {isDismissError(workspace.status) && (
                <div className="scheduling__workspace-error scheduling__workspace-error-text">
                  <Icon name="close" /> {t('scheduling.workspaceCreateError')}
                </div>
              )}
              {isRevertError(workspace.status) && (
                <div className="scheduling__workspace-error scheduling__workspace-error-text">
                  <Icon name="close" /> {t('scheduling.workspaceUpdateError')}
                </div>
              )}
            </div>
          );
        }
      },
      {
        id: 'database',
        label: t('Database'),
        sortable: true,
        render: (workspace: IWorkspace) => (
          <div className="scheduling__workspace-connection">
            <div className="scheduling__workspace-connection-database">
              {workspace.database}
            </div>
            <div className="scheduling__workspace-connection-server">
              {workspace.server}
            </div>
          </div>
        )
      },
      {
        id: 'status',
        label: t('Status'),
        width: '8rem',
        render: (workspace: IWorkspace) => {
          const status = (() => {
            switch (workspace.status) {
              case WorkspaceStatus.Pending:
                return Status.Todo;
              case WorkspaceStatus.InProgress:
                return Status.InProgress;
              case WorkspaceStatus.Complete:
                return Status.Complete;
              case WorkspaceStatus.ErrorCreate:
                return Status.Todo;
              case WorkspaceStatus.ErrorClone:
                return Status.Todo;
              case WorkspaceStatus.ErrorUpdate:
                return Status.Todo;
              case WorkspaceStatus.ErrorDelete:
                return Status.Todo;
              case WorkspaceStatus.ErrorRestore:
                return Status.Todo;
            }
          })();

          const isError =
            workspace.status === WorkspaceStatus.ErrorCreate ||
            workspace.status === WorkspaceStatus.ErrorClone ||
            workspace.status === WorkspaceStatus.ErrorUpdate ||
            workspace.status === WorkspaceStatus.ErrorDelete ||
            workspace.status === WorkspaceStatus.ErrorRestore;

          return (
            <div
              className={classnames({
                'scheduling__workspace-error': isError,
                'scheduling__workspace-pending':
                  workspace.status === WorkspaceStatus.Pending
              })}
            >
              <StatusIcon
                label={getWorkspaceStatusLabel(t, workspace.status)}
                status={status}
              />
            </div>
          );
        },
        // filtering
        filter: makeTableCheckboxFilter(getFilterOptions()),
        filterActive: (value?: string[]) => !!value && value.length > 0
      },
      {
        id: 'updateDate',
        label: t('Last Modified'),
        sortable: true,
        render: (workspace: IWorkspace) => formatDate(workspace.updateDate)
      },
      {
        id: 'actions',
        label: '',
        width: '14rem',
        render: (workspace: IWorkspace) => (
          <WorkspaceButton
            connections={connections}
            isReadOnly={engagement.isReadOnly || isAppReadOnly}
            onCompleteWorkspace={() => onCompleteWorkspace(workspace)}
            onDeleteWorkspace={() => {
              setDeleteModal({ workspace });
            }}
            onDismissWorkspace={() => onDismissWorkspace(workspace)}
            onDuplicateWorkspace={() => onDuplicateWorkspace(workspace)}
            onEditWorkspace={() => onEditWorkspace(workspace)}
            onLaunchWorkspace={() => onLaunchWorkspace(workspace)}
            onReopenWorkspace={() => onReopenWorkspace(workspace)}
            onRevertWorkspace={() => onRevertWorkspace(workspace)}
            workspace={workspace}
          />
        )
      }
    ],
    [
      connections,
      engagement,
      isAppReadOnly,
      getFilterOptions,
      onCompleteWorkspace,
      onDismissWorkspace,
      onReopenWorkspace,
      onRevertWorkspace,
      t
    ]
  );

  const onCreateWorkspace = () => {
    setOpenModal({
      duplicate: false
    });
  };

  const onEditWorkspace = (workspace: IWorkspace) => {
    setOpenModal({
      workspace,
      duplicate: false
    });
  };

  const onDuplicateWorkspace = (workspace: IWorkspace) => {
    setOpenModal({
      workspace,
      duplicate: true
    });
  };

  const handleDeleteWorkspaceConfirm = async (workspace: IWorkspace) => {
    await ApiService.deleteWorkspace(
      workspace.engagementGuid,
      workspace.workspaceGuid
    );

    pushToast({
      content: t(
        'scheduling.deleteWorkspaceConfirmationModal.successToast.content',
        { workspaceTitle: workspace.title }
      ),
      title: t(
        'scheduling.deleteWorkspaceConfirmationModal.successToast.title'
      ),
      type: EMessageTypes.SUCCESS
    });

    fetchWorkspaces({});
  };

  const onLaunchWorkspace = (workspace: IWorkspace) => {
    window.open(
      `${ENV.TAS_BASE_URL}/query-designer?engagementId=${workspace.engagementId}&workspaceId=${workspace.workspaceId}`,
      '_blank'
    );
  };

  const onCloseWorkspaceModal = useCallback(
    (workspace?: IWorkspace) => {
      if (workspace) {
        fetchWorkspaces({});
      }
      setOpenModal(null);
    },
    [fetchWorkspaces]
  );

  // cancel ongoing requests
  useEffect(
    () => () => {
      sourceRef.current?.cancel();
    },
    []
  );

  // start polling for workspace data updates on completion of initial load
  useEffect(() => {
    if (hasLoaded && pollIntervalRef.current === null) {
      pollIntervalRef.current = window.setInterval(
        fetchWorkspaces,
        REFRESH_INTERVAL
      );
    }

    return () => {
      if (pollIntervalRef.current !== null) {
        window.clearTimeout(pollIntervalRef.current);
        pollIntervalRef.current = null;
      }
    };
  }, [hasLoaded, fetchWorkspaces]);

  return (
    <div className="scheduling">
      <ErrorBoundary>
        <Table<IWorkspace>
          columns={columns}
          data={workspaces.state.data}
          expanded={expandedRows}
          filtered={workspaces.state.query.filtered}
          groupBy="group"
          groupByClassName={(group: WorkspaceGroup) =>
            `scheduling__workspace-group-${group}`
          }
          // query
          loading={
            // only show loading skeleton on initial load via `hasLoaded`,
            // subsequent data updates will occur silently
            workspaces.state.loading && !hasLoaded
          }
          onFilterChange={workspaces.onFilterChange}
          onPageChange={workspaces.onPageChange}
          onSearchChange={workspaces.onSearchChange}
          onSortChange={workspaces.onSortChange}
          // handlers
          onToggleExpansion={(expanded, row, rowId) => {
            setExpandedRows({
              ...expandedRows,
              [rowId]: expanded
            });
            workspaces.onToggleExpansion(expanded, row, rowId);
          }}
          page={workspaces.state.query.page}
          pageSize={workspaces.state.query.pageSize}
          // onToggleExpansion={workspaces.onToggleExpansion}
          renderActionPlacement={1}
          renderActions={() => {
            const showTooltip = connections && connections.length < 1;
            const createButton = (
              <Button
                disabled={
                  engagement.isReadOnly ||
                  isAppReadOnly ||
                  (connections ? connections.length < 1 : undefined)
                }
                icon="plus"
                onClick={onCreateWorkspace}
                size={EButtonSizes.SMALL}
                style={{
                  pointerEvents:
                    connections && connections.length < 1 ? 'none' : 'inherit'
                }}
                text={t('scheduling.buttonCreateWorkspace')}
                variant={EButtonVariant.TEXT}
              />
            );
            const createWorkspaceButton = showTooltip ? (
              <Tooltip
                content={t('scheduling.databaseAccessTooltip')}
                placement="bottom"
                renderTarget={({ open, ...props }) => (
                  <span
                    aria-expanded={open}
                    aria-haspopup="true"
                    style={{ display: 'inline-block' }}
                    {...props}
                  >
                    {createButton}
                  </span>
                )}
              ></Tooltip>
            ) : (
              createButton
            );

            return <div>{createWorkspaceButton}</div>;
          }}
          // grouping
          renderEmpty={() => (
            <TableEmpty
              filterActive={isTableFilterActive(
                columns,
                workspaces.state.query.filtered
              )}
              searchQuery={workspaces.state.query.searchQuery}
            >
              <Empty icon="project">{t('scheduling.noWorkspaces')}</Empty>
            </TableEmpty>
          )}
          renderExpandedRow={(workspace: IWorkspace) => (
            <Workspace engagement={engagement} workspace={workspace} />
          )}
          renderGroupHeader={(group: WorkspaceGroup) => {
            switch (Number(group)) {
              case WorkspaceGroup.Outstanding:
                return <label>{t('Outstanding')}</label>;
              case WorkspaceGroup.Complete:
                return (
                  <>
                    <label>Complete</label>
                    <InfoTooltip>
                      {t('scheduling.completedWorkspacesDeletedTime')}
                    </InfoTooltip>
                  </>
                );
            }
          }}
          // expanded rows
          rowId="workspaceGuid"
          searchQuery={workspaces.state.query.searchQuery}
          // render top actions
          sorted={workspaces.state.query.sorted}
          title={t('Workspaces')}
          // render empty placeholder
          totalCount={workspaces.state.totalCount}
        />
      </ErrorBoundary>

      {openModal !== null && (
        <WorkspaceModal
          connections={connections}
          duplicate={openModal.duplicate}
          engagementGuid={engagement.engagementGuid}
          onClose={onCloseWorkspaceModal}
          templates={templates}
          workspace={openModal.workspace}
        />
      )}

      {deleteModal && (
        <ConfirmationModal
          confirmationMessage={t(
            'scheduling.deleteWorkspaceConfirmationModal.description'
          )}
          onClose={() => setDeleteModal(null)}
          onSubmit={() => handleDeleteWorkspaceConfirm(deleteModal.workspace)}
          title={t('scheduling.deleteWorkspaceConfirmationModal.title')}
        />
      )}
    </div>
  );
};

export default Scheduling;
