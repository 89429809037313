import { DirectoryItemLinkType, IDirectoryItemLink } from 'interfaces';

export default function getDirectoryItemLinkUrl(link: IDirectoryItemLink) {
  switch (link.linkType) {
    case DirectoryItemLinkType.DataRequestAttachment:
    case DirectoryItemLinkType.DataRequestUpload:
    case DirectoryItemLinkType.FileUpload:
      return `/app/data-request/${link.linkGuid}`;
    case DirectoryItemLinkType.LearningAttachment:
      return `/app/project/${link.engagementGuid}/learnings/${link.linkGuid}`;
    case DirectoryItemLinkType.SavingsAttachment:
      return `/app/project/${link.engagementGuid}/savings-history`;
    case DirectoryItemLinkType.TaskAttachment:
      return `/app/project/${link.engagementGuid}/tasks/${link.linkGuid}`;
    case DirectoryItemLinkType.ProjectLink:
      return `/app/project/${link.engagementGuid}/overview`;
  }
}
