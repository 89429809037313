import React, { FunctionComponent, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, Icon } from '@ryan/components';

import './FilesUploadModal.scss';

export interface CollapseProps extends WithTranslation {
  title: string;
  children: JSX.Element;
  collapsed: boolean;
  isComplete: boolean;
  onNext?: () => void;
}

const Collapse: FunctionComponent<CollapseProps> = ({
  t,
  title,
  children,
  isComplete,
  collapsed,
  onNext
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  const next = () => {
    !isComplete && !isCollapsed && onNext
      ? onNext()
      : setIsCollapsed(!isCollapsed);
  };

  const onChevronTapped = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    next();
  };

  return (
    <div>
      <div className={`collapsible-row ${isCollapsed ? '' : 'gray'}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Icon
            id="success"
            name="success-filled"
            style={{
              paddingRight: 10,
              margin: 0,
              height: 'auto',
              fill: isComplete ? 'green' : 'gray'
            }}
          />
          <h4>{title}</h4>
        </div>

        <button id="chevron" onClick={onChevronTapped}>
          {isCollapsed ? (
            <Icon name="chevron-down" />
          ) : (
            <Icon name="chevron-up" />
          )}
        </button>
      </div>

      <div
        className={`children ${isCollapsed ? 'collapsed' : 'expanded gray'}`}
      >
        {children}
        {onNext && (
          <div className="next-button">
            <Button onClick={next}>
              {t('filesUpload.modal.button.next.label')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(Collapse);
