import React from 'react';
import { ICustomViewType } from 'interfaces';

export enum CustomViewRole {
  Personal = 'PERSONAL',
  Executive = 'EXECUTIVE'
}

export interface ICustomViewContext {
  hasExecutiveAccess: boolean;

  role: CustomViewRole | null;
  setRole: React.Dispatch<ICustomViewContext['role']>;

  showWell: boolean;
  setShowWell: React.Dispatch<ICustomViewContext['showWell']>;

  viewType: ICustomViewType | null;
  setViewType: React.Dispatch<ICustomViewContext['viewType']>;
}

const CustomViewContext = React.createContext<ICustomViewContext>({
  hasExecutiveAccess: false,
  role: null,
  setRole() {},
  showWell: false,
  setShowWell() {},
  viewType: null,
  setViewType() {}
});

export default CustomViewContext;
