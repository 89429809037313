import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, EButtonVariant } from '@ryan/components';

import * as CommonActionsEnums from './CommonActions.enums';
import * as CommonActionsInterfaces from './CommonActions.interfaces';

const CommonActions = (props: CommonActionsInterfaces.TComponentProps) => {
  const ROOT_TO_TEXT = 'table.actions.common';
  const { t: getTextToDisplay } = useTranslation();

  const renderTextButton: CommonActionsInterfaces.IRenderTextButton = props => (
    <Button
      {...{ ...(props.icon && { icon: props.icon }) }}
      disabled={props.isDisabled}
      onClick={props.onClick}
      size={EButtonSizes.SMALL}
      text={getTextToDisplay(`${ROOT_TO_TEXT}.${props.textPath}`)}
      variant={EButtonVariant.TEXT}
    />
  );

  const actionsToRenderMap = {
    [CommonActionsEnums.EActionType.TEXT_BUTTON]: renderTextButton
  };

  const { actionType } = props;
  const renderActionType = actionsToRenderMap[actionType];

  const actionProps = props[actionType];

  return (
    <div className="common-actions">{renderActionType(actionProps as any)}</div>
  );
};

export default CommonActions;
