export enum IconEnums {
  APPROVE = 'approve',
  CLIPBOARD_CHECK = 'clipboard-check',
  COMMENT = 'comment',
  COMMENT_ALERT = 'comment-alert',
  FILE_REFRESH = 'file-refresh',
  LINK = 'link',
  PENCIL = 'pencil',
  PLUS = 'plus',
  TIME = 'time',
  TRASH = 'trash',
  USER_CIRCLE = 'user-circle',
  USER_REASSIGN = 'user-reassign'
}
