import TooltipList from 'components/TooltipList/TooltipList';
import { IFile } from 'interfaces';
import { formatBytes } from 'utils/formatBytes';
import { formatDate } from 'utils/formatDate';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

interface IFileTooltipProps {
  t: TFunction;
  file: IFile;
  isSingleProjectView?: boolean;
  show: boolean;
  style?: Record<string, unknown>;
}

export const FileTooltip: FunctionComponent<IFileTooltipProps> = props => {
  const { t, file, isSingleProjectView, show, style } = props;
  const isArchived = !!file.archiveDate && !!file.archivedByName;

  const archivedFileToolTipContent: { label: string; value: string }[] = [];

  if (isArchived) {
    archivedFileToolTipContent.push({
      label: t('Archived'),
      value: formatDate(file.archiveDate!)
    });

    archivedFileToolTipContent.push({
      label: t('Archived By'),
      value: file.archivedByName!
    });
  }

  const fileDetailsContent = [
    {
      label: t('file.fileType'),
      value: file.fileType
    },
    {
      label: t('file.fileSize'),
      value: formatBytes(file.size)
    }
  ];

  return show ? (
    <TooltipList
      ariaLabel={t('file.fileInformation')}
      list={[
        ...(isArchived
          ? isSingleProjectView
            ? fileDetailsContent.concat(archivedFileToolTipContent)
            : archivedFileToolTipContent
          : fileDetailsContent)
      ]}
      style={style}
    />
  ) : (
    <></>
  );
};

export default FileTooltip;
