import DocumentTitle from 'components/DocumentTitle';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import UserDetails from 'components/UserDetails/UserDetails';
import { WithUser, withUser } from 'contexts/UserContext';
import { ITableState, IUser, IUserSearchParams } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import { formatDate } from 'utils/formatDate';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ITableColumn, Icon, makeTableCheckboxFilter } from '@ryan/components';

import UserNameAndProfile from '../../components/Table/UserNameAndProfile/UserNameAndProfile';
import { formatToUserNameAndProfile } from '../../components/Table/UserNameAndProfile/utils';
import { mapUserToUserDetailsUserData } from '../../components/UserDetails/utils';

import './ManageTeamSearch.scss';

interface IManageTeamSearchProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps<{ userType?: string }> {
  // ...
}

interface IManageTeamSearchState extends ITableState {
  users: IUser[] | null;
}

class ManageTeamSearch extends Component<
  IManageTeamSearchProps,
  IManageTeamSearchState
> {
  private source?: CancelTokenSource;

  private columns: ITableColumn<IUser>[];

  constructor(props: IManageTeamSearchProps) {
    super(props);
    const { t } = props;

    this.state = {
      loading: false,
      users: null,
      searchQuery: '',
      sorted: {
        id: 'FirstName',
        desc: false
      },
      filtered: { activeStatus: [] },
      expanded: {},
      page: 1,
      pageSize: 10,
      totalCount: 0
    };

    this.columns = [
      {
        id: 'firstName',
        label: t('manageTeam.columns.nameAndEmail'),
        render: (row: IUser) => (
          <UserNameAndProfile
            isLinkable
            user={formatToUserNameAndProfile({
              additionalInfoKey: 'email',
              user: row
            })}
          />
        ),
        sortable: true
      },
      {
        id: 'title',
        label: t('manageTeam.columns.title'),
        render: 'title'
      },
      {
        id: 'company',
        label: t('manageTeam.columns.company'),
        render: 'company',
        sortable: true
      },
      {
        id: 'roleName',
        label: t('Role'),
        render: 'roleName'
      },
      {
        id: 'activeStatus',
        label: t('manageTeam.columns.status'),
        render: (row: IUser) =>
          row.activeStatus === 1 ? t('Active') : t('Inactive'),
        filter: makeTableCheckboxFilter([
          { value: '1', label: t('Active') },
          { value: '0', label: t('Inactive') }
        ]),
        filterActive: (value: string[]) => value.length > 0
      },
      {
        id: 'loginDate',
        label: t('manageTeam.columns.lastActive'),
        sortable: true,
        render: (row: IUser) =>
          row.loginDate ? formatDate(row.loginDate, true) : row.loginDate
      },
      {
        id: 'edit',
        label: t('manageTeam.columns.edit'),
        align: 'center',
        render: (row: IUser) =>
          row.memberGuid && (
            <div data-qid="edit-user">
              <Link
                className="manage-team-users__edit-user"
                to={`/app/team/edit-user/${row.memberGuid}`}
              >
                <Icon name="pencil" />
              </Link>
            </div>
          )
      }
    ];
  }

  componentWillUnmount() {
    this.source?.cancel();
  }

  fetchUsers(updates: Partial<IManageTeamSearchState> = {}) {
    this.setState(
      {
        ...(updates as IManageTeamSearchState),
        loading: true
      },
      async () => {
        const { filtered, page, pageSize, searchQuery, sorted } = this.state;
        const params: IUserSearchParams = {
          pageNumber: page,
          searchTerm: searchQuery,
          activeStatus:
            filtered!.activeStatus.length === 1
              ? filtered!.activeStatus[0]
              : '',
          sort: getSortParam(sorted),
          itemsPerPage: pageSize
        };

        // refresh cancel token
        this.source?.cancel();
        this.source = ApiService.CancelToken.source();

        try {
          const response = await ApiService.getUsers(params, this.source.token);
          this.setState({
            loading: false,
            totalCount: response.data.totalResults,
            users: response.data.results
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
            this.setState({
              loading: false
            });
          }
        }
      }
    );
  }

  handleViewUser = (user: IUser) => {
    const { history } = this.props;
    history.push(`/app/personal-information/${user.memberGuid}`, {
      pushFrom: [
        {
          route: `/app/team/`,
          title: 'Team'
        }
      ]
    });
  };

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      if (searchQuery) {
        this.fetchUsers({ page: 1, searchQuery });
      } else {
        this.setState({ users: null });
      }
    }
  );

  handlePage = (page: number, pageSize: number) => {
    this.fetchUsers({ page, pageSize });
  };

  handleSort = (sorted: any) => {
    this.fetchUsers({ sorted });
  };

  handleFilter = (filtered: Record<string, unknown>) => {
    this.fetchUsers({ filtered, page: 1 });
  };

  handleToggleExpansion = (isExpanded: boolean, row: IUser, rowId: string) => {
    this.setState(state => ({
      expanded: { ...state.expanded, [rowId]: isExpanded }
    }));
  };

  render() {
    const { t } = this.props;
    const {
      loading,
      users,
      searchQuery,
      sorted,
      filtered,
      expanded,
      page,
      pageSize,
      totalCount
    } = this.state;

    return (
      <div className="manage-team-search">
        <DocumentTitle title={t('manageTeam.searchAllTitle')} />
        <h4 className="ry-h4">{t('All Accounts')}</h4>
        <h1 className="ry-h1">{t('manageTeam.searchAllTitle')}</h1>
        <hr />
        <div className="manage-team-search__header">
          <div className="manage-team-search__header-headings" />
          <SearchInput
            loading={loading}
            onChange={this.handleSearch}
            value={searchQuery}
          />
        </div>
        {users === null && !loading ? (
          <>
            <hr />
            <div className="table-empty">
              <Icon className="table-empty__icon" name="users" />
              <div className="table-empty__content">
                {t('manageTeam.searchAllDescription')}
              </div>
            </div>
          </>
        ) : (
          <Table<IUser, string>
            columns={this.columns}
            data={users || []}
            expanded={expanded}
            filtered={filtered}
            loading={loading}
            onFilterChange={this.handleFilter}
            onPageChange={this.handlePage}
            onSortChange={this.handleSort}
            onToggleExpansion={this.handleToggleExpansion}
            page={page}
            pageSize={pageSize}
            renderEmpty={this.renderEmpty}
            renderExpandedRow={this.renderExpandedRow}
            rowId="memberGuid"
            sorted={sorted}
            totalCount={totalCount}
          />
        )}
      </div>
    );
  }

  renderEmpty = () => {
    const { t } = this.props;
    const { searchQuery } = this.state;
    return (
      <div className="table-empty">
        <Icon className="table-empty__icon" name="users" />
        {searchQuery ? (
          <div>
            <Trans i18nKey="Search Not Found">
              <b>{{ searchQuery }}</b> could not be found.
            </Trans>
            <br />
            {t('manageTeam.adjustSearch')}
          </div>
        ) : (
          <div>{t('manageTeam.searchAllEmpty', { userType: 'Ryan' })}</div>
        )}
      </div>
    );
  };

  renderExpandedRow = (user: IUser) => (
    <UserDetails
      onViewAll={() => this.handleViewUser(user)}
      userData={mapUserToUserDetailsUserData(user)}
    />
  );
}

export default withTranslation()(withUser(ManageTeamSearch));
