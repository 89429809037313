export enum ChunkStatus {
  Ready,
  Uploading,
  Success,
  Error
}

type ChunkData = {
  blob: Blob;
  bytesLoaded: number;
  bytesTotal: number;
  index: number;
  status: ChunkStatus;
  tries: number;
  onSuccess?: () => void;
};

export class Chunk {
  private _statusShadow = ChunkStatus.Ready;
  readonly blob: Blob;
  public bytesLoaded = 0;
  public bytesTotal = 0;
  readonly index: number;
  public tries = 0;
  private onSuccess?: ChunkData['onSuccess'];

  constructor({
    blob,
    bytesLoaded,
    bytesTotal,
    index,
    status,
    tries,
    onSuccess
  }: ChunkData) {
    this.blob = blob;
    this.bytesLoaded = bytesLoaded;
    this.bytesTotal = bytesTotal;
    this.index = index;
    this.status = status;
    this.tries = tries;
    this.onSuccess = onSuccess;
  }

  get status(): ChunkStatus {
    return this._statusShadow;
  }

  set status(newStatus: ChunkStatus) {
    // prevent update of status once it has moved to success state
    if (this._statusShadow !== ChunkStatus.Success) {
      this._statusShadow = newStatus;

      // call success callback if status is set to success
      if (newStatus === ChunkStatus.Success) {
        this.onSuccess?.();
      }
    }
  }
}

export default class Chunks extends Array<Chunk> {
  private chunksCompleted = 0;

  private chunksTotal = 0;

  constructor(blob: Blob, chunkSize: number) {
    super();

    this.chunksTotal = Math.ceil(blob.size / chunkSize);

    for (let i = 0; i < this.chunksTotal; i++) {
      const start = chunkSize * i;
      const end = Math.min(start + chunkSize, blob.size);
      const chunkBlob = blob.slice(start, end, blob.type);
      this.push(
        new Chunk({
          blob: chunkBlob,
          index: i + 1,
          status: ChunkStatus.Ready,
          tries: 0,

          // This value will be updated once the chunk is compressed and rest of
          // formdata is included.
          // NOTE: these fields will also be innacurate while a compressed
          // chunk is uploading as the bytesTotal value will reflect the
          // original uncompressed chunk size until the upload is complete
          bytesTotal: chunkBlob.size,
          bytesLoaded: 0,

          // increment completed chunks counter on success
          onSuccess: () => {
            this.chunksCompleted += 1;
          }
        })
      );
    }
  }

  /**
   * The number of chunks actively uploading.
   */
  get activeCount(): number {
    return this.reduce(
      (count, chunk) =>
        count + (chunk.status === ChunkStatus.Uploading ? 1 : 0),
      0
    );
  }

  /**
   * `true` if all chunks have been successfully uploaded.
   */
  get complete(): boolean {
    // adding extra validation step with `.every()` just in case...
    // should only be executed if first check passes
    return (
      this.chunksCompleted === this.chunksTotal &&
      this.every(c => c.status === ChunkStatus.Success)
    );
  }

  /**
   * The next chunk ready to be uploaded.
   */
  get next(): Chunk | undefined {
    return this.find(chunk => chunk.status === ChunkStatus.Ready);
  }

  /**
   * THe current progress of all chunks.
   */
  get progress(): {
    chunksCompleted: number;
    chunksTotal: number;
    percent: number;
  } {
    // calculate completion percentage via # of completed chunk uploads as
    // measuring against bytes uploaded is innacurate for compressed chunk
    // uploads
    const chunksCompleted = this.chunksCompleted;
    const chunksTotal = this.chunksTotal;
    return {
      chunksCompleted,
      chunksTotal,
      percent: Math.floor((chunksCompleted / chunksTotal) * 100)
    };
  }
}
