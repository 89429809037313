import addHours from 'date-fns/addHours';
import startOfDay from 'date-fns/startOfDay';

/**
 * Aligns date value of midnight Date object regardless of timezone. This is
 * only used on date values that have a midnight timestamp applied to them and
 * the time is irrelevant.
 *
 * ex. `2021-06-14T00:00:00-04:00` (Eastern midnight time) should still display
 *  as `06/14/2021` in Central and Pacific timezones.
 *
 * @todo This is a temporary solution to handle NUR related issues where an
 * activation date set for midnight Eastern would translate to the day before
 * in other timezones. This will be updated in the future to a global
 * standardized activation time.
 */
export default function standardizeUSDate(date: Date | string): Date {
  return startOfDay(
    addHours(typeof date === 'string' ? new Date(date) : date, 3)
  );
}
