import { useCallback, useRef, useState } from 'react';

import useEvent from '../useEvent';
import { DSSManager, IDSSManagerOptions } from './DSSManager';

export function useDSSManager(options: IDSSManagerOptions = {}) {
  const dssManager = useRef(new DSSManager(options));

  // Force re-render when DSS updates! This is hacky!
  const [, updateState] = useState<unknown>();
  const forceUpdate = useCallback(() => updateState({}), []);
  useEvent(dssManager.current, 'change', forceUpdate);

  return dssManager.current;
}
