import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dropdown,
  EButtonVariant,
  EMessageTypes,
  pushToast
} from '@ryan/components';

import ApiService from '../../../services/ApiService';
import { formikFieldProps } from '../../../utils/forms';
import { handleDefaultApiError } from '../../../utils/handleApiError/handleApiError';
import InfoTooltip from '../../InfoTooltip';
import Modal from '../Modal';
import { IEditCurrencyModalProps, IHandleSubmit, TFormikValues } from './utils';

import './EditCurrencyModal.scss';

const EditCurrencyModal: React.FC<IEditCurrencyModalProps> = ({
  engagementGuid,
  isoCurrencyCode,
  isoCurrencyCodeOptions,
  onClose,
  onSubmit,
  projectName
}) => {
  const { t: getTextToDisplay } = useTranslation();

  const [isSubmit, setIsSubmit] = useState(false);

  const ROOT_TO_TEXT = 'modal.editCurrencyModal';

  const handleSubmit: IHandleSubmit = async ({
    engagementGuidToUpdate,
    isoCurrencyCodeOption,
    onCloseCallback,
    onSubmitCallback,
    pushToastCallback
  }) => {
    if (!engagementGuidToUpdate) {
      onSubmitCallback(isoCurrencyCodeOption.isoAlphaCode);
      return;
    }

    try {
      const { status } = await ApiService.updateProjectCurrency({
        currencyGuid: isoCurrencyCodeOption.currencyGuid,
        engagementGuid: engagementGuidToUpdate
      });

      if (status === 200) {
        pushToastCallback();
        onSubmitCallback(isoCurrencyCodeOption.isoAlphaCode);
      } else {
        throw new Error();
      }
    } catch (error) {
      handleDefaultApiError(error);
      onCloseCallback();
    }
  };

  return (
    <Modal
      className="edit-currency-modal"
      onClose={onClose}
      open
      title={getTextToDisplay(`${ROOT_TO_TEXT}.title`)}
    >
      <Formik<TFormikValues>
        initialValues={{ isoCurrencyCode }}
        onSubmit={formikValues => {
          setIsSubmit(true);
          handleSubmit({
            engagementGuidToUpdate: engagementGuid,
            isoCurrencyCodeOption: isoCurrencyCodeOptions.find(
              option => option.isoAlphaCode === formikValues.isoCurrencyCode
            )!,
            onCloseCallback: onClose,
            onSubmitCallback: onSubmit,
            pushToastCallback: () => {
              pushToast({
                title: getTextToDisplay(`${ROOT_TO_TEXT}.successToastTitle`, {
                  projectName
                }),
                type: EMessageTypes.SUCCESS
              });
            }
          });
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Dropdown
              {...formikFieldProps('isoCurrencyCode', formik)}
              disabled={isSubmit}
              // TODO: Update components library to allow JSX if this pattern continues
              label={
                (
                  <>
                    {getTextToDisplay(`${ROOT_TO_TEXT}.projectCurrency`)}
                    <InfoTooltip>
                      {getTextToDisplay(
                        `${ROOT_TO_TEXT}.projectCurrencyToolTip`
                      )}
                    </InfoTooltip>
                  </>
                ) as any
              }
              options={[
                ...(isoCurrencyCode
                  ? []
                  : [
                      { label: `- ${getTextToDisplay('Select')} -`, value: '' }
                    ]),
                ...isoCurrencyCodeOptions.map(option => ({
                  label: `${option.isoAlphaCode} - ${option.ryanCurrencyName}`,
                  value: option.isoAlphaCode
                }))
              ]}
            />

            <ButtonGroup>
              <Button
                disabled={isSubmit}
                onClick={onClose}
                text={getTextToDisplay(`${ROOT_TO_TEXT}.cancel`)}
              />
              <Button
                disabled={!formik.dirty || isSubmit}
                text={getTextToDisplay(`${ROOT_TO_TEXT}.save`)}
                type="submit"
                variant={EButtonVariant.PRIMARY}
              />
            </ButtonGroup>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditCurrencyModal;
