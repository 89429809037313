import { IAmplitudeContext } from './AmplitudeContext.interfaces';

import { useContext } from 'react';

import { withContext } from '../../utils/withContext';
import AmplitudeContext from './AmplitudeContext';

export const AmplitudeConsumer = AmplitudeContext.Consumer;

export type WithAmplitude = IAmplitudeContext;
export const withAmplitude = withContext(AmplitudeContext, 'withAmplitude');

export const useAmplitude = () => {
  const context = useContext(AmplitudeContext);

  if (!context) {
    throw new Error('useAmplitude must be used within an AmplitudeProvider');
  }

  return context;
};
