/**
 * Scrolls to the top of the target element.
 *
 * @param element An Element or selector of an element to scroll to.
 * @param buffer An amount of padding above the target element to be visible
 *  after scroll.
 */
export default function scrollTo(element: Element | string, buffer = 0) {
  setTimeout(() => {
    const el =
      typeof element === 'string' ? document.querySelector(element) : element;

    if (el) {
      // get top of element relative to the document
      const elTop =
        el.getBoundingClientRect().top + document.documentElement.scrollTop;

      // accomodate header nav
      const appHeader = document.querySelector('.app-header');
      const appHeaderHeight = appHeader
        ? appHeader.getBoundingClientRect().height
        : 0;

      window.scrollTo({
        top: elTop - appHeaderHeight - buffer
      });
    }
  }, 150);
}
