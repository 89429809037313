import React, { FunctionComponent } from 'react';

import { Mentions } from '@ryan/components';

export interface ICommentTaggedUser {
  fullName: string;
  userGuid: string;
}

export interface ICommentTextProps {
  text: string;
  taggedUsers: ICommentTaggedUser[];
}

const CommentText: FunctionComponent<ICommentTextProps> = ({
  text,
  taggedUsers
}) => (
  <Mentions
    value={{
      text,
      users:
        taggedUsers &&
        taggedUsers.map(user => ({
          id: user.userGuid,
          display: user.fullName
        }))
    }}
  />
);

export default CommentText;
