import {
  IAccount,
  ICountry,
  ICustomViewFilter,
  ICustomViewFilterCreate,
  IJurisdiction,
  IPracticeArea,
  IUserSummary
} from 'interfaces';

export const isAccount = (
  value: ICustomViewFilter['value']
): value is IAccount =>
  !!(value as IAccount).accountGuid &&
  (value as IAccount).hasOwnProperty('clientPrincipalGuid') &&
  (value as IAccount).hasOwnProperty('clientManagerGuid');

export const isCountry = (
  value: ICustomViewFilter['value']
): value is ICountry =>
  !!(value as ICountry).countryGuid && !!(value as ICountry).ryanCountryName;

export const isJurisdiction = (
  value: ICustomViewFilter['value']
): value is IJurisdiction => !!(value as IJurisdiction).jurisdictionGuid;

export const isPractice = (
  value: ICustomViewFilter['value']
): value is IPracticeArea =>
  !!(value as IPracticeArea).practiceAreaGuid &&
  !!(value as IPracticeArea).practiceArea;

export const isUser = (
  value: ICustomViewFilter['value']
): value is IUserSummary =>
  !!(value as IUserSummary).userGuid && !!(value as IUserSummary).firstName;

/**
 * Checks if a filter is fully defined by validating that each field has been
 * populated. Ignore falsey values, do undefined checks for enum fields to
 * support 0 values.
 */
export const isDefinedFilter = (filter: ICustomViewFilter | null): boolean =>
  !!filter &&
  (!!filter.value || typeof filter.value === 'number') &&
  typeof filter.operator !== 'undefined' &&
  typeof filter.type !== 'undefined';

/**
 * Transforms complex filter value to string/number for create endpoint.
 */
export const transformFilterValue = (
  filter: ICustomViewFilter
): ICustomViewFilterCreate => {
  const { value: baseValue } = filter;
  let value: ICustomViewFilterCreate['value'] = '';

  if (isAccount(baseValue)) {
    value = baseValue.accountGuid;
  } else if (isCountry(baseValue)) {
    value = baseValue.countryGuid;
  } else if (isJurisdiction(baseValue)) {
    value = baseValue.jurisdictionGuid;
  } else if (isPractice(baseValue)) {
    value = baseValue.practiceAreaGuid;
  } else if (isUser(baseValue)) {
    value = baseValue.userGuid;
  }

  return value
    ? {
        ...filter,
        value
      }
    : (filter as ICustomViewFilterCreate);
};
