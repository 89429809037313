import { TFunction } from 'i18next';
import {
  IAudienceUser,
  IHistoricalSavingsSummary,
  IReportEngagement
} from 'interfaces';
import { yup } from 'utils/forms';

export interface IReportBuilderFormValues {
  coverPageExecutiveSummary: string;
  coverPageIncludeLogo: boolean;
  coverPageLogoSelection: string;

  timeframeType: string;
  timeframeStartDate: Date | null;
  timeframeEndDate: Date | null;

  audienceType: string;
  audienceOnBehalfOf: IAudienceUser | null;

  selectedPublishedEngagements: IReportEngagement[];

  contentProjectOverview: boolean;
  contentTasks: boolean;
  contentDataRequests: boolean;
  contentLearnings: boolean;
  contentSavingsSummary: boolean;
  contentSavingsCustomDate: boolean;
  contentSavingsType: string;
  contentSavingsStartDate: Date | null;
  contentSavingsEndDate: Date | null;

  manageHistoricalSavingsEngagements: boolean;
  selectedHistoricalSavingsEngagements: IHistoricalSavingsSummary[];
}

function dateRangeValidation(
  t: TFunction,
  key: string,
  value: any
): boolean | yup.ValidationError {
  const typeId = `${key}Type`;
  const startDate = `${key}StartDate`;
  const endDate = `${key}EndDate`;

  const isDateRangeType = value[typeId] === '2';

  if (isDateRangeType && value[startDate] === null) {
    return new yup.ValidationError(
      t('reportBuilder.timeframe.startDate.required'),
      null,
      startDate
    );
  }

  if (value[endDate] === null) {
    return new yup.ValidationError(
      isDateRangeType
        ? t('reportBuilder.timeframe.endDate.required')
        : t('reportBuilder.timeframe.endDate.asOfRequired'),
      null,
      endDate
    );
  }

  if (
    isDateRangeType &&
    value[startDate] &&
    value[endDate] &&
    value[startDate] >= value[endDate]
  ) {
    return new yup.ValidationError(
      t('reportBuilder.timeframe.invalidDateRangeText'),
      null,
      endDate
    );
  }

  return false;
}

export function getSchema(t: TFunction) {
  return yup
    .object<IReportBuilderFormValues>({
      coverPageExecutiveSummary: yup.string().max(
        250,
        t('reportBuilder.coverPage.executiveSummary.maxLength', {
          count: 250
        })
      ),
      coverPageIncludeLogo: yup.boolean(),
      coverPageLogoSelection: yup.string(),

      timeframeType: yup.string(),
      timeframeStartDate: yup.date().nullable(),
      timeframeEndDate: yup.date().nullable(),

      audienceType: yup.string(),
      audienceOnBehalfOf: yup.object<IAudienceUser>().nullable(),

      selectedPublishedEngagements: yup.array<IReportEngagement>(),

      contentProjectOverview: yup.boolean(),
      contentTasks: yup.boolean(),
      contentDataRequests: yup.boolean(),
      contentLearnings: yup.boolean(),
      contentSavingsSummary: yup.boolean(),
      contentSavingsCustomDate: yup.boolean(),
      contentSavingsType: yup.string(),
      contentSavingsStartDate: yup.date().nullable(),
      contentSavingsEndDate: yup.date().nullable(),

      manageHistoricalSavingsEngagements: yup.boolean(),
      selectedHistoricalSavingsEngagements:
        yup.array<IHistoricalSavingsSummary>()
    })
    .test('timeframeSection', '', value =>
      dateRangeValidation(t, 'timeframe', value)
    )
    .test('audienceSection', '', value => {
      if (value.audienceType === '2' && value.audienceOnBehalfOf === null) {
        return new yup.ValidationError(
          t('reportBuilder.audience.invalidOnBehalfOf'),
          null,
          'audienceOnBehalfOf'
        );
      }

      return false;
    })
    .test('contentSection', '', value =>
      !value.contentSavingsCustomDate
        ? false
        : dateRangeValidation(t, 'contentSavings', value)
    )
    .test('projectSection', '', value => {
      if (
        value.selectedPublishedEngagements.length === 0 &&
        value.selectedHistoricalSavingsEngagements.length === 0
      ) {
        return new yup.ValidationError(
          t('reportBuilder.projects.minLength'),
          null,
          'selectedPublishedEngagements'
        );
      }

      return false;
    });
}
