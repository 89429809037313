import { EntityType } from 'interfaces';
import { FilterType } from './FilterType';

const FILTER_TYPES_ACCOUNT = [
  FilterType.All,
  FilterType.AssignedToMe,
  FilterType.Projects,
  FilterType.Milestones,
  FilterType.DataRequests,
  FilterType.Files,
  FilterType.Contracts,
  FilterType.Invoices,
  FilterType.SavingsSummary,
  FilterType.Tasks,
  FilterType.Learnings,
  FilterType.Team,
  FilterType.AccessControl,
  FilterType.Edits,
  FilterType.Comments,
  FilterType.Deleted
];

const FILTER_TYPES_DATA_AND_FILES = [
  FilterType.All,
  FilterType.AssignedToMe,
  FilterType.DataRequests,
  FilterType.Files,
  FilterType.Contracts,
  FilterType.Invoices,
  FilterType.Edits,
  FilterType.Comments,
  FilterType.Deleted
];

const FILTER_TYPES_ENGAGEMENT = [
  FilterType.All,
  FilterType.AssignedToMe,
  FilterType.Projects,
  FilterType.DataRequests,
  FilterType.Milestones,
  FilterType.Files,
  FilterType.Contracts,
  FilterType.Invoices,
  FilterType.SavingsSummary,
  FilterType.Tasks,
  FilterType.Learnings,
  FilterType.Team,
  FilterType.Edits,
  FilterType.Comments,
  FilterType.Deleted
];

const FILTER_TYPES_MAP_ENTITY_TYPES: { [key: string]: EntityType[] | true } = {
  [FilterType.All]: true,
  [FilterType.AssignedToMe]: [EntityType.DataRequest, EntityType.Task],
  [FilterType.Projects]: [EntityType.Project],
  [FilterType.DataRequests]: [EntityType.DataRequest],
  [FilterType.Milestones]: [EntityType.Milestone],
  [FilterType.SavingsSummary]: [EntityType.SavingsSummary],
  [FilterType.Tasks]: [EntityType.Task],
  [FilterType.Files]: [EntityType.File],
  [FilterType.Contracts]: [EntityType.Contract],
  [FilterType.Invoices]: [EntityType.Invoice],
  [FilterType.Learnings]: [EntityType.Learning],
  [FilterType.Team]: [EntityType.Team],
  [FilterType.AccessControl]: [EntityType.AccessControl],
  [FilterType.Edits]: true,
  [FilterType.Comments]: [
    EntityType.Milestone,
    EntityType.Learning,
    EntityType.DataRequest,
    EntityType.Task
  ],
  [FilterType.Deleted]: true
};

export default function getFilterTypes(
  entityTypes: EntityType[],
  forEngagements: boolean,
  forDataAndFiles: boolean
) {
  // Start with the filterTypes applicable to the context (page).
  let filterTypes = FILTER_TYPES_ACCOUNT;
  if (forEngagements) {
    filterTypes = FILTER_TYPES_ENGAGEMENT;
  } else if (forDataAndFiles) {
    filterTypes = FILTER_TYPES_DATA_AND_FILES;
  }

  // For each of those filterTypes, only return those applicable to the passed entityTypes.
  return filterTypes.filter(filterType => {
    // This filterType is either always applicable (true) or only applicable to these entityTypes.
    const mixed = FILTER_TYPES_MAP_ENTITY_TYPES[filterType];
    return mixed === true || mixed.some(et => entityTypes.includes(et));
  });
}
