import React, { FunctionComponent, ReactNode } from 'react';

import './NotificationFeed.scss';

interface INotificationFeedSkeletonProps {
  count?: number;
}

export const NotificationFeedSkeleton: FunctionComponent<
  INotificationFeedSkeletonProps
> = props => {
  const { count } = props;

  const notifications: ReactNode[] = [];
  for (let i = 0; i < count!; i++) {
    notifications.push(
      <tr key={i}>
        <td className="notification-feed__activity">
          <div className="activity">
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <table className="notification-feed notification-feed--skeleton">
      <tbody>{notifications}</tbody>
    </table>
  );
};

NotificationFeedSkeleton.defaultProps = {
  count: 3
};

export default NotificationFeedSkeleton;
