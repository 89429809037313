import { User, UserManager } from 'oidc-client';
import { EXECUTIVE_VIEW_STORAGE_KEY } from 'services/ApiService/storageKeys';
import history from 'services/history';
import { updateGlobalLoggingContext } from 'services/logger';
import { eraseCookie, setCookie } from 'utils/cookies';

import ENV from 'env';
import { makeUserManager } from 'react-oidc';

import { getOidcConfiguration } from './OidcConfiguration';

// Singleton
let userManager: UserManager | null = null;

/**
 * Returns `true` if the active user session is set to expire within the
 * provided timeframe (in seconds).
 */
export async function expiresWithin(seconds: number): Promise<boolean> {
  const user = await getUserManager().getUser();
  return !!user && user.expires_in <= seconds;
}

/**
 * Returns OIDC user manager module.
 */
export function getUserManager() {
  if (!userManager) {
    userManager = makeUserManager(getOidcConfiguration());

    if (ENV.IS_MAINTENANCE_PAGE_ENABLED === 'true') {
      // HACK: Attempt to call the identity url to handle auth identity url errors
      fetch(
        `${ENV.IDENTITY_AUTHORITY}/.well-known/openid-configuration?validate`
      )
        .then(response => {
          if (!response.ok) {
            throw new Error('Error connecting to auth identity');
          }
        })
        .catch(() => {
          history.push('/public/maintenance');
        });
    }
  }

  return userManager;
}

/**
 * Logout the user/clear user session on session expiration.
 */
export function handleUserExpired() {
  history.push('/public/logout');
}

/**
 * User update callback. Updates authentication cookie for endpoint calls and
 * applies user to logging context.
 */
export function handleUserUpdate(user?: User | null) {
  if (user) {
    setCookie('Auth', user.access_token);

    // update logger with user info
    const {
      dxp_UserGuid: userGuid = '',
      dxp_ImpersonatingUserGuid: impersonatingUserGuid = ''
    } = user.profile;
    updateGlobalLoggingContext(userGuid, impersonatingUserGuid);
  } else {
    window.sessionStorage.removeItem(EXECUTIVE_VIEW_STORAGE_KEY);
    eraseCookie('Auth');
  }
}

/**
 * Manually updates the user manager singleton. ONLY USED FOR UNIT TESTING!
 *
 * @ignore
 */
export function mockUserManager(mock: UserManager) {
  userManager = mock;
}

/**
 * Resets the user manager singleton. ONLY USED FOR UNIT TESTING!
 *
 * @ignore
 */
export function resetUserManager() {
  userManager = null;
}
