import { CancelToken } from 'axios';
import { ITermsOfUse } from 'interfaces';
import ApiBase from './ApiBase';

export async function getTermsOfUse(
  this: ApiBase,
  termsOfUseType: number,
  cancelToken?: CancelToken
) {
  return this.server.get<ITermsOfUse>(`/api/user/terms/${termsOfUseType}`, {
    cancelToken
  });
}

export async function acceptTermsOfUse(
  this: ApiBase,
  version: number,
  cancelToken?: CancelToken
) {
  return this.server.post(`/api/user/terms/accept/${version}`, { cancelToken });
}
