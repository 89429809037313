import { DSSManager } from 'utils/DSS';
import useEvent from 'utils/useEvent';

import React, { FunctionComponent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Message } from '@ryan/components';

function isDssInProgress(dss: DSSManager) {
  return (
    dss.getUploadingPromise() !== null || dss.getUploadedDocuments().length > 0
  );
}

const UploadModalWarning: FunctionComponent<{
  dss: DSSManager;
  children: (props: {
    dss: DSSManager;
    warning: ReactNode;
    onEscape: () => void;
    onCancel: () => void;
  }) => ReactNode;
  onClose: () => void;
}> = ({ dss, children, onClose }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  /**
   * On change, if DSS is no longer in progress, then hide warning.
   */
  useEvent(dss, 'change', function () {
    if (show && !isDssInProgress(dss)) {
      setShow(false);
    }
  });

  /**
   * User clicked modal's close button (x) or clicked outside modal.
   * If file upload in progress, inform user they must cancel file uploads.
   */
  function onEscape() {
    if (isDssInProgress(dss)) {
      setShow(true);
    } else {
      onClose();
    }
  }

  return (
    <div>
      {children({
        dss,
        warning: show ? (
          <Message title={t('fileUploadWarning.title')} type="info">
            {t('fileUploadWarning.content')}
          </Message>
        ) : null,
        onEscape,
        onCancel: onClose
      })}
    </div>
  );
};

export default UploadModalWarning;
