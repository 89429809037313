import DocumentTitle from 'components/DocumentTitle';
import DOMPurify from 'dompurify';
import { useStateMounted } from 'hooks';
import { ITermsOfUse, TermsOfUseType } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import ENV from 'env';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

const sanitizedAddress = DOMPurify.sanitize(ENV.CONTACT_ADDRESS, {
  ALLOWED_TAGS: ['br']
});

export interface IViewTermsOfUseProps {
  termsOfUseType: TermsOfUseType;
}

const ViewTermsOfUse: React.FC<IViewTermsOfUseProps> = ({ termsOfUseType }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useStateMounted(false);
  const [terms, setTerms] = useState<ITermsOfUse>({
    acceptedVersion: null,
    content: '',
    version: 0
  });
  const sourceRef = useRef<CancelTokenSource>();
  const baseURL = `${ENV.API_ROOT}/api/user/terms/doc`;
  const dataSubjectUrl = `${baseURL}/data-subject-access-request-form`;
  const privacyNoticeCaliforniaUrl = `${baseURL}/privacy-notice-california-residents`;
  const privacyNoticeNevadaUrl = `${baseURL}/privacy-notice-nevada-residents`;

  // fetch terms of use/privacy policy
  useEffect(() => {
    const fetchTerms = async () => {
      // refresh cancel token
      sourceRef.current = ApiService.CancelToken.source();

      try {
        setIsLoading(true);
        const response = await ApiService.getTermsOfUse(
          termsOfUseType,
          sourceRef.current.token
        );

        // sanitize content markup
        const content = DOMPurify.sanitize(response.data.content, {
          // support "target" attribute on anchors
          ADD_ATTR: ['target']
        });
        setTerms({ ...response.data, content });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchTerms();

    return () => {
      // cancel ongoing requests
      sourceRef.current?.cancel();
    };
  }, [termsOfUseType, setIsLoading, t]);

  const docTitle = {
    [TermsOfUseType.PrivacyPolicy]: t('Privacy Policy'),
    [TermsOfUseType.TermsOfUse]: t('Terms of Use')
  };

  return (
    <>
      <DocumentTitle title={docTitle[termsOfUseType]} />
      <div className="terms-of-use">
        {termsOfUseType === TermsOfUseType.TermsOfUse && !isLoading && (
          <h1>
            {terms.acceptedVersion !== null &&
            terms.version > terms.acceptedVersion
              ? t('termsOfUse.updatedTermsTitle')
              : t('termsOfUse.title')}
          </h1>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: terms.content.replace('dataSubjectUrl', dataSubjectUrl)
          }}
        />
        {termsOfUseType === TermsOfUseType.PrivacyPolicy && (
          <div className="document-links">
            <p>
              <a
                href={privacyNoticeCaliforniaUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('termsOfUse.privacyNoticeCaliforniaRequestForm')}
              </a>
            </p>
          </div>
        )}
        {termsOfUseType === TermsOfUseType.PrivacyPolicy && (
          <div className="document-links">
            <p>
              <a
                href={privacyNoticeNevadaUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('termsOfUse.privacyNoticeNevadaRequestForm')}
              </a>
            </p>
          </div>
        )}
        <div className="page-help-form__assistance">
          <h2>{t('termsOfUse.contactTitle')}</h2>
          <p>
            <a href={`tel:${ENV.CONTACT_PHONE}`}>
              <Icon className="page-help-form__assistance-icon" name="phone" />{' '}
              {ENV.CONTACT_PHONE}
            </a>
          </p>
          <p>
            <a href={`mailto:${ENV.CONTACT_EMAIL}`}>
              <Icon className="page-help-form__assistance-icon" name="mail" />{' '}
              {ENV.CONTACT_EMAIL}
            </a>
          </p>
          <p>
            <Icon className="page-help-form__assistance-icon" name="location" />{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizedAddress
              }}
              style={{ display: 'inline-block' }}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default ViewTermsOfUse;
