import { EnumsWelcomeEmailCard } from '../EnumsWelcomeEmailCard';
import { IGetEmailStatusPostSchedule } from '../IWelcomeEmailCard';

export const getEmailStatusPostSchedule: IGetEmailStatusPostSchedule = (
  todaysDate,
  emailExpirationDate
) => {
  const EMAIL_STATUS = `${EnumsWelcomeEmailCard.ROOT_TO_TEXT}.emailStatus`;
  const UNIT_OF_TIME = `${EnumsWelcomeEmailCard.ROOT_TO_TEXT}.unitOfTime`;

  const timeUntilExpiration =
    emailExpirationDate.getTime() - todaysDate.getTime();

  let additionalDetails = {};
  let isExpired = false;
  let pathToStatus;
  let pathToUnitOfTime;

  const numberOfDaysUntilExpiration = timeUntilExpiration / (1000 * 3600 * 24);

  if (emailExpirationDate >= todaysDate) {
    const daysToExpire = Math.floor(numberOfDaysUntilExpiration);

    additionalDetails =
      daysToExpire === 0
        ? {}
        : {
            expireOrExpiredLength: daysToExpire,
            pathToUnitOfTime: `${UNIT_OF_TIME}.${
              daysToExpire === 1 ? 'day' : 'days'
            }`
          };
    pathToStatus =
      daysToExpire === 0
        ? `${EMAIL_STATUS}.expiresToday`
        : `${EMAIL_STATUS}.emailPendingExpiration`;
  } else {
    const numberofOfDaysPostExpiration = Math.abs(numberOfDaysUntilExpiration);
    const daysExpired = Math.floor(numberofOfDaysPostExpiration);

    isExpired = true;

    if (daysExpired === 0) {
      pathToStatus = `${EMAIL_STATUS}.emailExpiredToday`;
    } else {
      const modifiedEmailExpirationDate = new Date(emailExpirationDate);
      modifiedEmailExpirationDate.setMonth(
        modifiedEmailExpirationDate.getMonth() + 1
      );

      let monthsExpired = 0;
      while (todaysDate > modifiedEmailExpirationDate) {
        monthsExpired += 1;
        modifiedEmailExpirationDate.setMonth(
          modifiedEmailExpirationDate.getMonth() + 1
        );
      }

      pathToStatus = `${EMAIL_STATUS}.emailExpired`;
      pathToUnitOfTime =
        monthsExpired > 0
          ? `${UNIT_OF_TIME}.${monthsExpired === 1 ? 'month' : 'months'}`
          : `${UNIT_OF_TIME}.${daysExpired === 1 ? 'day' : 'days'}`;

      additionalDetails =
        monthsExpired > 0
          ? { expireOrExpiredLength: monthsExpired, pathToUnitOfTime }
          : { expireOrExpiredLength: daysExpired, pathToUnitOfTime };
    }
  }

  return { additionalDetails, isExpired, pathToStatus };
};
