import SearchInput from 'components/SearchInput';
import TimelinesCalendar from 'components/TimelinesCalendar/TimelinesCalendar';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  EngagementStatus,
  IEngagementTimeline,
  IParentPracticeArea,
  ITableState
} from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import { addMonths, startOfYear } from 'date-fns';
import React, { Component, ComponentProps } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Dropdown,
  EButtonSizes,
  EButtonVariant,
  Popover
} from '@ryan/components';

import AmplitudeApiService from '../../../services/AmplitudeApiService';

import './Timelines.scss';

const MONTHS = 12;

function getEndDate(startDate: Date | number) {
  return new Date(addMonths(startDate, MONTHS).getTime() - 1);
}

interface ITimelinesProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps<{ engagementGuid: string }> {
  // ...
}

interface ITimelinesState extends ITableState {
  engagements: IEngagementTimeline[] | null;
  year: string;
  yearOptions: ComponentProps<typeof Dropdown>['options'] | null;
  status: string[];
  parentPracticeAreas: string[];
  parentPracticeAreaOptions: IParentPracticeArea[] | null;
  startDate: Date;
  isAtEnd: boolean;
}

export class Timelines extends Component<ITimelinesProps, ITimelinesState> {
  private lastRequest: Promise<any> | null = null;
  private getTimelinesCancelToken?: CancelTokenSource;
  private getTimelineYearsCancelToken?: CancelTokenSource;
  private getTimelineParentPracticeAreasCancelToken?: CancelTokenSource;

  constructor(props: ITimelinesProps) {
    super(props);

    const startDate = startOfYear(new Date());
    const year = startDate.getFullYear().toString();

    this.state = {
      loading: false,
      engagements: null,
      page: 1,
      pageSize: 10,
      totalCount: 0,
      startDate,
      year,
      yearOptions: null,
      status: [EngagementStatus.Active.toString()],
      parentPracticeAreas: [],
      parentPracticeAreaOptions: null,
      searchQuery: '',
      isAtEnd: false
    };
  }

  componentDidMount() {
    this.fetchFilterData();
    this.fetchTimelines();
  }

  componentDidUpdate(prevProps: ITimelinesProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchFilterData();
      this.fetchTimelines({ page: 1 });
    }
  }

  componentWillUnmount() {
    this.getTimelinesCancelToken?.cancel();
    this.getTimelineYearsCancelToken?.cancel();
    this.getTimelineParentPracticeAreasCancelToken?.cancel();
  }

  /**
   * This is called on load and when the switcher updates to
   * fetch options for YEAR and PRACTICE.
   */
  async fetchFilterData() {
    const { activeView } = this.props;

    // refresh cancel tokens
    this.getTimelineYearsCancelToken?.cancel();
    this.getTimelineParentPracticeAreasCancelToken?.cancel();

    this.getTimelineYearsCancelToken = ApiService.CancelToken.source();
    this.getTimelineParentPracticeAreasCancelToken =
      ApiService.CancelToken.source();

    /**
     * API returns all years for which we have projects to display.
     * eg [2016, 2018]
     *
     * getYearOptions() fills in holes between years + this year.
     * eg [
     *  Select Year (placeholder, disabled),
     *  2021 (current year, disabled),
     *  2020 (gap year, disabled)
     *  2019 (gap year, disabled),
     *  2018,
     *  2017 (gap year, disabled),
     *  2016
     * ]
     */

    ApiService.getTimelineYears(
      activeView.customViewGuid,
      this.getTimelineYearsCancelToken.token
    )
      .then(response => {
        this.setState(({ startDate }) => ({
          year: startDate.getFullYear().toString(),
          yearOptions: this.getYearOptions(response.data)
        }));
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });

    ApiService.getTimelineParentPracticeAreas(
      activeView.customViewGuid,
      this.getTimelineParentPracticeAreasCancelToken.token
    )
      .then(response => {
        this.setState({
          parentPracticeAreaOptions: response.data.sort((a, b) => {
            const aName = a.parentPracticeAreaName || '';
            const bName = b.parentPracticeAreaName || '';
            return aName.localeCompare(bName);
          })
        });
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });
  }

  getYearOptions(years: number[]) {
    const { t } = this.props;

    // mark years as enabled
    const withDisabled: [number, boolean][] = years.map(y => [y, false]);

    // if no data for current year, and the current year will not be
    // added by the missing loop below, then add it now as disabled
    const currentYear = new Date().getFullYear();
    if (years.indexOf(currentYear) === -1) {
      const lt = years.some(y => y < currentYear);
      const gt = years.some(y => y > currentYear);
      if (!(lt && gt)) {
        withDisabled.push([currentYear, true]);
      }
    }

    // sort descending
    withDisabled.sort((a, b) => b[0] - a[0]);

    // fill missing intermediate years with disabled options
    const withMissing: [number, boolean][] = [];
    for (let i = 0; i < withDisabled.length; i++) {
      withMissing.push(withDisabled[i]);

      if (withDisabled[i + 1]) {
        let current = withDisabled[i][0];
        const next = withDisabled[i + 1][0];
        while (current - next > 1) {
          current -= 1;
          withMissing.push([current, true]);
        }
      }
    }

    const placeholder = {
      label: t('Select Year'),
      value: '',
      disabled: true
    };

    const options = withMissing.map(t => ({
      label: t[0].toString(),
      value: t[0].toString(),
      disabled: t[1]
    }));

    return [placeholder, ...options];
  }

  fetchTimelines(updates?: Partial<ITimelinesState>) {
    const { activeView } = this.props;

    this.setState({ ...(updates as any), loading: true }, async () => {
      const {
        page,
        pageSize,
        searchQuery,
        status,
        parentPracticeAreas,
        startDate
      } = this.state;

      try {
        // refresh cancel token
        this.getTimelinesCancelToken?.cancel();
        this.getTimelinesCancelToken = ApiService.CancelToken.source();

        const request = (this.lastRequest = ApiService.getTimelines(
          {
            customViewGuid: activeView.customViewGuid,
            pageNumber: 1,
            itemsPerPage: page * pageSize,
            searchTerm: searchQuery,
            isActive: status.length === 1 ? status[0] : undefined,
            parentPracticeAreaGuids: parentPracticeAreas.join(','),
            alternateBeginDate: startDate.toISOString(),
            projectedEndDate: getEndDate(startDate).toISOString(),
            sort: 'AlternateBeginDate'
          },
          this.getTimelinesCancelToken.token
        ));

        const response = await request;

        if (request === this.lastRequest) {
          const {
            results: engagements,
            totalResults: totalCount,
            pageNumber: page,
            itemsPerPage: pageSize
          } = response.data;

          this.setState({
            loading: false,
            engagements,
            totalCount,
            isAtEnd: page >= 20 || totalCount < page * pageSize
          });

          this.lastRequest = null;
        }
      } catch (error) {
        /* @todo */
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      }
    });
  }

  createStringStatus(arr: string[]): string {
    const hasOne = arr.includes('1');
    const hasZero = arr.includes('0');

    if (hasOne && hasZero) {
      return 'active, inactive';
    } else if (hasOne) {
      return 'active';
    } else if (hasZero) {
      return 'inactive';
    } else {
      return '';
    }
  }

  handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = e.target.value;
    const startDate = new Date(parseInt(year, 10), 0);
    AmplitudeApiService.logEvent('customize-timeline-view', {
      'year-selector': year
    });
    this.fetchTimelines({ page: 1, year, startDate });
  };

  handleStatusChange = (_e: React.ChangeEvent, values: string[]) => {
    const resultStatuses = this.createStringStatus(values);

    if (resultStatuses !== '') {
      AmplitudeApiService.logEvent('customize-timeline-view', {
        status: resultStatuses
      });
    }
    this.fetchTimelines({ page: 1, status: values });
  };

  handlePracticeAreaChange = (_e: React.ChangeEvent, values: string[]) => {
    const { parentPracticeAreaOptions } = this.state;

    const selectedPracticeAreaNames = values.map(selectedGuid => {
      const matchingObject =
        parentPracticeAreaOptions &&
        parentPracticeAreaOptions.find(
          item => item.parentPracticeAreaGuid === selectedGuid
        );
      return matchingObject ? matchingObject.parentPracticeAreaName : '';
    });

    const resultPractices = selectedPracticeAreaNames.join(', ');

    if (resultPractices !== '') {
      AmplitudeApiService.logEvent('customize-timeline-view', {
        practice: resultPractices
      });
    }

    this.fetchTimelines({ page: 1, parentPracticeAreas: values });
  };

  handleSearchChange = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchTimelines({ page: 1, searchQuery });
    }
  );

  handleBackOneMonth = () => {
    this.move(-1);
  };

  handleForwardOneMonth = () => {
    this.move(1);
  };

  move(months: number) {
    const { startDate: prevStartDate } = this.state;
    if (prevStartDate) {
      const startDate = addMonths(prevStartDate, months);
      const year = startDate.getFullYear().toString();
      this.fetchTimelines({ page: 1, startDate, year });
    }
  }

  handleShowMore = () => {
    const { page } = this.state;
    this.fetchTimelines({ page: Math.min(page + 1, 20) });
  };

  hasActiveFilters() {
    const { status, parentPracticeAreas, searchQuery } = this.state;
    return (
      status.length > 0 ||
      parentPracticeAreas.length > 0 ||
      (searchQuery && searchQuery.length > 0)
    );
  }

  render() {
    const { t } = this.props;
    const {
      loading,
      year,
      yearOptions,
      status,
      parentPracticeAreas,
      parentPracticeAreaOptions,
      searchQuery,
      engagements,
      startDate,
      isAtEnd
    } = this.state;

    return (
      <div className="projects-timelines">
        <div className="projects-timelines__header">
          <Dropdown
            onChange={this.handleDateChange}
            options={yearOptions || []}
            value={year}
          />
          <div className="projects-timelines__header-right">
            <Popover
              placement="bottom"
              renderContent={() => (
                <CheckboxGroup
                  onChange={this.handleStatusChange}
                  value={status}
                >
                  <Checkbox
                    label={t('Active')}
                    value={EngagementStatus.Active.toString()}
                  />
                  <Checkbox
                    label={t('Inactive')}
                    value={EngagementStatus.InActive.toString()}
                  />
                </CheckboxGroup>
              )}
              renderTarget={({ ref, onClick }) => (
                <Button
                  icon="filter"
                  iconPlacement="after"
                  innerRef={ref}
                  onClick={onClick}
                  size={EButtonSizes.SMALL}
                  text={t('Status')}
                  variant={EButtonVariant.TEXT}
                />
              )}
            />
            <Popover
              className="projects-timelines__filter-practice-areas"
              placement="bottom"
              renderContent={() => (
                <CheckboxGroup
                  onChange={this.handlePracticeAreaChange}
                  value={parentPracticeAreas}
                >
                  {parentPracticeAreaOptions &&
                    parentPracticeAreaOptions.map((ppa, i) => (
                      <Checkbox
                        key={i}
                        label={ppa.parentPracticeAreaName}
                        value={ppa.parentPracticeAreaGuid}
                      />
                    ))}
                </CheckboxGroup>
              )}
              renderTarget={({ ref, onClick }) => (
                <Button
                  disabled={
                    !(
                      parentPracticeAreaOptions !== null &&
                      parentPracticeAreaOptions.length !== 0
                    )
                  }
                  icon="filter"
                  iconPlacement="after"
                  innerRef={ref}
                  onClick={onClick}
                  size={EButtonSizes.SMALL}
                  text={t('Practice')}
                  variant={EButtonVariant.TEXT}
                />
              )}
            />
            <SearchInput
              loading={loading}
              onChange={this.handleSearchChange}
              value={searchQuery}
            />
          </div>
        </div>
        <TimelinesCalendar
          engagements={engagements}
          isAtEnd={isAtEnd}
          loading={loading}
          months={MONTHS}
          onBackOneMonth={this.handleBackOneMonth}
          onForwardOneMonth={this.handleForwardOneMonth}
          onShowMore={this.handleShowMore}
          startDate={startDate}
          t={t}
        />
      </div>
    );
  }
}

export default withTranslation()(withUser(Timelines));
