import { CancelToken } from 'axios';
import {
  IComment,
  IDirectoryFile,
  IFile,
  IFileRequest,
  ILearning,
  ILearningFilesSearch,
  ILearningRequest,
  ILearningsSearch,
  IPagedDataResponse
} from 'interfaces';
import { transformResponse } from 'utils/transformResponse';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';
import ApiBase from './ApiBase';

export async function createLearning(
  this: ApiBase,
  engagementGuid: string,
  learning: ILearningRequest
) {
  return this.server.post<ILearning>(
    `/api/engagements/${engagementGuid}/learnings`,
    learning,
    { transformResponse }
  );
}

export async function getLearning(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  cancelToken: CancelToken
) {
  return this.server.get<ILearning>(
    `/api/engagements/${engagementGuid}/learnings/${learningGuid}`,
    { cancelToken, transformResponse }
  );
}

export async function updateLearning(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  learning: ILearningRequest
) {
  return this.server.put<ILearning>(
    `/api/engagements/${engagementGuid}/learnings/${learningGuid}`,
    learning,
    { transformResponse }
  );
}

export async function deleteLearning(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string
) {
  return this.server.delete(
    `/api/engagements/${engagementGuid}/learnings/${learningGuid}`,
    { transformResponse }
  );
}

export async function getEngagementLearnings(
  this: ApiBase,
  engagementGuid: string,
  params: ILearningsSearch,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<ILearning>>(
    `/api/engagements/${engagementGuid}/learnings/search`,
    { cancelToken, params, transformResponse }
  );
}

//
//
// Learning Files
//
//

export async function getLearningFiles(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  params: ILearningFilesSearch
) {
  return this.server.get<IDirectoryFile[]>(
    `/api/engagements/${engagementGuid}/learnings/${learningGuid}/documents/search`,
    { params, transformResponse }
  );
}

export async function updateLearningFile(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  documentGuid: string,
  file: IFileRequest
) {
  return this.server.put<IFile>(
    `/api/engagements/${engagementGuid}/learnings/${learningGuid}/documents/${documentGuid}`,
    file,
    { transformResponse }
  );
}

export async function deleteLearningFiles(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  documentGuids: string[]
) {
  return this.server.post<IFile>(
    `/api/engagements/${engagementGuid}/learnings/${learningGuid}/documents/delete`,
    documentGuids,
    { transformResponse }
  );
}

export async function zipLearningFiles(this: ApiBase, learning: ILearning) {
  return this.server.post(
    `/api/engagements/${learning.engagementGuid}/learnings/${learning.learningGuid}/zipFiles`,
    learning
  );
}

//
//
// Learning Comments
//
//

export async function getLearningComments(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string
) {
  return this.server.get<IComment[]>(
    `/api/engagements/${engagementGuid}/learnings/${learningGuid}/comments`
  );
}

export async function createLearningComment(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  commentText: string
) {
  return unwrapCommandResponse<IComment>(
    this.server.post(
      `/api/engagements/${engagementGuid}/learnings/${learningGuid}/comments`,
      { commentText }
    )
  );
}

export async function updateLearningComment(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  commentGuid: string,
  commentText: string
) {
  return unwrapCommandResponse<IComment>(
    this.server.put(
      `/api/engagements/${engagementGuid}/learnings/${learningGuid}/comments/${commentGuid}`,
      { commentText }
    )
  );
}

export async function deleteLearningComment(
  this: ApiBase,
  engagementGuid: string,
  learningGuid: string,
  commentGuid: string
) {
  return unwrapCommandResponse(
    this.server.delete(
      `/api/engagements/${engagementGuid}/learnings/${learningGuid}/comments/${commentGuid}`
    )
  );
}
