import { IExecutiveAccessToggle } from 'interfaces';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Switch } from '@ryan/components';

import ExecutiveAccessToggleGroup, {
  ExecutiveAccessSelectionStatus
} from './ExecutiveAccessToggleGroup';

import './ExecutiveAccessControl.scss';

export interface ExecutiveAccess {
  enabled: boolean;
  selections: IExecutiveAccessToggle[];
}

interface IExecutiveAccessProps {
  value: ExecutiveAccess;
  onChange: (executiveAccess: ExecutiveAccess) => void;
}

type ToggleTypeMap = {
  [toggleType in IExecutiveAccessToggle['toggleType']]: IExecutiveAccessToggle[];
};

/**
 * Maps the type of executive access item to a list of items of that type.
 */
const mapToType = (toggles: IExecutiveAccessToggle[]): ToggleTypeMap => {
  const toggleTypeMap: ToggleTypeMap = {
    LegalEntity: [],
    PracticeArea: []
  };
  toggles.forEach(toggle => toggleTypeMap[toggle.toggleType].push(toggle));
  return toggleTypeMap;
};

/**
 * Component to toggle user's Executive Access status and the individual
 * items the user has access to.
 */
const ExecutiveAccessControl: React.FC<IExecutiveAccessProps> = ({
  value,
  onChange
}) => {
  const { t } = useTranslation();
  const toggleTypeMap = useMemo(
    () => mapToType(value.selections),
    [value.selections]
  );

  function onToggleEnabled() {
    onChange({
      enabled: !value.enabled,
      selections: value.selections.map(t => ({ ...t, isSelected: false }))
    });
  }

  /**
   * Updates user's toggle settings as options are updated.
   */
  function onSelection(selectionStatus: ExecutiveAccessSelectionStatus) {
    onChange({
      ...value,
      selections: value.selections.map(t =>
        t.toggleGuid in selectionStatus
          ? { ...t, isSelected: selectionStatus[t.toggleGuid] }
          : t
      )
    });
  }

  return (
    <div className="executive-access-control">
      <Card title={t('executiveAuditAccess.title')}>
        {t('executiveAuditAccess.description')}
        <Switch
          checked={value.enabled}
          name="executive-access-toggle"
          onChange={onToggleEnabled}
        />
      </Card>
      {value.enabled && (
        <div className="ry-table">
          <div className="ry-table__body">
            <table className="ry-table__table">
              <thead>
                <tr>
                  <th className="ry-table__th">
                    <span className="ry-table__th-el ry-table__th-label">
                      {t('executiveAuditAccess.title')}
                    </span>
                  </th>
                  <th className="ry-table__th ry-table__th--align-right">
                    <span className="ry-table__th-el ry-table__th-label">
                      {t('executiveAuditAccess.permissions')}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(
                  Object.keys(
                    toggleTypeMap
                  ) as IExecutiveAccessToggle['toggleType'][]
                ).map(toggleType => (
                  <ExecutiveAccessToggleGroup
                    items={toggleTypeMap[toggleType]}
                    key={toggleType}
                    onChange={onSelection}
                    typeName={t(`executiveAuditAccess.type.${toggleType}`)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExecutiveAccessControl;
