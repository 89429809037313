import camelCase from 'lodash/camelCase';
import * as yup from 'yup';

import { startOfDay } from 'date-fns';
import { withFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  InfoWell
} from '@ryan/components';

import { withUser } from '../../../../contexts/UserContext';
import { IUserSummary } from '../../../../interfaces';
import ApiService from '../../../../services/ApiService';
import {
  formikAutocompleteAjaxProps,
  formikDatepickerProps,
  formikFieldProps
} from '../../../../utils/forms';
import { UserAutocomplete } from '../../../AutocompleteAjax';
import Datepicker from '../../../Datepicker';
import ManageAttachments from '../../../ManageAttachments';
import { RadioButton, RadioButtonGroup } from '../../../RadioButton';
import {
  IDataRequestFormCombinedProps,
  IDataRequestFormProps,
  IDataRequestFormValues,
  InitialAttachments,
  ThirdStepFormEnums
} from './utils';

import './ThirdStepForm.scss';

const DataRequestInnerForm: React.FC<IDataRequestFormCombinedProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);

  const {
    attachments,
    assignedTo,
    dataRequestTypeName,
    dss,
    dueDate,
    engagement,
    errors,
    existingFile,
    handleSubmit,
    onNextStepClick,
    setFieldValue,
    setAssignedTo,
    setAttachments,
    setDueDate,
    setExistingFile,
    setShouldAttachFiles,
    setTouched,
    shouldAttachFiles,
    status,
    t: getTextToDisplay,
    title,
    touched,
    values
  } = props;

  useEffect(() => {
    if (engagement) {
      setFieldValue('engagement', engagement);
    }
    if (assignedTo) {
      setFieldValue('assignedTo', assignedTo);
    }
    if (dueDate) {
      setFieldValue('dueDate', dueDate);
    }
    if (attachments) {
      setFieldValue('attachments', attachments);
    }
    if (shouldAttachFiles === ThirdStepFormEnums.YES) {
      setFieldValue('shouldAttachFiles', shouldAttachFiles);
    }
  }, [
    assignedTo,
    attachments,
    dueDate,
    engagement,
    setFieldValue,
    shouldAttachFiles
  ]);

  const fetchAssignableUsers = async (query: string) => {
    const { engagement } = props;
    if (engagement) {
      const response = await ApiService.getEngagementDataRequestAssignableUsers(
        engagement.engagementGuid,
        query
      );
      return response.data;
    }
    return [];
  };

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setTouched({
      assignedTo: true,
      dueDate: true,
      attachments: {},
      engagement: true,
      shouldAttachFiles: true
    });
    handleSubmit();
  };

  const handleAttachFilesChange = (event: { target: { value: string } }) => {
    const value = event.target.value;
    setFieldValue('shouldAttachFiles', value);
    if (value === ThirdStepFormEnums.NO) {
      setAttachments(InitialAttachments);
      setFieldValue('attachments', InitialAttachments);
      dss.clearUploads();
    }
    setShouldAttachFiles(value);
  };

  const handleAssignedToChange = (option: IUserSummary | null) => {
    setFieldValue('assignedTo', option);
    setAssignedTo(option);
  };

  const handleDueDateChange = (
    e: React.SyntheticEvent<any, Event> | undefined,
    value: Date | null
  ) => {
    setFieldValue('dueDate', value);
    setDueDate(value);
  };

  const radioButtonGroupProps = {
    ...formikFieldProps('shouldAttachFiles', props),
    onChange: handleAttachFilesChange
  };

  const today = startOfDay(new Date());

  return (
    <>
      {props.status.error}
      <h2 className="step-title">
        {getTextToDisplay('modal.dataRequestModal.steps.step-4.title')}
      </h2>
      <InfoWell
        accountName={engagement?.accountName || ''}
        dataRequestTitle={title}
        dataRequestType={getTextToDisplay(
          `dataRequest.dataTypes.${camelCase(
            dataRequestTypeName.replaceAll(' ', '')
          )}`
        )}
        projectName={engagement?.engagementDisplayNameLong || ''}
      />
      <form autoComplete="off" ref={formRef}>
        <UserAutocomplete
          {...formikAutocompleteAjaxProps(
            'assignedTo',
            props,
            (name, formik, ...args) => {
              const [user] = args;
              formik.setFieldValue(
                name,
                user
                  ? {
                      firstName: user?.firstName,
                      fullName: user?.fullName,
                      lastName: user?.lastName,
                      title: user?.title,
                      userGuid: user?.userGuid
                    }
                  : null
              );
            }
          )}
          disabled={values.engagement === null}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.assignedTo.label'
          )}
          onChange={handleAssignedToChange}
          onFetchOptions={fetchAssignableUsers}
        />
        <Datepicker
          {...formikDatepickerProps('dueDate', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.dueDate.label'
          )}
          minDate={today}
          onChange={(e, value) => handleDueDateChange(e, value)}
          value={dueDate}
        />
        <label className="ry-label">
          {getTextToDisplay('modal.dataRequestModal.attachText')}
        </label>
        <div className="attach-buttons-wrapper">
          <RadioButtonGroup {...radioButtonGroupProps}>
            <RadioButton
              checked={values.shouldAttachFiles === ThirdStepFormEnums.YES}
              label={getTextToDisplay('modal.dataRequestModal.attachYes')}
              value={ThirdStepFormEnums.YES}
            />
            <RadioButton
              checked={values.shouldAttachFiles === ThirdStepFormEnums.NO}
              label={getTextToDisplay('modal.dataRequestModal.attachNo')}
              value={ThirdStepFormEnums.NO}
            />
          </RadioButtonGroup>
        </div>
        {values.shouldAttachFiles === ThirdStepFormEnums.YES && (
          <>
            <ManageAttachments
              attachments={[] || attachments}
              dss={dss}
              engagementGuid={engagement?.engagementGuid || null}
              engagementName={engagement?.engagementDisplayNameShort || null}
              errors={errors.attachments && touched.attachments && errors}
              existingFile={existingFile}
              label={getTextToDisplay(
                'modal.dataRequestModal.fields.attachments.label'
              )}
              onChange={updates => {
                setFieldValue('attachments', updates);
                setAttachments(updates);
              }}
              setExistingFile={setExistingFile}
              value={attachments || InitialAttachments}
            />
          </>
        )}
        <ButtonGroup>
          <Button
            disabled={status.loading}
            onClick={() => onNextStepClick(3)}
            text={getTextToDisplay('modal.dataRequestModal.back')}
            type="submit"
            variant={EButtonVariant.SECONDARY}
          />
          <Button
            loading={status.loading}
            onClick={e => handleFormSubmit(e)}
            text={getTextToDisplay('modal.dataRequestModal.create')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const defaultValues: IDataRequestFormValues = {
  assignedTo: null,
  attachments: InitialAttachments,
  dueDate: null,
  engagement: null,
  shouldAttachFiles: ThirdStepFormEnums.NO
};

const handleSubmit = async (
  values: IDataRequestFormValues,
  { setStatus, props }: any
) => {
  setStatus({ loading: new Promise(() => {}) });

  try {
    await props.onSubmitted();
    setStatus({ loading: null });
  } catch (error) {
    console.error(error);
    setStatus({ loading: null });
  }
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: () => defaultValues,

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: ({ t: getTextToDisplay }: IDataRequestFormProps) =>
      yup.object({
        assignedTo: yup
          .object()
          .nullable()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.assignedTo.required'
            )
          ),
        dueDate: yup
          .date()
          .nullable()
          .required(
            getTextToDisplay('modal.dataRequestModal.fields.dueDate.required')
          )
          .min(
            startOfDay(new Date()),
            getTextToDisplay('modal.dataRequestModal.fields.dueDate.minToday')
          ),
        attachments: yup
          .mixed()
          .test(
            'has-attachments',
            getTextToDisplay(
              'modal.dataRequestModal.fields.attachments.required'
            ),
            function (value) {
              const { shouldAttachFiles } = this.parent;
              if (shouldAttachFiles === ThirdStepFormEnums.YES) {
                const hasAttachments =
                  value.deleteAttachments.length > 0 ||
                  value.addExisting.length > 0 ||
                  value.addUploaded.length > 0;
                return hasAttachments;
              }
              return true;
            }
          )
      }),

    handleSubmit: handleSubmit
  })(withUser(DataRequestInnerForm))
);
