import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

const SectionDivider: FunctionComponent<WithTranslation> = ({ t }) => {
  return (
    <div className="section-divider">
      <hr style={{ width: '40%' }} />
      <p>{t('And')}</p>
      <hr style={{ width: '40%' }} />
    </div>
  );
};

export default withTranslation()(SectionDivider);
