import React from 'react';

import { TWorklistTaskItem } from '../../../../../routes/Worklist/WorklistMine/WorklistMine.interfaces';
import {
  IMapToTaskExpandedRow,
  TTaskExpandedRowProps
} from './TaskExpandedRow.interfaces';
import TaskOverview from './TaskOverview/TaskOverview';
import TaskStatus from './TaskStatus/TaskStatus';

import './TaskExpandedRow.scss';

export const mapToTaskExpandedRow: IMapToTaskExpandedRow = ({
  attachments,
  commentCount,
  createdBy,
  createdDate,
  description,
  dueDate,
  engagementDisplayNameShort,
  engagementGuid,
  followerCount,
  isEngagementReadOnly,
  isFollowing,
  statusId,
  title,
  ...rest
}) => {
  const { engagementMilestoneGuid, engagementMilestoneTitle } =
    rest as TWorklistTaskItem;

  return {
    engagementGuid,
    overview: {
      attachments,
      commentCount,
      description,
      engagementDisplayNameShort,
      engagementGuid,
      isEngagementReadOnly,
      title
    },
    status: {
      createdBy,
      createdDate,
      dueDate,
      engagementMilestoneGuid,
      engagementMilestoneTitle,
      followerCount,
      isFollowing,
      statusId
    }
  };
};

const TaskExpandedRow = ({
  isDisabled,
  onLinkToMilestone,
  onOpenFollowersModal,
  onOpenTaskCommentDrawer,
  onToggleFollow,
  taskDetails
}: TTaskExpandedRowProps) => (
  <div className="task-expanded-row">
    <TaskOverview
      isDisabled={isDisabled}
      onOpenTaskCommentDrawer={onOpenTaskCommentDrawer}
      taskDetails={{
        ...taskDetails.overview,
        engagementGuid: taskDetails.engagementGuid
      }}
    />

    <TaskStatus
      isDisabled={isDisabled}
      onLinkToMilestone={onLinkToMilestone}
      onOpenFollowersModal={onOpenFollowersModal}
      onToggleFollow={onToggleFollow}
      taskStatus={{
        ...taskDetails.status,
        engagementGuid: taskDetails.engagementGuid
      }}
    />
  </div>
);

export default TaskExpandedRow;
