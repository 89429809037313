import { CancelToken } from 'axios';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, EButtonVariant, Icon } from '@ryan/components';

import BarChart from '../../../../components/BarChart/BarChart';
import ApiService from '../../../../services/ApiService';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';
import {
  IProjectSnapshotAssignmentsProps,
  IRenderBarChartDetail
} from './utils';

import './ProjectSnapshotAssignments.scss';

const ProjectSnapshotAssignments: React.FC<
  IProjectSnapshotAssignmentsProps
> = ({ engagementGuid, handleViewAssignments, icon, title }) => {
  const [assignmentsSummary, setAssignmentsSummary] = useState({
    fifteenDaysPlus: 0,
    overdue: 0,
    total: 0,
    withinFourteenDays: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const { t: textToDisplay } = useTranslation();

  const barChartOrder = [
    {
      keyName: 'overdue',
      style: { backgroundColor: '#cc2936' }
    },
    {
      keyName: 'withinFourteenDays',
      style: { backgroundColor: '#edae49' }
    },
    {
      keyName: 'fifteenDaysPlus',
      style: { backgroundColor: '#a6caff' }
    }
  ];
  const isAssignmentsEmpty = assignmentsSummary.total === 0;
  const textToDisplayPath = 'table.projectSnapshot.assignments.';

  const barChartDataPoints = barChartOrder.map(dataPoint => ({
    ...dataPoint,
    value:
      assignmentsSummary[dataPoint.keyName as keyof typeof assignmentsSummary]
  }));

  const initialize = useCallback(
    async (title: string, guid: string, cancelToken: CancelToken) => {
      const apiServiceMapper = {
        dataRequests: async (guid: string, cancelToken: CancelToken) =>
          await ApiService.getEngagementDataRequestCount(guid, cancelToken),
        tasks: async (guid: string, cancelToken: CancelToken) =>
          await ApiService.getEngagementTaskCounts(guid, cancelToken)
      };

      try {
        const response = await apiServiceMapper[
          title as keyof typeof apiServiceMapper
        ](guid, cancelToken);

        if (response.data) {
          const {
            dueInFifteenDaysOrMore = 0,
            dueWithinFourteenDays = 0,
            overdue = 0
          } = response.data;

          setAssignmentsSummary({
            fifteenDaysPlus: dueInFifteenDaysOrMore,
            overdue,
            total: dueInFifteenDaysOrMore + dueWithinFourteenDays + overdue,
            withinFourteenDays: dueWithinFourteenDays
          });
          setIsLoading(false);
        } else {
          throw new Error('response data missing');
        }
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading]
  );

  useEffect(() => {
    const cancelTokenSource = ApiService.CancelToken.source();

    initialize(title, engagementGuid, cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [engagementGuid, initialize, title]);

  const renderBarChartDetail: IRenderBarChartDetail = (value, title) => (
    <div key={title}>
      <div className="project-snapshot-assignments__breakdown__bar-chart__details__marker" />
      <div>
        <span>{title}</span>
        {value}
      </div>
    </div>
  );

  return (
    <div className="project-snapshot-assignments">
      <Button
        disabled={isLoading}
        onClick={
          handleViewAssignments as any as MouseEventHandler<HTMLButtonElement>
        }
        size={EButtonSizes.SMALL}
        text={textToDisplay(`${textToDisplayPath}view`)}
        variant={EButtonVariant.TEXT}
      />

      <h4 className="ry-h4">
        {textToDisplay(`${textToDisplayPath}titles.${title}`)}
      </h4>

      {isLoading && (
        <div className="project-snapshot-assignments__loading">
          <Icon className="loading-spin" name="loading" />
        </div>
      )}

      {!isLoading && isAssignmentsEmpty && (
        <div className="project-snapshot-assignments__no-assignments">
          <Icon name={icon} />{' '}
          {textToDisplay(`${textToDisplayPath}noAssignments.${title}`)}
        </div>
      )}

      {!isLoading && !isAssignmentsEmpty && (
        <div className="project-snapshot-assignments__breakdown">
          <div className="project-snapshot-assignments__breakdown__summary">
            <span className="project-snapshot-assignments__breakdown__summary__total">
              {assignmentsSummary.total}
            </span>
            <span className="project-snapshot-assignments__breakdown__summary__title">
              {textToDisplay(`${textToDisplayPath}outstanding`)}
            </span>
          </div>

          <div className="project-snapshot-assignments__breakdown__bar-chart">
            <BarChart barChartDataPoints={barChartDataPoints} />
            <div className="project-snapshot-assignments__breakdown__bar-chart__details">
              {barChartDataPoints.map(dataPoint =>
                renderBarChartDetail(
                  dataPoint.value,
                  textToDisplay(
                    `${textToDisplayPath}barChart.${dataPoint.keyName}`
                  )
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectSnapshotAssignments;
