import React, { useContext } from 'react';

export interface IUnsavedChangesContext {
  blockNavigation: boolean;
  isUnsavedChanges: boolean;
  pendingLocation: any | null;
  setBlockNavigation: (value: boolean) => void;
  setIsUnsavedChanges: (value: boolean) => void;
  setPendingLocation: (value: any | null) => void;
  setTargetUrl: (value: string) => void;
  targetUrl: string;
}

const UnsavedChangesContext = React.createContext<IUnsavedChangesContext>({
  blockNavigation: false,
  isUnsavedChanges: false,
  pendingLocation: '',
  setBlockNavigation: () => {},
  setIsUnsavedChanges: () => {},
  setPendingLocation: () => {},
  setTargetUrl: () => {},
  targetUrl: ''
});

export default UnsavedChangesContext;

export const useUnsavedChanges = () => {
  const context = useContext(UnsavedChangesContext);

  if (!context) {
    throw new Error(
      'useUnsavedChanges must be used within an UnsavedChangesProvider'
    );
  }

  return context;
};
