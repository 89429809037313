import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Status } from '../../interfaces';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import useUniqueId from '../../utils/useUniqueId';

import './StatusIcon.scss';

interface IStatusProps {
  status: Status;
  subStatus?: SubStatusEnums;
  label?: string;
  size?: 'sm' | 'lg';
  iconOnly?: boolean;
  inverted?: boolean;
}

const StatusIcon: FunctionComponent<IStatusProps> = ({
  status,
  subStatus,
  label: labelProp,
  size,
  iconOnly,
  inverted
}) => {
  const { t } = useTranslation();
  const titleId = useUniqueId();
  const label =
    labelProp ||
    t(
      `status.${
        subStatus === SubStatusEnums.DataDelivered ? status + subStatus : status
      }`
    );
  return (
    <div className={classnames('status', `status--${size}`)}>
      <svg
        aria-labelledby={titleId}
        className="status__icon"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {iconOnly && <title id={titleId}>{label}</title>}

        <g transform="translate(8, 8)">
          <circle
            cx="16"
            cy="16"
            fill={inverted ? '#FFFFFF' : undefined}
            r="16"
          />

          {status === Status.Todo && (
            <circle cx="16" cy="16" fill="#FFFFFF" r="14" />
          )}

          {(status === Status.InProgress ||
            (subStatus && subStatus === SubStatusEnums.DataDelivered)) && (
            <g>
              <path d="M30,16 A1,1,0,0,0,2,16" fill="#FFFFFF" />
              <path d="M2,16 A1,1,0,0,0,30,16" />
            </g>
          )}

          {status === Status.Complete && <circle cx="16" cy="16" r="14" />}
        </g>
      </svg>

      {!iconOnly && label}
    </div>
  );
};

StatusIcon.defaultProps = {
  size: 'sm'
};

export default StatusIcon;
