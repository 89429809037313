import { useContext } from 'react';
import { withContext } from 'utils/withContext';
import { DownloadContext, IDownloadContext } from './DownloadContext';

export const DownloadConsumer = DownloadContext.Consumer;

// HOC
export type WithDownload = IDownloadContext;
export const withDownload = withContext(DownloadContext, 'withDownload');

// hook
export const useDownload = () => useContext(DownloadContext);
