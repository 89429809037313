import EventEmitter from 'events';

import React, { FunctionComponent, useMemo, useState } from 'react';

import { MentionsValue } from '@ryan/components';

import CodeNoteDeleteModal from '../../components/CodeNotes/CodeNoteDeleteModal/CodeNoteDeleteModal';
import CodeNotesDrawer from '../../components/CodeNotesDrawer/CodeNotesDrawer';
import { PermissionService, useUser } from '../../contexts/UserContext';
import { ICodeNote, IDataRequest, Permission } from '../../interfaces';
import ApiService from '../../services/ApiService';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import CodeNotesDrawerContext from './CodeNotesDrawerContext';
import {
  ICanComment,
  IHandleEditCodeNote,
  IHandleEditCodeNoteCancel
} from './utils';

const EMPTY_COMMENT = new MentionsValue();

export const CodeNotesDrawerProvider: FunctionComponent = ({ children }) => {
  const [areCodeNotesLoading, setAreCodeNotesLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [dataRequest, setDataRequest] = useState<IDataRequest | null>(null);
  const [codeNotes, setCodeNotes] = useState<ICodeNote[] | null>(null);
  const [codeNoteEdited, setCodeNoteEdited] = useState<ICodeNote | null>(null);
  const [codeNoteEditedText, setCodeNoteEditedText] = useState<
    MentionsValue | string
  >(EMPTY_COMMENT);
  const [isCodeNoteLoading, setIsCodeNoteLoading] = useState(false);
  const [deleteCodeNote, setDeleteCodeNote] = useState<ICodeNote | null>(null);

  const { isAppReadOnly, permissionService } = useUser();

  const events = useMemo(() => new EventEmitter(), []);

  const canComment: ICanComment = ({ isReadOnly, permission, ps, request }) => {
    return (
      request !== null &&
      !request.isEngagementReadOnly &&
      !isReadOnly &&
      ps.hasPermission(permission)
    );
  };

  const canView = (permission: Permission, ps: PermissionService): boolean =>
    ps.hasPermission(permission);

  const isCodeNoteChangeable = canComment({
    isReadOnly: isAppReadOnly,
    permission: Permission.DataRequestsContribute,
    ps: permissionService,
    request: dataRequest!
  });

  const getMentionCodeNoteValue = (codeNote: ICodeNote): MentionsValue => {
    return new MentionsValue({
      text: codeNote.noteText,
      users: codeNote.noteTaggedUsers.map(user => ({
        id: user.userGuid,
        display: user.fullName
      }))
    });
  };

  const handleEditCodeNote: IHandleEditCodeNote = ({
    codeNoteToEdit,
    setCodeNoteEditedCallback,
    setCodeNoteEditedTextCallback,
    text
  }) => {
    if (isCodeNoteChangeable && codeNoteToEdit) {
      setCodeNoteEditedCallback(codeNoteToEdit);
      setCodeNoteEditedTextCallback(text);
    }
  };

  const handleEditCodeNoteCancel: IHandleEditCodeNoteCancel = ({
    setCodeNoteEditedCallback,
    setCodeNoteEditedTextCallback,
    text
  }) => {
    setCodeNoteEditedCallback(null);
    setCodeNoteEditedTextCallback(text);
  };

  const handleEditCodeNoteSave = async (
    editedCodeNote: MentionsValue | string
  ) => {
    if (isCodeNoteChangeable && editedCodeNote && dataRequest) {
      setIsCodeNoteLoading(true);
      try {
        const { data } = await ApiService.updateDataRequestCodeNotes(
          dataRequest.engagementGuid,
          codeNoteEdited!.noteGuid,
          editedCodeNote
        );
        const updatedCodeNotes = (codeNotes || []).map(note =>
          note.noteGuid === codeNoteEdited?.noteGuid ? data : note
        );

        setCodeNotes(updatedCodeNotes);
        setCodeNoteEdited(null);
        setCodeNoteEditedText(EMPTY_COMMENT);
      } catch {
        pushServerErrorToast();
      }
      setIsCodeNoteLoading(false);
    }
  };

  const handleNewCodeNoteSave = async (newNoteText: MentionsValue | string) => {
    if (isCodeNoteChangeable && newNoteText && dataRequest) {
      try {
        const { data } = await ApiService.createDataRequestCodeNotes(
          dataRequest.engagementGuid,
          dataRequest.queueItemGuid,
          newNoteText
        );
        setCodeNotes([data, ...(codeNotes || [])]);
        setDeleteCodeNote(null);
        events.emit('noteAdded');
      } catch {
        pushServerErrorToast();
      }
    }
  };

  const handleDeleteCodeNote = async () => {
    if (isCodeNoteChangeable && dataRequest && deleteCodeNote) {
      try {
        await ApiService.deleteDataRequestCodeNote(
          dataRequest.engagementGuid,
          deleteCodeNote.noteGuid
        );
        const updatedCodeNotes = (codeNotes || []).filter(
          (codeNote: ICodeNote) =>
            codeNote.noteGuid !== deleteCodeNote?.noteGuid
        );
        setCodeNotes(updatedCodeNotes);
        setDeleteCodeNote(null);
        events.emit('noteRemoved');
      } catch {
        pushServerErrorToast();
      }
    }
  };

  const handleOpen = async (request: IDataRequest) => {
    setIsOpened(true);
    setAreCodeNotesLoading(true);
    setDataRequest(request);
    if (canView(Permission.DataRequestsView, permissionService)) {
      try {
        const { data } = await ApiService.getDataRequestCodeNotes(
          request.engagementGuid,
          request.queueItemGuid
        );
        setCodeNotes([...data]);
      } catch {
        pushServerErrorToast();
      } finally {
        setAreCodeNotesLoading(false);
      }
    }
  };

  return (
    <>
      <CodeNotesDrawerContext.Provider
        value={{
          codeNotesDrawerEvents: events,
          onCodeNotesDrawerOpen: handleOpen
        }}
      >
        {children}
      </CodeNotesDrawerContext.Provider>

      {isOpened && (
        <CodeNotesDrawer
          canComment={isCodeNoteChangeable}
          codeNotes={codeNotes}
          dataRequest={dataRequest}
          editCodeNote={codeNoteEdited}
          editCodeNoteText={codeNoteEditedText}
          isCodeNotesLoading={areCodeNotesLoading}
          isEditCodeNoteLoading={isCodeNoteLoading}
          isNewCodeNoteLoading={isCodeNoteLoading}
          onClose={() => {
            setIsOpened(false);
            setCodeNotes(null);
            setCodeNoteEdited(null);
          }}
          onDeleteCodeNote={deleteCodeNote => setDeleteCodeNote(deleteCodeNote)}
          onEditCodeNote={note =>
            handleEditCodeNote({
              codeNoteToEdit: note,
              setCodeNoteEditedCallback: setCodeNoteEdited,
              setCodeNoteEditedTextCallback: setCodeNoteEditedText,
              text: getMentionCodeNoteValue(note)
            })
          }
          onEditCodeNoteCancel={(note: string) =>
            handleEditCodeNoteCancel({
              setCodeNoteEditedCallback: setCodeNoteEdited,
              setCodeNoteEditedTextCallback: setCodeNoteEditedText,
              text: note
            })
          }
          onEditCodeNoteSave={edited => handleEditCodeNoteSave(edited)}
          onNewCodeCodeSave={note => handleNewCodeNoteSave(note)}
        />
      )}
      {deleteCodeNote && (
        <CodeNoteDeleteModal
          onCancel={() => setDeleteCodeNote(null)}
          onConfirm={handleDeleteCodeNote}
        />
      )}
    </>
  );
};

export default CodeNotesDrawerProvider;
