import Table from 'components/Table';
import UserDetails from 'components/UserDetails/UserDetails';
import { WithUser, withUser } from 'contexts/UserContext';
import { ITableState, ITeamSearchParams, IUser, UserType } from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import { formatDate } from 'utils/formatDate';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  EButtonSizes,
  EButtonVariant,
  Icon,
  makeTableCheckboxFilter
} from '@ryan/components';

import DeactivateUserModal from '../../components/Modal/DeactivateUserModal/DeactivateUserModal';
import UserNameAndProfile from '../../components/Table/UserNameAndProfile/UserNameAndProfile';
import { formatToUserNameAndProfile } from '../../components/Table/UserNameAndProfile/utils';
import { mapUserToUserDetailsUserData } from '../../components/UserDetails/utils';
import * as GetUserInfoUtil from '../../utils/getUserInfo/getUserInfo';
import NewUserButton from './NewUserButton';

import './ManageTeamUsers.scss';

function paramToUserType(userTypeParam: string): UserType {
  switch (userTypeParam) {
    case 'client':
      return UserType.Client;
    case 'ryan':
      return UserType.Ryan;
    case 'third-party':
      return UserType.ThirdParty;
    default:
      throw new TypeError(`Unknown userType parameter: ${userTypeParam}`);
  }
}

interface IManageTeamUsersProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps<{ userType: string; previousPath: string }> {
  // ...
}

interface IManageTeamUsersState extends ITableState {
  users: IUser[];
  deactivateModalUser: IUser | null;
}

type IManageTeamUsersInboundLocationState = {
  previousPath?: string;
};

class ManageTeamUsers extends Component<
  IManageTeamUsersProps,
  IManageTeamUsersState
> {
  private usersCancelToken?: CancelTokenSource;
  private _isMounted = false;

  private columns: any[];

  constructor(props: IManageTeamUsersProps) {
    super(props);
    const { t, location } = props;

    const prevPath = (location.state as IManageTeamUsersInboundLocationState)
      ?.previousPath;

    this.state = {
      loading: true,
      users: [],
      searchQuery: '',
      sorted: {
        id: 'firstName',
        desc: false
      },
      filtered: { activeStatus: [] },
      expanded: {},
      page: 1,
      pageSize: 10,
      totalCount: 0,

      deactivateModalUser: null
    };

    this.columns = [
      {
        id: 'firstName',
        label: t('manageTeam.columns.nameAndEmail'),
        render: (row: IUser) => (
          <UserNameAndProfile
            isLinkable
            user={formatToUserNameAndProfile({
              additionalInfoKey: 'email',
              user: row
            })}
          />
        ),
        sortable: true
      },
      {
        id: 'title',
        label: t('manageTeam.columns.title'),
        render: 'title'
      },
      {
        id: 'roleName',
        label: t('Role'),
        render: 'roleName'
      },
      {
        id: 'projectCount',
        label: t('manageTeam.columns.projects'),
        render: 'projectCount'
      },
      {
        id: 'activeStatus',
        label: t('manageTeam.columns.status'),
        render: (row: IUser) =>
          row.activeStatus === 1 ? t('Active') : t('Inactive'),
        filter: makeTableCheckboxFilter([
          { value: '1', label: t('Active') },
          { value: '0', label: t('Inactive') }
        ]),
        filterActive: (value: string[]) => value.length > 0
      },
      {
        id: 'loginDate',
        label: t('manageTeam.columns.lastActive'),
        sortable: true,
        render: (row: IUser) =>
          row.loginDate ? formatDate(row.loginDate) : row.loginDate
      },
      {
        id: 'edit',
        label: '', // t('manageTeam.columns.edit'),
        align: 'center',
        render: (row: IUser) => {
          const { isAppReadOnly, permissionService: ps } = this.props;

          // do not allow editing in read-only mode or allow non-super admin
          // users to edit themselves
          if (
            isAppReadOnly ||
            (ps.isUser(row.userGuid) && !ps.isSuperAdmin())
          ) {
            return null;
          }

          if (ps.canEditUsersOnSameAccount() && row.canCurrentUserEdit) {
            // Ryan users can edit people
            if (ps.isRyan()) {
              const { queueItemGuid, userGuid } = row;
              const hasExistingGuid = queueItemGuid || userGuid;

              let linkTo = '/app/team/review-new-user';
              if (hasExistingGuid) {
                linkTo = userGuid
                  ? `/app/team/edit-user/${userGuid}`
                  : `${linkTo}/${queueItemGuid}`;
              }

              return (
                <div data-qid="edit-user">
                  <Link
                    className="manage-team-users__edit-user"
                    onClick={() => {
                      if (prevPath) {
                        const from = prevPath.includes('team/client')
                          ? 'manage-team big-team'
                          : null;
                        AmplitudeApiService.logEvent('edit-user-access', {
                          'edit-user-access-path': from
                        });
                      } else {
                        AmplitudeApiService.logEvent('edit-user-access', {
                          'edit-user-access-path':
                            'manage-team single-project-team'
                        });
                      }
                    }}
                    to={{
                      ...(!hasExistingGuid && {
                        state: {
                          previousPath: window.location.pathname,
                          newUserRequest: {
                            accountGuid: row.accountGuid,
                            accountName: row.company,
                            activateDate: row.activateDate,
                            displayLocation: row.displayLocation || null,
                            email: row.email,
                            hasDxpAccess: row.hasDxpAccess,
                            firstName: row.firstName,
                            fullName: row.fullName,
                            lastName: row.lastName,
                            mailingAddress: row.mailingAddress || null,
                            minimumNewUserRequestDate:
                              row.minimumNewUserRequestDate || null,
                            mobilePhone: row.mobilePhone || null,
                            officeAddress: row.officeAddress || null,
                            officePhone: row.officePhone || null,
                            roleGuid: row.roleGuid || null,
                            roleName: row.roleName || null,
                            status: null,
                            title: row.title || null,
                            userGuid: row.memberGuid,
                            userType: row.userTypeId
                          }
                        }
                      }),
                      pathname: linkTo
                    }}
                  >
                    <Icon name="pencil" />
                  </Link>
                </div>
              );
            }

            // ClientAdmin can only Deactivate Client users.
            if (
              row.userTypeId === UserType.Client &&
              !this.isUserDeactivatedOrHasNeverBeenActivated(
                row.activateDate,
                row.deactivateDate
              )
            ) {
              return (
                <button
                  className="manage-team-users__deactivate"
                  onClick={() => this.handleDeactivateUser(row)}
                >
                  {t('Deactivate')}
                </button>
              );
            }
          }

          return null;
        }
      }
    ];
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchUsers();
    this.handleColumns(this.props.match.params.userType);
  }

  componentDidUpdate(prevProps: IManageTeamUsersProps) {
    const prevUserType = prevProps.match.params.userType;
    const nextUserType = this.props.match.params.userType;

    if (
      prevUserType !== nextUserType ||
      switcherDidUpdate(prevProps, this.props)
    ) {
      this.setState({ filtered: { activeStatus: [] } });
      this.fetchUsers({ page: 1, users: [], totalCount: 0, searchQuery: '' });
      this.handleColumns(nextUserType);
    }
  }

  componentWillUnmount() {
    this.usersCancelToken?.cancel();
    this._isMounted = false;
  }

  handleColumns(userType: string) {
    const { t } = this.props;

    // We want to format the tables depending on userType in params
    const companyIndex = this.columns.map(x => x.id).indexOf('company');

    // remove company column for Ryan users and add for non-Ryan users
    if (userType === 'ryan' && companyIndex > -1) {
      this.columns.splice(companyIndex, 1);
    } else if (userType !== 'ryan' && companyIndex === -1) {
      this.columns.splice(2, 0, {
        id: 'company',
        label: t('Company'),
        sortable: true,
        render: 'company'
      });
    }
  }

  isUserDeactivatedOrHasNeverBeenActivated(
    activateDateTimestamp: string | null,
    deactivateDateTimestamp: string | null
  ) {
    return (
      activateDateTimestamp === null ||
      (deactivateDateTimestamp !== null &&
        deactivateDateTimestamp > activateDateTimestamp)
    );
  }

  fetchUsers(updates?: Partial<IManageTeamUsersState>) {
    this.setState(
      { ...(updates as IManageTeamUsersState), loading: true },
      async () => {
        const { activeView, match } = this.props;
        const { page, searchQuery, filtered, sorted, pageSize } = this.state;
        const params: ITeamSearchParams = {
          pageNumber: page,
          userType: paramToUserType(match.params.userType),
          searchTerm: searchQuery,
          activeStatus:
            filtered!.activeStatus.length === 1
              ? filtered!.activeStatus[0]
              : '',
          sort: getSortParam(sorted),
          itemsPerPage: pageSize
        };

        try {
          this.usersCancelToken?.cancel();
          this.usersCancelToken = ApiService.CancelToken.source();

          const request = ApiService.getTeamUsersToManageByCustomView(
            activeView.customViewGuid,
            params,
            this.usersCancelToken.token
          );

          const {
            data: { results, totalResults }
          } = await request;

          this.setState({
            loading: false,
            totalCount: totalResults,
            users: results
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();

            if (this._isMounted) {
              this.setState({ loading: false });
            }
          }
        }
      }
    );
  }

  handleViewUser = (user: IUser) => {
    const { history, match } = this.props;
    history.push(`/app/personal-information/${user.userGuid}`, {
      pushFrom: [
        {
          route: `/app/team/${match.params.userType}`,
          title: 'Team'
        }
      ]
    });
  };

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchUsers({ page: 1, searchQuery });
    }
  );

  handlePage = (page: number, pageSize: number) => {
    this.fetchUsers({ page, pageSize });
  };

  handleSort = (sorted: any) => {
    this.fetchUsers({ sorted });
  };

  handleFilter = (filtered: Record<string, unknown>) => {
    this.fetchUsers({ filtered, page: 1 });
  };

  handleToggleExpansion = (isExpanded: boolean, row: IUser, rowId: string) => {
    this.setState(state => ({
      expanded: { ...state.expanded, [rowId]: isExpanded }
    }));
  };

  handleDeactivateUser(user: IUser) {
    this.setState({ deactivateModalUser: user });
  }

  handleDeactivateUserClose = (isDeactivated = false) => {
    if (isDeactivated) {
      this.fetchUsers();
    }
    this.setState({ deactivateModalUser: null });
  };

  render() {
    const { permissionService: ps, match, t } = this.props;
    const {
      loading,
      users,
      searchQuery,
      sorted,
      filtered,
      expanded,
      page,
      pageSize,
      totalCount,
      deactivateModalUser
    } = this.state;

    // update table title based on user type
    const userType = paramToUserType(match.params.userType);
    let tableTitle: string = t('Third Party');

    if (userType === UserType.Ryan) {
      tableTitle = t(ps.isRyan() ? 'My Team' : 'Ryan Team');
    } else if (userType === UserType.Client) {
      tableTitle = t(ps.isClient() ? 'My Team' : 'Client Team');
    }

    return (
      <div className="manage-team-users" data-qid="manage-team-users">
        <Table<IUser, string>
          columns={this.columns}
          data={users}
          expanded={expanded}
          filtered={filtered}
          isLoadedResetOnDataChange
          loading={loading}
          onFilterChange={this.handleFilter}
          onPageChange={this.handlePage}
          onSearchChange={this.handleSearch}
          onSortChange={this.handleSort}
          onToggleExpansion={this.handleToggleExpansion}
          page={page}
          pageSize={pageSize}
          renderActionPlacement={1}
          renderActions={this.renderActions}
          renderEmpty={this.renderEmpty}
          renderExpandedRow={this.renderExpandedRow}
          rowId="memberGuid"
          searchQuery={searchQuery}
          sorted={sorted}
          title={tableTitle}
          totalCount={totalCount}
        />

        {deactivateModalUser && (
          <DeactivateUserModal
            onClose={this.handleDeactivateUserClose}
            userFullName={GetUserInfoUtil.getFullName(deactivateModalUser)}
            userGuid={deactivateModalUser.userGuid}
          />
        )}
      </div>
    );
  }

  renderActions = () => {
    const { userType } = this.props.match.params;
    const isActiveUserTypeRyan = this.props.permissionService.isRyan();

    if (
      !isActiveUserTypeRyan &&
      (userType === 'client' || userType === 'third-party')
    ) {
      return (
        <NewUserButton
          size={EButtonSizes.SMALL}
          variant={EButtonVariant.TEXT}
        />
      );
    }

    return null;
  };

  renderEmpty = () => {
    const { t, match, permissionService: ps, activeView } = this.props;
    const { searchQuery } = this.state;
    const viewName = activeView.name;

    return (
      <div className="table-empty">
        <Icon className="table-empty__icon" name="users" />
        {searchQuery ? (
          <div className="table-empty__content">
            <Trans i18nKey="Search Not Found">
              <b>{{ searchQuery }}</b> could not be found.
            </Trans>
            <br />
            {t('manageTeam.adjustSearch')}
          </div>
        ) : (
          <div className="table-empty__content">
            {t(`manageTeam.empty.${match.params.userType}`, { viewName })}
          </div>
        )}
        <div>
          <br />
          {ps.isClient() && (
            <>
              {t('manageTeam.emptyWithNewUserRequest')}
              <br />
              <NewUserButton variant={EButtonVariant.PRIMARY} />
            </>
          )}
        </div>
      </div>
    );
  };

  renderExpandedRow = (user: IUser) => {
    const { activeView } = this.props;
    return (
      <UserDetails
        customViewGuid={activeView.customViewGuid}
        onViewAll={() => this.handleViewUser(user)}
        userData={mapUserToUserDetailsUserData(user)}
      />
    );
  };
}

export default withTranslation()(withUser(ManageTeamUsers));
