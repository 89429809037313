import classNames from 'classnames';
import React, { FunctionComponent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip, autocompleteHighlight } from '@ryan/components';

import { withUser } from '../../../contexts/UserContext';
import { IFolder } from '../../../interfaces';
import FileLink from '../../FileLink';
import mapDirectoryItem from '../utils/mapDirectoryItem';
import { IFileDirectorySearchResultProps } from './utils';

import './FileDirectorySearchResult.scss';

const FileDirectorySearchResult: FunctionComponent<
  IFileDirectorySearchResultProps
> = ({
  accountName,
  activeView,
  directoryItem,
  engagementName,
  isUserGhosted,
  onFolderClick,
  searchQuery
}) => {
  const { t } = useTranslation();
  const { parentFolder } = directoryItem;

  return (
    <div className="file-directory-search-result">
      {mapDirectoryItem({
        directoryItem,
        handleMapForFolder: folder => (
          <div
            className={classNames('file-directory-search-result__folder', {
              'file-directory-search-result__folder--archived': Boolean(
                folder.archiveDate
              )
            })}
          >
            <button
              className="bs bs--light ry-link"
              onClick={() => onFolderClick(folder.folderGuid)}
              type="button"
            >
              <b>{autocompleteHighlight(folder.folderName, searchQuery)}</b>
            </button>
            {folder.archiveDate && (
              <div className="pill archived">{t('Archived')}</div>
            )}
          </div>
        ),
        handleMapForFile: file => (
          <FileLink
            engagementGuid={file.engagementGuid}
            file={file}
            fileDescription={file.friendlyName ? file.documentName : undefined}
            fileName={autocompleteHighlight(file.displayName, searchQuery)}
            isDownloadingDisabled={isUserGhosted || activeView.isExecutiveView}
          />
        )
      })}

      {parentFolder && (
        <Tooltip
          content={
            <div className="file-directory-search-result__tooltip">
              <label className="ry-label">{t('Path')}</label>
              <ul>
                <li>{accountName}</li>
                <li>
                  <Icon name="arrow-nested" />
                  {engagementName}
                </li>
                {parentFolder.path.map((folder: IFolder, i: number) => (
                  <li
                    key={folder.folderGuid}
                    style={{ marginLeft: `${(i + 1) * 1.5}rem` }}
                  >
                    <Icon name="arrow-nested" />
                    {folder.folderName}
                  </li>
                ))}
              </ul>
            </div>
          }
          placement="bottom-start"
          renderTarget={({ open, ...tooltipProps }) => (
            <button
              {...tooltipProps}
              className="ry-link file-directory-search-result__tooltip-target"
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                onFolderClick(parentFolder.element.folderGuid);
              }}
              type="button"
            >
              {parentFolder.element.folderName}
            </button>
          )}
        />
      )}
    </div>
  );
};

export default withUser(FileDirectorySearchResult);
