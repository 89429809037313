import { RouteProps } from 'react-router-dom';

export interface Tab extends RouteProps {
  to?: string;
  path: string | string[];
  label: string;
  enabled?: boolean;
}

export function getTabKey(tab: Tab) {
  return tab.to || (Array.isArray(tab.path) ? tab.path[0] : tab.path);
}
