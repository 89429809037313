import { useUser } from 'contexts/UserContext';
import { Permission } from 'interfaces';

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Card } from '@ryan/components';

import ProjectSnapshotAssignments from './ProjectSnapshotAssignments/ProjectSnapshotAssignments';
import ProjectSnapshotOverview from './ProjectSnapshotOverview/ProjectSnapshotOverview';
import ProjectSnapshotSavings from './ProjectSnapshotSavings/ProjectSnapshotSavings';
import { IHandleView, IProjectSnapshotProps } from './utils';

import './ProjectSnapshot.scss';

const ProjectSnapshot: React.FC<IProjectSnapshotProps> = ({ engagement }) => {
  const history = useHistory();
  const { permissionService } = useUser();

  const { engagementGuid } = engagement;

  const handleView: IHandleView = (projectEngagementGuid, urlPath) => {
    history.push(`/app/project/${projectEngagementGuid}/${urlPath}`);
  };

  return (
    <Card className="project-snapshot">
      <div className="row">
        <div className="col-12 col-md-6 project-snapshot__overview">
          <ProjectSnapshotOverview
            engagement={engagement}
            handleViewProject={() => handleView(engagementGuid, 'overview')}
          />
        </div>
        <div className="col-12 col-md-6 project-snapshot__highlights">
          <div className="row">
            <div className="col-12">
              {permissionService.hasPermission(Permission.DataRequestsView) && (
                <ProjectSnapshotAssignments
                  engagementGuid={engagementGuid}
                  handleViewAssignments={() =>
                    handleView(engagementGuid, 'data-requests')
                  }
                  icon={'file'}
                  title={'dataRequests'}
                />
              )}
            </div>

            <div className="col-12">
              {permissionService.hasPermission(Permission.TasksView) && (
                <ProjectSnapshotAssignments
                  engagementGuid={engagementGuid}
                  handleViewAssignments={() =>
                    handleView(engagementGuid, 'tasks')
                  }
                  icon={'clipboard'}
                  title={'tasks'}
                />
              )}
            </div>

            <div className="col-12">
              {permissionService.hasPermission(
                Permission.SavingsSummaryView
              ) && (
                <ProjectSnapshotSavings
                  engagementGuid={engagementGuid}
                  handleViewSavings={() =>
                    handleView(engagementGuid, 'savings-history')
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProjectSnapshot;
