import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableProps, Icon, Table as RyanTable } from '@ryan/components';

export type { ITableProps } from '@ryan/components';

const Table = <T, K extends React.Key = React.Key>({
  collapseText,
  expandText,
  pageSizeLabel,
  renderEmpty,
  searchPlaceholder,
  ...props
}: PropsWithChildren<ITableProps<T, K>>) => {
  const { t } = useTranslation();
  return (
    <RyanTable<T, K>
      collapseText={collapseText ?? t('table.collapseRowAriaLabel')}
      expandText={expandText ?? t('table.expandRowAriaLabel')}
      pageSizeLabel={pageSizeLabel ?? t('table.pageSizeLabel')}
      renderEmpty={
        renderEmpty ??
        (() => (
          <div className="ry-table__empty">
            <Icon name="null" />
            <div>{t('table.emptyState')}</div>
          </div>
        ))
      }
      searchPlaceholder={searchPlaceholder ?? t('Search')}
      {...props}
    />
  );
};

export default Table;
