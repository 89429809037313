import classNames from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Avatar, Checkbox, Icon, TextInput } from '@ryan/components';

import getAvatarUrl from '../../../utils/getAvatarUrl';
import Empty from '../../Empty';
import {
  EngagementPersonnelFilter,
  FilterOptions,
  JurisdictionFilter,
  PracticeAreaFilter,
  ProjectDataType,
  ProjectFilter
} from '../interfaces';

interface AdditionalFilterSelectProps extends WithTranslation {
  availableFilters: FilterOptions;
  dataTypeFilter: ProjectDataType;
  isDropdownShown: boolean;
  onDeselectAll: (filters: ProjectFilter[], dataType: ProjectDataType) => void;
  onFilterSelect: (filter: ProjectFilter, dataType: ProjectDataType) => void;
  onSelectAll: (filters: ProjectFilter[], dataType: ProjectDataType) => void;
  selectedFilters: FilterOptions;
  setIsDropdownShown: (value: boolean) => void;
}

const AdditionalFilterSelect: FunctionComponent<
  AdditionalFilterSelectProps
> = ({
  availableFilters,
  dataTypeFilter,
  isDropdownShown,
  onDeselectAll,
  onFilterSelect,
  onSelectAll,
  selectedFilters,
  setIsDropdownShown,
  t: getTextToDisplay
}) => {
  const [filteredOptions, setFilteredOptions] = useState<ProjectFilter[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectFilters, setSelectFilters] = useState<ProjectFilter[]>([]);
  const advancedFilterSelectRef = useRef<HTMLDivElement>(null);
  const resetSearchTerm = () => setSearchTerm('');

  const textInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Side Effects

  useEffect(() => {
    setSelectAllChecked(false);
    setSearchTerm('');
  }, [dataTypeFilter]);

  useEffect(() => {
    switch (dataTypeFilter) {
      case ProjectDataType.AccountHandler:
        const { accountHandlers } = availableFilters;
        const { accountHandlers: selectedAccountHandlers } = selectedFilters;
        const filteredAccountHandlers = (accountHandlers || []).filter(pa => {
          const term = searchTerm ?? '';
          if (term !== '') {
            return pa?.fullName.toLowerCase().includes(term.toLowerCase());
          } else {
            return true;
          }
        });
        setFilteredOptions(filteredAccountHandlers);
        setSelectFilters(selectedAccountHandlers);
        return;
      case ProjectDataType.EngagementManager:
        const { engagementManagers } = availableFilters;
        const { engagementManagers: selectedEngagementManagers } =
          selectedFilters;
        const filteredManagers = engagementManagers.filter(pa => {
          if (searchTerm !== '') {
            return pa.fullName.toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return true;
          }
        });
        setFilteredOptions(filteredManagers);
        setSelectFilters(selectedEngagementManagers);
        return;
      case ProjectDataType.EngagementPrincipal:
        const { engagementPrincipals } = availableFilters;
        const { engagementPrincipals: selectedEngagementPrincipals } =
          selectedFilters;
        const filteredPrincipals = engagementPrincipals.filter(pa => {
          if (searchTerm !== '') {
            return pa.fullName.toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return true;
          }
        });
        setFilteredOptions(filteredPrincipals);
        setSelectFilters(selectedEngagementPrincipals);
        return;
      case ProjectDataType.Jurisdiction:
        const { jurisdictions } = availableFilters;
        const { jurisdictions: selectedJurisdiction } = selectedFilters;
        const filterJurisdictions = jurisdictions.filter(pa => {
          if (searchTerm !== '') {
            return pa.name.toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return true;
          }
        });
        setFilteredOptions(filterJurisdictions);
        setSelectFilters(selectedJurisdiction);
        return;
      case ProjectDataType.PracticeArea:
        const { practiceAreas } = availableFilters;
        const { practiceAreas: selectedPracticeAreas } = selectedFilters;
        const filteredPracticeAreas = practiceAreas.filter(pa => {
          if (searchTerm !== '') {
            return pa.name.toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return true;
          }
        });
        setFilteredOptions(filteredPracticeAreas);
        setSelectFilters(selectedPracticeAreas);
        return;
    }
  }, [selectedFilters, dataTypeFilter, availableFilters, searchTerm]);

  useEffect(() => {
    if (advancedFilterSelectRef.current && isDropdownShown) {
      advancedFilterSelectRef.current.scrollTop = 0;
    }
  }, [dataTypeFilter, isDropdownShown]);

  const getFilterName = (filter: ProjectFilter) => {
    if ((filter as EngagementPersonnelFilter).fullName)
      return (filter as EngagementPersonnelFilter).fullName;
    if ((filter as JurisdictionFilter).name)
      return (filter as JurisdictionFilter).name;
    if ((filter as PracticeAreaFilter).name)
      return (filter as PracticeAreaFilter).name;
    if (typeof filter === 'string') return filter;
    return '';
  };

  const getDisplayText = (
    selectedFilterOptions: FilterOptions,
    dataType: ProjectDataType
  ) => {
    let selectedFilters: ProjectFilter[] = [];

    switch (dataType) {
      case ProjectDataType.AccountHandler:
        selectedFilters = selectedFilterOptions.accountHandlers;
        break;
      case ProjectDataType.EngagementManager:
        selectedFilters = selectedFilterOptions.engagementManagers;
        break;
      case ProjectDataType.EngagementPrincipal:
        selectedFilters = selectedFilterOptions.engagementPrincipals;
        break;
      case ProjectDataType.Jurisdiction:
        selectedFilters = selectedFilterOptions.jurisdictions;
        break;
      case ProjectDataType.PracticeArea:
        selectedFilters = selectedFilterOptions.practiceAreas;
        break;
    }

    if (!selectedFilters.length || !selectFilters.length)
      return getTextToDisplay('Select');
    if (selectedFilters.length === 1) return getFilterName(selectedFilters[0]);
    if (selectedFilters.length === 2)
      return `${getFilterName(selectedFilters[0])}, ${getFilterName(
        selectedFilters[1]
      )}`;
    return getTextToDisplay('advancedFilterModal.numberOfItemsSelected', {
      numItems: selectedFilters.length
    });
  };

  const isPartialSelectionFor = (dataType: ProjectDataType) => {
    switch (dataType) {
      case ProjectDataType.AccountHandler:
        const filteredAH = filteredOptions as EngagementPersonnelFilter[];
        const selectedAH = selectedFilters.accountHandlers;
        return (
          filteredAH.filter(e =>
            selectedAH.some(s => s.userGuid === e.userGuid)
          ).length > 0
        );
      case ProjectDataType.EngagementManager:
        const filteredEM = filteredOptions as EngagementPersonnelFilter[];
        const selectedEM = selectedFilters.engagementManagers;
        return (
          filteredEM.filter(e =>
            selectedEM.some(s => s.userGuid === e.userGuid)
          ).length > 0
        );
      case ProjectDataType.EngagementPrincipal:
        const filteredEngagementPersonnel =
          filteredOptions as EngagementPersonnelFilter[];
        const selectedEngagementPersonnel =
          selectedFilters.engagementPrincipals;
        return (
          filteredEngagementPersonnel.filter(e =>
            selectedEngagementPersonnel.some(s => s.userGuid === e.userGuid)
          ).length > 0
        );
      case ProjectDataType.Jurisdiction:
        const filteredJ = filteredOptions as JurisdictionFilter[];
        const selectedJ = selectedFilters.jurisdictions;
        return (
          filteredJ.filter(e =>
            selectedJ.some(s => s.jurisdictionGuid === e.jurisdictionGuid)
          ).length > 0
        );
      case ProjectDataType.PracticeArea:
        const filteredPA = filteredOptions as PracticeAreaFilter[];
        const selectedPA = selectedFilters.practiceAreas;
        return (
          filteredPA.filter(e =>
            selectedPA.some(s => s.practiceAreaGuid === e.practiceAreaGuid)
          ).length > 0
        );
    }
  };

  const filteredSelectionsIncludes = useCallback(
    (option: ProjectFilter): boolean => {
      switch (dataTypeFilter) {
        case ProjectDataType.AccountHandler:
          return selectedFilters.accountHandlers.some(
            i => i.userGuid === (option as EngagementPersonnelFilter).userGuid
          );
        case ProjectDataType.EngagementManager:
          return selectedFilters.engagementManagers.some(
            i => i.userGuid === (option as EngagementPersonnelFilter).userGuid
          );
        case ProjectDataType.EngagementPrincipal:
          return selectedFilters.engagementPrincipals.some(
            i => i.userGuid === (option as EngagementPersonnelFilter).userGuid
          );
        case ProjectDataType.Jurisdiction:
          return selectedFilters.jurisdictions.some(
            i =>
              i.jurisdictionGuid ===
              (option as JurisdictionFilter).jurisdictionGuid
          );
        case ProjectDataType.PracticeArea:
          return selectedFilters.practiceAreas.some(
            i =>
              i.practiceAreaGuid ===
              (option as PracticeAreaFilter).practiceAreaGuid
          );
      }
    },
    [dataTypeFilter, selectedFilters]
  );

  useEffect(() => {
    const allOptionsSelected = filteredOptions.every(option =>
      filteredSelectionsIncludes(option)
    );
    setSelectAllChecked(allOptionsSelected);
  }, [filteredOptions, filteredSelectionsIncludes, selectFilters]);

  const selectAll = (filters: ProjectFilter[]) => {
    setSelectAllChecked(true);
    onSelectAll(filters, dataTypeFilter);
  };

  const deselectAll = (filters: ProjectFilter[]) => {
    setSelectAllChecked(false);
    onDeselectAll(filters, dataTypeFilter);
  };

  const renderLabel = (option: ProjectFilter) => {
    switch (dataTypeFilter) {
      case ProjectDataType.AccountHandler:
      case ProjectDataType.EngagementPrincipal:
      case ProjectDataType.EngagementManager:
        const {
          avatarUrl,
          firstName,
          fullName,
          lastName,
          userAvatarDocumentGuid
        } = option as EngagementPersonnelFilter;

        return (
          <>
            <Avatar
              firstName={firstName}
              lastName={lastName}
              profileImageSrc={getAvatarUrl({
                avatarUrl,
                userAvatarDocumentGuid
              })}
            />
            <h4 style={{ paddingLeft: 10 }}>{fullName}</h4>
          </>
        );
      case ProjectDataType.Jurisdiction:
        const jurisdiction = option as JurisdictionFilter;
        return <h4>{jurisdiction.name}</h4>;
      case ProjectDataType.PracticeArea:
        const practiceArea = option as PracticeAreaFilter;
        return <h4>{practiceArea.name}</h4>;
    }
  };

  const displayText = getDisplayText(selectedFilters, dataTypeFilter);
  const partialOfFiltered = isPartialSelectionFor(dataTypeFilter);

  return (
    <div
      className="filter-select-container"
      data-testid="filter-select-dropdown"
    >
      <div className="ry-label">
        {getTextToDisplay('advancedFilterModal.selectBelow')}
      </div>
      <div
        className="filter-select-container__selection-label"
        onClick={() => setIsDropdownShown(!isDropdownShown)}
        role="button"
      >
        <div
          className={classNames(
            'filter-select-container__selection-label__label',
            {
              'filter-select-container__selection-label--placeholder':
                displayText === getTextToDisplay('Select')
            }
          )}
        >
          {displayText}
        </div>
        <button
          aria-label="show-dropdown-button"
          className="filter-select-container__selection-label__caret"
          onClick={event => {
            event.stopPropagation();
            setIsDropdownShown(!isDropdownShown);
          }}
        >
          <Icon name={isDropdownShown ? 'chevron-up' : 'chevron-down'} />
        </button>
      </div>
      {isDropdownShown && (
        <div
          className="filter-select-container__dropdown"
          ref={advancedFilterSelectRef}
        >
          <TextInput
            disabled={false}
            icon={searchTerm ? 'delete' : 'search'}
            onChange={textInputOnChangeHandler}
            onIconClick={resetSearchTerm}
            placeholder={getTextToDisplay('Search')}
            value={searchTerm}
          />
          <ul>
            {filteredOptions.length > 0 && (
              <>
                <li>
                  <div className="filter-option">
                    <Checkbox
                      checked={selectAllChecked}
                      indeterminate={!selectAllChecked && partialOfFiltered}
                      onChange={() => {
                        if (filteredOptions && filteredOptions.length > 0) {
                          selectAllChecked
                            ? deselectAll(filteredOptions)
                            : selectAll(filteredOptions);
                        }
                      }}
                      value="delete-files"
                    />
                    <h4>{getTextToDisplay('Select All')}</h4>
                  </div>
                </li>
                <hr style={{ margin: 0, backgroundColor: '#e9e9e9' }} />
              </>
            )}

            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => {
                return (
                  <li key={index}>
                    <div className="filter-option">
                      <Checkbox
                        checked={filteredSelectionsIncludes(option)}
                        onChange={() => onFilterSelect(option, dataTypeFilter)}
                        value="delete-files"
                      />
                      {renderLabel(option)}
                    </div>
                  </li>
                );
              })
            ) : (
              <Empty>
                {searchTerm !== '' ? (
                  <p>
                    <Trans i18nKey="Search Not Found">
                      {{ searchQuery: searchTerm }}
                      <b>{getTextToDisplay('Search Not Found')}</b>
                    </Trans>
                  </p>
                ) : null}
              </Empty>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(AdditionalFilterSelect);
