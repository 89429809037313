import Empty from 'components/Empty';
import Table from 'components/Table';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import { IEngagement } from 'interfaces';

import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  ITableColumn,
  Icon
} from '@ryan/components';

import './CustomViewProjectPicker.scss';

interface ICustomViewProjectPickerProps {
  /**
   * Loading status of matching projects list.
   */
  loading?: boolean;

  /**
   * Handler to be called on change to selected projects list.
   */
  onChange?(selectedProjects: IEngagement[]): void;

  /**
   * Handler to be called on sort of matching projects table.
   */
  onSortChange?(sorted: { id: string; desc: boolean } | null): void;

  /**
   * List of matching projects available to be selected by the user.
   */
  projects: IEngagement[];

  /**
   * List of selected projects.
   */
  selected: IEngagement[] | null;

  /**
   * Table sort settings for matching projects table.
   */
  sorted?: { id: string; desc: boolean } | null;
}

/**
 * Empty state of matching projects table.
 */
const EmptyProjectsTable: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <TableEmpty searchQuery="">
    <Empty icon="picklist">{children}</Empty>
  </TableEmpty>
);

/**
 * Generates an array compare function to sort engagements via display name.
 *
 * @param desc Sort in descending order.
 */
const getEngagementNamecompareFunction =
  (desc: boolean) => (a: IEngagement, b: IEngagement) => {
    const nameA = a.engagementDisplayNameLong.toUpperCase();
    const nameB = b.engagementDisplayNameLong.toUpperCase();

    if (nameA < nameB) {
      return desc ? 1 : -1;
    }

    if (nameA > nameB) {
      return desc ? -1 : 1;
    }

    return 0;
  };

const CustomViewProjectPicker: React.FC<ICustomViewProjectPickerProps> = ({
  loading,
  onChange,
  onSortChange,
  projects,
  selected,
  sorted
}) => {
  const { t } = useTranslation();

  // keeps track of initial empty render state
  const [initialEmptyState, setInitialEmptyState] = useState(true);

  // selected items from matching projects table
  const [toBeAdded, setToBeAdded] = useState<React.Key[]>([]);

  // selected items from selected projects table
  const [toBeRemoved, setToBeRemoved] = useState<React.Key[]>([]);

  // the list of selected projects
  const [selectedProjects, setSelectedProjects] = useState<IEngagement[]>(
    selected || []
  );

  // table sort settings for selected projects table
  const [selectedProjectsTableSort, setSelectedProjectsTableSort] = useState({
    id: 'engagementDisplayNameLong',
    desc: false
  });

  // the list of original matching project data with selected projects removed
  const filteredProjects = useMemo(() => {
    const selectedGuids: string[] = selectedProjects.map(
      project => project.engagementGuid
    );
    return projects.filter(
      project => selectedGuids.indexOf(project.engagementGuid) === -1
    );
  }, [projects, selectedProjects]);

  // the current list of selected projects sorted by engagement name
  const selectedProjectsSorted = useMemo(
    () =>
      selectedProjects.sort(
        getEngagementNamecompareFunction(selectedProjectsTableSort.desc)
      ),
    [selectedProjects, selectedProjectsTableSort]
  );

  /**
   * Adds projects to selected projects list. By default will add all selected
   * matching projects.
   */
  const addProjects = useCallback(
    (toAdd?: IEngagement) => {
      const projectsToAdd = toAdd
        ? [toAdd]
        : filteredProjects.filter(
            project => toBeAdded.indexOf(project.engagementGuid) > -1
          );
      setSelectedProjects(prevSelectedProjects => [
        ...prevSelectedProjects,
        ...projectsToAdd
      ]);

      // update list of selections - if single item added, remove that item
      // from list
      setToBeAdded(prevToBeAdded =>
        toAdd ? prevToBeAdded.filter(guid => guid !== toAdd.engagementGuid) : []
      );
    },
    [filteredProjects, toBeAdded]
  );

  /**
   * Removes projects from selected projects list. By default will remove all
   * selected projects that have been selected.
   */
  const removeProjects = useCallback(
    (toRemove?: IEngagement) => {
      setSelectedProjects(prevSelectedProjects =>
        prevSelectedProjects.filter(project =>
          toRemove
            ? project.engagementGuid !== toRemove.engagementGuid
            : toBeRemoved.indexOf(project.engagementGuid) === -1
        )
      );

      // update list of selections - if single item removed, remove that item
      // from list
      setToBeRemoved(prevToBeRemoved =>
        toRemove
          ? prevToBeRemoved.filter(guid => guid !== toRemove.engagementGuid)
          : []
      );
    },
    [toBeRemoved]
  );

  /**
   * Updates sort settings for selected projects table.
   */
  const onSelectedProjectsSortChange = useCallback(
    (sorted: { id: string; desc: boolean } | null): void => {
      if (sorted) {
        setSelectedProjectsTableSort(sorted);
      }
    },
    []
  );

  /**
   * Updates matching project's selection state.
   */
  const onMatchingProjectSelect = useCallback((selected: React.Key[]) => {
    setToBeAdded(selected);
  }, []);

  /**
   * Updates selected project's selection state.
   */
  const onSelectedProjectSelect = useCallback((selected: React.Key[]) => {
    setToBeRemoved(selected);
  }, []);

  /**
   * Returns project tables column configuration.
   */
  const getProjectColumns = useCallback(
    (isMatchingProjects: boolean): ITableColumn<IEngagement>[] => [
      {
        id: 'engagementDisplayNameLong',
        label: t('projectOverview.columns.project'),
        render: row => (
          <>
            <b
              aria-label={t(
                isMatchingProjects
                  ? 'customView.static.matching.rowActionAria'
                  : 'customView.static.selected.rowActionAria',
                {
                  name: row.engagementDisplayNameLong
                }
              )}
              className="custom-view-project-picker__action-project"
              onClick={() =>
                isMatchingProjects ? addProjects(row) : removeProjects(row)
              }
              role="button"
            >
              {row.engagementDisplayNameLong}
            </b>
            <br />
            <small>{row.accountName}</small>
          </>
        ),
        sortable: true
      },
      {
        id: 'action',
        label: '',
        render: row => (
          <Button
            ariaLabel={t(
              isMatchingProjects
                ? 'customView.static.matching.rowActionAria'
                : 'customView.static.selected.rowActionAria',
              {
                name: row.engagementDisplayNameLong
              }
            )}
            className="custom-view-project-picker__action-btn"
            icon={isMatchingProjects ? 'arrow-block-down' : 'close'}
            onClick={() =>
              isMatchingProjects ? addProjects(row) : removeProjects(row)
            }
            variant={EButtonVariant.TEXT}
          />
        )
      }
    ],
    [addProjects, removeProjects, t]
  );

  /**
   * Renders the project selection tables.
   */
  const renderProjectPicker = () => (
    <>
      <Table<IEngagement>
        className={classNames(
          'custom-view-project-picker__project-table',
          'custom-view-project-picker__project-table--matching',
          {
            'custom-view-project-picker__project-table--empty':
              filteredProjects.length === 0
          }
        )}
        columns={getProjectColumns(true)}
        data={filteredProjects}
        loading={loading}
        onSelectChange={onMatchingProjectSelect}
        onSortChange={onSortChange}
        renderActionPlacement={1}
        renderActions={() => (
          <Button
            disabled={toBeAdded.length === 0}
            icon="check"
            onClick={() => addProjects()}
            size={EButtonSizes.SMALL}
            text={t('customView.static.matching.action')}
            variant={EButtonVariant.TEXT}
          />
        )}
        renderEmpty={() => (
          <EmptyProjectsTable>
            {t('customView.static.matching.empty')}
          </EmptyProjectsTable>
        )}
        rowId="engagementGuid"
        selected={toBeAdded}
        sorted={sorted}
        title={
          <Trans
            count={filteredProjects.length}
            i18nKey="customView.static.matching.header"
          >
            <span />
          </Trans>
        }
      />
      <Icon
        aria-hidden
        className="custom-view-project-picker__arrows"
        name="ascend"
      />
      <Table<IEngagement>
        className={classNames(
          'custom-view-project-picker__project-table',
          'custom-view-project-picker__project-table--selected',
          {
            'custom-view-project-picker__project-table--empty':
              selectedProjectsSorted.length === 0
          }
        )}
        columns={getProjectColumns(false)}
        data={selectedProjectsSorted}
        onSelectChange={onSelectedProjectSelect}
        onSortChange={onSelectedProjectsSortChange}
        renderActionPlacement={1}
        renderActions={() => (
          <Button
            disabled={toBeRemoved.length === 0}
            icon="trash"
            negative
            onClick={() => removeProjects()}
            size={EButtonSizes.SMALL}
            text={t('customView.static.selected.action')}
            variant={EButtonVariant.TEXT}
          />
        )}
        renderEmpty={() => (
          <EmptyProjectsTable>
            {t('customView.static.selected.empty')}
          </EmptyProjectsTable>
        )}
        rowId="engagementGuid"
        selected={toBeRemoved}
        sorted={selectedProjectsTableSort}
        title={
          <Trans
            count={selectedProjects.length}
            i18nKey="customView.static.selected.header"
          >
            <span />
          </Trans>
        }
      />
    </>
  );

  // toggles initial empty state status so initial empty indicator no longer
  // renders
  useEffect(() => {
    if (
      initialEmptyState &&
      (projects.length > 0 || selectedProjects.length > 0)
    ) {
      setInitialEmptyState(false);
    }
  }, [initialEmptyState, projects, selectedProjects]);

  // update selected projects state if selected prop updates
  useEffect(() => {
    if (selected) {
      setSelectedProjects(selected);
    }
  }, [selected]);

  // triggers onChange method on updates to selected project list
  useEffect(() => {
    onChange?.(selectedProjects);
  }, [selectedProjects, onChange]);

  return (
    <div className="custom-view-project-picker">
      {initialEmptyState ? (
        <EmptyProjectsTable>
          {t('customView.static.matching.empty')}
        </EmptyProjectsTable>
      ) : (
        renderProjectPicker()
      )}
    </div>
  );
};

export default CustomViewProjectPicker;
