function pad(n: number) {
  const norm = Math.floor(Math.abs(n));
  return (norm < 10 ? '0' : '') + norm;
}

function toISOStringWithTimezone(date: Date) {
  const YYYY = date.getFullYear();
  const MM = pad(date.getMonth() + 1);
  const DD = pad(date.getDate());
  const hh = pad(date.getHours());
  const mm = pad(date.getMinutes());
  const ss = pad(date.getSeconds());

  const tzo = -date.getTimezoneOffset();
  const tz = `${tzo >= 0 ? '+' : '-'}${pad(tzo / 60)}:${pad(tzo % 60)}`;

  return `${YYYY}-${MM}-${DD}T${hh}:${mm}:${ss}${tz}`;
}

// Use this when serializing JSON.
// eslint-disable-next-line no-extend-native
Date.prototype.toISOString = function () {
  return toISOStringWithTimezone(this);
};

export {};
