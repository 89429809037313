import { CancelToken } from 'axios';
import {
  IInvoice,
  IInvoicesSearch,
  IPagedDataResponse,
  ISearchTerm
} from 'interfaces';
import { transformResponse } from 'utils/transformResponse';
import ApiBase from './ApiBase';

/**
 * Get a list of invoices by custom view
 */
export async function getInvoicesByCustomView(
  this: ApiBase,
  customViewGuid: string,
  params: IInvoicesSearch,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IInvoice>>(
    `/api/customViews/${customViewGuid}/invoices/search`,
    { cancelToken, params, transformResponse }
  );
}

/**
 * Get a list of invoices by engagement files
 */
export async function getInvoicesByEngagement(
  this: ApiBase,
  engagementGuid: string,
  params: ISearchTerm,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IInvoice>>(
    `/api/engagements/${engagementGuid}/invoices/search`,
    { cancelToken, params, transformResponse }
  );
}
