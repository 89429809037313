export enum Permission {
  // Savings Summary
  SavingsSummaryView = '0a09522b-088e-4765-b7c3-1efa5530116b',
  SavingsSummaryEdit = '54dcd878-e3cb-44c9-ba6f-f8f254b1ff2f',

  // Learnings
  LearningsView = 'e44eacec-6d6c-4c1f-8f4f-318f94bf51be',
  LearningsContribute = '0334f242-3914-4252-ad2c-f69bd0b0f210',
  LearningsEdit = 'f990e361-e6a1-4882-b7b5-ea4727258cc3',

  // Tasks
  TasksView = 'bbdd5726-c512-4f1e-96c0-3d4925cbaeae',
  TasksContribute = 'a5c429ae-3727-4dfb-b796-3f097203507b',
  TasksEdit = 'e0df18c7-2211-4876-ab46-ad1fa43f909f',

  // Invoices
  InvoicesView = 'fc0cef05-a225-4a27-a5c4-95ea51dd98ce',

  // Data Requests
  DataRequestsView = '1c5f8338-e6cf-465e-acaf-7d2e437e3546',
  DataRequestsContribute = '9799edd1-c723-4b29-8325-79b5ac5a7b67',
  DataRequestsEdit = 'dc9c4ab1-06ee-4945-9aa3-e971bb0146e1',

  // Engagements, Milestones
  TimelinesView = '9f9661c1-7de2-42f8-829e-73da87a9a5aa',
  TimelinesContribute = '5b133884-8984-4dbd-9a76-6d1bc949d650',
  TimelinesEdit = '3a7c5565-4470-4741-914c-4d9a9146a9ea',

  // Contracts
  ContractsView = '50bc0bff-0e95-4e90-8eae-cbd524abd750',
  ContractsEditAndPublish = 'c162c4d8-be09-44ec-b672-7ff172de7cd7',

  // Reporting
  ReportsView = 'ecfc62a5-2196-4b4e-b2f3-bbe37c2cf2ac',
  ReportsLogos = '6238f1d3-7dd3-4ce1-9cda-a3eb6ed9d727',

  // Scheduling
  SchedulingBasic = '896319e6-963c-46ad-bd9e-8a358cf52d5b', // View, Create, Edit
  SchedulingAdmin = '2ead1443-3ba0-492f-bd8b-bc6d84ad2075', // Delete

  // Activity Feed
  ActivityRead = 'fe418857-6584-4fbc-9a0d-4925e3f743bc',

  // Security
  CustomizePermissions = '0fcf7941-cbe7-4764-b84e-17d10e49c643', // EA
  ClientAdmin = '7b9a71f8-e305-4479-84a7-b41a95f669b0', // Client
  ExecutiveAccess = 'cfe12af5-ea6b-4021-a0ab-ad2c792437e7',
  Impersonate = '3f608ece-5a0c-40c6-bb3a-dae997266dc9',

  //Files
  FilesRead = '4597dbc3-5a23-4eb9-9be0-30fd066ca93b',
  FilesContribute = '97d6a1b8-0f12-4db2-bf64-14a6d339ea5a',
  FilesWrite = '5cdc2499-70f5-492c-a927-614b26a48fb2'
}

export enum PermissionGroup {
  Projects,
  DataRequests,
  Tasks,
  Learnings,
  Contracts,
  Invoices,
  Reports,
  Savings,
  UserAccess,
  ActivityFeed,
  Files
}

// return from /api/identity
// return from /api/permissions
export interface IPermission {
  permissionGuid: string;
  permissionName: string;
  permissionDescription: string;
  permissionGroup: string;
  permissionGroupId: PermissionGroup;
  userTypes: number;
}

// return from /api/newuser/${newUserGuid}
// return from /api/users/${userGuid}/permissions
export interface IUserPermission extends IPermission {
  isGranted: boolean;
  // userGuid: string;
}

// return from /api/roles
export interface IRolePermission extends IPermission {
  isGranted: boolean;
  isLocked: boolean;
}
