import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@ryan/components';
import './SwitcherCounts.scss';

export interface ISwitcherCountsProps {
  activeCount: number;
  inactiveCount: number;
  level: number;
}
const SwitcherCounts: FunctionComponent<ISwitcherCountsProps> = ({
  activeCount,
  inactiveCount,
  level
}) => {
  const { t } = useTranslation();

  return (
    <div className="switcher-counts" data-level-color={level}>
      <div className="switcher-counts__total" data-level-color={level}>
        {activeCount + inactiveCount}
      </div>
      <span className="switcher-counts__count-container">
        <Badge
          className={classNames(
            'ry-badge',
            'switcher-counts__badge',
            'switcher-counts__badge--active'
          )}
        >
          {activeCount}
        </Badge>{' '}
        {t('Active')}
      </span>
      <span className="switcher-counts__spacer" />
      <span className="switcher-counts__count-container">
        <Badge
          className={classNames(
            'ry-badge',
            'switcher-counts__badge',
            'switcher-counts__badge--inactive'
          )}
        >
          {inactiveCount}
        </Badge>{' '}
        {t('Inactive')}
      </span>
    </div>
  );
};

export default SwitcherCounts;
