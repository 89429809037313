import {
  IAccount,
  ICountry,
  IEngagement,
  IJurisdiction,
  IPracticeArea,
  IUserSummary
} from '.';

export enum ICustomViewType {
  Static = 1,
  Dynamic
}

export enum ICustomViewCreateType {
  AccountSingle = 1,
  AccountMultiple,
  CustomOneTimeUse,
  CustomSaved
}

// GET /api/identity
export interface ICustomViewSummary {
  // included if createType is AccountSingle
  accountView: null | {
    account: IAccount;
    includeSubsidiaries: boolean;
  };
  createType: ICustomViewCreateType;
  customViewGuid: string;
  isExecutiveView: boolean;
  name: string;
  projectCount: number;
  type: ICustomViewType;
}

// GET /api/customviews/{customViewGuid}
export interface ICustomView extends ICustomViewSummary {
  filters: ICustomViewFilter[] | null;
  engagements: IEngagement[] | null;
}

// POST /api/customviews
// PUT /api/customviews/{customViewGuid}
export interface ICustomViewCreate
  extends Omit<ICustomView, 'customViewGuid' | 'projectCount' | 'accountView'> {
  customViewGuid?: string;
  filters: ICustomViewFilterCreate[] | null;
}

export interface ICustomViewFilter {
  customViewDynamicFilterGuid?: string;
  customViewGuid?: string;
  type: ICustomViewFilterType;
  operator: ICustomViewFilterOperator;
  value:
    | string
    | number
    | IAccount
    | ICountry
    | IJurisdiction
    | IPracticeArea
    | IUserSummary;
}

// only support string/number values when creating new filters - extract
// relevant field before sending POST request (ie. userGuid from IUserSummary)
export interface ICustomViewFilterCreate extends ICustomViewFilter {
  value: string | number;
}

export enum ICustomViewFilterType {
  Account = 1,
  Country,
  Jurisdiction,
  Practice,
  Status,
  AccountManagement,
  ProjectManagement,
  MarketingProfessional,
  Visibility
}

export enum ICustomViewFilterOperator {
  Equals = 1,
  EqualsIncludingSubsidiaries,
  DoesNotEqual
}

export enum ICustomViewProjectStatus {
  Inactive,
  Active
}

export enum ICustomViewProjectVisibility {
  Unpublished,
  Published
}
