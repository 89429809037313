import React from 'react';

import { IBarChartProps } from './utils';

import './BarChart.scss';

const BarChart: React.FC<IBarChartProps> = ({ barChartDataPoints }) => {
  let runningTotal = 0;

  const mappedBarChartDataPoints = barChartDataPoints
    .map(dataPoint => {
      runningTotal += dataPoint.value;
      return { ...dataPoint, sumAtDataPoint: runningTotal };
    })
    .map(dataPoint => ({
      ...dataPoint,
      barWidth: parseFloat(
        ((dataPoint.sumAtDataPoint / runningTotal) * 100).toFixed(2)
      )
    }))
    .reverse();

  return (
    <div className="bar-chart">
      {mappedBarChartDataPoints.map((dataPoint, i) => (
        <div
          key={i}
          style={{
            ...dataPoint.style,
            width: `${dataPoint.barWidth}%`
          }}
        />
      ))}
    </div>
  );
};

export default BarChart;
