import ENV from 'env';
import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import {
  ActivityType,
  IAddress,
  INewUserSummary,
  IUserSummary
} from '../../../interfaces';
import formatAddress from '../../../utils/formatAddress';
import { formatDate } from '../../../utils/formatDate';
import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';
import { findModifiedByKey } from '../findModifiedByKey';

export const AccessControlActivity: FunctionComponent<IActivityProps> =
  props => {
    const {
      t,
      user,
      activity,
      isNotification,
      showAccountName,
      showEngagementName,
      onCloseNotificationDrawer
    } = props;

    const {
      entityGuid,
      createdBy,
      createdByName,
      userInformation,
      snapShot: { entitySummary }
    } = activity;
    const { userGuid } = entitySummary as IUserSummary;

    // user name to display in activity content
    // NOTE: noticed some activity data may be missing `userInformation` so
    // adding a fallback to handle incomplete data
    const userName: string = userInformation?.fullName || 'unknown user';

    // Context helper.
    function you(userGuid: string) {
      return user.userGuid === userGuid ? 'you' : 'x';
    }

    const tBy = you(createdBy);
    const tUser = you(userGuid);

    switch (activity.activityTypeId) {
      /**
       * Reactivated
       */
      case ActivityType.UserReactivated: {
        const tKey = `activity.accessControl.reactivated.by-${tBy}-${tUser}`;

        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            ctaText={t('activity.accessControl.viewProfile')}
            icon="user-check"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.accessControl.reactivated.title')}
            to={`/app/personal-information/${entityGuid}`}
          >
            <Trans i18nKey={tKey}>
              <b />
              {{ userName }}
              {{ createdByName }}
            </Trans>{' '}
          </AbstractActivity>
        );
      }

      /**
       * Deactivated
       */
      case ActivityType.UserDeactivated: {
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            icon="user-deny"
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.accessControl.deactivated.title')}
          >
            <Trans
              i18nKey={`activity.accessControl.deactivated.by-${tBy}-${tUser}`}
            >
              <b />
              {{ userName }}
              {{ createdByName }}
            </Trans>
          </AbstractActivity>
        );
      }

      /**
       * User Activated
       */
      case ActivityType.UserActivated: {
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            ctaText={t('activity.accessControl.viewProfile')}
            icon="user-check"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.accessControl.userActivated.title')}
            to={`/app/personal-information/${entityGuid}`}
          >
            <Trans i18nKey={`activity.accessControl.userActivated.by-${tUser}`}>
              <b />
              {{ userName }}
            </Trans>{' '}
          </AbstractActivity>
        );
      }

      /**
       * Scheduled for Reactivation
       */
      case ActivityType.UserScheduledForReactivation: {
        const tKey = `activity.accessControl.scheduledForReactivation.by-${tBy}-${tUser}`;

        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            icon="user-clock"
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.accessControl.scheduledForReactivation.title')}
          >
            <Trans i18nKey={tKey}>
              <b />
              {{ userName }}
              {{ createdByName }}
              {{
                date: formatDate(
                  findModifiedByKey(activity, 'ActivateDate').newValue
                )
              }}
            </Trans>
          </AbstractActivity>
        );
      }

      /**
       * Scheduled for Deactivation
       */
      case ActivityType.UserScheduledForDeactivation: {
        const tKey = `activity.accessControl.scheduledForDeactivation.by-${tBy}-${tUser}`;
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            icon="user-clock"
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.accessControl.scheduledForDeactivation.title')}
          >
            <Trans i18nKey={tKey}>
              <b />
              {{ userName }}
              {{ createdByName }}
              {{
                date: formatDate(
                  findModifiedByKey(activity, 'DeactivateDate').newValue
                )
              }}
            </Trans>
          </AbstractActivity>
        );
      }

      /**
       * Client Role Edited
       */
      case ActivityType.UserRoleModified: {
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            ctaText={t('activity.accessControl.viewProfile')}
            icon="user-pencil"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.accessControl.roleEdited.title')}
            to={`/app/personal-information/${entityGuid}`}
          >
            <Trans
              i18nKey={`activity.accessControl.roleEdited.by-${tBy}-${tUser}`}
            >
              <b />
              {{ userName }}
              {{ createdByName }}
            </Trans>{' '}
            <ActivityModified
              activity={activity}
              cases={[
                {
                  key: 'Role',
                  label: t('Role'),
                  type: 'string' as const
                }
              ]}
            />
          </AbstractActivity>
        );
      }

      /**
       * Client Profile Edited
       */
      case ActivityType.ClientContactProfileUpdate: {
        const _for = `for-${entityGuid === user.userGuid ? 'you' : 'x'}`;
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            ctaText={t('activity.accessControl.viewProfile')}
            icon="user-pencil"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.accessControl.profileEdited.title')}
            to={`/app/personal-information/${entityGuid}`}
          >
            <Trans i18nKey={`activity.accessControl.profileEdited.${_for}`}>
              <b />
              {{ userName }}
            </Trans>{' '}
            <ActivityModified
              activity={activity}
              cases={[
                {
                  key: 'Name',
                  label: t('Name'),
                  type: 'string' as const
                },
                {
                  key: 'Title',
                  label: t('Title'),
                  type: 'string' as const
                },
                {
                  key: 'OfficePhone',
                  label: t('Office Number'),
                  type: 'string' as const
                },
                {
                  key: 'MobilePhone',
                  label: t('Mobile Number'),
                  type: 'string' as const
                },
                {
                  key: 'OfficeAddress',
                  label: t('Physical Address'),
                  type: (value: IAddress) => formatAddress(value) || ''
                },
                {
                  key: 'MailingAddress',
                  label: t('Mailing Address'),
                  type: (value: IAddress) => formatAddress(value) || ''
                }
              ]}
            />
          </AbstractActivity>
        );
      }

      /**
       * New User Request Created
       */
      case ActivityType.NewUserRequestCreated:
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            ctaText={t('activity.newUser.viewRequest')}
            icon="user-profile-search"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.newUser.created.title')}
            to="/app/team/manage/newusers"
          >
            <Trans i18nKey="activity.newUser.created.content">
              <b />
              {{ newUser: (entitySummary as INewUserSummary).name }}
              {{ requestor: createdByName }}
            </Trans>{' '}
          </AbstractActivity>
        );

      /**
       * New User Access Requested
       */
      case ActivityType.NewUserAccessRequested:
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            ctaText={t('activity.newUser.viewRequest')}
            icon="user"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.newUser.accessrequested.title')}
            to="/app/team/manage/newusers"
          >
            <Trans i18nKey="activity.newUser.accessrequested.content">
              <b />
              {{
                requestorFullName: createdByName,
                ryanPlatform: ENV.RYAN_PLATFORM,
                userFullName: (entitySummary as INewUserSummary).name
              }}
            </Trans>{' '}
          </AbstractActivity>
        );

      /**
       * New User Request Approved
       */
      case ActivityType.NewUserRequestApproved:
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            ctaText={t('activity.accessControl.viewProfile')}
            icon="user-profile-check"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.newUser.approved.title')}
            to={`/app/personal-information/${userGuid}`}
          >
            <Trans i18nKey="activity.newUser.approved.content">
              <b />
              {{ newUserName: (entitySummary as INewUserSummary).name }}
              {{ createdByName }}
            </Trans>{' '}
          </AbstractActivity>
        );

      /**
       * New User Request Denied
       */
      case ActivityType.NewUserRequestDenied:
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.DarkPurple}
            icon="user-profile-deny"
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.newUser.denied.title')}
          >
            <Trans i18nKey="activity.newUser.denied.content">
              <b />
              {{ newUserName: (entitySummary as INewUserSummary).name }}
              {{ createdByName }}
            </Trans>
          </AbstractActivity>
        );

      default:
        return <UnrecognizedActivity {...props} />;
    }
  };
