import React, { Fragment, FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';

function flatten(target: React.ReactNode[], children: React.ReactNode) {
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}

// react-router doesn't have support for React fragments in <Switch />. This component
// wraps react-router's <Switch /> so that it gets fragment support.
//
// https://github.com/ReactTraining/react-router/issues/5785
const SwitchWithFragments: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const flattenedChildren: React.ReactNode[] = [];
  flatten(flattenedChildren, children);
  return <Switch>{flattenedChildren}</Switch>;
};

export default SwitchWithFragments;
