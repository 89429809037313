const IMPERSONATE_SESSION_KEY = 'ImpersonateUserId';

export function getImpersonatedUserGuid() {
  return window.sessionStorage.getItem(IMPERSONATE_SESSION_KEY);
}

export function setImpersonation(userGuid: string) {
  window.sessionStorage.setItem(IMPERSONATE_SESSION_KEY, userGuid);
}

export function clearImpersonation() {
  window.sessionStorage.removeItem(IMPERSONATE_SESSION_KEY);
}
