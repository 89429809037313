import loadImage, {
  CanvasTrueOptions,
  LoadImageOptions
} from 'blueimp-load-image';

type ResizeImageConfig = {
  compressFormat?: string;
} & Omit<
  LoadImageOptions & CanvasTrueOptions,
  'canvas' | 'meta' | 'orientation'
>;

class ImageResize {
  static b64toBlob(b64Data: string, contentType = 'image/jpeg') {
    const sliceSize = 512;
    const byteCharacters = atob(
      b64Data.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  static async createResizedImage(
    file: File,
    config: ResizeImageConfig = {}
  ): Promise<File> {
    if (!file) throw Error();

    // load image file with loadImage library
    // see https://www.npmjs.com/package/blueimp-load-image#options
    const { compressFormat = 'jpeg', ...loadImageConfig } = config;
    const imageData = await loadImage(file, {
      canvas: true,
      imageSmoothingQuality: 'high',
      maxHeight: 500,
      maxWidth: 500,
      orientation: true,
      ...loadImageConfig
    });

    // convert scaled image back to file
    // NOTE: return `image` will be a canvas due to `canvas=true` config
    const canvas = imageData.image as unknown as HTMLCanvasElement;
    const contentType = `image/${compressFormat}`;
    const blob = ImageResize.b64toBlob(
      canvas.toDataURL(contentType, 1),
      contentType
    );
    return new File([blob], file.name, { type: contentType });
  }
}

export default ImageResize;
