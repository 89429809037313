import {
  IGetCommentButtonTextPath,
  IHandleToggleFollow
} from './Worklist.interfaces';

import ApiService from '../../../../services/ApiService';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';

export const getCommentButtonTextPath: IGetCommentButtonTextPath = (
  isReadOnly,
  hasComments
) => {
  let textPath = 'viewCommentsWithCount';

  if (!isReadOnly) {
    textPath = hasComments ? 'commentsWithCount' : 'addAComment';
  }

  return textPath;
};

export const handleToggleFollow: IHandleToggleFollow = async ({
  engagementGuid,
  isFollowing,
  queueItemGuid,
  setIsLoading,
  setIsUpdatingFollowStatus
}) => {
  try {
    setIsUpdatingFollowStatus(true);

    await ApiService.addWatcherToQueue(
      engagementGuid,
      !isFollowing,
      queueItemGuid
    );

    setIsLoading(true);
  } catch {
    pushServerErrorToast();
  }
};
