import InfoTooltip from 'components/InfoTooltip';
import { WithUser, withUser } from 'contexts/UserContext';
import { Feature, MAX_SELECTED_COMPANIES, Permission } from 'interfaces';

import classNames from 'classnames';
import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  BodyClass,
  Button,
  EButtonSizes,
  EButtonVariant,
  Icon,
  Switch,
  TabManager,
  TabPanel,
  Tabs,
  TextInput
} from '@ryan/components';

import { WithSwitcher, withSwitcher } from '../SwitcherContext';
import { SwitcherCustomViews } from '../SwitcherCustomViews/SwitcherCustomViews';
import SwitcherFilterApplyButton from '../SwitcherFilterApplyButton/SwitcherFilterApplyButton';
import SwitcherOptions from '../SwitcherOptions/SwitcherOptions';
import filterAccounts from '../filterAccounts';
import isSwitcherOnRoute from '../isSwitcherOnRoute';

import './SwitcherMobile.scss';

interface ISwitcherMobileProps
  extends WithTranslation,
    WithUser,
    WithSwitcher,
    RouteComponentProps {
  // ...
}

interface ISwitcherMobileState {
  recentsExpanded: boolean;
}

export class SwitcherMobile extends Component<
  ISwitcherMobileProps,
  ISwitcherMobileState
> {
  private ref = React.createRef<HTMLDivElement>();

  readonly state: ISwitcherMobileState = {
    recentsExpanded: false
  };

  static getDerivedStateFromProps(
    props: ISwitcherMobileProps
  ): Partial<ISwitcherMobileState> | null {
    // close recently viewed list on close of switcher
    if (!props.openMobile) {
      return {
        recentsExpanded: false
      };
    }

    return null;
  }

  handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onSearchChange(e.target.value);
  };

  handleSearchClear = () => {
    this.props.onSearchChange('');
  };

  handleRecentsToggle = () => {
    this.setState(({ recentsExpanded }) => ({
      recentsExpanded: !recentsExpanded
    }));
  };

  render() {
    const {
      // withUser
      t,
      user,
      activeView,
      executiveAccounts,
      permissionService,
      isFeatureToggled,

      // withRouter
      location,

      // withSwitcher
      openMobile,
      openFilterPane,
      searchQuery,
      expanded,
      filterCompanies,
      filterCustomView,
      filterExecutiveAccess,
      filterTab,
      onToggleMobile,
      onAccountSelect,
      onAccountToggle,
      onFilterCustomViewDelete,
      onFilterCustomViewSelect,
      onFilterCompanyToggle,
      onFilterCompanyToggleAll,
      onFilterExecutiveToggle,
      onFilterPaneToggle,
      onFilterTabSelect
    } = this.props;

    if (!isSwitcherOnRoute(location) || !openMobile) {
      return null;
    }

    const accounts = filterExecutiveAccess
      ? executiveAccounts || []
      : user.accountTree;

    const filteredAccounts = filterAccounts(searchQuery, accounts);

    // Cannot select all if we'd be selecting more than 300 accounts.
    const isSelectionLimited =
      isFeatureToggled(Feature.LimitedCustomView) &&
      accounts.length > MAX_SELECTED_COMPANIES;

    /**
     * Render Companies view and default mobile Switcher body.
     */
    const renderCompanies = () => {
      // (executive access) render loading state if initial executive account
      // load
      if (filterExecutiveAccess && executiveAccounts === null) {
        return (
          <div className="center-icon">
            <Icon className="loading-spin" name="loading" />
          </div>
        );
      }

      // render empty state - will display if:
      // 1. user has no accounts
      // 2. user toggles on executive access and has no executive accounts
      // 3. user is searching for an account and returns no matches
      if (filteredAccounts.length === 0) {
        return (
          <div className="account-switcher-mobile__empty">
            {searchQuery
              ? t('switcher.empty', { context: 'search', searchQuery })
              : t(
                  'switcher.empty',
                  filterExecutiveAccess ? { context: 'executive' } : {}
                )}
          </div>
        );
      }

      // render companies list by default
      return (
        <SwitcherOptions
          accounts={filteredAccounts}
          accountSelection={
            openFilterPane && filterTab === 'companies' ? filterCompanies : null
          }
          activeView={activeView}
          expanded={expanded}
          level={1}
          onAccountChecked={onFilterCompanyToggle}
          onAccountSelect={onAccountSelect}
          onAccountToggle={onAccountToggle}
          searchQuery={searchQuery}
          t={t}
        />
      );
    };

    return (
      <div
        className={classNames('account-switcher-mobile', {
          'account-switcher-mobile--filters-open': openFilterPane
        })}
        ref={this.ref}
      >
        <BodyClass className="with-account-switcher-mobile" />

        {/**
         * Search & Filter Toggle
         */}
        <div className="account-switcher-mobile__search">
          <Button
            icon={openFilterPane ? 'filter-filled' : 'filter'}
            onClick={onFilterPaneToggle}
            size={EButtonSizes.SMALL}
            variant={EButtonVariant.TEXT}
          >
            {t('Filter')}
          </Button>
          <TextInput
            icon={searchQuery ? 'delete' : 'search'}
            onChange={this.handleSearchChange}
            onIconClick={searchQuery ? this.handleSearchClear : undefined}
            placeholder={t('Search')}
            value={searchQuery}
          />
        </div>

        <TabManager
          activeTabKey={filterTab}
          onTabChange={activeTabKey => {
            onFilterTabSelect(activeTabKey as 'companies' | 'custom-views');
          }}
        >
          <Tabs
            block
            tabs={[
              {
                tabKey: 'companies',
                text: t('Companies')
              },
              {
                tabKey: 'custom-views',
                text: t('Custom Views')
              }
            ]}
          />
          <TabPanel tabKey="companies">
            <>
              {/**
               * Companies Tab - Controls
               */}
              {openFilterPane ? (
                <>
                  {/**
                   * Executive Access Swtich
                   */}
                  {permissionService.hasPermission(
                    Permission.ExecutiveAccess
                  ) && (
                    <div className="account-switcher-mobile__filters-switch">
                      {t('switcher.executiveAuditView')}
                      <InfoTooltip>{t('ExecutiveToggleToolTip')}</InfoTooltip>
                      <Switch
                        checked={filterExecutiveAccess}
                        onChange={onFilterExecutiveToggle}
                      />
                    </div>
                  )}
                  {/**
                   * Select All Switch
                   */}
                  <div className="account-switcher-mobile__filters-switch">
                    {t('Select All')}
                    {isSelectionLimited && (
                      <InfoTooltip>
                        {t('switcher.selectAllDisabled')}
                      </InfoTooltip>
                    )}
                    <Switch
                      checked={filterCompanies.isAllChecked()}
                      disabled={
                        isSelectionLimited || filteredAccounts.length === 0
                      }
                      onChange={onFilterCompanyToggleAll}
                    />
                  </div>
                </>
              ) : (
                this.renderRecents()
              )}
              {/**
               * Companies Tab - Companies to select
               */}
              {renderCompanies()}
            </>
          </TabPanel>
          <TabPanel tabKey="custom-views">
            <SwitcherCustomViews
              customViews={user.customViews}
              onCloseSwitcher={onToggleMobile}
              onCustomViewDeleted={onFilterCustomViewDelete}
              onCustomViewSelected={onFilterCustomViewSelect}
              searchQuery={searchQuery}
              selectedCustomView={filterCustomView}
            />
          </TabPanel>
        </TabManager>

        {/**
         * Filter Buttons - Cancel & Apply
         */}
        {(filterCustomView !== null || filterCompanies.view !== null) && (
          <div className="account-switcher-mobile__filters-buttons">
            <div className="row">
              <div className="col-6">
                <Button
                  block
                  onClick={onFilterPaneToggle}
                  size={EButtonSizes.SMALL}
                  text={t('Cancel')}
                  variant={EButtonVariant.SECONDARY}
                />
              </div>
              <div className="col-6">
                <SwitcherFilterApplyButton />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderRecents() {
    const { onToggleMobile, recentViews, searchQuery, setActiveView, t } =
      this.props;
    const { recentsExpanded } = this.state;
    const iconName = `chevron-${recentsExpanded ? 'up' : 'down'}`;

    if (searchQuery) {
      return null;
    }

    return (
      <div className="account-switcher-mobile__recents">
        <Button
          icon={iconName}
          onClick={this.handleRecentsToggle}
          variant={EButtonVariant.TEXT}
        >
          {t('Recently Viewed')}
        </Button>
        {recentsExpanded && (
          <ul aria-label={t('Recently Viewed')}>
            {recentViews.map(view => (
              <li
                className="account-switcher-mobile__recents__option"
                key={view.customViewGuid}
              >
                <button
                  onClick={() => {
                    setActiveView(view);
                    onToggleMobile();
                  }}
                >
                  {view.name}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(
  withUser(withSwitcher(withRouter(SwitcherMobile)))
);
