import StatusIcon from 'components/StatusIcon/StatusIcon';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  IMilestone,
  IMilestoneRequest,
  IPagedDataResponse,
  ITask,
  Permission
} from 'interfaces';
import history from 'services/history';

import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, EButtonSizes, EButtonVariant } from '@ryan/components';

import './MilestoneTasks.scss';

export interface IMilestoneTasksProps {
  milestone: IMilestone | IMilestoneRequest;
  tasks: IPagedDataResponse<ITask> | null;
  onCreateTask: () => void;
}

// Assumes we have Permission.TasksView.
const MilestoneTasks: FunctionComponent<
  IMilestoneTasksProps & WithUser & WithTranslation
> = props => {
  const {
    t,
    isAppReadOnly,
    permissionService: ps,
    milestone,
    tasks,
    onCreateTask
  } = props;
  const canViewTasks = ps.hasPermission(Permission.TasksView);
  const canEditTasks = ps.hasPermission(Permission.TasksEdit);
  const isSavedMilestone = 'engagementMilestoneGuid' in milestone;

  return canViewTasks ? (
    <div className="milestone-tasks">
      <h4>
        {t('milestones.milestoneContent.linkedTasks.title')}{' '}
        {tasks !== null && `(${tasks.totalResults})`}
      </h4>
      <div className="milestone-tasks__tasks">
        {/**
         * If milestone is new, show message telling user to create the milestone.
         */}
        {!isSavedMilestone ? (
          <p>{t('milestones.milestoneContent.linkedTasks.none')}</p>
        ) : (
          <>
            {/**
             * If tasks are loading, show skeleton.
             */}
            {tasks === null && (
              <div>
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </div>
            )}

            {/**
             * If we don't have any tasks, show empty state.
             */}
            {tasks && tasks.results.length === 0 && (
              <p>{t('milestones.milestoneContent.linkedTasks.empty')}</p>
            )}

            {/**
             * Render the tasks!
             */}
            {tasks &&
              tasks.results.map(task => (
                <Link
                  className="milestone-tasks__task"
                  key={task.queueItemGuid}
                  to={`/app/project/${task.engagementGuid}/tasks`}
                >
                  <StatusIcon iconOnly status={task.statusId} />
                  {task.title}
                </Link>
              ))}
          </>
        )}
      </div>

      {isSavedMilestone && (
        <div className="milestone-tasks__buttons">
          {/**
           * Create Task
           */}
          {canEditTasks && (
            <Button
              disabled={
                (milestone as IMilestone).isEngagementReadOnly || isAppReadOnly
              }
              icon="plus"
              onClick={onCreateTask}
              size={EButtonSizes.SMALL}
              text={t('New Task')}
              variant={EButtonVariant.TEXT}
            />
          )}

          {/**
           * View Tasks
           */}
          {canViewTasks && (
            <Button
              onClick={() =>
                history.push(
                  `/app/project/${
                    (milestone as IMilestone).engagementGuid
                  }/tasks`
                )
              }
              size={EButtonSizes.SMALL}
              text={t('View All')}
              variant={EButtonVariant.TEXT}
            />
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default withTranslation()(withUser(MilestoneTasks));
