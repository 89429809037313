import {
  IOnDocumentManagerChange,
  IOnHandlePostMessage
} from './FileUploadEventWrapper.interfaces';

import ENV from 'env';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DSSFileUpload from '../../components/DSSFileUpload/DSSFileUpload';
import { DSSManagerEventType, useDSSManager } from '../../utils/DSS';

import './FileUploadEventWrapper.scss';

const FileUploadEventWrapper: FunctionComponent = () => {
  const onDocumentManagerChange: IOnDocumentManagerChange = (
    event,
    ...args
  ) => {
    let eventData = {};

    if (event === DSSManagerEventType.Removed) {
      eventData = { documentGuid: args[1] };
    } else if (event === DSSManagerEventType.Uploading) {
      eventData = { complete: 0, documentName: args[0].name };
    } else if (event === DSSManagerEventType.Uploaded) {
      eventData = {
        complete: 1,
        documentGuid: args[1].documentGuid,
        documentName: args[1].documentName
      };
    }

    onHandlePostMessage(`dxp-file-upload:${event}`, eventData);
  };

  const documentManager = useDSSManager({ onChange: onDocumentManagerChange });
  const fileUploadWrapperRef = useRef<HTMLDivElement | null>(null);
  const { t: getTextToDisplay } = useTranslation();

  useEffect(() => {
    document.body.className = `${document.body.className} content-only`.trim();
  }, []);

  useEffect(() => {
    const fileUploadWrapperElement = fileUploadWrapperRef.current;

    if (!fileUploadWrapperElement) {
      return;
    }

    const observer = new ResizeObserver(resizeObserverEntries =>
      onHandlePostMessage('dxp-file-upload:size-change', {
        height: resizeObserverEntries[0].target.clientHeight,
        width: resizeObserverEntries[0].target.clientWidth
      })
    );
    observer.observe(fileUploadWrapperElement);

    return () => {
      observer.unobserve(fileUploadWrapperElement);
    };
  }, [fileUploadWrapperRef]);

  const onHandlePostMessage: IOnHandlePostMessage = (type, data) => {
    const isWithinIframe = window.top !== window.self;

    if (!window.top || !isWithinIframe) return;

    window.top.postMessage(
      JSON.stringify({ documentData: data, type }),
      ENV.DECISIONS_BASE_URL
    );
  };

  return (
    <div className="file-upload-event-wrapper" ref={fileUploadWrapperRef}>
      <DSSFileUpload
        border
        dssManager={documentManager}
        multiple
        title={getTextToDisplay('fileUploadEventWrapper.selectFiles')}
      />
    </div>
  );
};

export default FileUploadEventWrapper;
