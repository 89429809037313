import { TFunction } from 'i18next';
import React from 'react';

import { ITaskCounts } from '../../interfaces';
import BarChart from '../BarChart/BarChart';
import Empty from '../Empty';

interface ITaskDataVizProps {
  t: TFunction;
  counts: ITaskCounts;
}

export const TaskDataViz: React.FC<ITaskDataVizProps> = props => {
  const { t } = props;
  const { total, completeTotal, inProgressTotal, toDoTotal } = props.counts;

  return (
    <>
      {total === 0 ? (
        <Empty icon="clipboard">{t('taskDataViz.empty')}</Empty>
      ) : (
        <>
          <div className="row">
            <div className="col-4 task-dataviz__overall-total">
              <div className="ry-number-1">{total}</div>
              <label>{t('taskDataViz.total')}</label>
            </div>
            <div className="col-8 task-dataviz__legend">
              <div>
                <div className="task-dataviz__icon task-dataviz__icon--todo" />
                <strong>{toDoTotal}</strong> {t('taskDataViz.toDo')}
              </div>
              <div>
                <div className="task-dataviz__icon task-dataviz__icon--inprogress" />
                <strong>{inProgressTotal}</strong> {t('taskDataViz.inProgress')}
              </div>
              <div>
                <div className="task-dataviz__icon task-dataviz__icon--completed" />
                <strong>{completeTotal}</strong> {t('taskDataViz.complete')}
              </div>
            </div>
          </div>
          <BarChart
            barChartDataPoints={[
              {
                keyName: 'completed',
                style: { backgroundColor: '#0a8287' },
                value: completeTotal
              },
              {
                keyName: 'inProgress',
                style: {
                  background:
                    'repeating-linear-gradient(-45deg, #0a8287, #0a8287 1px, #bededf 2px, #bededf 3px)'
                },
                value: inProgressTotal
              },
              {
                keyName: 'todo',
                style: { backgroundColor: '#e8e9ed' },
                value: toDoTotal
              }
            ]}
          />
        </>
      )}
    </>
  );
};

export default TaskDataViz;
