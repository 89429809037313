import React, { FunctionComponent } from 'react';
import './ContactInformationSkeleton.scss';

export const ContactInformationSkeleton: FunctionComponent = () => (
  <div className="contact-information-skeleton row">
    <div className="col-lg-4">
      <div className="contact-information-skeleton__avatar-area">
        <div className="ry-skeleton sk-btn-lg" />
      </div>
      <div className="ry-skeleton sk-field" />
      <div className="ry-skeleton sk-field" />
      <div className="ry-skeleton sk-field" />
      <div className="ry-skeleton sk-field" />
      <div className="ry-skeleton sk-field" />
      <div className="ry-skeleton sk-field" />
    </div>
    <div className="col-lg-8 contact-information-skeleton__address-area">
      <div className="row">
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
      </div>
      <div className="space" />
      <div className="row">
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
        <div className="col-6">
          <div className="ry-skeleton sk-field" />
        </div>
      </div>
    </div>
  </div>
);
