import CustomViewFilter, {
  isDefinedFilter,
  transformFilterValue
} from 'components/CustomViewFilter';
import Empty from 'components/Empty';
import { RadioButton, RadioButtonGroup } from 'components/RadioButton';
import Table from 'components/Table';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import { useUser } from 'contexts/UserContext';
import { useStateMounted } from 'hooks';
import {
  ICustomViewCreate,
  ICustomViewCreateType,
  ICustomViewFilter,
  ICustomViewFilterOperator,
  ICustomViewFilterType,
  ICustomViewType,
  IEngagement,
  ISortDescriptor,
  ISortDirection
} from 'interfaces';
import isEqual from 'lodash.isequal';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import { formikFieldProps, useFormik, yup } from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  ITableColumn,
  TextInput,
  pushToast
} from '@ryan/components';

import CustomViewContext, { CustomViewRole } from '../CustomViewContext';
import CustomViewProjectPicker from '../CustomViewProjectPicker';

import './CustomViewBuilder.scss';

// react-router params
interface ICustomViewBuilderParams {
  customViewGuid?: string;
}

interface ICustomViewBuilderProps {
  /**
   * A handler to be called on cancel button click.
   */
  onCancel: () => void;

  /**
   * A handler to be called on successful submission of custom view form.
   */
  onSubmit?: () => void;
}

// initial filter state of newly added filters
const emptyFilter: ICustomViewFilter = {
  type: ICustomViewFilterType.Account,
  operator: ICustomViewFilterOperator.Equals,
  value: ''
};

/**
 * Empty state of project preview table.
 */
const EmptyPreviewTable: React.FC<{
  children?: React.ReactNode;
  searchQuery?: string;
}> = ({ children, searchQuery }) => (
  <TableEmpty searchQuery={searchQuery}>
    <Empty icon="venn-diagram">{children}</Empty>
  </TableEmpty>
);

/**
 * Memoized `CustomViewFilter` that does not update on `onChange` change to
 * prevent excessive rendering due to rendering `CustomViewFilter` in a loop
 * that redefines `onChange` each iteration.
 */
const MemoCustomViewFilter = React.memo(
  CustomViewFilter,
  (prevProps, props) => {
    const { onChange: prevOnChange, ...prevPropsToCompare } = prevProps;
    const { onChange, ...propsToCompare } = props;
    return isEqual(prevPropsToCompare, propsToCompare);
  }
);

// max character length of custom view name field
const maxCustomViewNameLength = 50;

/**
 * Renders a form to build custom views.
 */
const CustomViewBuilder: React.FC<ICustomViewBuilderProps> = ({
  onCancel,
  onSubmit
}) => {
  // if `customViewGuid` is defined then call endpoint to grab view data and
  // reinitialize form values
  const { customViewGuid }: ICustomViewBuilderParams = useParams();
  const { t } = useTranslation();
  const { permissionService, setActiveView } = useUser();
  const { role, setRole, setShowWell, setViewType, viewType } =
    useContext(CustomViewContext);

  // pre-defined customViewGuid indicates editing an existing view
  const isEditing = !!customViewGuid;

  // initialize custom view form values
  const [initialValues, setInitialValues] = useState<ICustomViewCreate>({
    engagements: null,
    filters: null,
    isExecutiveView: role === CustomViewRole.Executive,
    createType: ICustomViewCreateType.CustomSaved,
    name: '',
    type: viewType || ICustomViewType.Dynamic
  });

  // indicates that a static/dynamic view has been updated by the user; on view
  // edit, submit button will be disabled until view is edited
  const [isEdited, setIsEdited] = useState(false);

  // custom view endpoint requests
  const [request, setRequest] = useStateMounted<Promise<any> | null>(null);

  // form configuration
  const formik = useFormik<ICustomViewCreate>({
    validationSchema: yup.object({
      isExecutiveView: yup.boolean(),
      createType: yup.number(),
      name: yup
        .string()
        .trim()
        .max(
          maxCustomViewNameLength,
          t('customView.viewName.maxLength', {
            length: maxCustomViewNameLength
          })
        )
        .when('createType', {
          is: ICustomViewCreateType.CustomSaved,
          then: yup.string().required(t('customView.viewName.required'))
        })
    }),
    initialValues,
    enableReinitialize: true,
    onSubmit: async values => {
      const isOneTimeUse =
        values.createType === ICustomViewCreateType.CustomOneTimeUse;

      const viewRequest: ICustomViewCreate = {
        ...values,

        // filters do not need to be sent for static views
        filters: values.type === ICustomViewType.Static ? null : values.filters,

        // add default name for one-time use views
        name: isOneTimeUse ? t('Custom View') : values.name
      };

      try {
        const responsePromise =
          isEditing && values.customViewGuid
            ? ApiService.editView(values.customViewGuid, viewRequest)
            : ApiService.createView(viewRequest);
        setRequest(responsePromise);
        const { data: newCustomView } = await responsePromise;

        // push success toast
        pushToast({
          type: 'success',
          title: t(
            'customView.success',
            isEditing || !isOneTimeUse
              ? {
                  context: isEditing ? 'edit' : 'save',
                  name: newCustomView.name
                }
              : {}
          )
        });

        // update current view with new view
        setActiveView(newCustomView);

        // call onSubmit handler on successful submission
        onSubmit?.();
      } catch {
        pushServerErrorToast();
      } finally {
        setRequest(null);
      }
    }
  });

  // ref for formik methods used in hooks to prevent excessive hook calls
  const formikSetFieldValueRef = useRef(formik.setFieldValue);
  const isDynamicView = formik.values.type === ICustomViewType.Dynamic;

  // list of unstable filters for the current builder session
  const [filters, setFilters] = useState<(ICustomViewFilter | null)[]>(
    formik.values.filters && formik.values.filters.length > 0
      ? formik.values.filters
      : [emptyFilter]
  );

  // number of active, non-null filters for rendering controls
  const [activeFilterNum, setActiveFilterNum] = useState(0);

  // indicates a view is currently loading to be edited
  const [editViewIsLoading, setEditViewIsLoading] = useStateMounted(false);

  // state variables for rendering project preview table
  const [previewEngagements, setPreviewEngagements] = useState<IEngagement[]>(
    []
  );
  const [previewLoading, setPreviewLoading] = useStateMounted(false);
  const [previewPage, setPreviewPage] = useState(1);
  const [previewPageSize, setPreviewPageSize] = useState(5);
  const [previewSearch, setPreviewSearch] = useState('');
  const [previewSort, setPreviewSort] = useState<ISortDescriptor[]>([
    {
      sortDirection: ISortDirection.Ascending,
      sortPropertyName: 'engagementDisplayNameLong'
    }
  ]);
  const [previewTotal, setPreviewTotal] = useState(0);
  const tableSortValue = useMemo(
    () => ({
      id: previewSort[0]?.sortPropertyName || '',
      desc: previewSort[0]?.sortDirection === ISortDirection.Descending
    }),
    [previewSort]
  );

  // preview table column configuration
  const previewColumns = useMemo(
    (): ITableColumn<IEngagement>[] => [
      {
        id: 'engagementDisplayNameLong',
        label: t('projectOverview.columns.project'),
        render: row => (
          <>
            <b>{row.engagementDisplayNameLong}</b>
            <br />
            <small>{row.accountName}</small>
          </>
        ),
        sortable: true
      },
      {
        id: 'country',
        label: t('projectOverview.columns.country'),
        render: row => `${row.country ? row.country : '–'}`,
        sortable: true
      },
      {
        id: 'isActive',
        label: t('projectOverview.columns.isActive'),
        render: row => (
          <div className="projects-overview__pills">
            <div className={`pill ${row.isActive ? 'active' : 'inactive'}`}>
              {t(row.isActive ? 'Active' : 'Inactive')}
            </div>
          </div>
        ),
        sortable: true
      }
    ],
    [t]
  );

  // cancel token source for fetching a view
  const getViewSourceRef = useRef<CancelTokenSource>();

  // cancel token source for engagement preview table requests
  const previewSourceRef = useRef<CancelTokenSource>();

  // used to cache timer for fetching previews
  // NOTE: Changing filter types can cause multiple field updates as a
  // a type change can cause a value change when a value is nulled. Short
  // timeout is used to prevent these unnecessary calls.
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  /**
   * Fetches new preview results on click of new page or change in items per
   * page.
   */
  const handlePreviewPageChange = useCallback(
    (page: number, pageSize: number) => {
      setPreviewPage(page);
      setPreviewPageSize(pageSize);
    },
    []
  );

  /**
   * Fetches filtered results based on a search term.
   */
  const handlePreviewSearch = useCallback((searchTerm: string) => {
    setPreviewSearch(searchTerm);
    setPreviewPage(1);
  }, []);

  /**
   * Fetches sorted results.
   */
  const handlePreviewSort = useCallback(
    (sorted: { id: string; desc: boolean } | null) => {
      if (sorted) {
        setPreviewSort([
          {
            sortDirection: sorted.desc
              ? ISortDirection.Descending
              : ISortDirection.Ascending,
            sortPropertyName: sorted.id
          }
        ]);

        // reset page number on sort to see start of results
        setPreviewPage(1);
      }
    },
    []
  );

  /**
   * Fetches a list of engagements matching the defined filters.
   */
  const fetchPreview = useCallback(async () => {
    const filtersInForm = formik.values.filters;
    const isExecutiveView = formik.values.isExecutiveView;

    // dynamic views should be paginated while static should be a single large
    // page
    const requestPagedDataParams = isDynamicView
      ? {
          itemsPerPage: previewPageSize,
          pageNumber: previewPage,
          searchTerm: previewSearch,
          sort: previewSort
        }
      : {
          itemsPerPage: 1000,
          pageNumber: 1,
          searchTerm: '',
          sort: previewSort
        };

    try {
      setPreviewLoading(true);
      const {
        data: { results, totalResults }
      } = await ApiService.getEngagementPreview(
        {
          ...requestPagedDataParams,
          filters: filtersInForm,
          isExecutiveView
        },
        previewSourceRef.current?.token
      );
      setPreviewEngagements(results);
      setPreviewTotal(totalResults);

      // reset page number if no results
      if (results.length === 0 && previewPage !== 1) {
        setPreviewPage(1);
      }
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      setPreviewLoading(false);
    }
  }, [
    formik.values.filters,
    formik.values.isExecutiveView,
    isDynamicView,
    previewPage,
    previewPageSize,
    previewSearch,
    previewSort,
    setPreviewLoading
  ]);

  /**
   * Adds a new filter to the unstable list of filters.
   */
  const filterAdd = useCallback(() => {
    setFilters(prevFilters => [...prevFilters, emptyFilter]);
  }, []);

  /**
   * Deletes a filter from the unstable list of filters.
   * NOTE: Index used as key as new filters will not have a unique guid. Null
   * deleted filters instead of removing from list so each filter's index
   * remains stable.
   */
  const filterDelete = useCallback((indexToDelete: number) => {
    setFilters(prevFilters => {
      const updatedFilters = [...prevFilters];
      updatedFilters[indexToDelete] = null;
      return updatedFilters;
    });
  }, []);

  /**
   * Updates list of unstable filters on filter change.
   */
  const filterUpdate = useCallback(
    (indexToUpdate: number, updatedFilter: ICustomViewFilter) => {
      setFilters(prevFilters => {
        const updatedFilters = [...prevFilters];
        updatedFilters[indexToUpdate] = updatedFilter;
        return updatedFilters;
      });
    },
    []
  );

  // exclude user-related type options for non-Ryan users
  const excludedFilterTypes: ICustomViewFilterType[] =
    permissionService.isRyan()
      ? [ICustomViewFilterType.Visibility]
      : [
          ICustomViewFilterType.AccountManagement,
          ICustomViewFilterType.ProjectManagement,
          ICustomViewFilterType.MarketingProfessional,
          ICustomViewFilterType.Visibility
        ];

  const isOneTimeUse =
    formik.values.createType === ICustomViewCreateType.CustomOneTimeUse;

  // allow submit once user has selected a filter or projects in dynamic and
  // static respectively
  const isSubmitDisabled =
    editViewIsLoading ||
    !isEdited ||
    (!isDynamicView
      ? !formik.values.engagements || formik.values.engagements.length === 0
      : !formik.values.filters || formik.values.filters.length === 0);

  /**
   * Updates engagements in form on update to static view project picker.
   */
  const handleProjectPickerOnChange = useCallback(
    (selectedProjects: IEngagement[]) => {
      formikSetFieldValueRef.current('engagements', selectedProjects);
      setIsEdited(true);
    },
    []
  );

  /**
   * Renders cancel/submit form controls.
   */
  const renderFormControls = () => (
    <ButtonGroup>
      <Button
        disabled={isSubmitDisabled}
        loading={request}
        text={t(
          'customView.submit',
          isEditing || !isOneTimeUse
            ? {
                context: isEditing ? 'edit' : 'save'
              }
            : {}
        )}
        type="submit"
        variant={EButtonVariant.PRIMARY}
      />
      <Button
        disabled={!!request}
        onClick={onCancel}
        text={t('Cancel')}
        type="button"
        variant={EButtonVariant.SECONDARY}
      />
    </ButtonGroup>
  );

  /**
   * Resets the engagement preview table.
   */
  const resetPreview = useCallback(() => {
    setPreviewEngagements([]);
    setPreviewLoading(false);
    setPreviewPage(1);
    setPreviewSearch('');
    setPreviewTotal(0);
  }, [setPreviewLoading]);

  // update formik methods stored in refs
  useEffect(() => {
    formikSetFieldValueRef.current = formik.setFieldValue;
  }, [formik]);

  // toggle information well on parent custom view page
  useEffect(() => {
    setShowWell(true);

    return () => {
      setShowWell(false);
    };
  }, [setShowWell]);

  // get existing custom view data to edit
  useEffect(() => {
    /**
     * Fetches custom view by guid.
     */
    const fetchCustomView = async (guid: string) => {
      // trigger reset of preview table - may be already populated if switching
      // between views to edit
      setPreviewEngagements([]);
      setPreviewPage(1);
      setPreviewSearch('');

      // initialize cancel token
      getViewSourceRef.current = ApiService.CancelToken.source();

      try {
        setEditViewIsLoading(true);
        const { data } = await ApiService.getView(
          guid,
          getViewSourceRef.current.token
        );

        // update initial form values but update filters separately as filters
        // in form will be updated via hook
        setInitialValues({
          ...data,
          filters: null
        });
        setFilters(
          data.filters && data.filters.length > 0 ? data.filters : [emptyFilter]
        );

        // update information well
        setRole(
          data.isExecutiveView
            ? CustomViewRole.Executive
            : CustomViewRole.Personal
        );
        setViewType(data.type);

        // reset edit status on custom view fetch
        // NOTE: Wrap in setTimeout to delay dispatch until previous dispatches
        // executive above (updating filters/engagements will set edit status
        // to true and may overwrite this update). Not a true callback but
        // working for now...
        setTimeout(() => {
          setIsEdited(false);
        });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();

          // back out of edit if unable to fetch custom view
          onCancel();
        }
      } finally {
        setEditViewIsLoading(false);
      }
    };

    if (customViewGuid) {
      fetchCustomView(customViewGuid);
    }

    return () => {
      getViewSourceRef.current?.cancel();
    };
  }, [customViewGuid, onCancel, setEditViewIsLoading, setRole, setViewType]);

  // update filters in form on filter update
  useEffect(() => {
    // only apply filters that fully defined; transform values of defined
    // filters to string/number value to support create call value type
    const definedFilters = filters
      .filter(filter => isDefinedFilter(filter))
      .map(filter => filter && transformFilterValue(filter));

    // only update if filters have actually changed value to avoid redundant
    // preview endpoint calls
    if (!isEqual(formik.values.filters, definedFilters)) {
      formikSetFieldValueRef.current('filters', definedFilters);

      // if dynamic custom view, update edit status on filter update
      if (formik.values.type === ICustomViewType.Dynamic) {
        setIsEdited(true);
      }
    }

    // update list of active, non-null filters
    setActiveFilterNum(
      filters ? filters.filter(filter => filter !== null).length : 0
    );
  }, [filters, formik.values.filters, formik.values.type]);

  // update engagement table when filters in form are updated or preview page
  // has been updated
  useEffect(() => {
    const filtersInForm = formik.values.filters;

    // (re)initialize request cancel token
    previewSourceRef.current = ApiService.CancelToken.source();

    // clear previous fetch timeout to avoid unnecessary calls
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // update preview table if filters are defined or reset otherwise
    if (filtersInForm && filtersInForm.length > 0) {
      timeoutRef.current = setTimeout(() => {
        fetchPreview();
      }, 250);
    } else {
      resetPreview();
    }

    // cancel any queued fetch or ongoing requests on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      previewSourceRef.current?.cancel(
        'cancelling CustomViewBuilder engagement preview fetch'
      );
    };
  }, [
    formik.values.filters,
    previewPage,
    previewPageSize,
    previewSearch,
    previewSort,
    fetchPreview,
    resetPreview
  ]);

  // update edit state on form name update
  useEffect(() => {
    setIsEdited(true);
  }, [formik.values.name]);

  return (
    <div className="custom-view-builder">
      <form autoComplete="off" className="row" onSubmit={formik.handleSubmit}>
        <div className="col-lg-9">
          {!isEditing && (
            <>
              <label className="ry-label">
                {t('customView.saveView.label')}
              </label>
              <RadioButtonGroup
                {...formikFieldProps('createType', formik)}
                name={t('customView.saveView.label')}
                onChange={(e, value) => {
                  formik.setFieldValue('createType', Number(value));
                }}
                value={String(formik.values.createType)}
              >
                <RadioButton
                  label={t('customView.saveView.save')}
                  value={String(ICustomViewCreateType.CustomSaved)}
                />
                <RadioButton
                  label={t('customView.saveView.oneTimeUse')}
                  value={String(ICustomViewCreateType.CustomOneTimeUse)}
                />
              </RadioButtonGroup>
            </>
          )}
          {formik.values.createType === ICustomViewCreateType.CustomSaved && (
            <TextInput
              {...formikFieldProps('name', formik)}
              disabled={!!request || editViewIsLoading}
              label={t('customView.viewName.label')}
            />
          )}
          <div className="custom-view-builder__filters">
            <h3 className="ry-h3">{t('customView.filter.title')}</h3>
            {filters.map(
              (filter, i) =>
                filter && (
                  // NOTE: there is an issue where react still triggers onClicks
                  // on buttons within disabled fieldsets so need to disable each
                  // individually for now: https://github.com/facebook/react/issues/7711
                  <fieldset key={`${formik.values.customViewGuid}|${i}`}>
                    <MemoCustomViewFilter
                      disabled={!!request || editViewIsLoading}
                      exclude={excludedFilterTypes}
                      executiveAccessEnabled={formik.values.isExecutiveView}
                      onChange={updatedFilter => filterUpdate(i, updatedFilter)}
                      value={filter}
                    />
                    <div className="custom-view-builder__filter-controls">
                      {activeFilterNum > 1 && (
                        <Button
                          ariaLabel={t('customView.filter.delete')}
                          disabled={!!request || editViewIsLoading}
                          icon="trash"
                          negative
                          onClick={() => filterDelete(i)}
                          variant={EButtonVariant.TEXT}
                        />
                      )}
                      <Button
                        ariaLabel={t('customView.filter.add')}
                        className="custom-view-builder__filter-add"
                        disabled={!!request || editViewIsLoading}
                        icon="plus"
                        onClick={filterAdd}
                        variant={EButtonVariant.TEXT}
                      />
                    </div>
                  </fieldset>
                )
            )}
          </div>
          {isDynamicView && renderFormControls()}
        </div>
        {!isDynamicView && (
          <div className="col-md-12">
            <CustomViewProjectPicker
              loading={previewLoading}
              onChange={handleProjectPickerOnChange}
              onSortChange={handlePreviewSort}
              projects={previewEngagements}
              selected={formik.values.engagements}
              sorted={tableSortValue}
            />
            {renderFormControls()}
          </div>
        )}
      </form>
      {isDynamicView && (
        <div className="row">
          <div className="col-lg-9">
            <Table<IEngagement>
              className="custom-view-builder__preview"
              columns={previewColumns}
              data={previewEngagements}
              loading={previewLoading}
              onPageChange={handlePreviewPageChange}
              onSearchChange={handlePreviewSearch}
              onSortChange={handlePreviewSort}
              page={previewPage}
              pageSize={previewPageSize}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              renderActionPlacement={1}
              renderEmpty={() => (
                <EmptyPreviewTable searchQuery={previewSearch}>
                  {t('customView.preview.empty')}
                </EmptyPreviewTable>
              )}
              rowId="engagementGuid"
              searchQuery={previewSearch}
              sorted={tableSortValue}
              title={`${t('customView.preview.header')}${
                previewTotal ? ` (${previewTotal})` : ''
              }`}
              totalCount={previewTotal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomViewBuilder;
