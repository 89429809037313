export enum EColumnId {
  ACTIONS = 'actions',
  ASSIGNED_TO = 'assignedTo',
  COMMENT = 'comment',
  DATA_TYPE = 'dataType',
  DUE_DATE = 'dueDate',
  STATUS = 'status',
  TITLE = 'title',
  TRANSFERRED_FILES = 'transferredFiles'
}
