import Currency from 'components/Currency/Currency';
import { IInvoiceEngagements } from 'interfaces';

import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@ryan/components';

import './InvoiceEngagementCard.scss';

const InvoiceEngagementCard: FunctionComponent<{
  invoiceEngagement: IInvoiceEngagements;
  currencyCode: string;
}> = ({ invoiceEngagement, currencyCode }) => {
  const { t } = useTranslation();
  return (
    <Card
      className={classnames(
        'invoice-engagement-card',
        invoiceEngagement.status.toLocaleLowerCase()
      )}
      title={invoiceEngagement.engagementDisplayNameShort}
    >
      <div className="row">
        <div className="col-6">
          <ul className="labeled-list">
            <li>
              <label>{t('invoices.columns.serviceType')}</label>
              {invoiceEngagement.serviceType}
            </li>
            <li>
              <label>{t('invoices.columns.jurisdiction')}</label>
              {invoiceEngagement.jurisdiction}
            </li>
            {!!invoiceEngagement.siteName && (
              <li>
                <label>{t('invoices.columns.siteName')}</label>
                {invoiceEngagement.siteName}
              </li>
            )}
          </ul>
        </div>
        <div className="col-6 highlight">
          <ul className="labeled-list">
            <li>
              <label>{t('invoices.columns.status')}</label>
              <span className="highlight__text">
                {t(`invoices.status.${invoiceEngagement.statusId}`)}
              </span>
            </li>
            <li>
              <label>{t('invoices.columns.invoiceAmount')}</label>
              <span className="highlight__amount">
                <Currency
                  currencyCode={currencyCode}
                  value={invoiceEngagement.amountDue}
                />
              </span>
            </li>
            <li>
              <label>{t('invoices.columns.balance')}</label>
              <span className="highlight__balance">
                <Currency
                  currencyCode={currencyCode}
                  includeCreditFormat
                  value={invoiceEngagement.balance}
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default InvoiceEngagementCard;
