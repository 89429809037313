import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { pushToast } from '@ryan/components';
import { WithUser, withUser } from 'contexts/UserContext';
import { IAccount, ICustomViewSummary } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import { CheckedAccounts } from './CheckedAccounts';
import SwitcherContext, { ISwitcherContext } from './SwitcherContext';

interface ISwitcherProviderProps extends WithTranslation, WithUser {
  children?: React.ReactNode;
}

interface ISwitcherProviderState {
  context: ISwitcherContext;

  /**
   * Executive accounts request status to prevent creating  multiple requests.
   */
  isLoadingExecutiveAccounts: boolean;
}

export class SwitcherProvider extends Component<
  ISwitcherProviderProps,
  ISwitcherProviderState
> {
  constructor(props: ISwitcherProviderProps) {
    super(props);
    this.state = {
      context: {
        openDesktop: false,
        openMobile: false,
        searchQuery: '',
        expanded: {},
        openFilterPane: false,
        filterExecutiveAccess: false,
        filterTab: 'companies',
        filterCompanies: CheckedAccounts(props.user.accountTree, false),
        filterCustomView: null,
        filterApplyLoading: null,
        onToggleDesktop: this.onToggleDesktop,
        onToggleMobile: this.onToggleMobile,
        onSearchChange: this.onSearchChange,
        onAccountToggle: this.onAccountToggle,
        onAccountSelect: this.onAccountSelect,
        onFilterPaneToggle: this.onFilterPaneToggle,
        onFilterPaneReset: this.onFilterPaneReset,
        onFilterTabSelect: this.onFilterTabSelect,
        onFilterCompanyToggle: this.onFilterCompanyToggle,
        onFilterCompanyToggleAll: this.onFilterCompanyToggleAll,
        onFilterExecutiveToggle: this.onFilterExecutiveToggle,
        onFilterCustomViewSelect: this.onFilterCustomViewSelect,
        onFilterCustomViewApply: this.onFilterCustomViewApply,
        onFilterCustomViewDelete: this.onFilterCustomViewDelete
      },
      isLoadingExecutiveAccounts: false
    };
  }

  setContext(
    updater: (context: ISwitcherContext) => Partial<ISwitcherContext>,
    callback?: () => void
  ) {
    this.setState(
      ({ context }) => ({
        context: { ...context, ...updater(context) }
      }),
      callback
    );
  }

  close() {
    const { accountTree } = this.props.user;
    this.setContext(() => ({
      openDesktop: false,
      openMobile: false,
      openFilterPane: false,
      searchQuery: '',
      expanded: {},
      filterTab: 'companies',
      filterExecutiveAccess: false,
      filterCompanies: CheckedAccounts(accountTree, false),
      filterCustomView: null
    }));
  }

  /**
   * Toggle Switcher
   */

  /**
   * Toggles the visibility of the desktop switcher.
   *
   * @param open Coerces visibilty of the switcher. Opens the switcher if `true`
   *  and closes it if `false`.
   */
  onToggleDesktop = (open?: boolean) => {
    if (
      (typeof open === 'boolean' && !open) ||
      (typeof open === 'undefined' && this.state.context.openDesktop)
    ) {
      this.close();
    } else {
      this.setContext(() => ({ openDesktop: true }));
    }
  };

  onToggleMobile = () => {
    if (this.state.context.openMobile) {
      this.close();
    } else {
      this.setContext(() => ({ openMobile: true }));
    }
  };

  /**
   * Accounts
   */

  onSearchChange = (searchQuery: string) => {
    this.setContext(() => ({
      searchQuery
    }));
  };

  onAccountToggle = (account: IAccount) => {
    const { accountGuid } = account;
    this.setContext(context => ({
      expanded: {
        ...context.expanded,
        [accountGuid]: !context.expanded[accountGuid]
      }
    }));
  };

  onAccountSelect = async (
    account: IAccount,
    includingSubsidiaries: boolean
  ) => {
    const { setActiveAccount } = this.props;
    await setActiveAccount(account, includingSubsidiaries, false);
    this.close();
  };

  /**
   * Filter Pane
   */

  getEmptyCheckedAccounts(isExecutiveView: boolean) {
    const { user, executiveAccounts } = this.props;
    return CheckedAccounts(
      isExecutiveView ? executiveAccounts || [] : user.accountTree,
      isExecutiveView
    );
  }

  onFilterPaneToggle = () => {
    this.setContext(context => ({
      openFilterPane: !context.openFilterPane,
      filterCompanies: this.getEmptyCheckedAccounts(false),
      filterCustomView: null,
      filterExecutiveAccess: false,
      filterTab: 'companies'
    }));
  };

  onFilterPaneReset = () => {
    this.setContext(() => ({
      searchQuery: '',
      expanded: {},
      filterTab: 'companies',
      filterExecutiveAccess: false,
      filterCompanies: this.getEmptyCheckedAccounts(false),
      filterCustomView: null
    }));
  };

  onFilterTabSelect = (filterTab: 'companies' | 'custom-views') => {
    this.setContext(prevContext => ({
      filterTab,
      filterCompanies: this.getEmptyCheckedAccounts(
        prevContext.filterExecutiveAccess
      ),
      filterCustomView: null
    }));
  };

  onFilterCompanyToggle = (
    accountGuid: string,
    includingSubsidiaries: boolean
  ) => {
    this.setContext(context => ({
      filterCompanies: context.filterCompanies.toggle(
        accountGuid,
        includingSubsidiaries
      )
    }));
  };

  onFilterCompanyToggleAll = () => {
    this.setContext(context => ({
      filterCompanies: context.filterCompanies.toggleAll()
    }));
  };

  onFilterExecutiveToggle = async () => {
    const { executiveAccounts, loadExectiveAccounts } = this.props;
    const {
      context: { filterExecutiveAccess },
      isLoadingExecutiveAccounts
    } = this.state;

    // Toggle executive accounts switch
    this.setContext(prevContext => ({
      filterExecutiveAccess: !prevContext.filterExecutiveAccess,
      filterCompanies: executiveAccounts
        ? this.getEmptyCheckedAccounts(!prevContext.filterExecutiveAccess)
        : prevContext.filterCompanies
    }));

    // Load executive accounts if not yet loaded. Store reference to request
    // Promise to prevent creating multiple requests.
    if (
      !executiveAccounts &&
      !filterExecutiveAccess &&
      !isLoadingExecutiveAccounts
    ) {
      this.setState({ isLoadingExecutiveAccounts: true });
      await loadExectiveAccounts();

      // update company list once executive accounts have been fetched
      this.setContext(prevContext => ({
        filterCompanies: this.getEmptyCheckedAccounts(
          prevContext.filterExecutiveAccess
        )
      }));
      this.setState({ isLoadingExecutiveAccounts: false });
    }
  };

  onFilterCustomViewSelect = (view: ICustomViewSummary) => {
    this.setContext(() => ({
      filterCustomView: view
    }));
  };

  onFilterCustomViewDelete = (view: ICustomViewSummary) => {
    const { deleteCustomView } = this.props;
    deleteCustomView(view);
  };

  onFilterCustomViewApply = async () => {
    const { t, setActiveView } = this.props;
    const { filterTab, filterCompanies, filterCustomView } = this.state.context;

    if (filterTab === 'companies' && filterCompanies.view) {
      const promise = ApiService.createView(filterCompanies.view);
      this.setContext(() => ({ filterApplyLoading: promise }));

      try {
        const { data: view } = await promise;

        pushToast({
          type: 'success',
          title: t('customView.success')
        });

        setActiveView(view);
        this.close();
      } catch {
        pushServerErrorToast();
      }

      this.setContext(() => ({ filterApplyLoading: null }));
    }

    if (filterTab === 'custom-views' && filterCustomView) {
      setActiveView(filterCustomView);
      this.close();
    }
  };

  render() {
    const { children } = this.props;
    const { context } = this.state;
    return (
      <SwitcherContext.Provider value={context}>
        {children}
      </SwitcherContext.Provider>
    );
  }
}

export default withTranslation()(withUser(SwitcherProvider));
