import { CancelToken } from 'axios';

import {
  DSSDocument,
  IContactInfoForm,
  IEngagementUser,
  INotificationPreferenceGroup,
  IPagedDataResponse,
  ITeamSearchParams,
  IUser,
  IUserIdentity,
  IUserSearchParams,
  IUserSummary,
  IUserSummaryResponse,
  UserType
} from '../../interfaces';
import { transformResponse } from '../../utils/transformResponse';
import ApiBase from './ApiBase';

//
//
// User Profile
//
//

export async function updateUserInfo(
  this: ApiBase,
  userGuid: string,
  contactInfo: IContactInfoForm
) {
  return this.server.put<IUser>(`/api/users/${userGuid}`, contactInfo, {
    transformResponse
  });
}

export async function updateClientAvatar(
  this: ApiBase,
  userGuid: string,
  dssDocument: DSSDocument
) {
  return this.server.post(`/api/users/${userGuid}/image`, {
    document: dssDocument
  });
}

/**
 * Get Notification Preferences
 */
export async function getNotificationPreferences(this: ApiBase) {
  return this.server.get<INotificationPreferenceGroup[]>(
    `/api/userprofile/notificationpreferences`
  );
}

/**
 * Save Notification Preferences
 */
export async function saveNotificationPreferences(
  this: ApiBase,
  notificationPreferences: INotificationPreferenceGroup[]
) {
  return this.server.put<INotificationPreferenceGroup[]>(
    `/api/userprofile/notificationpreferences`,
    { notificationPreferences }
  );
}

//
//
// Users
//
//

export async function getUser(
  this: ApiBase,
  userGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IUser>(`/api/users/${userGuid}`, { cancelToken });
}

export async function getUserInfoForProfilePage(
  this: ApiBase,
  userGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get(`/api/users/profile/nur/${userGuid}`, { cancelToken });
}

export async function getUserInfo(this: ApiBase, cancelToken?: CancelToken) {
  return this.server.get<{
    type: UserType;
    userFullName: string;
    userFirstName: string;
    userLastName: string;
  }>('api/users/info', { cancelToken });
}

export async function getUsersAutocomplete(
  this: ApiBase,
  params: {
    accountGuid?: string;
    email?: string;
    engagementGuid?: string;
    isDxpUser?: boolean; // We can search for dxp and non dxp users -- default is true
    searchTerm?: string;
    userType?: UserType;
  } = {}
) {
  return this.server.get<IUserSummary[]>(`/api/users/autocomplete`, {
    params
  });
}

export async function getExistingUserByEmail(
  this: ApiBase,
  params: { email?: string } = {}
) {
  return this.server.get<IUserSummary>(`/api/newuser/existing`, {
    params
  });
}

export async function getUserManagerEmail(this: ApiBase, userGuid: string) {
  return this.server.get<{ UserGuid: string; userManagerEmail: string }>(
    `/api/users/${userGuid}/manageremail`
  );
}

//
//
// Teams
//
//

export async function getTeamUsersByAccount(
  this: ApiBase,
  accountGuid: string,
  params: ITeamSearchParams
) {
  return this.server.get<IPagedDataResponse<IUser>>(
    `/api/accounts/${accountGuid}/team/search`,
    { params }
  );
}

export async function getTeamUsersByCustomView(
  this: ApiBase,
  customViewGuid: string,
  params: ITeamSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IUser>>(
    `/api/customViews/${customViewGuid}/team/search`,
    { cancelToken, params }
  );
}

export async function getTeamUsersByEngagement(
  this: ApiBase,
  engagementGuid: string,
  params: ITeamSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IEngagementUser>>(
    `/api/engagements/${engagementGuid}/team/search`,
    { cancelToken, params }
  );
}

/**
 * @todo duplicate?
 */
export async function getAllEngagementMembers(
  this: ApiBase,
  engagementGuid: string,
  userType?: UserType
) {
  return this.server.get<IUserSummaryResponse>(
    `/api/engagements/${engagementGuid}/members`,
    { params: { userType } }
  );
}

//
//
// Manage Teams
//
//

export async function getTeamUsersToManageByCustomView(
  this: ApiBase,
  customViewGuid: string,
  params: ITeamSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IUser>>(
    `/api/customViews/${customViewGuid}/manage/team/search`,
    { cancelToken, params }
  );
}

export async function getTeamUsersToManageByEngagement(
  this: ApiBase,
  engagementGuid: string,
  params: ITeamSearchParams
) {
  return this.server.get<IPagedDataResponse<IEngagementUser>>(
    `/api/engagements/${engagementGuid}/manage/team/search`,
    { params }
  );
}

//
//
// Administrator
//
//

export async function getUsers(
  this: ApiBase,
  params: IUserSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IUser>>(`/api/users/search`, {
    cancelToken,
    params
  });
}

export async function startImpersonationSession(
  this: ApiBase,
  userToImpersonateGuid: string
) {
  return this.server.post<IUserIdentity>(
    `/api/identity/impersonate/${userToImpersonateGuid}`,
    {
      transformResponse
    }
  );
}

export async function stopImpersonationSession(this: ApiBase) {
  return this.server.post<IUserIdentity>(`/api/identity/stopimpersonating`, {
    transformResponse
  });
}

// TODO: Identify location for this interface as part of the refactor efforts
interface ISendUserActivationEmail {
  email: string;
  emailSentDate: string;
  emailScheduledDate: string;
  emailExpirationDate: string;
  guid: string;
}

export async function sendUserActivationEmail(
  this: ApiBase,
  guid: string,
  activateDateTimestamp: string
) {
  return this.server.put<ISendUserActivationEmail>(
    `api/newuser/${guid}/sendemail`,
    { activateDate: activateDateTimestamp }
  );
}
