import React, { useState } from 'react';

import UnsavedChangesContext, {
  IUnsavedChangesContext
} from './UnsavedChangesContext';

const UnsavedChangesProvider: React.FC = ({ children }) => {
  const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(false);
  const [blockNavigation, setBlockNavigation] = useState<boolean>(false);
  const [pendingLocation, setPendingLocation] = useState<any | null>(null);
  const [targetUrl, setTargetUrl] = useState<string>('');

  const contextValue: IUnsavedChangesContext = {
    blockNavigation,
    isUnsavedChanges,
    pendingLocation,
    setBlockNavigation,
    setIsUnsavedChanges,
    setPendingLocation,
    setTargetUrl,
    targetUrl
  };

  return (
    <UnsavedChangesContext.Provider value={contextValue}>
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export default UnsavedChangesProvider;
