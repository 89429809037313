import { IButtonLoadingState, IHelpFormRequest } from 'interfaces';
import ApiService from 'services/ApiService';
import { Formik, formikFieldProps, yup } from 'utils/forms';
import useHelpSubjectOptions from 'utils/useHelpSubjectOptions';

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dropdown,
  EButtonVariant,
  Textarea,
  pushToast
} from '@ryan/components';

import { IHelpModalProps } from '../../interfaces/IHelpModalProps';
import pushServerErrorToast from '../../utils/pushServerErrorToast';

import './HelpModalForm.scss';

const initialValues = {
  subject: '',
  message: ''
};

const maxLengthMessage = 2048;
class HelpModalForm extends Component<IHelpModalProps, IButtonLoadingState> {
  readonly state = {
    loading: null
  };

  private schema = yup.object({
    subject: yup
      .string()
      .required(this.props.t('helpForm.fields.subject.validation.required')),
    message: yup
      .string()
      .required(this.props.t('helpModal.message.required'))
      .max(
        maxLengthMessage,
        this.props.t('helpModal.message.maxLength', {
          length: maxLengthMessage
        })
      )
  });

  submitHelpModal = (values: IHelpFormRequest) => {
    const { t } = this.props;
    const promise = ApiService.submitHelpFormByUser(values)
      .then(response => {
        if (response.status === 200) {
          pushToast({
            type: 'success',
            title: t('helpModal.successToast.title'),
            content: t('helpModal.successToast.content')
          });

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          if (this.props.onClose) {
            this.props.onClose();
          }
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          pushServerErrorToast();
        }

        this.setState({ loading: null });
      });

    this.setState({ loading: promise });
  };

  render() {
    const { t, onClose } = this.props;
    const { loading } = this.state;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submitHelpModal}
        validationSchema={this.schema}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Dropdown
              {...formikFieldProps('subject', formik)}
              label={t('helpForm.fields.subject.label')}
              options={useHelpSubjectOptions(t)}
            />
            <Textarea
              {...formikFieldProps('message', formik)}
              label={t('helpModal.message.label')}
              maxLength={maxLengthMessage}
            />
            <ButtonGroup>
              <Button onClick={onClose} text={t('Cancel')} type="button" />
              <Button
                loading={loading}
                text={t('Submit')}
                type="submit"
                variant={EButtonVariant.PRIMARY}
              />
            </ButtonGroup>
          </form>
        )}
      </Formik>
    );
  }
}

export default withTranslation()(HelpModalForm);
