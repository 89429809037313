import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { WithUser, withUser } from 'contexts/UserContext';
import { IEngagement, UserType } from 'interfaces';
import ProjectTeamTable from './ProjectTeamTable';
import './ProjectTeamPage.scss';

interface IProjectTeamPageProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps<{ engagementGuid: string }> {
  engagement: IEngagement;
  canManageEngagement: boolean;
}

export const ProjectTeamPage: FunctionComponent<IProjectTeamPageProps> = ({
  t,
  permissionService: ps,
  engagement,
  canManageEngagement
}) => (
  <div className="project-team-page">
    <ProjectTeamTable
      engagement={engagement}
      header={t('Project Team')}
      isActiveUserWithManageEngagementAccess={canManageEngagement}
      userTypes={
        !ps.isThirdParty() ? UserType.Ryan | UserType.Client : UserType.Ryan
      }
    />
    {!ps.isThirdParty() && (
      <div className="project-team-page__third-party-table">
        <ProjectTeamTable
          engagement={engagement}
          header={t('Third Party')}
          isActiveUserWithManageEngagementAccess={canManageEngagement}
          userTypes={UserType.ThirdParty}
        />
      </div>
    )}
  </div>
);

export default withTranslation()(withUser(ProjectTeamPage));
