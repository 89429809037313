import { DirectoryFilterGuid, IDirectoryItem } from 'interfaces';

export default function getDirectoryItemIcon(item: IDirectoryItem) {
  switch (item.fileSearchFilterGuid) {
    case DirectoryFilterGuid.Folder:
      return 'folder-open';
    case DirectoryFilterGuid.Image:
      return 'landscape';
    case DirectoryFilterGuid.PDF:
      return 'file-ext-pdf';
    case DirectoryFilterGuid.Presentation:
      return 'presentation';
    case DirectoryFilterGuid.Spreadsheet:
      return 'file-spreadsheet';
    case DirectoryFilterGuid.WordDocument:
      return 'file';
    case DirectoryFilterGuid.Other:
    default:
      return 'data-file';
  }
}
