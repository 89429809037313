export enum EngagementStatus {
  // Treated as a flag in DB but column is an INT
  // Setting up as other enums in case these statuses are ever expanded on
  InActive = 0,
  Active = 1
}

export interface IEngagement {
  ultimateParentAccountGuid: string;
  ultimateParentAccountName: string;

  // parentAccountGuid: string | null;
  // parentAccountName: string | null;

  accountHierarchy: {
    accountGuid: string;
    name: string;
  }[];

  accountGuid: string;
  accountName: string;

  engagementGuid: string;
  engagementId: string;

  engagementDisplayNameShort: string;
  engagementDisplayNameLong: string;
  // friendlyNameShort: string;
  // friendlyNameLong: string;
  // internalEngagementName: string;

  engagementManagerGuid: string;
  engagementPrincipalGuid: string;
  accountHandlerGuid: string;

  // engagementDescription: string;
  projectDetails: string;

  parentPracticeAreaGuid: string;
  // practiceArea: string; //AKA service
  // serviceType: string; //AKA type

  country: string;
  countryAbbreviation: string;

  // To be used for invoicing
  billingCurrency: string | null;
  // To be used for everything NOT invoicing
  projectCurrency: string | null;

  jurisdiction: string;
  jurisdictionDisplayName?: string;
  // periodBegin: Date | null;
  // periodEnd: Date | null;
  statuteOfLimitations: string | null; // Date?

  // Dates
  // These dates are dxp facing only and could differ from the start and end dates for a project in Salesforce
  alternateBeginDate: string | null;
  projectedEndDate: string | null;
  publishedDate: string | null;

  isStartDateEditable: boolean;
  isProjectedEndDateEditable: boolean;
  isStatuteOfLimitationsDateEditable: boolean;

  isReadOnly: boolean; // !isActive || isUserGhosted
  isActive: boolean;
  isPublished: boolean;
  isUserGhosted: boolean;

  // Savings
  lastSavingsSummarySavingsTotal: number | null;
  lastSavingsSummaryAsOfDate: string | null;

  // Directory
  totalFileCount: number;
  totalFileSize: number;

  // Changed Date
  updateDate: string;

  // TAS Scheduling
  schedulingTabEnabled?: boolean;

  bankruptcyFilingDate?: string | null;
}
