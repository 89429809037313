import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@ryan/components';

import { SavingsCategoryBucketEnums } from '../../../../utils/enums/SavingsCategoryBucketEnums';
import { formikNumberInputProps } from '../../../../utils/forms';
import { SavingsCategoryDetailEnums } from '../utils';
import { IUpdateSavingsBucketFormProps } from './utils';

import './UpdateSavingsBucketForm.scss';

const UpdateSavingsBucketForm: React.FC<IUpdateSavingsBucketFormProps> = ({
  bucketName,
  formik
}) => {
  const { t: textToDisplay } = useTranslation();

  const { APPROVED, POTENTIAL, RECEIVED, SUBMITTED } =
    SavingsCategoryBucketEnums;
  const { ADDED, PASSED, TO_APPROVED, TO_RECEIVED, TO_SUBMITTED } =
    SavingsCategoryDetailEnums;

  const ROOT_TO_TEXT = 'modal.updateSavingsBucketModal.updateSavingsBucketForm';

  return (
    <div className="update-savings-bucket-form">
      <div className="update-savings-bucket-form__directions">
        {textToDisplay(`${ROOT_TO_TEXT}.addOrDropSavings`)}
      </div>

      <div className="update-savings-bucket-form__added-savings">
        <TextInput
          {...formikNumberInputProps(ADDED, formik)}
          icon="add-square"
          label={textToDisplay(`${ROOT_TO_TEXT}.newAmount`)}
        />
      </div>

      <div className="update-savings-bucket-form__dropped-savings">
        <TextInput
          {...formikNumberInputProps(PASSED, formik)}
          icon="subtract-square"
          label={textToDisplay(`${ROOT_TO_TEXT}.droppedAmount`)}
        />
      </div>

      {bucketName !== RECEIVED && (
        <div className="update-savings-bucket-form__additional-options">
          <div className="update-savings-bucket-form__additional-options__directions">
            {textToDisplay(`${ROOT_TO_TEXT}.updateFlowOfSavings`)}
          </div>

          {bucketName === POTENTIAL && (
            <TextInput
              {...formikNumberInputProps(TO_SUBMITTED, formik)}
              icon="advance-to"
              label={textToDisplay(`${ROOT_TO_TEXT}.moveToSubmitted`)}
            />
          )}

          {(bucketName === POTENTIAL || bucketName === SUBMITTED) && (
            <TextInput
              {...formikNumberInputProps(TO_APPROVED, formik)}
              icon="advance-to"
              label={textToDisplay(`${ROOT_TO_TEXT}.moveToApproved`)}
            />
          )}

          {(bucketName === POTENTIAL ||
            bucketName === SUBMITTED ||
            bucketName === APPROVED) && (
            <TextInput
              {...formikNumberInputProps(TO_RECEIVED, formik)}
              icon="advance-to"
              label={textToDisplay(`${ROOT_TO_TEXT}.moveToReceived`)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UpdateSavingsBucketForm;
