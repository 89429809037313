import { parse } from 'query-string';
import ttiPolyfill from 'tti-polyfill';
import ReactGA from 'react-ga';
import ENV from 'env';
import history from 'services/history';
import { scrubSensitiveDataFromURL } from 'utils/scrubSensitiveDataFromURL';

const navigationCallback: PerformanceObserverCallback = list => {
  list.getEntries().forEach(baseEntry => {
    const entry = baseEntry as PerformanceNavigationTiming;
    ReactGA.timing({
      category: 'Load',
      variable: 'server latency',
      value: Math.round(entry.responseStart - entry.requestStart)
    });
    ReactGA.timing({
      category: 'Load',
      variable: 'download',
      value: Math.round(entry.responseEnd - entry.responseStart)
    });
    ReactGA.timing({
      category: 'Load',
      variable: 'total load',
      value: Math.round(entry.responseEnd - entry.requestStart)
    });
  });
};

const paintCallback: PerformanceObserverCallback = list => {
  list.getEntries().forEach(entry => {
    ReactGA.timing({
      category: 'Paint',
      variable: entry.name,
      value: Math.round(entry.startTime)
    });
  });
};

/**
 * Initializes page load performance tracking.
 */
const initPerformance = () => {
  // try to capture performance if Performance Observer API is supported
  try {
    // measure page load times
    const navigationObserver = new PerformanceObserver(navigationCallback);
    navigationObserver.observe({ entryTypes: ['navigation'] });

    // measure time to first paint
    const paintObserver = new PerformanceObserver(paintCallback);
    paintObserver.observe({ entryTypes: ['paint'] });
  } catch {}

  // measure time to first interactive
  ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {
    if (tti) {
      ReactGA.timing({
        category: 'Load',
        variable: 'time to interactive',
        value: Math.round(tti)
      });
    }
  });
};

let pageViewDebounce: number;

/**
 * Tracks a single page view.
 * @param page The name of the page to track.
 */
export const trackPageView = (page: string = window.location.pathname) => {
  // debounce page view so that routes that have internal routing do not
  // trigger a page view for both the base + re-routed page path
  clearTimeout(pageViewDebounce);
  pageViewDebounce = window.setTimeout(() => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }, 10);
};

/**
 * Initializes Google Analytics tracking.
 */
export const initialize = () => {
  const hash = parse(window.location.hash);
  ReactGA.initialize(ENV.GA_TRACKING_ID, {
    debug: typeof hash.debug !== 'undefined',
    gaOptions: {
      siteSpeedSampleRate: parseInt(ENV.GA_OPTION_SPEED_SAMPLE_RATE, 10)
    },
    standardImplementation: true,
    titleCase: false
  });
  ReactGA.set({
    appName: 'ClientPortal'
  });

  initPerformance();

  // track page view on route change
  history.listen(location => {
    trackPageView(
      scrubSensitiveDataFromURL(
        location.pathname + location.search + location.hash
      )
    );
  });
};

const Analytics = {
  initialize,
  trackPageView
};

export default Analytics;
