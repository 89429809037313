import * as yup from 'yup';

import { withFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  InfoWell,
  TextInput,
  Textarea
} from '@ryan/components';

import { SystemAutocomplete } from '../../../../../components/AutocompleteAjax';
import { withUser } from '../../../../../contexts/UserContext';
import {
  FolderSelection,
  IErpSystemTypes,
  ITaxEngineTypes
} from '../../../../../interfaces';
import ApiService from '../../../../../services/ApiService';
import * as FolderValidationUtils from '../../../../../utils/folderValidation/folderValidation.utils';
import {
  formikAutocompleteAjaxProps,
  formikFieldProps
} from '../../../../../utils/forms';
import pushServerErrorToast from '../../../../../utils/pushServerErrorToast';
import SelectFolder from '../../../../SelectFolder/SelectFolder';
import {
  IDataRequestFormProps,
  IDataRequestFormValues,
  IDataRequestInnerFormProps
} from './utils';

const FirstStepFormErp: React.FC<IDataRequestInnerFormProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);
  const [erpSystemTypesFetching, setErpSystemTypesFetching] = useState(false);
  const [taxEngineTypesFetching, setTaxEngineTypesFetching] = useState(false);
  const [otherERPTypeGuid, setOtherERPTypeGuid] = useState<string>('');
  const [otherTaxEngineTypeGuid, setOtherTaxEngineTypeGuid] =
    useState<string>('');

  const {
    engagement,
    erpSystem,
    errors,
    folders,
    foldersFetching,
    dataRequestTypeName,
    description,
    handleSubmit,
    isSubmitting,
    onNextStepClick,
    otherERPType,
    otherTaxEngineType,
    setDescription,
    setErpSystem,
    setFieldTouched,
    setFieldValue,
    setOtherERPType,
    setOtherTaxEngineType,
    setStatus,
    setTaxEngine,
    setTitle,
    setTouched,
    setTransferDestination,
    status,
    t: getTextToDisplay,
    transferDestination,
    taxEngine,
    title,
    values
  } = props;

  const selectedErpSystemType = values.selectedErpSystemType;
  const selectedTaxEngineType = values.selectedTaxEngineType;
  const isOtherErpSystemSelected =
    selectedErpSystemType?.erpSystemGuid === otherERPTypeGuid ||
    otherERPType.length > 0;
  const isOtherTaxEngineSelected =
    selectedTaxEngineType?.taxEngineGuid === otherTaxEngineTypeGuid ||
    otherTaxEngineType.length > 0;

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const transferDestinationError =
      transferDestination === null
        ? null
        : transferDestination?.folderName.trim().length === 0
        ? getTextToDisplay(
            'modal.dataRequestModal.fields.transferDestination.required'
          )
        : null;

    setStatus({
      ...status,
      transferDestinationError
    });

    setTouched({
      dataRequestTitle: true
    });
    handleSubmit();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.value;
    setFieldValue('dataRequestTitle', selected);
    setTitle(selected);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const selected = e.target.value;
    setFieldValue('descriptionOptional', selected);
    setDescription(selected);
  };

  const handleOtherErpTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setOtherERPType(value);
    setFieldValue('otherERPType', value);
  };

  const handleOtherTaxEngineTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;

    setOtherTaxEngineType(value);
    setFieldValue('otherTaxEngineType', value);
  };

  const fetchErpSystemTypes = useCallback(
    async (query: string): Promise<IErpSystemTypes[]> => {
      setErpSystemTypesFetching(true);
      try {
        const response = await ApiService.getErpSystemTypes(query);
        const erpSystemTypesData =
          response.data as unknown as IErpSystemTypes[];

        const otherType = erpSystemTypesData.find(
          type => type.name === 'Other'
        );
        setOtherERPTypeGuid(otherType?.erpSystemGuid ?? '');
        setFieldValue('otherERPTypeGuid', otherType?.erpSystemGuid ?? '');

        return erpSystemTypesData;
      } catch (error) {
        pushServerErrorToast();
        return [];
      } finally {
        setErpSystemTypesFetching(false);
      }
    },
    [setFieldValue]
  );

  const fetchTaxEngineTypes = useCallback(
    async (query: string): Promise<ITaxEngineTypes[]> => {
      setTaxEngineTypesFetching(true);
      try {
        const response = await ApiService.getTaxEngineTypes(query);
        const taxEngineTypesData =
          response.data as unknown as ITaxEngineTypes[];

        const otherType = taxEngineTypesData.find(
          type => type.name === 'Other'
        );
        setOtherTaxEngineTypeGuid(otherType?.taxEngineGuid ?? '');
        setFieldValue('otherTaxEngineTypeGuid', otherType?.taxEngineGuid ?? '');

        return taxEngineTypesData;
      } catch (error) {
        pushServerErrorToast();
        return [];
      } finally {
        setTaxEngineTypesFetching(false);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (title) {
      setFieldValue('dataRequestTitle', title);
    }
    if (erpSystem) {
      setFieldValue('selectedErpSystemType', erpSystem);
    }
    if (taxEngine) {
      setFieldValue('selectedTaxEngineType', taxEngine);
    }
    if (description) {
      setFieldValue('descriptionOptional', description);
    }
    if (otherERPType) {
      setFieldValue('otherERPType', otherERPType);
    }
    if (otherTaxEngineType) {
      setFieldValue('otherTaxEngineType', otherTaxEngineType);
    }
    if (transferDestination) {
      setFieldValue('defaultFolder', transferDestination);
    }
  }, [
    description,
    engagement,
    erpSystem,
    otherERPType,
    otherTaxEngineType,
    setFieldValue,
    taxEngine,
    title,
    transferDestination
  ]);

  useEffect(() => {
    fetchErpSystemTypes('');
    fetchTaxEngineTypes('');
  }, [engagement, fetchErpSystemTypes, fetchTaxEngineTypes]);

  const isValid =
    !isSubmitting &&
    !erpSystemTypesFetching &&
    !taxEngineTypesFetching &&
    !foldersFetching &&
    !Boolean(status.transferDestinationError);

  return (
    <>
      {status.error}
      <h2 className="step-title">
        {getTextToDisplay('modal.dataRequestModal.steps.step-2.title')}
      </h2>
      <InfoWell
        accountName={engagement?.accountName || ''}
        dataRequestType={dataRequestTypeName}
        projectName={engagement?.engagementDisplayNameLong || ''}
      />
      <form autoComplete="off" className="erp-form" ref={formRef}>
        <TextInput
          {...formikFieldProps('dataRequestTitle', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.requestTitle.label'
          )}
          onChange={e => handleTitleChange(e)}
        />
        <SystemAutocomplete<IErpSystemTypes>
          {...formikAutocompleteAjaxProps('selectedErpSystemType', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.erpSystemTypes.label'
          )}
          onBlur={() => setFieldTouched('selectedErpSystemType', true)}
          onChange={value => {
            setOtherERPType('');
            setFieldValue('otherERPType', '');
            setFieldValue('selectedErpSystemType', value);
            setErpSystem(value);
          }}
          onFetchOptions={fetchErpSystemTypes}
        />
        {isOtherErpSystemSelected && (
          <TextInput
            {...formikFieldProps('otherERPType', props)}
            label={getTextToDisplay(
              'modal.dataRequestModal.fields.otherErpSystem.label'
            )}
            onChange={e => handleOtherErpTypeChange(e)}
          />
        )}
        <SystemAutocomplete<ITaxEngineTypes>
          {...formikAutocompleteAjaxProps('selectedTaxEngineType', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.taxEngineTypes.label'
          )}
          onBlur={() => setFieldTouched('selectedTaxEngineType', true)}
          onChange={value => {
            setOtherTaxEngineType('');
            setFieldValue('otherTaxEngineType', '');
            setFieldValue('selectedTaxEngineType', value);
            setTaxEngine(value);
          }}
          onFetchOptions={fetchTaxEngineTypes}
        />
        {isOtherTaxEngineSelected && (
          <TextInput
            {...formikFieldProps('otherTaxEngineType', props)}
            label={getTextToDisplay(
              'modal.dataRequestModal.fields.otherTaxEngine.label'
            )}
            onChange={e => handleOtherTaxEngineTypeChange(e)}
          />
        )}
        <Textarea
          {...formikFieldProps('descriptionOptional', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.descriptionOptional.label'
          )}
          maxLength={600}
          onChange={e => handleDescriptionChange(e)}
          value={description}
        />
        <SelectFolder
          disabled={folders === null}
          feedback={status.transferDestinationError || errors.defaultFolder}
          folders={folders || []}
          invalid={!!status.transferDestinationError || !!errors.defaultFolder}
          isHideRyanInternal={true}
          label={getTextToDisplay(
            'dataRequest.modal.fields.defaultFolder.label'
          )}
          moveFileCount={2}
          onChange={(folder: FolderSelection) => {
            if (status.transferDestinationError) {
              setStatus({
                ...status,
                transferDestinationError: null
              });
            }
            if (folder?.folderGuid) {
              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            } else {
              const transferDestinationError =
                FolderValidationUtils.validateFolderInput(
                  folder,
                  folders
                ) as string;

              if (Boolean(transferDestinationError)) {
                setStatus({
                  ...status,
                  transferDestinationError: getTextToDisplay(
                    transferDestinationError
                  )
                });
              }

              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            }
          }}
          rootName={engagement ? engagement.engagementDisplayNameShort : ''}
          value={values.defaultFolder}
        />
        <ButtonGroup>
          <Button
            loading={status.loading}
            onClick={() => onNextStepClick(1)}
            text={getTextToDisplay('modal.dataRequestModal.back')}
            type="submit"
            variant={EButtonVariant.SECONDARY}
          />
          <Button
            disabled={!isValid}
            loading={status.loading}
            onClick={e => handleFormSubmit(e)}
            text={getTextToDisplay('modal.dataRequestModal.next')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const defaultValues: IDataRequestFormValues = {
  dataRequestTitle: '',
  defaultFolder: null,
  engagement: null,
  otherERPType: '',
  otherERPTypeGuid: '',
  otherTaxEngineType: '',
  otherTaxEngineTypeGuid: '',
  selectedErpSystemType: null,
  selectedTaxEngineType: null
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: () => defaultValues,

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: ({
      folders,
      t: getTextToDisplay
    }: IDataRequestFormProps & IDataRequestFormValues) =>
      yup.object({
        dataRequestTitle: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.requestTitle.required'
            )
          )
          .max(
            50,
            getTextToDisplay('modal.dataRequestModal.fields.requestTitle.max')
          ),
        descriptionOptional: yup
          .string()
          .max(
            600,
            getTextToDisplay(
              'modal.dataRequestModal.fields.descriptionOptional.max'
            )
          ),
        selectedErpSystemType: yup
          .object()
          .nullable()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.erpSystemTypes.required'
            )
          ),
        otherERPType: yup.string().when('selectedErpSystemType', {
          is: (selectedErpSystemType: IErpSystemTypes) => {
            return selectedErpSystemType;
          },
          then: yup
            .string()
            .test(
              'validate-other-erp-type',
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherErpSystem.required'
              ),
              function (value) {
                const { selectedErpSystemType, otherERPTypeGuid } = this.parent;
                if (selectedErpSystemType.erpSystemGuid === otherERPTypeGuid) {
                  return !!value;
                }

                return true;
              }
            )
            .max(
              50,
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherErpSystem.maxLength',
                { count: 50 }
              )
            )
        }),
        selectedTaxEngineType: yup
          .object()
          .nullable()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.taxEngineTypes.required'
            )
          ),
        otherTaxEngineType: yup.string().when('selectedTaxEngineType', {
          is: (selectedTaxEngineType: ITaxEngineTypes) => {
            return selectedTaxEngineType;
          },
          then: yup
            .string()
            .test(
              'validate-other-tax-type',
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherTaxEngine.required'
              ),
              function (value) {
                const { selectedTaxEngineType, otherTaxEngineTypeGuid } =
                  this.parent;
                if (
                  selectedTaxEngineType.taxEngineGuid === otherTaxEngineTypeGuid
                ) {
                  return !!value;
                }

                return true;
              }
            )
            .max(
              50,
              getTextToDisplay(
                'modal.dataRequestModal.fields.otherTaxEngine.maxLength',
                { count: 50 }
              )
            )
        })
      }),

    handleSubmit: async (values, formik) => {
      formik.props.onNextStepClick(3);
    }
  })(withUser(FirstStepFormErp))
);
