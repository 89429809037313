import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Icon, pushToast } from '@ryan/components';

import { useUser } from '../../../contexts/UserContext';
import ApiService from '../../../services/ApiService';
import { formatDate } from '../../../utils/formatDate';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import {
  EnumsWelcomeEmailCard,
  IHandleResendRequest,
  IRenderEmailStatus,
  IWelcomeEmailCardProps,
  getEmailStatusPostSchedule
} from './utils';

import './WelcomeEmailCard.scss';

const WelcomeEmailCard: React.FC<IWelcomeEmailCardProps> = ({
  isCurrentUserGhosted,
  mailToDetails,
  queueItemGuid,
  timestamps
}) => {
  const { t: textToDisplay } = useTranslation();
  const { permissionService } = useUser();

  const [resendEmailPromise, setResendEmailPromise] = useState(null);
  const [updatedEmailExpirationTimestamp, setUpdatedEmailExpirationTimestamp] =
    useState(null);
  const [updatedEmailSentTimestamp, setUpdatedEmailSentTimestamp] =
    useState(null);

  const { ROOT_TO_TEXT } = EnumsWelcomeEmailCard;
  const isEmailSent =
    timestamps.emailExpirationTimestamp && timestamps.emailSentTimestamp;
  const isActiveUserSuperAdmin = permissionService.isSuperAdmin();

  const handleResendRequest: IHandleResendRequest = async ({
    callbacks,
    company,
    fullName,
    guid,
    isActiveUserSuperAdmin,
    rootToText
  }) => {
    if (isActiveUserSuperAdmin) {
      try {
        const ApiServiceSendEmail = ApiService.sendUserActivationEmail(
          guid || '',
          new Date().toISOString()
        );
        callbacks.setResendEmailPromiseCallback(ApiServiceSendEmail);

        const ApiPromiseResponse = await ApiServiceSendEmail;

        const { emailSentDate, emailExpirationDate } = ApiPromiseResponse.data;

        if (!emailExpirationDate || !emailSentDate) {
          throw new Error('Server response missing required data');
        }

        callbacks.setUpdatedEmailExpirationTimestampCallback(
          emailExpirationDate
        );
        callbacks.setUpdatedEmailSentTimestampCallback(emailSentDate);
        pushToast({
          content: callbacks.textToDisplayCallback(
            `${rootToText}.welcomeEmailSent`
          ),
          type: 'success'
        });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        callbacks.setResendEmailPromiseCallback(null);
      }
    } else {
      window.location.href = `mailto:contactus@ryan.com?subject=Request to Resend Welcome Email to ${fullName} at ${company}`;
    }
  };

  const renderEmailStatus: IRenderEmailStatus = ({
    emailExpirationTimestamp,
    emailScheduledTimestamp,
    emailSentTimestamp,
    rootToText,
    textToDisplayCallback
  }) => {
    const todaysDate = new Date();

    let emailStatus = textToDisplayCallback(
      `${rootToText}.emailStatus.emailNotScheduled`
    ) as ReactElement | string;

    if (emailScheduledTimestamp) {
      const emailScheduledDate = new Date(emailScheduledTimestamp);

      if (!emailSentTimestamp) {
        emailStatus = textToDisplayCallback(
          `${rootToText}.emailStatus.emailPendingDelivery`,
          { date: formatDate(emailScheduledDate, false) }
        );
      } else {
        const emailStatusPostSchedule = getEmailStatusPostSchedule(
          todaysDate,
          new Date(emailExpirationTimestamp!)
        );

        const { expireOrExpiredLength, pathToUnitOfTime } =
          emailStatusPostSchedule.additionalDetails;

        let emailStatusAdditionalDetails = {};

        if (expireOrExpiredLength && pathToUnitOfTime) {
          emailStatusAdditionalDetails = {
            expireOrExpiredLength,
            unitOfTime: textToDisplay(pathToUnitOfTime)
          };
        }

        const isEmailExpired = emailStatusPostSchedule.isExpired;

        const emailAdditionalDetailsMessage = `${textToDisplay(
          emailStatusPostSchedule.pathToStatus,
          { ...emailStatusAdditionalDetails }
        )}`;
        const emailWasSentMessage = `${textToDisplay(
          `${rootToText}.emailStatus.emailWasSent`,
          { date: formatDate(new Date(emailSentTimestamp!)) }
        )}`;

        emailStatus = (
          <>
            {emailWasSentMessage}
            &nbsp;
            {isEmailExpired ? (
              <span
                className={classNames({
                  'welcome-email-card__message__expired-status': isEmailExpired
                })}
              >
                {emailAdditionalDetailsMessage}
              </span>
            ) : (
              emailAdditionalDetailsMessage
            )}
          </>
        );
      }
    }

    return emailStatus;
  };

  return (
    <Card
      className="welcome-email-card"
      title={textToDisplay(`${ROOT_TO_TEXT}.title`)}
    >
      <div
        className={classNames('welcome-email-card__message', {
          'welcome-email-card__message--short': isEmailSent
        })}
      >
        {renderEmailStatus({
          emailExpirationTimestamp:
            updatedEmailExpirationTimestamp ||
            timestamps.emailExpirationTimestamp,
          emailScheduledTimestamp: timestamps.emailScheduledTimestamp,
          emailSentTimestamp:
            updatedEmailSentTimestamp || timestamps.emailSentTimestamp,
          rootToText: ROOT_TO_TEXT,
          textToDisplayCallback: textToDisplay
        })}
      </div>

      {isEmailSent && (
        <button
          className="ry-button"
          disabled={
            isActiveUserSuperAdmin
              ? resendEmailPromise !== null
              : isCurrentUserGhosted
          }
          onClick={() =>
            handleResendRequest({
              callbacks: {
                setResendEmailPromiseCallback: setResendEmailPromise,
                setUpdatedEmailExpirationTimestampCallback:
                  setUpdatedEmailExpirationTimestamp,
                setUpdatedEmailSentTimestampCallback:
                  setUpdatedEmailSentTimestamp,
                textToDisplayCallback: textToDisplay
              },
              company: mailToDetails.company,
              fullName: mailToDetails.fullName,
              guid: queueItemGuid,
              isActiveUserSuperAdmin,
              rootToText: ROOT_TO_TEXT
            })
          }
        >
          <Icon name={'send-email'} />
          {textToDisplay(
            `${ROOT_TO_TEXT}.${
              isActiveUserSuperAdmin ? 'resendEmail' : 'requestResendEmail'
            }`
          )}
        </button>
      )}
    </Card>
  );
};

export default WelcomeEmailCard;
