import { getDateRange } from './dateRange.localStorage';
import { IEngagement } from 'interfaces';
import { Formik, yup } from 'utils/forms';
import { ValidationError } from 'yup';

import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Card } from '@ryan/components';

import ISavingsSummaryCardFilterValues from './ISavingsSummaryCardFilterValues';
import SavingsFilterType from './SavingsFilterType';
import SavingsSummaryCardContent from './SavingsSummaryCardContent';

import './SavingsSummaryCard.scss';

interface ISavingsSummaryCardProps extends WithTranslation {
  engagement?: IEngagement;
  customViewGuid?: string;
  onSavingsEmpty: () => void;
}

export const SavingsSummaryCard: FunctionComponent<ISavingsSummaryCardProps> =
  ({ t, engagement, customViewGuid, onSavingsEmpty }) => {
    const [startDate, endDate, filterType] = getDateRange();
    return (
      <Card
        className="savings-summary-card"
        role="region"
        title={t('Savings Summary')}
      >
        <Formik<ISavingsSummaryCardFilterValues>
          initialValues={{
            filterType:
              filterType === SavingsFilterType.AsOf
                ? SavingsFilterType.AsOf
                : SavingsFilterType.Range,
            startDate,
            endDate
          }}
          onSubmit={() => {}}
          validationSchema={yup
            .object<ISavingsSummaryCardFilterValues>()
            .test('dateRange', '', (value: ISavingsSummaryCardFilterValues) => {
              if (value.filterType === SavingsFilterType.Range) {
                if (value.startDate === null) {
                  return new ValidationError(
                    t('savingsSummaryWidget.datePicker.endDate'),
                    null,
                    'startDate'
                  );
                }

                if (value.endDate === null) {
                  return new ValidationError(
                    t('savingsSummaryWidget.datePicker.endDate'),
                    null,
                    'endDate'
                  );
                }

                if (value.startDate > value.endDate) {
                  return new ValidationError(
                    t('savingsSummaryWidget.datePicker.dateRange'),
                    null,
                    'startDate'
                  );
                }
              }

              return false;
            })}
        >
          {formik => (
            <SavingsSummaryCardContent
              customViewGuid={customViewGuid}
              engagement={engagement}
              formik={formik}
              onSavingsEmpty={onSavingsEmpty}
            />
          )}
        </Formik>
      </Card>
    );
  };

export default withTranslation()(SavingsSummaryCard);
