import { ISystemMessage } from 'interfaces';
import { transformResponse } from 'utils/transformResponse';
import ApiBase from './ApiBase';

export async function getSystemMessages(this: ApiBase) {
  return this.server
    .get<ISystemMessage[]>('/api/systemmessages', { transformResponse })
    .then(response => ({
      ...response,
      data: response.data.filter(
        msg =>
          typeof msg.systemMessage === 'string' &&
          msg.systemMessage.length > 0 &&
          !msg.systemMessage.includes('System.Net.WebException')
      )
    }));
}

export async function dismissSystemMessage(this: ApiBase, messageGuid: string) {
  return this.server.post(
    `/api/systemmessages/${messageGuid}/dismiss`,
    undefined, // empty body
    { transformResponse }
  );
}
