/**
 * Utility methods to join two arrays of project filters without duplicates based on each different types guid parameter.
 */
import {
  EngagementPersonnelFilter,
  JurisdictionFilter,
  PracticeAreaFilter
} from 'components/AdvancedFilterModal/interfaces';

export function joinAndRemoveDuplicateEngagementPersonnel(
  one: EngagementPersonnelFilter[],
  two: EngagementPersonnelFilter[]
) {
  return one.concat(two.filter(x => one.every(y => y.userGuid !== x.userGuid)));
}

export function joinAndRemoveDuplicateJurisdictions(
  one: JurisdictionFilter[],
  two: JurisdictionFilter[]
) {
  return one.concat(
    two.filter(x => one.every(y => y.jurisdictionGuid !== x.jurisdictionGuid))
  );
}

export function joinAndRemoveDuplicatePracticeAreas(
  one: PracticeAreaFilter[],
  two: PracticeAreaFilter[]
) {
  return one.concat(
    two.filter(x => one.every(y => y.practiceAreaGuid !== x.practiceAreaGuid))
  );
}
