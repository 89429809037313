import { ReportSectionStatus, ReportSections } from 'interfaces';

import classnames from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant, Icon } from '@ryan/components';

import './ReportBuilderPanel.scss';

interface IReportBuilderPanelProps {
  children?: React.ReactNode;
  disableNext?: boolean;
  formik: any;
  id: ReportSections;
  onNext?: () => void;
  onToggle: (section: ReportSections, formik: any) => void;
  selectedSection: ReportSections | null;
  status: ReportSectionStatus;
  title: string;
}

export const ReportBuilderPanel: React.SFC<IReportBuilderPanelProps> = ({
  children,
  disableNext,
  formik,
  id,
  onNext,
  onToggle,
  selectedSection,
  status,
  title
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames({
        'report-builder-panel': true,
        'report-builder-panel--open': id === selectedSection,
        'report-builder-panel--error': status === ReportSectionStatus.Error,
        'report-builder-panel--complete':
          status === ReportSectionStatus.Complete
      })}
    >
      <div
        className="report-builder-panel__header"
        onClick={() => onToggle(id, formik)}
      >
        <Icon
          className="report-builder-panel__icon"
          name={
            status === ReportSectionStatus.Error ? 'delete' : 'success-filled'
          }
        />
        <div className="title">{title}</div>
        <button
          className="report-builder-panel__toggle"
          key={id === selectedSection ? 'chevron-up' : 'chevron-down'}
          onClick={() => onToggle(id, formik)}
          type="button"
        >
          <Icon name={id === selectedSection ? 'chevron-up' : 'chevron-down'} />
        </button>
      </div>
      {children && id === selectedSection && (
        <div className="report-builder-panel__body">
          {children}
          {onNext && (
            <div className="report-builder__buttons">
              <Button
                disabled={disableNext}
                onClick={onNext}
                text={t('Next')}
                variant={EButtonVariant.SECONDARY}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
