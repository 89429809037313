export enum ApiFailSafeEnums {
  DATE = '01/01/1970',
  ENGAGEMENT_ID = '000000000.000',
  GUID = '00000000-0000-0000-0000-000000000000',
  LIST = '[]',
  NAME = '',
  NUM = 0,
  STATUS_ID = 1,
  USER_TYPE = 2
}
