import { EventEmitter } from 'events';
import { createContext } from 'react';
import { IMilestone } from 'interfaces';

export interface IMilestoneDrawerContext {
  milestoneDrawerEvents: EventEmitter;
  onMilestoneDrawerOpen: (milestone: IMilestone) => void;
}

export const MilestoneDrawerContext = createContext<IMilestoneDrawerContext>({
  milestoneDrawerEvents: new EventEmitter(),
  onMilestoneDrawerOpen: () => {}
});
