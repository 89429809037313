import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISingleReleaseNoteProps } from './utils';

import './SingleReleaseNote.scss';

const SingleReleaseNote: React.FC<ISingleReleaseNoteProps> = ({
  releaseNote,
  textLimit
}) => {
  const { details, releaseNoteGuid, title } = releaseNote;

  const { t: textToDisplay } = useTranslation();
  const [isShowMore, setIsShowMore] = useState(false);

  const isWithinMaxLength = details.length <= textLimit;

  return (
    <div className="release-note__single" key={releaseNoteGuid}>
      <h3 className="release-note__single__title">{title}</h3>
      <p className="release-note__single__details">
        {isShowMore || isWithinMaxLength
          ? details
          : `${details.slice(0, textLimit)}...`}
      </p>
      {!isWithinMaxLength && !isShowMore && (
        <button
          className="release-note__single__more"
          onClick={() => setIsShowMore(true)}
        >
          {textToDisplay('modal.releaseNotesModal.readMore')}
        </button>
      )}
    </div>
  );
};

export default SingleReleaseNote;
