import { CancelToken } from 'axios';

import {
  IAccount,
  IActivateUserResponse,
  IDeactivateUserResponse,
  IExecutiveAccess,
  INewUserRequest,
  INewUserRequestCompleteRequest,
  INewUserRequestProjectPreviewParams,
  INewUserRequestProjectPreviewResponse,
  INewUserRequestRequest,
  INewUserRequestSearchParams,
  INewUserRequestWithRoleAndPermissions,
  IPagedDataResponse,
  IPermission,
  IRole,
  IRolePermission,
  IUserAccessRequest,
  IUserIdentity,
  IUserPermission,
  UserType
} from '../../interfaces';
import { transformResponse } from '../../utils/transformResponse';
import { unwrapCommandResponse } from '../../utils/unwrapCommandResponse';
import ApiBase from './ApiBase';

//
//
// Roles
//
//

export async function getRoles(this: ApiBase) {
  return this.server.get<IRole[]>(`/api/roles`);
}

export async function getRolesForUserType(this: ApiBase, userType: UserType) {
  const response = await getRoles.call(this);
  response.data = response.data.filter(role => (role.userTypes & userType) > 0);
  return response;
}

export async function getRole(this: ApiBase, roleGuid: string) {
  return this.server.get<IRole>(`/api/roles/${roleGuid}`);
}

export async function createRole(
  this: ApiBase,
  request: {
    name: string;
    userTypes: number;
    permissions: IRolePermission[];
  }
) {
  return this.server.post<IRole>(`/api/roles`, request, {
    transformResponse
  });
}

export async function updateRole(
  this: ApiBase,
  roleGuid: string,
  request: {
    permissions: IRolePermission[];
  }
) {
  return this.server.put<IRole>(`/api/roles/${roleGuid}`, request, {
    transformResponse
  });
}

export async function deleteRole(
  this: ApiBase,
  roleGuid: string,
  reassignToRoleGuid: string
) {
  return this.server.delete(`/api/roles/${roleGuid}/${reassignToRoleGuid}`);
}

//
//
// Permissions
//
//

export async function getPermissions(this: ApiBase) {
  return this.server.get<IPermission[]>(`/api/permissions`).then(response => ({
    ...response,
    data: response.data.filter(
      permission =>
        !['0b61b0b1-65f6-4900-8ffe-9b9e228416c0'].includes(
          permission.permissionGuid
        )
    )
  }));
}

export async function getUserPermissions(this: ApiBase, userGuid: string) {
  return this.server.get<IUserPermission[]>(
    `/api/users/${userGuid}/permissions`
  );
}

//
//
// New User Requests
//
//

export async function requestNewUser(
  this: ApiBase,
  request: INewUserRequestRequest
) {
  return this.server.post<INewUserRequest>('/api/newuser/request', request, {
    transformResponse
  });
}

export async function getNewUserRequestDetails(
  this: ApiBase,
  userGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get(`/api/newuser/${userGuid}/status`, { cancelToken });
}

export async function getNewUserRequests(
  this: ApiBase,
  params: INewUserRequestSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<INewUserRequest>>(
    `/api/newuser/search`,
    { cancelToken, params, transformResponse }
  );
}

export async function getNewUserRequestById(
  this: ApiBase,
  newUserGuid: string
) {
  return this.server.get<INewUserRequestWithRoleAndPermissions>(
    `/api/newuser/${newUserGuid}`,
    { transformResponse }
  );
}

export async function deleteNewRequestById(this: ApiBase, newUserGuid: string) {
  return this.server.delete(`/api/newuser/${newUserGuid}`);
}

export async function denyNewUserRequest(
  this: ApiBase,
  queueItemGuid: string,
  userGuid: string | null
) {
  return this.server.put<INewUserRequest>(
    `/api/newuser/${queueItemGuid || userGuid}/deny`,
    { userGuid },
    { transformResponse }
  );
}

export async function completeNewUserRequest(
  this: ApiBase,
  queueItemGuid: string,
  request: INewUserRequestCompleteRequest
) {
  return unwrapCommandResponse<INewUserRequest>(
    this.server.put(`/api/newuser/${queueItemGuid}/complete`, request)
  );
}

export async function directNewUserRequest(
  this: ApiBase,
  memberGuid: string,
  request: any
) {
  return unwrapCommandResponse<INewUserRequest>(
    this.server.put(`/api/newuser/${memberGuid}/directconversion`, request)
  );
}

export async function getPendingUserRequests(
  this: ApiBase,
  params: INewUserRequestSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<INewUserRequest>>(
    `/api/newuser/pending`,
    { cancelToken, params, transformResponse }
  );
}

export async function getNewUserProjectPreview(
  this: ApiBase,
  params: INewUserRequestProjectPreviewParams,
  cancelToken?: CancelToken
) {
  return this.server.get<
    IPagedDataResponse<INewUserRequestProjectPreviewResponse>
  >(`/api/newuser/projectReviewRequest`, {
    cancelToken,
    params,
    transformResponse
  });
}

//
//
// User Access
//
//

export async function userIdentity(this: ApiBase) {
  return this.server.get<IUserIdentity>('/api/identity');
}

// save an entire user's access information (Role, permissions, and activated date) -
// deactivated date is handled separately
// @todo type request argument
export async function userAccessSave(
  this: ApiBase,
  request: IUserAccessRequest
) {
  return this.server.post(`/api/user/access`, request);
}

export async function activateUser(
  this: ApiBase,
  userGuid: string,
  activateDate: string
) {
  return this.server.post<IActivateUserResponse>(`/api/user/activate/`, {
    userGuid,
    activateDate
  });
}

export async function deactivateUser(
  this: ApiBase,
  userGuid: string,
  deactivateDate: string
) {
  return this.server.post<IDeactivateUserResponse>(`/api/user/deactivate/`, {
    userGuid,
    deactivateDate
  });
}

export async function cancelUserDeactivation(this: ApiBase, userGuid: string) {
  return this.server.put<IDeactivateUserResponse>(
    `/api/user/canceldeactivation/`,
    {
      userGuid
    }
  );
}

export async function getUserExecutiveAccess(this: ApiBase, userGuid: string) {
  return this.server.get<IExecutiveAccess>(
    `/api/users/${userGuid}/executiveaccess`
  );
}

export async function getExecutiveAccounts(this: ApiBase, userGuid: string) {
  return this.server.get<IAccount[]>(
    `/api/users/${userGuid}/executiveaccounts`
  );
}

//
//
// User Password
//
//

/**
 * Requests a password reset link for existing users.
 */
export async function requestResetPassword(this: ApiBase, email: string) {
  return this.server.put<string>('/api/password/requestreset', {
    userEmail: email
  });
}

/**
 * Requests a password setup link for new users.
 */
export async function requestResetPasswordForNewUser(
  this: ApiBase,
  email: string
) {
  return this.server.put<string>('api/newuser/password/requestreset', {
    userEmail: email
  });
}

// Validates whether or not the email has expired
export async function validateEmailToken(this: ApiBase, emailToken: string) {
  return this.server.post<{
    passwordResetGuid: string;
    userGuid: string;
    userEmail: string;
    createDate: Date;
    expirationDate: Date;
    resetDate: Date | null;
  }>(
    `/api/password/validate`,
    { passwordResetGuid: emailToken },
    { transformResponse }
  );
}

/**
 * Gets info for user trying to reset their password.
 * Used for password complexity requirements.
 */
export async function resetPasswordUser(this: ApiBase, requestGuid: string) {
  interface Response {
    firstName: string;
    lastName: string;
    userTypeId: UserType;
  }

  return this.server.get<Response>(`/api/password/reset/${requestGuid}`);
}

/**
 * Resets the password matching the validated email guid.
 */
export async function resetPassword(
  this: ApiBase,
  emailToken: string,
  newPassword: string
) {
  return this.server.put<string>(`/api/password/reset`, {
    passwordResetGuid: emailToken,
    password: newPassword
  });
}

export async function getPasswordExpirationDays(this: ApiBase) {
  return this.server.get<number>(`/api/password/expiration`);
}

export async function validateCredentialsAndResetPassword(
  this: ApiBase,
  oldPassword: string,
  newPassword: string
) {
  return this.server.post(`/api/password/validateCredentials/resetPassword`, {
    oldPassword,
    newPassword
  });
}

/**
 * will return true/false based on if the current user can indeed manage the engagement
 * it will grab that engagement and related accounts
 * then make sure that you are a big4 or ea on that project/accounts
 */
export async function canManageEngagementIfBigFourOrEa(
  this: ApiBase,
  engagementGuid: string
) {
  return this.server.get<boolean>(`/api/engagements/${engagementGuid}/manage`);
}

/**
 * will return true/false based on if the current user can indeed manage the user
 * it will look at the user - grab all shared projects (if any)
 * then make sure that you are big 4 or ea on those projects/accounts
 */
export async function canManageUserIfBigFourOrEa(
  this: ApiBase,
  userGuid: string
) {
  return this.server.get<boolean>(`/api/users/${userGuid}/manage`);
}
