import Empty from 'components/Empty';
import PhoneNumber from 'components/PhoneNumber';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import OverflowMenu from 'components/Table/OverflowMenu/OverflowMenu';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import UserConversionModal from 'components/UserConversionModal/UserConversionModal';
import UserInfoCard from 'components/UserInfoCard/UserInfoCard';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  Feature,
  IEngagement,
  IEngagementUser,
  ITableState,
  ITeamSearchParams,
  IUser,
  UserType
} from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import history from 'services/history';
import debouncedSearch from 'utils/debouncedSearch';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  EButtonVariant,
  EMessageTypes,
  ITableColumn,
  Icon,
  Pagination,
  Toggle,
  Toggles,
  pushToast
} from '@ryan/components';

import ConfirmationModal from '../../../components/Modal/ConfirmationModal/ConfirmationModal';
import UserNameAndProfile from '../../../components/Table/UserNameAndProfile/UserNameAndProfile';
import { formatToUserNameAndProfile } from '../../../components/Table/UserNameAndProfile/utils';
import { getFormattedUserInfo } from '../../../utils/getFormattedUserInfo/getFormattedUserInfo';

import './ProjectTeamTable.scss';

export interface IProjectTeamTableProps extends WithTranslation, WithUser {
  engagement: IEngagement;
  header: string;
  isActiveUserWithManageEngagementAccess: boolean;
  userTypes: number;
}

enum Layout {
  List,
  Card
}

interface IProjectTeamTableState extends ITableState {
  layout: Layout;
  teamMembers: IEngagementUser[];
  userToBeGrantedAccess: IUser | null;
  userToBeRemoved: IUser | null;

  /**
   * Only show a third-party user-specific table if the engagement contains
   * third-party members.
   */
  hideTable: boolean;
}

export class ProjectTeamTable extends Component<
  IProjectTeamTableProps,
  IProjectTeamTableState
> {
  private _isMounted = false;

  private source?: CancelTokenSource;

  private columns: ITableColumn<IEngagementUser>[];

  constructor(props: IProjectTeamTableProps) {
    super(props);
    const { userTypes, t } = props;

    this.state = {
      layout: Layout.List,
      teamMembers: [],
      loading: false,
      page: 1,
      pageSize: 10,
      totalCount: 0,
      searchQuery: '',
      sorted: {
        id: 'firstName',
        desc: false
      },
      filtered: { company: '' },
      userToBeGrantedAccess: null,
      userToBeRemoved: null,

      // hide the table on mount if the table is specific to third-party users;
      // will reveal once data is fetched and can confirm that third-party users
      // exist on engagement; be sure not to hide when a search query returns 0
      // results
      hideTable: userTypes === UserType.ThirdParty
    };

    this.columns = [
      {
        id: 'firstName',
        label: t('NameAndTitle'),
        sortable: true,
        render: (row: IEngagementUser) => (
          <UserNameAndProfile
            isLinkable
            user={formatToUserNameAndProfile({
              additionalInfoKey: 'title',
              user: row
            })}
          />
        )
      },
      {
        id: 'company',
        label: t('Company'),
        sortable: true,
        render: 'company'
      },
      {
        id: 'displayLocation',
        label: t('Location'),
        render: (row: IEngagementUser) => (
          <div>{row.displayLocation || '\u2013'}</div>
        ),
        sortable: true
      },
      {
        id: 'officePhone',
        label: t('Office'),
        render: ({ directDialNumber, officePhone, userTypeId }) => {
          return (
            <PhoneNumber
              phone={
                userTypeId === UserType.Ryan && directDialNumber
                  ? directDialNumber
                  : officePhone
              }
            />
          );
        }
      },
      {
        id: 'mobilePhone',
        label: t('Mobile'),
        render: ({ mobilePhone }) => <PhoneNumber phone={mobilePhone} />
      },
      {
        id: 'email',
        label: t('Email'),
        render: (row: IEngagementUser) => (
          <a
            className="project-team-table__user-email"
            href={`mailto:${row.email}`}
          >
            <Icon name="mail" />
          </a>
        )
      },
      {
        id: 'ryanUserOptions',
        label: '',
        render: (row: IEngagementUser) => this.renderAdditionalOptions(row)
      }
    ];
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchProjectTeamData({}, this.toggleTableVisibility);
  }

  componentDidUpdate(prevProps: IProjectTeamTableProps) {
    // refetch data on user type change, reset page and searchQuery first
    if (this.props.userTypes !== prevProps.userTypes) {
      this.setState(
        {
          page: 1,
          searchQuery: undefined
        },
        () => {
          this.fetchProjectTeamData({}, this.toggleTableVisibility);
        }
      );
    }
  }

  componentWillUnmount() {
    this.source?.cancel();
    this._isMounted = false;
  }

  fetchProjectTeamData(
    updates: Partial<IProjectTeamTableState> = {},
    callback?: () => void
  ) {
    this.setState(
      {
        ...(updates as IProjectTeamTableState),
        loading: true
      },
      async () => {
        const { page, searchQuery, pageSize, sorted, filtered } = this.state;
        const { engagement, userTypes } = this.props;
        const params: ITeamSearchParams = {
          pageNumber: page,
          searchTerm: searchQuery,
          itemsPerPage: pageSize,
          sort: getSortParam(sorted),
          userType: userTypes,
          company: filtered?.company
        };

        // refresh cancel token
        this.source?.cancel();
        this.source = ApiService.CancelToken.source();

        try {
          const response = await ApiService.getTeamUsersByEngagement(
            engagement.engagementGuid,
            params,
            this.source.token
          );
          this.setState(
            {
              loading: false,
              teamMembers: response.data.results,
              totalCount: response.data.totalResults
            },
            callback
          );
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }

          if (this._isMounted) {
            this.setState({
              loading: false
            });
          }
        }
      }
    );
  }

  /**
   * Table Handlers
   */

  onSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchProjectTeamData({ page: 1, searchQuery });
    }
  );

  onSort = (sorted: any) => {
    this.fetchProjectTeamData({ sorted });
  };

  onPage = (page: number, pageSize: number) => {
    this.fetchProjectTeamData({ page, pageSize });
  };

  /**
   * Convert User
   */
  handleGrantUserAccess = (user: IUser) => {
    AmplitudeApiService.logEvent('grant-user-access', {
      'grant-access-path': 'meatball - single project team'
    });
    this.setState({ userToBeGrantedAccess: user });
  };

  handleGrantUserAccessClose = (isUpdateRequired: boolean) => {
    if (isUpdateRequired) {
      this.fetchProjectTeamData();
    }

    this.setState({ userToBeGrantedAccess: null });
  };

  /**
   * Remove User
   */

  handleRemoveUserSubmit = async () => {
    const { t, engagement, isFeatureToggled } = this.props;
    const { userToBeRemoved } = this.state;

    await ApiService.removeRyanUserFromEngagement(
      userToBeRemoved!.userGuid,
      engagement.engagementGuid
    ).then(() => {
      this.onSearch(this.state.searchQuery || '');
    });

    pushToast({
      content:
        t('manageTeam.removeUserConfirmationModal.successToast.content', {
          projectName: engagement.engagementDisplayNameLong,
          userFullName:
            getFormattedUserInfo('fullName', userToBeRemoved!) ||
            t('manageTeam.removeUserConfirmationModal.thisUser')
        }) +
        (!isFeatureToggled(Feature.WorkdayTeamDecouple)
          ? ` ${t(
              'manageTeam.removeUserConfirmationModal.successToast.contentWorkdayNote'
            )}`
          : ''),
      type: EMessageTypes.SUCCESS
    });
  };

  /**
   * Ghost User
   */

  handleUserGhosting = (user: IEngagementUser, isGhosted: boolean) => {
    const { t, engagement } = this.props;

    ApiService.manageGhostRyanUserOnEngagement({
      userGuid: user.userGuid,
      engagementGuid: engagement.engagementGuid,
      isGhosted
    })
      .then(response => {
        if (response.data) {
          const { teamMembers } = this.state;

          // set the user to be ghosted or unghosted, depending on the action that was taken
          teamMembers[
            teamMembers.findIndex(
              member => member.memberGuid === user.memberGuid
            )
          ].isGhosted = isGhosted;
          this.setState({
            teamMembers: [...teamMembers]
          });

          pushToast({
            type: 'success',
            title: t('manageTeam.success'),
            content: t(
              isGhosted
                ? 'manageTeam.ghostSuccessContent'
                : 'manageTeam.unghostSuccessContent',
              {
                user: user.fullName,
                projectName: engagement.engagementDisplayNameShort
              }
            )
          });
        }
      })
      .catch(() => {
        pushToast({
          type: 'error',
          title: t('serverError.title'),
          content: t('serverError.content')
        });
      });
  };

  /**
   * Toggle flag to reveal this table if this table contains users. Called on
   * mount and user type change only.
   */
  toggleTableVisibility = () => {
    const { totalCount } = this.state;

    if (totalCount > 0) {
      this.setState({
        hideTable: false
      });
    }
  };

  /**
   * Render
   */

  render() {
    const { engagement, t, isFeatureToggled } = this.props;
    const { layout, hideTable, userToBeGrantedAccess, userToBeRemoved } =
      this.state;

    // hide table if we are displaying third-party users but project does not
    // have any third-party users attached
    return hideTable ? null : (
      <div className="project-team-table">
        {layout === Layout.List
          ? this.renderTableView()
          : this.renderCardView()}

        {userToBeRemoved && (
          <ConfirmationModal
            confirmationMessage={t(
              isFeatureToggled(Feature.WorkdayTeamDecouple)
                ? 'manageTeam.removeUserConfirmationModal.descriptionNoWorkday'
                : 'manageTeam.removeUserConfirmationModal.description',
              {
                userFullName:
                  getFormattedUserInfo('fullName', userToBeRemoved) ||
                  t('manageTeam.removeUserConfirmationModal.thisUser')
              }
            )}
            onClose={() => {
              this.setState({ userToBeRemoved: null });
            }}
            onSubmit={this.handleRemoveUserSubmit}
            submitTextToDisplay={t(
              'manageTeam.removeUserConfirmationModal.yesRemove'
            )}
            title={t('manageTeam.removeUserConfirmationModal.title', {
              projectName: engagement.engagementDisplayNameShort
            })}
          />
        )}

        {userToBeGrantedAccess && (
          <UserConversionModal
            accountGuid={engagement.accountGuid}
            onClose={this.handleGrantUserAccessClose as any}
            user={userToBeGrantedAccess}
          />
        )}
      </div>
    );
  }

  renderTableView() {
    const { header, t } = this.props;
    const {
      loading,
      teamMembers,
      sorted,
      page,
      pageSize,
      totalCount,
      filtered,
      searchQuery
    } = this.state;

    return (
      <Table<IEngagementUser>
        columns={this.columns}
        data={teamMembers}
        filtered={filtered}
        loading={loading}
        onPageChange={this.onPage}
        onSearchChange={this.onSearch}
        onSortChange={this.onSort}
        page={page}
        pageSize={pageSize}
        renderActions={this.renderActions}
        renderEmpty={() => (
          <TableEmpty searchQuery={searchQuery}>
            <Empty icon="null">{t('No Results')}</Empty>
          </TableEmpty>
        )}
        rowId="memberGuid"
        sorted={sorted}
        title={header}
        totalCount={totalCount}
      />
    );
  }

  renderAdditionalOptions(
    user: IEngagementUser,
    dropdownMenuButtonSize: 'sm' | 'md' | 'lg' | undefined = 'sm'
  ) {
    const passthroughProps = {
      isActiveUserWithManageEngagementAccess:
        this.props.isActiveUserWithManageEngagementAccess,
      permissionService: this.props.permissionService,
      textToDisplay: this.props.t
    };

    return (
      <OverflowMenu
        {...passthroughProps}
        dropdownMenuButtonSize={dropdownMenuButtonSize}
        handleGrantUserAccess={this.handleGrantUserAccess}
        handleRemoveUserConfirm={() => {
          this.setState({ userToBeRemoved: user });
        }}
        handleUserGhosting={this.handleUserGhosting}
        isOverflowDisabled={
          this.props.engagement.isReadOnly || this.props.isAppReadOnly
        }
        user={user}
      />
    );
  }

  renderCardView() {
    const { header, t } = this.props;
    const { teamMembers, page, pageSize, totalCount } = this.state;

    return (
      <div className="project-team-table__card-view">
        <div className="project-team-table__card-view-header">
          <h2 className="ry-h2">{header}</h2>
          {this.renderActions()}
        </div>
        <hr />
        <div className="project-team-table__card-view-content">
          {teamMembers.map((user: IEngagementUser, i: number) => (
            <React.Fragment key={i}>
              <UserInfoCard
                renderAdditionalOptions={() =>
                  this.renderAdditionalOptions(user, 'lg')
                }
                user={user}
              />
            </React.Fragment>
          ))}
        </div>

        <Pagination
          onPageChange={this.onPage}
          page={page}
          pageSize={pageSize}
          pageSizeLabel={t('table.pageSizeLabel')}
          totalCount={totalCount}
        />
      </div>
    );
  }

  renderActions = () => {
    const {
      engagement,
      isActiveUserWithManageEngagementAccess,
      isAppReadOnly,
      permissionService: ps,
      t,
      userTypes
    } = this.props;
    const { loading, layout } = this.state;
    const isReadOnly = engagement.isReadOnly || isAppReadOnly;
    const isAddRynUserButtonDisabled =
      engagement.isUserGhosted || isAppReadOnly;

    return (
      <div className="project-team-table__actions">
        <Toggles>
          <Toggle
            active={layout === Layout.List}
            icon="layout-list"
            onClick={() => this.setState({ layout: Layout.List })}
          />
          <Toggle
            active={layout === Layout.Card}
            icon="layout-card"
            onClick={() => this.setState({ layout: Layout.Card })}
          />
        </Toggles>

        {isActiveUserWithManageEngagementAccess &&
          (userTypes & UserType.Ryan) > 0 && (
            <Button
              disabled={isAddRynUserButtonDisabled}
              icon="new-user"
              onClick={() =>
                history.push(
                  `/app/project/${engagement.engagementGuid}/team/edit`
                )
              }
              text={t('manageTeam.AddRyanUserButton')}
              variant={EButtonVariant.TEXT}
            />
          )}

        {ps.canViewManageTeams() &&
          (!ps.isClient() || (userTypes & UserType.Client) > 0) && (
            <Button
              disabled={isReadOnly}
              icon="users"
              onClick={() => {
                let url = '/app/team/manage/';

                if (ps.canEditUsersOnSameAccount()) {
                  // navigate to correct user type table within manage teams if
                  // user can edit users
                  if (userTypes === UserType.Client) {
                    url += 'client';
                  }

                  if (
                    (userTypes & UserType.ThirdParty) > 0 &&
                    (userTypes & ~UserType.ThirdParty) === 0
                  ) {
                    url += 'third-party';
                  }
                } else {
                  // navigate to new user request table if user cannot edit
                  // users
                  url += 'newusers';
                }

                const location = {
                  pathname: url,
                  state: {
                    previousPath: window.location.pathname
                  }
                };

                history.push(location);
              }}
              text={t('manageTeam.title')}
              variant={EButtonVariant.TEXT}
            />
          )}

        <SearchInput
          loading={loading}
          onChange={this.onSearch}
          value={this.state.searchQuery}
        />
      </div>
    );
  };
}

export default withTranslation()(withUser(ProjectTeamTable));
