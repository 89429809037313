import { MentionsValue } from '@ryan/components';

import { ICodeNote } from '../../interfaces';
import ApiBase from './ApiBase';

export async function createDataRequestCodeNotes(
  this: ApiBase,
  engagementGuid: string,
  queueItemGuid: string,
  codeNote: string | MentionsValue
) {
  return this.server.post<ICodeNote>(
    `/api/engagements/${engagementGuid}/datarequests/${queueItemGuid}/codenotes`,
    {
      noteText: codeNote
    }
  );
}

export async function getDataRequestCodeNotes(
  this: ApiBase,
  engagementGuid: string,
  queueItemGuid: string
) {
  return this.server.get<ICodeNote[]>(
    `/api/engagements/${engagementGuid}/datarequests/${queueItemGuid}/codenotes`
  );
}

export async function updateDataRequestCodeNotes(
  this: ApiBase,
  engagementGuid: string,
  queueItemCODENoteGuid: string,
  codeNoteText: MentionsValue | string
) {
  return this.server.put<ICodeNote>(
    `/api/engagements/${engagementGuid}/datarequests/codenotes/${queueItemCODENoteGuid}`,
    {
      noteText: codeNoteText
    }
  );
}

export async function deleteDataRequestCodeNote(
  this: ApiBase,
  engagementGuid: string,
  queueItemCODENoteGuid: string
) {
  return this.server.delete<ICodeNote>(
    `/api/engagements/${engagementGuid}/datarequests/codenotes/${queueItemCODENoteGuid}`
  );
}
