import { CancelToken } from 'axios';
import { IActivity, IPagedDataResponse, IRecentActivity } from 'interfaces';
import ApiBase from './ApiBase';

/**
 * Get activity for custom view
 */
export async function getCustomViewRecentActivity(
  this: ApiBase,
  customViewGuid: string,
  params: IRecentActivity,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IActivity>>(
    `/api/customViews/${customViewGuid}/activity`,
    { cancelToken, params }
  );
}

/**
 * Get activity for engagement
 */
export async function getEngagementRecentActivity(
  this: ApiBase,
  engagementGuid: string,
  params: IRecentActivity,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IActivity>>(
    `/api/engagements/${engagementGuid}/activity`,
    { cancelToken, params }
  );
}
