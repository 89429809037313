import { formatDate } from '../../../../../../utils/formatDate';
import { IFormatEngagementToProjectSnapshotOverview } from '../IProjectSnapshotOverview';

export const formatEngagementToProjectSnapshotOverview: IFormatEngagementToProjectSnapshotOverview =
  (engagement, openMilestones) => {
    const {
      alternateBeginDate,
      projectDetails: summary,
      projectedEndDate,
      statuteOfLimitations,
      updateDate
    } = engagement;

    let upcomingMilestone = openMilestones[0];

    if (openMilestones.length > 1) {
      const upcomingMilestoneDate = new Date(upcomingMilestone.startDate);
      const parsedMilestones = openMilestones
        .filter(
          openMilestone =>
            new Date(openMilestone.startDate).getTime() ===
            upcomingMilestoneDate.getTime()
        )
        .sort((firstItem, secondItem) =>
          firstItem.title.localeCompare(secondItem.title)
        );

      upcomingMilestone = parsedMilestones[0];
    }

    return {
      estimatedEnd: projectedEndDate ? formatDate(projectedEndDate) : '-',
      kickoff: alternateBeginDate ? formatDate(alternateBeginDate) : '-',
      lastUpdated: updateDate ? formatDate(updateDate) : '-',
      milestoneEnd:
        upcomingMilestone && upcomingMilestone.endDate
          ? formatDate(upcomingMilestone.endDate)
          : '-',
      milestoneStatus: upcomingMilestone ? upcomingMilestone.status! : '-',
      statuteWaiverExpiration: statuteOfLimitations
        ? formatDate(statuteOfLimitations)
        : '-',
      summary,
      upcomingMilestone: upcomingMilestone ? upcomingMilestone.title : '-'
    };
  };
