import { UserType } from 'interfaces';
import { Field, FieldProps, yup } from 'utils/forms';

import React, { ComponentProps, FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordInput } from '@ryan/components';

export interface UserInfo {
  firstName: string | null;
  lastName: string | null;
  userTypeId: UserType;
}

export function makeUserInfoRegex(userInfo: UserInfo) {
  const { firstName, lastName, userTypeId } = userInfo;
  const substrings: { [key: string]: '' } = {};
  const tokenize = (s: string) => s.replace(/[^a-z]/gi, ' ').split(' ');
  const tokens: string[] = [
    ...(userTypeId === UserType.Ryan ? tokenize('Ryan') : [])
  ];
  if (firstName) tokens.push(...tokenize(firstName));
  if (lastName) tokens.push(...tokenize(lastName));
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    for (let j = 0; j < token.length - 2; j++) {
      const substring = token.slice(j, j + 3).toLowerCase();
      substrings[substring] = '';
    }
  }
  return new RegExp(`(${Object.keys(substrings).join('|')})`, 'i');
}

export interface IPasswordProps
  extends Omit<
    ComponentProps<typeof PasswordInput>,
    | 'defaultValue'
    | 'value'
    | 'onChange'
    | 'onBlur'
    | 'valid'
    | 'invalid'
    | 'feedback'
    | 'requirements'
  > {
  name: string;
  userInfo: UserInfo;
}

/**
 * Renders a password creation field with default Ryan password validation.
 * A Formik context is required for this component.
 *
 * Inspired by...
 * @url https://github.com/formium/formik/issues/243
 */
const Password: FunctionComponent<IPasswordProps> = ({
  name,
  userInfo,
  ...props
}) => {
  const { t } = useTranslation();

  const { validate, requirements } = useMemo(() => {
    const userInfoRegex = makeUserInfoRegex(userInfo);

    const messages = [
      'password.fields.atLeastTwelve',
      'password.fields.atLeastOneUppercase',
      'password.fields.atLeastOneLowercase',
      'password.fields.atLeastOneNumber',
      'password.fields.atLeastOneSpecial',
      'password.fields.noNames'
    ];

    const schema = yup
      .string()
      .min(12, messages[0])
      .matches(/[A-Z]/, messages[1])
      .matches(/[a-z]/, messages[2])
      .matches(/\d/, messages[3])
      .matches(/[~!@#$%^&*()=_+\-[\]{}\\/|;:,.'"`<>?]/, messages[4])
      .test(
        'noNames',
        messages[5],
        value => value.length > 0 && !userInfoRegex.test(value)
      );
    return {
      validate: (value: string) =>
        schema
          .validate(value, { abortEarly: false })
          .then(() => {})
          .catch(error => error.errors),
      requirements: (errors: string[] | undefined) =>
        messages.map(message => ({
          message: t(message),
          pass: !errors || !errors.includes(message)
        }))
    };
  }, [t, userInfo]);

  return (
    <Field name={name} validate={validate}>
      {({ field, meta: { touched, error } }: FieldProps) => {
        return (
          <PasswordInput
            {...props}
            {...field}
            autoComplete="new-password"
            invalid={touched && error !== undefined}
            requirements={requirements(error as any)}
          />
        );
      }}
    </Field>
  );
};

export default Password;
