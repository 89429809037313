import { CommentDeleteModal } from 'components/Comments';
import { EventEmitter } from 'events';
import { IComment, ITask, Permission } from 'interfaces';
import ApiService from 'services/ApiService';
import history from 'services/history';
import getMentionValue from 'utils/getMentionValue';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { MentionsValue } from '@ryan/components';

import { WithUser, withUser } from '../UserContext';
import TaskDrawer from './TaskDrawer';
import { TaskDrawerContext } from './TaskDrawerContext';

const EMPTY_COMMENT = new MentionsValue();

interface ITaskDrawerProviderProps extends WithTranslation, WithUser {
  children: React.ReactNode;
}

interface ITaskDrawerProviderState {
  task: ITask | null;
  comments: IComment[] | null;
  commentsLoading: boolean;
  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;
  deleteComment: IComment | null;
  deleteCommentLoading: Promise<any> | null;
  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
}

export class TaskDrawerProvider extends Component<
  ITaskDrawerProviderProps,
  ITaskDrawerProviderState
> {
  private events = new EventEmitter();

  readonly state: ITaskDrawerProviderState = {
    task: null,
    comments: null,
    commentsLoading: false,
    editComment: null,
    editCommentText: EMPTY_COMMENT,
    editCommentLoading: null,
    deleteComment: null,
    deleteCommentLoading: null,
    newCommentText: EMPTY_COMMENT,
    newCommentLoading: null
  };

  get canView() {
    const { permissionService: ps } = this.props;
    return ps.hasPermission(Permission.TasksView);
  }

  get canComment() {
    const { isAppReadOnly, permissionService: ps } = this.props;
    const { task } = this.state;
    return (
      task !== null &&
      !task.isEngagementReadOnly &&
      !isAppReadOnly &&
      ps.hasPermission(Permission.TasksContribute)
    );
  }

  /**
   * Open the TaskDrawer with a Task or a taskGuid.
   */
  handleOpen = async (task: ITask) => {
    if (this.canView) {
      // Render get the drawer loading.
      // If we have permission to view, fetch comments.
      this.setState({ task, commentsLoading: true });
      try {
        const response = await ApiService.getTaskComments(
          task.engagementGuid,
          task.queueItemGuid
        );
        this.setState({ comments: response.data });
      } catch {
        pushServerErrorToast();
      }
      this.setState({ commentsLoading: false });
    }
  };

  /**
   * Close the TaskDrawer, clear the state.
   */
  handleClose = () => {
    // Don't close if we have an active request
    // for creating, editing, or deleting a comment.
    const { editCommentLoading, newCommentLoading, deleteComment } = this.state;
    if (!editCommentLoading && !newCommentLoading && !deleteComment) {
      // Remove the #task
      history.replace({
        ...history.location,
        hash: ''
      });

      // Clear the state.
      this.setState({
        task: null,
        comments: null,
        editComment: null,
        editCommentText: EMPTY_COMMENT,
        deleteComment: null,
        newCommentText: EMPTY_COMMENT,
        newCommentLoading: null
      });
    }
  };

  /**
   *
   * Edit a comment.
   *
   */

  /**
   * User chooses a comment to edit.
   */
  handleEditComment = (editComment: IComment) => {
    if (this.canComment) {
      this.setState({
        editComment,
        editCommentText: getMentionValue(editComment)
      });
    }
  };

  /**
   * User begins typing.
   */
  handleEditCommentChange = (editCommentText: MentionsValue) => {
    if (this.canComment) {
      this.setState({ editCommentText });
    }
  };

  /**
   * User saves the typed comment.
   */
  handleEditCommentSave = async () => {
    const { task, editComment, editCommentText } = this.state;

    if (this.canComment && task && editComment) {
      const { text } = editCommentText.toJSON();

      // If editing the comment to be an empty string, then delete instead of edit.
      if (text.length === 0) {
        this.handleDeleteCommentRequest(editComment);
        return;
      }

      const editCommentLoading = ApiService.updateTaskComment(
        task.engagementGuid,
        editComment.commentGuid,
        text
      );

      this.setState({ editCommentLoading });

      try {
        const { data: updatedComment } = await editCommentLoading;
        this.setState(({ comments }) => ({
          comments:
            comments &&
            comments.map(c =>
              c.commentGuid === updatedComment.commentGuid ? updatedComment : c
            ),
          editComment: null,
          editCommentText: EMPTY_COMMENT
        }));
        this.events.emit('commentEdited', {
          engagementGuid: task.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }

      this.setState({ editCommentLoading: null });
    }
  };

  /**
   * User cancels the typed comment.
   */
  handleEditCommentCancel = () => {
    this.setState({
      editComment: null,
      editCommentText: EMPTY_COMMENT
    });
  };

  /**
   *
   * Delete a comment.
   *
   */

  /**
   * User chooses a comment to delete.
   * Show confirmation modal.
   */
  handleDeleteCommentRequest = (deleteComment: IComment) => {
    if (this.canComment) {
      this.setState({ deleteComment });
    }
  };

  /**
   * User confirms delete from modal.
   */
  handleDeleteCommentConfirm = async () => {
    const { task, deleteComment } = this.state;

    if (this.canComment && task && deleteComment) {
      const deleteCommentLoading = ApiService.deleteTaskComment(
        task.engagementGuid,
        deleteComment.commentGuid
      );

      this.setState({ deleteCommentLoading });

      try {
        await deleteCommentLoading;
        this.setState(prevState => {
          const { comments, editComment } = prevState;

          // if we deleted the edited comment, clear editComment state too
          const deletedEditComment =
            editComment &&
            editComment.commentGuid === deleteComment.commentGuid;

          return {
            comments:
              comments &&
              comments.filter(c => c.commentGuid !== deleteComment.commentGuid),
            deleteComment: null,
            editComment: deletedEditComment ? null : editComment,
            editCommentText: deletedEditComment
              ? EMPTY_COMMENT
              : prevState.editCommentText
          };
        });

        this.events.emit('commentRemoved', {
          engagementGuid: task.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }

      this.setState({ deleteCommentLoading: null });
    }
  };

  /**
   * User cancels delete from modal.
   */
  handleDeleteCommentCancel = () => {
    this.setState({ deleteComment: null });
  };

  /**
   *
   * Create a new comment.
   *
   */

  /**
   * User begins typing a new comment.
   */
  handleNewCommentChange = (newCommentText: MentionsValue) => {
    if (this.canComment) {
      this.setState({ newCommentText });
    }
  };

  /**
   * User saves new comment.
   */
  handleNewCommentSave = async () => {
    const { task, newCommentText } = this.state;
    const { text } = newCommentText.toJSON();

    if (this.canComment && task && text.length > 0) {
      const newCommentLoading = ApiService.createTaskComment(
        task.engagementGuid,
        task.queueItemGuid,
        text
      );

      this.setState({ newCommentLoading });

      try {
        const response = await newCommentLoading;
        this.setState(({ comments }) => ({
          comments: comments && comments.concat([response.data]),
          newCommentText: EMPTY_COMMENT
        }));

        this.events.emit('commentAdded', {
          engagementGuid: task.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }

      this.setState({ newCommentLoading: null });
    }
  };

  render() {
    const { t, children } = this.props;

    const {
      task,
      comments,
      commentsLoading,
      editComment,
      editCommentText,
      editCommentLoading,
      deleteComment,
      deleteCommentLoading,
      newCommentText,
      newCommentLoading
    } = this.state;

    return (
      <>
        <TaskDrawerContext.Provider
          value={{
            taskDrawerEvents: this.events,
            onTaskDrawerOpen: this.handleOpen
          }}
        >
          {children}
        </TaskDrawerContext.Provider>

        {this.canView && (
          <>
            <TaskDrawer
              canComment={this.canComment}
              comments={comments}
              commentsLoading={commentsLoading}
              editComment={editComment}
              editCommentLoading={editCommentLoading}
              editCommentText={editCommentText}
              newCommentLoading={newCommentLoading}
              newCommentText={newCommentText}
              onClose={this.handleClose}
              onDeleteComment={this.handleDeleteCommentRequest}
              onEditComment={this.handleEditComment}
              onEditCommentCancel={this.handleEditCommentCancel}
              onEditCommentChange={this.handleEditCommentChange}
              onEditCommentSave={this.handleEditCommentSave}
              onNewCommentChange={this.handleNewCommentChange}
              onNewCommentSave={this.handleNewCommentSave}
              t={t}
              task={task}
            />

            <CommentDeleteModal
              loading={deleteCommentLoading}
              onCancel={this.handleDeleteCommentCancel}
              onConfirm={this.handleDeleteCommentConfirm}
              open={deleteComment !== null}
              t={t}
            />
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(withUser(TaskDrawerProvider));
