export enum ProjectDataType {
  AccountHandler = 'PT Account Handler',
  EngagementManager = 'Engagement Manager',
  EngagementPrincipal = 'Engagement Principal',
  Jurisdiction = 'Jurisdiction',
  PracticeArea = 'Practice Area'
}

export type EngagementPersonnelFilter = {
  userGuid: string;
  memberGuid: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  userAvatarDocumentGuid: string;
  userTypeId: number;
};

export type PracticeAreaFilter = {
  name: string;
  practiceAreaGuid: string;
  practiceAreaId: string;
};

export type JurisdictionFilter = {
  abbreviation: string;
  jurisdictionGuid: string;
  name: string;
};

export type ProjectFilter =
  | EngagementPersonnelFilter
  | JurisdictionFilter
  | PracticeAreaFilter;

export interface FilterOptions {
  accountHandlers: EngagementPersonnelFilter[];
  engagementManagers: EngagementPersonnelFilter[];
  engagementPrincipals: EngagementPersonnelFilter[];
  jurisdictions: JurisdictionFilter[];
  practiceAreas: PracticeAreaFilter[];
}

export const DXP_PROJECT_FILTERS = 'DXP_PROJECT_FILTERS';
