import Table from 'components/Table';
import { IFile } from 'interfaces';

import React, { FunctionComponent } from 'react';

import { Card, ITableColumn } from '@ryan/components';

import './LearningCardSkeleton.scss';

const COLUMNS: ITableColumn<IFile>[] = [
  {
    id: 'name',
    label: 'Name',
    render: '',
    width: '60%'
  },
  {
    id: 'uploadedDate',
    label: 'Uploaded',
    render: ''
  },
  {
    id: 'actions',
    label: '',
    align: 'right',
    render: ''
  }
];

export const LearningCardSkeleton: FunctionComponent = () => (
  <Card className="learning-card learning-card-skeleton">
    <div className="learning-card__content">
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="learning-card-skeleton__title ry-skeleton" />
          <div className="learning-card-skeleton__p">
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
            <div className="ry-skeleton" />
          </div>
          <Table<IFile>
            className="learning-files-table"
            columns={COLUMNS}
            data={[]}
            loading
            loadingCount={2}
            page={1}
            pageSize={10}
            rowId="documentGuid"
            totalCount={0}
          />
        </div>
        <div className="col-12 col-md-4">
          <div className="well">
            <ul className="labeled-list row">
              <li className="col-12 col-sm-6 col-md-12">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
              <li className="col-12 col-sm-6 col-md-12">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
              <li className="col-12 col-sm-6 col-md-12">
                <div className="ry-skeleton" />
                <div className="ry-skeleton" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

export default LearningCardSkeleton;
