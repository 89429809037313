const currencies = [
  {
    isoCode: 'USD',
    symbol: '$'
  },
  {
    isoCode: 'ARS',
    symbol: '$'
  },
  {
    // Australian
    isoCode: 'AUD',
    symbol: '$'
  },
  {
    isoCode: 'CAD',
    symbol: '$'
  },
  {
    // swiss franc
    isoCode: 'CHF',
    symbol: 'CHF'
  },
  {
    isoCode: 'EUR',
    symbol: '€'
  },
  {
    // British pound
    isoCode: 'GBP',
    symbol: '£'
  },
  {
    // Hungarian forint
    isoCode: 'HUF',
    symbol: 'Ft'
  },
  {
    isoCode: 'INR',
    symbol: '₹'
  },
  {
    isoCode: 'MYR',
    symbol: 'R'
  },
  {
    isoCode: 'MXN',
    symbol: '$'
  },
  {
    // Norwegian Krone
    isoCode: 'NOK',
    symbol: 'kr'
  },
  {
    // Polish Złoty
    isoCode: 'PLN',
    symbol: 'zł'
  },
  {
    isoCode: 'SGD',
    symbol: '$'
  },
  {
    isoCode: 'DKK',
    symbol: 'kr'
  },
  {
    isoCode: 'SEK',
    symbol: 'kr'
  }
];

export default currencies;
