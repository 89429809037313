import { IconEnums } from 'utils/enums/IconEnums';

import React, { useMemo } from 'react';

import { useAppReadOnly, useUser } from '../../../../hooks';
import { Permission } from '../../../../interfaces';
import CommonActions from '../CommonActions/CommonActions';
import * as CommonActionsEnums from '../CommonActions/CommonActions.enums';
import * as UseWorklistActionsEnums from './useWorklistActions.enums';
import * as UseWorklistActionsInterfaces from './useWorklistActions.interfaces';
import * as UseWorklistActionsUtils from './useWorklistActions.utils';

const useWorklistActions = (
  props: UseWorklistActionsInterfaces.TComponentProps
) => {
  const isAppReadOnly = useAppReadOnly();
  const { permissionService } = useUser();

  return useMemo(() => {
    const { TEXT_BUTTON: ACTION_TYPE_TEXT_BUTTON } =
      CommonActionsEnums.EActionType;

    const renderCommonAction: UseWorklistActionsInterfaces.IRenderCommonAction =
      props => (
        <CommonActions
          {...UseWorklistActionsUtils.getDataForCommonAction(props)}
        />
      );

    const actionsToRenderMap: any = {
      [UseWorklistActionsEnums.EActionType.CREATE_DATA_REQUEST]: () =>
        renderCommonAction({
          actionType: ACTION_TYPE_TEXT_BUTTON,
          icon: IconEnums.PLUS,
          isDisabled: isAppReadOnly,
          onClick: props.onCreateDataRequest,
          textPath: UseWorklistActionsEnums.EActionType.CREATE_DATA_REQUEST
        }),
      [UseWorklistActionsEnums.EActionType.CREATE_TASK]: () =>
        renderCommonAction({
          actionType: ACTION_TYPE_TEXT_BUTTON,
          icon: IconEnums.PLUS,
          isDisabled: isAppReadOnly,
          onClick: props.onCreateTask,
          textPath: UseWorklistActionsEnums.EActionType.CREATE_TASK
        })
    };

    const permissionMap = {
      [UseWorklistActionsEnums.EActionType.CREATE_DATA_REQUEST]: () =>
        permissionService.hasPermission(Permission.DataRequestsEdit),
      [UseWorklistActionsEnums.EActionType.CREATE_TASK]: () =>
        permissionService.hasPermission(Permission.TasksEdit)
    };

    return () =>
      UseWorklistActionsUtils.actionTemplate.map(action => {
        return permissionMap[action]() ? (
          <span key={action}>{actionsToRenderMap[action]()}</span>
        ) : null;
      });
  }, [
    isAppReadOnly,
    permissionService,
    props.onCreateDataRequest,
    props.onCreateTask
  ]);
};

export default useWorklistActions;
