import {
  IAccount,
  IEngagement,
  IFile,
  IUser,
  IUserIdentity,
  Permission,
  UserType
} from 'interfaces';
import ApiService from 'services/ApiService';

import { findAccount } from './findAccount';

export class PermissionService {
  private user: IUserIdentity;

  /**
   * A map of GUIDs and the user's relationship with the GUID's item:
   *   - `accountGuid`: is user a CP or CM for the account
   *   - `engagementGuid`: is user big 4 or EA on an engagement
   *   - `permissionGuid`: does user have the associated permission
   *   - `userGuid`: can user manage the specified user
   */
  readonly cache: { [key: string]: boolean } = {};

  constructor(user: IUserIdentity) {
    this.user = user;
  }

  hasPermission(permissionGuid: Permission) {
    if (this.cache[permissionGuid] === undefined) {
      this.cache[permissionGuid] =
        // SuperAdmins can do EVERYTHING (except impersonate)
        (permissionGuid !== Permission.Impersonate && this.isSuperAdmin()) ||
        this.user.permissions.some(
          p => p.permissionGuid === permissionGuid && p.isGranted
        );
    }
    return this.cache[permissionGuid];
  }

  isUser(userGuid: string | null): boolean {
    return userGuid === this.user.profile.userGuid;
  }

  isRyan() {
    return this.user.profile.userTypeId === UserType.Ryan;
  }

  isSuperAdmin() {
    return this.user.profile.isSuperAdmin;
  }

  isClient() {
    return this.user.profile.userTypeId === UserType.Client;
  }

  isThirdParty() {
    return (this.user.profile.userTypeId & UserType.ThirdParty) > 0;
  }

  isClientAdmin() {
    return this.isClient() && this.hasPermission(Permission.ClientAdmin);
  }

  /**
   * Returns `true` if user is CP, CM, EP, EM or an Executive Assistant
   * (Executive Assistants are considered to beusers with the
   * `Permission.CustomizePermissions` permission).
   */
  isBig4OrExecutiveAssistant() {
    return this.user.profile.isBigFourOrExecutiveAssistant;
  }

  /**
   * Returns `true` if the current user is a CP or CM on ANY account in their
   * account tree. If an optional `accountGuid` is provided, returns `true` if
   * the user is a CP or CM on the specified account.
   */
  isClientPrincipalOrManager(accountGuid?: string): boolean {
    const {
      profile: { userGuid },
      accountTree
    } = this.user;

    // early return if not Ryan user
    if (!this.isRyan()) {
      return false;
    }

    if (accountGuid && typeof this.cache[accountGuid] !== 'undefined') {
      return this.cache[accountGuid];
    }

    // traverse accounts for one where user is a CP or CM and cache results of
    // each account check
    const match = findAccount(accountTree, (account: IAccount) => {
      const isCPCM =
        account.clientPrincipalGuid === userGuid ||
        account.clientManagerGuid === userGuid;
      this.cache[account.accountGuid] = isCPCM;
      return (!accountGuid || accountGuid === account.accountGuid) && isCPCM;
    });

    return !!match;
  }

  /**
   * Returns `true` if the current user is a CP, CM, EP, or EM on the specified engagement.
   *
   * @param engagement The engagement to check Big Four status against.
   */
  isUserBigFour({
    accountGuid,
    engagementManagerGuid,
    engagementPrincipalGuid,
    accountHandlerGuid
  }: IEngagement): boolean {
    const userGuid = this.user.profile.userGuid;
    const isEngagementPrincipalOrEngagementManager =
      userGuid === engagementPrincipalGuid ||
      userGuid === engagementManagerGuid ||
      userGuid === accountHandlerGuid;

    return (
      isEngagementPrincipalOrEngagementManager ||
      this.isClientPrincipalOrManager(accountGuid)
    );
  }

  async isUserManager(userGuid: string) {
    if (this.cache[userGuid] === undefined) {
      const response = await ApiService.canManageUserIfBigFourOrEa(userGuid);
      this.cache[userGuid] = response.data;
    }
    return this.cache[userGuid];
  }

  async isEngagementManager(engagementGuid: string) {
    if (this.cache[engagementGuid] === undefined) {
      const response = await ApiService.canManageEngagementIfBigFourOrEa(
        engagementGuid
      );
      this.cache[engagementGuid] = response.data;
    }
    return this.cache[engagementGuid];
  }

  /**
   * Returns `true` if the user can create New User Requests.
   */
  canRequestNewUser(): boolean {
    return this.isSuperAdmin() || this.isClientAdmin() || this.isRyan();
  }

  /**
   * Returns `true` if the user is able to view the "Manage Team" page.
   */
  canViewManageTeams() {
    return this.isRyan() || this.isClientAdmin() || this.isSuperAdmin();
  }

  /**
   * Returns `true` if the user can edit other users in the "Manage Team" page.
   * This check assumes that the active user is only seeing other users
   * associated with one of their accounts.
   */
  canEditUsersOnSameAccount() {
    return (
      this.isBig4OrExecutiveAssistant() ||
      this.isClientAdmin() ||
      this.isSuperAdmin()
    );
  }

  async canManageEngagement(engagementGuid: string) {
    return (
      this.isSuperAdmin() || (await this.isEngagementManager(engagementGuid))
    );
  }

  /**
   * Returns `true` if the user is able to publish the provided engagement.
   * Only super admins and the Big 4 are able to publish (not executive
   * assistants).
   *
   * @param engagement The engagement to be published.
   */
  canPublishEngagement({
    accountGuid,
    engagementManagerGuid,
    engagementPrincipalGuid
  }: IEngagement) {
    const userGuid = this.user.profile.userGuid;
    const isEPEM =
      userGuid === engagementPrincipalGuid ||
      userGuid === engagementManagerGuid;
    return (
      this.isSuperAdmin() ||
      isEPEM ||
      this.isClientPrincipalOrManager(accountGuid)
    );
  }

  /**
   * Returns `true` if current user can edit non-Ryan user profiles.
   */
  canEditClientProfile(userToEdit: IUser) {
    const {
      profile: { userTypeId }
    } = this.user;

    // allow non-Ryan user to edit their own profile
    const clientIsViewingTheirOwnProfile: boolean =
      userTypeId !== UserType.Ryan && this.isUser(userToEdit.userGuid);

    // allow Ryan users to edit non-Ryan users
    const ryanUserCanEdit: boolean =
      userTypeId === UserType.Ryan && userToEdit.userTypeId !== UserType.Ryan;

    // NOTE: don't add super admin check as it allows them to access other Ryan
    // users' personal profile pages
    return clientIsViewingTheirOwnProfile || ryanUserCanEdit;
  }

  /**
   * Returns true if the current user can perform the base level of user editing
   * operations. ie. editing role, permissions, and activating users for the
   * provided user. This check does not rely on the active account and does a
   * user-to-user check.
   */
  async canEditUser(user: IUser) {
    return (
      this.isSuperAdmin() ||
      (this.isRyan() &&
        !this.isUser(user.userGuid) &&
        (await this.isUserManager(user.userGuid ?? user.memberGuid)))
    );
  }

  async canEditUserRole(user: IUser) {
    return await this.canEditUser(user);
  }

  async canEditUserPermissions(user: IUser) {
    return await this.canEditUser(user);
  }

  async canActivateUser(user: IUser) {
    return await this.canEditUser(user);
  }

  /**
   * Returns `true` if the current user can deactivate the provided user. Ryan
   * users must have the base edit user permission level. Client users can
   * deactivate if they are a client admin (and will only have access to
   * deactivate non-Ryan users on their engagements).
   */
  async canDeactivateUser(user: IUser) {
    return (
      // deactivation only applies to non-Ryan users
      user.userTypeId !== UserType.Ryan &&
      (this.isClientAdmin() || (await this.canEditUser(user)))
    );
  }

  /**
   * Returns `true` if the user's deferred deactivation can be cancelled. Ryan
   * users must  have the base edit user permission level. Client users cannot
   * cancel deactivation.
   */
  async canCancelUserDeactivation(user: IUser) {
    return (
      // deactivation only applies to non-Ryan users
      user.userTypeId !== UserType.Ryan && (await this.canEditUser(user))
    );
  }

  canEditUserProjects(user: IUser) {
    return (
      user.userTypeId === UserType.Ryan &&
      (this.isSuperAdmin() ||
        (this.isBig4OrExecutiveAssistant() && !this.isUser(user.userGuid)))
    );
  }

  canEditUserExecutiveAccess(user: IUser) {
    return (
      this.isRyan() && this.isSuperAdmin() && user.userTypeId === UserType.Ryan
    );
  }

  canRemoveTaskAttachment(file: IFile) {
    return (
      this.hasPermission(Permission.TasksEdit) &&
      (this.isRyan() || this.user.profile.userTypeId === file.updatedByUserType)
    );
  }
}
