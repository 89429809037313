import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import { WithUser, withUser } from 'contexts/UserContext';
import { FolderSelection, IEngagement } from 'interfaces';
import { useDSSManager } from 'utils/DSS';

import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import FileRequestForm from './FileRequestForm';

import './FilesUploadModal.scss';

export interface FileUploadModalProps extends WithTranslation, WithUser {
  engagement: IEngagement;
  defaultFolder: FolderSelection;
  onClose: (file: boolean) => void;
  open: boolean;
}

const FilesUploadModal: FunctionComponent<FileUploadModalProps> = ({
  t,
  defaultFolder,
  open,
  permissionService: ps,
  engagement,
  onClose: onCloseProp
}) => {
  const dss = useDSSManager();

  const onClose = () => {
    onCloseProp(true);
    dss.clearUploads();
  };

  return (
    <UploadModalWarning dss={dss} onClose={onClose}>
      {({ dss, warning, onEscape, onCancel }) => (
        <Modal
          className="files-upload-modal"
          onClose={onEscape}
          open={open}
          title={t('Upload Files')}
        >
          {warning}
          <FileRequestForm
            defaultFolder={defaultFolder}
            dss={dss}
            engagementGuid={engagement.engagementGuid}
            isRyanUser={ps.isRyan()}
            onCancel={onCancel}
            onClose={onClose}
          />
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default withTranslation()(withUser(FilesUploadModal));
