import { useLayoutEffect, useState } from 'react';
import { BreadcrumbEmitter } from './BreadcrumbEmitter';
import IBreadcrumb from './IBreadcrumb';

export default function useBreadcrumbs() {
  const [crumbs, setCrumbs] = useState<IBreadcrumb[]>([]);

  useLayoutEffect(() => {
    function onAdd(crumb: IBreadcrumb) {
      setCrumbs(crumbs => [...crumbs, crumb]);
    }

    function onUpdate(prevUrl: string, crumb: IBreadcrumb) {
      setCrumbs(crumbs => crumbs.map(c => (c.to === prevUrl ? crumb : c)));
    }

    function onRemove(to: string) {
      setCrumbs(crumbs => crumbs.filter(c => c.to !== to));
    }

    BreadcrumbEmitter.addListener('add', onAdd);
    BreadcrumbEmitter.addListener('update', onUpdate);
    BreadcrumbEmitter.addListener('remove', onRemove);

    return () => {
      BreadcrumbEmitter.removeListener('add', onAdd);
      BreadcrumbEmitter.removeListener('update', onUpdate);
      BreadcrumbEmitter.removeListener('remove', onRemove);
    };
  }, []);

  return crumbs;
}
