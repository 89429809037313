import classNames from 'classnames';
import React, { useContext } from 'react';

import { Icon } from '@ryan/components';
import { useUniqueId } from '@ryan/components/dist/lib/internal/fallback-id';

import { IRadioButtonProps } from './RadioButton';
import { RadioButtonGroupContext } from './RadioButtonGroup';

import './RadioButtonCard.scss';

interface IRadioButtonCardProps extends IRadioButtonProps {
  children?: React.ReactNode;
  icon?: string;
}

const RadioButtonCard: React.FC<IRadioButtonCardProps> = props => {
  const context = useContext(RadioButtonGroupContext);
  const uniqueId = useUniqueId();
  const { children, icon, id = uniqueId, label, value } = props;
  let { checked, defaultChecked, name, onBlur, onChange } = props;

  // overwrite prop values if context available
  if (context) {
    ({ name, onBlur, onChange } = context);
    defaultChecked = undefined;
    checked = context.value === value;
  }

  return (
    <div
      className={classNames('radio-button-card', {
        'radio-button-card--active': checked,
        'radio-button-card--with-icon': icon
      })}
    >
      <input
        checked={checked}
        className="radio-button-card__control"
        defaultChecked={defaultChecked}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <label className="radio-button-card__label" htmlFor={id}>
        {icon && <Icon aria-hidden name={icon} />}
        {label && (
          <span className="radio-button-card__label__text">{label}</span>
        )}
        {children}
      </label>
    </div>
  );
};

export default RadioButtonCard;
