import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

type TGetWorklistAssignmentsParams = {
  filterEntityTypes: string;
  filterStatusTypes: string;
  itemsPerPage: number;
  pageNumber: number;
  searchTerm: string;
  sort: string;
  activeTabKey: string;
};

export async function getWorklistAssignments(
  this: ApiBase,
  cancelToken: CancelToken,
  params: TGetWorklistAssignmentsParams
) {
  return this.server.get('api/worklist', { cancelToken, params });
}
