import 'intersection-observer';
import { parse, stringify } from 'query-string';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BodyClass } from '@ryan/components';

import AppFooter from '../../components/AppFooter/AppFooter';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppMain from '../../components/AppMainBody/AppMainBody';
import ConfirmationModal from '../../components/Modal/ConfirmationModal/ConfirmationModal';
import { useAmplitude } from '../../contexts/AmplitudeContext/AmplitudeConsumer';
import AmplitudeProvider from '../../contexts/AmplitudeContext/AmplitudeProvider';
import { DataRequestDrawerProvider } from '../../contexts/DataRequestDrawerContext';
import { DownloadProvider } from '../../contexts/DownloadContext';
import { MilestoneDrawerProvider } from '../../contexts/MilestoneDrawerContext';
import { TaskDrawerProvider } from '../../contexts/TaskDrawerContext';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { useUser } from '../../contexts/UserContext';
import SourceOriginTracker from './SourceOriginTracker';

import './AppLayout.scss';

/**
 * The layout for the main app to be protected behind user-authentication.
 */
const AppLayout: React.FC = () => {
  const { t: getTextToDisplay } = useTranslation();
  const { terminateEventToTrack } = useAmplitude();
  const history = useHistory();
  const [, setUnblockFunction] = useState<() => void | null>(() => null);
  const {
    blockNavigation,
    isUnsavedChanges,
    pendingLocation,
    setBlockNavigation,
    setIsUnsavedChanges,
    setPendingLocation,
    targetUrl
  } = useUnsavedChanges();
  const { getAccountByGuid, setActiveAccount } = useUser();

  const { account: accountGuidQueryParam, ...queryParams } = parse(
    history.location.search
  );

  const handlePopstate = useCallback(
    (event: PopStateEvent) => {
      if (isUnsavedChanges) {
        event.preventDefault();
      }
    },
    [isUnsavedChanges]
  );

  const handleSetActiveAccount = useCallback(
    async (accountGuid: string) => {
      history.replace({ ...history.location, search: stringify(queryParams) });

      const { account, executiveAccessOnly } = await getAccountByGuid(
        accountGuid
      );

      if (!account) {
        return;
      }

      setActiveAccount(account, true, executiveAccessOnly || false);
    },
    [getAccountByGuid, history, queryParams, setActiveAccount]
  );

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [handlePopstate, isUnsavedChanges]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (isUnsavedChanges && action === 'POP') {
        setBlockNavigation(true);
        setPendingLocation(location);
        return false;
      }

      setPendingLocation(null);
      return void 0;
    });

    setUnblockFunction(() => unblock);

    return () => {
      unblock();
    };
  }, [history, isUnsavedChanges, setBlockNavigation, setPendingLocation]);

  useEffect(() => {
    if (!accountGuidQueryParam || typeof accountGuidQueryParam !== 'string') {
      return;
    }

    handleSetActiveAccount(accountGuidQueryParam);
  }, [accountGuidQueryParam, handleSetActiveAccount]);

  const handleConfirm = () => {
    setIsUnsavedChanges(false);
    const isExternalUrl = (url: string) =>
      new RegExp(
        '[a-zA-Z0-9]+://([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
      ).test(url);

    if (pendingLocation !== null) {
      const path = (pendingLocation as Location)?.pathname;
      isExternalUrl(path) ? window.location.assign(path) : history.push(path);
    } else {
      isExternalUrl(targetUrl)
        ? window.location.assign(targetUrl)
        : history.push(targetUrl);
    }
  };

  return (
    <>
      <BodyClass className="with-app-layout" />
      <AmplitudeProvider>
        <SourceOriginTracker />
        <DownloadProvider>
          <DataRequestDrawerProvider>
            <TaskDrawerProvider>
              <MilestoneDrawerProvider>
                <div className="app-layout">
                  <AppHeader />
                  <AppMain />
                  <AppFooter />
                </div>
              </MilestoneDrawerProvider>
            </TaskDrawerProvider>
          </DataRequestDrawerProvider>
        </DownloadProvider>
      </AmplitudeProvider>
      {blockNavigation && (
        <ConfirmationModal
          cancelTextToDisplay={getTextToDisplay(
            'modal.confirmationModal.noStay'
          )}
          confirmationMessage={getTextToDisplay(
            'modal.confirmationModal.unsavedMessage'
          )}
          isPositive
          onClose={() => {
            // HACK: Need to clear amplitude if user stays on page
            terminateEventToTrack();
            setBlockNavigation(false);
          }}
          onSubmit={() => handleConfirm()}
          submitTextToDisplay={getTextToDisplay(
            'modal.confirmationModal.yesLeave'
          )}
          title={getTextToDisplay('modal.confirmationModal.discardChanges')}
        />
      )}
    </>
  );
};

export default AppLayout;
