const TEMP_ID_PREFIX = 'TEMP_ID_';
const TEMP_ID_RE = new RegExp(`^${TEMP_ID_PREFIX}`);

let counter = 1;

export function createTempId() {
  return `${TEMP_ID_PREFIX}${counter++}`;
}

export function isTempId(candidate: any) {
  return typeof candidate === 'string' && TEMP_ID_RE.test(candidate);
}
