import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import { IAttachmentUpdates, IFile } from 'interfaces';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import FileAttach, { IFileAttachProps } from './FileAttach';

import './FileAttachModal.scss';

interface IFileAttachModalProps
  extends Omit<
    IFileAttachProps,
    'onAttachmentUpdate' | 'i18n' | 't' | 'tReady'
  > {
  // title of modal
  modalTitle?: string;

  // called on click of attach button
  onAttach: (
    attachments: IAttachmentUpdates,
    existingFilesToAttach: IFile[]
  ) => void;

  // called on close of modal
  onClose: () => void;

  // toggles visibility of modal
  open?: boolean;
}

/**
 * Wraps the `FileAttach` functionality in a modal.
 */
const FileAttachModal: React.FC<IFileAttachModalProps> = ({
  dss,
  modalTitle,
  onAttach,
  onClose,
  open,
  ...fileAttachProps
}) => {
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<IAttachmentUpdates>({
    addUploaded: [],
    addExisting: [],
    deleteAttachments: []
  });
  const [existingFilesToAttach, setExistingFilesToAttach] = useState<IFile[]>(
    []
  );

  /**
   * Calls `onAttach` to allow parent component to manage attached files and
   * then closes modal.
   */
  const handleAttach = () => {
    onAttach(attachments, existingFilesToAttach);
    onClose();
  };

  /**
   * Tracks selected attachments from FileAttach component.
   */
  const handleAttachmentUpdate = (
    attachments: IAttachmentUpdates,
    existingFilesToAttach: IFile[]
  ) => {
    setAttachments(attachments);
    setExistingFilesToAttach(existingFilesToAttach);
  };

  // reset attachments on close
  useEffect(() => {
    if (!open) {
      setAttachments({
        addUploaded: [],
        addExisting: [],
        deleteAttachments: []
      });
      setExistingFilesToAttach([]);
    }
  }, [open]);

  return (
    <UploadModalWarning dss={dss} onClose={onClose}>
      {({ warning, onEscape, onCancel }) => (
        <Modal
          className="file-attach-modal"
          onClose={onEscape}
          open={open}
          title={modalTitle || t('Attach', { context: 'files' })}
        >
          {warning}
          <FileAttach
            {...fileAttachProps}
            dss={dss}
            isInternalFolderShown
            onAttachmentUpdate={handleAttachmentUpdate}
          />
          <ButtonGroup>
            <Button
              disabled={
                attachments.addUploaded.length === 0 &&
                attachments.addExisting.length === 0
              }
              loading={dss.getUploadingPromise()}
              onClick={handleAttach}
              text={t('Attach')}
              type="submit"
              variant={EButtonVariant.PRIMARY}
            />
            <Button
              disabled={dss.getUploadingPromise() !== null}
              onClick={onCancel}
              text={t('Cancel')}
              variant={EButtonVariant.SECONDARY}
            />
          </ButtonGroup>
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default FileAttachModal;
