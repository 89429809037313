import copyToClipboard from 'copy-to-clipboard';
import qs from 'query-string';

import ENV from 'env';
import { TFunction } from 'i18next';
import React, { PureComponent } from 'react';
import { Trans } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Button,
  DropdownMenu,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  IDropdownMenuOption,
  ITableColumn,
  ITableProps,
  Icon,
  Tooltip,
  makeTableCheckboxFilter,
  pushToast
} from '@ryan/components';

import { WithDownload, withDownload } from '../../contexts/DownloadContext';
import EngagementContext from '../../contexts/EngagementContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import {
  DirectoryFilterGuid,
  DirectoryItemLinkType,
  Feature,
  FolderSelection,
  IAccount,
  IDirectoryFile,
  IDirectoryItem,
  IEngagement,
  IEngagementSummary,
  IFolder,
  Permission,
  UserType
} from '../../interfaces';
import { DirectoryItemStatus } from '../../routes/DataAndFiles/Files/Files';
import { IDirectoryState } from '../../routes/Project/DirectoryState';
import ApiService from '../../services/ApiService';
import { ApiErrorResponseEnums } from '../../utils/enums/ApiErrorResponseEnums';
import { formatBytes } from '../../utils/formatBytes';
import { formatDate, formatTime } from '../../utils/formatDate';
import { formatThirdPartyVisibility } from '../../utils/formatThirdPartyVisibility';
import getDirectoryItemIcon from '../../utils/getDirectoryItemIcon';
import { getDirectoryItemId } from '../../utils/getDirectoryItemId';
import getDirectoryItemLinkUrl from '../../utils/getDirectoryItemLinkUrl';
import getDirectoryItemVisibilityIcon from '../../utils/getDirectoryItemVisibilityIcon';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import Empty from '../Empty';
import FileLink from '../FileLink';
import ThirdPartyFileAccessModal from '../Modal/ThirdPartyFileAccessModal/ThirdPartyFileAccessModal';
import Table from '../Table';
import TableEmpty from '../TableEmpty/TableEmpty';
import FileBatchArchiveModal from './FileBatchArchiveModal/FileBatchArchiveModal';
import FileBatchDeleteModal from './FileBatchDeleteModal/FileBatchDeleteModal';
import FileDirectorySearchResult from './FileDirectorySearchResult/FileDirectorySearchResult';
import FileLinkModal from './FileLinkModal/FileLinkModal';
import FileLinksModal from './FileLinksModal/FileLinksModal';
import FileMoveModal from './FileMoveModal/FileMoveModal';
import FileRenameModal from './FileRenameModal';
import FileTooltip from './FileTooltip/FileTooltip';
import FolderDeleteModal from './FolderDeleteModal/FolderDeleteModal';
import FolderRenameModal from './FolderRenameModal';
import FolderTooltip from './FolderTooltip/FolderTooltip';
import {
  getArchiveRestoreSuccessToastDetails,
  getFolderGuidsFromFoldersWithActiveFiles,
  getStatusOfSelectedFilesAndFolders
} from './utils';
import {
  FileBatch,
  getFileBatch,
  getFileBatchFromSelection,
  getFileBatchFromSelectionByStatus
} from './utils/FileBatch';
import { RYAN_INTERNAL } from './utils/FileDirectoryEnums';
import { getFolderAndFileActionStatusTitle } from './utils/getFolderAndFileActionStatusTitle';
import mapDirectoryItem from './utils/mapDirectoryItem';

import './FileDirectory.scss';

export enum FileDirectoryRefreshType {
  FILE_ARCHIVED = 'fileArchived',
  FILE_DELETED = 'fileDeleted'
}

interface IFileDirectoryProps
  extends WithUser,
    RouteComponentProps,
    WithDownload,
    Omit<IDirectoryState, 'didDirectoryChange'> {
  engagement: IEngagement | null;
  fileStatusFilter?: DirectoryItemStatus;
  isInternalHeaderShown?: boolean;
  isSingleProjectView?: boolean;
  isTransferredFilesView?: boolean;
  selected: NonNullable<IDirectoryState['selected']>;
  showFileTooltips?: boolean;
  toggleLoadingState?: (status: boolean) => void;
  onFilter: ITableProps<IDirectoryItem, string>['onFilterChange'];
  onNavigateToFolder: (folderGuid: string) => void;
  onPage: ITableProps<IDirectoryItem, string>['onPageChange'];
  onRefresh: (refreshType?: FileDirectoryRefreshType) => void;
  onSelection: (selected: string[]) => void;
  onSort: ITableProps<IDirectoryItem, string>['onSortChange'];
  t: TFunction;
}

interface IFileDirectoryState {
  filesCanRead: boolean;
  filesCanWrite: boolean;
  filesCanContribute: boolean;

  ultimateAccount: IAccount | null;

  folderToDelete: IFolder | null;
  folderToDeleteLoading: Promise<any> | null;

  folderToRename: IFolder | null;
  folderToRenameLoading: Promise<any> | null;

  fileToRename: IDirectoryFile | null;
  fileToRenameLoading: Promise<any> | null;

  filesToMove: FileBatch | null;
  filesToMoveLoading: Promise<any> | null;

  filesToLink: FileBatch | null;
  filesToLinkLoading: Promise<any> | null;

  filesToArchive: FileBatch | null;
  filesToArchiveLoading: Promise<any> | null;

  filesToDelete: FileBatch | null;
  filesToDeleteLoading: Promise<any> | null;

  fileLinksModal: IDirectoryFile | null;

  fileToEditThirdPartyVisibility: IDirectoryFile | null;
}

export class FileDirectory extends PureComponent<
  IFileDirectoryProps,
  IFileDirectoryState
> {
  private _isMounted = false;

  private columns: ITableColumn<IDirectoryItem>[] = [];

  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  constructor(props: IFileDirectoryProps) {
    super(props);
    const {
      t,
      permissionService: ps,
      isSingleProjectView,
      isTransferredFilesView
    } = props;

    this.state = {
      filesCanRead: ps.hasPermission(Permission.FilesRead),
      filesCanWrite: ps.hasPermission(Permission.FilesWrite),
      filesCanContribute: ps.hasPermission(Permission.FilesContribute),
      ultimateAccount: null,
      folderToDelete: null,
      folderToDeleteLoading: null,
      folderToRename: null,
      folderToRenameLoading: null,
      fileToRename: null,
      fileToRenameLoading: null,
      filesToMove: null,
      filesToMoveLoading: null,
      filesToLink: null,
      filesToLinkLoading: null,
      filesToArchive: null,
      filesToArchiveLoading: null,
      filesToDelete: null,
      filesToDeleteLoading: null,
      fileLinksModal: null,
      fileToEditThirdPartyVisibility: null
    };

    this.columns = [
      {
        id: 'filterGuids',
        label: '',
        filter: makeTableCheckboxFilter([
          {
            value: DirectoryFilterGuid.Folder,
            label: t('Folders')
          },
          {
            value: DirectoryFilterGuid.Image,
            label: t('Images')
          },
          {
            value: DirectoryFilterGuid.PDF,
            label: t('PDFs')
          },
          {
            value: DirectoryFilterGuid.Presentation,
            label: t('Presentation')
          },
          {
            value: DirectoryFilterGuid.Spreadsheet,
            label: t('Spreadsheets')
          },
          {
            value: DirectoryFilterGuid.WordDocument,
            label: t('Word Documents')
          },
          {
            value: DirectoryFilterGuid.Other,
            label: t('Other')
          }
        ]),
        filterActive: value => value && value.length,
        render: this.renderIcon
      },
      {
        id: 'displayName',
        label: t('Name'),
        sortable: true,
        width: '25%',
        render: this.renderName
      },
      {
        id: 'updatedByName',
        label: t('Uploaded By'),
        sortable: true,
        render: this.renderUpdatedBy
      },
      {
        id: 'createDate',
        label: t('Uploaded'),
        sortable: true,
        render: this.renderUploadedDate
      },
      {
        id: 'links',
        label: t('Links'),
        render: this.renderLinks,
        width: '5%'
      },
      {
        id: 'actions',
        label: '',
        align: 'right',
        width: '10%',
        render: this.renderActions
      }
    ];

    const hideSizeAndTotalFiles = isSingleProjectView || isTransferredFilesView;

    if (!hideSizeAndTotalFiles) {
      this.columns.splice(
        4,
        0,
        {
          id: 'size',
          label: t('Size'),
          sortable: true,
          render: this.renderFileSize
        },
        {
          id: 'totalFiles',
          label: t('Total Files'),
          sortable: false,
          render: this.renderTotalFiles
        }
      );
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.setUltimateAccount();
  }

  componentDidUpdate(prevProps: IFileDirectoryProps) {
    const { engagement } = this.props;
    if (engagement !== prevProps.engagement && engagement !== null) {
      this.setUltimateAccount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Used for linking files to other accounts beneath this engagement's ultimate account.
  // Cannot link files to projects under different ultimate accounts.
  async setUltimateAccount() {
    const { engagement, getAccountByGuid } = this.props;
    if (engagement) {
      const { ultimateParentAccountGuid } = engagement;
      const { account } = await getAccountByGuid(ultimateParentAccountGuid);
      if (account) {
        this.setState({ ultimateAccount: account });
      }
    }
  }

  getSelectedFiles() {
    const { directoryItems, selected } = this.props;
    return getFileBatchFromSelection(directoryItems, selected);
  }

  handleFilterUnlockedItems = () => {
    const { directoryItems } = this.props;

    return directoryItems.filter(directoryItem => {
      if (directoryItem.type === 1) {
        return true;
      }

      if (
        this.identifyAsDirectoryFile(directoryItem.item) &&
        directoryItem.type === 2
      ) {
        return !directoryItem.item.isLocked;
      }

      return false;
    });
  };

  handleFolderRestore = (folder: IFolder) => {
    const { engagement } = this.props;

    const fileBatch = getFileBatch([], [folder]);

    this.restoreFileBatch(fileBatch, engagement!);
  };

  handleFolderRename = (folder: IFolder) => {
    this.setState({ folderToRename: folder });
  };

  handleFolderRenameSubmit = async (folderName: string) => {
    const { t, engagement, onRefresh } = this.props;
    const { folderToRename } = this.state;

    if (!engagement || !folderToRename) {
      return;
    }

    try {
      const updateEngagementFolderPromise = ApiService.updateEngagementFolder(
        engagement.engagementGuid,
        folderToRename.folderGuid,
        { folderName }
      );
      this.setState({ folderToRenameLoading: updateEngagementFolderPromise });

      await updateEngagementFolderPromise;

      this.setState({ folderToRename: null });

      pushToast({
        title: t('folder.renameModal.success.title'),
        type: EMessageTypes.SUCCESS
      });

      onRefresh();
    } catch (error: any) {
      const { data, status } = error.response || {};
      // NOTE: API call is not using unwrapCommandResponse
      const { errors } = data || {};

      let folderNameError: string | null = null;

      if (
        status === ApiErrorResponseEnums.BAD_REQUEST &&
        Array.isArray(errors)
      ) {
        errors.forEach(error => {
          if (error.propertyName === 'FolderName') {
            folderNameError = error.errorMessage;
          }
        });
      }

      if (folderNameError) {
        return folderNameError;
      }

      pushServerErrorToast();
    } finally {
      if (this._isMounted) {
        this.setState({ folderToRenameLoading: null });
      }
    }
  };

  handleFolderRenameCancel = () => {
    this.setState({ folderToRename: null });
  };

  handleFolderDelete = async (folder: IFolder) => {
    this.setState({ folderToDelete: folder });
  };

  handleFolderDeleteSubmit = async () => {
    const {
      engagement,
      fileStatusFilter,
      isFeatureToggled,
      onRefresh,
      t: getTextToDisplay
    } = this.props;
    const { folderToDelete } = this.state;
    const isLockedFilesFunctionalityVisible = isFeatureToggled(
      Feature.LockedFiles
    );

    if (engagement && folderToDelete) {
      const promise = ApiService.deleteEngagementFile(
        engagement.engagementGuid,
        fileStatusFilter || 1,
        [],
        [folderToDelete.folderGuid]
      );
      this.setState({ folderToDeleteLoading: promise });
      try {
        const { data } = await promise;
        const { deletedFilesCount } = data;

        const {
          lockedFilesCount: folderLockedFilesCount,
          totalFileCount: folderTotalFileCount
        } = folderToDelete;

        const isFolderWithOnlyLockedFiles =
          folderTotalFileCount > 0 &&
          folderLockedFilesCount === folderTotalFileCount;

        onRefresh();
        pushToast({
          type: isFolderWithOnlyLockedFiles
            ? EMessageTypes.ERROR
            : EMessageTypes.SUCCESS,
          title: getTextToDisplay(
            deletedFilesCount > 0
              ? 'folder.deleteModal.success.title'
              : isFolderWithOnlyLockedFiles
              ? 'folder.deleteModal.denied.lockedContent'
              : 'folder.deleteModal.success.zeroFileTitle',
            {
              totalFileCount: isLockedFilesFunctionalityVisible
                ? deletedFilesCount
                : folderTotalFileCount
            }
          )
        });
      } catch {
        pushServerErrorToast();
      }
      this.setState({
        folderToDelete: null,
        folderToDeleteLoading: null
      });
    }
  };

  handleFolderDeleteCancel = () => {
    this.setState({
      folderToDelete: null,
      folderToDeleteLoading: null
    });
  };

  handleFileRename = (file: IDirectoryFile) => {
    this.setState({ fileToRename: file });
  };

  handleFileRenameSubmit = async (friendlyName: string) => {
    const { engagement, folderPath, onRefresh, t } = this.props;
    const { fileToRename } = this.state;

    if (fileToRename && engagement) {
      const currentFolder =
        folderPath.length > 0 ? folderPath[folderPath.length - 1] : null;
      const promise = ApiService.updateEngagementFile(
        engagement.engagementGuid,
        fileToRename.documentGuid,
        {
          friendlyName,
          folder:
            currentFolder === null
              ? null
              : {
                  folderGuid: currentFolder.folderGuid,
                  folderName: currentFolder.folderName,
                  parentFolderGuid: currentFolder.parentFolderGuid,
                  folderVisibleToUserTypes:
                    currentFolder.folderVisibleToUserTypes
                }
        }
      );

      this.setState({ fileToRenameLoading: promise });

      try {
        const response = await promise;
        pushToast({
          type: EMessageTypes.SUCCESS,
          title: t('file.renameModal.success.title'),
          content: (
            <Trans i18nKey="file.renameModal.success.content">
              <b />
              {{ prevDisplayName: fileToRename.displayName }}
              {{ displayName: response.data.displayName }}
            </Trans>
          )
        });
        onRefresh();
      } catch {
        pushServerErrorToast();
      }

      this.setState({ fileToRename: null, fileToRenameLoading: null });
    }
  };

  handleFileRenameCancel = () => {
    const { fileToRenameLoading } = this.state;
    if (fileToRenameLoading === null) {
      this.setState({ fileToRename: null });
    }
  };

  handleFileMove = (file: IDirectoryFile) => {
    this.openMoveModal(getFileBatch([file], []));
  };

  handleFolderMove = (folder: IFolder) => {
    this.openMoveModal(getFileBatch([], [folder]));
  };

  handleBatchMove = () => {
    const { directoryItems, selected } = this.props;
    this.openMoveModal(getFileBatchFromSelection(directoryItems, selected));
  };

  openMoveModal(filesToMove: FileBatch) {
    this.setState({ filesToMove });
  }

  handleFileMoveSubmit = async (destinationFolder: FolderSelection) => {
    const { t, engagement, onRefresh } = this.props;
    const { filesToMove } = this.state;

    if (!engagement || !filesToMove) {
      return;
    }

    try {
      const batchMoveFilesPromise = ApiService.batchMoveFiles(
        engagement.engagementGuid,
        filesToMove.files.map(f => f.documentGuid),
        filesToMove.folders.map(f => f.folderGuid),
        destinationFolder
      );

      this.setState({ filesToMoveLoading: batchMoveFilesPromise });

      await batchMoveFilesPromise;

      pushToast({
        title: t('file.moveModal.successTitle', {
          count: filesToMove.filesCount,
          destinationName:
            destinationFolder !== null
              ? destinationFolder.folderName
              : engagement.engagementDisplayNameShort
        }),
        type: EMessageTypes.SUCCESS
      });

      this.setState({ filesToMove: null });

      onRefresh();
    } catch (error: any) {
      const { data, status } = error.response || {};
      // NOTE: API call is not using unwrapCommandResponse
      const { errors } = data || {};

      let folderNameError: string | null = null;

      if (
        status === ApiErrorResponseEnums.BAD_REQUEST &&
        Array.isArray(errors)
      ) {
        errors.forEach(error => {
          if (error.propertyName === 'FolderName') {
            folderNameError = error.errorMessage;
          }
        });
      }

      if (folderNameError) {
        return folderNameError;
      }

      pushServerErrorToast();
    } finally {
      if (this._isMounted) {
        this.setState({ filesToMoveLoading: null });
      }
    }
  };

  handleFileMoveCancel = () => {
    const { filesToMoveLoading } = this.state;

    if (filesToMoveLoading === null) {
      this.setState({ filesToMove: null });
    }
  };

  hasLinkOptions() {
    const { ultimateAccount } = this.state;
    return (
      ultimateAccount !== null &&
      ultimateAccount.activeEngagementCountRollup +
        ultimateAccount.inactiveEngagementCountRollup >
        1
    );
  }

  handleFileLink = (file: IDirectoryFile) => {
    this.openLinkModal(getFileBatch([file], []));
  };

  handleFolderLink = (folder: IFolder) => {
    this.openLinkModal(getFileBatch([], [folder]));
  };

  handleBatchLink = () => {
    const { directoryItems, selected } = this.props;
    this.openLinkModal(getFileBatchFromSelection(directoryItems, selected));
  };

  openLinkModal(filesToLink: FileBatch) {
    this.setState({ filesToLink });
  }

  handleFileLinkSubmit = async (
    destinationEngagement: IEngagementSummary,
    destinationFolder: FolderSelection
  ) => {
    const { t, engagement, onRefresh } = this.props;
    const { filesToLink } = this.state;

    if (!engagement || !filesToLink) {
      return;
    }

    let batchLinkFileCount = 0;

    const documentGuids = filesToLink.files
      .filter(({ archiveDate }) => !Boolean(archiveDate))
      .map(({ documentGuid }) => documentGuid);
    const folderGuids = filesToLink.folders
      .filter(({ archiveDate, archivedFileCount, totalFileCount }) => {
        if (
          !Boolean(archiveDate) &&
          totalFileCount > (archivedFileCount || 0)
        ) {
          batchLinkFileCount += totalFileCount - (archivedFileCount || 0);
          return true;
        }
        return false;
      })
      .map(({ folderGuid }) => folderGuid);

    batchLinkFileCount += documentGuids.length;

    try {
      const bachLinkFilesPromise = ApiService.batchLinkFiles(
        engagement.engagementGuid,
        documentGuids,
        folderGuids,
        destinationEngagement.engagementGuid,
        destinationFolder
      );

      this.setState({ filesToLinkLoading: bachLinkFilesPromise });

      const { data } = await bachLinkFilesPromise;
      const { alreadyLinked } = data || {};
      const alreadyLinkedCount =
        alreadyLinked !== null ? alreadyLinked.length : 0;
      const successCount = batchLinkFileCount - alreadyLinkedCount;

      // If some of the files were successfully linked, toast.
      if (successCount > 0) {
        pushToast({
          title: t('file.linkModal.successTitle', {
            count: successCount,
            destinationName:
              destinationFolder !== null
                ? destinationFolder.folderName
                : destinationEngagement.engagementDisplayNameShort
          }),
          type: EMessageTypes.SUCCESS
        });
      }

      // If some of the files were already linked, toast.
      if (alreadyLinkedCount > 0) {
        pushToast({
          content: t('file.linkModal.alreadyLinkedContent', {
            count: alreadyLinkedCount,
            engagementName: destinationEngagement.engagementDisplayNameShort,
            fileName: alreadyLinked![0].displayName
          }),
          title: t('file.linkModal.alreadyLinkedTitle', {
            count: alreadyLinkedCount
          }),
          type: EMessageTypes.WARNING
        });
      }

      this.setState({ filesToLink: null });
      onRefresh();
    } catch (error: any) {
      const { data, status } = error.response || {};
      // NOTE: API call is not using unwrapCommandResponse
      const { errors } = data || {};

      let folderNameError: string | null = null;

      if (
        status === ApiErrorResponseEnums.BAD_REQUEST &&
        Array.isArray(errors)
      ) {
        errors.forEach(error => {
          if (error.propertyName === 'FolderName') {
            folderNameError = error.errorMessage;
          }
        });
      }

      if (folderNameError) {
        return folderNameError;
      }

      pushServerErrorToast();
    } finally {
      if (this._isMounted) {
        this.setState({ filesToLinkLoading: null });
      }
    }
  };

  handleFileLinkCancel = () => {
    const { filesToLinkLoading } = this.state;
    if (filesToLinkLoading === null) {
      this.setState({ filesToLink: null });
    }
  };

  handleCopyLinkToDirectoryItem = (directoryItem: IDirectoryItem) => {
    const { t, engagement } = this.props;
    if (engagement) {
      const { engagementGuid } = engagement;
      const result = mapDirectoryItem<[string, string]>({
        directoryItem,
        handleMapForFolder: folder => [
          qs.stringify({ folderGuid: folder.folderGuid }),
          t('Folder link copied')
        ],
        handleMapForFile: file => [
          qs.stringify({ documentGuid: file.documentGuid }),
          t('File link copied')
        ]
      });

      copyToClipboard(
        `${
          ENV.PORTAL_DOMAIN
        }/app/project/${engagementGuid}/files?isDirectView=true#${result![0]}`,
        { format: 'text/plain' }
      );

      pushToast({ type: EMessageTypes.SUCCESS, title: result![1] });
    }
  };

  handleDownload = (item?: IDirectoryItem) => {
    const { engagement, directoryItems, selected, onDownloadDirectoryItems } =
      this.props;

    if (engagement) {
      onDownloadDirectoryItems(
        item
          ? [item]
          : directoryItems.filter(item =>
              selected.includes(getDirectoryItemId(item))
            ),
        engagement.engagementGuid
      );
    }
  };

  handleBatchArchive = async (fileBatchToProcess?: FileBatch) => {
    const { directoryItems, engagement, isFeatureToggled, selected } =
      this.props;

    const isLockedFilesFunctionalityVisible = isFeatureToggled(
      Feature.LockedFiles
    );
    const unlockedDirectoryItems = this.handleFilterUnlockedItems();
    const selectedDirectoryItems = isLockedFilesFunctionalityVisible
      ? unlockedDirectoryItems
      : directoryItems;

    if (engagement) {
      const fileBatch =
        fileBatchToProcess ||
        getFileBatchFromSelectionByStatus(
          selectedDirectoryItems,
          selected,
          true
        );

      const { engagementGuid } = engagement;
      const folderGuids = getFolderGuidsFromFoldersWithActiveFiles(
        fileBatch.folders
      );

      try {
        // TODO: fileBatch.filesCount is not reliable as it includes archived files which should not be processed
        if (folderGuids.length > 0 || fileBatch.files.length > 1) {
          const { data: hasValidLinks } = await ApiService.getAnyDocumentLinks(
            engagementGuid,
            {
              engagementDocumentGuids: fileBatch.files.map(
                file => (file as any).engagementDocumentGuid
              ),
              folderGuids
            }
          );

          if (hasValidLinks) {
            this.setState({ filesToArchive: fileBatch });
            return;
          }
        } else if (fileBatch.files.length === 1) {
          const { data } = await ApiService.getFileLinks({
            engagementGuid,
            documentGuid: fileBatch.files[0].documentGuid
          });
          const numberOfLinkedItems = data.results.filter(
            item =>
              item.engagementGuid === engagementGuid &&
              !(
                item.isOrigin &&
                item.linkType !== DirectoryItemLinkType.ProjectLink
              )
          ).length;

          if (numberOfLinkedItems > 0) {
            this.setState({
              filesToArchive: {
                ...fileBatch,
                numberOfLinkedItems
              }
            });
            return;
          }
        }

        // TODO: Line below may no longer be needed reconsider after refactor
        this.archiveFileBatch(fileBatch, engagement);
      } catch (error) {
        pushServerErrorToast();
      }
    }
  };

  handleBatchRestore = () => {
    const { directoryItems, engagement, selected } = this.props;

    const fileBatch = getFileBatchFromSelectionByStatus(
      directoryItems,
      selected,
      false
    );

    this.restoreFileBatch(fileBatch, engagement!);
  };

  handleBatchDelete = () => {
    const { directoryItems, isFeatureToggled, selected } = this.props;

    const isLockedFilesFunctionalityVisible = isFeatureToggled(
      Feature.LockedFiles
    );
    const unlockedDirectoryItems = this.handleFilterUnlockedItems();
    const selectedDirectoryItems = isLockedFilesFunctionalityVisible
      ? unlockedDirectoryItems
      : directoryItems;

    this.setState({
      filesToDelete: getFileBatchFromSelection(selectedDirectoryItems, selected)
    });
  };

  handleViewInFolder = (file: IDirectoryFile) => {
    // FIXME - HOW TO SOLVE FOR FILE BURIED IN PAGED DATA?
    const { history } = this.props;
    const { folderGuid, engagementGuid } = file;

    history.push(
      `/app/project/${engagementGuid}/files${
        !!folderGuid ? `#folderGuid=${folderGuid}` : ''
      }`
    );
  };

  handleFileRestore = (file: IDirectoryFile) => {
    const { engagement } = this.props;

    const fileBatch = getFileBatch([file], []);

    this.restoreFileBatch(fileBatch, engagement!);
  };

  handleFileArchiveSubmit = async () => {
    const { engagement } = this.props;
    const { filesToArchive } = this.state;

    if (filesToArchive && engagement) {
      this.archiveFileBatch(filesToArchive, engagement);
    }
  };

  archiveFileBatch = async (fileBatch: FileBatch, engagement: IEngagement) => {
    const {
      isFeatureToggled,
      onRefresh,
      onSelection,
      t: getTextToDisplay,
      toggleLoadingState
    } = this.props;
    const isLockedFilesFunctionalityVisible = isFeatureToggled(
      Feature.LockedFiles
    );

    const promise = ApiService.archiveEngagementFile(
      engagement.engagementGuid,
      fileBatch.files.map(f => f.documentGuid),
      fileBatch.folders.map(f => f.folderGuid)
    );

    this.setState({ filesToArchiveLoading: promise });

    !!toggleLoadingState && toggleLoadingState(true);

    try {
      const { data } = await promise;
      const { archivedFilesCount, archivedFoldersCount } = data;

      onSelection([]);

      const archiveSuccessToastDetails = getArchiveRestoreSuccessToastDetails({
        actionType: 'Archived',
        basePathToText: 'fileDirectory.archive.successToast',
        fileBatch: {
          ...fileBatch,
          filesCount:
            isLockedFilesFunctionalityVisible && archivedFilesCount
              ? archivedFilesCount
              : fileBatch.folders.reduce(
                  (fileCount, folder) =>
                    fileCount +
                    folder.totalFileCount -
                    folder.archivedFileCount! -
                    folder.lockedFilesCount!,
                  fileBatch.files.length
                )
        },
        archivedFoldersCount
      });

      pushToast({
        content: (
          <div className="file-directory__archive-restore-succes-toast">
            {getTextToDisplay(
              archiveSuccessToastDetails.contentPath,
              archiveSuccessToastDetails.contentDetails
            )}
          </div>
        ),
        title: getTextToDisplay(archiveSuccessToastDetails.titlePath),
        type: EMessageTypes.SUCCESS
      });
      onRefresh(FileDirectoryRefreshType.FILE_ARCHIVED);
    } catch {
      pushServerErrorToast();
    }

    this.setState({ filesToArchive: null, filesToArchiveLoading: null });
  };

  restoreFileBatch = async (fileBatch: FileBatch, engagement: IEngagement) => {
    const { onRefresh, onSelection, t, toggleLoadingState } = this.props;

    const promise = ApiService.restoreEngagementFile(
      engagement.engagementGuid,
      fileBatch.files.map(f => f.documentGuid),
      fileBatch.folders.map(f => f.folderGuid)
    );

    !!toggleLoadingState && toggleLoadingState(true);

    this.setState({ filesToArchiveLoading: promise });

    const { engagementDisplayNameShort } = engagement;
    const { folderPath } = this.props;
    const currentFolder =
      folderPath.length > 0 ? folderPath[folderPath.length - 1] : null;

    try {
      await promise;

      onSelection([]);

      const restoreSuccessToastDetails = getArchiveRestoreSuccessToastDetails({
        actionType: 'Restored',
        basePathToText: 'fileDirectory.restore.successToast',
        fileBatch
      });

      pushToast({
        content: (
          <div className="file-directory__archive-restore-succes-toast">
            {t(restoreSuccessToastDetails.contentPath, {
              ...restoreSuccessToastDetails.contentDetails,
              parentFolderName:
                currentFolder?.folderName || engagementDisplayNameShort
            })}
          </div>
        ),
        title: t(restoreSuccessToastDetails.titlePath),
        type: EMessageTypes.SUCCESS
      });
      onRefresh(FileDirectoryRefreshType.FILE_ARCHIVED);
    } catch {
      pushServerErrorToast();
    }

    this.setState({ filesToArchive: null, filesToArchiveLoading: null });
  };

  handleFileArchiveCancel = async () => {
    this.setState({ filesToArchive: null });
  };

  handleFileDelete = (file: IDirectoryFile) => {
    this.setState({ filesToDelete: getFileBatch([file], []) });
  };

  handleFileDeleteSubmit = async () => {
    const {
      engagement,
      fileStatusFilter,
      onRefresh,
      t: getTextToDisplay
    } = this.props;
    const { filesToDelete } = this.state;

    if (filesToDelete && engagement) {
      const promise = ApiService.deleteEngagementFile(
        engagement.engagementGuid,
        fileStatusFilter || DirectoryItemStatus.ALL_FILES,
        filesToDelete.files.map(f => f.documentGuid),
        filesToDelete.folders.map(f => f.folderGuid)
      );

      this.setState({ filesToDeleteLoading: promise });

      try {
        const { data } = await promise;
        const { deletedFilesCount, deletedFoldersCount } = data;

        const { files, folders } = filesToDelete;
        const hasOnlyLockedFiles =
          files.length > 0 && files.every(file => file.isLocked);
        const hasFoldersWithOnlyLockedFiles =
          folders.length > 0 &&
          folders.every(
            ({ lockedFilesCount, totalFileCount }) =>
              totalFileCount > 0 && lockedFilesCount === totalFileCount
          );

        pushToast({
          title:
            hasOnlyLockedFiles && hasFoldersWithOnlyLockedFiles
              ? getTextToDisplay('folder.deleteModal.denied.lockedContent')
              : getTextToDisplay(
                  ...getFolderAndFileActionStatusTitle({
                    basePathToTitle: 'file.batchDeleteModal.success',
                    files: filesToDelete,
                    deletedFilesCount,
                    deletedFoldersCount
                  })
                ),
          type:
            hasOnlyLockedFiles && hasFoldersWithOnlyLockedFiles
              ? EMessageTypes.ERROR
              : EMessageTypes.SUCCESS
        });
        onRefresh(FileDirectoryRefreshType.FILE_DELETED);
      } catch {
        pushServerErrorToast();
      }

      this.setState({ filesToDelete: null, filesToDeleteLoading: null });
    }
  };

  handleFileDeleteCancel = () => {
    const { filesToDeleteLoading } = this.state;
    if (filesToDeleteLoading === null) {
      this.setState({ filesToDelete: null });
    }
  };

  identifyAsDirectoryFile = (
    directoryItem: IFolder | IDirectoryFile
  ): directoryItem is IDirectoryFile => 'fileType' in directoryItem;

  identifyAsDirectoryFolder = (
    directoryItem: IFolder | IDirectoryFile
  ): directoryItem is IFolder => 'folderName' in directoryItem;

  getItemsToDisable = () => {
    const { directoryItems, isTransferredFilesView, permissionService } =
      this.props;

    let itemsToDisable: string[] = [];

    if (isTransferredFilesView && !permissionService.isRyan()) {
      const internalFilesGuids = directoryItems
        .filter(
          directoryItem =>
            this.identifyAsDirectoryFile(directoryItem.item) &&
            directoryItem.item.visibleToUserTypes === UserType.Ryan
        )
        .map(
          directoryFile => (directoryFile.item as IDirectoryFile).documentGuid
        );

      itemsToDisable = itemsToDisable.concat(internalFilesGuids);
    }

    return itemsToDisable;
  };

  render() {
    const {
      activeView,
      directoryItems,
      directoryItemsBySearch,
      engagement,
      engagementFolders,
      fileStatusFilter,
      filtered,
      folderPath,
      isAppReadOnly,
      isFeatureToggled,
      isInternalHeaderShown = true,
      isSingleProjectView,
      isTransferredFilesView,
      loading,
      onFilter,
      onPage,
      onRefresh,
      onSort,
      page,
      pageSize,
      permissionService,
      searchQuery,
      selected,
      sorted,
      t: getTextToDisplay,
      totalCount,
      totalFileCount
    } = this.props;

    const {
      ultimateAccount,
      folderToDelete,
      folderToDeleteLoading,
      folderToRename,
      folderToRenameLoading,
      filesToArchive,
      filesToArchiveLoading,
      fileToRename,
      fileToRenameLoading,
      filesToMove,
      filesToMoveLoading,
      filesToLink,
      filesToLinkLoading,
      filesToDelete,
      filesToDeleteLoading,
      fileLinksModal,
      fileToEditThirdPartyVisibility,
      filesCanRead,
      filesCanContribute,
      filesCanWrite
    } = this.state;

    const hasRyanInternalParentFolder = directoryItems.some(
      item => item.parentFolder?.path[0]?.folderName === RYAN_INTERNAL
    );
    const internalFolder = directoryItems.find(
      directoryItem =>
        this.identifyAsDirectoryFolder(directoryItem.item) &&
        directoryItem.item.folderName === RYAN_INTERNAL &&
        directoryItem.item.folderVisibleToUserTypes === UserType.Ryan
    );
    const isEngagementReadOnly =
      engagement === null || engagement.isReadOnly || isAppReadOnly;
    const isEngagementReadOnlyByGhosting =
      engagement === null || engagement.isUserGhosted || isAppReadOnly;
    const isInternalFilesFunctionalityVisible = isFeatureToggled(
      Feature.InternalFiles
    );
    const isLockedFilesFunctionalityVisible = isFeatureToggled(
      Feature.LockedFiles
    );
    const isProcessingAction = Boolean(filesToArchiveLoading);
    const isThirdParty = permissionService.isThirdParty();
    const isWithinArchivedFolder =
      folderPath.length > 0 &&
      folderPath.some(folder => Boolean(folder.archiveDate));
    const selectedFiles = this.getSelectedFiles();

    const {
      hasNestedLevelActiveFiles,
      hasTopLevelActiveFiles,
      hasTopLevelActiveFolders,
      hasTopLevelArchivedFiles,
      hasTopLevelArchivedFolders
    } = getStatusOfSelectedFilesAndFolders(selectedFiles);

    const filteredDirectoryItems = directoryItems.filter(directoryItem => {
      return directoryItem !== internalFolder;
    });
    const isAllSelectedFilesLocked =
      selectedFiles.files.length > 0 &&
      selectedFiles.files.every(file => file.isLocked === true) &&
      (!selectedFiles.folders || selectedFiles.folders.length === 0);
    const isBatchActionDisabledByInitialValidation =
      isEngagementReadOnly || selected.length === 0 || isProcessingAction;
    const isBatchActionDisabledByInitialValidationWithGhosting =
      isEngagementReadOnlyByGhosting ||
      selected.length === 0 ||
      isProcessingAction;
    const isInternalFolder = selectedFiles.folders.some(
      folder =>
        folder.folderName === RYAN_INTERNAL &&
        folder.folderVisibleToUserTypes === UserType.Ryan &&
        folder.parentFolderGuid === null
    );

    function allSelectedFoldersAndFilesCanBeDeleted(selectedFiles: FileBatch) {
      let flag = true;

      selectedFiles.folders.forEach(folder => {
        if (!folder.canBeDeletedByUser) {
          flag = false;
          return false;
        }
      });

      if (flag) {
        selectedFiles.files.forEach(file => {
          if (!file.canBeDeletedByUser) {
            flag = false;
            return false;
          }
        });
      }

      return flag;
    }

    return (
      <div className="file-directory">
        <div className="file-directory__selection-header">
          <div className="file-directory__selection-header-title">
            {selected.length > 0 && (
              <Trans
                count={
                  fileStatusFilter === DirectoryItemStatus.ALL_FILES
                    ? selectedFiles.filesCount
                    : selectedFiles.files.filter(
                        file => !Boolean(file.archiveDate)
                      ).length +
                      selectedFiles.folders.reduce(
                        (accumulator, { archivedFileCount, totalFileCount }) =>
                          accumulator + (totalFileCount - archivedFileCount!),
                        0
                      )
                }
                i18nKey="Files Selected"
              >
                <b />
              </Trans>
            )}

            {selected.length === 0 && (
              <>
                {directoryItemsBySearch ? (
                  <span>
                    <b>{getTextToDisplay('Search Results')}</b> ({totalCount})
                  </span>
                ) : (
                  <span>
                    <b>{getTextToDisplay('Total Files')}</b> ({totalFileCount})
                  </span>
                )}
              </>
            )}
          </div>
          <div className="file-directory__selection-header-actions">
            {/* Download */}
            <Button
              disabled={
                isProcessingAction ||
                selectedFiles.filesCount === 0 ||
                selectedFiles.files.some(f => !f.isReady) ||
                !filesCanRead ||
                engagement?.isUserGhosted ||
                activeView.isExecutiveView
              }
              icon="download"
              onClick={() => this.handleDownload()}
              size={EButtonSizes.SMALL}
              text={getTextToDisplay('Download')}
              variant={EButtonVariant.TEXT}
            />

            {!isThirdParty && (
              <>
                {/* Move */}
                {!isTransferredFilesView &&
                  fileStatusFilter !== DirectoryItemStatus.ARCHIVED && (
                    <Button
                      disabled={
                        isBatchActionDisabledByInitialValidation ||
                        !filesCanWrite ||
                        isWithinArchivedFolder ||
                        (isInternalFilesFunctionalityVisible &&
                          isInternalFolder)
                      }
                      icon="folder-open"
                      onClick={this.handleBatchMove}
                      size={EButtonSizes.SMALL}
                      text={getTextToDisplay('Move to…')}
                      variant={EButtonVariant.TEXT}
                    />
                  )}

                {/* Link */}
                {!isTransferredFilesView &&
                  (!isSingleProjectView ||
                    (isSingleProjectView &&
                      fileStatusFilter === DirectoryItemStatus.ACTIVE)) && (
                    <Button
                      disabled={
                        isBatchActionDisabledByInitialValidationWithGhosting ||
                        (!hasNestedLevelActiveFiles &&
                          !hasTopLevelActiveFiles) ||
                        !this.hasLinkOptions() ||
                        !filesCanContribute ||
                        (isInternalFilesFunctionalityVisible &&
                          isInternalFolder)
                      }
                      icon="link"
                      onClick={this.handleBatchLink}
                      size={EButtonSizes.SMALL}
                      text={getTextToDisplay('Link to Project…')}
                      variant={EButtonVariant.TEXT}
                    />
                  )}

                {/* Archive - should only show if selection only contains active files */}
                {fileStatusFilter !== DirectoryItemStatus.ARCHIVED && (
                  <Button
                    disabled={
                      isBatchActionDisabledByInitialValidation ||
                      (!hasTopLevelActiveFiles && !hasTopLevelActiveFolders) ||
                      !filesCanWrite ||
                      (isAllSelectedFilesLocked &&
                        isLockedFilesFunctionalityVisible) ||
                      (isInternalFilesFunctionalityVisible && isInternalFolder)
                    }
                    icon="archive"
                    onClick={() => this.handleBatchArchive()}
                    size={EButtonSizes.SMALL}
                    text={getTextToDisplay('Archive')}
                    variant={EButtonVariant.TEXT}
                  />
                )}

                {/*Restore - should only show if selection only contains archived files */}
                {fileStatusFilter !== DirectoryItemStatus.ACTIVE && (
                  <Button
                    disabled={
                      isBatchActionDisabledByInitialValidation ||
                      (!hasTopLevelArchivedFiles &&
                        !hasTopLevelArchivedFolders) ||
                      !filesCanWrite
                    }
                    icon="restore"
                    onClick={this.handleBatchRestore}
                    size={EButtonSizes.SMALL}
                    text={getTextToDisplay('Restore')}
                    variant={EButtonVariant.TEXT}
                  />
                )}

                {/* Delete */}
                {
                  <Button
                    disabled={
                      isBatchActionDisabledByInitialValidation ||
                      !filesCanWrite ||
                      !allSelectedFoldersAndFilesCanBeDeleted(selectedFiles) ||
                      (isTransferredFilesView &&
                        selectedFiles.folders.length > 0) ||
                      (isAllSelectedFilesLocked &&
                        isLockedFilesFunctionalityVisible) ||
                      (isInternalFilesFunctionalityVisible && isInternalFolder)
                    }
                    icon="trash"
                    negative
                    onClick={this.handleBatchDelete}
                    size={EButtonSizes.SMALL}
                    text={getTextToDisplay('Delete')}
                    variant={EButtonVariant.TEXT}
                  />
                }
              </>
            )}
          </div>
        </div>

        <Table<IDirectoryItem, string>
          className="file-directory__table"
          columns={this.columns}
          data={
            isInternalFilesFunctionalityVisible
              ? directoryItems
              : filteredDirectoryItems
          }
          disableSelection={this.getItemsToDisable()}
          filtered={filtered}
          groupBy={
            hasRyanInternalParentFolder && isInternalHeaderShown
              ? 'folderVisibleToUserTypes'
              : undefined
          }
          isGroupHeaderSticky
          loading={engagement === null || loading}
          loadingCount={5}
          onFilterChange={onFilter}
          onPageChange={onPage}
          onSelectChange={this.props.onSelection}
          onSortChange={onSort}
          page={page}
          pageSize={pageSize}
          renderEmpty={() => (
            <TableEmpty searchQuery={searchQuery || ''}>
              <Empty icon="folder-open">
                {getTextToDisplay('file.noFilesExist')}
              </Empty>
            </TableEmpty>
          )}
          renderGroupHeader={() => getTextToDisplay(`dataRequest.internalOnly`)}
          rowId={getDirectoryItemId}
          selected={selected}
          sorted={sorted}
          totalCount={totalCount}
        />

        {ultimateAccount && engagement && filesToLink && (
          <FileLinkModal
            accountGuid={ultimateAccount.accountGuid}
            engagementGuid={engagement.engagementGuid}
            files={filesToLink}
            loading={filesToLinkLoading}
            onCancel={this.handleFileLinkCancel}
            onSubmit={this.handleFileLinkSubmit}
          />
        )}

        {
          <>
            <FolderRenameModal
              folder={folderToRename}
              folders={engagementFolders}
              loading={folderToRenameLoading}
              onCancel={this.handleFolderRenameCancel}
              onSubmit={this.handleFolderRenameSubmit}
            />

            <FolderDeleteModal
              folder={folderToDelete}
              loading={folderToDeleteLoading}
              onCancel={this.handleFolderDeleteCancel}
              onSubmit={this.handleFolderDeleteSubmit}
            />

            <FileRenameModal
              file={fileToRename}
              loading={fileToRenameLoading}
              onCancel={this.handleFileRenameCancel}
              onSubmit={this.handleFileRenameSubmit}
            />

            {engagement && filesToMove && (
              <FileMoveModal
                files={filesToMove}
                folderDirectory={engagementFolders}
                folderPath={folderPath}
                loading={filesToMoveLoading}
                onCancel={this.handleFileMoveCancel}
                onSubmit={this.handleFileMoveSubmit}
                rootName={engagement.engagementDisplayNameShort}
              />
            )}

            {engagement && filesToArchive && (
              <FileBatchArchiveModal
                fileBatch={filesToArchive}
                loading={filesToArchiveLoading}
                onCancel={this.handleFileArchiveCancel}
                onSubmit={this.handleFileArchiveSubmit}
              />
            )}

            {engagement && (
              <FileBatchDeleteModal
                engagementGuid={engagement.engagementGuid}
                files={filesToDelete}
                loading={filesToDeleteLoading}
                onCancel={this.handleFileDeleteCancel}
                onSubmit={this.handleFileDeleteSubmit}
              />
            )}
          </>
        }

        {fileLinksModal && engagement && (
          <FileLinksModal
            engagementGuid={engagement.engagementGuid}
            file={fileLinksModal}
            onClose={this.handleLinksModalClose}
            permissionService={this.props.permissionService}
            user={this.props.user}
          />
        )}

        {fileToEditThirdPartyVisibility && (
          <ThirdPartyFileAccessModal
            currentUserTypeVisibility={
              fileToEditThirdPartyVisibility.visibleToUserTypes
            }
            fileName={fileToEditThirdPartyVisibility.displayName}
            guids={{
              documentGuid: fileToEditThirdPartyVisibility.documentGuid,
              engagementGuid: fileToEditThirdPartyVisibility.engagementGuid
            }}
            onClose={() => {
              this.setState({ fileToEditThirdPartyVisibility: null });
            }}
            onUpdate={() => {
              this.context.refreshUpdateDate?.(engagement?.engagementGuid);
              onRefresh();
            }}
          />
        )}
      </div>
    );
  }

  /**
   * Render Helpers
   */
  renderIcon = (directoryItem: IDirectoryItem) => {
    const { isFeatureToggled, isTransferredFilesView } = this.props;

    const isInternalFilesFunctionalityVisible = isFeatureToggled(
      Feature.InternalFiles
    );

    if (
      isInternalFilesFunctionalityVisible &&
      this.identifyAsDirectoryFile(directoryItem.item) &&
      directoryItem.item.visibleToUserTypes === UserType.Ryan &&
      isTransferredFilesView
    ) {
      return (
        <Icon
          className="file-directory__icon"
          name={getDirectoryItemVisibilityIcon(directoryItem)}
        />
      );
    }

    return isInternalFilesFunctionalityVisible &&
      this.identifyAsDirectoryFolder(directoryItem.item) &&
      directoryItem.item.folderVisibleToUserTypes === UserType.Ryan ? (
      <Icon className="file-directory__icon" name="folder-visibility" />
    ) : (
      <Icon
        className="file-directory__icon"
        name={getDirectoryItemIcon(directoryItem)}
      />
    );
  };

  renderName = (directoryItem: IDirectoryItem) => {
    const {
      activeView,
      directoryItemsBySearch,
      engagement,
      fileStatusFilter,
      isTransferredFilesView,
      permissionService,
      isFeatureToggled,
      onNavigateToFolder,
      t: getTextToDisplay
    } = this.props;

    if (directoryItemsBySearch) {
      return (
        <FileDirectorySearchResult
          accountName={engagement ? engagement.accountName : ''}
          directoryItem={directoryItem}
          engagementName={
            engagement ? engagement.engagementDisplayNameLong : ''
          }
          isUserGhosted={engagement?.isUserGhosted}
          onFolderClick={onNavigateToFolder}
          searchQuery={directoryItemsBySearch}
        />
      );
    }

    const isLockedFilesFunctionalityVisible = isFeatureToggled(
      Feature.LockedFiles
    );

    const isUserRyan = permissionService.isRyan();
    const disableInternalForClientUser =
      !isUserRyan &&
      directoryItem.item.visibleToUserTypes === UserType.Ryan &&
      isTransferredFilesView;

    return mapDirectoryItem({
      directoryItem,
      handleMapForFolder: folder => (
        <div className="file-link-name">
          <Button
            className="bs"
            onClick={() => onNavigateToFolder(folder.folderGuid)}
            variant={EButtonVariant.LINK}
          >
            {fileStatusFilter !== DirectoryItemStatus.ACTIVE &&
            folder.archiveDate ? (
              <i>
                <b>{folder.folderName}</b>
              </i>
            ) : (
              <b>{folder.folderName}</b>
            )}
          </Button>
          {fileStatusFilter !== DirectoryItemStatus.ACTIVE &&
            folder.archiveDate && (
              <div className="pill archived">
                {getTextToDisplay('Archived')}
              </div>
            )}
        </div>
      ),
      handleMapForFile: file => {
        const fileLinkComponent = (
          <FileLink
            engagementGuid={file.engagementGuid}
            file={file}
            fileDescription={file.friendlyName ? file.documentName : undefined}
            isDownloadingDisabled={
              engagement?.isUserGhosted ||
              activeView.isExecutiveView ||
              disableInternalForClientUser
            }
            status={fileStatusFilter}
          />
        );

        return this.identifyAsDirectoryFile(directoryItem.item) &&
          directoryItem.item.isLocked &&
          isLockedFilesFunctionalityVisible ? (
          <div className="locked-file-name">
            {fileLinkComponent}
            <Tooltip
              content={getTextToDisplay(
                'fileDirectory.lockedFileTooltipContent'
              )}
              renderTarget={({ open, ...props }) => (
                <Icon
                  aria-expanded={open}
                  aria-haspopup="true"
                  className="locked-file-name__tooltip"
                  name="lock"
                  {...props}
                />
              )}
            />
          </div>
        ) : (
          fileLinkComponent
        );
      }
    });
  };

  handleLinksModalOpen = (file: IDirectoryFile) => {
    this.setState({ fileLinksModal: file });
  };

  handleLinksModalClose = (didEdit: boolean) => {
    this.setState({ fileLinksModal: null });

    if (didEdit) {
      this.props.onRefresh();
    }
  };

  renderPill = (file: IDirectoryFile) => {
    return !!file.archiveDate ? (
      <div className="broken">
        <button
          className="pill link"
          onClick={() => this.handleLinksModalOpen(file)}
          type="button"
        >
          {file.totalLinkCount}
        </button>
      </div>
    ) : (
      <button
        className="pill link"
        onClick={() => this.handleLinksModalOpen(file)}
        type="button"
      >
        {file.totalLinkCount}
      </button>
    );
  };

  renderLinks = (directoryItem: IDirectoryItem) => {
    return mapDirectoryItem({
      directoryItem,
      handleMapForFile: file =>
        typeof file.totalLinkCount === 'number' && file.totalLinkCount > 1
          ? this.renderPill(file)
          : null
    });
  };

  renderUpdatedBy = (directoryItem: IDirectoryItem) => {
    const { t } = this.props;
    return mapDirectoryItem({
      directoryItem,
      handleMapForFile: file => {
        const { updatedByName: uploadedByName, uploadedOnBehalfOfMemberName } =
          file;
        return (
          <div>
            <div>{uploadedByName}</div>
            <div className="on-behalf-of">
              {uploadedOnBehalfOfMemberName &&
                t('On behalf of', {
                  onBehalfOfName: uploadedOnBehalfOfMemberName
                })}
            </div>
          </div>
        );
      }
    });
  };

  renderFileSize = (directoryItem: IDirectoryItem) => {
    return mapDirectoryItem({
      directoryItem,
      handleMapForFolder: folder => {
        return (
          <div>
            {formatBytes(
              this.props.fileStatusFilter === DirectoryItemStatus.ALL_FILES
                ? folder.totalFileSize
                : folder.totalFileSize - folder.archivedFileSize!
            )}
          </div>
        );
      },
      handleMapForFile: file => {
        return <div>{formatBytes(file.size)}</div>;
      }
    });
  };

  renderUploadedDate = (directoryItem: IDirectoryItem) => {
    const { isTransferredFilesView, t: getTextToDisplay } = this.props;

    return mapDirectoryItem({
      directoryItem,
      handleMapForFile: ({ uploadedDate, linkOrigin }) => {
        const formattedDate = formatDate(uploadedDate);
        const formattedTime = formatTime(uploadedDate);

        return (
          <div className="file-directory__uploaded">
            <div className="file-directory__uploaded-date">
              <div>{formattedDate}</div>
              <div>{formattedTime}</div>
            </div>
            {!isTransferredFilesView &&
              linkOrigin &&
              (!linkOrigin.engagementGuid ? (
                <div>{getTextToDisplay('Linked to Project')}</div>
              ) : (
                linkOrigin.linkGuid && (
                  <Tooltip
                    content={
                      <div className="file-directory__uploaded-via-tooltip">
                        <label className="ry-label">
                          {getTextToDisplay('Upload Origin')}
                        </label>
                        {linkOrigin.name}{' '}
                        {(linkOrigin.unlinkedDate || linkOrigin.deleteDate) && (
                          <span className="file-directory__upload-removed">
                            (
                            {getTextToDisplay(
                              linkOrigin.deleteDate ? 'Deleted' : 'Unlinked'
                            )}
                            )
                          </span>
                        )}
                      </div>
                    }
                    renderTarget={({ open, ...props }) =>
                      linkOrigin.unlinkedDate || linkOrigin.deleteDate ? (
                        <div {...props} className="table-link removed">
                          {linkOrigin.linkType
                            ? getTextToDisplay(
                                `file.viaLink.${linkOrigin.linkType}`
                              )
                            : ''}
                        </div>
                      ) : (
                        <Link
                          {...props}
                          className="table-link exists"
                          to={getDirectoryItemLinkUrl(linkOrigin)}
                        >
                          {linkOrigin.linkType
                            ? getTextToDisplay(
                                `file.viaLink.${linkOrigin.linkType}`
                              )
                            : ''}
                        </Link>
                      )
                    }
                  />
                )
              ))}
          </div>
        );
      }
    });
  };

  renderTotalFiles = (directoryItem: IDirectoryItem) => {
    return mapDirectoryItem({
      directoryItem,
      handleMapForFolder: folder => {
        return (
          <div>
            {this.props.fileStatusFilter === DirectoryItemStatus.ALL_FILES
              ? folder.totalFileCount
              : folder.totalFileCount - folder.archivedFileCount!}
          </div>
        );
      }
    });
  };

  renderActions = (directoryItem: IDirectoryItem) => (
    <div className="file-directory__actions">
      {mapDirectoryItem({
        directoryItem,
        handleMapForFolder: () => this.renderFolderActions(directoryItem),
        handleMapForFile: () => this.renderFileActions(directoryItem)
      })}
    </div>
  );

  renderFolderActions(directoryItem: IDirectoryItem) {
    const {
      activeView: { isExecutiveView },
      engagement,
      fileStatusFilter,
      isAppReadOnly,
      isSingleProjectView,
      isTransferredFilesView,
      permissionService,
      t: getTextToDisplay
    } = this.props;

    const {
      filesCanRead: hasFilesReadPermission,
      filesCanContribute: hasFilesContributePermission,
      filesCanWrite: hasFilesWritePermission
    } = this.state;

    const folder = directoryItem.item as IFolder;
    const isReadOnlyByEngagementState =
      engagement == null || engagement.isReadOnly || engagement.isUserGhosted;
    const isReadOnlyByEngagementStateWithGhosting =
      engagement == null || engagement.isUserGhosted;
    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;

    const isFolderAbleToBeDeletedByUser = folder.canBeDeletedByUser;
    const isFolderArchived = Boolean(folder.archiveDate);
    const isFolderWithFiles = folder.totalFileCount > 0;
    const isRyanInternalFolder =
      folder.folderName === RYAN_INTERNAL && folder.parentFolderGuid === null;
    const isThirdPartyUser = permissionService.isThirdParty();
    const hasFolderWithAllArchivedFiles =
      isFolderWithFiles && folder.totalFileCount === folder.archivedFileCount;

    const options = [
      {
        disabled:
          !hasFilesReadPermission ||
          isReadOnlyByUserState ||
          engagement == null ||
          engagement.isUserGhosted ||
          !isFolderWithFiles,
        icon: 'download',
        label: getTextToDisplay('Download'),
        onClick: () => this.handleDownload(directoryItem)
      },
      ...(!isThirdPartyUser && !isFolderArchived
        ? [
            {
              disabled:
                (!hasFilesWritePermission && !hasFilesContributePermission) ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState ||
                isRyanInternalFolder,
              icon: 'pencil',
              label: getTextToDisplay('Rename'),
              onClick: () => this.handleFolderRename(folder)
            }
          ]
        : []),
      ...(!isThirdPartyUser
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState ||
                isRyanInternalFolder,
              icon: 'folder-open',
              label: getTextToDisplay('Move to…'),
              onClick: () => this.handleFolderMove(folder)
            }
          ]
        : []),
      ...(!isThirdPartyUser && !isFolderArchived
        ? [
            {
              disabled:
                !hasFilesContributePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementStateWithGhosting ||
                !isFolderWithFiles ||
                hasFolderWithAllArchivedFiles ||
                !this.hasLinkOptions() ||
                isRyanInternalFolder,
              icon: 'link',
              label: getTextToDisplay('Link to Project…'),
              onClick: () => this.handleFolderLink(folder)
            }
          ]
        : []),
      {
        disabled: !hasFilesReadPermission,
        icon: 'folder-link',
        label: getTextToDisplay('Copy Link'),
        onClick: () => this.handleCopyLinkToDirectoryItem(directoryItem)
      },
      ...(!isThirdPartyUser && !isFolderArchived
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState ||
                isRyanInternalFolder,
              icon: 'archive',
              label: getTextToDisplay('Archive'),
              onClick: () => this.handleBatchArchive(getFileBatch([], [folder]))
            }
          ]
        : []),
      ...(!isThirdPartyUser && isFolderArchived
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState,
              icon: 'restore',
              label: getTextToDisplay('Restore'),
              onClick: () => this.handleFolderRestore(folder)
            }
          ]
        : []),
      ...(!isThirdPartyUser
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isTransferredFilesView ||
                isReadOnlyByEngagementState ||
                !isFolderAbleToBeDeletedByUser ||
                isRyanInternalFolder,
              icon: 'trash',
              label: getTextToDisplay('Delete'),
              negative: true,
              onClick: () => this.handleFolderDelete(folder)
            }
          ]
        : [])
    ];

    return (
      <>
        {/* Third-party visibility */}
        {this.renderThirdPartyVisibilityTooltip(directoryItem)}

        {/* Folder meta information */}
        {engagement && (
          <FolderTooltip
            fileStatusFilter={fileStatusFilter}
            folder={folder}
            hidden={Boolean(
              (!isSingleProjectView && !folder.archiveDate) ||
                isTransferredFilesView
            )}
            isSingleProjectFilesView={isSingleProjectView}
            style={{ cursor: 'pointer' }}
          />
        )}

        {/* Available actions */}
        {this.renderActionsDropdownMenu(options)}
      </>
    );
  }

  renderFileActions(directoryItem: IDirectoryItem) {
    const {
      activeView: { isExecutiveView },
      engagement,
      isAppReadOnly,
      isFeatureToggled,
      isSingleProjectView,
      isTransferredFilesView,
      permissionService,
      showFileTooltips,
      t: getTextToDisplay
    } = this.props;

    const {
      filesCanRead: hasFilesReadPermission,
      filesCanContribute: hasFilesContributePermission,
      filesCanWrite: hasFilesWritePermission
    } = this.state;

    const file = directoryItem.item as IDirectoryFile;
    const isReadOnlyByEngagementState =
      engagement == null || engagement.isReadOnly || engagement.isUserGhosted;
    const isReadOnlyByEngagementStateWithGhosting =
      engagement == null || engagement.isUserGhosted;
    const isLockedFilesFeatureToggled = isFeatureToggled(Feature.LockedFiles);
    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;

    const isFileArchived = Boolean(file.archiveDate);
    const isThirdPartyUser = permissionService.isThirdParty();
    const isUserRyan = permissionService.isRyan();
    const disableActionsMenu =
      !isUserRyan &&
      directoryItem.item.visibleToUserTypes === UserType.Ryan &&
      isTransferredFilesView;

    const {
      canBeDeletedByUser: isFileAbleToBeDeletedByUser,
      isLocked: isFileLocked,
      isReady: isFileReady
    } = file;

    const options = [
      {
        disabled:
          !hasFilesReadPermission ||
          isReadOnlyByUserState ||
          engagement == null ||
          engagement.isUserGhosted ||
          !isFileReady,
        icon: 'download',
        label: getTextToDisplay('Download'),
        onClick: () => this.handleDownload(directoryItem)
      },
      ...(!isThirdPartyUser && !isFileArchived
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState,
              icon: 'pencil',
              label: getTextToDisplay('Rename'),
              onClick: () => this.handleFileRename(file)
            }
          ]
        : []),
      ...(!isThirdPartyUser && !isFileArchived && !isTransferredFilesView
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState,
              icon: 'folder-open',
              label: getTextToDisplay('Move to…'),
              onClick: () => this.handleFileMove(file)
            }
          ]
        : []),
      ...(!isThirdPartyUser && !isFileArchived
        ? [
            {
              disabled:
                !hasFilesContributePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementStateWithGhosting ||
                !this.hasLinkOptions(),
              icon: 'link',
              label: getTextToDisplay('Link to Project…'),
              onClick: () => this.handleFileLink(file)
            }
          ]
        : []),
      {
        disabled: !hasFilesReadPermission,
        icon: 'file-link',
        label: getTextToDisplay('Copy Link'),
        onClick: () => this.handleCopyLinkToDirectoryItem(directoryItem)
      },
      ...(!isThirdPartyUser &&
      !isFileArchived &&
      file.visibleToUserTypes !== UserType.Ryan
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState,
              icon: 'show',
              label: getTextToDisplay('file.manageThirdPartyVisibility'),
              onClick: () =>
                this.setState({ fileToEditThirdPartyVisibility: file })
            }
          ]
        : []),
      ...(!isThirdPartyUser &&
      !isFileArchived &&
      (isTransferredFilesView || !(isFileLocked && isLockedFilesFeatureToggled))
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState,
              icon: 'archive',
              label: getTextToDisplay('Archive'),
              onClick: () => this.handleBatchArchive(getFileBatch([file], []))
            }
          ]
        : []),
      ...(!isThirdPartyUser && isFileArchived
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState,
              icon: 'restore',
              label: getTextToDisplay('Restore'),
              onClick: () => this.handleFileRestore(file)
            }
          ]
        : []),
      ...(!isThirdPartyUser &&
      (isFileArchived ||
        (!isFileArchived &&
          (isTransferredFilesView ||
            !(isFileLocked && isLockedFilesFeatureToggled))))
        ? [
            {
              disabled:
                !hasFilesWritePermission ||
                isReadOnlyByUserState ||
                isReadOnlyByEngagementState ||
                !isFileAbleToBeDeletedByUser,
              icon: 'trash',
              label: getTextToDisplay('Delete'),
              negative: true,
              onClick: () => this.handleFileDelete(file)
            }
          ]
        : [])
    ];

    return (
      <>
        {/* Third-party visibility */}
        {this.renderThirdPartyVisibilityTooltip(directoryItem)}

        <FileTooltip
          file={file}
          isSingleProjectView={isSingleProjectView}
          show={
            Boolean(isSingleProjectView) || showFileTooltips || isFileArchived
          }
          style={{ cursor: 'pointer' }}
          t={getTextToDisplay}
        />

        {/* Available actions */}
        {this.renderActionsDropdownMenu(options, disableActionsMenu)}
      </>
    );
  }

  renderThirdPartyVisibilityTooltip(directoryItem: IDirectoryItem) {
    const { t, permissionService: ps } = this.props;
    const { visibleToUserTypes } = directoryItem.item;
    if (
      (ps.isRyan() || ps.isClient()) &&
      (visibleToUserTypes & UserType.ThirdParty) > 0
    ) {
      const visibleToUserTypesString = formatThirdPartyVisibility(
        t,
        visibleToUserTypes
      );

      return (
        <Tooltip
          content={
            <>
              <div className="ry-label">{t('file.thirdPartyVisibility')}</div>
              {mapDirectoryItem({
                directoryItem,
                handleMapForFolder: () =>
                  t('file.folderVisibility', {
                    userTypes: visibleToUserTypesString
                  }),
                handleMapForFile: () => visibleToUserTypesString
              })}
            </>
          }
          placement="top"
          renderTarget={({ open, ...props }) => (
            <button
              aria-expanded={open}
              aria-haspopup="true"
              className="file-directory__actions-visibility"
              {...props}
            >
              <Icon name="show" />
            </button>
          )}
        />
      );
    }

    return null;
  }

  renderActionsDropdownMenu(
    options: IDropdownMenuOption[],
    disableActionsMenu?: boolean
  ) {
    return (
      <DropdownMenu
        options={options}
        renderTarget={({ ref, open, onClick }) => (
          <Button
            ariaExpanded={open}
            ariaHaspopup="menu"
            ariaLabel="menu"
            className="file-directory__actions-more"
            disabled={disableActionsMenu}
            icon="more-outline"
            innerRef={ref}
            onClick={onClick}
            size={EButtonSizes.SMALL}
            variant={EButtonVariant.TEXT}
          />
        )}
      />
    );
  }
}

export default withUser(withDownload(withRouter(FileDirectory)));
