export enum SavingsSummaryEnums {
  APPROVED = 'approved',
  APPROVED_COLOR = '#0e247e',
  POTENTIAL = 'potential',
  POTENTIAL_COLOR = '#a6caff',
  RECEIVED = 'received',
  RECEIVED_COLOR = '#edae49',
  SUBMITTED = 'submitted',
  SUBMITTED_COLOR = '#00beed'
}
