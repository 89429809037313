import DSSFileUpload from 'components/DSSFileUpload/DSSFileUpload';
import { RadioButtonCard, RadioButtonGroup } from 'components/RadioButton';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  DSSDocument,
  IReportBuilderSection,
  IReportLogo,
  Permission,
  ReportSections
} from 'interfaces';
import ApiService from 'services/ApiService';
import { DSSManager } from 'utils/DSS';
import { formikFieldProps } from 'utils/forms';

import ENV from 'env';
import { FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Icon, Switch, Textarea, Tooltip } from '@ryan/components';

import ConfirmationModal from '../../../components/Modal/ConfirmationModal/ConfirmationModal';
import { ReportBuilderPanel } from './ReportBuilderPanel';
import { getPanelStatus, panelHasNoErrors } from './reportingHelpers';

interface ICoverPageSectionProps
  extends IReportBuilderSection,
    WithUser,
    WithTranslation {
  customViewGuid: string;
  canIncludeLogos: boolean;
}

interface ICoverPageSectionState {
  accountLogos: IReportLogo[];
  logoToDelete: string | null;
  disableNext: boolean | false;
}

export class CoverPageSection extends Component<
  ICoverPageSectionProps,
  ICoverPageSectionState
> {
  fieldsToValidate = ['coverPageExecutiveSummary'];

  readonly state: ICoverPageSectionState = {
    accountLogos: [],
    logoToDelete: null,
    disableNext: false
  };

  componentDidMount() {
    this.fetchLogos();
  }

  componentDidUpdate(prevProps: ICoverPageSectionProps) {
    const prevCustomViewGuid = prevProps.customViewGuid;
    const nextCustomViewGuid = this.props.customViewGuid;
    if (prevCustomViewGuid !== nextCustomViewGuid) {
      this.fetchLogos();
    }
  }

  handleDefaultLogoSection = () => {
    const { formik } = this.props;
    const accountLogos = this.state.accountLogos as IReportLogo[];

    if (accountLogos.length > 0) {
      // We have the following conditions:
      // If none is selected, let's select the first one
      // If the one that was selected is no longer there, let's select the first one
      if (
        formik.values.coverPageLogoSelection === '' ||
        !accountLogos.some(
          a => a.reportRequestLogoGuid === formik.values.coverPageLogoSelection
        )
      ) {
        formik.setFieldValue(
          'coverPageLogoSelection',
          accountLogos[0].reportRequestLogoGuid
        );
      }
    }
  };

  fetchLogos = () => {
    const { customViewGuid, canIncludeLogos } = this.props;

    if (canIncludeLogos) {
      ApiService.getCustomViewReportLogos(customViewGuid).then(res => {
        this.setState(
          { accountLogos: res.data },
          this.handleDefaultLogoSection
        );
      });
    }
  };

  handleDSSChange = (event: string, ...args: any) => {
    const { customViewGuid } = this.props;
    if (event === 'uploading') {
      this.setState({ disableNext: true });
    }

    if (event === 'uploaded') {
      const file: File = args[0];
      const dssDocument: DSSDocument = args[1];

      ApiService.uploadReportLogoFile(customViewGuid, dssDocument).then(() => {
        this.fetchLogos();
        this.dss.removeFile(file);
      });

      this.setState({ disableNext: false });
    }

    this.forceUpdate();
  };

  private dss = new DSSManager({ onChange: this.handleDSSChange });

  handleLogoSelection = (id: string, formik: FormikProps<any>) => {
    formik.setFieldValue('coverPageLogoSelection', id);
  };

  handleLogoDelete = async () => {
    const { formik } = this.props;
    const { logoToDelete } = this.state;

    await ApiService.deleteReportLogo(logoToDelete!);

    if (formik.values.coverPageLogoSelection === logoToDelete) {
      formik.setFieldValue('coverPageLogoSelection', '');
    }

    this.setState({ logoToDelete: null });
    this.fetchLogos();
  };

  validateAndSendNext = (formik: FormikProps<any>) => {
    if (this.props.onNext && panelHasNoErrors(this.fieldsToValidate, formik)) {
      this.props.onNext(0, formik);
    }
  };

  handleLogoSwitchToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { formik } = this.props;
    const isEnabled = e.target.checked;

    formik.setFieldValue('coverPageIncludeLogo', isEnabled);
  };

  renderLogoOption = (item: IReportLogo) => {
    const { permissionService: ps } = this.props;
    const logoUrl = `${ENV.API_ROOT}/api/reports/logos/${item.reportRequestLogoGuid}`;

    return (
      <div
        className="report-builder__logo-wrapper"
        key={item.reportRequestLogoGuid}
      >
        <RadioButtonCard value={item.reportRequestLogoGuid}>
          <img alt="logo" src={logoUrl} />
        </RadioButtonCard>
        {ps.hasPermission(Permission.ReportsLogos) && (
          <button
            className="trash"
            onClick={() => {
              this.setState({ logoToDelete: item.reportRequestLogoGuid });
            }}
            type="button"
          >
            <Icon name="trash" />
          </button>
        )}
      </div>
    );
  };

  render() {
    const {
      t,
      formik,
      selectedPanel,
      onToggle,
      permissionService: ps,
      canIncludeLogos
    } = this.props;
    const { accountLogos, logoToDelete, disableNext } = this.state;

    return (
      <ReportBuilderPanel
        disableNext={disableNext}
        formik={formik}
        id={ReportSections.CoverPage}
        onNext={() => this.validateAndSendNext(formik)}
        onToggle={onToggle}
        selectedSection={selectedPanel}
        status={getPanelStatus(this.fieldsToValidate, formik)}
        title={t('reportBuilder.coverPage.title')}
      >
        <div className="report-builder__cover-page">
          <Textarea
            {...formikFieldProps('coverPageExecutiveSummary', formik)}
            label={t('reportBuilder.coverPage.executiveSummary.label')}
            maxLength={250}
          />
          {/* If they do not have the permission to upload AND there aren't any logos do not show this option */}
          {(accountLogos.length > 0 ||
            ps.hasPermission(Permission.ReportsLogos)) && (
            <>
              <div className="report-builder__cover-page__baseline-switch">
                <label>
                  {t('reportBuilder.coverPage.includeLogo.label')}
                  {canIncludeLogos ? (
                    <Switch
                      checked={formik.values.coverPageIncludeLogo}
                      onChange={this.handleLogoSwitchToggle}
                    />
                  ) : (
                    <Tooltip
                      content={t(
                        'reportBuilder.coverPage.unRelatedAccountsLogoTooltip'
                      )}
                      placement="top"
                      renderTarget={({ open, ...props }) => (
                        <div
                          aria-expanded={open}
                          aria-haspopup="true"
                          {...props}
                        >
                          <Switch disabled />
                        </div>
                      )}
                    />
                  )}
                </label>
              </div>
              {formik.values.coverPageIncludeLogo && (
                <div className="report-builder__logo-selection">
                  <RadioButtonGroup
                    {...formikFieldProps('coverPageLogoSelection', formik)}
                    onChange={(e, value) => {
                      this.handleLogoSelection(value, formik);
                    }}
                  >
                    {accountLogos.map(item => this.renderLogoOption(item))}
                  </RadioButtonGroup>
                  {/* If the user does not have the permissions to upload logos, we do not show the dss fileupload */}
                  {ps.hasPermission(Permission.ReportsLogos) && (
                    <>
                      <hr />
                      <label className="ry-label">
                        {t('reportBuilder.coverPage.fileUpload.label')}
                      </label>
                      <div className="inner-panel">
                        <DSSFileUpload
                          accept="image/png,image/jpeg,application/postscript,image/svg+xml"
                          dssManager={this.dss}
                          errorText={{
                            size: t(
                              'reportBuilder.coverPage.fileUpload.errorText'
                            ),
                            type: t(
                              'reportBuilder.coverPage.fileUpload.errorText'
                            )
                          }}
                          helperText={t(
                            'reportBuilder.coverPage.fileUpload.helperText'
                          )}
                          maxFileSize={2097152}
                          multiple
                          smallDropzone
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {logoToDelete && (
          <ConfirmationModal
            confirmationMessage={t(
              'reportBuilder.logoToDeleteConfirmationModal.description'
            )}
            onClose={() => {
              this.setState({ logoToDelete: null });
            }}
            onSubmit={this.handleLogoDelete}
            title={t('reportBuilder.logoToDeleteConfirmationModal.title')}
          />
        )}
      </ReportBuilderPanel>
    );
  }
}

export default withTranslation()(withUser(CoverPageSection));
