import SearchInput from 'components/SearchInput';
import { WithUser, withUser } from 'contexts/UserContext';
import { IContract, IContractFile } from 'interfaces';
import first from 'lodash.first';
import queryString from 'query-string';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import { BreadcrumbButton, Breadcrumbs, Icon } from '@ryan/components';

import {
  WithAmplitude,
  withAmplitude
} from '../../../contexts/AmplitudeContext/AmplitudeConsumer';
import { amplitudeEventDetail } from '../../../utils/amplitudeUtils/amplitudeUtils';
import ContractFiles from './ContractFiles';
import ContractFolders from './ContractFolders';

import './Contracts.scss';

interface IContractsProps
  extends WithAmplitude,
    WithUser,
    RouteComponentProps<{ engagementGuid: string }>,
    WithTranslation {}

interface IContractState {
  engagementDisplayNameShort: string;
  engagementGuid: string;
  searchQuery: string;
  loading: boolean;
}

// Assumes Permission.ContractsView
export class Contracts extends Component<IContractsProps, IContractState> {
  readonly state: IContractState = {
    engagementDisplayNameShort: '',
    engagementGuid: '',
    searchQuery: '',
    loading: false
  };

  constructor(props: IContractsProps) {
    super(props);
    this.contractFilesLoaded = this.contractFilesLoaded.bind(this);
  }

  async componentDidMount() {
    const { history, location, isEngagementInView } = this.props;
    const { accountGuid, engagementGuid } = queryString.parse(location.hash);
    let isInView = false;

    // verify engagement associated with the current contract is in the active
    // view
    if (
      typeof accountGuid === 'string' &&
      accountGuid &&
      typeof engagementGuid === 'string' &&
      engagementGuid
    ) {
      try {
        isInView = await isEngagementInView(engagementGuid);
      } catch {
        isInView = false;
      }

      // update view to single account view for contract's account if current
      // view does not contain engagement; leverages AccountRedirect component
      if (!isInView) {
        history.replace(`${location.pathname}?account=${accountGuid}`);
      } else {
        // clear hash
        history.replace({ ...location, hash: '' });
      }
    }

    if (isInView && typeof engagementGuid === 'string') {
      this.setState({ engagementGuid: engagementGuid });
    }
  }

  async componentDidUpdate(prevProps: IContractsProps) {
    const { isEngagementInView, location } = this.props;
    const { engagementGuid } = this.state;

    // verify current engagement is still in active view on view change
    if (switcherDidUpdate(prevProps, this.props)) {
      const isInView = await isEngagementInView(engagementGuid);

      // redirect to main contracts page if no longer in view
      if (!isInView) {
        this.handleBackToContracts();
      }
    }

    // Check previous and current location to determine how to handle internal "back" navigation
    if (prevProps.location !== location) {
      const { state: prevLocationState }: { state: any } = prevProps.location;
      const { state: curLocationState }: { state: any } = location;
      if (prevLocationState?.view === 'files' && !curLocationState?.view) {
        this.handleBackToContracts();
      }
    }
  }

  contractFilesLoaded(files: IContractFile[]) {
    // Sample the first item and take the name from it
    const sample = first(files);

    if (sample) {
      this.setState({
        engagementDisplayNameShort: sample.engagementDisplayNameShort
      });
    }
  }

  handleBackToContracts = (context?: string) => {
    const { location }: { location: any } = this.props;

    this.setState(
      {
        engagementGuid: '',
        engagementDisplayNameShort: '',
        searchQuery: ''
      },
      () => {
        // Check context and location state to understand action that triggered navigation and act accordingly.
        // In certain scenarios (bradcrumb navigation), this keeps the history stack up to date
        // and eliminates the need to click the back button twice in the browser
        if (context === 'breadcrumb' && location.state?.view === 'files') {
          this.props.history.goBack();
        }
      }
    );
  };

  handleContractFolderSelected = (contract: IContract) => {
    this.setState(
      {
        engagementGuid: contract.engagementGuid,
        engagementDisplayNameShort: contract.engagementDisplayNameShort,
        searchQuery: ''
      },
      () => {
        this.props.initializeEventToTrack({
          eventName: amplitudeEventDetail.ryanContracts.eventName,
          eventProperty:
            amplitudeEventDetail.ryanContracts.viewPropertyOptions.dataAndFiles
        });

        this.props.history.push('/app/data-and-files/contracts', {
          view: 'files'
        });
      }
    );
  };

  handleSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  render() {
    const { engagementGuid, engagementDisplayNameShort, searchQuery, loading } =
      this.state;
    const { activeView, t: getTextToDisplay } = this.props;

    return (
      <>
        <div className="contracts-page">
          <div className="contracts-page__header">
            <h2 className="ry-h2">
              {getTextToDisplay('dataAndFiles.ryanContracts.contracts')}
            </h2>
            <SearchInput
              loading={loading}
              onChange={this.handleSearch}
              value={searchQuery}
            />
          </div>
          <div className="contracts-page__explorer">
            <Breadcrumbs>
              <BreadcrumbButton
                onClick={this.handleBackToContracts.bind(this, 'breadcrumb')}
              >
                <Icon name="folder-open" />
                {activeView.name}
              </BreadcrumbButton>
              {engagementDisplayNameShort !== '' && (
                <BreadcrumbButton onClick={() => {}}>
                  {engagementDisplayNameShort}
                </BreadcrumbButton>
              )}
            </Breadcrumbs>
          </div>
        </div>

        {engagementGuid !== '' ? (
          <ContractFiles
            engagementGuid={engagementGuid}
            onContractFilesLoaded={this.contractFilesLoaded}
            onLoading={isLoading => this.setState({ loading: isLoading })}
            searchQuery={searchQuery}
          />
        ) : (
          <ContractFolders
            customViewGuid={activeView.customViewGuid}
            onContractFolderSelected={this.handleContractFolderSelected}
            onLoading={isLoading => this.setState({ loading: isLoading })}
            searchQuery={searchQuery}
          />
        )}
      </>
    );
  }
}

export default withRouter(
  withAmplitude(withUser(withTranslation()(Contracts)))
);
