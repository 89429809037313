import {
  IAmplitudeContext,
  IInitializeEventToTrack,
  ITriggerAmplitudeEvent,
  TEventProperty
} from './AmplitudeContext.interfaces';

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AmplitudeApiService from '../../services/AmplitudeApiService';
import {
  AmplitudeActionType,
  amplitudeLocationMapper,
  getAmplitudeLocation
} from '../../utils/amplitudeUtils/amplitudeUtils';
import AmplitudeContext from './AmplitudeContext';

const AmplitudeProvider: React.FC = ({ children }) => {
  const location = useLocation();

  const [eventAction, setEventAction] = useState<string>(
    AmplitudeActionType.VIEW
  );
  const [eventName, setEventName] = useState<string | null>(null);
  const [eventProperty, setEventProperty] = useState<TEventProperty>(null);
  const [eventPathname, setEventPathname] = useState<string | null>(null);

  const triggerAmplitudeEvent: ITriggerAmplitudeEvent = useCallback(
    customizedAmplitudeEvent => {
      const {
        amplitudeEventAction = eventAction,
        amplitudeEventLocation = eventPathname,
        amplitudeEventName = eventName,
        amplitudeEventProperty = eventProperty
      } = customizedAmplitudeEvent || {};

      AmplitudeApiService.logEvent(
        `${amplitudeEventAction}-${amplitudeEventName}-${
          amplitudeEventLocation
            ? amplitudeLocationMapper[
                amplitudeEventLocation as keyof typeof amplitudeLocationMapper
              ]
            : getAmplitudeLocation()
        }`,
        amplitudeEventProperty
      );
    },
    [eventAction, eventName, eventPathname, eventProperty]
  );

  const handleLocationChange = useCallback(async () => {
    if (!eventName) {
      return;
    }

    triggerAmplitudeEvent();
    terminateEventToTrack();
  }, [eventName, triggerAmplitudeEvent]);

  useEffect(() => {
    handleLocationChange();
  }, [location, handleLocationChange]);

  const initializeEventToTrack: IInitializeEventToTrack = ({
    eventAction: action = AmplitudeActionType.VIEW,
    eventName: name,
    eventProperty: property = null,
    isTrackingOrigin = false
  }) => {
    if (isTrackingOrigin) {
      setEventPathname(location.pathname);
    }

    setEventAction(action);
    setEventName(name);
    setEventProperty(property);
  };

  const terminateEventToTrack = () => {
    setEventAction(AmplitudeActionType.VIEW);
    setEventName(null);
    setEventPathname(null);
    setEventProperty(null);
  };

  const contextValue: IAmplitudeContext = {
    initializeEventToTrack,
    terminateEventToTrack,
    triggerAmplitudeEvent
  };

  return (
    <AmplitudeContext.Provider value={contextValue}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export default AmplitudeProvider;
