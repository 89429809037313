import { CommentText } from 'components/Comments';

import React, { FunctionComponent, ReactNode } from 'react';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { IActivityProps } from '../IActivityProps';
import { findModifiedByKey } from '../findModifiedByKey';

interface ICommentActivityProps extends IActivityProps {
  editedComment: boolean;
  link: string;
  children: (mentionedYou: boolean) => ReactNode;
}

export const CommentActivity: FunctionComponent<
  ICommentActivityProps
> = props => {
  const {
    t,
    user,
    activity,
    isNotification,
    showAccountName,
    showEngagementName,
    editedComment,
    link,
    children,
    onCloseNotificationDrawer
  } = props;

  const { commentTaggedUsers, snapShot } = activity;
  const commentText = snapShot.commentText || '';

  let oldCommentText: string | undefined;
  let newCommentText: string;

  // If we're dealing with an edited comment,
  // we'll find the history in modifiedInformation.
  if (editedComment) {
    try {
      const modifiedComment = findModifiedByKey(activity, 'CommentText');
      oldCommentText = modifiedComment.oldValue;
      newCommentText = modifiedComment.newValue;
    } catch {
      oldCommentText = commentText;
      newCommentText = commentText;
    }
  } else {
    // Otherwise, it's a new coment, so just check the activity.
    newCommentText = commentText;
  }

  const mentionedYou =
    commentTaggedUsers &&
    commentTaggedUsers.some(u => u.userGuid === user.userGuid);

  return (
    <AbstractActivity
      activity={activity}
      color={ActivityColor.Lime}
      ctaText={t('View Comment')}
      icon={mentionedYou ? 'at-mention' : 'comment'}
      isNotification={isNotification}
      onCloseNotificationDrawer={onCloseNotificationDrawer}
      showAccountName={showAccountName}
      showEngagementName={showEngagementName}
      title={t(editedComment ? 'Comment Edited' : 'New Comment')}
      to={link}
    >
      {children(mentionedYou)}
      <div className="activity-inset">
        <div className="line-clamp">
          <CommentText taggedUsers={commentTaggedUsers} text={newCommentText} />
        </div>
        {oldCommentText && (
          <del className="line-clamp">
            <CommentText
              taggedUsers={commentTaggedUsers}
              text={oldCommentText}
            />
          </del>
        )}
      </div>
    </AbstractActivity>
  );
};
