import TooltipList from 'components/TooltipList/TooltipList';
import { IFolder } from 'interfaces';
import { DirectoryItemStatus } from 'routes/DataAndFiles/Files/Files';
import { formatBytes } from 'utils/formatBytes';
import { formatDate } from 'utils/formatDate';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import './FolderTooltip.scss';

const FolderTooltip: FunctionComponent<{
  fileStatusFilter?: DirectoryItemStatus;
  folder: IFolder;
  hidden: boolean;
  isSingleProjectFilesView?: boolean;
  style?: Record<string, unknown>;
}> = ({
  fileStatusFilter,
  folder,
  hidden,
  isSingleProjectFilesView,
  ...passthroughProps
}) => {
  const { t } = useTranslation();
  const isArchived = !!folder.archiveDate;

  const archivedFolderTooltipContent = [
    {
      label: t('Archived'),
      value: folder.archiveDate ? formatDate(folder.archiveDate) : ''
    },
    {
      label: t('Archived By'),
      value: folder.archivedByName || ''
    }
  ];

  const totalFilesCountAndSize = [
    {
      label: t('Total Files'),
      value:
        fileStatusFilter === DirectoryItemStatus.ALL_FILES
          ? folder.totalFileCount
          : folder.totalFileCount - folder.archivedFileCount!
    },
    {
      label: t('file.fileSize'),
      value: formatBytes(
        fileStatusFilter === DirectoryItemStatus.ALL_FILES
          ? folder.totalFileSize
          : folder.totalFileSize - folder.archivedFileSize!
      )
    }
  ];

  const singleProjectArchivedFolderTooltipContent =
    totalFilesCountAndSize.concat(archivedFolderTooltipContent);

  return hidden ? (
    <></>
  ) : (
    <TooltipList
      {...passthroughProps}
      ariaLabel={t('folder.folderInformation')}
      list={[
        ...(fileStatusFilter === DirectoryItemStatus.ACTIVE
          ? totalFilesCountAndSize
          : isArchived
          ? isSingleProjectFilesView
            ? singleProjectArchivedFolderTooltipContent
            : archivedFolderTooltipContent
          : totalFilesCountAndSize)
      ]}
    />
  );
};

export default FolderTooltip;
