import { deflate } from 'pako';

// https://en.wikipedia.org/wiki/List_of_archive_formats
const compressedTypes = [
  'application/gzip',
  'application/java-archive',
  'application/vnd.android.package-archive',
  'application/vnd.ms-cab-compressed',
  'application/x-7z-compressed',
  'application/x-ace-compressed',
  'application/x-alz-compressed',
  'application/x-apple-diskimage',
  'application/x-arj',
  'application/x-b1',
  'application/x-bzip2',
  'application/x-cfs-compressed',
  'application/x-compress',
  'application/x-dar',
  'application/x-dgc-compressed',
  'application/x-freearc',
  'application/x-gca-compressed',
  'application/x-gtar',
  'application/x-lzh',
  'application/x-lzip',
  'application/x-lzma',
  'application/x-lzop',
  'application/x-lzx',
  'application/x-ms-wim',
  'application/x-rar-compressed',
  'application/x-snappy-framed',
  'application/x-stuffit',
  'application/x-stuffitx',
  'application/x-xar',
  'application/x-xz',
  'application/x-zip-compressed',
  'application/x-zoo',
  'application/zip',
  'application/zstd'
];

async function read(blob: Blob) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e.target) resolve(e.target.result as ArrayBuffer);
      else reject();
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(blob);
  });
}

/**
 * Compress a file, maybe.
 */
export default async function compress(blob: Blob) {
  try {
    if (compressedTypes.includes(blob.type)) {
      throw new Error('File already compressed.');
    }

    const buffer = await read(blob);
    const compressedArray = deflate(new Uint8Array(buffer), { level: 1 });
    const compressedBlob = new Blob([compressedArray.buffer], {
      type: blob.type
    });

    return { blob: compressedBlob, compressed: true };
  } catch (error) {
    return { blob, compressed: false };
  }
}
