import { IMilestone } from 'interfaces';

const effectiveDate = (milestone: IMilestone) => {
  const date = milestone.startDate || milestone.endDate;
  return date === null ? 0 : date.setHours(0, 0, 0, 0);
};

export default function sortMilestones(milestones: IMilestone[]) {
  return (
    milestones
      // shallow copy
      .slice()
      // sort first by type, then by date
      .sort(
        (a, b) =>
          a.milestoneTypeId - b.milestoneTypeId ||
          effectiveDate(a) - effectiveDate(b) ||
          b.statusId - a.statusId
      )
  );
}
