import { useEffect, useState } from 'react';
import { useUser } from './UserConsumer';
import { IUserContext } from './UserContext';

/**
 * Returns a memoized value of `isAppReadOnly` from `UserContext`, which is
 * more efficient if that is the only value needed from use context and prevents
 * uneccessary rerenders on view change. Use standard `useUser` hook if
 * additional fields are needed.
 */
export default function useAppReadOnly(): IUserContext['isAppReadOnly'] {
  const { isAppReadOnly } = useUser();
  const [memoizedIsAppReadOnly, setMemoizedIsAppReadOnly] = useState<
    IUserContext['isAppReadOnly']
  >(isAppReadOnly);

  useEffect(() => {
    setMemoizedIsAppReadOnly(isAppReadOnly);
  }, [isAppReadOnly]);

  return memoizedIsAppReadOnly;
}
