import { WithUser, hasOneAccount, withUser } from 'contexts/UserContext';
import { INotification } from 'interfaces';

import React, { FunctionComponent, useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import Activity from '../Activity';
import Empty from '../Empty';
import { NotificationHookResponse } from '../NotificationDrawer/useNotifications';
import groupNotificationsByDate from './groupNotificationsByDate';

import './NotificationFeed.scss';

interface INotificationFeedProps extends WithTranslation, WithUser {
  notifications: INotification[];
  notificationsLoading: boolean;
  onDismissNotifications: NotificationHookResponse[1]['dismissNotifications'];
  onCloseNotificationDrawer: () => void;
  onTrackDismissNotifications: (totalResults: number) => void;
}

export const NotificationFeed: FunctionComponent<
  INotificationFeedProps
> = props => {
  const {
    i18n: { language },
    notifications,
    notificationsLoading,
    onCloseNotificationDrawer,
    onDismissNotifications,
    onTrackDismissNotifications,
    t,
    user
  } = props;

  // Memoize the grouped notifications
  const groupedNotifications = useMemo(
    () => groupNotificationsByDate(notifications, t, language),
    [language, notifications, t]
  );

  // Empty state
  if (notifications.length === 0) {
    return <Empty icon="bell">{t('activity.notifications.none')}</Empty>;
  }

  return (
    <div className="notification-feed">
      {groupedNotifications.map(day => (
        <div className="notification-feed__day-group" key={day.key}>
          <div className="notification-feed__day-group-label">
            <b className="notification-feed__day-group-label-text">
              {day.label}
            </b>
            <div className="notification-feed__day-group-label-line" />
            <button
              className="notification-feed__day-group-dismiss"
              disabled={notificationsLoading}
              onClick={() => {
                onDismissNotifications(day.notifications, {
                  dismissDate: day.isoDate
                });
                onTrackDismissNotifications(day.notifications.length);
              }}
            >
              <Icon name="delete" />
            </button>
          </div>
          {day.notifications.map(notification => {
            return (
              <div
                className="notification-feed__activity"
                key={notification.notificationGuid}
              >
                <Activity
                  activity={notification.activitySummary}
                  isNotification={true}
                  onCloseNotificationDrawer={onCloseNotificationDrawer}
                  showAccountName={!hasOneAccount(user.accountTree)}
                  showEngagementName
                  // We only care about showing the account on the notification feed drawer
                  // if the user is associated to more than one account.
                  t={t}
                  user={user.profile}
                />
                <button
                  className="notification-feed__activity-dismiss"
                  disabled={notificationsLoading}
                  onClick={() => {
                    onDismissNotifications([notification], {
                      notificationGuids: [notification.notificationGuid]
                    });
                    onTrackDismissNotifications(1);
                  }}
                >
                  <Icon name="delete" />
                </button>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default withTranslation()(withUser(NotificationFeed));
