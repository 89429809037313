import { IGetFormattedUserInfo, IGetFullName } from './IGetFormattedUserInfo';
import { TGetUserInfoMapper } from './TGetUserInfoMapper';

export const getFormattedUserInfo: IGetFormattedUserInfo = (key, user) => {
  return getUserInfoMapper[key](user);
};

const getFullName: IGetFullName = user => {
  return (
    user.fullName || `${user.firstName || ''} ${user.lastName || ''}`.trim()
  );
};

const getUserInfoMapper: TGetUserInfoMapper = {
  fullName: getFullName
};
