export interface Match<T> {
  element: T;
  path: T[];
}

interface Predicate<T> {
  (element: T): boolean;
}

export default function makeTreeSearch<T>(
  subElementsPointer: (parent: T) => T[]
) {
  function traverse(element: T, predicate: Predicate<T>): Match<T> | undefined {
    if (predicate(element)) {
      return { element, path: [element] };
    }

    const subElements = subElementsPointer(element);
    for (let i = 0; i < subElements.length; i++) {
      const result = traverse(subElements[i], predicate);
      if (result) {
        result.path.unshift(element);
        return result;
      }
    }
  }

  return function (candidate: T | T[], predicate: Predicate<T>) {
    if (Array.isArray(candidate)) {
      for (let i = 0; i < candidate.length; i++) {
        const result = traverse(candidate[i], predicate);
        if (result) {
          return result;
        }
      }
    } else {
      return traverse(candidate, predicate);
    }
  };
}
