import { CancelToken } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  match,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';

import { usePrevious, useUser } from '../../hooks';
import screenTooSmallImage from '../../images/screen-size-too-small.svg';
import {
  ISavingsSummaryEntry,
  ISavingsSummaryEntryCategoryInitials
} from '../../interfaces';
import SavingsSummaryEdit from '../../routes/SavingsSummaryEntry/SavingsSummaryEdit';
import { createCategory } from '../../routes/SavingsSummaryEntry/savingsSummaryHelpers';
import ApiService from '../../services/ApiService';
import { TGetSupportedISOCurrencyCodes } from '../../services/ApiService/ApiSavings';
import { handleDefaultApiError } from '../../utils/handleApiError/handleApiError';
import switcherDidUpdate from '../../utils/switcherDidUpdate';
import { Breadcrumb } from '../Breadcrumbs';
import DocumentTitle from '../DocumentTitle';
import SavingsEntryDateAndCurrency from './SavingsEntryDateAndCurrency/SavingsEntryDateAndCurrency';
import { THandleUpdateEntry } from './SavingsEntryDateAndCurrency/utils';
import {
  IHandleCreateEntry,
  IRenderScreenTooSmall,
  SavingsSummaryEnums,
  TEngagementSavingsSummary,
  TMatchParams,
  savingsSummaryUtils
} from './utils';

import './SavingsSummary.scss';

const SavingsSummary: React.FC = () => {
  const {
    activeView,
    getAccountByGuid,
    isEngagementInView,
    setActiveAccountForEngagement
  } = useUser();

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch() as match<TMatchParams>;
  const [engagementAccountName, setEngagementAccountName] = useState('');
  const [engagementSavingsSummary, setEngagementSavingsSummary] =
    useState<TEngagementSavingsSummary>(
      savingsSummaryUtils.getInitialEngagementSavingsSummary()
    );
  const [engagementSummaryLatestEntry, setEngagementSummaryLatestEntry] =
    useState<ISavingsSummaryEntry | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isoCurrencyCodeOptions, setIsoCurrencyCodeOptions] = useState<
    TGetSupportedISOCurrencyCodes[]
  >([]);
  const { t: getTextToDisplay } = useTranslation();

  const {
    CREATE_ENTRY,
    CREATE_FIRST_ENTRY,
    ROOT_TO_TEXT,
    UPDATE_ACTION,
    UPDATE_ENTRY
  } = SavingsSummaryEnums;

  const isFirstEntry =
    engagementSavingsSummary.lastSavingsSummaryAsOfDate == null;
  const isUpdateEntry =
    !isFirstEntry && engagementSummaryLatestEntry?.savingsSummaryGuid != null;
  const previousActiveView = usePrevious(activeView);

  let entryType = CREATE_ENTRY;

  if (isFirstEntry) {
    entryType = CREATE_FIRST_ENTRY;
  } else if (isUpdateEntry) {
    entryType = UPDATE_ENTRY;
  }

  const title = getTextToDisplay(`${ROOT_TO_TEXT}.${entryType}.title`);
  const breadcrumbs = [
    {
      label: getTextToDisplay('projects.link'),
      to: '/app/projects'
    },
    {
      label: engagementSavingsSummary.engagementDisplayNameShort,
      to: `/app/project/${engagementSavingsSummary.engagementGuid}/savings-history`
    },
    {
      label: title,
      to: location.pathname
    }
  ];

  const handleSwitcherUpdate = useCallback(
    async (engagementGuid: string) => {
      const isInView = await isEngagementInView(engagementGuid);

      if (!isInView) {
        history.push('/app/projects/savings-summary');
        return;
      }
    },
    [history, isEngagementInView]
  );

  const initializeWithLatestEntry = useCallback(
    async (engagementGuid: string) => {
      const response = await ApiService.getEngagementSavingsSummaryLatestEntry(
        engagementGuid
      );

      setEngagementSummaryLatestEntry(response.data);
    },
    [setEngagementSummaryLatestEntry]
  );

  const initialize = useCallback(
    async (
      action: string,
      engagementGuid: string,
      cancelToken: CancelToken
    ) => {
      try {
        const [
          { data: engagementSavingsSummaryResponse },
          { data: engagementSavingsSummaryDetailsResponse },
          { data: isoCurrencyCodesResponse }
        ] = await Promise.all([
          ApiService.getEngagementSavingsSummary(engagementGuid, cancelToken),
          ApiService.getEngagementSavingsSummaryDetails(
            engagementGuid,
            cancelToken,
            true
          ),
          ApiService.getSupportedISOCurrencyCodes(cancelToken)
        ]);

        const { account } = await getAccountByGuid(
          engagementSavingsSummaryResponse.accountGuid
        );

        if (!account) {
          history.replace('/app/403');
          return;
        }

        setActiveAccountForEngagement(engagementSavingsSummaryResponse);
        setEngagementAccountName(account.name);
        setEngagementSavingsSummary(
          savingsSummaryUtils.parseToEngagementSavingsSummary(
            engagementSavingsSummaryResponse,
            engagementSavingsSummaryDetailsResponse
          )
        );
        setIsoCurrencyCodeOptions(isoCurrencyCodesResponse);

        if (action === UPDATE_ACTION) {
          await initializeWithLatestEntry(engagementGuid);
        }

        setIsLoading(false);
      } catch (error) {
        handleDefaultApiError(error);
      }
    },
    [
      getAccountByGuid,
      history,
      initializeWithLatestEntry,
      setActiveAccountForEngagement,
      UPDATE_ACTION
    ]
  );

  useEffect(() => {
    const cancelTokenSource = ApiService.CancelToken.source();
    const { action, engagementGuid } = match.params;

    if (!previousActiveView) {
      initialize(action, engagementGuid, cancelTokenSource.token);
    } else {
      if (
        switcherDidUpdate(
          // HACK: Convert to any until proper type is created
          { activeView: previousActiveView } as any,
          { activeView } as any
        )
      ) {
        handleSwitcherUpdate(engagementGuid);
      }
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [
    activeView,
    handleSwitcherUpdate,
    initialize,
    match.params,
    previousActiveView
  ]);

  const handleCreateEntry: IHandleCreateEntry = ({
    entry,
    setEngagementSummaryLatestEntryCallback
  }) => {
    const modifiedEntry = {
      ...entry,
      attachments: [],
      commentText: '',
      savingsSummaryGuid: null
    } as any;

    modifiedEntry.categories = entry.lastSavingsSummaryCategories
      ? entry.lastSavingsSummaryCategories
          .filter(
            (category: ISavingsSummaryEntryCategoryInitials) =>
              !category.isDeleted
          )
          .map((category: ISavingsSummaryEntryCategoryInitials) =>
            createCategory(category)
          )
      : [createCategory(getTextToDisplay('Savings'))];

    // HACK: Align to ISavingsSummaryEntry until new type is created in refactor of SavingsSummaryEdit component
    modifiedEntry.lastAsOfDate = modifiedEntry.lastSavingsSummaryAsOfDate;
    modifiedEntry.lastCategories = modifiedEntry.lastSavingsSummaryCategories;
    delete modifiedEntry.isActive;
    delete modifiedEntry.lastSavingsSummaryAsOfDate;
    delete modifiedEntry.lastSavingsSummaryCategories;

    setEngagementSummaryLatestEntryCallback(modifiedEntry);
  };

  const renderScreenTooSmall: IRenderScreenTooSmall = content => (
    <div className="savings-summary__screen-too-small d-lg-none">
      <img
        alt="Screen Too Small"
        aria-hidden
        role="presentation"
        src={screenTooSmallImage}
      />
      <h2 className="ry-h2">{content.title}</h2>
      <p>{content.message}</p>
    </div>
  );

  return isLoading ? (
    <>{`${getTextToDisplay(`${ROOT_TO_TEXT}.loading`)}...`}</>
  ) : (
    <div className="savings-summary">
      <DocumentTitle title={title} />

      {breadcrumbs.map((props, index) => (
        <Breadcrumb {...props} key={index} />
      ))}

      {renderScreenTooSmall({
        message: getTextToDisplay(`${ROOT_TO_TEXT}.screenTooSmall.message`),
        title: getTextToDisplay(`${ROOT_TO_TEXT}.screenTooSmall.title`)
      })}

      <div className="d-none d-lg-block">
        <h4 className="ry-h4">{engagementAccountName}</h4>
        <h1 className="ry-h1">
          {engagementSavingsSummary.engagementDisplayNameLong}
          {engagementSavingsSummary.isActive ? (
            <span className="pill active">{getTextToDisplay('Active')}</span>
          ) : (
            <span className="pill inactive">
              {getTextToDisplay('Inactive')}
            </span>
          )}
        </h1>

        <hr />

        <h2 className="ry-h2">{title}</h2>
        <p>{getTextToDisplay(`${ROOT_TO_TEXT}.${entryType}.description`)}</p>

        <SavingsEntryDateAndCurrency
          entryISOCurrencyCode={
            engagementSummaryLatestEntry?.isoCurrencyCode ||
            engagementSavingsSummary.isoCurrencyCode
          }
          handleCreateEntry={(
            asOfDate: Date,
            isBaseline: boolean,
            isoCurrencyCode: string
          ) => {
            handleCreateEntry({
              entry: {
                ...engagementSavingsSummary,
                asOfDate,
                isBaseline,
                isoCurrencyCode
              },
              setEngagementSummaryLatestEntryCallback:
                setEngagementSummaryLatestEntry
            });
          }}
          handleUpdateEntry={({
            asOfDate,
            isBaseline,
            isoCurrencyCode
          }: THandleUpdateEntry) => {
            setEngagementSummaryLatestEntry({
              ...engagementSummaryLatestEntry,
              ...(asOfDate && { asOfDate }),
              ...(isBaseline && { isBaseline }),
              ...(isoCurrencyCode && { isoCurrencyCode })
            } as ISavingsSummaryEntry);
          }}
          isEditCurrencyEnabled={
            entryType === CREATE_FIRST_ENTRY ||
            (entryType === CREATE_ENTRY &&
              !engagementSavingsSummary.isoCurrencyCode)
          }
          isoCurrencyCodeOptions={isoCurrencyCodeOptions}
          isSubmitEnabled={!Boolean(engagementSummaryLatestEntry)}
          latestEntryAsOfDate={engagementSummaryLatestEntry?.asOfDate}
          minimumEntryDate={
            engagementSummaryLatestEntry?.minAsOfDate ||
            engagementSummaryLatestEntry?.lastAsOfDate ||
            engagementSavingsSummary.lastSavingsSummaryAsOfDate
          }
        />

        {engagementSummaryLatestEntry && (
          <SavingsSummaryEdit
            defaultEntry={engagementSummaryLatestEntry}
            // HACK: Using Type-Any as TS cannot identify "from" in location.state object
            from={(location.state as any)?.from}
            isoCurrencyCodeOptions={isoCurrencyCodeOptions}
          />
        )}
      </div>
    </div>
  );
};

export default SavingsSummary;
