import { AccessControlActivity } from './ActivityTypes/AccessControlActivity';
import { ContractActivity } from './ActivityTypes/ContractActivity';
import { DataRequestActivity } from './ActivityTypes/DataRequestActivity';
import { EngagementActivity } from './ActivityTypes/EngagementActivity';
import { FileActivity } from './ActivityTypes/FileActivity';
import { InvoiceActivity } from './ActivityTypes/InvoiceActivity';
import { LearningActivity } from './ActivityTypes/LearningActivity';
import { MilestoneActivity } from './ActivityTypes/MilestoneActivity';
import { SavingsSummaryActivity } from './ActivityTypes/SavingsSummaryActivity';
import { TaskActivity } from './ActivityTypes/TaskActivity';
import { TeamActivity } from './ActivityTypes/TeamActivity';
import React from 'react';
import { ErrorBoundary } from '@ryan/components';
import { EntityType, IActivity } from 'interfaces';
import { IActivityProps } from './IActivityProps';
import { UnrecognizedActivity } from './UnrecognizedActivity';

function mapEntityTypeToComponent(activity: IActivity) {
  switch (activity.entityTypeId) {
    case EntityType.AccessControl:
      return AccessControlActivity;
    case EntityType.Contract:
      return ContractActivity;
    case EntityType.DataRequest:
      return DataRequestActivity;
    case EntityType.File:
      return FileActivity;
    case EntityType.Invoice:
      return InvoiceActivity;
    case EntityType.Learning:
      return LearningActivity;
    case EntityType.Milestone:
      return MilestoneActivity;
    case EntityType.Project:
      return EngagementActivity;
    case EntityType.SavingsSummary:
      return SavingsSummaryActivity;
    case EntityType.Task:
      return TaskActivity;
    case EntityType.Team:
      return TeamActivity;
    default:
      return UnrecognizedActivity;
  }
}

const Activity: React.FC<IActivityProps> = props => {
  const Component = mapEntityTypeToComponent(props.activity);

  // render an unrecognized activity as a fallback if imperfect data
  return (
    <ErrorBoundary fallback={<UnrecognizedActivity {...props} />}>
      <Component {...props} />
    </ErrorBoundary>
  );
};

export default Activity;
