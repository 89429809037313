import {
  AccountAutocomplete,
  CountryAutocomplete,
  JurisdictionAutocomplete,
  PracticeAutocomplete,
  UserAutocomplete
} from 'components/AutocompleteAjax';
import { usePrevious } from 'hooks';
import {
  ICustomViewFilter,
  ICustomViewFilterOperator,
  ICustomViewFilterType,
  ICustomViewProjectStatus,
  ICustomViewProjectVisibility,
  UserType
} from 'interfaces';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, IOption } from '@ryan/components';

import {
  isAccount,
  isCountry,
  isJurisdiction,
  isPractice,
  isUser
} from './filterUtilities';

import './CustomViewFilter.scss';

// a list of filter types associated with the `IUserSummary` data type
const userFilterTypes: ICustomViewFilterType[] = [
  ICustomViewFilterType.AccountManagement,
  ICustomViewFilterType.ProjectManagement,
  ICustomViewFilterType.MarketingProfessional
];

export interface ICustomViewFilterProps {
  children?: never;

  /**
   * Disables all fields within the filter.
   */
  disabled?: boolean;

  /**
   * A list of custom view filter types to exclude from the list of available
   * options to select from.
   */
  exclude?: ICustomViewFilterType[];

  /**
   * Updates options/results to reflect Executive Access status if enabled.
   */
  executiveAccessEnabled?: boolean;

  /**
   * An existing custom view filter to pre-populate fields.
   */
  value?: ICustomViewFilter;

  /**
   * A handler to respond to filter changes.
   */
  onChange: (filter: ICustomViewFilter) => void;
}

const CustomViewFilter: React.FC<ICustomViewFilterProps> = ({
  disabled = false,
  exclude = [],
  executiveAccessEnabled = false,
  value,
  onChange
}) => {
  const { t } = useTranslation();

  // dropdown option configuration by operator
  const operatorOptionMap: {
    [operator in ICustomViewFilterOperator]: IOption;
  } = useMemo(
    () => ({
      [ICustomViewFilterOperator.Equals]: {
        label: t(`customView.operator.${ICustomViewFilterOperator.Equals}`),
        value: `${ICustomViewFilterOperator.Equals}`
      },
      [ICustomViewFilterOperator.EqualsIncludingSubsidiaries]: {
        label: t(
          `customView.operator.${ICustomViewFilterOperator.EqualsIncludingSubsidiaries}`
        ),
        value: `${ICustomViewFilterOperator.EqualsIncludingSubsidiaries}`
      },
      [ICustomViewFilterOperator.DoesNotEqual]: {
        label: t(
          `customView.operator.${ICustomViewFilterOperator.DoesNotEqual}`
        ),
        value: `${ICustomViewFilterOperator.DoesNotEqual}`
      }
    }),
    [t]
  );

  // operator dropdown options by filter type
  const operatorOptionsByType: {
    [filterType in ICustomViewFilterType | 'default']?: IOption[];
  } = useMemo(
    () => ({
      default: [
        operatorOptionMap[ICustomViewFilterOperator.Equals],
        operatorOptionMap[ICustomViewFilterOperator.DoesNotEqual]
      ],
      [ICustomViewFilterType.Account]: [
        operatorOptionMap[ICustomViewFilterOperator.Equals],
        operatorOptionMap[
          ICustomViewFilterOperator.EqualsIncludingSubsidiaries
        ],
        operatorOptionMap[ICustomViewFilterOperator.DoesNotEqual]
      ],
      [ICustomViewFilterType.Status]: [
        operatorOptionMap[ICustomViewFilterOperator.Equals]
      ],
      [ICustomViewFilterType.AccountManagement]: [
        operatorOptionMap[ICustomViewFilterOperator.Equals]
      ],
      [ICustomViewFilterType.ProjectManagement]: [
        operatorOptionMap[ICustomViewFilterOperator.Equals]
      ],
      [ICustomViewFilterType.MarketingProfessional]: [
        operatorOptionMap[ICustomViewFilterOperator.Equals]
      ],
      [ICustomViewFilterType.Visibility]: [
        operatorOptionMap[ICustomViewFilterOperator.Equals]
      ]
    }),
    [operatorOptionMap]
  );

  // project status dropdown options
  const statusOptions = useMemo(
    (): IOption[] => [
      {
        label: t('Active'),
        value: `${ICustomViewProjectStatus.Active}`
      },
      {
        label: t('Inactive'),
        value: `${ICustomViewProjectStatus.Inactive}`
      }
    ],
    [t]
  );

  // project visibility dropdown options
  const visibilityOptions = useMemo(
    (): IOption[] => [
      {
        label: t('Published'),
        value: `${ICustomViewProjectVisibility.Published}`
      },
      {
        label: t('Unpublished'),
        value: `${ICustomViewProjectVisibility.Unpublished}`
      }
    ],
    [t]
  );

  // iterate over enum keys to create Filter By options and remove any types
  // that have been excluded
  const filterByOptions = useMemo(
    () =>
      Object.keys(ICustomViewFilterType)
        .filter(
          filterType =>
            isNaN(Number(filterType)) &&
            exclude.indexOf((ICustomViewFilterType as any)[filterType]) === -1
        )
        .map(filterType => ({
          label: t(`customView.filter.type.${filterType}`),
          value: `${(ICustomViewFilterType as any)[filterType]}`
        })),
    [exclude, t]
  );

  const [filterType, setFilterType] = useState<ICustomViewFilterType>(
    value?.type || (Number(filterByOptions[0].value) as ICustomViewFilterType)
  );

  // operator dropdown options for the selected filter type
  const operatorOptions =
    operatorOptionsByType[filterType] || operatorOptionsByType.default!;

  const [filterOperator, setFilterOperator] =
    useState<ICustomViewFilterOperator>(
      value?.operator || Number(operatorOptions[0].value)
    );
  const [filterValue, setFilterValue] = useState<ICustomViewFilter['value']>(
    value?.value || typeof value?.value === 'number' ? value.value : ''
  );

  // store ref of value so can be referenced in a hook that calls callback to
  // change the value; prevents excessive hook calls
  const valueRef = useRef(value);

  // store the last recorded filter type value
  const lastFilterType = usePrevious(filterType);

  // reset filter values if new filter type is selected
  useEffect(() => {
    // only execute if filterType is defined and has changed
    // NOTE: if `lastFilterType=undefined`, then this is the first render and we
    // should not execute hook, otherwise a pre-filled value will be cleared
    if (
      typeof lastFilterType === 'undefined' ||
      filterType === lastFilterType
    ) {
      return;
    }

    switch (filterType) {
      // set value to default project status value if user selects project
      // status
      case ICustomViewFilterType.Status:
        setFilterValue(Number(statusOptions[0].value));
        break;
      case ICustomViewFilterType.Visibility:
        setFilterValue(Number(visibilityOptions[0].value));
        break;
      // maintain current selected user if switching to another user based
      // filter type
      case ICustomViewFilterType.AccountManagement:
      case ICustomViewFilterType.ProjectManagement:
      case ICustomViewFilterType.MarketingProfessional:
        if (userFilterTypes.indexOf(lastFilterType) === -1) {
          setFilterValue('');
        }

        break;
      default:
        setFilterValue('');
    }
  }, [filterType, lastFilterType, statusOptions, visibilityOptions]);

  // update value ref
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  // call change handler on filter update
  useEffect(() => {
    const updatedFilter: ICustomViewFilter = {
      ...valueRef.current,
      operator: filterOperator,
      type: filterType,
      value: filterValue
    };
    onChange(updatedFilter);
  }, [filterOperator, filterType, filterValue, onChange]);

  return (
    <div className="custom-view-filter">
      <span className="custom-view-filter__prefix">
        {t('customView.prefix')}
      </span>
      <Dropdown
        disabled={disabled || filterByOptions.length < 2}
        label={t('customView.filter.type.label')}
        name="filterBy"
        onChange={e => {
          setFilterType(Number(e.target.value) as ICustomViewFilterType);
        }}
        options={filterByOptions}
        value={`${filterType}`}
      />
      <Dropdown
        disabled={disabled || operatorOptions.length < 2}
        name="filterOperator"
        onChange={e => {
          setFilterOperator(
            Number(e.target.value) as ICustomViewFilterOperator
          );
        }}
        options={operatorOptions}
        value={`${filterOperator}`}
      />

      {filterType === ICustomViewFilterType.Account && (
        <AccountAutocomplete
          disabled={disabled}
          displayExecutiveAccounts={executiveAccessEnabled}
          name="filterAccount"
          onChange={option => {
            setFilterValue(option || '');
          }}
          value={isAccount(filterValue) ? filterValue : null}
        />
      )}

      {filterType === ICustomViewFilterType.Country && (
        <CountryAutocomplete
          disabled={disabled}
          name="filterCountry"
          onChange={option => {
            setFilterValue(option || '');
          }}
          value={isCountry(filterValue) ? filterValue : null}
        />
      )}

      {filterType === ICustomViewFilterType.Jurisdiction && (
        <JurisdictionAutocomplete
          disabled={disabled}
          name="filterJurisdiction"
          onChange={option => {
            setFilterValue(option || '');
          }}
          value={isJurisdiction(filterValue) ? filterValue : null}
        />
      )}

      {filterType === ICustomViewFilterType.Practice && (
        <PracticeAutocomplete
          disabled={disabled}
          excludeParentPracticeAreas // temporarily filtering due to data
          name="filterPractice"
          onChange={option => {
            setFilterValue(option || '');
          }}
          value={isPractice(filterValue) ? filterValue : null}
        />
      )}

      {filterType === ICustomViewFilterType.Status && (
        <Dropdown
          disabled={disabled}
          label={t('customView.filter.type.Status')}
          name="filterstatus"
          onChange={e => {
            setFilterValue(Number(e.target.value));
          }}
          options={statusOptions}
          value={typeof filterValue === 'number' ? `${filterValue}` : undefined}
        />
      )}

      {filterType === ICustomViewFilterType.Visibility && (
        <Dropdown
          disabled={disabled}
          label={t('customView.filter.type.Visibility')}
          name="filtervisibility"
          onChange={e => {
            setFilterValue(Number(e.target.value));
          }}
          options={visibilityOptions}
          value={typeof filterValue === 'number' ? `${filterValue}` : undefined}
        />
      )}

      {userFilterTypes.indexOf(filterType) > -1 && (
        <UserAutocomplete
          disabled={disabled}
          name="filterUser"
          onChange={option => {
            setFilterValue(option || '');
          }}
          userType={UserType.Ryan}
          value={isUser(filterValue) ? filterValue : null}
        />
      )}
    </div>
  );
};

export default CustomViewFilter;
