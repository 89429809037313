import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar } from '@ryan/components';

import getAvatarUrl from '../../../utils/getAvatarUrl';
import { IRenderNameAndProfile, IUserNameAndProfileProps } from './utils';

import './UserNameAndProfile.scss';

const UserNameAndProfile: React.FC<IUserNameAndProfileProps> = ({
  isLinkable,
  user: { memberGuid, ...renderNameAndProfileArguments }
}) => {
  const renderNameAndProfile: IRenderNameAndProfile = ({
    avatarDocumentGuid,
    fullName,
    optionalInfo,
    ...UserAvatarProps
  }) => (
    <>
      <Avatar
        {...((UserAvatarProps.isGhosted || !UserAvatarProps.hasDxpAccess) && {
          variant: UserAvatarProps.isGhosted ? 'ghost' : 'inverse'
        })}
        firstName={UserAvatarProps.firstName}
        lastName={UserAvatarProps.lastName}
        profileImageSrc={getAvatarUrl({
          avatarUrl: UserAvatarProps.avatarUrl,
          userAvatarDocumentGuid: avatarDocumentGuid
        })}
      />

      <div className="user-name-and-profile__info">
        <span className="user-name-and-profile__info__name">{fullName}</span>
        {optionalInfo}
      </div>
    </>
  );

  const renderedNameAndProfile = renderNameAndProfile(
    renderNameAndProfileArguments
  );

  return isLinkable && memberGuid ? (
    <Link
      className="user-name-and-profile"
      to={`/app/personal-information/${memberGuid}`}
    >
      {renderedNameAndProfile}
    </Link>
  ) : (
    <div className="user-name-and-profile">{renderedNameAndProfile}</div>
  );
};

export default UserNameAndProfile;
