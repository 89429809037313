import Modal from 'components/Modal';
import Table from 'components/Table';
import { PermissionService, useAppReadOnly } from 'contexts/UserContext';
import {
  DirectoryItemLinkType,
  IDirectoryFile,
  IDirectoryItemLink,
  IUserIdentity,
  Permission
} from 'interfaces';
import ApiService from 'services/ApiService';
import { formatDate } from 'utils/formatDate';
import getDirectoryItemLinkIcon from 'utils/getDirectoryItemLinkIcon';
import getDirectoryItemLinkUrl from 'utils/getDirectoryItemLinkUrl';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  EButtonSizes,
  EButtonVariant,
  Icon
} from '@ryan/components';

import './FileLinksModal.scss';

const FileLinksModal: FunctionComponent<{
  engagementGuid: string;
  file: IDirectoryFile;
  onClose: (didEdit: boolean) => void;
  permissionService: PermissionService;
  user: IUserIdentity;
}> = ({ user, permissionService: ps, file, onClose }) => {
  const isAppReadOnly = useAppReadOnly();
  const { t } = useTranslation();
  const [state, setState] = useState<{
    loading: boolean;
    data: IDirectoryItemLink[];
    page: number;
    pageSize: number;
    totalCount: number;
    linksToRemove: string[];
  }>({
    loading: false,
    data: [],
    page: 1,
    pageSize: 10,
    totalCount: 0,
    linksToRemove: []
  });

  function renderLinkIcon(link: IDirectoryItemLink) {
    // if the current link is marked to be removed by user, have undo icon
    if (state.linksToRemove.includes(link.engagementDocumentLinkGuid)) {
      return (
        <Button
          icon="undo"
          onClick={() => handleUndoLinkRemove(link)}
          size={EButtonSizes.SMALL}
          variant={EButtonVariant.TEXT}
        />
      );
    }

    // Whether or not it is the link's origin -- we can ONLY unlink the file from the modal if one of the following:
    let canRemoveLink = false;

    // Now go through the types and check permissions
    // Types are not listed: File & Data Request Upload -- we do NOT delete those from modal
    switch (link.linkType) {
      case DirectoryItemLinkType.DataRequestAttachment:
        canRemoveLink = ps.hasPermission(Permission.DataRequestsEdit);
        break;
      case DirectoryItemLinkType.LearningAttachment:
        canRemoveLink = ps.hasPermission(Permission.LearningsEdit);
        break;
      case DirectoryItemLinkType.SavingsAttachment:
        canRemoveLink = ps.hasPermission(Permission.SavingsSummaryEdit);
        break;
      case DirectoryItemLinkType.TaskAttachment:
        canRemoveLink = ps.canRemoveTaskAttachment(file);
        break;
    }

    return (
      !isAppReadOnly &&
      canRemoveLink &&
      !link.unlinkedDate &&
      !link.deleteDate && (
        <Button
          icon="close"
          onClick={() => handleLinksToRemove(link)}
          size={EButtonSizes.SMALL}
          variant={EButtonVariant.TEXT}
        />
      )
    );
  }

  function renderLinkInformation(
    link: IDirectoryItemLink,
    informationToReturn: string
  ) {
    return !link.linkGuid && !link.name ? '–' : informationToReturn;
  }

  function handleLinksToRemove(link: IDirectoryItemLink) {
    setState(state => ({
      ...state,
      linksToRemove: [...state.linksToRemove, link.engagementDocumentLinkGuid]
    }));
  }

  function handleUndoLinkRemove(link: IDirectoryItemLink) {
    const updatedLinksToREmoveList = state.linksToRemove.filter(
      l => l !== link.engagementDocumentLinkGuid
    );

    setState(state => ({
      ...state,
      linksToRemove: updatedLinksToREmoveList
    }));
  }

  function removeLinks() {
    ApiService.removeFileLinks(file.documentGuid, state.linksToRemove)
      .then(() => {
        onClose(true);
      })
      .catch(() => {
        pushServerErrorToast();
      });
  }

  function handlePageChange(page: number, pageSize: number) {
    setState(state => ({
      ...state,
      page,
      pageSize
    }));
  }

  function renderLinkName(link: IDirectoryItemLink) {
    if (link.name === '') {
      link.name = t('file.linkModal.fileUpload');
    }

    const accessToLink = link.linkGuid && link.name;

    const linksNotLinkable =
      !accessToLink ||
      link.unlinkedDate ||
      link.deleteDate ||
      link.linkType === DirectoryItemLinkType.ProjectLink;

    return linksNotLinkable ? (
      <div className={link.isOrigin ? `ry-link` : `ry-link not-origin`}>
        {link.isOrigin &&
          link.linkType !== DirectoryItemLinkType.ProjectLink && (
            <div className="upload-origin-title">{t('Upload Origin')}</div>
          )}
        <Icon name={getDirectoryItemLinkIcon(link)} />
        {link.linkType === DirectoryItemLinkType.ProjectLink
          ? t('Linked to Project')
          : !accessToLink
          ? t('file.linkModal.fileUpload')
          : link.name}{' '}
        <span>
          {accessToLink && link.deleteDate
            ? `(${t('Deleted')})`
            : accessToLink && link.unlinkedDate
            ? `(${t('Unlinked')})`
            : ''}
        </span>
      </div>
    ) : (
      <>
        {link.isOrigin &&
          link.linkType !== DirectoryItemLinkType.ProjectLink && (
            <div className="upload-origin-title">{t('Upload Origin')}</div>
          )}
        <Link
          className={link.isOrigin ? `ry-link` : `ry-link not-origin`}
          onClick={() => {
            onClose(false);
          }}
          to={getDirectoryItemLinkUrl(link)}
        >
          <Icon name={getDirectoryItemLinkIcon(link)} />
          {link.name}
        </Link>
      </>
    );
  }

  // On mount, fetch file links
  useEffect(() => {
    if (file) {
      setState(state => ({
        ...state,
        loading: true
      }));
      ApiService.getFileLinks({
        engagementGuid: file.engagementGuid,
        documentGuid: file.documentGuid,
        pageNumber: state.page,
        itemsPerPage: state.pageSize
      })
        .then(({ data }) => {
          const isArchived = !!file.archiveDate;
          const filteredForCurrentEngagement = data.results.filter(
            link => link.engagementGuid === file.engagementGuid
          );
          setState(state => ({
            ...state,
            loading: false,
            data: isArchived ? filteredForCurrentEngagement : data.results,
            totalCount: isArchived
              ? filteredForCurrentEngagement.length
              : data.totalResults,
            linksToRemove: [],
            deleteLink: false
          }));
        })
        .catch(() => {
          setState(state => ({
            ...state,
            loading: false
          }));
        });
    }
  }, [file, state.page, state.pageSize]);

  if (file) {
    const isArchived = !!file.archiveDate;
    return (
      <Modal
        className="file-links-modal"
        onClose={() => onClose(false)}
        open
        title={isArchived ? t('Archived Links') : t('Links')}
      >
        {isArchived && t('archivedFileLinks.content')}
        <div className="file-links-header row">
          <div className="col-6">
            <h4 className="ry-h4">{t('File')}</h4>
            <h3 className="ry-h3">{file.displayName}</h3>
          </div>
          <div className="col-6">
            {t('Total')}:{' '}
            {
              state.data.filter(
                item => !Boolean(item.unlinkedDate) && !Boolean(item.deleteDate)
              ).length
            }
          </div>
        </div>

        <Table<IDirectoryItemLink>
          columns={[
            {
              id: 'name',
              label: t('Name'),
              render: renderLinkName
            },
            {
              id: 'type',
              label: t('Type'),
              render: link =>
                link.linkType === DirectoryItemLinkType.ProjectLink
                  ? '–'
                  : renderLinkInformation(link, t(`file.link.${link.linkType}`))
            },
            {
              id: 'creationDate',
              label: t('Creation Date'),
              render: link =>
                renderLinkInformation(link, formatDate(link.createDate))
            },
            {
              id: 'createdByName',
              label: t('Created By'),
              render: link =>
                renderLinkInformation(
                  link,
                  user.profile.userGuid === link.createdBy
                    ? t('You')
                    : link.createdByName
                )
            },
            {
              id: 'actions',
              label: '',
              align: 'right',
              render: isArchived ? () => <></> : renderLinkIcon
            }
          ]}
          data={state.data}
          groupBy="engagementDisplayNameShort"
          groupByClassName={() => 'file-links-modal__engagement'}
          loading={state.loading}
          onPageChange={handlePageChange}
          page={state.page}
          pageSize={state.pageSize}
          renderGroupHeader={name => name.toUpperCase()}
          rowClassName={(row: IDirectoryItemLink) =>
            classnames('link-row', {
              'link-row-removed': row.unlinkedDate || row.deleteDate,
              'link-row-deletelink': state.linksToRemove.includes(
                row.engagementDocumentLinkGuid
              )
            })
          }
          rowId="engagementDocumentLinkGuid"
          totalCount={state.totalCount}
        />

        <ButtonGroup>
          {!isArchived && (
            <Button
              disabled={state.linksToRemove.length === 0}
              onClick={() => removeLinks()}
              size={EButtonSizes.LARGE}
              text={t('Save')}
              variant={EButtonVariant.PRIMARY}
            />
          )}
          <Button
            onClick={() => onClose(false)}
            size={EButtonSizes.LARGE}
            text={t('Close')}
            variant={isArchived ? 'primary' : 'secondary'}
          />
        </ButtonGroup>
      </Modal>
    );
  }

  return null;
};

export default FileLinksModal;
