import { AxiosError, AxiosResponse } from 'axios';

interface CommandResponse<T> {
  isSuccessful: boolean;
  errors: string[];
  response: T;
}

export async function unwrapCommandResponse<T>(
  promise: Promise<AxiosResponse<CommandResponse<T>>>
): Promise<AxiosResponse<T>> {
  return promise
    .then(response => {
      const { data } = response;

      if (
        typeof data === 'object' &&
        'isSuccessful' in data &&
        'errors' in data &&
        'response' in data
      ) {
        return {
          ...response,
          data: data.response
        };
      }

      return response as unknown as Promise<AxiosResponse<T>>;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        error.response.data = error.response.data.errors;
      }

      throw error;
    });
}
