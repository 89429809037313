import { MentionsValue } from '@ryan/components';

import { IComment } from '../interfaces';

export default function getMentionValue(comment: IComment) {
  return new MentionsValue({
    text: comment.commentText,
    users: comment.commentTaggedUsers?.map(user => ({
      id: user.userGuid,
      display: user.fullName
    }))
  });
}
