import {
  IGetArchiveRestoreSuccessToastDetails,
  IGetFolderGuidsFromFoldersWithActiveFiles,
  IGetStatusOfSelectedFilesAndFolders
} from '../IFileDirectory';

export const getArchiveRestoreSuccessToastDetails: IGetArchiveRestoreSuccessToastDetails =
  ({ actionType, archivedFoldersCount, basePathToText, fileBatch }) => {
    const { files, filesCount: fileCount, folders } = fileBatch;

    const contentBasePath = `${basePathToText}.content`;
    const folderCount = archivedFoldersCount
      ? archivedFoldersCount
      : folders.reduce((folderCountSum, folder) => {
          if (folder.lockedFilesCount && folder.lockedFilesCount > 0) {
            return folderCountSum;
          }

          let subFolderCount = folder.totalSubFolderCount;

          if (actionType === 'Restored') {
            subFolderCount = folder.archivedSubFolderCount;
          }

          return folderCountSum + subFolderCount;
        }, folders.length);

    const isMultipleFiles = fileCount > 1;
    const titleBasePath = `${basePathToText}.title`;

    let contentDetails;
    let contentPath;
    let titlePath;

    if (folderCount === 1 && fileCount === 0) {
      contentDetails = { folderName: folders[0].folderName };
      contentPath = `${contentBasePath}.folder`;
      titlePath = `${titleBasePath}.folder${actionType}`;
    } else {
      let pathPrefix = '';
      let pathInfix = '';

      if (fileCount > 0) {
        pathPrefix = isMultipleFiles ? 'files' : 'file';

        if (folderCount > 0) {
          pathInfix = `And${folderCount > 1 ? 'Folders' : 'Folder'}`;
        }
      } else if (folderCount > 0) {
        pathInfix = 'folders';
      }

      contentDetails = {
        ...(folderCount === 0 && { fileName: files[0].documentName }),
        ...(folderCount > 0 && { folderCount }),
        ...(isMultipleFiles && { fileCount })
      };
      contentPath = `${contentBasePath}.${pathPrefix}${pathInfix}`;
      titlePath = `${titleBasePath}.${
        pathPrefix !== '' && pathInfix !== ''
          ? pathPrefix
          : pathPrefix || pathInfix
      }${actionType}`;
    }

    contentPath += `Successfully${actionType}`;

    return { contentDetails, contentPath, titlePath };
  };

export const getFolderGuidsFromFoldersWithActiveFiles: IGetFolderGuidsFromFoldersWithActiveFiles =
  folders =>
    folders.reduce(
      (folderGuids, { archivedFileCount, folderGuid, totalFileCount }) => {
        if (totalFileCount > 0 && totalFileCount > archivedFileCount!) {
          folderGuids.push(folderGuid);
        }
        return folderGuids;
      },
      [] as string[]
    );

export const getStatusOfSelectedFilesAndFolders: IGetStatusOfSelectedFilesAndFolders =
  selection => {
    const { files, folders } = selection;

    let hasTopLevelActiveFiles = false;
    let hasTopLevelArchivedFiles = false;

    files.every(file => {
      file.archiveDate
        ? (hasTopLevelArchivedFiles = true)
        : (hasTopLevelActiveFiles = true);

      return !(hasTopLevelActiveFiles && hasTopLevelArchivedFiles);
    });

    let hasNestedLevelArchivedFiles = false;
    let hasNestedLevelActiveFiles = false;
    let hasTopLevelActiveFolders = false;
    let hasTopLevelArchivedFolders = false;

    folders.every(({ archiveDate, archivedFileCount, totalFileCount }) => {
      const modifiedArchivedFilecount = archivedFileCount || 0;

      archiveDate
        ? (hasTopLevelArchivedFolders = true)
        : (hasTopLevelActiveFolders = true);

      if (!hasNestedLevelArchivedFiles && modifiedArchivedFilecount > 0) {
        hasNestedLevelArchivedFiles = true;
      }

      if (
        !hasNestedLevelActiveFiles &&
        totalFileCount > modifiedArchivedFilecount
      ) {
        hasNestedLevelActiveFiles = true;
      }

      return !(
        hasNestedLevelActiveFiles &&
        hasNestedLevelArchivedFiles &&
        hasTopLevelActiveFolders &&
        hasTopLevelArchivedFolders
      );
    });

    return {
      hasNestedLevelArchivedFiles,
      hasNestedLevelActiveFiles,
      hasTopLevelActiveFiles,
      hasTopLevelActiveFolders,
      hasTopLevelArchivedFiles,
      hasTopLevelArchivedFolders
    };
  };
