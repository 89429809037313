import queryString from 'query-string';
import AmplitudeApiService from 'services/AmplitudeApiService';
import sourceOriginCodeNames from 'utils/sourceOriginCodes/sourceOriginCodeNames';
import sourceSubcategoryCodeNames from 'utils/sourceOriginCodes/sourceSubcategoryNames';

import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const SourceOriginTracker: React.FC<Record<string, never>> = () => {
  const history = useHistory();

  const logEntryOriginEvent = useCallback(() => {
    const urlParams = queryString.parse(window.location.search);
    const sourceOriginCode = urlParams.soc as string;
    const sourceSubcategoryCode = urlParams.ssc as string;
    if (sourceOriginCode) {
      delete urlParams.soc;
      if (sourceSubcategoryCode) {
        delete urlParams.ssc;
      }
      const hashString = window.location.hash;
      history.replace({
        hash: hashString.substring(hashString.indexOf('#')),
        search: queryString.stringify(urlParams)
      });
    }

    const sourceOriginCodeName =
      sourceOriginCodeNames[sourceOriginCode] || window.location.host;
    const eventPayload: { [index: string]: string } = {
      'entry-origin': sourceOriginCodeName
    };
    if (sourceOriginCodeNames[sourceOriginCode] && sourceSubcategoryCode) {
      const sourceSubcategoryCodeName =
        sourceSubcategoryCodeNames[sourceSubcategoryCode] || 'Unrecognized SSC';
      eventPayload['entry-origin-sub'] = sourceSubcategoryCodeName;
    }

    AmplitudeApiService.logEvent('entry-origin', eventPayload);
  }, [history]);

  useEffect(() => {
    logEntryOriginEvent();
  }, [logEntryOriginEvent]);

  return <></>;
};

export default SourceOriginTracker;
