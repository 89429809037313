export interface ITermsOfUse {
  version: number;
  content: string;
  acceptedVersion: number | null;
}

export enum TermsOfUseType {
  TermsOfUse = 1,
  PrivacyPolicy = 2
}
