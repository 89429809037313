import { DSSManager } from 'utils/DSS';
import { DSSUploaderStatus } from 'utils/DSS/DSSUploader';

import classnames from 'classnames';
import ENV from 'env';
import React, {
  ComponentProps,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { FileStatus, FileUpload, IFileError, Message } from '@ryan/components';

import './DSSFileUpload.scss';

/**
 * Maps the DSSManagerStatus to FileUploadStatus.
 */
function mapStatus(status: DSSUploaderStatus): FileStatus {
  switch (status) {
    case DSSUploaderStatus.Uploading:
      return FileStatus.Uploading;
    case DSSUploaderStatus.Uploaded:
      return FileStatus.Success;
    case DSSUploaderStatus.Error:
    case DSSUploaderStatus.Canceled:
      return FileStatus.Error;
    default:
      throw new TypeError(`DSSUploaderStatus not recognized: ${status}`);
  }
}

interface IDSSFileUploadProps
  extends Omit<
    ComponentProps<typeof FileUpload>,
    'files' | 'onFilesAdded' | 'onFileTrashed'
  > {
  border?: boolean;
  dssManager: DSSManager;
  errorContainerRef?: React.RefObject<HTMLElement>;
  errorText?: { [errorType: string]: string };
  helperText?: string;
  shrink?: boolean; // shrinks padding around dropzone and header text size
  title?: string;
}

export const DSSFileUpload: FunctionComponent<IDSSFileUploadProps> = ({
  blacklist = ENV.BLACKLIST_FILES,
  border = false,
  dssManager,
  errorContainerRef,
  errorText,
  helperText,
  multiple = true,
  shrink = false,
  title,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<IFileError[]>([]);
  const [errorMessages, setErrorMessages] = useState<ReactNode[]>([]);

  useEffect(() => {
    const updatedMessages = errors.map(error => {
      const errorTextMap: { [errorType: string]: string } = {
        default: t('fileUploadError.default', { fileName: error.file.name }),
        size: t('fileUploadError.size', { fileName: error.file.name }),
        type: t('fileUploadError.type', { fileName: error.file.name }),
        ...errorText
      };

      return (
        <Message
          dismissible
          key={`${error.file.name}-${error.type}`}
          onClose={() => {
            const fileNameToRemove = error.file.name;
            const errorTypeToRemove = error.type;
            setErrors(prevErrors =>
              prevErrors.filter(
                prevError =>
                  prevError.type !== errorTypeToRemove ||
                  prevError.file.name !== fileNameToRemove
              )
            );
          }}
          type="warning"
        >
          {errorTextMap[error.type] || errorTextMap.default}
        </Message>
      );
    });
    setErrorMessages(updatedMessages);
  }, [errors, errorText, t]);

  return (
    <div
      className={classnames('dss-file-upload', {
        'dss-file-upload--border': border,
        'dss-file-upload--shrink': shrink
      })}
    >
      {title && <h3 className="ry-h3">{title}</h3>}
      {helperText && <p>{helperText}</p>}
      {errorContainerRef && errorContainerRef.current
        ? createPortal(errorMessages, errorContainerRef.current)
        : errorMessages}
      <FileUpload
        blacklist={blacklist}
        dragAndDropFilesText={
          multiple
            ? t('fileUpload.dragAndDropFiles')
            : t('fileUpload.dragAndDropFile')
        }
        dragAndDropOrText={t('fileUpload.or')}
        dragAndDropSelectFilesText={
          multiple ? t('fileUpload.selectFiles') : t('fileUpload.selectFile')
        }
        files={dssManager.getUploadsSnapshot().map(upload => ({
          ...upload,
          status: mapStatus(upload.status)
        }))}
        multiple={multiple}
        onErrors={setErrors}
        onFilesAdded={files => dssManager.uploadFiles(files)}
        onFileTrashed={file => dssManager.removeFile(file)}
        {...otherProps}
      />
    </div>
  );
};

export default DSSFileUpload;
