import Datepicker from 'components/Datepicker';
import { RadioButton, RadioButtonGroup } from 'components/RadioButton';
import { IReportBuilderSection, ReportSections } from 'interfaces';
import { formikDatepickerProps, formikFieldProps } from 'utils/forms';

import { FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { ReportBuilderPanel } from './ReportBuilderPanel';
import { getPanelStatus, panelHasNoErrors } from './reportingHelpers';

enum TimeframeType {
  AsOf = '1',
  Range = '2'
}

interface ITimeframeSectionProps
  extends IReportBuilderSection,
    WithTranslation {}

export class TimeframeSection extends Component<ITimeframeSectionProps> {
  fieldsToValidate = ['timeframeStartDate', 'timeframeEndDate'];

  private today = new Date();

  validateAndSendNext = (formik: FormikProps<any>) => {
    if (this.props.onNext && panelHasNoErrors(this.fieldsToValidate, formik)) {
      this.props.onNext(0, formik);
    }
  };

  render() {
    const { t, selectedPanel, onToggle, formik } = this.props;

    return (
      <ReportBuilderPanel
        formik={formik}
        id={ReportSections.Timeframe}
        onNext={() => this.validateAndSendNext(formik)}
        onToggle={onToggle}
        selectedSection={selectedPanel}
        status={getPanelStatus(this.fieldsToValidate, formik)}
        title={t('reportBuilder.timeframe.title')}
      >
        <RadioButtonGroup {...formikFieldProps('timeframeType', formik)}>
          <RadioButton
            label={t('reportBuilder.timeframe.asOf')}
            value={TimeframeType.AsOf}
          />
          <RadioButton
            label={t('reportBuilder.timeframe.dateRange')}
            value={TimeframeType.Range}
          />
        </RadioButtonGroup>
        <div className="report-builder__date-range">
          {formik.values.timeframeType === TimeframeType.Range && (
            <Datepicker
              {...formikDatepickerProps('timeframeStartDate', formik)}
              label={t('reportBuilder.timeframe.startDate.label')}
              maxDate={formik.values.timeframeEndDate}
            />
          )}
          <Datepicker
            {...formikDatepickerProps('timeframeEndDate', formik)}
            label={
              formik.values.timeframeType === TimeframeType.Range
                ? t('reportBuilder.timeframe.endDate.label')
                : t('reportBuilder.timeframe.asOf')
            }
            maxDate={this.today}
            minDate={
              formik.values.timeframeType === TimeframeType.Range
                ? formik.values.timeframeStartDate
                : null
            }
            {...(formik.values.timeframeType === TimeframeType.Range
              ? {
                  // adjust initial placement of datepicker dropdown when date
                  // range selection is enabled
                  popperPlacement: 'bottom-end'
                }
              : {})}
          />
        </div>
      </ReportBuilderPanel>
    );
  }
}

export default withTranslation()(TimeframeSection);
