import { EventEmitter } from 'events';
import { createContext } from 'react';
import { ITask } from 'interfaces';

export interface ITaskDrawerContext {
  taskDrawerEvents: EventEmitter;
  onTaskDrawerOpen: (task: ITask) => void;
}

export const TaskDrawerContext = createContext<ITaskDrawerContext>({
  taskDrawerEvents: new EventEmitter(),
  onTaskDrawerOpen: () => {}
});
