import React from 'react';

import SideNavigationComponent from '../../components/AppHeader/SideNavigation/SideNavigation';
import { useUser } from '../../hooks';
import { Feature } from '../../interfaces';
import AppRouter from '../../layouts/AppLayout/AppRouter';

const AppMain: React.FC = () => {
  const { isFeatureToggled } = useUser();

  const MainBodyComponent = (
    <main className="container-fluid app-layout__content">
      <AppRouter />
    </main>
  );
  const MainBodyWithLeftNavigationComponent = (
    <div className="container" style={{ display: 'inline-flex' }}>
      <div className="col-1.5">
        <SideNavigationComponent />
      </div>
      <div className="col-10">{MainBodyComponent}</div>
    </div>
  );
  return isFeatureToggled(Feature.LeftNav)
    ? MainBodyWithLeftNavigationComponent
    : MainBodyComponent;
};
export default AppMain;
