import {
  INotification,
  INotificationDismissConfig,
  IPagedDataResponse
} from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import sortNotifications from 'utils/sortNotifications';

import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { pushToast } from '@ryan/components';

interface NotificationsState
  extends Omit<IPagedDataResponse<INotification>, 'results'> {
  /**
   * The `itemsPerPage` value used to initialize state.
   */
  itemsPerPageInitial: IPagedDataResponse<INotification>['itemsPerPage'];
  open: boolean;
  loading: boolean;
  notifications: INotification[] | null;
}

interface NotificationsReducerAction {
  itemsPerPage?: number;
  notification?: INotification;
  notifications?: INotification[];
  totalResults?: number;
  type: string;
}

export type NotificationHookResponse = [
  NotificationsState,
  {
    closeDrawer: () => void;
    dismissNotifications: (
      notifications?: INotification[],
      dismissConfig?: INotificationDismissConfig
    ) => void;
    fetchNext: () => void;
    fetchNotifications: () => void;
    openDrawer: (totalResults?: number) => void;
    trackDismissNotifications: (totalResults: number) => void;
  }
];

function notificationsReducer(
  state: NotificationsState,
  action: NotificationsReducerAction
): NotificationsState {
  const prevNotifications = state.notifications || [];

  switch (action.type) {
    case 'OPEN':
      return { ...state, open: true };
    case 'CLOSE':
      return { ...state, open: false };
    case 'FETCH_NEXT':
      // Fetch next set of notifications.
      // NOTE: While the GET endpoint for notifications uses the standard
      // paged data response interface for paged results, notifications are
      // expected to be displayed in a single, infinitely scrolling list.
      // Since multiple "pages" of notifications are displayed together, it
      // makes dismissals tricky as the number of items dismissed at a time can
      // shift these items' position within the pages, potentially skipping
      // some if we were to request batches by page number. We will instead
      // always request the first page but increment/decrement the
      // `itemsPerPage` per next fetch and dismiss requests.
      return !state.loading && state.itemsPerPage < state.totalResults
        ? {
            ...state,

            // fetch currently displayed number of notifications + requested
            // notifications per fetch count
            itemsPerPage:
              (state.notifications?.length || 0) + state.itemsPerPageInitial,

            // increment pageNumber to trigger fetch
            pageNumber: state.pageNumber + 1
          }
        : state;
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      // filter out duplicate notifications
      const filteredNotifications: INotification[] = Array.isArray(
        action.notifications
      )
        ? action.notifications.filter(
            (notificationToTest: INotification) =>
              !prevNotifications.some(
                prevNotification =>
                  prevNotification.notificationGuid ===
                  notificationToTest.notificationGuid
              )
          )
        : [];

      return {
        ...state,
        itemsPerPage: action.itemsPerPage || state.itemsPerPage,
        notifications: sortNotifications([
          ...prevNotifications,
          ...filteredNotifications
        ]),
        totalResults: action.totalResults || state.totalResults
      };
    case 'FETCH_COMPLETE':
      return { ...state, loading: false };
    case 'PUSH':
      const pushedNotification = action.notification;

      // delay in SignalR event could cause a notification that already exists
      // in the list to be pushed - make sure this is not a duplicate before
      // appending
      const isNotificationInList = !!(
        pushedNotification &&
        state.notifications?.some(
          ({ notificationGuid }) =>
            notificationGuid === pushedNotification.notificationGuid
        )
      );

      return {
        ...state,
        ...(pushedNotification && !isNotificationInList
          ? {
              notifications: [...prevNotifications, pushedNotification],
              totalResults: state.totalResults + 1
            }
          : {})
      };
    case 'DISMISS':
      // NOTE: total results count will be updated via request response
      return {
        ...state,
        notifications: Array.isArray(action.notifications)
          ? prevNotifications.filter(
              n =>
                !action.notifications!.some(
                  (dn: INotification) =>
                    dn.notificationGuid === n.notificationGuid
                )
            )
          : prevNotifications
      };
    case 'DISMISS_SUCCESS':
      const { totalResults: newTotalResults } = action;
      let totalResults = state.totalResults;

      if (typeof newTotalResults === 'number') {
        totalResults = newTotalResults >= 0 ? newTotalResults : 0;
      }

      return {
        ...state,
        totalResults
      };
    case 'DISMISS_FAILURE':
      return {
        ...state,
        notifications: Array.isArray(action.notifications)
          ? sortNotifications([...prevNotifications, ...action.notifications])
          : prevNotifications
      };
  }

  return state;
}

/**
 * React hook to assist in displaying and interacting with user notifications as
 * an infinitely scrolling list.
 *
 * @param [notificationsPerFetch = 50] The number of notifications to fetch per
 *  call to `fetchNext`.
 */
export default function useNotifications(
  notificationsPerFetch = 50
): NotificationHookResponse {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(notificationsReducer, {
    itemsPerPage: notificationsPerFetch,
    itemsPerPageInitial: notificationsPerFetch,
    loading: false,
    notifications: null,
    open: false,
    pageNumber: 1,
    totalResults: 0
  });

  /**
   * Fetch a subset of notifications for the user.
   */
  const fetchNotifications = useCallback(async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });

      // always request page number 1, adjust items per page (see note above in
      // FETCH_NEXT)
      const { data } = await ApiService.getNotifications({
        itemsPerPage: state.itemsPerPage,
        pageNumber: 1
      });
      dispatch({
        type: 'FETCH_SUCCESS',
        itemsPerPage: data.itemsPerPage,
        notifications: data.results,
        totalResults: data.totalResults
      });
    } catch {
      pushServerErrorToast();
    } finally {
      dispatch({ type: 'FETCH_COMPLETE' });
    }
  }, [state.itemsPerPage]);

  // Fetch on mount and update of notification page
  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  // subscribe to push notifications
  useEffect(() => {
    // TODO / CONTEXT - commenting out the push notification setup
    // Browser notifications are currently leading to numerous errors and creating noise.
    // This functionality may be re-enabled in the future at some point.

    // const hubPromise = getSignalRHub();
    // const onPush = (event: INotificationEvent) => {
    //   dispatch({ type: 'PUSH', notification: event.notification });
    // };

    // hubPromise
    //   .then(hub => hub.on('UserNotification', onPush))
    //   .then(() => {
    //     logger.debug('subscribed to SignalR push notifications');
    //   })
    //   .catch(() => {});

    // unsubscribe from notifications on unmount
    return () => {
      // hubPromise
      //   .then(hub => hub.off('UserNotification', onPush))
      //   .then(() => {
      //     logger.debug('unsubscribed from SignalR push notifications');
      //   })
      //   .catch(() => {});
    };
  }, []);

  // Open the notification drawer. Maybe fetch.
  const openDrawer = useCallback(
    (totalResults?: number) => {
      /**
       * temporarily fetching notifications when drawer opens to handle issue
       * where SignalR push notifications are not received by all active users
       * @see https://dev.azure.com/RyanITApps/ITApplications/_workitems/edit/28967
       */
      AmplitudeApiService.logEvent('view-bell-notification-drawer', {
        'notification-count': totalResults
      });

      fetchNotifications();
      dispatch({ type: 'OPEN' });
    },
    [fetchNotifications]
  );

  // Close the notification drawer.
  const closeDrawer = useCallback(() => {
    dispatch({ type: 'CLOSE' });
  }, []);

  /**
   * Dismiss one or more notifications.
   *
   * @param notifications A list of notifications to be dismissed. Used to
   *  optimistically dismiss notifications while making the request.
   * @param dismissConfig Configuration to pass to dismissal endpoint.
   */
  const dismissNotifications = useCallback(
    async (
      notifications?: INotification[],
      dismissConfig?: INotificationDismissConfig
    ) => {
      // Optimistically dismiss.
      // We remove the notifications from the list before the request returns.
      dispatch({ type: 'DISMISS', notifications });

      // Send the request - revert on failture...
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data: totalResults } = await ApiService.dismissNotifications(
          dismissConfig
        );
        dispatch({
          type: 'DISMISS_SUCCESS',
          totalResults
        });
      } catch {
        pushToast({
          type: 'error',
          title: t('activity.notifications.dismissError')
        });
        dispatch({ type: 'DISMISS_FAILURE', notifications });
      } finally {
        dispatch({ type: 'FETCH_COMPLETE' });
      }
    },
    [t]
  );

  const trackDismissNotifications = useCallback((totalResults: number) => {
    AmplitudeApiService.logEvent('clear-all-notifications', {
      'notification-count': totalResults
    });
  }, []);

  /**
   * Trigger fetch of next set of notifications.
   */
  const fetchNext = useCallback(() => {
    dispatch({ type: 'FETCH_NEXT' });
  }, [dispatch]);

  const hookResponse: NotificationHookResponse = useMemo(
    () => [
      state,
      {
        closeDrawer,
        dismissNotifications,
        fetchNext,
        fetchNotifications,
        openDrawer,
        trackDismissNotifications
      }
    ],
    [
      state,
      closeDrawer,
      dismissNotifications,
      fetchNext,
      fetchNotifications,
      openDrawer,
      trackDismissNotifications
    ]
  );

  return hookResponse;
}
