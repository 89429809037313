export enum ISortDirection {
  Ascending,
  Descending
}

export interface ISortDescriptor {
  sortDirection?: ISortDirection;
  sortPropertyName?: string;
}

export interface IPagedDataParams {
  pageNumber?: number;
  itemsPerPage?: number;
  sort?: string | ISortDescriptor[];
  engagementSort?: string | ISortDescriptor[];
}
