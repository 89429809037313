import { useContext } from 'react';
import { withContext } from 'utils/withContext';
import {
  DataRequestDrawerContext,
  IDataRequestDrawerContext
} from './DataRequestDrawerContext';

export type WithDataRequestDrawer = IDataRequestDrawerContext;

export const withDataRequestDrawer = withContext(
  DataRequestDrawerContext,
  'withDataRequestDrawer'
);

export const useDataRequestDrawer = () => useContext(DataRequestDrawerContext);
