import { IFormatToUserInfoCard } from '../IUserInfoCard';

export const formatToUserInfoCard: IFormatToUserInfoCard = user => {
  const userInfoToDisplay = {
    cityState: user.displayLocation,
    companyName: user.company,
    directDialNumber: user.directDialNumber,
    emailAddress: user.email,
    mailingAddress: user.mailingAddress,
    mobilePhoneNumber: user.mobilePhone,
    officeAddress: user.officeAddress,
    officePhoneNumber: user.officePhone,
    title: user.title
  };

  const passthroughProps = {
    avatarUrl: user.avatarUrl,
    firstName: user.firstName,
    hasDxpAccess: user.hasDxpAccess,
    isGhosted: user.isGhosted,
    lastName: user.lastName,
    userAvatarDocumentGuid: user.userAvatarDocumentGuid
  };

  return {
    ...removeEmptyKeysFromObject(userInfoToDisplay),
    ...passthroughProps,
    fullName: user.fullName || `${user.firstName} ${user.lastName}`.trim(),
    guid: user.userGuid || user.memberGuid,
    typeId: user.userTypeId
  };
};

function removeEmptyKeysFromObject(
  obj: Record<string, unknown>
): Record<string, unknown> {
  return Object.keys(obj)
    .filter(key => (obj as any)[key] != null && (obj as any)[key] !== '')
    .reduce((prev, curr) => ({ ...prev, [curr]: (obj as any)[curr] }), {});
}
