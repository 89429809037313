import DocumentTitle from 'components/DocumentTitle';
import Password from 'components/Password';
import { UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import { Formik, formikFieldProps, yup } from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { Button, EButtonVariant, TextInput, pushToast } from '@ryan/components';

import './ResetPassword.scss';

interface IResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const initialValues: IResetPasswordFormValues = {
  password: '',
  confirmPassword: ''
};

interface IResetPasswordProps
  extends RouteComponentProps<{ requestId: string }>,
    WithTranslation {
  /**
   * Indicates a new user is using reset password page to create their initial
   * password. Updates component verbiage.
   */
  newUser?: boolean;
}

interface IResetPasswordState {
  userInfo: {
    firstName: string;
    lastName: string;
    userTypeId: UserType;
  } | null;
  masked: boolean;
  loading: Promise<any> | null;
}

/**
 * Resets a user's password without them needing to enter a current password
 * (not be confused with changing an existing user's password under
 * `PasswordManagement/NewPassword` where a current password is required). User
 * enters this page after initiating the "forgot password" flow or when creating
 * their account password for the first time.
 */
class ResetPassword extends Component<
  IResetPasswordProps,
  IResetPasswordState
> {
  static defaultProps = { newUser: false };

  private schema: yup.ObjectSchema;

  constructor(props: IResetPasswordProps) {
    super(props);

    const { t } = this.props;

    this.schema = yup.object({
      password: yup.string(),
      confirmPassword: yup
        .string()
        .equalTo(yup.ref('password'), t('password.fields.mustMatch'))
    });

    this.state = {
      userInfo: null,
      masked: true,
      loading: null
    };
  }

  /**
   * When the component mounts, validate that the emailed token has not expired;
   */
  async componentDidMount() {
    const {
      t,
      history,
      match: {
        params: { requestId }
      },
      newUser
    } = this.props;

    try {
      const response = await ApiService.validateEmailToken(requestId);

      // If the token has already been used, navigate to app.
      if (response.data.resetDate) {
        history.push('/app');
      }

      const { data: userInfo } = await ApiService.resetPasswordUser(requestId);
      this.setState({ userInfo });
    } catch (error: any) {
      // If the token has expired, push the user back to the
      // Forgot Password page and notify them of the error.
      if (error?.response?.status === 400) {
        history.push(
          newUser
            ? '/public/newuser-password/expired'
            : '/public/forgot-password'
        );
        pushToast({
          type: 'error',
          title: t(
            'password.toast.expired',
            newUser ? { context: 'newUser' } : {}
          )
        });
      } else {
        pushServerErrorToast();
      }
    }
  }

  handleSubmit = async (values: IResetPasswordFormValues) => {
    const {
      t,
      history,
      match: {
        params: { requestId }
      },
      newUser
    } = this.props;

    try {
      const promise = ApiService.resetPassword(
        requestId,
        values.confirmPassword
      );
      this.setState({ loading: promise });
      await promise;
      history.push(
        newUser
          ? '/public/newuser-password-success'
          : '/public/reset-password-success'
      );
    } catch (error: any) {
      // 400 error indicates the password did not meet an ADFS set requirement,
      // such as "cannot contain user's name"
      if (error.response.status === 400) {
        pushToast({
          title: t('password.toast.invalid'),
          type: 'error'
        });
      } else {
        pushServerErrorToast();
      }

      this.setState({ loading: null });
    }
  };

  handleToggleMasked = () => {
    this.setState({ masked: !this.state.masked });
  };

  render() {
    const { t, newUser } = this.props;
    const { userInfo, masked, loading } = this.state;

    if (!userInfo) {
      return null;
    }

    return (
      <div className="reset-password">
        <DocumentTitle
          title={t(newUser ? 'password.newUserTitle' : 'password.resetTitle')}
        />

        <p>
          {t(
            newUser ? 'password.newUserDirections' : 'password.resetDirections'
          )}
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validateOnMount
          validationSchema={this.schema}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Password
                label={t(
                  newUser
                    ? 'password.fields.newUserLabel'
                    : 'password.fields.resetLabel'
                )}
                name="password"
                placeholder={t(
                  newUser
                    ? 'password.fields.newUserPlaceholder'
                    : 'password.fields.resetPlaceholder'
                )}
                userInfo={userInfo}
              />
              <TextInput
                {...formikFieldProps('confirmPassword', formik)}
                autoComplete="new-password"
                icon={masked ? 'show' : 'hide'}
                label={t(
                  newUser
                    ? 'password.fields.confirmNewUserPasswordLabel'
                    : 'password.fields.confirmResetPasswordLabel'
                )}
                onIconClick={this.handleToggleMasked}
                placeholder={t('password.fields.confirmPasswordPlaceholder')}
                type={masked ? 'password' : 'text'}
              />
              <Button
                loading={loading}
                text={t('Submit')}
                type="submit"
                variant={EButtonVariant.PRIMARY}
              />
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withTranslation()(ResetPassword);
