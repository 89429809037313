interface ISorted {
  id: string;
  desc: boolean;
}

export default function getSortParam(sorted?: ISorted | null) {
  if (sorted) {
    return `${sorted.id}${sorted.desc ? '-' : ''}`;
  }
  return '';
}
