import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticContext } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import DocumentTitle from 'components/DocumentTitle';
import { useUser } from 'contexts/UserContext';
import { Permission } from 'interfaces';
import CustomViewBuilder from './CustomViewBuilder';
import CustomViewContext, { ICustomViewContext } from './CustomViewContext';
import CustomViewInit from './CustomViewInit';
import CustomViewWell from './CustomViewWell';
import './CustomView.scss';

type ICustomViewInboundLocationState = {
  redirectTo?: string;
};

export type ICustomViewOutboundLocationState = {
  customViewUpdated?: boolean;
};

const CustomView: React.FC<
  RouteComponentProps<
    Record<string, never>,
    StaticContext,
    | (ICustomViewInboundLocationState & ICustomViewOutboundLocationState)
    | unknown
  >
> = ({ history, location, match }) => {
  const { path, url } = match;
  const { t } = useTranslation();
  const { permissionService } = useUser();

  // redirect path on cancel or successful submit
  const redirectRef = useRef(
    typeof (location.state as ICustomViewInboundLocationState)?.redirectTo ===
      'string' &&
      (location.state as ICustomViewInboundLocationState).redirectTo!.indexOf(
        path
      ) !== 0
      ? (location.state as ICustomViewInboundLocationState).redirectTo!
      : '/app'
  );

  // page title for current custom view route
  const pageTitle = t(
    'customView.title',
    location.pathname?.indexOf('edit') > -1 ? { context: 'edit' } : {}
  );

  // stores selected role for custom view
  const [role, setRole] = useState<ICustomViewContext['role']>(null);

  // toggles custom view information well
  const [showWell, setShowWell] = useState<ICustomViewContext['showWell']>(
    false
  );

  // stores selected view type
  const [viewType, setViewType] = useState<ICustomViewContext['viewType']>(
    null
  );

  // allow user to select role type if user has executive accecss
  const hasExecutiveAccess = permissionService.hasPermission(
    Permission.ExecutiveAccess
  );

  // ready to proceed once all visible initialization fields have been selected
  const isReadyToCreateNew =
    (!hasExecutiveAccess || role !== null) && viewType !== null;

  // initialize custom view context
  const context = useMemo(
    () => ({
      hasExecutiveAccess,
      role,
      setRole,
      showWell,
      setShowWell,
      viewType,
      setViewType
    }),
    [hasExecutiveAccess, role, showWell, viewType]
  );

  /**
   * Navigates back to originating path prior to entering create/edit custom
   * view on click of cancel or form submission. Pass path to redirect to
   * react-router `location.state.redirectTo`.
   */
  const redirectToPrevious = useCallback(() => {
    history.push(redirectRef.current);
  }, [history]);

  /**
   * Initiates creation of new custom view on click of next.
   */
  const handleNext = useCallback(() => {
    if (isReadyToCreateNew) {
      history.push({
        pathname: `${url}/create`
      });
    }
  }, [history, isReadyToCreateNew, url]);

  /**
   * Callback to handle custom view create/edit.
   */
  const handleSubmit = useCallback(() => {
    history.push({
      pathname: redirectRef.current,
      state: {
        customViewUpdated: true
      }
    });
  }, [history]);

  return (
    <CustomViewContext.Provider value={context}>
      <DocumentTitle title={pageTitle} />
      <div className="custom-view">
        <div className="row">
          <div className="col-md-9">
            <h1 className="ry-h1">{pageTitle}</h1>
            <p>
              {t(
                'customView.description',
                (location.pathname?.indexOf('create') > -1 ||
                  location.pathname?.indexOf('edit') > -1) &&
                  viewType
                  ? {
                      context: `${viewType}`
                    }
                  : {}
              )}
            </p>
          </div>
          <div className="col-md-3">{showWell && <CustomViewWell />}</div>
        </div>
        <Switch>
          {/* render custom view builder once initialization is complete */}
          {isReadyToCreateNew && (
            <Route exact path={`${path}/create`}>
              <CustomViewBuilder
                onCancel={redirectToPrevious}
                onSubmit={handleSubmit}
              />
            </Route>
          )}

          {/* render custom view builder with custom view to be edited */}
          <Route exact path={`${path}/edit/:customViewGuid`}>
            <CustomViewBuilder
              onCancel={redirectToPrevious}
              onSubmit={handleSubmit}
            />
          </Route>

          {/* render custom view initialization options */}
          <Route exact path={path}>
            <CustomViewInit
              disableNext={!isReadyToCreateNew}
              onCancel={redirectToPrevious}
              onNext={handleNext}
            />
          </Route>
          <Redirect to={path} />
        </Switch>
      </div>
    </CustomViewContext.Provider>
  );
};

export default CustomView;
