import Modal from 'components/Modal';
import { IHistoricalSavingsSummary } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  pushToast
} from '@ryan/components';

import { getHistoricalSavingsEngagementName } from './HistoricalSavingsEngagementName';

interface HistoricalSavingsDeleteModalProps extends WithTranslation {
  onClose?: (wasDeleted?: boolean) => void;
  open?: boolean;
  savings: IHistoricalSavingsSummary;
}

const HistoricalSavingsDeleteModal: React.FC<
  HistoricalSavingsDeleteModalProps
> = ({ onClose, open = false, savings, t }) => {
  const [submitPromise, setSubmitPromise] = useState<Promise<any> | null>(null);
  const projectName = getHistoricalSavingsEngagementName(savings);
  const handleDelete: React.MouseEventHandler = async () => {
    let wasDeleted = false;

    try {
      const responsePromise = ApiService.deleteHistoricalSavings(
        savings.historicalSavingsSummaryGuid
      );
      setSubmitPromise(responsePromise);
      await responsePromise;
      wasDeleted = true;
      pushToast({
        type: 'success',
        title: t('savings.historical.deleteToastTitle'),
        content: t('savings.historical.deleteToastContent', {
          name: projectName
        })
      });
    } catch (error) {
      pushServerErrorToast();
    } finally {
      setSubmitPromise(null);
      if (onClose) onClose(wasDeleted);
    }
  };

  return (
    <Modal
      open={open}
      title={t('Delete Savings')}
      {...(onClose ? { onClose: () => onClose() } : {})}
    >
      <p>{t('savings.historical.deleteDescription', { name: projectName })}</p>
      <ButtonGroup>
        <Button
          loading={submitPromise}
          negative
          onClick={handleDelete}
          text={t('Yes, Delete')}
        />
        <Button
          disabled={Boolean(submitPromise)}
          text={t('No, Cancel')}
          variant={EButtonVariant.SECONDARY}
          {...(onClose ? { onClick: () => onClose() } : {})}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default withTranslation()(HistoricalSavingsDeleteModal);
