import React, { FunctionComponent } from 'react';
import {
  IHistoricalSavingsForm,
  IHistoricalSavingsFormValidated,
  IHistoricalSavingsSummary
} from 'interfaces';

function pad(n: number) {
  const norm = Math.floor(Math.abs(n));
  return (norm < 10 ? '0' : '') + norm;
}

function getDateString(date: Date) {
  const MM = pad(date.getMonth() + 1);
  const YY = date.getFullYear().toString().slice(2);
  return `${MM}/${YY}`;
}

export function getHistoricalSavingsEngagementName(
  values:
    | IHistoricalSavingsForm
    | IHistoricalSavingsFormValidated
    | IHistoricalSavingsSummary
) {
  const practice = values.practiceName || '{Service Line}';
  const service = values.serviceTypeName || '{Type}';
  const jurisdiction =
    (
      (values as IHistoricalSavingsForm).jField ||
      (values as IHistoricalSavingsFormValidated | IHistoricalSavingsSummary)
        .jurisdiction
    )?.abbreviation || '{Jurisdiction}';

  const startDate =
    values.periodStartDate !== null
      ? getDateString(values.periodStartDate)
      : '{Start Date}';

  const endDate =
    values.periodEndDate !== null
      ? getDateString(values.periodEndDate)
      : '{End Date}';

  return `${practice} (${service}) ${jurisdiction} ${startDate}–${endDate}`;
}

export const HistoricalSavingsEngagementName: FunctionComponent<{
  values: IHistoricalSavingsForm;
}> = ({ values }) => {
  return (
    <span>
      {values.practiceName || <i>{`{Service Line}`}</i>} (
      {values.serviceTypeName || <i>{`{Type}`}</i>}){' '}
      {values.jField?.abbreviation || <i>{`{Jurisdiction}`}</i>}{' '}
      {values.periodStartDate !== null ? (
        getDateString(values.periodStartDate)
      ) : (
        <i>{`{Start Date}`}</i>
      )}
      –
      {values.periodEndDate !== null ? (
        getDateString(values.periodEndDate)
      ) : (
        <i>{`{End Date}`}</i>
      )}
    </span>
  );
};

export default HistoricalSavingsEngagementName;
