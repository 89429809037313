import { IRolePermission, IUserPermission } from 'interfaces';

/*
  Compare the set of original permissions of the role with the customized permissions
  Used to disable Save Changes button if no changes have been made.
 */
/*
  defaultPermissions === the set of saved permissions on a role
  customPermissions === the customized set of permissions on a role
*/
export default function isSameSetOfPermissions(
  defaultPermissions: IRolePermission[] | IUserPermission[],
  customPermissions: IRolePermission[] | IUserPermission[]
) {
  if (defaultPermissions.length !== customPermissions.length) {
    return false;
  }
  return customPermissions.every(permission => {
    const matchingPermission = defaultPermissions.find(
      p => p.permissionGuid === permission.permissionGuid
    );
    return (
      matchingPermission !== undefined &&
      matchingPermission.isGranted === permission.isGranted
    );
  });
}
