import { IAmplitudeContext } from './AmplitudeContext.interfaces';

import { createContext } from 'react';

const AmplitudeContext = createContext<IAmplitudeContext>({
  initializeEventToTrack: () => {},
  terminateEventToTrack: () => {},
  triggerAmplitudeEvent: () => {}
});

export default AmplitudeContext;
