import parseISO from 'date-fns/parseISO';

import { IGetIsDeferredActivation } from './IGetIsDeferredActivation';

export const getIsDeferredActivation: IGetIsDeferredActivation = (
  activateDate,
  deactivateDate
) => {
  const todaysDate = new Date();

  const hasActivateDate = activateDate != null;
  const hasActivateDatePassed =
    hasActivateDate && parseISO(activateDate as any as string) <= todaysDate;
  const hasDeactivateDatePassed =
    deactivateDate && parseISO(deactivateDate) <= todaysDate;

  return hasActivateDate && !hasActivateDatePassed && !hasDeactivateDatePassed;
};
