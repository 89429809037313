import Modal from 'components/Modal';
import Password from 'components/Password';
import { WithUser, withUser } from 'contexts/UserContext';
import { INewPasswordForm } from 'interfaces';
import ApiService from 'services/ApiService';
import { Formik, formikFieldProps, yup } from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import { TFunction } from 'i18next';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  Message,
  TextInput,
  pushToast
} from '@ryan/components';

interface IResetPasswordModalProps extends WithTranslation, WithUser {
  open: boolean;
  onClose: () => void;
}

interface IResetPasswordModalState {
  oldMasked: boolean;
  confirmMasked: boolean;
  loading: Promise<any> | null;
  errorMessage: React.ReactNode;
}

const initialValues: INewPasswordForm = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const getSchema = (t: TFunction) =>
  yup.object({
    oldPassword: yup
      .string()
      .required(t('security.resetPassword.current', { context: 'required' }))
      .notOneOf(
        [yup.ref('newPassword')],
        t('security.resetPassword.oldMustNotMatchNew')
      ),
    newPassword: yup.string(),
    confirmPassword: yup
      .string()
      .equalTo(
        yup.ref('newPassword'),
        t('security.resetPassword.confirmMustMatchNew')
      )
  });

export class ResetPasswordModal extends Component<
  IResetPasswordModalProps,
  IResetPasswordModalState
> {
  readonly state = {
    oldMasked: true,
    confirmMasked: true,
    loading: null,
    errorMessage: null
  };

  schema = getSchema(this.props.t);

  handleSubmit = async (values: INewPasswordForm, formik: any) => {
    const { t } = this.props;
    const promise = ApiService.validateCredentialsAndResetPassword(
      values.oldPassword,
      values.confirmPassword
    );

    this.setState({ loading: promise });

    try {
      await promise;

      pushToast({
        type: 'success',
        title: t('security.resetPassword.successToast')
      });

      this.handleClose();
    } catch (error: any) {
      if (!ApiService.isCancel(error) && error?.response) {
        switch (error.response.status) {
          case 500:
            pushServerErrorToast();
            this.handleClose();
            break;
          case 400:
            this.setState(
              {
                errorMessage: t('security.resetPassword.incorrectPassword')
              },
              formik.validateForm
            );
            break;
        }
      }
    } finally {
      this.setState({ loading: null });
    }
  };

  handleToggleOldMasked = () => {
    this.setState(({ oldMasked: prevOldMasked }) => ({
      oldMasked: !prevOldMasked
    }));
  };

  handleToggleConfirmMasked = () => {
    this.setState(({ confirmMasked: prevConfirmMasked }) => ({
      confirmMasked: !prevConfirmMasked
    }));
  };

  handleClose = () => {
    this.props.onClose();
    this.setState({ oldMasked: true, confirmMasked: true, errorMessage: null });
  };

  render() {
    const { t, open, user } = this.props;
    const { oldMasked, confirmMasked, loading, errorMessage } = this.state;

    return (
      <Modal
        onClose={this.handleClose}
        open={open}
        title={t('security.resetPassword.title')}
      >
        {errorMessage && <Message type="error">{errorMessage}</Message>}
        <Formik<INewPasswordForm>
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validateOnMount
          validationSchema={this.schema}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <TextInput
                {...formikFieldProps('oldPassword', formik)}
                autoComplete="current-password"
                icon={oldMasked ? 'show' : 'hide'}
                label={t('security.resetPassword.current')}
                onIconClick={() => this.handleToggleOldMasked()}
                type={oldMasked ? 'password' : 'text'}
              />
              <Password
                label={t('security.resetPassword.new')}
                name="newPassword"
                userInfo={user.profile}
              />
              <TextInput
                {...formikFieldProps('confirmPassword', formik)}
                autoComplete="new-password"
                icon={confirmMasked ? 'show' : 'hide'}
                label={t('security.resetPassword.confirm')}
                onIconClick={() => this.handleToggleConfirmMasked()}
                type={confirmMasked ? 'password' : 'text'}
              />
              <ButtonGroup>
                <Button
                  loading={loading}
                  text={t('Save')}
                  type="submit"
                  variant={EButtonVariant.PRIMARY}
                />
                <Button
                  disabled={!!loading}
                  onClick={this.handleClose}
                  text={t('Cancel')}
                  variant={EButtonVariant.SECONDARY}
                />
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(withUser(ResetPasswordModal));
