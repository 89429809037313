import { escapeRegexCharacters } from './escapeRegexCharacters';

/**
 * Given a string, replace every substring that is matched by the `match` regex
 * with the result of calling `fn` on matched substring. The result will be an
 * array with all odd indexed elements containing the replacements. The primary
 * use case is similar to using String.prototype.replace except for React.
 *
 * React will happily render an array as children of a react element, which
 * makes this approach very useful for tasks like surrounding certain text
 * within a string with react elements.
 *
 * Example:
 * stringReplaceJsx(
 *   'Emphasize all phone numbers like 884-555-4443.',
 *   /([\d|-]+)/g,
 *   (number, i) => <strong key={i}>{number}</strong>
 * );
 * // => ['Emphasize all phone numbers like ', <strong>884-555-4443</strong>, '.'
 */
export function stringReplaceJsx(
  source: string,
  match: RegExp | string,
  fn: (match: string, index: number) => React.ReactNode,
  limit = Infinity
): React.ReactNode {
  if (source === '') {
    return source;
  }

  let re = match;
  if (!(re instanceof RegExp)) {
    re = new RegExp(`(${escapeRegexCharacters(re)})`, 'gi');
  }

  const result = source.split(re) as React.ReactNode[];
  for (
    let i = 1, { length } = result, count = 0;
    i < length && count < limit;
    i += 2, count++
  ) {
    result[i] = fn(result[i] as string, i);
  }

  return ([] as React.ReactNode[]).concat(...result);
}
