import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import SingleReleaseNote from '../SingleReleaseNote/SingleReleaseNote';
import { IReleaseNoteProps } from './utils';

import './ReleaseNotes.scss';

const ReleaseNotes: React.FC<IReleaseNoteProps> = ({
  className,
  isLoading,
  listInnerRef,
  releaseNotes
}) => {
  const { t: textToDisplay } = useTranslation();
  const isReleaseNotes = releaseNotes?.length;

  return (
    <div
      className={classnames(
        'release-note',
        !isReleaseNotes && 'release-note__empty',
        className
      )}
      ref={listInnerRef}
    >
      {isReleaseNotes ? (
        releaseNotes.map((releaseNote, index) => {
          return (
            <SingleReleaseNote
              key={releaseNote.releaseNoteGuid + index}
              releaseNote={releaseNote}
              textLimit={600}
            />
          );
        })
      ) : (
        <div className="release-note__no-result">
          {textToDisplay('modal.releaseNotesModal.noResultsFound')}
        </div>
      )}
      {isLoading && (
        <Icon
          className={classnames('loading-spin', {
            'loading-spin--top': isReleaseNotes < 1
          })}
          name="loading"
        />
      )}
    </div>
  );
};

export default ReleaseNotes;
