import logo from 'images/ryan-logo.svg';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { BodyClass } from '@ryan/components';

import './PublicLayout.scss';

/**
 * The container for "public" route body content.
 */
const PublicLayoutContainer: React.FC<{
  /**
   * Hides links to app specific pages.
   */
  hideAppLinks?: boolean;
}> = ({ children, hideAppLinks = false }) => {
  const { t } = useTranslation();

  return (
    <div className="public-layout">
      <BodyClass className="with-public-layout" />
      <div className="public-layout__picture" />
      <div className="public-layout__content">
        {/* Logo */}
        <NavLink className="public-layout__logo" to={hideAppLinks ? '#' : '/'}>
          <img alt="Ryan logo" src={logo} />
        </NavLink>

        {/* Body */}
        {children}
      </div>
      <div className="public-layout__footer">
        {!hideAppLinks && (
          <nav className="public-layout__footer-nav">
            <NavLink to="/go-to/privacy">{t('Privacy Policy')}</NavLink>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <NavLink to="/go-to/terms">{t('Terms of Use')}</NavLink>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <NavLink to="/public/help">{t('Help')}</NavLink>
          </nav>
        )}
        <div className="public-layout__footer-copyright">
          &copy; {new Date().getFullYear()} Ryan, LLC &nbsp;&nbsp;&nbsp;{' '}
        </div>
      </div>
    </div>
  );
};

export default PublicLayoutContainer;
