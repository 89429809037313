import { ContractFileType, IContractSummary } from 'interfaces';

import { TFunction } from 'i18next';

/**
 * Parses a display name from a contract summary.
 */
export default function getContractFileName(
  t: TFunction,
  { name, typeId }: IContractSummary
): string {
  return typeId !== ContractFileType.Other &&
    typeId !== ContractFileType.Unknown
    ? `${t(`contracts.type.${typeId}`)} ${name}`
    : name;
}
