import StatusIcon from 'components/StatusIcon/StatusIcon';
import { IMilestone } from 'interfaces';

import classnames from 'classnames';
import { TFunction } from 'i18next';
import React, { Component } from 'react';

import { Tooltip } from '@ryan/components';

import ScrollingCalendar from '../../ScrollingCalendar/ScrollingCalendar';

import './MilestonesTimeline.scss';

export interface IMilestonesTimelineProps {
  t: TFunction;
  milestones: IMilestone[];
  activeMilestoneGuid: string | null;
  startDate: Date;
  months: number;
  onBackOneMonth?: () => void;
  onForwardOneMonth?: () => void;
  onToggleActive: (engagementMilestoneGuid: string) => void;
}

class MilestonesTimeline extends Component<IMilestonesTimelineProps> {
  render() {
    const { milestones, startDate, months, onBackOneMonth, onForwardOneMonth } =
      this.props;
    return (
      <ScrollingCalendar
        loading={false}
        months={months}
        onBackOneMonth={onBackOneMonth}
        onForwardOneMonth={onForwardOneMonth}
        render={({ getOffsets }) => (
          <div className="milestones-timeline">
            <div className="milestones-timeline__rows">
              {milestones.map(milestone =>
                this.renderCalendarRow(
                  milestone,
                  getOffsets(milestone.startDate, milestone.endDate)
                )
              )}
            </div>
          </div>
        )}
        startDate={startDate}
      />
    );
  }

  renderCalendarRow(
    milestone: IMilestone,
    offsets: {
      left: number;
      right: number;
      isVisible: boolean;
      isOverflowingLeft: boolean;
      isOverflowingRight: boolean;
    }
  ) {
    if (!offsets.isVisible) {
      return null;
    }

    const { onToggleActive, activeMilestoneGuid } = this.props;
    const isActive = activeMilestoneGuid === milestone.engagementMilestoneGuid;
    return (
      <div
        className="milestones-timeline__row"
        key={milestone.engagementMilestoneGuid}
      >
        <Tooltip
          content={milestone.title}
          placement="top"
          renderTarget={({ open, ref, ...props }) => (
            <button
              {...props}
              aria-expanded={open}
              aria-haspopup="true"
              className={classnames({
                'milestones-timeline__milestone': true,
                'milestones-timeline__milestone--active': isActive
              })}
              onClick={() => onToggleActive(milestone.engagementMilestoneGuid)}
              ref={ref}
              style={{
                left: `${offsets.left}%`,
                width: `${100 - offsets.right - offsets.left}%`
              }}
            >
              <StatusIcon
                iconOnly
                inverted={isActive}
                size="lg"
                status={milestone.statusId}
              />

              {/**
               * If the milestone is one date, render simply the milestone status,
               * otherwise render the title as well.
               */}
              {milestone.startDate != null &&
                milestone.endDate != null &&
                milestone.startDate.getTime() !==
                  milestone.endDate.getTime() && (
                  <div className="milestones-timeline__milestone-title">
                    {milestone.title}
                  </div>
                )}
            </button>
          )}
        />
      </div>
    );
  }
}

export default MilestonesTimeline;
