import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from 'contexts/UserContext';
import { IContactInfoForm, IUser } from 'interfaces';
import ApiService from 'services/ApiService';
import history from 'services/history';

export type ProfileHookResponse = {
  profileUser: IUser | null;
  profileUserIsLoggedInUser: boolean;
  updateProfileUser: (values: IContactInfoForm) => void;
};

export default function useProfileUser(): ProfileHookResponse {
  // Get logged-in user
  const {
    permissionService,
    user: { profile: loggedInUser }
  } = useUser();

  // Get userGuid from route params
  const { userGuid: paramUserGuid } = useParams<{ userGuid: string }>();

  // Determine which user we are showing
  const [profileUserGuid, setProfileUserGuid] = useState(
    paramUserGuid || loggedInUser.userGuid
  );
  const [profileUserIsLoggedInUser, setProfileUserIsLoggedInUser] = useState(
    profileUserGuid === loggedInUser.userGuid
  );
  const [profileUser, setProfileUser] = useState<IUser | null>(
    profileUserIsLoggedInUser ? loggedInUser : null
  );

  const fetchProfileUser = useCallback(
    (profileUserGuid: string) => {
      ApiService.getUser(profileUserGuid).then(response => {
        const profileUser = response.data;

        if (
          // All users can view their own profile.
          profileUserIsLoggedInUser ||
          // Ryan users can view other Client and ThirdParty profiles.
          permissionService.canEditClientProfile(profileUser)
        ) {
          setProfileUser(profileUser);
        } else {
          history.replace('/app/403');
        }
      });
    },
    [permissionService, profileUserIsLoggedInUser]
  );

  const updateProfileUser = useCallback(
    (values: IContactInfoForm) => {
      ApiService.updateUserInfo(profileUserGuid, values).then(response => {
        setProfileUser(response.data);
      });
    },
    [profileUserGuid]
  );

  // update values on user change
  useEffect(() => {
    setProfileUserGuid(paramUserGuid || loggedInUser.userGuid);
    setProfileUserIsLoggedInUser(profileUserGuid === loggedInUser.userGuid);
    setProfileUser(profileUserIsLoggedInUser ? loggedInUser : null);
  }, [loggedInUser, paramUserGuid, profileUserGuid, profileUserIsLoggedInUser]);

  // Load if needed
  useEffect(() => {
    if (profileUser === null || (profileUser.userGuid !== null && profileUser.userGuid !== profileUserGuid)) {
      fetchProfileUser(profileUserGuid);
    }
  }, [profileUser, profileUserGuid, fetchProfileUser]);

  // Poll if needed
  const poll = useRef<number>(0);

  useEffect(() => {
    if (profileUser && profileUser.isPendingUpdate) {
      poll.current = window.setTimeout(
        () => fetchProfileUser(profileUser.memberGuid),
        5000
      );
    }
    return () => {
      window.clearTimeout(poll.current);
    };
  }, [profileUser, fetchProfileUser]);

  const hookResponse: ProfileHookResponse = useMemo(
    () => ({
      profileUser,
      profileUserIsLoggedInUser,
      updateProfileUser
    }),
    [profileUser, profileUserIsLoggedInUser, updateProfileUser]
  );

  return hookResponse;
}
