import debounce from 'lodash.debounce';

import { TFunction } from 'i18next';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  Icon,
  Message,
  makeTableCheckboxFilter,
  pushToast
} from '@ryan/components';

import ContractFileEditPublishModal from '../../../components/ContractFileEditPublishModal/ContractFileEditPublishModal';
import ContractFileUnpublishModal from '../../../components/ContractFileUnpublishModal/ContractFileUnpublishModal';
import Empty from '../../../components/Empty';
import Table from '../../../components/Table';
import ContractFileOverflowMenu from '../../../components/Table/ContractFileOverflowMenu/ContractFileOverflowMenu';
import TableEmpty from '../../../components/TableEmpty/TableEmpty';
import TooltipList from '../../../components/TooltipList/TooltipList';
import {
  WithAmplitude,
  withAmplitude
} from '../../../contexts/AmplitudeContext/AmplitudeConsumer';
import { WithDownload, withDownload } from '../../../contexts/DownloadContext';
import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  ContractFileStatus,
  IContractFile,
  IContractFileSearch,
  ITableState,
  Permission
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import { amplitudeEventDetail } from '../../../utils/amplitudeUtils/amplitudeUtils';
import { formatDate } from '../../../utils/formatDate';
import getContractFileName from '../../../utils/getContractFileName';
import getSortParam from '../../../utils/getSortParm';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';

import './ContractFiles.scss';

interface IContractFileProps
  extends WithTranslation,
    WithAmplitude,
    WithUser,
    WithDownload {
  engagementGuid: string;
  onContractFilesLoaded: (files: IContractFile[]) => void;
  onLoading: (isLoading: boolean) => void;
  searchQuery: string;
}

interface IContractFileState extends ITableState {
  contractFiles: IContractFile[];
  editPublishContractFile?: IContractFile | null;
  error?: React.ReactNode;
  isEngagementGhosting: boolean;
  isEngagementReadOnly: boolean;
  unpublishContractFile?: IContractFile | null;
}

// Assumes Permission.ContractsView
export class ContractFiles extends Component<
  IContractFileProps,
  IContractFileState
> {
  private _isMounted = false;
  private getEngagementCancelToken?: CancelTokenSource;
  private getContractFilesByEngagementGuidCancelToken?: CancelTokenSource;
  private pubUnpubContractFilesCancelToken?: CancelTokenSource;

  private columns: any[];

  constructor(props: IContractFileProps) {
    super(props);
    const {
      activeView: { isExecutiveView },
      isAppReadOnly,
      permissionService,
      t: getTextToDisplay
    } = props;

    this.state = {
      contractFiles: [],
      editPublishContractFile: null,
      filtered: { status: [] },
      isEngagementGhosting: false,
      isEngagementReadOnly: true,
      loading: false,
      page: 1,
      pageSize: 10,
      selected: [],
      sorted: {
        desc: false,
        id: 'name'
      },
      totalCount: 0,
      unpublishContractFile: null
    };

    this.columns = [
      {
        id: 'name',
        label: getTextToDisplay('contracts.columns.name'),
        render: (row: IContractFile) => (
          <div className="bs bs--icon bs--light">
            <Icon
              name={(function (fileType) {
                switch (fileType) {
                  case 'PDF':
                    return 'file-ext-pdf';
                  case 'XLS':
                    return 'file-spreadsheet';
                  case 'ZIP':
                    return 'data-file';
                  default:
                    return 'file';
                }
              })(row.fileType)}
            />
            <b>{getContractFileName(getTextToDisplay, row)}</b>
            {/* Only the display name shows for external users, Ryan also sees the original name */}
            {permissionService.isRyan() && <small>{row.originalName}</small>}
          </div>
        ),
        sortable: true,
        width: permissionService.isRyan() ? '55%' : '85%'
      },
      {
        id: 'executedDate',
        label: getTextToDisplay('contracts.columns.executed'),
        render: (row: IContractFile) =>
          row.executedDate ? formatDate(row.executedDate) : '–',
        sortable: true,
        width: '15%'
      },
      {
        align: 'right',
        id: 'actions',
        label: '',
        render: (contractFile: IContractFile) => {
          const { isEngagementGhosting, isEngagementReadOnly } = this.state;

          const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;

          return (
            <div className="contract-files-page__actions">
              <TooltipList
                list={[
                  {
                    label: getTextToDisplay('Original File Name'),
                    value: contractFile.originalName
                  },
                  {
                    label: getTextToDisplay('File Type'),
                    value: contractFile.fileType
                  }
                ]}
              />
              <div className="contract-files-page__button">
                {permissionService.isRyan() ? (
                  <ContractFileOverflowMenu
                    contractFile={contractFile}
                    isEngagementGhosting={isEngagementGhosting}
                    isEngagementReadOnly={isEngagementReadOnly}
                    isReadOnlyByUserState={isReadOnlyByUserState}
                    onDownload={() => {
                      this.handleDownloadContract(contractFile);
                    }}
                    onEdit={() => {
                      this.setState({ editPublishContractFile: contractFile });
                    }}
                    onPublish={() => {
                      this.handlePublishSingleFile({
                        contractFileToPublish: contractFile,
                        getTextToDisplayCallback: getTextToDisplay,
                        handleRefreshCallback: this.handleRefresh
                      });
                    }}
                    onUnpublish={() => {
                      this.setState({ unpublishContractFile: contractFile });
                    }}
                    overflowButtonSize={EButtonSizes.SMALL}
                  />
                ) : (
                  <Button
                    disabled={isReadOnlyByUserState || isEngagementGhosting}
                    icon="download"
                    onClick={() => {
                      this.handleDownloadContract(contractFile);
                    }}
                    size={EButtonSizes.SMALL}
                    variant={EButtonVariant.TEXT}
                  />
                )}
              </div>
            </div>
          );
        }
      }
    ];

    if (permissionService.isRyan()) {
      this.columns.splice(
        2,
        0,
        {
          id: 'publishedDate',
          label: getTextToDisplay('contracts.columns.published'),
          render: (row: IContractFile) => {
            return row.publishedDate ? formatDate(row.publishedDate) : '–';
          },
          sortable: true,
          width: '15%'
        },
        {
          accessor: 'updateDate',
          filter: makeTableCheckboxFilter([
            {
              label: getTextToDisplay('contracts.status.unpublished'),
              value: ContractFileStatus.Unpublished.toString()
            },
            {
              label: getTextToDisplay('contracts.status.published'),
              value: ContractFileStatus.Published.toString()
            }
          ]),
          filterActive: (value: string[]) => value.length > 0,
          id: 'status',
          label: getTextToDisplay('contracts.columns.status'),
          render: (row: IContractFile) => {
            switch (row.statusId) {
              case ContractFileStatus.Unpublished:
                return getTextToDisplay('contracts.status.unpublished');
              case ContractFileStatus.Published:
                return getTextToDisplay('contracts.status.published');
            }
          },
          width: '15%'
        }
      );
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchEngagement();
    this.fetchContractFilesData();
  }

  componentDidUpdate(prevProps: IContractFileProps) {
    if (prevProps.engagementGuid !== this.props.engagementGuid) {
      this.fetchEngagement();
    }

    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.debouncedSearch();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.getEngagementCancelToken?.cancel();
    this.getContractFilesByEngagementGuidCancelToken?.cancel();
    this.pubUnpubContractFilesCancelToken?.cancel();
  }

  debouncedSearch = debounce(() => {
    this.fetchContractFilesData({ page: 1 });
  }, 250);

  fetchEngagement() {
    const { engagementGuid } = this.props;
    this.setState({ isEngagementReadOnly: true }, async () => {
      try {
        this.getEngagementCancelToken?.cancel();
        this.getEngagementCancelToken = ApiService.CancelToken.source();

        const { data } = await ApiService.getEngagement(
          engagementGuid,
          this.getEngagementCancelToken.token
        );

        this.setState({
          isEngagementGhosting: data.isUserGhosted,
          isEngagementReadOnly: data.isReadOnly
        });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      }
    });
  }

  fetchContractFilesData(updates?: Partial<IContractFileState>) {
    const { engagementGuid, onLoading, searchQuery } = this.props;

    this.setState(
      { ...(updates as IContractFileState), loading: true },
      async () => {
        onLoading(true);

        const { filtered, page, pageSize, sorted } = this.state;
        const params: IContractFileSearch = {
          itemsPerPage: pageSize,
          pageNumber: page,
          searchTerm: searchQuery,
          sort: getSortParam(sorted),
          status: filtered!.status.length === 1 ? filtered!.status[0] : ''
        };

        try {
          this.getContractFilesByEngagementGuidCancelToken?.cancel();
          this.getContractFilesByEngagementGuidCancelToken =
            ApiService.CancelToken.source();

          const { data } = await ApiService.getContractFilesByEngagementGuid(
            engagementGuid,
            params,
            this.getContractFilesByEngagementGuidCancelToken.token
          );

          this.setState({
            contractFiles: data.results,
            selected: [],
            totalCount: data.totalResults
          });

          this.props.onContractFilesLoaded(data.results);
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({
              loading: false
            });
            onLoading(false);
          }
        }
      }
    );
  }

  getSelectedContractFiles() {
    const { contractFiles } = this.state;
    const selected = this.state.selected || [];
    return contractFiles.filter(contract =>
      selected.includes(contract.engagementContractDocumentGuid)
    );
  }

  handleDownloadContract = (contractFile: IContractFile) => {
    const { onDownloadContract, triggerAmplitudeEvent } = this.props;

    triggerAmplitudeEvent({
      amplitudeEventAction:
        amplitudeEventDetail.ryanContracts.downloadEventName,
      amplitudeEventName: amplitudeEventDetail.ryanContracts.eventName,
      amplitudeEventProperty:
        amplitudeEventDetail.ryanContracts.downloadPropertyOptions.dataAndFiles
    });
    onDownloadContract(contractFile);
  };

  handleFilter = (filtered: Record<string, unknown>) => {
    this.fetchContractFilesData({ filtered, page: 1 });
  };

  handlePage = (page: number, pageSize: number) => {
    this.fetchContractFilesData({ page, pageSize });
  };

  handlePublishAll = () => {
    this.handleStatusChange(true);
  };

  // TODO: Functionality duplicate on ContractCard Component. Merge
  handlePublishSingleFile = async ({
    contractFileToPublish,
    getTextToDisplayCallback,
    handleRefreshCallback
  }: {
    contractFileToPublish: IContractFile;
    getTextToDisplayCallback: TFunction;
    handleRefreshCallback: () => void;
  }) => {
    if (!Boolean(contractFileToPublish.executedDate)) {
      this.setState({ editPublishContractFile: contractFileToPublish });
      return;
    }

    try {
      await ApiService.publishContractFiles(
        contractFileToPublish.engagementGuid,
        [contractFileToPublish.engagementContractDocumentGuid]
      );

      pushToast({
        content: (
          <Trans i18nKey="contracts.modal.publish.success.content">
            <b />
            {{
              displayName: getContractFileName(
                getTextToDisplayCallback,
                contractFileToPublish
              )
            }}
          </Trans>
        ),
        title: getTextToDisplayCallback(
          'contracts.modal.publish.success.title'
        ),
        type: EMessageTypes.SUCCESS
      });

      handleRefreshCallback();
    } catch (error) {
      pushToast({
        content: getTextToDisplayCallback('serverError.content'),
        title: getTextToDisplayCallback('serverError.title'),
        type: EMessageTypes.ERROR
      });
    }
  };

  handleRefresh = () => {
    this.fetchContractFilesData();
  };

  handleSelect = (selected: string[]) => {
    this.setState({
      selected
    });
  };

  handleSort = (sorted: any) => {
    this.fetchContractFilesData({ sorted });
  };

  handleStatusChange = async (isPublish: boolean) => {
    const { selected } = this.state;
    const { t: getTextToDisplay, engagementGuid } = this.props;

    if (selected && selected.length > 0) {
      this.pubUnpubContractFilesCancelToken?.cancel();
      this.pubUnpubContractFilesCancelToken = ApiService.CancelToken.source();

      const request = isPublish
        ? ApiService.publishContractFiles(
            engagementGuid,
            selected,
            this.pubUnpubContractFilesCancelToken?.token
          )
        : ApiService.unpublishContractFiles(
            engagementGuid,
            selected,
            this.pubUnpubContractFilesCancelToken?.token
          );

      try {
        await request;
        pushToast({
          content: getTextToDisplay(
            isPublish
              ? 'contracts.modal.publish.success.allContent'
              : 'contracts.modal.unpublish.success.allContent'
          ),
          title: getTextToDisplay(
            isPublish
              ? 'contracts.modal.publish.success.title'
              : 'contracts.modal.edit.success.title'
          ),
          type: EMessageTypes.SUCCESS
        });

        this.fetchContractFilesData();
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          this.setState({
            error: (
              <Message
                title={getTextToDisplay('serverError.title')}
                type="error"
              >
                {getTextToDisplay('serverError.content')}
              </Message>
            )
          });
        }
      }
    }
  };

  handleUnpublishAll = () => {
    this.handleStatusChange(false);
  };

  render() {
    const { permissionService, searchQuery, t: getTextToDisplay } = this.props;
    const {
      editPublishContractFile,
      isEngagementReadOnly,
      page,
      pageSize,
      sorted,
      contractFiles,
      filtered,
      loading,
      selected,
      totalCount,
      unpublishContractFile
    } = this.state;

    const files = this.getSelectedContractFiles();
    const isBatchOptionByState =
      isEngagementReadOnly ||
      !permissionService.hasPermission(Permission.ContractsEditAndPublish) ||
      files.length === 0;

    return (
      <div className="contract-files-page">
        <Table<IContractFile, string>
          columns={this.columns}
          data={contractFiles}
          filtered={filtered}
          headerTitle={`${getTextToDisplay('Files')} (${totalCount})`}
          loading={loading}
          onFilterChange={this.handleFilter}
          onPageChange={this.handlePage}
          onSelectChange={this.handleSelect}
          onSortChange={this.handleSort}
          page={page}
          pageSize={pageSize}
          renderEmpty={() => (
            <TableEmpty searchQuery={searchQuery}>
              <Empty icon="file">
                {getTextToDisplay('contracts.files.empty')}
              </Empty>
            </TableEmpty>
          )}
          renderSelectionActions={() => (
            <>
              <Button
                disabled={
                  isBatchOptionByState ||
                  !files.every(
                    contracFile =>
                      (files.length > 1
                        ? contracFile?.executedDate !== null
                        : true) &&
                      contracFile?.statusId === ContractFileStatus.Unpublished
                  )
                }
                icon="file-check"
                onClick={event => {
                  event.preventDefault();

                  selected?.length === 1
                    ? this.handlePublishSingleFile({
                        contractFileToPublish:
                          this.getSelectedContractFiles()[0],
                        getTextToDisplayCallback: getTextToDisplay,
                        handleRefreshCallback: this.handleRefresh
                      })
                    : this.handlePublishAll();
                }}
                size={EButtonSizes.SMALL}
                text={getTextToDisplay('contracts.files.publish')}
                variant={EButtonVariant.TEXT}
              />
              <Button
                disabled={
                  isBatchOptionByState ||
                  !files.every(
                    contractFile =>
                      contractFile?.statusId === ContractFileStatus.Published
                  )
                }
                icon="file-delete"
                onClick={() => {
                  if (selected?.length === 1) {
                    this.setState({
                      unpublishContractFile: this.getSelectedContractFiles()[0]
                    });
                  } else {
                    this.handleUnpublishAll();
                  }
                }}
                size={EButtonSizes.SMALL}
                text={getTextToDisplay('contracts.files.unpublish')}
                variant={EButtonVariant.TEXT}
              />
            </>
          )}
          rowId="engagementContractDocumentGuid"
          selected={permissionService.isRyan() ? selected : undefined}
          selectedTitle={`${getTextToDisplay('Files')} (${
            selected ? selected.length : ''
          })`}
          sorted={sorted}
          totalCount={totalCount}
        />
        {editPublishContractFile && (
          <ContractFileEditPublishModal
            contractFile={editPublishContractFile}
            contractStatus={
              Boolean(editPublishContractFile.executedDate)
                ? null
                : ContractFileStatus.Published
            }
            onClose={(updatedContractFile?: IContractFile) => {
              this.setState({ editPublishContractFile: null });

              if (updatedContractFile) {
                this.handleRefresh();
              }
            }}
            open
          />
        )}
        {unpublishContractFile && (
          <ContractFileUnpublishModal
            contractFile={unpublishContractFile}
            onClose={(isUpdated?: boolean) => {
              this.setState({ unpublishContractFile: null });

              if (isUpdated) {
                this.handleRefresh();
              }
            }}
            open
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(
  withAmplitude(withUser(withDownload(ContractFiles)))
);
