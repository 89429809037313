import axios from 'axios';
import { Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';

import { ITableProps } from '@ryan/components';

import useDebounce from './useDebounce';
import useLatest from './useLatest';
import useTableQuery, {
  TableQuery,
  TableQueryHookResponse
} from './useTableQuery';

interface Action {
  type: string;
  [key: string]: any;
}

interface TableState<T> {
  loading: boolean;
  data: T[];
  totalCount: number;
  expanded: { [key: string]: boolean };
  selected: string[];
}

const defaultTableState: TableState<any> = {
  loading: false,
  data: [],
  totalCount: 0,
  expanded: {},
  selected: []
};

function tableReducer<T = any>(state: TableState<T>, action: Action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.data,
        totalCount: action.totalCount,
        expanded: {},
        selected: []
      };
    case 'FETCH_FAILURE':
      return { ...state, loading: false };
    case 'ROW_EXPAND':
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [action.rowKey]: action.expanded
        }
      };
    case 'ROW_SELECT':
      return {
        ...state,
        selected: action.selected
      };
    default: {
      return state;
    }
  }
}

export type TableHookInitialState<T = any> = Partial<TableState<T>> & {
  query: TableQuery;
};

export type TableHookState<T = any> = TableState<T> & { query: TableQuery };

export type TableHookOnFetch<T = any> = (table: TableQuery) => Promise<{
  data: T[];
  totalCount: number;
}>;

type TableHookResponse<T, K extends React.Key = React.Key> = [
  Required<
    Pick<
      ITableProps<T, K>,
      | 'onFilterChange'
      | 'onPageChange'
      | 'onSearchChange'
      | 'onSelectChange'
      | 'onSortChange'
      | 'onToggleExpansion'
    >
  > & {
    state: TableHookState<T>;
  },
  TableQueryHookResponse['update']
];

function useTable<T, K extends React.Key = React.Key>(
  initialState: TableHookInitialState<T>,
  onFetch: TableHookOnFetch<T>
): TableHookResponse<T, K> {
  // Query
  const {
    query,
    update,
    onSearchChange,
    onSortChange,
    onFilterChange,
    onPageChange
  } = useTableQuery(initialState.query || {});

  // State
  const [state, dispatch] = useReducer<Reducer<TableState<T>, Action>>(
    tableReducer,
    {
      ...defaultTableState,
      ...initialState
    }
  );

  // Fetch when Query is updated, debounced
  const debouncedQuery = useDebounce<TableQuery>(query, 250);
  const [isLatest, setLatest] = useLatest<ReturnType<
    TableHookOnFetch<T>
  > | null>(null);

  useEffect(() => {
    const fetch = async () => {
      let request: ReturnType<typeof onFetch> | undefined;
      dispatch({ type: 'FETCH_REQUEST' });

      try {
        request = onFetch(debouncedQuery);
        setLatest(request);
        const { data, totalCount } = await request;

        if (isLatest(request)) {
          // If there are no results on this page, but there are results
          // elsewhere, go to page 1. This may happen if this user or some
          // other user deletes the last entry on this page.
          // Perhaps we'd like to go to the last page with results?
          if (data) {
            if (data.length === 0 && totalCount > 0 && query.page !== 1) {
              onPageChange(1, query.pageSize);
            } else {
              dispatch({ type: 'FETCH_SUCCESS', data, totalCount });
            }
          }
        }
      } catch (error) {
        if (
          !axios.isCancel(error) &&
          typeof request !== 'undefined' &&
          isLatest(request)
        ) {
          dispatch({ type: 'FETCH_ERROR' });
        }
      }
    };

    fetch();
  }, [
    debouncedQuery,
    query.page,
    query.pageSize,
    isLatest,
    onFetch,
    onPageChange,
    setLatest
  ]);

  const onSelectChange = useCallback((selected: K[]) => {
    dispatch({ type: 'ROW_SELECT', selected });
  }, []);

  const onToggleExpansion = useCallback(
    (expanded: boolean, _: T, rowKey: K) => {
      dispatch({ type: 'ROW_EXPAND', rowKey, expanded });
    },
    []
  );

  const hookResponse = useMemo<TableHookResponse<T, K>>(
    () => [
      {
        state: { ...state, query },
        onSearchChange,
        onSortChange,
        onFilterChange,
        onPageChange,
        onSelectChange,
        onToggleExpansion
      },
      update
    ],
    [
      query,
      state,
      onSearchChange,
      onSortChange,
      onFilterChange,
      onPageChange,
      onSelectChange,
      onToggleExpansion,
      update
    ]
  );

  return hookResponse;
}

export default useTable;
