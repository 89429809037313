import { isFolderNameExist } from '../isFolderNameExist';
import * as FolderValidationInterfaces from './folderValidation.interfaces';

const validateFolder: FolderValidationInterfaces.IValidateFolder =
  // NOTE: Validation order matters
  selectedFolder =>
    selectedFolder === null ||
    Boolean(selectedFolder.folderGuid) ||
    Boolean((selectedFolder.folderName || '').trim());

const validateUniqueFolder: FolderValidationInterfaces.IValidateUniqueFolder =
  ({ folders, selectedFolder }) => {
    const validations = {
      hasExistingArchivedFolder: false,
      isFolderLengthValid: true,
      isFolderUnique: true
    };

    if (selectedFolder === null || selectedFolder.folderGuid) {
      return validations;
    }

    if (selectedFolder.folderName.length > 255) {
      validations.isFolderLengthValid = false;
    }

    const { hasExistingName, isArchived: isExistingFolderArchived } =
      isFolderNameExist(
        selectedFolder.folderName,
        folders,
        selectedFolder.folderGuid,
        selectedFolder.parentFolderGuid
      );

    if (!hasExistingName) {
      return validations;
    }

    validations.isFolderUnique = false;
    validations.hasExistingArchivedFolder = isExistingFolderArchived;

    return validations;
  };

export const validateFolderInput: FolderValidationInterfaces.IValidateFolderInput =
  (selectedFolder, folders, createError) => {
    let errorPath = '';

    if (!validateFolder(selectedFolder)) {
      errorPath = 'modal.dataRequestModal.fields.transferDestination.required';
    }

    if (!Boolean(errorPath)) {
      const { hasExistingArchivedFolder, isFolderLengthValid, isFolderUnique } =
        validateUniqueFolder({ folders, selectedFolder });

      // HACK: Re-using keys from another component.
      if (!isFolderLengthValid)
        errorPath = 'folder.renameModal.folderName.maxLength';
      else if (!isFolderUnique) {
        errorPath = `modal.dataRequestModal.fields.transferDestination.${
          hasExistingArchivedFolder ? 'archived' : 'exists'
        }`;
      }
    }

    return createError ? createError(errorPath) : errorPath;
  };
