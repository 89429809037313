import { Match } from '../utils/makeTreeSearch';
import { IFile } from './IFile';
import { IFolder } from './IFolder';

export interface FileSearchFilter {
  fileSearchFilterGuid: string;
  fileSearchFilterName: string;
}

export enum DirectoryItemType {
  Folder = 1,
  File
}

export enum DirectoryFilter {
  Folder = '5b11fedd-f1d8-47a7-aa87-8ce1dfd1fec2',
  Image = '52b3ee1c-071f-4d76-8ddb-3fe78b9941ce',
  PDF = '8cf63ff9-8cd7-477c-b404-48a537d4d6b7',
  Presentation = 'b317a1d1-6a39-4fea-b4ca-078a13819801',
  Spreadsheet = '98f9a591-6125-40e2-aced-2bf11190ea75',
  WordDocument = '678a263a-e8cf-4c46-b2b0-405d7eacf081',
  Other = '2370b521-0679-4ace-b404-32d71c3bf9ec'
}

export enum DirectoryItemLinkType {
  TaskAttachment = 1,
  DataRequestAttachment = 2,
  DataRequestUpload = 3,
  LearningAttachment = 4,
  SavingsAttachment = 5,
  FileUpload = 6,
  ProjectLink = 7
}

export interface IDirectoryItemLink {
  // If user does not have access to the engagement associated to link, we null out this info
  engagementGuid: string | null;
  engagementDisplayNameShort: string | null;
  engagementDisplayNameLong: string | null;

  documentGuid: string;

  engagementDocumentLinkGuid: string;

  linkType: DirectoryItemLinkType;
  linkGuid: string | null; // taskGuid, learningGuid, queueItemGuid, savingsSummaryGuid, etc
  name: string | null;
  isOrigin: boolean;
  unlinkedDate: Date | null; // removal of the link to the document

  createDate: Date;
  createdByName: string;
  createdBy: string;
  createdImpersonatingByName: string | null;
  createdImpersonatingByUserGuid: string | null;

  deleteDate: Date | null; // the removal of the linked item itself from the app
}

export interface IDirectoryFile extends IFile {
  engagementGuid: string;
  decisionsEntityId: string | null;
  folderGuid: string | null;
  folderVisibleToUserTypes?: number;
  isDataRequestAdHoc: boolean;
  isDataRequestDeleted: boolean;
  isLocked: boolean;
  linkCount: number;
  linkOrigin: IDirectoryItemLink | null;
  queueItemGuid: string;
  totalLinkCount?: number;
  updatedByName: string;
  uploadedOnBehalfOfMemberName: string | null;
  visibleToUserTypes: number;
}

export interface IDirectoryItem {
  type: DirectoryItemType;
  item: IFolder | IDirectoryFile;
  fileSearchFilterGuid: string;

  // mapped in ApiService
  parentFolder: Match<IFolder> | null;
}
