import moment from 'moment-timezone';
import { logger } from 'services/logger';
import { getLocale, getLocalizedDateFormat, parseDateFormat } from 'utils/i18n';

import { FormikContext } from 'formik';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  IDatepickerProps,
  Datepicker as RyanDatepicker
} from '@ryan/components';

import AmplitudeApiService from '../../services/AmplitudeApiService';
import {
  AmplitudeActionType,
  getAmplitudeLocation
} from '../../utils/amplitudeUtils/amplitudeUtils';

const Datepicker: FunctionComponent<
  IDatepickerProps & {
    amplitudeAdditionalSelector?: string;
    amplitudeEventDetails?: { [key: string]: string };
    isAmplitudeTracking?: boolean;
  }
> = ({
  amplitudeAdditionalSelector,
  amplitudeEventDetails = {},
  isAmplitudeTracking,
  onChange,
  value,
  ...rest
}) => {
  const { i18n, t } = useTranslation();
  const [formattedDate, setFormattedDate] = useState(value);
  const [locale, setLocale] = useState(getLocale(i18n.language).code);
  const [dateFormat, setDateFormat] = useState<string[]>(
    parseDateFormat(i18n.language)
  );

  // store onChange to ref to prevent excessive calls to hook
  const onChangeRef = useRef(onChange);

  // Access Formik context if Datepicker is wrapped in a Formik component. This is being used to
  // reset Datepicker values on forms to prevent potential false "dirtying" of the form.
  const {
    resetForm,
    values: formValues,
    initialValues: formInitialValues
  } = useContext(FormikContext) ?? {};

  // get placeholder text using localized initials for day, month, and year
  const placeholderText = useMemo(
    () =>
      getLocalizedDateFormat(locale || 'en-US', t).toLocaleUpperCase(
        i18n.languages?.slice()
      ),
    [locale, i18n.languages, t]
  );

  // update onChange ref
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // Update current reference to `resetForm` and `formValues` for dependency purposes
  const setResetFormRef = useRef(resetForm);
  const setFormValuesRef = useRef(formValues);
  const setFormInitialValuesRef = useRef(formInitialValues);

  useEffect(() => {
    setResetFormRef.current = resetForm;
    setFormValuesRef.current = formValues;
    setFormInitialValuesRef.current = formInitialValues;
  }, [resetForm, formValues, formInitialValues]);

  /**
   * Do not allow a daily time before 3AM Eastern to handle issues of day
   * display across US timezones.
   *
   * @see standardizeUSDate utility method for more info on issue
   * @todo remove this once we allow users to select times using the datepicker
   */
  useEffect(() => {
    if (value) {
      // Use Eastern timezone as basis for update. If time in Eastern is less
      // than our default minimum of 3AM Eastern, then update to minimum
      // NOTE: toISOString() prototype method has been modified here in
      // `toISOStringWithTimezone()` in case of undesired result
      const easternDate: string = moment(value)
        .tz('America/New_York')
        .format('YYYY-MM-DD');
      const easternTZMin: Date = moment
        .tz(`${easternDate} 03:00`, 'America/New_York')
        .toDate();

      if (value < easternTZMin) {
        logger.debug(
          `updating datetime of ${value} to minimum daily datetime of ${easternTZMin}`
        );
        setFormattedDate(easternTZMin);

        /**
         * Manually trigger onChange event on time change as `react-datepicker`
         * currently does not.
         *
         * @see https://github.com/Hacker0x01/react-datepicker/issues/1182
         * @see https://github.com/Hacker0x01/react-datepicker/issues/1446
         */
        try {
          onChangeRef.current?.(
            /**
             * this event subtitute is definitely hacky, but it doesn't seem
             * this event argument is used currently
             *
             * @todo update this component's onChange handler to omit the event
             * and only pass the value
             */
            {} as any as React.SyntheticEvent,
            easternTZMin
          );

          /**
           * @todo: Test should be added to check for invocation of resetForm in future. Currently, existing downstream tests test the side effects of this
           * invocation and serve as a proxy for a direct test. Due to the complicated nature of testing this in conjunction with Formik, these indirect tests
           * are favored at the moment.
           */
          if (
            setFormValuesRef.current &&
            !!setFormInitialValuesRef.current[rest.name as string]
          ) {
            setResetFormRef.current({
              values: {
                ...(setFormValuesRef.current as any),
                [rest.name as string]: easternTZMin
              }
            });
          }
        } catch (error: any) {
          logger.warn(
            `an error occurred when manually calling the Datepicker change handler - ${error?.message}`
          );
        }
      } else {
        setFormattedDate(value);
      }
    }
  }, [value, rest.name]);

  // update locale and date format on language change
  useEffect(() => {
    setLocale(getLocale(i18n.language).code);
    setDateFormat(parseDateFormat(i18n.language));
  }, [i18n.language]);

  const handleChange = (
    event: React.SyntheticEvent<any, Event> | undefined,
    value: Date | null
  ) => {
    const amplitudeLocation = getAmplitudeLocation();

    if (isAmplitudeTracking && amplitudeLocation === 'dashboard') {
      const AMPLITUDE_COMPONENT_NAME = `${
        amplitudeAdditionalSelector ? `${amplitudeAdditionalSelector}-` : ''
      }date-range-selector`;
      AmplitudeApiService.logEvent(
        `${AmplitudeActionType.CLICK}-${AMPLITUDE_COMPONENT_NAME}-${amplitudeLocation}`,
        amplitudeEventDetails
      );
    }

    if (!onChange) {
      return;
    }
    onChange(event, value);
  };

  return (
    <RyanDatepicker
      dateFormat={dateFormat}
      locale={locale}
      onChange={handleChange}
      placeholderText={placeholderText}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          // force popper to stay in viewport (even when input is scrolled out of view)
          escapeWithReference: false,
          boundariesElement: 'viewport'
        }
      }}
      value={formattedDate}
      {...rest}
    />
  );
};

export default Datepicker;
