import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Tooltip } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../../hooks';
import { EntityType, Permission } from '../../../../interfaces';
import {
  TWorklistDataRequestItem,
  TWorklistItem
} from '../../../../routes/Worklist/WorklistMine/WorklistMine.interfaces';
import { formatDate } from '../../../../utils/formatDate';
import StatusIcon from '../../../StatusIcon/StatusIcon';
import * as ColumnUtils from '../columns.utils';
import ActionsByEntityType from './ActionsByEntityType/ActionsByEntityType';
import { WorklistColumnIdEnums } from './useWorkListColumns.enums';
import {
  IRenderColumn,
  TUseWorkListColumnsProps
} from './useWorkListColumns.interfaces';
import * as UseWorkListColumnUtils from './useWorkListColumns.utils';

const useWorkListColumns = ({
  onDataRequestCommentClick,
  onMarkDataDelivered,
  onMarkTaskInProgress,
  onSetWorklistItemToState,
  onTaskCommentClick,
  typeFilters,
  statusFilters
}: TUseWorkListColumnsProps) => {
  const ROOT_TO_TEXT = 'table.columns.worklist';

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const {
    permissionService,
    user: { profile: activeUserProfile }
  } = useUser();

  return useMemo(() => {
    const renderAction: IRenderColumn<TWorklistItem> = worklistItem => {
      const {
        createdBy,
        entityTypeId,
        isEngagementReadOnly,
        queueItemGuid,
        statusId
      } = worklistItem;
      const { codeAssistance, isMarkedAsDataDelivered, typeGuid } =
        worklistItem as TWorklistDataRequestItem;

      return (
        <ActionsByEntityType
          commonEntityTypeProps={{ statusId }}
          dataRequestTypeProps={{
            codeAssistance,
            isMarkedAsDataDelivered,
            onMarkDataDelivered: () => {
              onMarkDataDelivered(worklistItem);
            },
            queueItemGuid,
            typeGuid
          }}
          entityTypeId={entityTypeId}
          isEngagementReadOnly={isEngagementReadOnly}
          onSetWorklistItemToState={stateAction => {
            onSetWorklistItemToState(stateAction, worklistItem);
          }}
          taskTypeProps={{
            assignedToUserType: worklistItem.assignedTo.userType,
            createdByUserType: createdBy.userType,
            onMarkTaskInProgress: () => {
              onMarkTaskInProgress(worklistItem);
            }
          }}
        />
      );
    };

    const renderAssignedTo: IRenderColumn<TWorklistItem> = ({ assignedTo }) => (
      <span>
        {assignedTo.userGuid === activeUserProfile.memberGuid
          ? getTextToDisplay(`${ROOT_TO_TEXT}.assignedToYou`)
          : assignedTo.name}
      </span>
    );

    const renderComment: IRenderColumn<TWorklistItem> = worklistItem => {
      const { commentCount, entityTypeId, isEngagementReadOnly } = worklistItem;

      const isReadOnlyByState = isAppReadOnly || isEngagementReadOnly;
      const getCommentArgs = { commentCount, isReadOnlyByState };

      let commentProps: any;

      switch (entityTypeId) {
        case EntityType.DataRequest:
          commentProps = {
            ...ColumnUtils.getCommentProps({
              ...getCommentArgs,
              isReadOnlyByPermissions: !permissionService.hasPermission(
                Permission.DataRequestsContribute
              )
            }),
            onClick: () => {
              onDataRequestCommentClick(worklistItem);
            }
          };
          break;
        case EntityType.Task:
          commentProps = {
            ...ColumnUtils.getCommentProps({
              ...getCommentArgs,
              isReadOnlyByPermissions: !permissionService.hasPermission(
                Permission.TasksContribute
              )
            }),
            onClick: () => {
              onTaskCommentClick(worklistItem);
            }
          };
          break;
        default:
      }

      if (!commentProps) {
        return <></>;
      }

      return (
        <Tooltip
          {...commentProps.tooltipProps}
          content={getTextToDisplay(`${ROOT_TO_TEXT}.${commentProps.path}`, {
            count: commentCount
          })}
          renderTarget={({ open, ref, ...props }) => (
            <Button
              {...props}
              {...commentProps.buttonProps}
              aria-expanded={open}
              icon={commentProps.icon}
              innerRef={ref}
              onClick={commentProps.onClick}
            />
          )}
        />
      );
    };

    const renderDueDate: IRenderColumn<TWorklistItem> = ({ dueDate }) => (
      <span className="column-due-date">{formatDate(dueDate)}</span>
    );

    const renderProject: IRenderColumn<TWorklistItem> = ({
      engagementDisplayNameShort,
      engagementGuid,
      engagementId
    }) => (
      <Link
        className="column-project table-link"
        to={`/app/project/${engagementGuid}`}
      >
        <span>{engagementDisplayNameShort}</span>
        {permissionService.isRyan() && <small>{engagementId}</small>}
      </Link>
    );

    const renderStatus: IRenderColumn<TWorklistItem> = worklistItem => {
      const { statusId, entityTypeId } = worklistItem;
      const { isMarkedAsDataDelivered, subStatusId } =
        worklistItem as TWorklistDataRequestItem;

      return (
        <div className="column-status">
          <StatusIcon
            {...(entityTypeId === EntityType.DataRequest &&
              isMarkedAsDataDelivered && { subStatus: subStatusId })}
            status={statusId}
          />
        </div>
      );
    };

    const renderTitle: IRenderColumn<TWorklistItem> = ({
      accountName,
      title
    }) => (
      <div className="column-title">
        <span>{title}</span>
        <small>{accountName}</small>
      </div>
    );

    const renderType: IRenderColumn<TWorklistItem> = worklistItem => {
      const { entityTypeId } = worklistItem;
      const { subTypeName } = worklistItem as TWorklistDataRequestItem;

      return (
        <div className="column-type">
          <span>
            {getTextToDisplay(
              `${ROOT_TO_TEXT}.entityTypes.${
                Object.values(EntityType)[entityTypeId - 1]
              }`
            )}
          </span>
          {entityTypeId === EntityType.DataRequest && (
            <small>{getTextToDisplay(subTypeName)}</small>
          )}
        </div>
      );
    };

    const columnsToRenderMap = {
      [WorklistColumnIdEnums.ACTION]: renderAction,
      [WorklistColumnIdEnums.ASSIGNED_TO]: renderAssignedTo,
      [WorklistColumnIdEnums.COMMENT]: renderComment,
      [WorklistColumnIdEnums.DUE_DATE]: renderDueDate,
      [WorklistColumnIdEnums.PROJECT]: renderProject,
      [WorklistColumnIdEnums.STATUS]: renderStatus,
      [WorklistColumnIdEnums.TITLE]: renderTitle,
      [WorklistColumnIdEnums.TYPE]: renderType
    };

    return ColumnUtils.columnTemplateMapper({
      columnTemplate: UseWorkListColumnUtils.getColumnTemplate({
        getCheckboxLabel: key => getTextToDisplay(`${ROOT_TO_TEXT}.${key}`),
        statusFilters,
        typeFilters
      }),
      columnsToRenderMap,
      getTextToDisplayCallback: getTextToDisplay,
      rootToText: ROOT_TO_TEXT
    });
  }, [
    activeUserProfile,
    getTextToDisplay,
    isAppReadOnly,
    onDataRequestCommentClick,
    onMarkDataDelivered,
    onMarkTaskInProgress,
    onSetWorklistItemToState,
    onTaskCommentClick,
    statusFilters,
    typeFilters,
    permissionService
  ]);
};

export default useWorkListColumns;
