import React, { useContext } from 'react';
import { IAccount, ICustomViewSummary } from 'interfaces';
import { withContext } from 'utils/withContext';
import { CheckedAccounts, ICheckedAccounts } from './CheckedAccounts';

export interface ISwitcherContext {
  openDesktop: boolean;
  openMobile: boolean;
  onToggleDesktop: (open?: boolean) => void;
  onToggleMobile: () => void;

  // Search is shared across account selection and filter pane tabs
  searchQuery: string;
  onSearchChange: (searchQuery: string) => void;

  // Companies (Basic)
  expanded: { [guid: string]: boolean };
  onAccountToggle: (account: IAccount) => void;
  onAccountSelect: (account: IAccount, includingSubsidiaries: boolean) => void;

  // Filter Pane
  // Tab - Companies (Advanced)
  // Tab - Custom Views
  openFilterPane: boolean;
  filterTab: 'companies' | 'custom-views';
  filterExecutiveAccess: boolean;
  filterCompanies: ICheckedAccounts;
  filterCustomView: ICustomViewSummary | null;
  filterApplyLoading: Promise<any> | null;
  onFilterPaneToggle: () => void;
  onFilterPaneReset: () => void;
  onFilterTabSelect: (filterTab: 'companies' | 'custom-views') => void;
  onFilterCompanyToggle: (
    accountGuid: string,
    includingSubsidiaries: boolean
  ) => void;
  onFilterCompanyToggleAll: () => void;
  onFilterExecutiveToggle: () => void;
  onFilterCustomViewSelect: (view: ICustomViewSummary) => void;
  onFilterCustomViewApply: () => void;
  onFilterCustomViewDelete: (view: ICustomViewSummary) => void;
}

const defaultSwitcherContext = {
  openDesktop: false,
  openMobile: false,
  searchQuery: '',
  expanded: {},
  onToggleDesktop: () => {},
  onToggleMobile: () => {},
  onSearchChange: () => {},
  onAccountToggle: () => {},
  onAccountSelect: () => {},

  openFilterPane: false,
  filterTab: 'companies' as const,
  filterExecutiveAccess: false,
  filterCompanies: CheckedAccounts([], false),
  filterCustomView: null,
  filterApplyLoading: null,
  onFilterPaneToggle: () => {},
  onFilterPaneReset: () => {},
  onFilterTabSelect: () => {},
  onFilterCompanyToggle: () => {},
  onFilterCompanyToggleAll: () => {},
  onFilterExecutiveToggle: () => {},
  onFilterCustomViewSelect: () => {},
  onFilterCustomViewApply: () => {},
  onFilterCustomViewDelete: () => {}
};

const SwitcherContext = React.createContext<ISwitcherContext>(
  defaultSwitcherContext
);

export default SwitcherContext;

/**
 * HOC to wrap a component to receive WithUser.
 */
export type WithSwitcher = ISwitcherContext;
export const withSwitcher = withContext<ISwitcherContext>(
  SwitcherContext,
  'withSwitcher'
);

export const useSwitcher = () => useContext(SwitcherContext);
