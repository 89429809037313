import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ITextInputProps, TextInput } from '@ryan/components';

import './SearchInput.scss';

export interface ISearchInputProps
  extends Omit<ITextInputProps, 'icon' | 'onIconClick' | 'onChange'> {
  loading: boolean;
  onChange: (value: string) => void;
}

/**
 * Renders a search field with support for calling a callback on value
 * change, and for clearing a populated value.
 */
const SearchInput: React.FC<ISearchInputProps> = ({
  loading,
  value,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();

  /**
   * Callback called on text input change.
   */
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  /**
   * Callback called on text clear.
   */
  const handleClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onChange('');
    },
    [onChange]
  );

  return (
    <div
      className={classnames('search-input', {
        'search-input--loading': loading && value
      })}
    >
      <TextInput
        autoComplete="off"
        icon={loading && value ? 'loading' : value ? 'delete' : 'search'}
        onChange={handleChange}
        onIconClick={!loading && value ? handleClear : undefined}
        placeholder={t('Search')}
        type="search"
        value={value}
        {...rest}
      />
    </div>
  );
};

export default SearchInput;
