import Modal from 'components/Modal';
import { IModalProps, IUser } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

interface ICancelUserDeactivationModalProps
  extends IModalProps,
    WithTranslation {
  user: IUser;
  onCanceUserDeactivationRequest: () => void;
}

interface ICancelUserDeactivationModalState {
  loading: Promise<any> | null;
}

class CancelUserDeactivationModal extends Component<
  ICancelUserDeactivationModalProps,
  ICancelUserDeactivationModalState
> {
  constructor(props: ICancelUserDeactivationModalProps) {
    super(props);
    this.state = {
      loading: null
    };
  }

  handleConfirm = async () => {
    const { t, user, onCanceUserDeactivationRequest, onClose } = this.props;
    const userName = user.fullName;
    this.setState({
      loading: ApiService.cancelUserDeactivation(user.userGuid)
        .then(() => {
          pushToast({
            type: 'success',
            title: t('cancelDeactivationModal.successToast.title'),
            content: t(
              'cancelDeactivationModal.successToast.cancelDeactivation',
              {
                userName
              }
            )
          });
          onCanceUserDeactivationRequest();
        })
        .catch(() => {
          pushToast({
            type: 'error',
            title: t('cancelDeactivationModal.errorToast.cancelDeactivation', {
              userName
            })
          });
          onClose();
        })
    });
  };

  handleCancel = () => {
    this.props.onClose();
  };

  handleClose = () => {
    const { onClose } = this.props;
    const { loading } = this.state;
    if (!loading) {
      onClose();
    }
  };

  render() {
    const { t, open, user } = this.props;
    const { loading } = this.state;
    const userName = user.fullName;
    return (
      <Modal
        onClose={this.handleClose}
        open={open}
        title={t('cancelDeactivationModal.title')}
      >
        <p>
          <Trans i18nKey="cancelDeactivationModal.confirmation">
            <b />
            {{ userName }}
          </Trans>
        </p>
        <ButtonGroup>
          <Button
            onClick={this.handleCancel}
            text={t('cancelDeactivationModal.cancel')}
          />
          <Button
            loading={loading}
            negative
            onClick={this.handleConfirm}
            text={t('cancelDeactivationModal.yes')}
            type="submit"
          />
        </ButtonGroup>
      </Modal>
    );
  }
}

export default withTranslation()(CancelUserDeactivationModal);
