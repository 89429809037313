import { EntityType, IRecentActivity } from 'interfaces';
import { FilterType } from './FilterType';

function fromWhitelist(entityTypes: EntityType[], whitelist: EntityType[]) {
  return entityTypes
    .filter(entityType => whitelist.includes(entityType))
    .join(',');
}

export default function getActivityParams(
  entityTypes: EntityType[],
  filterType: FilterType,
  userGuid: string
): IRecentActivity {
  switch (filterType) {
    case FilterType.All:
    default:
      return {
        entityTypes: entityTypes.join(',')
      };

    case FilterType.AssignedToMe:
      return {
        entityTypes: fromWhitelist(entityTypes, [
          EntityType.DataRequest,
          EntityType.Task
        ]),
        assignedToUserGuid: userGuid
      };

    case FilterType.DataRequests:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.DataRequest])
      };

    case FilterType.Milestones:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.Milestone])
      };

    case FilterType.SavingsSummary:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.SavingsSummary])
      };

    case FilterType.Tasks:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.Task])
      };

    case FilterType.Files:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.File])
      };

    case FilterType.Contracts:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.Contract])
      };

    case FilterType.Invoices:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.Invoice])
      };

    case FilterType.Learnings:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.Learning])
      };

    case FilterType.Team:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.Team])
      };

    case FilterType.AccessControl:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.AccessControl])
      };

    case FilterType.Projects:
      return {
        entityTypes: fromWhitelist(entityTypes, [EntityType.Project])
      };

    case FilterType.Edits:
      return {
        entityTypes: entityTypes.join(','),
        filterType
      };

    case FilterType.Comments:
      return {
        entityTypes: entityTypes.join(','),
        filterType
      };

    case FilterType.Deleted:
      return {
        entityTypes: entityTypes.join(','),
        filterType
      };
  }
}
