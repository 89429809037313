import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, Card, EButtonSizes, EButtonVariant } from '@ryan/components';

import DataRequestStatusSummaryCard from '../../../components/DataRequestStatusSummaryCard/DataRequestStatusSummaryCard';
import Empty from '../../../components/Empty';
import Milestones from '../../../components/Milestones/Milestones';
import DataRequestModal from '../../../components/Modal/DataRequestModal/DataRequestModal';
import ActivityEvents from '../../../components/RecentActivityCard/ActivityEvents';
import RecentActivityCard from '../../../components/RecentActivityCard/RecentActivityCard';
import ReportingCard from '../../../components/ReportingCard/ReportingCard';
import SavingsSummaryCard from '../../../components/SavingsSummaryCard/SavingsSummaryCard';
import TaskDataVizCard from '../../../components/TaskDataViz/TaskDataVizCard';
import UserInfoCard from '../../../components/UserInfoCard/UserInfoCard';
import UnsavedChangesContext from '../../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  IDataRequestV2,
  IDataRequestsCountByStatus,
  IEngagement,
  IUser,
  Permission
} from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import { ProjectEditTypeEnums } from '../../../utils/enums/ProjectEditTypeEnums';

import './Overview.scss';

interface IOverviewProps
  extends WithUser,
    WithTranslation,
    RouteComponentProps {
  engagement: IEngagement;
  onEditProjectDetails: (editType: ProjectEditTypeEnums) => () => void;
  onEngagementUpdate: () => void;
}

interface IOverviewState {
  dataRequests: IDataRequestsCountByStatus;
  openDataRequestModal: boolean;

  engagementManager: IUser | null;
  engagementPrincipal: IUser | null;
  hideSavings: boolean;
}

export class Overview extends Component<IOverviewProps, IOverviewState> {
  readonly state: IOverviewState = {
    dataRequests: {
      toDo: 0,
      inProgress: 0,
      complete: 0
    },
    openDataRequestModal: false,
    engagementManager: null,
    engagementPrincipal: null,
    hideSavings: false
  };

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  componentDidUpdate({ engagement: prevEngagement }: IOverviewProps) {
    const { engagement } = this.props;
    if (engagement !== prevEngagement) {
      this.setState({
        hideSavings: false
      });
    }
  }

  getData() {
    this.getDataRequestStatusCount();
    this.fetchUsers();
  }

  async getDataRequestStatusCount() {
    const {
      engagement: { engagementGuid },
      permissionService: ps
    } = this.props;

    if (ps.hasPermission(Permission.DataRequestsView)) {
      try {
        const response = await ApiService.getEngagementDataRequestCount(
          engagementGuid
        );
        this.setState({ dataRequests: response.data });
      } catch {
        // ...
      }
    }
  }

  fetchUsers() {
    const { engagement } = this.props;

    // Fetch EM
    if (engagement.engagementManagerGuid) {
      ApiService.getUser(engagement.engagementManagerGuid).then(response => {
        this.setState({ engagementManager: response.data });
      });
    }

    // Fetch EP
    if (engagement.engagementPrincipalGuid) {
      ApiService.getUser(engagement.engagementPrincipalGuid).then(response => {
        this.setState({ engagementPrincipal: response.data });
      });
    }
  }

  handleCreateDataRequestClose = (dataRequest?: IDataRequestV2) => {
    if (dataRequest) {
      ActivityEvents.emit();
      this.getDataRequestStatusCount();
    }
    this.setState({ openDataRequestModal: false });
  };

  hideSavingsEmpty = () => {
    const { permissionService: ps } = this.props;
    if (ps.isClient()) {
      this.setState({ hideSavings: true });
    }
  };

  componentDidMount() {
    this.getData();
  }

  onCreateClick = () => {
    const { permissionService } = this.props;
    const { isUnsavedChanges } = this.context;

    if (isUnsavedChanges) {
      if (permissionService.hasPermission(Permission.DataRequestsEdit)) {
        this.setState({ openDataRequestModal: true });
        return;
      }
    }

    if (permissionService.hasPermission(Permission.DataRequestsEdit)) {
      this.setState({ openDataRequestModal: true });
    }
  };

  render() {
    const {
      engagement,
      isAppReadOnly,
      onEditProjectDetails,
      onEngagementUpdate,
      permissionService,
      t: getTextToDisplay
    } = this.props;
    const {
      dataRequests,
      openDataRequestModal,
      engagementManager,
      engagementPrincipal,
      hideSavings
    } = this.state;
    const isReadOnly =
      engagement.isUserGhosted || engagement.isReadOnly || isAppReadOnly;

    return (
      <div className="project-overview">
        <div className="row">
          <div className="col-12 col-lg-8">
            {!permissionService.isThirdParty() && (
              <Card
                className="project-overview__details"
                title={getTextToDisplay('Project Highlights')}
              >
                {permissionService.hasPermission(Permission.TimelinesEdit) && (
                  <Button
                    disabled={isReadOnly}
                    icon="pencil"
                    onClick={onEditProjectDetails(
                      ProjectEditTypeEnums.HIGHLIGHTS
                    )}
                    size={EButtonSizes.SMALL}
                    text={getTextToDisplay('Edit')}
                    variant={EButtonVariant.TEXT}
                  />
                )}
                {!engagement.projectDetails && (
                  <Empty icon="project">
                    {getTextToDisplay('projectDetails.highlights.empty')}
                  </Empty>
                )}
                {engagement.projectDetails && (
                  <p className="white-space-pre-line">
                    {engagement.projectDetails}
                  </p>
                )}
              </Card>
            )}
            {permissionService.hasPermission(Permission.ActivityRead) && (
              <RecentActivityCard engagementGuid={engagement.engagementGuid} />
            )}
            {permissionService.hasPermission(Permission.TimelinesView) && (
              <Milestones
                engagement={engagement}
                onEmptyMilestoneEditProjectDetails={onEditProjectDetails(
                  ProjectEditTypeEnums.GENERAL
                )}
                onEngagementUpdated={onEngagementUpdate}
              />
            )}
            <div className="row">
              {engagementPrincipal && (
                <div className="col-12 col-md-6 project-overview__user-container">
                  <UserInfoCard
                    cardTitle={getTextToDisplay('Engagement Principal')}
                    user={engagementPrincipal}
                  />
                </div>
              )}
              {engagementManager && (
                <div className="col-12 col-md-6 project-overview__user-container">
                  <UserInfoCard
                    cardTitle={getTextToDisplay('Engagement Manager')}
                    user={engagementManager}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            {permissionService.hasPermission(Permission.ReportsView) && (
              <ReportingCard engagements={[engagement]} />
            )}
            {permissionService.hasPermission(Permission.SavingsSummaryView) &&
              !hideSavings && (
                <SavingsSummaryCard
                  engagement={engagement}
                  onSavingsEmpty={this.hideSavingsEmpty}
                />
              )}
            {permissionService.hasPermission(Permission.DataRequestsView) && (
              <DataRequestStatusSummaryCard
                engagementGuid={engagement.engagementGuid}
                isEngagementReadOnly={isReadOnly}
                onCreateClick={() => {
                  this.onCreateClick();
                }}
                values={dataRequests}
              />
            )}
            {permissionService.hasPermission(Permission.TasksView) && (
              <TaskDataVizCard
                engagementGuid={engagement.engagementGuid}
                isEngagementReadOnly={isReadOnly}
              />
            )}
          </div>
        </div>

        {openDataRequestModal &&
          permissionService.hasPermission(Permission.DataRequestsEdit) && (
            <DataRequestModal
              engagementGuid={
                engagement ? engagement.engagementGuid : undefined
              }
              onClose={this.handleCreateDataRequestClose}
            />
          )}
      </div>
    );
  }
}

export default withTranslation()(withUser(withRouter(Overview)));
