const WINDOW_DOMAIN: string = window.location.host;
const COOKIE_DOMAIN: string = WINDOW_DOMAIN.substring(
  WINDOW_DOMAIN.indexOf('.')
);
const COOKIE_PATH = '/';
const COOKIE_BASE = `secure;domain=${COOKIE_DOMAIN};path=${COOKIE_PATH}`;

/**
 * Gets the value of a cookie by name.
 */
export function getCookie(name: string) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match?.[2] || undefined;
}

/**
 * Sets a secure (https only) session cookie (expires when page is closed).
 */
export function setCookie(name: string, value: string) {
  //console.log('setting cookie value: ', name, ' to: ', value);
  document.cookie = `${name}=${value || ''};${COOKIE_BASE}`;
  //console.log('cookie is: ', document.cookie);
}

/**
 * Expires cookie immediately.
 */
export function eraseCookie(name: string) {
  //console.log('destroying cookie, ', name);
  document.cookie = `${name}=;${COOKIE_BASE};max-age=-99999999;`;
  //console.log('cookie is: ', document.cookie);
}
