import logo from 'images/ryan-logo.svg';

import classnames from 'classnames';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react';

import { Icon } from '@ryan/components';

import './Screen.scss';

enum Step {
  Hidden,
  Revealing,
  Revealed
}

export interface IScreenProps {
  loading?: boolean;
  reveal?: boolean;
  children?: ReactNode;
}

/**
 * Full-screen display that masks child elements during load.
 */
const Screen: FunctionComponent<IScreenProps> = ({
  loading,
  reveal,
  children
}) => {
  const [step, setStep] = useState<Step>(Step.Hidden);

  useEffect(() => {
    if (reveal && step === Step.Hidden) {
      setStep(Step.Revealing);
      window.setTimeout(() => {
        setStep(Step.Revealed);
      }, 250);
    }
  }, [reveal, step]);

  return (
    <div
      className={classnames({
        screen: true,
        'screen--revealing': step === Step.Revealing,
        'screen--revealed': step === Step.Revealed
      })}
    >
      {children}
      <div
        aria-busy={step !== Step.Revealed ? 'true' : 'false'}
        aria-label="Loading"
        className="screen__screen"
        role="status"
      >
        <div aria-hidden className="screen__screen-content">
          <img alt="Ryan logo" src={logo} />
          <br />
          {loading !== false && (
            <Icon className="loading-spin" name="loading" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Screen;
