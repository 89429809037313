import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IModalProps as IModalPropsBase,
  Modal as RyanModal
} from '@ryan/components';

export type IModalProps = IModalPropsBase;

const Modal: FC<IModalProps> = ({ closeText, ...props }) => {
  const { t } = useTranslation();

  return (
    <RyanModal
      closeText={closeText ?? t('Close')}
      // by default, DXP modals should not close when the overlay is clicked
      preventCloseOnClickOutside
      {...props}
    />
  );
};

export default Modal;
