import { TFunction } from 'i18next';

export default function getCommentButtonProps(
  t: TFunction,
  hasPermission: boolean,
  entityWithComments: {
    isEngagementReadOnly: boolean;
    commentsCount: number;
  } | null,
  isAppReadOnly: boolean
) {
  if (entityWithComments) {
    const { isEngagementReadOnly, commentsCount: count } = entityWithComments;

    return {
      text: t(
        !hasPermission || isEngagementReadOnly || isAppReadOnly
          ? 'View Comments (n)'
          : count === 0
          ? 'Add a Comment'
          : 'Comments (n)',
        { count }
      ),
      icon: count === 0 ? 'comment' : 'comment-alert'
    };
  }

  return {
    text: t('View Comments'),
    icon: 'comment',
    disabled: true
  };
}
