import { useStateMounted } from 'hooks';
import debounce from 'lodash.debounce';
import ApiService from 'services/ApiService';

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, IAutocompleteProps } from '@ryan/components';

type IServiceAutocompleteHistoricalSavingsProps = Omit<
  IAutocompleteProps<string>,
  | 'autoComplete'
  | 'loading'
  | 'onOptionsClearRequested'
  | 'onOptionsFetchRequested'
  | 'options'
  | 'shouldRenderSuggestions'
>;

function shouldRenderSuggestions() {
  return true;
}

function ServiceAutocompleteHistoricalSavings(
  props: IServiceAutocompleteHistoricalSavingsProps
) {
  const { t } = useTranslation();
  const [state, setState] = useStateMounted<{
    options: string[];
    loading: boolean;
  }>({
    options: [],
    loading: false
  });

  const onOptionsFetchRequested = useMemo(
    () =>
      debounce((query: string) => {
        setState(state => ({ ...state, loading: true }));
        ApiService.autocompleteHistoricalSavingsServices(query).then(
          response => {
            setState({ options: response.data, loading: false });
          }
        );
      }, 300),
    [setState]
  );

  const onOptionsClearRequested = useCallback(() => {
    setState(state => ({ ...state, options: [] }));
  }, [setState]);

  return (
    <Autocomplete
      autoComplete="off"
      label={t('Service Type')}
      loading={state.loading}
      onOptionsClearRequested={onOptionsClearRequested}
      onOptionsFetchRequested={onOptionsFetchRequested}
      options={state.options}
      shouldRenderSuggestions={shouldRenderSuggestions}
      spellCheck="false"
      type="search"
      {...props}
    />
  );
}

export default ServiceAutocompleteHistoricalSavings;
