import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, IOption } from '@ryan/components';
import { LanguageType, LanguageTypes } from 'interfaces';

/**
 * @todo export `IDropdownProps` from @ryan/components and support standard
 * select element attributes
 */
export type ISelectLanguageProps = Omit<
  React.ComponentProps<typeof Dropdown>,
  'options'
>;

const SelectLanguage: React.FC<ISelectLanguageProps> = props => {
  const { t } = useTranslation();

  // options list for user language dropdown
  const optionsLanguage: IOption[] = useMemo(
    () => [
      {
        value: '',
        label: t('newUserModal.form.placeholder'),
        disabled: true
      },
      ...LanguageTypes.map((lan: LanguageType) => ({
        value: lan.code,
        label: t(`languages.${lan.code}`)
      }))
    ],
    [t]
  );

  return (
    <Dropdown
      label={t('newUserModal.form.languagePreference')}
      options={optionsLanguage}
      {...props}
    />
  );
};

export default SelectLanguage;
