import {
  DirectoryItemType,
  IDirectoryFile,
  IDirectoryItem,
  IFolder
} from 'interfaces';
import { getDirectoryItemId } from 'utils/getDirectoryItemId';

export interface FileBatch {
  readonly files: IDirectoryFile[];
  readonly folders: IFolder[];
  readonly filesCount: number;
  numberOfLinkedItems?: number;
}

export function getFileBatch(files: IDirectoryFile[], folders: IFolder[]) {
  return {
    files,
    folders,
    filesCount: files.length + folders.reduce((t, f) => t + f.totalFileCount, 0)
  };
}

export function getFileBatchFromSelection(
  directoryItems: IDirectoryItem[],
  selectedGuids: string[]
): FileBatch {
  const files: IDirectoryFile[] = [];
  const folders: IFolder[] = [];
  for (let i = 0; i < selectedGuids.length; i++) {
    const item = directoryItems.find(
      item => getDirectoryItemId(item) === selectedGuids[i]
    );
    if (item) {
      if (item.type === DirectoryItemType.File) {
        files.push(item.item as IDirectoryFile);
      } else {
        folders.push(item.item as IFolder);
      }
    }
  }

  return getFileBatch(files, folders);
}

export function getFileBatchFromSelectionByStatus(
  directoryItems: IDirectoryItem[],
  selectedGuids: string[],
  active: boolean
): FileBatch {
  const fileBatch = getFileBatchFromSelection(directoryItems, selectedGuids);
  let files: IDirectoryFile[] = [];
  let folders: IFolder[] = [];

  if (active) {
    files = fileBatch.files.filter(file => !file.archiveDate);
    folders = fileBatch.folders.filter(folder => !folder.archiveDate);
  } else {
    files = fileBatch.files.filter(file => !!file.archiveDate);
    folders = fileBatch.folders.filter(folder => !!folder.archiveDate);
  }

  return getFileBatch(files, folders);
}
