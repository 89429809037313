import Datepicker from 'components/Datepicker';

import classNames from 'classnames';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Radio, RadioGroup } from '@ryan/components';

import './NowOrLater.scss';

enum Time {
  NOW = 'NOW',
  LATER = 'LATER'
}

type IDatepickerProps = React.ComponentProps<typeof Datepicker>;

export interface INowOrLaterProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'onChange'> {
  /** Date to be prefilled into the "later" date field. */
  laterDate: Date | null;

  /** Error feedback to display for an invalid date in the date field. */
  laterErrorText?: IDatepickerProps['feedback'];

  /** Additional information to be displayed with the date field. */
  laterHelperText?: IDatepickerProps['helperText'];

  /** Label for the "later" option  */
  laterLabel: string;

  /**
   * Minimum allowed date for the later date field. The "now" option will be
   * disabled if this date is tomorrow or later.
   */
  laterMinDate?: IDatepickerProps['minDate'];

  /** Selects the "now" option if `true`. Otherwise selects "later". */
  now: boolean;

  /** Label for the "now" option. */
  nowLabel: string;

  /** Callback fired on option selection change. */
  onChange: (now: boolean, laterDate: Date | null) => void;
}

/**
 * Displays a radio group allowing the user to select between now or a date in
 * the future.
 */
const NowOrLater: React.FC<INowOrLaterProps> = ({
  className,
  laterDate,
  laterErrorText,
  laterHelperText,
  laterMinDate,
  laterLabel,
  now,
  nowLabel,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const isInitialRenderRef = useRef(true);
  const startOfTodayRef = useRef(startOfDay(new Date()));
  const startOfTomorrowRef = useRef(addDays(startOfTodayRef.current, 1));

  // The minimum allowed date for the "later" date field. If `minDate` is not in
  // the future then default to `tomorrow`.
  const minDateStart: Date = useMemo(() => {
    const minStart = laterMinDate && startOfDay(laterMinDate);
    return minStart && minStart > startOfTomorrowRef.current
      ? minStart
      : startOfTomorrowRef.current;
  }, [laterMinDate]);

  // disable "now" if minDate is defined and is tomorrow or later; never disable
  // now if minDate is not defined
  const disableNow: boolean =
    !!laterMinDate && startOfDay(laterMinDate) > startOfTodayRef.current;

  // trigger update of `laterDate` on mount if date is invalid
  // ie. `laterDate` is before the minimum allowed date
  // NOTE: only update laterDate if undefined on initial render so that field
  // error text will display if the user manually removes the text post-render
  useEffect(() => {
    if (
      (!laterDate && isInitialRenderRef.current) ||
      (laterDate && startOfDay(laterDate) < minDateStart)
    ) {
      onChange(now, minDateStart);
    }
  }, [laterDate, now, minDateStart, onChange]);

  // toggle initial render flag
  useEffect(() => {
    isInitialRenderRef.current = false;
  }, []);

  return (
    <div className={classNames('now-or-later', className)} {...rest}>
      <RadioGroup
        onChange={(_, value) => onChange(value === Time.NOW, laterDate)}
        value={now ? Time.NOW : Time.LATER}
      >
        <Radio disabled={disableNow} label={nowLabel} value={Time.NOW} />
        <Radio label={laterLabel} value={Time.LATER} />
      </RadioGroup>

      {!now && (
        <div className="now-or-later__datepicker">
          <Datepicker
            feedback={laterErrorText || t('Date', { context: 'required' })}
            helperText={laterHelperText}
            invalid={laterDate === null}
            label={t('Date')}
            minDate={minDateStart}
            onChange={(_e: unknown, value: Date | null) => onChange(now, value)}
            value={laterDate}
          />
        </div>
      )}
    </div>
  );
};

export default NowOrLater;
