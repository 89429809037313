import { IFolderTree } from '../interfaces';
import makeTreeSearch from './makeTreeSearch';

const folderTreeSearch = makeTreeSearch<IFolderTree>(f => f.childFolders);

export const isFolderNameExist = (
  folderName: string,
  folders: IFolderTree[] | null,
  currentFolderGuid?: string | null,
  parentFolderGuid?: string | null
): { hasExistingName: boolean; isArchived: boolean } => {
  if (!folders) return { hasExistingName: false, isArchived: false };

  const normalizedFolderName = folderName.trim().toLowerCase();

  const result = folderTreeSearch(folders, folder => {
    return (
      folder.parentFolderGuid === parentFolderGuid &&
      folder.folderGuid !== currentFolderGuid &&
      folder.folderName.trim().toLowerCase() === normalizedFolderName
    );
  });

  if (result) {
    return {
      hasExistingName: true,
      isArchived: !!result.element.archiveDate
    };
  }

  return { hasExistingName: false, isArchived: false };
};
