import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface LazyRenderHookResponse<T> {
  /**
   * A callback ref to apply on the element that will trigger the next render
   * request.
   */
  infiniteScrollTargetRef(node: Element | null): void;

  /**
   * The subset of items to render.
   */
  subset: T[];
}

/**
 * Supports lazy rendering of items via infinite scroll using the Intersection
 * Observer API.
 *
 * @param items The items to lazy render.
 * @param itemsPerSubset The number of items to display per request.
 * @param infiniteScrollRootRef The Intersection Observer root element.
 */
export default function useLazyRender<T = any>(
  items: T[],
  itemsPerSubset: number,
  infiniteScrollRootRef?: React.RefObject<Element>
): LazyRenderHookResponse<T> {
  // updates the end position of the accounts subset
  const [subsetMultiplier, setSubsetMultiplier] = useState(1);

  // a subset of the original account list to render, up to the original list
  // NOTE: this only limits the accounts to render visually; selecting all
  // accounts should still select ALL accounts in the original list
  const subset = useMemo(
    () => items.slice(0, itemsPerSubset * subsetMultiplier),
    [items, itemsPerSubset, subsetMultiplier]
  );

  // initialize intersection observer hook; add root and rootMargin to trigger
  // fetch before target scrolls into view
  const [inViewRef, isInView] = useInView({
    root: infiniteScrollRootRef?.current,
    rootMargin: '0px 0px 200px'
  });

  // callback ref for intersection observer target to update root and forward
  // ref to useInView callback ref
  const infiniteScrollTargetRef = useCallback(
    (node: Element | null) => {
      inViewRef(node);
    },
    [inViewRef]
  );

  // add accounts to subset to display on trigger of intersection observer root
  useEffect(() => {
    if (isInView) {
      setSubsetMultiplier(prevSubset => prevSubset + 1);
    }
  }, [isInView]);

  // reset subset multiplier when original list of accounts updates
  // ie. filter pane toggle, search, etc
  useEffect(() => {
    setSubsetMultiplier(1);
  }, [items]);

  return {
    subset,
    infiniteScrollTargetRef
  };
}
