import { useUser } from 'contexts/UserContext';
import { IEngagement, IWorkspace } from 'interfaces';
import { formatDate } from 'utils/formatDate';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export interface IWorkspaceProps {
  engagement: IEngagement;
  workspace: IWorkspace;
}

const Workspace: FunctionComponent<IWorkspaceProps> = props => {
  const { engagement, workspace } = props;
  const { t } = useTranslation();
  const { permissionService: ps } = useUser();
  return (
    <div className="workspace">
      <div className="row">
        <div className="col-12 col-md-8">
          <h4 className="ry-h4">{engagement.engagementDisplayNameShort}</h4>
          <h3 className="ry-h3">{workspace.title}</h3>
          <p className="white-space-pre-line">{workspace.description}</p>
        </div>
        <div className="col-12 col-md-4">
          <div className="well">
            <ul className="row labeled-list">
              <li className="col-12 col-sm-6">
                <label>{t('Created By')}</label>
                {ps.isUser(workspace.createdBy)
                  ? t('You')
                  : workspace.createdByName}
              </li>
              <li className="col-12 col-sm-6">
                <label>{t('Creation Date')}</label>
                {formatDate(workspace.createDate)}
              </li>
              <li className="col-12 col-sm-6">
                <label>{t('Last Modified By')}</label>
                {ps.isUser(workspace.updatedBy)
                  ? t('You')
                  : workspace.updatedByName}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workspace;
