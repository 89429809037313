import {
  IGetInitialEngagementSavingsSummary,
  IParseToEngagementSavingsSummary
} from '../';

export const getInitialEngagementSavingsSummary: IGetInitialEngagementSavingsSummary =
  () => {
    return {
      engagementDisplayNameLong: '',
      engagementDisplayNameShort: '',
      engagementGuid: '',
      isActive: false,
      isoCurrencyCode: '',
      lastSavingsSummaryAsOfDate: null,
      lastSavingsSummaryCategories: null
    };
  };

export const parseToEngagementSavingsSummary: IParseToEngagementSavingsSummary =
  (
    engagementSavingsSummaryResponse,
    engagementSavingsSummaryDetailsResponse
  ) => {
    return {
      engagementDisplayNameLong:
        engagementSavingsSummaryResponse.engagementDisplayNameLong,
      engagementDisplayNameShort:
        engagementSavingsSummaryResponse.engagementDisplayNameShort,
      engagementGuid: engagementSavingsSummaryResponse.engagementGuid,
      isActive: engagementSavingsSummaryResponse.isActive,
      isoCurrencyCode: engagementSavingsSummaryResponse.isoCurrencyCode || '',
      lastSavingsSummaryAsOfDate:
        engagementSavingsSummaryResponse.lastSavingsSummaryAsOfDate,
      lastSavingsSummaryCategories:
        engagementSavingsSummaryDetailsResponse.lastSavingsSummaryCategories
    };
  };
