export enum ContractFileStatus {
  Unpublished = 1,
  Published
}

export enum ContractFileType {
  Amendment = 4,
  MasterServicesAgreement = 2,
  NonDisclosureAgreement = 10,
  Other = 9,
  PurchaseOrder_WorkOrder = 6,
  ServicesAgreement = 1,
  StatementOfWork_Release = 3,
  ThirdPartyProfessionalAgreement = 8,
  Unknown = 0
}

export interface IContractFile {
  bigFourUsers: string[];
  contractGuid: string;
  engagementContractDocumentGuid: string;
  engagementDisplayNameShort: string;
  engagementGuid: string;
  executedDate: Date | null;
  fileType: string;
  name: string;
  originalName: string;
  publishedDate: Date | null;
  status: string;
  statusId: ContractFileStatus;
  typeId: ContractFileType;
  updateDate: Date | null;
}
