import ENV from 'env';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './Maintenance.scss';

const Maintenance = () => {
  const { t: getTextToDisplay } = useTranslation();

  useEffect(() => {
    document.body.className = `${document.body.className} maintenance`.trim();
  }, []);

  return (
    <div className="maintenance-page">
      <img
        alt="maintenance-page"
        src={`${ENV.PORTAL_DOMAIN}/assets/maintenance.png`}
      />

      <p className="maintenance-page__main">
        {getTextToDisplay('maintenance.downForMaintenance')}
      </p>
      <p className="maintenance-page__details">
        {getTextToDisplay('maintenance.apologies')}
      </p>
    </div>
  );
};

export default Maintenance;
