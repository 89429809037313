import classnames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Button,
  Card,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  SplitButton,
  pushToast
} from '@ryan/components';

import { WithUser, withUser } from '../../contexts/UserContext';
import { IDataRequestV2, Permission, Status } from '../../interfaces';
import ApiService from '../../services/ApiService';
import { TDateRange } from '../../types';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import { formatDate } from '../../utils/formatDate';
import getCommentButtonProps from '../../utils/getCommentButtonProps';
import { isDataRequestPastDue } from '../../utils/isPastDue';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import DataRequestActions from '../DataRequestActions/DataRequestActions';
import DataRequestFields from '../DataRequestFields/DataRequestFields';
import FollowersModal from '../FollowersModal/FollowersModal';
import RecordsModal from '../RecordsModal/RecordsModal';
import StatusIcon from '../StatusIcon/StatusIcon';

import './DataRequestCard.scss';

export interface IDataRequestCardProps {
  dataRequest: IDataRequestV2;

  /**
   * Used to hide the actions if this card is being displayed in a table
   * that already shows the actions.
   */
  hideActions?: boolean;
  /**
   * Called when the data request is updated.
   */
  onOpenComments: () => void;
  onOpenHistory: () => void;
  onUpdate: () => void;
}

type IDataRequestCardHOCProps = WithUser &
  WithTranslation &
  RouteComponentProps;

interface IDataRequestCardState {
  isDateRangesModalOpen: boolean;
  isDocumentTypesModalOpen: boolean;
  isJurisdictionsModalOpen: boolean;
  isSiteClassesModalOpen: boolean;
  isPropertyTaxDocumentTypesModalOpen: boolean;
  openFollowersModal: boolean;
}

// Assumes Permission.DataRequestsView
class DataRequestCard extends Component<
  IDataRequestCardProps & IDataRequestCardHOCProps,
  IDataRequestCardState
> {
  constructor(props: IDataRequestCardProps & IDataRequestCardHOCProps) {
    super(props);
    this.state = {
      isDateRangesModalOpen: false,
      isDocumentTypesModalOpen: false,
      isJurisdictionsModalOpen: false,
      isSiteClassesModalOpen: false,
      isPropertyTaxDocumentTypesModalOpen: false,
      openFollowersModal: false
    };
  }

  handleWatchClick = async () => {
    const { dataRequest, onUpdate } = this.props;
    try {
      await ApiService.addWatcherToQueue(
        dataRequest.engagementGuid,
        !dataRequest.isCurrentUserWatching,
        dataRequest.queueItemGuid
      );
      onUpdate();
    } catch {
      pushServerErrorToast();
    }
  };

  handleFollowersModalClose = () => {
    this.setState({ openFollowersModal: false });
  };

  handleFollowersModalOpen = () => {
    this.setState({ openFollowersModal: true });
  };

  handleFollowersModalUpdate = () => {
    this.props.onUpdate();
    this.handleFollowersModalClose();
  };

  handleMarkDataDelivered = async (dataRequest: IDataRequestV2) => {
    const { onUpdate, t: getTextToDisplay } = this.props;

    if (!dataRequest) return;

    try {
      const { data } = await ApiService.markDataRequestDataDelivered({
        markAsDataDelivered: true,
        queueItemGuid: dataRequest.queueItemGuid,
        status: dataRequest.statusId,
        subStatus: SubStatusEnums.DataDelivered
      });

      pushToast({
        type: EMessageTypes.SUCCESS,
        content: getTextToDisplay(
          'dataRequest.markDataDeliveredSuccessContent',
          {
            title: data.title
          }
        )
      });
      onUpdate();
    } catch (error) {
      pushServerErrorToast();
    }
  };

  getButtonText = () => {
    const statusId = this.props.dataRequest.statusId;
    const isContributor = this.props.permissionService.hasPermission(
      Permission.DataRequestsContribute
    );
    const isEditor = this.props.permissionService.hasPermission(
      Permission.DataRequestsEdit
    );
    const isRyanUser = this.props.permissionService.isRyan();
    const isInProgressDataDelivered =
      statusId === Status.InProgress &&
      this.props.dataRequest.subStatusId === SubStatusEnums.DataDelivered;

    let buttonText;
    switch (statusId) {
      case Status.Todo:
        buttonText = isContributor && !isEditor ? 'Review' : 'View';
        break;
      case Status.InProgress:
        buttonText = isContributor ? 'Transfer Data' : 'View';
        break;
      case Status.Complete:
        buttonText = 'View';
        break;
      default:
        buttonText = 'View';
        break;
    }

    if (isRyanUser && (isContributor || isEditor)) {
      buttonText = 'Review';
    }

    if (!isRyanUser && isContributor) {
      if (!isInProgressDataDelivered && statusId !== Status.Complete) {
        buttonText = 'Transfer Data';
      }

      if (isInProgressDataDelivered) {
        buttonText = 'View';
      }
    }

    return buttonText;
  };

  render() {
    const {
      dataRequest,
      hideActions,
      isAppReadOnly,
      history,
      onOpenComments,
      onOpenHistory,
      onUpdate,
      permissionService,
      t: getTextToDisplay,
      user
    } = this.props;

    const {
      assignedToName,
      assignedToUserGuid,
      completedDate,
      createdBy,
      createdByName,
      createDate,
      dateRange,
      documentTypes,
      dueDate,
      engagementDisplayNameLong,
      engagementGuid,
      isCurrentUserWatching,
      isEngagementReadOnly,
      isUserGhosted,
      jurisdictions,
      markAsDataDelivered,
      otherDocumentType,
      propertyTaxDocumentTypes,
      propertyTaxSiteClasses,
      queueItemGuid,
      statusId,
      subStatusId,
      title,
      transferredFilesCount,
      watcherCount
    } = dataRequest;

    const viewHistory = (
      <Button
        icon="time"
        onClick={onOpenHistory}
        size={EButtonSizes.SMALL}
        text={getTextToDisplay('View History')}
        variant={EButtonVariant.TEXT}
      />
    );

    const addComment = (
      <Button
        {...getCommentButtonProps(
          getTextToDisplay,
          permissionService.hasPermission(Permission.DataRequestsContribute),
          dataRequest,
          isAppReadOnly
        )}
        data-testid="comment-button"
        disabled={isUserGhosted}
        onClick={onOpenComments}
        size={EButtonSizes.SMALL}
        variant={EButtonVariant.TEXT}
      />
    );

    return (
      <>
        <Card
          className={classnames(`data-request-card`, {
            'data-request-card--pastdue': isDataRequestPastDue(dataRequest)
          })}
        >
          <div className="data-request-card__content">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-7">
                <DataRequestFields
                  dataRequest={dataRequest}
                  handleDateRangesModalOpen={() =>
                    this.setState({ isDateRangesModalOpen: true })
                  }
                  handleDocumentTypesModalOpen={() =>
                    this.setState({ isDocumentTypesModalOpen: true })
                  }
                  handleJurisdictionsModalOpen={() =>
                    this.setState({ isJurisdictionsModalOpen: true })
                  }
                  handlePropertyTaxDocumentTypesModalOpen={() =>
                    this.setState({ isPropertyTaxDocumentTypesModalOpen: true })
                  }
                  handleSiteClassesModalOpen={() =>
                    this.setState({ isSiteClassesModalOpen: true })
                  }
                />
                <div className="data-request-card__comment-tablet">
                  <hr />
                  <div className="data-request-card__comment-tablet-flex">
                    <div className="data-request-card__comment-tablet-flex__action-buttons">
                      {viewHistory}
                      {addComment}
                    </div>
                  </div>
                </div>
              </div>
              <div className="data-request-card__aside col-12 col-md-4 offset-lg-1">
                {!hideActions &&
                  (permissionService.hasPermission(
                    Permission.DataRequestsEdit
                  ) ? (
                    <DataRequestActions
                      dataRequest={dataRequest as IDataRequestV2}
                      onUpdate={onUpdate}
                      size={EButtonSizes.LARGE}
                    />
                  ) : statusId === Status.InProgress &&
                    !markAsDataDelivered &&
                    permissionService.hasPermission(
                      Permission.DataRequestsContribute
                    ) ? (
                    <SplitButton
                      block
                      onClick={() => {
                        history.push(
                          `/app/data-request/${dataRequest.queueItemGuid}`
                        );
                      }}
                      options={[
                        {
                          disabled: isEngagementReadOnly || isAppReadOnly,
                          icon: 'clipboard-check',
                          label: getTextToDisplay(
                            'dataRequest.markDataDeliveredButtonName'
                          ),
                          onClick: () =>
                            this.handleMarkDataDelivered(
                              dataRequest as IDataRequestV2
                            )
                        }
                      ]}
                      size={EButtonSizes.SMALL}
                      text={getTextToDisplay(this.getButtonText())}
                    />
                  ) : (
                    <Button
                      block
                      data-testid="navigate-to-data-request-button"
                      disabled={isUserGhosted}
                      onClick={() =>
                        history.push(
                          `/app/data-request/${dataRequest.queueItemGuid}`
                        )
                      }
                      size={EButtonSizes.LARGE}
                      text={getTextToDisplay(this.getButtonText())}
                    />
                  ))}

                <div className="well">
                  {Object.values(Status).includes(statusId) && (
                    <div className="well__status">
                      <StatusIcon
                        size={EButtonSizes.LARGE}
                        status={statusId}
                        subStatus={subStatusId}
                      />
                    </div>
                  )}

                  <hr />
                  <ul className="row labeled-list">
                    <li className="col-12 col-sm-6">
                      <label>
                        {getTextToDisplay('dataRequest.columns.assignedTo')}
                      </label>
                      {permissionService.isUser(assignedToUserGuid)
                        ? getTextToDisplay('You')
                        : assignedToName}
                    </li>
                    {dueDate && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay('dataRequest.columns.dueDate')}
                        </label>
                        <span className="duedate">{formatDate(dueDate)}</span>
                      </li>
                    )}
                    <li className="col-12 col-sm-6">
                      <label>
                        {getTextToDisplay('dataRequest.columns.createdByName')}
                      </label>
                      {permissionService.isUser(createdBy)
                        ? getTextToDisplay('You')
                        : createdByName}
                    </li>
                    {createDate && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay('dataRequest.columns.createDate')}
                        </label>
                        {formatDate(createDate)}
                      </li>
                    )}
                    {!isEngagementReadOnly && !isAppReadOnly && (
                      <li className="col-12 col-sm-6">
                        <label>{getTextToDisplay('Followers')}</label>
                        <div className="data-request-card__follow-action-container">
                          <Button
                            onClick={this.handleWatchClick}
                            variant={EButtonVariant.LINK}
                          >
                            {getTextToDisplay(
                              isCurrentUserWatching ? 'Unfollow' : 'Follow'
                            )}
                          </Button>
                          <span className="data-request-card__follow-action-container__separator">
                            &#8226;
                          </span>
                          <Button
                            onClick={this.handleFollowersModalOpen}
                            variant={EButtonVariant.LINK}
                          >
                            {`${getTextToDisplay(
                              'Followers'
                            )} (${watcherCount})`}
                          </Button>
                        </div>
                      </li>
                    )}
                    {statusId !== Status.Todo && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay(
                            'dataRequest.columns.transferredFiles'
                          )}
                        </label>
                        {transferredFilesCount}
                      </li>
                    )}
                    {statusId === Status.Complete && completedDate && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay(
                            'dataRequest.columns.completionDate'
                          )}
                        </label>
                        {formatDate(completedDate)}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="data-request-card__comment-mobile">
            <div className="data-request-card__comment-mobile-border">
              {viewHistory}
            </div>
            <div className="data-request-card__comment-mobile-border">
              {addComment}
            </div>
          </div>
        </Card>
        <FollowersModal
          engagementGuid={engagementGuid}
          instructions={getTextToDisplay('followersModal.instructions', {
            context: 'dataRequest'
          })}
          onClose={this.handleFollowersModalClose}
          onUpdate={this.handleFollowersModalUpdate}
          open={this.state.openFollowersModal}
          queueItemGuid={queueItemGuid}
          title={getTextToDisplay('Followers', { context: 'dataRequest' })}
          userTypeId={user.profile.userTypeId}
        />
        {this.state.isDateRangesModalOpen && (
          <RecordsModal
            data={dateRange as unknown as TDateRange[]}
            dataRequestTitle={title}
            engagementDisplayName={engagementDisplayNameLong}
            modalTitle={getTextToDisplay('dataRequest.recordsModal.dateRanges')}
            onClose={() => this.setState({ isDateRangesModalOpen: false })}
          />
        )}
        {this.state.isJurisdictionsModalOpen && (
          <RecordsModal
            data={(jurisdictions || []).map(jurisdiction => {
              if ('jurisdictionGuid' in jurisdiction) {
                return getTextToDisplay(
                  `jurisdictions.${jurisdiction.jurisdictionGuid}.name`
                ) as string;
              }
              return jurisdiction.name;
            })}
            dataRequestTitle={title}
            engagementDisplayName={engagementDisplayNameLong}
            modalTitle={getTextToDisplay(
              'dataRequest.recordsModal.jurisdictions'
            )}
            onClose={() => this.setState({ isJurisdictionsModalOpen: false })}
          />
        )}
        {this.state.isDocumentTypesModalOpen && (
          <RecordsModal
            data={(documentTypes || []).map(documentType =>
              documentType.name === 'Other'
                ? (otherDocumentType as string)
                : documentType.name
            )}
            dataRequestTitle={title}
            engagementDisplayName={engagementDisplayNameLong}
            modalTitle={getTextToDisplay(
              'dataRequest.recordsModal.documentTypes'
            )}
            onClose={() => this.setState({ isDocumentTypesModalOpen: false })}
          />
        )}
        {dataRequest?.propertyTaxSiteClasses &&
          this.state.isSiteClassesModalOpen && (
            <RecordsModal
              data={dataRequest.propertyTaxSiteClasses.map(
                siteClass => siteClass.siteClassName
              )}
              dataRequestTitle={dataRequest.title}
              engagementDisplayName={dataRequest.engagementDisplayNameLong}
              modalTitle={getTextToDisplay(
                'dataRequest.recordsModal.propertyTaxSiteClasses'
              )}
              onClose={() => this.setState({ isSiteClassesModalOpen: false })}
            />
          )}
        {dataRequest?.propertyTaxDocumentTypes &&
          this.state.isPropertyTaxDocumentTypesModalOpen && (
            <RecordsModal
              data={dataRequest.propertyTaxDocumentTypes.map(
                documentType => documentType.documentTypeName
              )}
              dataRequestTitle={dataRequest.title}
              engagementDisplayName={dataRequest.engagementDisplayNameLong}
              modalTitle={getTextToDisplay(
                'dataRequest.recordsModal.documentTypes'
              )}
              onClose={() =>
                this.setState({ isPropertyTaxDocumentTypesModalOpen: false })
              }
            />
          )}
      </>
    );
  }
}

export default withRouter(withUser(withTranslation()(DataRequestCard)));
