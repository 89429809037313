import { ActivityType, IActivity, ISavingsSummarySummary } from 'interfaces';
import { formatCurrency } from 'utils/formatCurrency';
import i18n from 'utils/i18n';

import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';
import { findModifiedByKey } from '../findModifiedByKey';

function formatValues(activity: IActivity) {
  const { oldValue, newValue } = findModifiedByKey(
    activity,
    'LastSavingsSummarySavingsTotal'
  );
  return {
    change: formatCurrency(
      // decimal safe subtraction
      Math.abs((newValue * 100 - oldValue * 100) / 100),
      i18n.language,
      activity.isoCurrencyCode
    ),
    total: formatCurrency(newValue, i18n.language, activity.isoCurrencyCode)
  };
}

export const SavingsSummaryActivity: FunctionComponent<IActivityProps> =
  props => {
    const {
      t,
      activity,
      isNotification,
      user,
      showAccountName,
      showEngagementName,
      onCloseNotificationDrawer
    } = props;

    const {
      engagementGuid,
      createdBy,
      createdByName,
      snapShot: { entitySummary }
    } = activity;

    const by = `by-${user.userGuid === createdBy ? 'you' : 'x'}`;

    switch (activity.activityTypeId) {
      /**
       * Increased
       */
      case ActivityType.SavingsIncreased: {
        const { change, total } = formatValues(activity);
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.LightTeal}
            ctaText={t('activity.savings.viewSavingsSummary')}
            icon="calculator-increase"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.savings.increased.title')}
            to={`/app/project/${engagementGuid}/savings-history`}
          >
            <Trans i18nKey={`activity.savings.increased.${by}`}>
              <b />
              {{ createdByName }}
              {{ change }}
              {{ total }}
            </Trans>{' '}
            <ActivityModified
              activity={activity}
              cases={[
                {
                  key: 'Notes',
                  label: t('Update Notes'),
                  type: 'string' as const,
                  clamp: true
                }
              ]}
            />
          </AbstractActivity>
        );
      }

      /**
       * Decreased
       */
      case ActivityType.SavingsDecreased: {
        const { change, total } = formatValues(activity);
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.LightTeal}
            ctaText={t('activity.savings.viewSavingsSummary')}
            icon="calculator-decrease"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.savings.decreased.title')}
            to={`/app/project/${engagementGuid}/savings-history`}
          >
            <Trans i18nKey={`activity.savings.decreased.${by}`}>
              <b />
              {{ createdByName }}
              {{ change }}
              {{ total }}
            </Trans>{' '}
            <ActivityModified
              activity={activity}
              cases={[
                {
                  key: 'Notes',
                  label: t('Update Notes'),
                  type: 'string' as const,
                  clamp: true
                }
              ]}
            />
          </AbstractActivity>
        );
      }

      /**
       * Updated (But Total the Same)
       */
      case ActivityType.SavingsUpdated: {
        const { amount } = entitySummary as ISavingsSummarySummary;
        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.LightTeal}
            ctaText={t('activity.savings.viewSavingsSummary')}
            icon="calculator"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.savings.updated.title')}
            to={`/app/project/${engagementGuid}/savings-history`}
          >
            <Trans i18nKey={`activity.savings.updated.${by}`}>
              <b />
              {{ createdByName }}
              {{
                total: formatCurrency(
                  parseFloat(amount),
                  i18n.language,
                  activity.isoCurrencyCode
                )
              }}
            </Trans>{' '}
            <ActivityModified
              activity={activity}
              cases={[
                {
                  key: 'Notes',
                  label: t('Update Notes'),
                  type: 'string' as const,
                  clamp: true
                }
              ]}
            />
          </AbstractActivity>
        );
      }

      default:
        return <UnrecognizedActivity {...props} />;
    }
  };
