import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  MentionsValue,
  Pagination,
  pushToast
} from '@ryan/components';

import { CommentDeleteModal } from '../../../components/Comments';
import Empty from '../../../components/Empty';
import SearchInput from '../../../components/SearchInput';
import EngagementContext from '../../../contexts/EngagementContext';
import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  IComment,
  IEngagement,
  ILearning,
  ILearningsSearch,
  ITableState,
  Permission
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import debouncedSearch from '../../../utils/debouncedSearch';
import getMentionValue from '../../../utils/getMentionValue';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import LearningCard from './LearningCard';
import LearningCardSkeleton from './LearningCardSkeleton';
import LearningCommentsDrawer from './LearningCommentsDrawer';
import LearningModal from './LearningModal';

import './Learnings.scss';

const EMPTY_COMMENT = new MentionsValue();

export interface ILearningsProps
  extends WithTranslation,
    RouteComponentProps<{ engagementGuid: string; learningGuid?: string }>,
    WithUser {
  engagement: IEngagement;
}

interface ILearningsState extends ITableState {
  // learnings table
  learnings: ILearning[];

  // new learning
  openNewLearningModal: boolean;

  // comments for learning
  commentsForLearning: ILearning | null;
  comments: IComment[] | null;
  commentsLoading: boolean;

  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;

  deleteComment: IComment | null;
  deleteCommentLoading: Promise<any> | null;

  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
}

// Assume Permission.LearningsView
export class Learnings extends PureComponent<ILearningsProps, ILearningsState> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  private _isMounted = false;

  private learningsToken?: CancelTokenSource;
  private linkedLearningsToken?: CancelTokenSource;

  readonly state: ILearningsState = {
    // learnings
    loading: true,
    learnings: [] as ILearning[],
    searchQuery: '',
    page: 1,
    pageSize: 10,
    totalCount: 0,

    // new learning
    openNewLearningModal: false,

    // learning comments
    commentsForLearning: null,
    comments: null,
    commentsLoading: false,
    editComment: null,
    editCommentText: EMPTY_COMMENT,
    editCommentLoading: null,

    deleteComment: null,
    deleteCommentLoading: null,

    newCommentText: EMPTY_COMMENT,
    newCommentLoading: null
  };

  get canCreate() {
    const { permissionService: ps } = this.props;
    return ps.hasPermission(Permission.LearningsEdit);
  }

  get canComment() {
    const { isAppReadOnly, permissionService: ps, engagement } = this.props;
    return (
      ps.hasPermission(Permission.LearningsContribute) &&
      !engagement.isReadOnly &&
      !isAppReadOnly
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchLearnings();
    this.fetchLinkedLearning();
  }

  componentDidUpdate(prevProps: ILearningsProps) {
    const prevParams = prevProps.match.params;
    const { params } = this.props.match;
    if (params.engagementGuid !== prevParams.engagementGuid) {
      this.fetchLearnings();
    }
    if (params.learningGuid !== prevParams.learningGuid) {
      this.fetchLinkedLearning();
    }
  }

  componentWillUnmount() {
    this.learningsToken?.cancel();
    this.linkedLearningsToken?.cancel();
    this._isMounted = false;
  }

  async fetchLinkedLearning() {
    const {
      t,
      match,
      location: { hash }
    } = this.props;
    const { engagementGuid, learningGuid } = match.params;

    // If a learningGuid is in the url, fetch it.
    // If it is deleted, notify user.
    // If not deleted, maybe open comments.
    if (learningGuid) {
      try {
        this.linkedLearningsToken?.cancel();
        this.linkedLearningsToken = ApiService.CancelToken.source();

        const { data: learning } = await ApiService.getLearning(
          engagementGuid,
          learningGuid,
          this.linkedLearningsToken.token
        );
        if (hash === '#comments') {
          this.handleViewComments(learning);
        }
      } catch (error: any) {
        if (error?.response?.status === 410) {
          pushToast({
            type: 'warning',
            title: t('learning.linkedLearningHasBeenDeleted')
          });
        }
      }
    }
  }

  /**
   * Paginated learnings.
   */

  fetchLearnings(updates?: Partial<ILearningsState>) {
    this.setState(
      { ...(updates as ILearningsState), loading: true },
      async () => {
        const { engagementGuid } = this.props.match.params;
        const { page, pageSize, searchQuery } = this.state;

        const params: ILearningsSearch = {
          pageNumber: page,
          itemsPerPage: pageSize,
          searchTerm: searchQuery
        };

        try {
          this.learningsToken?.cancel();
          this.learningsToken = ApiService.CancelToken.source();

          const response = await ApiService.getEngagementLearnings(
            engagementGuid,
            params,
            this.learningsToken.token
          );
          this.setState({
            learnings: response.data.results,
            totalCount: response.data.totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({
              loading: false
            });
          }
        }
      }
    );
  }

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchLearnings({ page: 1, searchQuery });
    }
  );

  handlePage = (page: number, pageSize: number) => {
    this.fetchLearnings({ page, pageSize });
  };

  handleLearningUpdate = () => {
    this.fetchLearnings();
  };

  /**
   * Create a new learning.
   */

  handleNewLearning = () => {
    if (this.canCreate) {
      this.setState({ openNewLearningModal: true });
    }
  };

  handleNewLearningClose = (learning: ILearning | null) => {
    if (learning) {
      this.fetchLearnings();
    }

    this.setState({ openNewLearningModal: false });
  };

  /**
   * Comments
   */

  handleViewComments = async (commentsForLearning: ILearning) => {
    const { engagementGuid } = this.props.match.params;
    const learning: ILearning = commentsForLearning;

    this.setState({ commentsForLearning, commentsLoading: true });

    try {
      const response = await ApiService.getLearningComments(
        engagementGuid,
        learning.learningGuid
      );
      this.setState({ comments: response.data });
    } catch {
      pushServerErrorToast();
    }

    this.setState({ commentsLoading: false });
  };

  handleClose = () => {
    const { editCommentLoading, newCommentLoading, deleteComment } = this.state;
    if (!(editCommentLoading || newCommentLoading || deleteComment)) {
      this.setState({
        commentsForLearning: null,
        comments: null,
        editComment: null,
        editCommentText: EMPTY_COMMENT,
        deleteComment: null,
        newCommentText: EMPTY_COMMENT,
        newCommentLoading: null
      });
    }
  };

  /**
   * Edit a comment.
   */

  handleEditComment = (editComment: IComment) => {
    if (this.canComment) {
      this.setState({
        editComment,
        editCommentText: getMentionValue(editComment)
      });
    }
  };

  handleEditCommentChange = (editCommentText: MentionsValue) => {
    if (this.canComment) {
      this.setState({ editCommentText });
    }
  };

  handleEditCommentSave = async () => {
    const { commentsForLearning, editComment, editCommentText } = this.state;

    if (this.canComment && commentsForLearning && editComment) {
      const { engagementGuid } = this.props.match.params;
      const learning: ILearning = commentsForLearning;
      const comment: IComment = editComment;
      const { text } = editCommentText.toJSON();

      // if empty string, delete instead of edit
      if (text.length === 0) {
        this.handleDeleteCommentRequest(comment);
        return;
      }

      const editCommentLoading = ApiService.updateLearningComment(
        engagementGuid,
        learning.learningGuid,
        comment.commentGuid,
        text
      );

      this.setState({ editCommentLoading });

      try {
        const { data: updatedComment } = await editCommentLoading;
        this.setState(({ comments }) => ({
          comments:
            comments &&
            comments.map(c =>
              c.commentGuid === updatedComment.commentGuid ? updatedComment : c
            ),
          editComment: null,
          editCommentText: EMPTY_COMMENT
        }));
        this.context.refreshUpdateDate?.(engagementGuid);
      } catch {
        pushServerErrorToast();
      }

      this.setState({ editCommentLoading: null });
    }
  };

  handleEditCommentCancel = () => {
    this.setState({ editComment: null, editCommentText: EMPTY_COMMENT });
  };

  /**
   * Delete a comment.
   */

  handleDeleteCommentRequest = (deleteComment: IComment) => {
    if (this.canComment) {
      this.setState({ deleteComment });
    }
  };

  handleDeleteCommentConfirm = async () => {
    const { commentsForLearning, deleteComment } = this.state;

    if (this.canComment && commentsForLearning && deleteComment) {
      const { engagementGuid } = this.props.match.params;

      const deleteCommentLoading = ApiService.deleteLearningComment(
        engagementGuid,
        commentsForLearning.learningGuid,
        deleteComment.commentGuid
      );

      this.setState({ deleteCommentLoading });

      try {
        await deleteCommentLoading;
        this.setState(prevState => {
          const { comments, editComment } = prevState;

          // if we deleted the edited comment, clear editComment state too
          const deletedEditComment =
            editComment &&
            editComment.commentGuid === deleteComment.commentGuid;

          return {
            comments:
              comments &&
              comments.filter(c => c.commentGuid !== deleteComment.commentGuid),
            deleteComment: null,
            editComment: deletedEditComment ? null : editComment,
            editCommentText: deletedEditComment
              ? EMPTY_COMMENT
              : prevState.editCommentText
          };
        });

        // Fetch learnings for latest `commentsCount`
        this.fetchLearnings();
      } catch {
        pushServerErrorToast();
      }

      this.setState({ deleteCommentLoading: null });
    }
  };

  handleDeleteCommentCancel = () => {
    this.setState({ deleteComment: null });
  };

  /**
   * Create a new comment.
   */

  handleNewCommentChange = (newCommentText: MentionsValue) => {
    if (this.canComment) {
      this.setState({ newCommentText });
    }
  };

  handleNewCommentSave = async () => {
    const { commentsForLearning, newCommentText } = this.state;

    if (this.canComment && commentsForLearning) {
      const { engagementGuid } = this.props.match.params;
      const learning: ILearning = commentsForLearning;
      const { text } = newCommentText.toJSON();

      const newCommentLoading = ApiService.createLearningComment(
        engagementGuid,
        learning.learningGuid,
        text
      );

      this.setState({ newCommentLoading });

      try {
        const response = await newCommentLoading;
        this.setState(({ comments }) => ({
          comments: (comments || []).concat([response.data]),
          newCommentText: EMPTY_COMMENT
        }));
        this.context.refreshUpdateDate?.(engagementGuid);

        // Fetch learnings for latest `commentsCount`
        this.fetchLearnings();
      } catch {
        pushServerErrorToast();
      }

      this.setState({ newCommentLoading: null });
    }
  };

  render() {
    const {
      activeView,
      engagement,
      isAppReadOnly,
      match: {
        params: { engagementGuid }
      },
      permissionService,
      t: getTextToDisplay
    } = this.props;

    const {
      // learnings
      loading,
      learnings,
      searchQuery,
      page,
      pageSize,
      totalCount,

      // learning comments
      commentsForLearning,
      comments,
      commentsLoading,
      editComment,
      editCommentText,
      editCommentLoading,
      deleteComment,
      deleteCommentLoading,
      newCommentText,
      newCommentLoading
    } = this.state;

    const isCompletelyEmpty =
      !loading && totalCount === 0 && searchQuery === '';
    const isRyan = permissionService.isRyan();
    const isReadOnly =
      engagement.isReadOnly || isAppReadOnly || activeView.isExecutiveView;

    return (
      <div className="project-learnings">
        <div className="project-learnings__header">
          <h2 className="ry-h2">{getTextToDisplay('Learnings')}</h2>
          {this.canCreate && (
            <Button
              disabled={isReadOnly}
              icon="plus"
              onClick={this.handleNewLearning}
              size={EButtonSizes.SMALL}
              text={getTextToDisplay('learning.new')}
              variant={EButtonVariant.TEXT}
            />
          )}
          <SearchInput
            loading={loading}
            onChange={this.handleSearch}
            value={searchQuery}
          />
        </div>
        <hr />
        {loading ? (
          <LearningCardSkeleton />
        ) : isCompletelyEmpty ? (
          <Empty icon={isRyan ? 'file' : 'bulb'}>
            <p>
              {isRyan
                ? getTextToDisplay('learning.empty')
                : getTextToDisplay('learning.notRyanEmpty')}
            </p>
            {this.canCreate && (
              <Button
                disabled={isReadOnly}
                onClick={this.handleNewLearning}
                text={getTextToDisplay('learning.new')}
                variant={EButtonVariant.PRIMARY}
              />
            )}
          </Empty>
        ) : (
          <>
            {learnings.length === 0 ? (
              <Empty icon="null">
                {getTextToDisplay('learning.emptySearch')}
              </Empty>
            ) : null}
            {learnings.map(learning => (
              <LearningCard
                isEngagementGhosting={engagement.isUserGhosted}
                isEngagementReadOnly={engagement.isReadOnly}
                key={learning.learningGuid}
                learning={learning}
                onUpdate={this.handleLearningUpdate}
                onViewComments={() => {
                  this.handleViewComments(learning);
                }}
              />
            ))}
            <Pagination
              onPageChange={this.handlePage}
              page={page}
              pageSize={pageSize}
              totalCount={totalCount}
            />
          </>
        )}

        <LearningModal
          engagementGuid={engagementGuid}
          engagementName={engagement.engagementDisplayNameShort}
          onClose={this.handleNewLearningClose}
          open={this.state.openNewLearningModal}
        />

        <LearningCommentsDrawer
          canComment={this.canComment}
          comments={comments}
          commentsLoading={commentsLoading}
          editComment={editComment}
          editCommentLoading={editCommentLoading}
          editCommentText={editCommentText}
          engagement={engagement}
          learning={commentsForLearning}
          newCommentLoading={newCommentLoading}
          newCommentText={newCommentText}
          onClose={this.handleClose}
          onDeleteComment={this.handleDeleteCommentRequest}
          onEditComment={this.handleEditComment}
          onEditCommentCancel={this.handleEditCommentCancel}
          onEditCommentChange={this.handleEditCommentChange}
          onEditCommentSave={this.handleEditCommentSave}
          onNewCommentChange={this.handleNewCommentChange}
          onNewCommentSave={this.handleNewCommentSave}
        />

        <CommentDeleteModal
          loading={deleteCommentLoading}
          onCancel={this.handleDeleteCommentCancel}
          onConfirm={this.handleDeleteCommentConfirm}
          open={deleteComment !== null}
          t={getTextToDisplay}
        />
      </div>
    );
  }
}

export default withTranslation()(withUser(Learnings));
