import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { TFunction } from 'i18next';
import { INotification } from 'interfaces';
import { getLocale } from 'utils/i18n';
import sortNotifications from 'utils/sortNotifications';

interface INotificationGroupedByDate {
  [key: string]: {
    key: string;
    isoDate: string;
    label: string;
    notifications: INotification[];
  };
}

function formatDateLabel(date: Date, t: TFunction, language: string) {
  const diff = differenceInCalendarDays(new Date(), date);
  switch (diff) {
    case 0:
      return t('Today');
    case 1:
      return t('Yesterday');
    default:
      return format(date, 'EEEE, MMMM d', { locale: getLocale(language) });
  }
}

export default function groupNotificationsByDate(
  notifications: INotification[],
  t: TFunction,
  language = 'en-US'
) {
  // First let's organize the dates latest first!
  const sortedNotifications = sortNotifications(notifications);

  const groupNotifications = sortedNotifications.reduce(
    (days, notification) => {
      const date = parseISO(notification.activitySummary.createDate);
      const key = format(date, 'yyyyMMdd');
      if (!days[key]) {
        days[key] = {
          key,
          isoDate: notification.activitySummary.createDate,
          label: formatDateLabel(date, t, language),
          notifications: []
        };
      }
      days[key].notifications.push(notification);
      return days;
    },
    {} as any as INotificationGroupedByDate
  );

  const sortedGroups = Object.keys(groupNotifications)
    .sort()
    .reverse()
    .map(key => groupNotifications[key]);

  return sortedGroups;
}
