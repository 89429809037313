import { IFolder, IFolderTree } from 'interfaces';

import { RYAN_INTERNAL } from '../FileDirectory/utils/FileDirectoryEnums';

function compare(a: IFolder, b: IFolder) {
  return a.folderName.localeCompare(b.folderName);
}

function moveRyanInternalFolderFirst(folders: IFolderTree[]) {
  const ryanInternalFolder = folders.find(
    el => el.folderName === RYAN_INTERNAL
  );
  if (ryanInternalFolder) {
    const foundIdx = folders.findIndex(el => el === ryanInternalFolder);
    folders.splice(foundIdx, 1);
    folders.unshift(ryanInternalFolder);
  }
}

export function sortFolders(folders: IFolderTree[]) {
  const sortedFolders = folders.slice().sort(compare);
  moveRyanInternalFolderFirst(sortedFolders);
  return sortedFolders;
}
