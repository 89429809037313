/**
 * Transforms a number into a locale-based currency value.
 */
export function formatCurrency(
  value: number,
  language: string,
  currencyCode: string | null,
  includeCents = true
): string {
  const formatterOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currencyCode || undefined
  };

  if (!includeCents) {
    formatterOptions.minimumFractionDigits = 0;
    formatterOptions.maximumFractionDigits = 0;
  }

  try {
    const formatter = new Intl.NumberFormat(language, formatterOptions);
    return formatter.format(value);
  } catch {
    return `${value}`;
  }
}
