import React from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteAjax, {
  IAutocompleteAjaxProps
} from 'components/AutocompleteAjax';
import { IJurisdiction } from 'interfaces';
import ApiService from 'services/ApiService';

type IJurisdictionAutocompleteProps = Omit<
  IAutocompleteAjaxProps<IJurisdiction>,
  'getOptionValue' | 'onFetchOptions' | 'renderOption'
>;

const JurisdictionAutocomplete: React.FC<IJurisdictionAutocompleteProps> = props => {
  const { t } = useTranslation();

  return (
    <AutocompleteAjax<IJurisdiction>
      getOptionValue={jurisdiction => jurisdiction.name}
      label={t('Jurisdiction')}
      onFetchOptions={query =>
        ApiService.getJurisdictions(query).then(response => response.data)
      }
      renderOption={jurisdiction => jurisdiction.name}
      {...props}
    />
  );
};

export default JurisdictionAutocomplete;
