import ActivityFeed, { ActivityFeedSkeleton } from 'components/ActivityFeed';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import Datepicker from 'components/Datepicker';
import DocumentTitle from 'components/DocumentTitle';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  EntityType,
  IActivity,
  IEngagement,
  IPagedDataResponse,
  ITableState,
  Permission
} from 'interfaces';
import ApiService from 'services/ApiService';
import getEntityTypes from 'utils/getEntityTypes';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import scrollTo from 'utils/scrollTo';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import { AxiosResponse, CancelTokenSource } from 'axios';
import classnames from 'classnames';
import { addDays } from 'date-fns';
import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, matchPath, withRouter } from 'react-router';

import { Dropdown, Pagination } from '@ryan/components';

import { FilterType, FilterTypeGroups } from './FilterType';
import getActivityParams from './getActivityParams';
import getFilterTypes from './getFilterTypes';

import './AllRecentActivity.scss';

const isAllProjects = (location: RouteComponentProps['location']) =>
  matchPath(location.pathname, '/app/projects/all-activity') !== null;

const isDataAndFiles = (location: RouteComponentProps['location']) =>
  matchPath(location.pathname, '/app/data-and-files') !== null;

interface IAllRecentActivityProps
  extends RouteComponentProps<{ engagementGuid: string }>,
    WithUser,
    WithTranslation {
  // ...
}

interface IAllRecentActivityState extends ITableState {
  history: IActivity[] | null;
  engagement: IEngagement | null;
  startDate: Date | null;
  endDate: Date | null;
  entityTypes: EntityType[];
  filterTypes: FilterType[];
  activeFilterType: FilterType;
  filterTypeGroups: FilterTypeGroups;
}

type ActivityRequest = Promise<AxiosResponse<IPagedDataResponse<IActivity>>>;

class AllRecentActivity extends React.Component<
  IAllRecentActivityProps,
  IAllRecentActivityState
> {
  private sourceEngagement?: CancelTokenSource;
  private sourceRecentActivity?: CancelTokenSource;

  private feedRef = React.createRef<HTMLDivElement>();

  constructor(props: IAllRecentActivityProps) {
    super(props);
    const { permissionService, match, location } = props;
    const forEngagement = !!match.params.engagementGuid;
    const forDataAndFiles = isDataAndFiles(location);

    // For the page, get the entityTypes user has permissions for...
    const entityTypes = getEntityTypes(
      permissionService,
      forEngagement,
      forDataAndFiles
    );

    const filterTypes = getFilterTypes(
      entityTypes,
      forEngagement,
      forDataAndFiles
    ).sort((a, b) => {
      const { t } = this.props;
      const aLabel = t(`activity.feed.filterTypes.${a}`);
      const bLabel = t(`activity.feed.filterTypes.${b}`);

      if (aLabel < bLabel) return -1;
      if (aLabel > bLabel) return 1;
      return 0;
    });

    const filterTypeGroups: FilterTypeGroups = {
      default: filterTypes.filter(filterType =>
        [FilterType.All, FilterType.AssignedToMe].includes(filterType)
      ),
      byActivity: filterTypes.filter(filterType =>
        [
          FilterType.DataRequests,
          FilterType.Milestones,
          FilterType.Tasks,
          ...(permissionService.hasPermission(Permission.FilesRead)
            ? [FilterType.Files]
            : []),
          FilterType.Contracts,
          FilterType.Invoices,
          FilterType.Learnings,
          FilterType.Team,
          FilterType.SavingsSummary,
          ...(permissionService.hasPermission(Permission.TimelinesView)
            ? [FilterType.Projects]
            : []),
          FilterType.AccessControl
        ].includes(filterType)
      ),
      byAction: filterTypes.filter(filterType =>
        [FilterType.Comments, FilterType.Deleted, FilterType.Edits].includes(
          filterType
        )
      )
    };
    const filterTypeHash = parseInt(location.hash.replace('#', ''), 10);

    this.state = {
      loading: true,
      engagement: null,
      startDate: null,
      endDate: null,
      history: [],
      sorted: {
        id: 'createDate',
        desc: true
      },
      page: 1,
      pageSize: 10,
      totalCount: 0,
      entityTypes,
      filterTypes,
      activeFilterType:
        filterTypeHash in FilterType ? filterTypeHash : FilterType.All,
      filterTypeGroups
    };
  }

  componentDidMount() {
    this.fetchEngagement();
    this.fetchActivity();
  }

  componentDidUpdate(prevProps: IAllRecentActivityProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchActivity({ page: 1 });
    }

    const prevEngagementGuid = prevProps.match.params.engagementGuid;
    const { engagementGuid } = this.props.match.params;
    if (prevEngagementGuid !== engagementGuid) {
      this.fetchEngagement();
      this.fetchActivity();
    }
  }

  componentWillUnmount() {
    this.sourceEngagement?.cancel();
    this.sourceRecentActivity?.cancel();
  }

  async fetchEngagement() {
    const { engagementGuid } = this.props.match.params;

    try {
      this.sourceEngagement?.cancel();
      this.sourceEngagement = ApiService.CancelToken.source();

      if (engagementGuid) {
        const { data } = await ApiService.getEngagement(
          engagementGuid,
          this.sourceEngagement.token
        );

        this.setState({ engagement: data });
      }
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    }
  }

  fetchActivity(updates?: Partial<IAllRecentActivityState>) {
    this.setState(
      { ...(updates as IAllRecentActivityState), loading: true },
      async () => {
        const { customViewGuid } = this.props.activeView;
        const { engagementGuid } = this.props.match.params;
        const {
          entityTypes,
          sorted,
          page,
          pageSize,
          startDate,
          endDate,
          activeFilterType
        } = this.state;

        // It's possible that they do not permission to view any entities with activity.
        if (entityTypes.length > 0) {
          const params = {
            // Pagination
            sort: getSortParam(sorted),
            pageNumber: page,
            itemsPerPage: pageSize,

            // Dates
            startDate: startDate,
            endDate: endDate ? addDays(endDate, 1) : null,

            // Filtering
            ...getActivityParams(
              entityTypes,
              activeFilterType,
              this.props.user.profile.userGuid
            )
          };

          try {
            this.sourceRecentActivity?.cancel();
            this.sourceRecentActivity = ApiService.CancelToken.source();

            const request: ActivityRequest = engagementGuid
              ? // Fetch activity for an engagement.
                ApiService.getEngagementRecentActivity(
                  engagementGuid,
                  params,
                  this.sourceRecentActivity.token
                )
              : // Fetch activity for an account.
                ApiService.getCustomViewRecentActivity(
                  customViewGuid,
                  params,
                  this.sourceRecentActivity.token
                );

            const {
              data: { results, totalResults }
            } = await request;

            this.setState({
              history: results,
              totalCount: totalResults
            });
          } catch (error) {
            if (!ApiService.isCancel(error)) {
              pushServerErrorToast();
            }
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  renderParentBreadcrumbs() {
    const { t, location } = this.props;

    return isDataAndFiles(location) ? (
      <Breadcrumb
        label={t(`activity.feed.dataAndFiles`)}
        to="/app/data-and-files/overview"
      />
    ) : (
      <Breadcrumb
        label={t(`activity.feed.projects`)}
        to="/app/projects/overview"
      />
    );
  }

  renderActivityBreadcrumbs() {
    const { t, location, match } = this.props;
    const { engagementGuid } = match.params;
    const engagement: IEngagement = this.state.engagement!;

    return (
      <>
        {engagementGuid && (
          <Breadcrumb
            label={
              engagement ? engagement.engagementDisplayNameShort : t('Project')
            }
            to={`/app/project/${engagementGuid}/overview`}
          />
        )}
        <Breadcrumb
          label={t(
            isAllProjects(location)
              ? 'activity.feed.allRecentActivity'
              : 'activity.feed.recentActivity'
          )}
          to={match.url}
        />
      </>
    );
  }

  handleStartDateDatepickerChange = (_e: unknown, date: Date | null) => {
    this.fetchActivity({ page: 1, startDate: date });
  };

  handleEndDateDatepickerChange = (_e: unknown, date: Date | null) => {
    this.fetchActivity({ page: 1, endDate: date });
  };

  handleTypeChange = (activeFilterType: FilterType) => {
    const { history, location } = this.props;

    this.fetchActivity({
      page: 1,
      activeFilterType
    });

    history.replace({
      ...location,
      hash: `${activeFilterType}`
    });

    // scroll to top of feed
    if (this.feedRef.current) {
      scrollTo(this.feedRef.current);
    }
  };

  handleDropdownTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.fetchActivity({
      page: 1,
      activeFilterType: parseFloat(e.target.value)
    });
  };

  handlePage = (page: number, pageSize: number) => {
    this.fetchActivity({ page, pageSize });
  };

  renderFilters = (filterTypes: FilterType[]) => {
    const { t } = this.props;
    const { activeFilterType } = this.state;

    return (
      <ol className="all-recent-activity__filter-tabs">
        {filterTypes.map(filterType => (
          <li key={filterType}>
            <button
              className={classnames({
                'all-recent-activity__filter-tab': true,
                'all-recent-activity__filter-tab--active':
                  activeFilterType === filterType
              })}
              onClick={() => this.handleTypeChange(filterType)}
            >
              {t(`activity.feed.filterTypes.${filterType}`)}
            </button>
          </li>
        ))}
      </ol>
    );
  };

  render() {
    const {
      activeView,
      location,
      match: {
        params: { engagementGuid }
      },
      t
    } = this.props;
    const {
      history,
      loading,
      startDate,
      endDate,
      entityTypes,
      filterTypes,
      activeFilterType,
      filterTypeGroups,
      page,
      pageSize,
      totalCount
    } = this.state;
    const canSeeActivity = entityTypes.length > 0;
    const pageCount = totalCount === 0 ? 1 : Math.ceil(totalCount / pageSize);
    const pageTitle = t(
      isAllProjects(location)
        ? 'activity.feed.allRecentActivity'
        : 'activity.feed.recentActivity'
    );

    return (
      <div className="all-recent-activity">
        <h4 className="ry-h4">{activeView.name}</h4>

        {/* Header */}
        <DocumentTitle title={pageTitle} />
        <h1 className="ry-h1">{pageTitle}</h1>

        {this.renderParentBreadcrumbs()}
        {this.renderActivityBreadcrumbs()}

        {canSeeActivity ? (
          <>
            <hr />
            {/* Filter by Date Range */}
            <section
              className="all-recent-activity__date-range"
              ref={this.feedRef}
            >
              <div className="row">
                <div className="col-md-3">
                  <h2 className="ry-h3">
                    {t(`activity.feed.viewByDateRange`)}
                  </h2>
                </div>
                <div className="col-md-9">
                  <div className="all-recent-activity__date-range-inputs">
                    <Datepicker
                      label={t(`activity.feed.startDate`)}
                      maxDate={endDate || undefined}
                      onChange={this.handleStartDateDatepickerChange}
                      value={startDate}
                    />
                    <Datepicker
                      label={t(`activity.feed.endDate`)}
                      minDate={startDate || undefined}
                      onChange={this.handleEndDateDatepickerChange}
                      value={endDate}
                    />
                  </div>
                </div>
              </div>
            </section>
            <hr />
            <div className="row">
              <div className="col-md-3">
                {/* Filter by Type */}
                <section className="all-recent-activity__filter">
                  <h2 className="ry-h2">{t(`activity.feed.filterBy`)}</h2>

                  {/* Mobile */}
                  <div className="all-recent-activity__filter-dropdown">
                    <Dropdown
                      name="Activity Filters"
                      onChange={this.handleDropdownTypeChange}
                      options={filterTypes.map(a => ({
                        value: a.toString(),
                        label: t(`activity.feed.filterTypes.${a}`)
                      }))}
                      value={activeFilterType.toString()}
                    />
                  </div>

                  {/* Tablet and Desktop */}
                  {filterTypeGroups.default.length > 0 &&
                    this.renderFilters(filterTypeGroups.default)}
                  {filterTypeGroups.byActivity && (
                    <>
                      <h3 className="all-recent-activity__filter-subheading">
                        {t('activity.feed.filterByActivity')}
                      </h3>
                      {this.renderFilters(filterTypeGroups.byActivity)}
                    </>
                  )}
                  {filterTypeGroups.byAction && (
                    <>
                      <h3 className="all-recent-activity__filter-subheading">
                        {t('activity.feed.filterByAction')}
                      </h3>
                      {this.renderFilters(filterTypeGroups.byAction)}
                    </>
                  )}
                </section>
              </div>
              <div className="col-md-9">
                {/* Activity Feed */}
                <section className="all-recent-activity__feed">
                  {loading ? (
                    <ActivityFeedSkeleton count={pageSize} />
                  ) : (
                    <ActivityFeed
                      activities={history || []}
                      showAccountName={!engagementGuid}
                      showEngagementName={!engagementGuid}
                    />
                  )}
                  {history!.length !== 0 && pageCount === page && (
                    <div className="all-recent-activity__beginning">
                      {t(`activity.feed.beginning`)}
                    </div>
                  )}
                </section>
              </div>
            </div>
            <Pagination
              onPageChange={this.handlePage}
              page={page}
              pageSize={pageSize}
              pageSizeLabel={t('table.pageSizeLabel')}
              totalCount={totalCount}
            />
          </>
        ) : (
          'You are not authorized to see any activity.'
        )}
      </div>
    );
  }
}

export default withRouter(withUser(withTranslation()(AllRecentActivity)));
