import Currency from 'components/Currency/Currency';
import InfoTooltip from 'components/InfoTooltip';
import { IAccountSavings } from 'interfaces';
import { formatDate } from 'utils/formatDate';
import { getCurrencySymbol } from 'utils/getCurrencySymbol';

import ENV from 'env';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Icon, Tooltip } from '@ryan/components';

const SavingsTotals: FunctionComponent<{
  totals: IAccountSavings[] | null;
}> = ({ totals }) => {
  const { t } = useTranslation();

  /**
   * Skeleton when loading...
   */
  if (totals === null) {
    return (
      <div className="savings-summary__totals">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <Card title={t('savings.totalSavings')}>
              <div className="savings-summary__totals-as-of">
                <span
                  className="ry-skeleton"
                  style={{
                    display: `inline-block`,
                    width: '6rem',
                    height: '0.75rem',
                    margin: '0.125rem'
                  }}
                />
              </div>
              <label className="ry-label">{t('Total')}*</label>
              <span className="ry-number-1 ry-skeleton" />
            </Card>
          </div>
        </div>
      </div>
    );
  }

  const nonZeroSavings = totals.filter(total => total.total > 0);

  /**
   * Show nothing when there are no savings.
   */
  if (nonZeroSavings.length === 0) {
    return null;
  }

  const hasHistoricalSavings =
    totals !== null && totals.some(total => total.externalTotal > 0);

  return (
    <div className="savings-summary__totals">
      <div className="row">
        {nonZeroSavings.map(total => {
          const symbol = getCurrencySymbol(total.currencyCode);
          return (
            <div className="col-12 col-md-6 col-lg-4" key={total.currencyCode}>
              <Card title={`${t('savings.totalSavings')} (${symbol})`}>
                <div className="savings-summary__totals-as-of">
                  {t('As of')} {formatDate(total.asOfDate)}
                </div>

                {/**
                 * Total
                 */}
                <label className="ry-label">{t('Total')}*</label>
                <Currency
                  className="ry-number-1"
                  currencyCode={total.currencyCode}
                  includeCents={false}
                  value={total.total}
                />

                {/**
                 * Breakdown only shown if account has historical savings.
                 */}
                {hasHistoricalSavings && (
                  <>
                    <hr />

                    {/**
                     * Internal Total
                     */}
                    <div className="savings-summary__totals-line">
                      <label className="ry-label">
                        {t('savings.overview.projectsManagedIn', {
                          ryanPlatform: ENV.RYAN_PLATFORM
                        })}
                      </label>
                      <Currency
                        className="ry-number-2"
                        currencyCode={total.currencyCode}
                        includeCents={false}
                        includeSymbol={false}
                        value={total.internalTotal}
                      />
                      <InfoTooltip>
                        {t('savings.overview.projectsManagedInTooltip', {
                          ryanPlatform: ENV.RYAN_PLATFORM
                        })}
                      </InfoTooltip>
                    </div>

                    {/**
                     * External Total
                     */}
                    <div className="savings-summary__totals-line">
                      <label className="ry-label">
                        {t('savings.overview.projectsManagedOutside', {
                          ryanPlatform: ENV.RYAN_PLATFORM
                        })}
                      </label>
                      <Currency
                        className="ry-number-2"
                        currencyCode={total.currencyCode}
                        includeCents={false}
                        includeSymbol={false}
                        value={total.externalTotal}
                      />
                      <InfoTooltip>
                        {t('savings.overview.projectsManagedOutsideTooltip', {
                          ryanPlatform: ENV.RYAN_PLATFORM
                        })}
                      </InfoTooltip>
                    </div>
                  </>
                )}
              </Card>
            </div>
          );
        })}
      </div>

      {/**
       * Show tooltip if there are multiple currencies.
       */}
      {nonZeroSavings.length > 1 && (
        <Tooltip
          content={t('savings.overview.multipleCurrenciesTooltip', {
            ryanPlatform: ENV.RYAN_PLATFORM
          })}
          placement="top"
          renderTarget={({ open, ...props }) => (
            <button
              className="savings-summary__totals-tooltip-target"
              type="button"
              {...props}
            >
              <Icon name="information" />
              {t('savings.overview.multipleCurrencies')}
            </button>
          )}
        />
      )}
    </div>
  );
};

export default SavingsTotals;
