import { ActivityType } from 'interfaces';
import { IEngagementActivitySummary } from 'interfaces/IEngagementActivitySummary';

import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';

export const EngagementActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    user,
    activity,
    isNotification,
    showAccountName,
    onCloseNotificationDrawer
  } = props;

  const {
    createdBy,
    createdByName,
    engagementGuid,
    engagementDisplayNameShort: title,
    snapShot: { entitySummary }
  } = activity;

  const { isEngagementActive } = entitySummary as IEngagementActivitySummary;

  const by = `by-${user.userGuid === createdBy ? 'you' : 'x'}`;

  switch (activity.activityTypeId) {
    /**
     * Published
     */
    case ActivityType.ProjectPublished:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Purple}
          ctaText={t('activity.project.viewProject')}
          icon="project-visible"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={false}
          title={t('activity.project.published.title')}
          to={`/app/project/${engagementGuid}`}
        >
          <Trans i18nKey={`activity.project.published.${by}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
        </AbstractActivity>
      );

    /**
     * Unpublished
     */
    case ActivityType.ProjectUnpublished:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Purple}
          ctaText={t('activity.project.viewProject')}
          icon="project-hidden"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={false}
          title={t('activity.project.unpublished.title')}
          to={`/app/project/${engagementGuid}`}
        >
          <Trans i18nKey={`activity.project.unpublished.${by}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
        </AbstractActivity>
      );

    /**
     * Edited
     */
    case ActivityType.ProjectModified:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Purple}
          ctaText={t('activity.project.viewProject')}
          icon="project-pencil"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={false}
          title={t('activity.project.edited.title')}
          to={`/app/project/${engagementGuid}`}
        >
          <Trans i18nKey={`activity.project.edited.${by}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'AlternateBeginDate',
                label: t('Kickoff'),
                type: 'date' as const
              },
              {
                key: 'ProjectedEndDate',
                label:
                  isEngagementActive === undefined || isEngagementActive
                    ? t('Estimated End')
                    : t('Completed'),
                type: 'date' as const
              },
              {
                key: 'ProjectDetails',
                label: t('Project Highlights'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'StatuteOfLimitations',
                label: t('Statute Waiver Expiration'),
                type: 'date' as const
              },
              {
                key: 'IsActive',
                label: t('Status'),
                type: (value: boolean) => t(value ? 'Active' : 'Inactive')
              }
            ]}
          />
        </AbstractActivity>
      );

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
