import { DirectoryItemType, IDirectoryItem, IFile, IFolder } from 'interfaces';

export function getDirectoryItemId(directoryItem: IDirectoryItem) {
  switch (directoryItem.type) {
    case DirectoryItemType.Folder:
      return (directoryItem.item as IFolder).folderGuid;
    case DirectoryItemType.File:
      return (directoryItem.item as IFile).documentGuid;
    default:
      throw new TypeError(`Directory type unknown: ${directoryItem.type}`);
  }
}
