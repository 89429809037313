import Oidc, { UserManagerSettings } from 'oidc-client';
import { getImpersonatedUserGuid } from 'utils/getImpersonatedUserGuid';

import ENV from 'env';

function makeOidcConfiguration(): UserManagerSettings {
  const impersonationUserId = getImpersonatedUserGuid();

  return {
    acr_values: `wsfed${impersonationUserId ? `|${impersonationUserId}` : ''}`, // (isInternal ? 'Windows' : 'wsfed') +
    authority: ENV.IDENTITY_AUTHORITY,
    automaticSilentRenew: true,
    client_id: ENV.ADFS_CLIENT_ID, // isInternal ? 'windows_client' : adfsClientId,
    loadUserInfo: true,
    post_logout_redirect_uri: `${ENV.PORTAL_DOMAIN}/public/logout`,
    //prompt: 'login',
    redirect_uri: `${ENV.PORTAL_DOMAIN}/authentication/callback`,
    response_type: 'code',
    scope: 'openid profile email dxp-api',
    silent_redirect_uri: `${ENV.PORTAL_DOMAIN}/silent-renew.html`,
    silentRequestTimeout: 10000,
    userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage })
  };
}

// Singleton
let oidcConfiguration: UserManagerSettings | null = null;

/**
 * Returns an OIDC configuration for user authentication.
 */
export function getOidcConfiguration() {
  if (!oidcConfiguration) {
    oidcConfiguration = makeOidcConfiguration();
  }

  return oidcConfiguration;
}

/**
 * Resets the OIDC configuration singleton. Used for unit testing only.
 *
 * @ignore
 */
export function resetOidcConfiguration() {
  oidcConfiguration = null;
}
