import DocumentTitle from 'components/DocumentTitle';
import Tabs from 'components/Tabs/Tabs';
import { WithUser, withUser } from 'contexts/UserContext';
import { Feature } from 'interfaces';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';

import TeamTable from './TeamTable';

import './Team.scss';

export class Team extends Component<WithUser & WithTranslation> {
  render() {
    const {
      t,
      permissionService: ps,
      activeView,
      isFeatureToggled
    } = this.props;

    const tab = [
      {
        label: t(ps.isClient() ? t('My Team') : t('Client Team')),
        path: '/app/team/client'
      },
      {
        label: t(ps.isRyan() ? t('My Team') : t('Ryan Team')),
        path: '/app/team/ryan'
      },
      {
        label: t('Third Party'),
        path: '/app/team/third-party'
      }
    ];

    return (
      <div className="team-page">
        <DocumentTitle title={t('team.title')} />

        <h4 className="ry-h4">{activeView.name}</h4>
        <h1 className="ry-h1">{t('team.title')}</h1>
        <div className="team-page__tabs">
          {!ps.isThirdParty() &&
            (isFeatureToggled(Feature.LeftNav) ? '' : <Tabs tabs={tab} />)}
        </div>
        <Switch>
          <Route component={TeamTable} exact path="/app/team/:userType" />
          {isFeatureToggled(Feature.LeftNav) ? (
            ''
          ) : (
            <Redirect
              to={ps.isThirdParty() ? '/app/team/ryan' : '/app/team/client'}
            />
          )}
        </Switch>
      </div>
    );
  }
}

export default withUser(withTranslation()(Team));
