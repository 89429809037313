import { UserManager } from 'oidc-client';

import React, { FunctionComponent } from 'react';

import PublicLayoutContainer from './PublicLayoutContainer';
import PublicRouter from './PublicRouter';

interface IPublicLayoutProps {
  userManager: UserManager;
}

/**
 * The layout for pages using the public page container and do not require the
 * user to be authenticated to view.
 */
const PublicLayout: FunctionComponent<IPublicLayoutProps> = ({
  userManager
}) => (
  <PublicLayoutContainer>
    <PublicRouter userManager={userManager} />
  </PublicLayoutContainer>
);

export default PublicLayout;
