import { FilterOptions } from 'components/AdvancedFilterModal/interfaces';

import ApiBase from './ApiBase';

export async function fetchFilterOptions(
  this: ApiBase,
  customViewGuid: string
) {
  return this.server.get<FilterOptions>(
    `/api/customViews/${customViewGuid}/filteroptions`
  );
}
