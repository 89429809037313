import { DirectoryFilterGuid, IDirectoryItem } from '../interfaces';

export default function getDirectoryItemVisibilityIcon(item: IDirectoryItem) {
  switch (item.fileSearchFilterGuid) {
    case DirectoryFilterGuid.Image:
      return 'landscape-visibility';
    case DirectoryFilterGuid.PDF:
      return 'file-ext-pdf-visibility';
    case DirectoryFilterGuid.Presentation:
      return 'presentation';
    case DirectoryFilterGuid.Spreadsheet:
      return 'file-ext-xls-visibility';
    case DirectoryFilterGuid.WordDocument:
      return 'file-ext-doc-visibility';
    case DirectoryFilterGuid.Other:
    default:
      return 'file-visibility';
  }
}
