import { useCallback, useMemo, useRef } from 'react';

export type LatestHookResponse<T = any> = [
  (value: T) => boolean,
  (value: T) => void
];

export default function useLatest<T = any>(
  initialValue: T
): LatestHookResponse<T> {
  const ref = useRef<T>(initialValue);
  const isLatest = useCallback(value => ref.current === value, []);
  const setLatest = useCallback(value => {
    ref.current = value;
  }, []);
  const hookResponse = useMemo<LatestHookResponse<T>>(
    () => [isLatest, setLatest],
    [isLatest, setLatest]
  );
  return hookResponse;
}
