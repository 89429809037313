import React, { FunctionComponent } from 'react';
import { withTranslation } from 'react-i18next';

import { FilterOptions, ProjectDataType, ProjectFilter } from '../interfaces';
import DataTypeSection from './DataTypeSection';
import SectionDivider from './SectionDivider';

interface FilterSelectionsProps {
  clearSelection: (filter: string, dataType: ProjectDataType) => void;
  onClear: (dataType: ProjectDataType) => void;
  selectedFilters: FilterOptions;
}

const FilterSelections: FunctionComponent<FilterSelectionsProps> = ({
  clearSelection,
  onClear,
  selectedFilters
}) => {
  const {
    accountHandlers,
    engagementManagers,
    engagementPrincipals,
    jurisdictions,
    practiceAreas
  } = selectedFilters;

  const sections: {
    type: ProjectDataType;
    selectedFilters: ProjectFilter[];
  }[] = [
    {
      type: ProjectDataType.AccountHandler,
      selectedFilters: accountHandlers
    },
    {
      type: ProjectDataType.EngagementManager,
      selectedFilters: engagementManagers
    },
    {
      type: ProjectDataType.EngagementPrincipal,
      selectedFilters: engagementPrincipals
    },
    { type: ProjectDataType.Jurisdiction, selectedFilters: jurisdictions },
    { type: ProjectDataType.PracticeArea, selectedFilters: practiceAreas }
  ].filter(section => section.selectedFilters.length > 0);

  return (
    <div className="selections" data-testid="filter-selections">
      {sections.map(({ selectedFilters, type }, index) => {
        if (selectedFilters.length > 0) {
          return (
            <div key={index}>
              <DataTypeSection
                clearSelection={clearSelection}
                key={index}
                onClear={onClear}
                selectedFilters={selectedFilters}
                type={type}
              />
              {index < sections.length - 1 && <SectionDivider />}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default withTranslation()(FilterSelections);
