import { sum } from '../../../../../routes/SavingsSummaryEntry/savingsSummaryHelpers';
import { SavingsCategoryBucketEnums } from '../../../../../utils/enums/SavingsCategoryBucketEnums';
import { SavingsCategoryDetailEnums } from '../EnumsUpdateSavingsBucketModal';
import {
  IGetFormattedKey,
  IGetInitialFormikValues,
  IGetKeysToAdd,
  IGetMappedFormikValues,
  IGetSavingsSummaryValues
} from '../IUpdateSavingsBucketModal';
import {
  TMappedFormikValues,
  TSavingsCategoryDetail
} from '../TUpdateSavingsBucketModal';

const getFormattedKey: IGetFormattedKey = (bucketName, keyName) => {
  const modifiedKey = keyName.charAt(0).toUpperCase() + keyName.slice(1);

  return `${bucketName}${modifiedKey}`;
};

export const getInitialFormikValues: IGetInitialFormikValues = (
  savingsCategoryDetail,
  bucketName
) => {
  const { ADDED, PASSED, TO_APPROVED, TO_RECEIVED, TO_SUBMITTED } =
    SavingsCategoryDetailEnums;

  const initialFormikValues = {
    [ADDED]: '0',
    [PASSED]: '0',
    [TO_APPROVED]: '0',
    [TO_RECEIVED]: '0',
    [TO_SUBMITTED]: '0'
  };

  getKeysToAdd(bucketName).forEach(key => {
    const formattedKey = getFormattedKey(bucketName, key);

    initialFormikValues[key] =
      savingsCategoryDetail[
        formattedKey as keyof TSavingsCategoryDetail
      ].toString();
  });

  return initialFormikValues;
};

const getKeysToAdd: IGetKeysToAdd = bucketName => {
  const { ADDED, PASSED, TO_APPROVED, TO_RECEIVED, TO_SUBMITTED } =
    SavingsCategoryDetailEnums;
  const { APPROVED, POTENTIAL, SUBMITTED } = SavingsCategoryBucketEnums;

  const keysToAdd = [ADDED, PASSED];

  switch (bucketName) {
    case POTENTIAL:
      keysToAdd.push(...[TO_SUBMITTED, TO_APPROVED, TO_RECEIVED]);
      break;
    case SUBMITTED:
      keysToAdd.push(...[TO_APPROVED, TO_RECEIVED]);
      break;
    case APPROVED:
      keysToAdd.push(...[TO_RECEIVED]);
      break;
  }

  return keysToAdd;
};

export const getMappedToFloatFormikValues: IGetMappedFormikValues = (
  bucketName,
  formikValues
) => {
  const mappedFormikValues: TMappedFormikValues = {};

  getKeysToAdd(bucketName).forEach(keyName => {
    mappedFormikValues[getFormattedKey(bucketName, keyName)] = parseFloat(
      formikValues[keyName as keyof typeof formikValues]
    );
  });

  return mappedFormikValues;
};

export const getSavingsSummaryValues: IGetSavingsSummaryValues = (
  savingsCategoryDetail,
  formikValues,
  bucketName,
  lastSavingsForBucket
) => {
  const { ADDED, PASSED, TO_APPROVED, TO_RECEIVED, TO_SUBMITTED } =
    SavingsCategoryDetailEnums;
  const { APPROVED, POTENTIAL, RECEIVED, SUBMITTED } =
    SavingsCategoryBucketEnums;

  const mappedFormikValues = getMappedToFloatFormikValues(
    bucketName,
    formikValues
  );

  const updatedSavingsCategoryDetail: TSavingsCategoryDetail = {
    ...savingsCategoryDetail,
    ...mappedFormikValues
  };

  const initialAmount = lastSavingsForBucket;
  const totalAddedPassed = sum(
    updatedSavingsCategoryDetail[
      getFormattedKey(bucketName, ADDED) as keyof TSavingsCategoryDetail
    ] as number,
    -updatedSavingsCategoryDetail[
      getFormattedKey(bucketName, PASSED) as keyof TSavingsCategoryDetail
    ] as number
  );

  let totalAdvancedIn: number;
  let totalAdvancedOut: number;

  switch (bucketName) {
    case POTENTIAL:
      totalAdvancedIn = 0;
      totalAdvancedOut = sum(
        -updatedSavingsCategoryDetail[
          getFormattedKey(
            bucketName,
            TO_SUBMITTED
          ) as keyof TSavingsCategoryDetail
        ] as number,
        -updatedSavingsCategoryDetail[
          getFormattedKey(
            bucketName,
            TO_APPROVED
          ) as keyof TSavingsCategoryDetail
        ] as number,
        -updatedSavingsCategoryDetail[
          getFormattedKey(
            bucketName,
            TO_RECEIVED
          ) as keyof TSavingsCategoryDetail
        ] as number
      );
      break;

    case SUBMITTED:
      totalAdvancedIn = updatedSavingsCategoryDetail[
        getFormattedKey(POTENTIAL, TO_SUBMITTED) as keyof TSavingsCategoryDetail
      ] as number;
      totalAdvancedOut = sum(
        -updatedSavingsCategoryDetail[
          getFormattedKey(
            bucketName,
            TO_APPROVED
          ) as keyof TSavingsCategoryDetail
        ] as number,
        -updatedSavingsCategoryDetail[
          getFormattedKey(
            bucketName,
            TO_RECEIVED
          ) as keyof TSavingsCategoryDetail
        ] as number
      );
      break;

    case APPROVED:
      totalAdvancedIn = sum(
        updatedSavingsCategoryDetail[
          getFormattedKey(
            POTENTIAL,
            TO_APPROVED
          ) as keyof TSavingsCategoryDetail
        ] as number,
        updatedSavingsCategoryDetail[
          getFormattedKey(
            SUBMITTED,
            TO_APPROVED
          ) as keyof TSavingsCategoryDetail
        ] as number
      );
      totalAdvancedOut = -updatedSavingsCategoryDetail[
        getFormattedKey(bucketName, TO_RECEIVED) as keyof TSavingsCategoryDetail
      ] as number;
      break;

    case RECEIVED:
      totalAdvancedIn = sum(
        updatedSavingsCategoryDetail[
          getFormattedKey(
            POTENTIAL,
            TO_RECEIVED
          ) as keyof TSavingsCategoryDetail
        ] as number,
        updatedSavingsCategoryDetail[
          getFormattedKey(
            SUBMITTED,
            TO_RECEIVED
          ) as keyof TSavingsCategoryDetail
        ] as number,
        updatedSavingsCategoryDetail[
          getFormattedKey(APPROVED, TO_RECEIVED) as keyof TSavingsCategoryDetail
        ] as number
      );
      totalAdvancedOut = 0;
      break;
  }

  const totalChanges = sum(totalAddedPassed, totalAdvancedIn, totalAdvancedOut);
  const finalAmount = sum(initialAmount, totalChanges);

  return {
    initialAmount,
    totalAddedPassed,
    totalAdvancedIn,
    totalAdvancedOut,
    totalChanges,
    finalAmount
  };
};
