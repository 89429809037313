export enum NewUserRequestStatus {
  // New user has been requested, but there is no user in db.
  // We're waiting for Salesforce to create the user.
  Pending = 1,
  // Salesforce created the user and sent it back, so we now have a user in the db,
  // but we're waiting for Salesforce to assign engagements.
  // Ryan users see them with "Review" in New User Requests table, but the link is disabled.
  // They just can't do anything to them yet!
  ReviewEngagements,
  // User in the db, and the user has engagements.
  // Ryan users see them with "Review" in New User Requests table, and should click to assign them a role.
  ReviewDxpRole,
  // User has a role assigned but may not be activated yet.
  // They will still show in the New User Requests table if not yet activated.
  Complete,
  // Request was denied.
  // Will not show in the New User Requests table.
  Denied,
  // New user has been requested, but the email address is associated with multiple contacts.
  // We're waiting for that to get resolved in Salesforce.
  PendingMultipleEmails
}
