import AutocompleteAjax, {
  IAutocompleteAjaxProps
} from 'components/AutocompleteAjax';
import { useStateMounted } from 'hooks';
import { ICountry } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import { escapeRegexCharacters } from 'utils/escapeRegexCharacters';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { autocompleteHighlight } from '@ryan/components';

type ICountryAutocompleteProps = Omit<
  IAutocompleteAjaxProps<ICountry>,
  'getOptionValue' | 'onFetchOptions' | 'renderOption'
>;

const getOptionValue = (country: ICountry) => country.ryanCountryName;

const renderOption = (country: ICountry, { query }: { query: string }) =>
  autocompleteHighlight(country.ryanCountryName, query);

const CountryAutocomplete: React.FC<ICountryAutocompleteProps> = ({
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const sourceRef = useRef<CancelTokenSource>();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [loading, setLoading] = useStateMounted(false);

  useEffect(() => {
    sourceRef.current = ApiService.CancelToken.source();
    setLoading(true);
    ApiService.getCountries(sourceRef.current.token)
      .then(response => {
        setCountries(response.data);
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      // cancel an ongoing request
      sourceRef.current?.cancel(
        'cancelling previous CountryAutocomplete fetch'
      );
    };
  }, [setLoading]);

  const onFetchOptions = useCallback(
    async (query: string) => {
      const regExp = new RegExp(escapeRegexCharacters(query), 'gi');
      return countries.filter(country => regExp.test(country.ryanCountryName));
    },
    [countries]
  );

  return (
    <AutocompleteAjax<ICountry>
      disabled={loading || disabled}
      getOptionValue={getOptionValue}
      label={t('Country')}
      loading={loading}
      onFetchOptions={onFetchOptions}
      renderOption={renderOption}
      {...rest}
    />
  );
};

export default CountryAutocomplete;
