import React, { FunctionComponent } from 'react';
import './NotificationPreferenceCardSkeleton.scss';

export const NotificationPreferenceCardSkeleton: FunctionComponent = () => (
  <div className="notification-preference-card__ry-skeleton">
    <table>
      <tbody>
        <tr>
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
        </tr>
        <tr>
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
        </tr>
        <tr>
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
          <td className="ry-skeleton" />
        </tr>
      </tbody>
    </table>
  </div>
);
