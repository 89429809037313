import AutocompleteAjax, {
  IAutocompleteAjaxProps
} from 'components/AutocompleteAjax';
import { IUserSummary, UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import getAvatarUrl from 'utils/getAvatarUrl';

import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, autocompleteHighlight } from '@ryan/components';

import './UserAutocomplete.scss';

type TUser = Omit<IUserSummary, 'firstName' | 'lastName'> & {
  firstName: string;
  hasDxpAccess: boolean;
  lastName: string;
};

interface IUserAutocompleteProps
  extends Omit<
    IAutocompleteAjaxProps<IUserSummary>,
    'getOptionValue' | 'renderOption' | 'onFetchOptions'
  > {
  /**
   * Returns users associated with the account.
   */
  accountGuid?: string;

  /**
   * Returns users associated with the enagement.
   */
  engagementGuid?: string;

  isQueryEngagementGuid?: boolean;

  /**
   * Only return users of this usertype.
   *
   * @todo Not supported for engagement members!
   */
  userType?: UserType;

  /**
   * If provided, all other props ignored.
   */
  onFetchOptions?: (query: string) => Promise<IUserSummary[]>;

  /**
   * Handler to transform the response from the endpoint.
   */
  transformResponse?: (users: IUserSummary[]) => IUserSummary[];
}

const getOptionValue = (user: IUserSummary): string => user?.fullName || '';

const renderOption = (
  user: IUserSummary,
  { query }: { query: string }
): JSX.Element => (
  <div className="user-autocomplete__option">
    <Avatar
      {...((user as TUser).hasDxpAccess !== undefined &&
        !(user as TUser).hasDxpAccess && { variant: 'inverse' })}
      firstName={(user as TUser).firstName}
      lastName={(user as TUser).lastName}
      profileImageSrc={getAvatarUrl({
        avatarUrl: user.avatarUrl,
        userAvatarDocumentGuid: user.userAvatarDocumentGuid
      })}
    />
    <div className="user-autocomplete__option-text">
      {autocompleteHighlight(getOptionValue(user), query)}
      <div className="user-autocomplete__option-role">{user.title}</div>
    </div>
  </div>
);

const UserAutocomplete: FunctionComponent<IUserAutocompleteProps> = ({
  accountGuid,
  engagementGuid,
  isQueryEngagementGuid = false,
  userType,
  onFetchOptions: onFetchOptionsProp,
  transformResponse,
  ...props
}) => {
  const { t } = useTranslation();
  const onFetchOptions = useCallback(
    (query: string) => {
      let request: ReturnType<
        | typeof ApiService.engagementMembersAutocomplete
        | typeof ApiService.getUsersAutocomplete
      >;

      if (engagementGuid && !isQueryEngagementGuid) {
        request = ApiService.engagementMembersAutocomplete(
          engagementGuid,
          query
        );
      } else {
        request = ApiService.getUsersAutocomplete({
          accountGuid,
          engagementGuid,
          searchTerm: query,
          userType
        });
      }

      return request
        .then(response => response.data)
        .then(transformResponse || ((users: IUserSummary[]) => users));
    },
    [
      accountGuid,
      engagementGuid,
      isQueryEngagementGuid,
      userType,
      transformResponse
    ]
  );

  return (
    <AutocompleteAjax<IUserSummary>
      getOptionValue={getOptionValue}
      label={t('Select User')}
      onFetchOptions={onFetchOptionsProp || onFetchOptions}
      renderOption={renderOption}
      {...props}
    />
  );
};

export default UserAutocomplete;
