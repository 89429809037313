import StatusIcon from 'components/StatusIcon/StatusIcon';
import { IMilestoneRequest } from 'interfaces';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import MilestoneDates from './MilestoneDates';

export interface IMilestoneHeaderProps {
  milestone: IMilestoneRequest;
  isActive: boolean;
  onClick?: () => void;
}

const MilestoneHeader: FunctionComponent<IMilestoneHeaderProps> = ({
  milestone,
  isActive,
  onClick
}) => {
  const { t } = useTranslation();

  return (
    <h3 className="milestone-header">
      <button
        aria-label={t('milestones.toggle', { name: milestone.title })}
        disabled={!onClick}
        onClick={onClick}
        type="button"
      >
        <StatusIcon iconOnly size="lg" status={milestone.statusId} />
        {milestone.title}
        <MilestoneDates milestone={milestone} />
        <Icon
          className="milestone-header__caret"
          name={isActive ? 'chevron-up' : 'chevron-down'}
        />
      </button>
    </h3>
  );
};

export default MilestoneHeader;
