import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

import ApiService from '../../../services/ApiService';
import Modal from '../Modal';
import {
  DenyUserModalEnums,
  IDenyUserModalProps,
  IHandleDenyAccess
} from './utils';

import './DenyUserModal.scss';

const DenyUserModal: React.FC<IDenyUserModalProps> = ({
  guids,
  onClose,
  onDenyRequest,
  userFullName
}) => {
  const { t: textToDisplay } = useTranslation();

  const { ROOT_TO_TEXT } = DenyUserModalEnums;

  const handleDenyAccess: IHandleDenyAccess = async ({
    callbacks,
    queueItemGuid,
    toastTitles,
    userGuid
  }) => {
    try {
      await ApiService.denyNewUserRequest(queueItemGuid, userGuid);

      pushToast({
        title: toastTitles.successToastTitle,
        type: 'success'
      });

      callbacks.onDenyRequestCallback();
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushToast({
          title: toastTitles.errorToastTitle,
          type: 'error'
        });
      }

      callbacks.onCloseCallback();
    }
  };

  return (
    <Modal
      className="deny-user-modal"
      onClose={onClose}
      open
      title={textToDisplay(`${ROOT_TO_TEXT}.title`)}
    >
      <p>
        <Trans i18nKey={`${ROOT_TO_TEXT}.content`}>
          <b>{{ userFullName }}</b>
        </Trans>
      </p>

      <ButtonGroup>
        <Button
          onClick={onClose}
          text={textToDisplay(`${ROOT_TO_TEXT}.noCancel`)}
        />

        <Button
          negative
          onClick={() =>
            handleDenyAccess({
              callbacks: {
                onCloseCallback: onClose,
                onDenyRequestCallback: onDenyRequest
              },
              queueItemGuid: guids.queueItemGuid,
              toastTitles: {
                errorToastTitle: textToDisplay(
                  `${ROOT_TO_TEXT}.errorToast.title`,
                  { userFullName }
                ),
                successToastTitle: textToDisplay(
                  `${ROOT_TO_TEXT}.successToast.title`,
                  { userFullName }
                )
              },
              userGuid: guids.userGuid
            })
          }
          text={textToDisplay(`${ROOT_TO_TEXT}.yesDenyAccess`)}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default DenyUserModal;
