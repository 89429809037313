import * as CommonActionsEnums from '../CommonActions/CommonActions.enums';
import * as UseWorklistActionsEnums from './useWorklistActions.enums';
import * as UseWorklistActionsInterfaces from './useWorklistActions.interfaces';

export const actionTemplate = [
  UseWorklistActionsEnums.EActionType.CREATE_DATA_REQUEST,
  UseWorklistActionsEnums.EActionType.CREATE_TASK
];

export const getDataForCommonAction: UseWorklistActionsInterfaces.IGetDataForCommonAction =
  props => {
    const { actionType } = props;

    const dataForAction = {
      actionType
    } as UseWorklistActionsInterfaces.TCommonActionProps;

    switch (actionType) {
      case CommonActionsEnums.EActionType.TEXT_BUTTON:
        dataForAction[actionType] = {
          ...dataForAction,
          icon: props.icon,
          isDisabled: props.isDisabled,
          onClick: props.onClick,
          textPath: props.textPath
        };
        break;
      default:
    }

    return dataForAction;
  };
