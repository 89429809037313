import {
  ArrayHelpers,
  Field,
  FieldArray,
  FieldProps,
  FormikProps
} from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant } from '@ryan/components';

import { MultiSelectDateRangeField } from './MultiSelectDateRangeField/MultiSelectDateRangeField';
import { IMultiSelectDateRangeProps, TMultiSelectDateRange } from './utils';

import './MultiSelectDateRange.scss';

interface IMultiSelectDateRangeFormikProps extends IMultiSelectDateRangeProps {
  formik: FormikProps<any>;
}

export const MultiSelectDateRange = ({
  daterange,
  formik,
  isRequired,
  name
}: IMultiSelectDateRangeFormikProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { t: textToDisplay } = useTranslation();

  const checkIfStateIsEmpty = (dateRange: TMultiSelectDateRange[]) => {
    return dateRange.some(
      range => range.startDate === null || range.endDate === null
    );
  };

  useEffect(() => {
    setIsDisabled(checkIfStateIsEmpty(daterange));
  }, [daterange]);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: ArrayHelpers) => (
        <div className="multiselect-daterange">
          {daterange.map((value: TMultiSelectDateRange, index: number) => {
            return (
              <div key={index}>
                <Field name={`${name}[${index}]`}>
                  {({ field, form }: FieldProps) => {
                    return (
                      <MultiSelectDateRangeField
                        key={`${name}-${index}-${value.startDate || 'empty'}`}
                        {...field}
                        errors={formik.errors}
                        formik={formik}
                        index={index}
                        isRequired={isRequired}
                        item={{
                          endDate: value.endDate,
                          startDate: value.startDate
                        }}
                        onChange={(value: TMultiSelectDateRange) => {
                          arrayHelpers.replace(index, value);
                          form.setFieldValue(field.name, value);
                        }}
                        onRemove={() => {
                          arrayHelpers.remove(index);
                          formik.setFieldValue(
                            `${name}[${index}].startDate`,
                            null
                          );
                          formik.setFieldValue(
                            `${name}[${index}].endDate`,
                            null
                          );
                        }}
                        setIsDisabled={setIsDisabled}
                        values={daterange}
                      />
                    );
                  }}
                </Field>
              </div>
            );
          })}
          <div className="multiselect-daterange__add-button">
            <Button
              data-testid="add-date-range-button"
              disabled={isDisabled}
              icon="plus"
              iconPlacement="before"
              onClick={() => {
                arrayHelpers.push({
                  startDate: null,
                  endDate: null
                });
                setIsDisabled(true);
              }}
              variant={EButtonVariant.TEXT}
            >
              {textToDisplay('multiSelectDateRange.add')}
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default MultiSelectDateRange;
