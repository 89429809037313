import React from 'react';

export interface IPathHistoryContext {
  addPath: (pathname: string) => void;
  pathHistory: string[];
}

export const PathHistoryContext = React.createContext<IPathHistoryContext>({
  addPath: () => {},
  pathHistory: []
});

export default PathHistoryContext;
