import { getFormattedUserInfo } from '../../../../../utils';
import { IFormatToUserNameAndProfile } from '../IUserNameAndProfile';

export const formatToUserNameAndProfile: IFormatToUserNameAndProfile = ({
  additionalInfoKey,
  user
}) => {
  return {
    ...(additionalInfoKey && {
      optionalInfo: user[additionalInfoKey as keyof typeof user]
    }),
    ...(user.memberGuid && { memberGuid: user.memberGuid }),
    avatarDocumentGuid: user.userAvatarDocumentGuid || null,
    avatarUrl: user.avatarUrl || null,
    firstName: user.firstName || '',
    fullName: getFormattedUserInfo('fullName', user),
    hasDxpAccess: user.hasDxpAccess || false,
    isGhosted: user.isGhosted || false,
    lastName: user.lastName || ''
  };
};
