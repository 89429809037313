import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IButtonProps } from '@ryan/components';
import { useUser } from 'contexts/UserContext';
import NewUserContext from './NewUserContext';

const NewUserButton: React.FC<IButtonProps> = ({
  children,
  disabled,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isAppReadOnly, permissionService } = useUser();

  if (permissionService.canRequestNewUser()) {
    return (
      <NewUserContext.Consumer>
        {onNewUser => (
          <Button
            disabled={disabled || isAppReadOnly}
            icon="plus"
            onClick={e => {
              onNewUser();
              onClick?.(e);
            }}
            {...rest}
          >
            {children || t('manageTeam.newUser.request')}
          </Button>
        )}
      </NewUserContext.Consumer>
    );
  }

  return null;
};

export default NewUserButton;
