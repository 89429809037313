import { CancelToken } from 'axios';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, EButtonVariant, Icon } from '@ryan/components';

import ApiService from '../../../../services/ApiService';
import { SavingsSummaryEnums } from '../../../../utils/enums/SavingsSummaryEnums';
import { formatDate } from '../../../../utils/formatDate';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';
import BarChart from '../../../BarChart/BarChart';
import Currency from '../../../Currency/Currency';
import InfoTooltip from '../../../InfoTooltip/InfoTooltip';
import { IProjectSnapshotSavingsProps, IRenderBarChartDetail } from './utils';

import './ProjectSnapshotSavings.scss';

const ProjectSnapshotSavings: React.FC<IProjectSnapshotSavingsProps> = ({
  engagementGuid,
  handleViewSavings
}) => {
  const [currencyCode, setCurrencyCode] = useState('USD');
  const [isLoading, setIsLoading] = useState(true);
  const [savingsSummary, setSavingsSummary] = useState({
    approved: 0,
    potential: 0,
    received: 0,
    submitted: 0
  });
  const [savingsTotal, setSavingsTotal] = useState(0);
  const { t: textToDisplay } = useTranslation();

  const barChartOrder = [
    {
      keyName: SavingsSummaryEnums.POTENTIAL,
      style: { backgroundColor: SavingsSummaryEnums.POTENTIAL_COLOR }
    },
    {
      keyName: SavingsSummaryEnums.SUBMITTED,
      style: { backgroundColor: SavingsSummaryEnums.SUBMITTED_COLOR }
    },
    {
      keyName: SavingsSummaryEnums.APPROVED,
      style: { backgroundColor: SavingsSummaryEnums.APPROVED_COLOR }
    },
    {
      keyName: SavingsSummaryEnums.RECEIVED,
      style: { backgroundColor: SavingsSummaryEnums.RECEIVED_COLOR }
    }
  ];
  const isSavingsEmpty = savingsTotal === 0;
  const textToDisplayPath = 'table.projectSnapshot.savings.';

  const barChartDataPoints = barChartOrder.map(dataPoint => ({
    ...dataPoint,
    value: savingsSummary[dataPoint.keyName as keyof typeof savingsSummary]
  }));

  const initialize = useCallback(
    async (guid: string, cancelToken: CancelToken) => {
      try {
        const response = await ApiService.getEngagementSavingsSummaryRollup(
          guid,
          undefined,
          undefined,
          cancelToken
        );

        if (response.status === 204) {
          return;
        } else if (response.data && response.data.summariesPerCurrency[0]) {
          const {
            currencyCode: savingsCurrencyCode,
            totals: { approved, potential, received, summary, submitted }
          } = response.data.summariesPerCurrency[0];

          setCurrencyCode(savingsCurrencyCode);
          setSavingsSummary({ approved, potential, received, submitted });
          setSavingsTotal(summary);
        } else {
          throw new Error('response data missing');
        }
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setCurrencyCode, setIsLoading, setSavingsSummary, setSavingsTotal]
  );

  useEffect(() => {
    const cancelTokenSource = ApiService.CancelToken.source();

    initialize(engagementGuid, cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [engagementGuid, initialize]);

  const renderBarChartDetail: IRenderBarChartDetail = (
    { backgroundColor, title, value },
    savingsTotal,
    savingsCurrencyCode
  ) => (
    <div key={title}>
      <div
        className="project-snapshot-savings__breakdown__bar-chart__details__marker"
        style={{ backgroundColor }}
      />
      <span className="project-snapshot-savings__breakdown__bar-chart__details__title">
        {title}
      </span>
      <span className="project-snapshot-savings__breakdown__bar-chart__details__amount">
        <Currency
          currencyCode={savingsCurrencyCode}
          includeCents={false}
          includeSymbol={false}
          value={value}
        />
      </span>
      <span className="project-snapshot-savings__breakdown__bar-chart__details__percent">
        {parseFloat(((value / savingsTotal) * 100).toFixed(2))}%
      </span>
    </div>
  );

  return (
    <div className="project-snapshot-savings">
      <Button
        disabled={isLoading}
        onClick={
          handleViewSavings as any as MouseEventHandler<HTMLButtonElement>
        }
        size={EButtonSizes.SMALL}
        text={textToDisplay(`${textToDisplayPath}view`)}
        variant={EButtonVariant.TEXT}
      />

      <h4 className="ry-h4">{textToDisplay(`${textToDisplayPath}title`)}</h4>

      {isLoading && (
        <div className="project-snapshot-savings__loading">
          <Icon className="loading-spin" name="loading" />
        </div>
      )}

      {!isLoading && isSavingsEmpty && (
        <div className="project-snapshot-savings__no-savings">
          <Icon name="calculator" />{' '}
          {textToDisplay(`${textToDisplayPath}noSavings`)}
        </div>
      )}

      {!isLoading && !isSavingsEmpty && (
        <div className="project-snapshot-savings__breakdown">
          <div className="project-snapshot-savings__breakdown__summary">
            {textToDisplay(`${textToDisplayPath}totalAsOf`, {
              date: formatDate(new Date())
            })}
            <span className="project-snapshot-savings__breakdown__summary__total">
              <Currency
                currencyCode={currencyCode}
                includeCents={false}
                value={savingsTotal}
              />
            </span>
            {textToDisplay(`${textToDisplayPath}mayIncludeEstimates`)}
            <InfoTooltip>
              {textToDisplay(`${textToDisplayPath}tooltip`)}
            </InfoTooltip>
          </div>

          <div className="project-snapshot-savings__breakdown__bar-chart">
            <BarChart barChartDataPoints={barChartDataPoints} />
            <div className="project-snapshot-savings__breakdown__bar-chart__details">
              {barChartDataPoints.map(({ keyName, style, value }) =>
                renderBarChartDetail(
                  {
                    backgroundColor: style.backgroundColor,
                    title: textToDisplay(
                      `${textToDisplayPath}barChart.${keyName}`
                    ),
                    value
                  },
                  savingsTotal,
                  currencyCode
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectSnapshotSavings;
