import TaskModal from 'components/TaskActions/TaskModal/TaskModal';
import TaskTable from 'components/TaskTable/TaskTable';
import EngagementContext from 'contexts/EngagementContext';
import { WithTaskDrawer, withTaskDrawer } from 'contexts/TaskDrawerContext';
import { WithUser, withUser } from 'contexts/UserContext';
import { IEngagement, ITask, Permission } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  pushToast
} from '@ryan/components';

import { TasksEmitter } from './TasksEmitter';

import './Tasks.scss';

interface ITasksProps
  extends WithTranslation,
    WithUser,
    WithTaskDrawer,
    RouteComponentProps<{ engagementGuid: string; taskGuid?: string }> {
  engagement: IEngagement;
}

interface ITasksState {
  isNewTaskModalOpen: boolean;
  task: ITask | null;
}

class Tasks extends Component<ITasksProps, ITasksState> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  private updateEmitter = TasksEmitter;

  readonly state: ITasksState = {
    isNewTaskModalOpen: false,
    task: null
  };

  componentDidMount() {
    const { taskDrawerEvents } = this.props;
    this.fetchLinkedTask();
    taskDrawerEvents.addListener('commentAdded', this.handleCommentAdded);
    taskDrawerEvents.addListener('commentEdited', this.handleCommentEdited);
    taskDrawerEvents.addListener('commentRemoved', this.handleCommentRemoved);
  }

  componentDidUpdate(prevProps: ITasksProps) {
    const {
      match: { params: prevParams },
      location: { hash: prevHash }
    } = prevProps;
    const {
      match: { params },
      location: { hash }
    } = this.props;

    if (params.taskGuid !== prevParams.taskGuid) {
      this.fetchLinkedTask();
    }

    // makes sure to open the comment drawer every time the URL contains `#comment`
    if (prevHash !== hash) {
      this.openCommentsDrawer();
    }
  }

  componentWillUnmount() {
    const { taskDrawerEvents } = this.props;
    taskDrawerEvents.removeListener('commentAdded', this.handleCommentAdded);
    taskDrawerEvents.removeListener('commentEdited', this.handleCommentEdited);
    taskDrawerEvents.removeListener(
      'commentRemoved',
      this.handleCommentRemoved
    );
  }

  fetchLinkedTask() {
    const { t, match } = this.props;
    const { engagementGuid } = match.params;
    const { taskGuid } = match.params;

    // If a taskGuid is in the url, fetch it.
    // If it is deleted, notify user.
    // If not deleted, maybe open comments.
    if (taskGuid) {
      ApiService.getTask(engagementGuid, taskGuid)
        .then(({ data: task }) => {
          // on load, if URL contains `#comments`, will open comments drawer
          this.setState({ task }, this.openCommentsDrawer);
        })
        .catch(error => {
          if (error?.response?.status === 410) {
            pushToast({
              type: EMessageTypes.WARNING,
              title: t('task.linkedTaskHasBeenDeleted')
            });
          }
        });
    }
  }

  openCommentsDrawer = () => {
    const { task } = this.state;

    if (task && this.props.location.hash === '#comments') {
      this.props.onTaskDrawerOpen(task);
    }
  };

  updateTaskTable = () => {
    this.updateEmitter.emit('update');
  };

  handleCommentAdded = (eventData: any) => {
    this.updateTaskTable();
    this.context.refreshUpdateDate?.(eventData?.engagementGuid);
  };

  handleCommentEdited = (eventData: any) => {
    this.context.refreshUpdateDate?.(eventData?.engagementGuid);
  };

  handleCommentRemoved = (eventData: any) => {
    this.updateTaskTable();
  };

  handleNewTask = () => {
    this.setState({ isNewTaskModalOpen: true });
  };

  handleNewTaskClose = (task?: ITask) => {
    this.setState({ isNewTaskModalOpen: false });

    // on successful request...
    if (task) {
      this.updateTaskTable();
    }
  };

  renderActions = () => {
    const { engagement, isAppReadOnly, permissionService: ps, t } = this.props;

    return (
      <>
        {ps.hasPermission(Permission.TasksEdit) && (
          <Button
            disabled={
              engagement.isReadOnly || isAppReadOnly || engagement.isUserGhosted
            }
            icon="plus"
            onClick={this.handleNewTask}
            size={EButtonSizes.SMALL}
            text={t('New Task')}
            variant={EButtonVariant.TEXT}
          />
        )}
      </>
    );
  };

  render() {
    const { engagement } = this.props;
    const { isNewTaskModalOpen } = this.state;

    return (
      <div className="project-tasks-page">
        <TaskTable
          engagementGuid={engagement.engagementGuid}
          renderActions={this.renderActions}
          updateEmitter={this.updateEmitter}
        />

        <TaskModal
          engagementGuid={engagement.engagementGuid}
          onClose={this.handleNewTaskClose}
          open={isNewTaskModalOpen}
        />
      </div>
    );
  }
}

export default withTranslation()(withUser(withTaskDrawer(Tasks)));
