import React, { FunctionComponent } from 'react';
import { IMilestoneRequest, MilestoneType } from 'interfaces';
import { formatDate } from 'utils/formatDate';

interface IMilestoneDatesProps {
  milestone: IMilestoneRequest;
}

const MilestoneDates: FunctionComponent<IMilestoneDatesProps> = ({
  milestone: { milestoneTypeId, startDate, endDate }
}) => (
  <span className="milestone-dates">
    {milestoneTypeId === MilestoneType.Kickoff &&
      startDate &&
      formatDate(startDate)}
    {milestoneTypeId === MilestoneType.Interim &&
      startDate &&
      endDate &&
      (formatDate(startDate) === formatDate(endDate)
        ? `${formatDate(endDate)}`
        : `${formatDate(startDate)} – ${formatDate(endDate)}`)}
    {milestoneTypeId === MilestoneType.End && endDate && formatDate(endDate)}
  </span>
);

export default MilestoneDates;
