import { ILastUpdatedCardProps } from './LastUpdatedCard.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Card, Icon } from '@ryan/components';

import { useAmplitude } from '../../../contexts/AmplitudeContext/AmplitudeConsumer';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from '../../../utils/amplitudeUtils/amplitudeUtils';

import './LastUpdatedCard.scss';

const LastUpdatedCard: React.FC<ILastUpdatedCardProps> = ({ engagements }) => {
  const ROOT_TO_TEXT = 'dashboard.lastUpdatedCard';

  const { triggerAmplitudeEvent } = useAmplitude();
  const location = useLocation();
  const { t: getTextToDisplay } = useTranslation();

  const renderLastUpdatedSkeleton = () => {
    const skeletonItem = <div className="ry-skeleton sk-title" />;

    return (
      <div className="last-updated-card__skeleton">
        <div>{skeletonItem}</div>
        <div>{skeletonItem}</div>
        <div>{skeletonItem}</div>
        <div>{skeletonItem}</div>
        <div>{skeletonItem}</div>
      </div>
    );
  };

  return (
    <Card
      className="last-updated-card"
      role="region"
      title={getTextToDisplay(`${ROOT_TO_TEXT}.lastUpdated`)}
    >
      {engagements === null
        ? renderLastUpdatedSkeleton()
        : engagements.map(engagement => (
            <Link
              className="last-updated-card__link"
              key={engagement.engagementGuid}
              onClick={() => {
                // NOTE: Handling of "onClick" event and URL redirect does not appear to be synchronous. Using triggerAmplitudeEvent.
                triggerAmplitudeEvent({
                  amplitudeEventAction: AmplitudeActionType.CLICK,
                  amplitudeEventLocation: location.pathname,
                  amplitudeEventName:
                    amplitudeEventDetail.dashboard
                      .viewLastUpdatedEngagementEventName
                });
              }}
              to={`/app/project/${engagement.engagementGuid}/overview`}
            >
              <div className="last-updated-card__link__content">
                <div>
                  <span className="last-updated-card__link__content--display-name">
                    {engagement.engagementDisplayNameLong}
                  </span>
                  <span className="last-updated-card__link__content--account-name">
                    {engagement.accountName}
                  </span>
                </div>
                <Icon name="chevron-right" />
              </div>
            </Link>
          ))}
    </Card>
  );
};

export default LastUpdatedCard;
