import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import Modal from '../../../components/Modal';
import { FileBatch } from '../utils/FileBatch';
import { getFolderAndFileActionStatusTitle } from '../utils/getFolderAndFileActionStatusTitle';

interface IFileBatchArchiveModalProps extends WithTranslation {
  fileBatch: FileBatch;
  loading: Promise<any> | null;
  onCancel: () => void;
  onSubmit: () => void;
}

const FileBatchArchiveModal: React.FC<IFileBatchArchiveModalProps> = ({
  fileBatch,
  loading,
  onCancel,
  onSubmit,
  t
}) => {
  const { numberOfLinkedItems } = fileBatch;

  let subPath;

  if (numberOfLinkedItems) {
    subPath = `SingleFile${
      numberOfLinkedItems === 1 ? 'SingleLink' : 'MultipleLinks'
    }`;
  } else {
    subPath = 'MultipleFile';
  }

  return (
    <Modal
      onClose={onCancel}
      open
      title={t(
        ...getFolderAndFileActionStatusTitle({
          basePathToTitle: 'file.batchArchiveModal.title',
          files: fileBatch,
          isNestedArchivedFilesExcluded: true
        })
      )}
    >
      <p>
        {t(`file.batchArchiveModal.link${subPath}Content`, {
          ...(Boolean(numberOfLinkedItems) && { count: numberOfLinkedItems })
        })}
      </p>
      <p>{t('file.batchArchiveModal.warningRestoration')}</p>

      <ButtonGroup>
        <Button
          loading={loading}
          onClick={onSubmit}
          text={t('yesArchive')}
          variant={EButtonVariant.PRIMARY}
        />
        <Button
          disabled={loading !== null}
          onClick={onCancel}
          text={t('No, Cancel')}
          variant={EButtonVariant.SECONDARY}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default withTranslation()(FileBatchArchiveModal);
