import {
  IContract,
  IContractFile,
  IContractFileSearch,
  IPagedDataResponse,
  ISearchTerm
} from 'interfaces';
import { transformResponse } from 'utils/transformResponse';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

/**
 * Get a list of contracts/these are engagements that have contracts with files
 */
export async function getContractFolders(
  this: ApiBase,
  customViewGuid: string,
  params: ISearchTerm,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IContract>>(
    `/api/customViews/${customViewGuid}/contracts/search`,
    { cancelToken, params }
  );
}

/**
 * Get a list of contract files by engagementGuid
 */
export async function getContractFilesByEngagementGuid(
  this: ApiBase,
  engagementGuid: string,
  params: IContractFileSearch,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IContractFile>>(
    `/api/engagements/${engagementGuid}/contractFiles/search`,
    { cancelToken, params, transformResponse }
  );
}

/**
 * Get a list of contract files
 */
export async function getContractFilesByEngagement(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IContractFile[]>(
    `/api/engagements/${engagementGuid}/files`,
    { cancelToken, transformResponse }
  );
}

/**
 * Upsert information on a contract file
 */
export async function upsertContractFile(
  this: ApiBase,
  engagementGuid: string,
  contractDocumentGuid: string,
  params: IContractFile
) {
  return unwrapCommandResponse<IContractFile>(
    this.server.put(
      `/api/engagements/${engagementGuid}/contracts/files/${contractDocumentGuid}`,
      {
        executedDate: params.executedDate,
        status: params.status,
        name: params.name,
        type: params.typeId
      }
    )
  );
}

/**
 * Publish contract file(s) selected
 */
export async function publishContractFiles(
  this: ApiBase,
  engagementGuid: string,
  contractDocumentGuids: string[],
  cancelToken?: CancelToken
) {
  return unwrapCommandResponse(
    this.server.post(
      `/api/engagements/${engagementGuid}/contracts/files/publish`,
      { cancelToken, contractDocumentGuids }
    )
  );
}

/**
 * Unpublish contract file(s) selected
 */
export async function unpublishContractFiles(
  this: ApiBase,
  engagementGuid: string,
  contractDocumentGuids: string[],
  cancelToken?: CancelToken
) {
  return unwrapCommandResponse(
    this.server.post(
      `/api/engagements/${engagementGuid}/contracts/files/unpublish`,
      { cancelToken, contractDocumentGuids }
    )
  );
}
