import SwitcherDesktop from './SwitcherDesktop/SwitcherDesktop';
import SwitcherMobile from './SwitcherMobile/SwitcherMobile';
import SwitcherContext from './SwitcherContext';
import SwitcherProvider from './SwitcherProvider';
import isSwitcherOnRoute from './isSwitcherOnRoute';

const SwitcherConsumer = SwitcherContext.Consumer;

export {
  SwitcherProvider,
  SwitcherConsumer,
  SwitcherMobile,
  SwitcherDesktop,
  isSwitcherOnRoute
};
