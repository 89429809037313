import { init, setUserId } from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';
import { Identify, identify } from '@amplitude/analytics-browser';
import { IUserIdentity } from 'interfaces';

import ENV from 'env';

// Init with API Key
const ApiKey = ENV.AMPLITUDE_API_KEY;

if (ApiKey.length > 0) {
  init(ApiKey, undefined, {
    serverUrl: 'https://api.app.tax.com/analytics/2/httpapi',
    trackingOptions: {
      ipAddress: false
    }
  });
}

export async function logEvent(eventDetails: string, eventProperties?: any) {
  /*
  eventProperties.userProfile = mockedUser; //how to I get to the real user info
  eventProperties.userProfileObject = {
    userGuid: 'guid here'
  };
*/
  try {
    if (ApiKey.length > 0) {
      track(eventDetails, eventProperties || {});
    }
  } catch (error: any) {
    // NOTE: Quietly Fail
  }
}

export function setUserID(userID: string) {
  setUserId(userID);
}

export function updateUserProperties(user: IUserIdentity) {
  const identity = new Identify();

  const { profile } = user;

  const totalEngagements = user.accountTree.reduce(
    (acc, cum) => acc + cum.activeEngagementCount + cum.inactiveEngagementCount,
    0
  );
  const activeEngagements = user.accountTree.reduce(
    (acc, cur) => acc + cur.activeEngagementCount,
    0
  );

  identity.set('userType', profile.userType.toString());
  identity.set('userRole', profile.roleName);
  identity.set('accountAssociations', user.accountTree.length.toString());
  identity.set('totalEngagements', totalEngagements.toString());
  identity.set('activePublishedEngagements', activeEngagements.toString());
  profile.activateDate &&
    identity.set('activateDate', new Date(profile.activateDate).toISOString());
  profile.acceptedTermsOfUseDate &&
    identity.set(
      'userSince',
      new Date(profile.acceptedTermsOfUseDate).toISOString()
    );

  if (profile.loginDate) {
    const loginDate: Date = new Date(profile.loginDate);

    const diff = Math.abs(new Date().getTime() - loginDate.getTime());
    const dateRangeNumberOfDays = Math.ceil(diff / (1000 * 3600 * 24));
    identity.set('daysSinceLastLogin', dateRangeNumberOfDays.toString());
  }

  identify(identity);
}

const AmplitudeApiService = {
  logEvent,
  setUserID,
  updateUserProperties
};

export default AmplitudeApiService;
