import { IAddress } from 'interfaces';

export default function formatAddress(address: IAddress) {
  const {
    addressLine1: line1,
    addressLine2: line2,
    city,
    stateLabel: subdivision,
    postalCode
  } = address;

  if (line1 && city && postalCode) {
    if (line2 == null)
      return `${line1}  
      ${city},${subdivision ? ` ${subdivision}` : ''} ${postalCode}`;
    else
      return `${line1} 
      ${line2}  
      ${city},${subdivision ? ` ${subdivision}` : ''} ${postalCode}`;
  }
  return undefined;
}
