import { IComment, IUser } from 'interfaces';
import getAvatarUrl from 'utils/getAvatarUrl';

import classNames from 'classnames';
import { format, isSameSecond, parseISO } from 'date-fns';
import { TFunction } from 'i18next';
import React, { FunctionComponent, ReactNode, useRef } from 'react';

import {
  Avatar,
  Button,
  ButtonGroup,
  DropdownMenu,
  EButtonSizes,
  EButtonVariant,
  MentionsValue
} from '@ryan/components';

import CommentInput from '../CommentInput';
import CommentText from '../CommentText';

import './Comment.scss';

export interface ICommentProps extends React.HTMLAttributes<HTMLDivElement> {
  t: TFunction;
  engagementGuid: string;
  comment: IComment;
  canEdit: boolean;
  editText?: MentionsValue;
  editLoading: Promise<any> | null;
  onEdit: (comment: IComment) => void;
  onEditChange: (commentText: MentionsValue) => void;
  onEditSave: () => void;
  onEditCancel: () => void;
  onDelete: (comment: IComment) => void;
}

/**
 * Renders a single, editable comment.
 */
const Comment: FunctionComponent<ICommentProps> = props => {
  const {
    t,
    engagementGuid,
    className,
    comment,
    canEdit,
    editText,
    editLoading,
    onEdit,
    onEditChange,
    onEditSave,
    onEditCancel,
    onDelete,
    ...rest
  } = props;

  const commentContentRef = useRef<HTMLDivElement | null>(null);

  const [firstName, lastName] = comment.createdByName.split(' ');
  const user = {
    avatarUrl: comment.avatarUrl
  };

  // Per BED - there seems to be an issue of saving off by the millisecond.
  // So we are going to look to the second
  const edited = !isSameSecond(
    new Date(comment.createDate),
    new Date(comment.updateDate)
  );

  let commentContent: ReactNode;

  if (canEdit && editText !== undefined) {
    const { text } = editText.toJSON();
    const editWillDelete = text.length === 0;
    const isWhitespace = /^\s+$/g.test(text);

    commentContent = (
      <>
        <CommentInput
          boundingParentRef={commentContentRef}
          engagementGuid={engagementGuid}
          onChange={onEditChange}
          placement="top"
          value={editText}
        />
        <ButtonGroup>
          <Button
            disabled={isWhitespace}
            loading={editLoading}
            negative={editWillDelete}
            onClick={onEditSave}
            size={EButtonSizes.SMALL}
            text={t(editWillDelete ? 'Delete' : 'Save')}
            variant={EButtonVariant.PRIMARY}
          />
          <Button
            disabled={editLoading !== null}
            onClick={onEditCancel}
            size={EButtonSizes.SMALL}
            text={t('Cancel')}
            variant={EButtonVariant.SECONDARY}
          />
        </ButtonGroup>
      </>
    );
  } else {
    commentContent = (
      <CommentText
        taggedUsers={comment.commentTaggedUsers}
        text={comment.commentText}
      />
    );
  }

  return (
    <div className={classNames('comment', className)} {...rest}>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        profileImageSrc={getAvatarUrl(user as IUser)}
      />
      <div className="comment__meta">
        <b>{comment.createdByName}</b>{' '}
        <span className="comment__date">
          {format(parseISO(comment.createDate), t('format.time'))}
          {edited && comment.updateDate !== null && ` (${t('comment.edited')})`}
        </span>
      </div>
      <div className="comment__content" ref={commentContentRef}>
        {commentContent}
      </div>
      {canEdit && (
        <DropdownMenu
          options={[
            {
              label: t('Edit'),
              icon: 'pencil',
              onClick: () => onEdit(comment)
            },
            {
              label: t('Delete'),
              icon: 'trash',
              negative: true,
              onClick: () => onDelete(comment)
            }
          ]}
          placement="top-end"
          renderTarget={({ ref, open, onClick }) => (
            <Button
              aria-expanded={open}
              aria-haspopup="menu"
              aria-label={t(open ? 'comment.menuClose' : 'comment.menuOpen')}
              className="comment__menu"
              icon="more-outline"
              innerRef={ref}
              onClick={onClick}
              size={EButtonSizes.SMALL}
              variant={EButtonVariant.TEXT}
            />
          )}
        />
      )}
    </div>
  );
};

export default Comment;
