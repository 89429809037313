import { useContext } from 'react';

import { withContext } from '../../utils/withContext';
import { ITaskDrawerContext, TaskDrawerContext } from './TaskDrawerContext';

export const TaskDrawerConsumer = TaskDrawerContext.Consumer;

export type WithTaskDrawer = ITaskDrawerContext;
export const withTaskDrawer = withContext(TaskDrawerContext, 'withTaskDrawer');

export const useTaskDrawer = () => useContext(TaskDrawerContext);
