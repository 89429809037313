import * as yup from 'yup';

import { withFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  InfoWell,
  TextInput,
  Textarea
} from '@ryan/components';

import { withUser } from '../../../../../contexts/UserContext';
import { FolderSelection } from '../../../../../interfaces';
import * as FolderValidationUtils from '../../../../../utils/folderValidation/folderValidation.utils';
import { formikFieldProps } from '../../../../../utils/forms';
import SelectFolder from '../../../../SelectFolder/SelectFolder';
import {
  IDataRequestFormProps,
  IDataRequestFormValues,
  IDataRequestInnerFormProps
} from './utils';

const FirstStepFormOrgChart: React.FC<IDataRequestInnerFormProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);

  const {
    dataRequestTypeName,
    description,
    engagement,
    errors,
    folders,
    foldersFetching,
    handleSubmit,
    isSubmitting,
    onNextStepClick,
    setDescription,
    setFieldValue,
    setStatus,
    setTitle,
    setTouched,
    setTransferDestination,
    status,
    t: getTextToDisplay,
    title,
    transferDestination,
    values
  } = props;

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const transferDestinationError =
      transferDestination === null
        ? null
        : transferDestination?.folderName.trim().length === 0
        ? getTextToDisplay(
            'modal.dataRequestModal.fields.transferDestination.required'
          )
        : null;

    setStatus({
      ...status,
      transferDestinationError
    });

    setTouched({
      dataRequestTitle: true,
      description: true
    });
    handleSubmit();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.value;
    setFieldValue('dataRequestTitle', selected);
    setTitle(selected);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const selected = e.target.value;
    setFieldValue('description', selected);
    setDescription(selected);
  };

  useEffect(() => {
    if (title) {
      setFieldValue('dataRequestTitle', title);
    }
    if (description) {
      setFieldValue('description', description);
    }
    if (transferDestination) {
      setFieldValue('defaultFolder', transferDestination);
    }
  }, [engagement, description, setFieldValue, title, transferDestination]);

  const isValid =
    !isSubmitting &&
    !foldersFetching &&
    !Boolean(status.transferDestinationError);

  return (
    <>
      {status.error}
      <h2 className="step-title">
        {getTextToDisplay('modal.dataRequestModal.steps.step-2.title')}
      </h2>
      <InfoWell
        accountName={engagement?.accountName || ''}
        dataRequestType={dataRequestTypeName}
        projectName={engagement?.engagementDisplayNameLong || ''}
      />
      <form autoComplete="off" ref={formRef}>
        <TextInput
          {...formikFieldProps('dataRequestTitle', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.requestTitle.label'
          )}
          onChange={e => handleTitleChange(e)}
        />
        <Textarea
          {...formikFieldProps('description', props)}
          label={getTextToDisplay(
            'modal.dataRequestModal.fields.description.label'
          )}
          maxLength={600}
          onChange={e => handleDescriptionChange(e)}
          value={description}
        />
        <SelectFolder
          disabled={folders === null}
          feedback={status.transferDestinationError || errors.defaultFolder}
          folders={folders || []}
          invalid={!!status.transferDestinationError || !!errors.defaultFolder}
          isHideRyanInternal={true}
          label={getTextToDisplay(
            'dataRequest.modal.fields.defaultFolder.label'
          )}
          moveFileCount={2}
          onChange={(folder: FolderSelection) => {
            if (status.transferDestinationError) {
              setStatus({
                ...status,
                transferDestinationError: null
              });
            }
            if (folder?.folderGuid) {
              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            } else {
              const transferDestinationError =
                FolderValidationUtils.validateFolderInput(
                  folder,
                  folders
                ) as string;

              if (Boolean(transferDestinationError)) {
                setStatus({
                  ...status,
                  transferDestinationError: getTextToDisplay(
                    transferDestinationError
                  )
                });
              }

              setFieldValue('defaultFolder', folder);
              setTransferDestination(folder);
            }
          }}
          rootName={engagement ? engagement.engagementDisplayNameShort : ''}
          value={values.defaultFolder}
        />
        <ButtonGroup>
          <Button
            loading={status.loading}
            onClick={() => onNextStepClick(1)}
            text={getTextToDisplay('modal.dataRequestModal.back')}
            type="submit"
            variant={EButtonVariant.SECONDARY}
          />
          <Button
            disabled={!isValid}
            loading={status.loading}
            onClick={e => handleFormSubmit(e)}
            text={getTextToDisplay('modal.dataRequestModal.next')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
        </ButtonGroup>
      </form>
    </>
  );
};

const defaultValues: IDataRequestFormValues = {
  dataRequestTitle: '',
  defaultFolder: null,
  description: '',
  engagement: null
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: () => defaultValues,

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: ({
      folders,
      t: getTextToDisplay
    }: IDataRequestFormProps) =>
      yup.object({
        dataRequestTitle: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.requestTitle.required'
            )
          )
          .max(
            50,
            getTextToDisplay('modal.dataRequestModal.fields.requestTitle.max')
          ),
        description: yup
          .string()
          .required(
            getTextToDisplay(
              'modal.dataRequestModal.fields.description.required'
            )
          )
          .max(
            600,
            getTextToDisplay('modal.dataRequestModal.fields.description.max')
          )
      }),

    handleSubmit: async (values, formik) => {
      formik.props.onNextStepClick(3);
    }
  })(withUser(FirstStepFormOrgChart))
);
