import { TItemType, TReleaseNoteFilters, TReleaseNotesData } from '../../types';
import ApiBase from './ApiBase';

export async function getReleaseNotes(
  this: ApiBase,
  params: TReleaseNoteFilters
) {
  return this.server.get<TReleaseNotesData[]>(
    `/api/releasenote/searchreleasenotes`,
    { params }
  );
}

export async function getItemTypes(this: ApiBase) {
  return this.server.get<TItemType[]>(`/api/releasenote/releaseitemtypes`);
}

export async function getShouldOpenNewModal(this: ApiBase) {
  return this.server.get<boolean>(`/api/releasenote/needtoforceopennewsmodal`);
}
