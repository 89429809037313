export enum WorkspaceStatus {
  Pending,
  InProgress,
  Complete,
  ErrorCreate,
  ErrorClone,
  ErrorUpdate,
  ErrorDelete,
  ErrorRestore
}

export enum WorkspaceGroup {
  Outstanding,
  Complete
}

export enum WorkspaceSource {
  Blank = 'blank',
  Template = 'template'
}

export interface IWorkspace {
  engagementGuid: string;
  engagementId: string;

  workspaceGuid: string;
  workspaceId: string;
  title: string;
  description: string;

  server: string;
  database: string;
  name: string;
  connectionGuid: string;
  id: string;

  status: WorkspaceStatus;

  createDate: Date;
  createdBy: string; // userGuid
  createdByName: string;
  // createdByEmail: string;
  // createdImpersonatingByName: string;
  // createdImpersonatingByUserGuid: string;
  // createdImpersonatingByEmail: string;

  updateDate: Date;
  updatedBy: string; // userGuid
  updatedByName: string;
  // updatedByEmail?: string;
  // updatedImpersonatingByName?: string;
  // updatedImpersonatingByUserGuid?: string;
  // updatedImpersonatingByEmail?: string;

  // Mapped in ApiWorkspaces
  group: WorkspaceGroup;
}

export interface IWorkspaceConnection {
  connectionGuid: string;
  database: string | null;
  datasourceType: number;
  id: string;
  name: string;
  server: string;
}

export interface IWorkspaceRequest {
  name: string;
  description?: string;
  engagementGuid: string;
  id: string;
  templateGuid?: string;
}
