import Checklocks from 'components/Checklocks';
import { IRolePermission, Permission } from 'interfaces';
import { numberOf } from 'utils/numberOf';

import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Checkbox, Icon } from '@ryan/components';

import './RolePermissions.scss';

/**
 * Group Helper
 */

interface IGroupedRolePermissions {
  [key: number]: IRolePermission[];
}

function groupPermissions(permissions: IRolePermission[]) {
  const grouped = permissions.reduce((grouped, permission) => {
    const key = permission.permissionGroupId;
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(permission);
    return grouped;
  }, {} as any as IGroupedRolePermissions);

  return Object.keys(grouped)
    .sort()
    .map(key => grouped[parseFloat(key)]);
}

/**
 * Component
 */

interface IRolePermissionsProps {
  loading: boolean;
  permissions: IRolePermission[];
  onChange: (permissions: IRolePermission[]) => void;
}

interface IRolePermissionsState {
  closedGroups: { [key: string]: boolean };
}

export class RolePermissions extends Component<
  IRolePermissionsProps & WithTranslation,
  IRolePermissionsState
> {
  readonly state: IRolePermissionsState = {
    closedGroups: {}
  };

  handleSectionToggle = (permissionGroup: number) => {
    this.setState(({ closedGroups }) => ({
      closedGroups: {
        ...closedGroups,
        [permissionGroup]: !closedGroups[permissionGroup]
      }
    }));
  };

  handlePermissionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { permissions, onChange } = this.props;
    const [permissionGuid, key] = e.target.value.split('::');
    onChange(
      permissions.map(p => {
        if (p.permissionGuid === permissionGuid) {
          return { ...p, [key]: e.target.checked };
        }
        return p;
      })
    );
  };

  render() {
    const { i18n, loading, permissions: permissionsProp, t } = this.props;
    const { closedGroups } = this.state;

    // Executive Access is given per user by SuperAdmins.
    // Remove it from list.
    const permissions = permissionsProp.filter(
      p => p.permissionGuid !== Permission.ExecutiveAccess
    );

    return (
      <div className="role-permissions">
        <table className="rp-table">
          <thead>
            <tr>
              <th>{t('Permissions')}</th>
              <th>{t('Granted')}</th>
            </tr>
          </thead>
          {loading ? (
            <tbody>
              {numberOf(5, i => (
                <tr key={i}>
                  <td className="rp-table__permission-name">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </td>
                  <td className="rp-table__td-checkboxes">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            groupPermissions(permissions).map(permissions => {
              const key = permissions[0].permissionGroupId;
              const open = !closedGroups[key];
              return (
                <tbody key={key}>
                  <tr className="rp-table__group-header">
                    <td colSpan={2}>
                      <button
                        onClick={() => this.handleSectionToggle(key)}
                        type="button"
                      >
                        <Icon name={open ? 'chevron-up' : 'chevron-down'} />
                        {t(`manageRoles.permissionGroup.${key}`)}
                      </button>
                    </td>
                  </tr>
                  {open &&
                    permissions.map(
                      ({
                        isGranted,
                        isLocked,
                        permissionDescription,
                        permissionGuid,
                        permissionName
                      }) => (
                        <tr key={permissionGuid}>
                          <td className="rp-table__permission-name">
                            <b>
                              {i18n.exists(
                                `manageRoles.permission.${permissionGuid}.name`
                              )
                                ? t(
                                    `manageRoles.permission.${permissionGuid}.name`
                                  )
                                : permissionName}
                            </b>
                            <p>
                              {i18n.exists(
                                `manageRoles.permission.${permissionGuid}.description`
                              )
                                ? t(
                                    `manageRoles.permission.${permissionGuid}.description`
                                  )
                                : permissionDescription}
                            </p>
                          </td>
                          <td className="rp-table__checkboxes">
                            <Checkbox
                              checked={isGranted}
                              onChange={this.handlePermissionChange}
                              value={`${permissionGuid}::isGranted`}
                            />
                            <Checklocks
                              checked={isLocked}
                              onChange={this.handlePermissionChange}
                              value={`${permissionGuid}::isLocked`}
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              );
            })
          )}
        </table>
      </div>
    );
  }
}

export default withTranslation()(RolePermissions);
