import { CancelToken } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoWell } from '@ryan/components';

import { useEngagement } from '../../../../contexts/EngagementContext';
import { IDataRequestV2, IFolderTree } from '../../../../interfaces';
import ApiService from '../../../../services/ApiService';
import { useDSSManager } from '../../../../utils/DSS';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';
import Modal from '../../../Modal';
import UploadModalWarning from '../../../UploadModalWarning/UploadModalWarning';
import EditForm from './EditForm/EditForm';
import {
  IHandleClose,
  TUpdateDataRequestModalProps
} from './UpdateDataRequestModal.interfaces';

import './UpdateDataRequestModal.scss';

export const UpdateDataRequestModal = ({
  dataRequest,
  isFetchCompleteDataRequest = false,
  onClose
}: TUpdateDataRequestModalProps) => {
  const dss = useDSSManager();
  const { refreshUpdateDate } = useEngagement();
  const { t: getTextToDisplay } = useTranslation();

  const [delayTimer, setDelayTimer] = useState<NodeJS.Timer | null>(null);
  const [hasDelayExpired, setHasDelayExpired] = useState(false);
  const [folders, setFolders] = useState<IFolderTree[] | null>(null);
  const [fetchedDataRequest, setFetchedDataRequest] =
    useState<IDataRequestV2 | null>(null);

  const isShowSkeleton =
    isFetchCompleteDataRequest &&
    (!hasDelayExpired || fetchedDataRequest === null);

  const fetchDataRequest = useCallback(
    async (cancelToken: CancelToken) => {
      const minimumDelayTimer = setTimeout(() => {
        setHasDelayExpired(true);
      }, 1000);

      setDelayTimer(minimumDelayTimer);

      const { data } = await ApiService.getDataRequestV2(
        dataRequest.queueItemGuid,
        cancelToken
      );

      setFetchedDataRequest(data as IDataRequestV2);
    },
    [dataRequest, setDelayTimer, setFetchedDataRequest, setHasDelayExpired]
  );

  const fetchFolders = useCallback(
    async (cancelToken: CancelToken) => {
      const { data: engagementFolders } = await ApiService.getEngagementFolders(
        dataRequest.engagementGuid,
        cancelToken
      );

      setFolders(engagementFolders);
    },
    [dataRequest]
  );

  useEffect(() => {
    const fetchDataRequestCancelTokenSource = ApiService.CancelToken.source();
    const fetchFoldersCancelTokenSource = ApiService.CancelToken.source();

    try {
      if (isFetchCompleteDataRequest) {
        fetchDataRequest(fetchDataRequestCancelTokenSource.token);
      }

      fetchFolders(fetchFoldersCancelTokenSource.token);
    } catch (error) {
      pushServerErrorToast();
      onClose();
    }

    return () => {
      fetchDataRequestCancelTokenSource.cancel();
      fetchFoldersCancelTokenSource.cancel();
    };
  }, [fetchDataRequest, fetchFolders, isFetchCompleteDataRequest, onClose]);

  useEffect(() => {
    return () => {
      if (delayTimer) {
        clearTimeout(delayTimer);
      }
    };
  }, [delayTimer]);

  const handleClose: IHandleClose = dataRequest => {
    dss.clearUploads();
    onClose(dataRequest);
  };

  const renderSkeleton = () => (
    <div className="update-data-request-modal__skeleton">
      <div className="ry-skeleton" />
      <div className="ry-skeleton" />
      <div className="ry-skeleton" />
      <div className="ry-skeleton" />
      <div className="ry-skeleton" />
    </div>
  );

  return (
    <UploadModalWarning dss={dss} onClose={handleClose}>
      {({ warning, onEscape }) => (
        <Modal
          className="update-data-request-modal"
          onClose={onEscape}
          open
          title={getTextToDisplay('dataRequest.modal.edit.title')}
        >
          {warning}
          <div className="update-data-request-modal__info-well">
            <InfoWell
              accountName={dataRequest.accountName}
              dataRequestTitle={dataRequest.title}
              dataRequestType={dataRequest.dataRequestTypeName as string}
              projectName={dataRequest.engagementDisplayNameLong}
            />
          </div>

          {isShowSkeleton && renderSkeleton()}

          {!isShowSkeleton && (
            <EditForm
              dataRequest={fetchedDataRequest || dataRequest}
              dss={dss}
              engagementGuid={dataRequest.engagementGuid}
              folders={folders}
              isFetchCompleteDataRequest={isFetchCompleteDataRequest}
              onCancel={handleClose}
              onSubmitted={(dataRequest?: IDataRequestV2) => {
                refreshUpdateDate?.(dataRequest?.engagementGuid);
                handleClose(dataRequest);
              }}
            />
          )}
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default UpdateDataRequestModal;
