import { CancelToken } from 'axios';
import {
  IAccount,
  ICustomView,
  ICustomViewCreate,
  ICustomViewCreateType,
  ICustomViewFilterOperator,
  ICustomViewFilterType,
  ICustomViewType,
  IEngagement,
  IPagedDataParams,
  IPagedDataResponse
} from 'interfaces';
import { transformResponse } from 'utils/transformResponse';
import ApiBase from './ApiBase';

export async function createView(this: ApiBase, view: ICustomViewCreate) {
  return this.server.post<ICustomView>('api/customviews', view);
}

export async function createAccountView(
  this: ApiBase,
  account: IAccount,
  includingSubsidiaries: boolean,
  isExecutiveView = false
) {
  return createView.call(this, {
    name: account.name,
    type: ICustomViewType.Dynamic,
    createType: ICustomViewCreateType.AccountSingle,
    isExecutiveView,
    engagements: null,
    filters: [
      {
        type: ICustomViewFilterType.Account,
        operator: includingSubsidiaries
          ? ICustomViewFilterOperator.EqualsIncludingSubsidiaries
          : ICustomViewFilterOperator.Equals,
        value: account.accountGuid
      }
    ]
  });
}

export async function deleteView(this: ApiBase, customViewGuid: string) {
  return this.server.delete(`/api/customviews/${customViewGuid}`);
}

export async function editView(
  this: ApiBase,
  customViewGuid: string,
  view: ICustomViewCreate
) {
  return this.server.put<ICustomView>(
    `/api/customviews/${customViewGuid}`,
    view
  );
}

/**
 * Returns an existing Custom View by guid.
 * NOTE: The `value` prop of filters attached to views will only contain the
 * minimum information required to render the option for the value types'
 * corresponding autosuggest fields ie. Jurisdiction type values will only
 * contain `jurisdictionGuid` and `name`. Updates may be needed if props
 * required to render the options are updated.
 */
export async function getView(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<ICustomView>(`/api/customviews/${customViewGuid}`, {
    cancelToken
  });
}

export async function getEngagementPreview(
  this: ApiBase,
  request: IPagedDataParams &
    Pick<ICustomViewCreate, 'filters' | 'isExecutiveView'> & {
      searchTerm?: string;
    },
  cancelToken?: CancelToken
) {
  return this.server.post<IPagedDataResponse<IEngagement>>(
    'api/customviews/preview',
    request,
    { cancelToken, transformResponse }
  );
}

const IS_ENGAGEMENT_IN_VIEW: Record<string, Promise<boolean>> = {};

export async function isEngagementInView(
  this: ApiBase,
  engagementGuid: string,
  customViewGuid: string,
  cancelToken?: CancelToken
): Promise<boolean> {
  const key = `${customViewGuid}_${engagementGuid}`;

  if (typeof IS_ENGAGEMENT_IN_VIEW[key] === 'undefined') {
    const promise = this.server
      .get<boolean>(
        `/api/customViews/${customViewGuid}/engagements/${engagementGuid}/isinview`,
        { cancelToken }
      )
      .then(response => response.data)
      .catch(error => {
        // on cancel or request error, reset map value so we can attempt this
        // call again later and continue throw to next catch block
        delete IS_ENGAGEMENT_IN_VIEW[key];
        throw error;
      });

    IS_ENGAGEMENT_IN_VIEW[key] = promise;
  }

  return IS_ENGAGEMENT_IN_VIEW[key];
}

/**
 * Updates the "last viewed" time of the custom view. Assists in fetching the
 * list of the user's most recent views.
 *
 * @returns The GUID of the updated view.
 */
export async function updateViewLastViewed(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.put<string>(
    `/api/customviews/lastViewed/${customViewGuid}`,
    {},
    { cancelToken }
  );
}
