export enum SavingsCategoryDetailEnums {
  ADDED = 'added',
  PASSED = 'passed',
  TO_APPROVED = 'toApproved',
  TO_RECEIVED = 'toReceived',
  TO_SUBMITTED = 'toSubmitted'
}

export enum UpdateSavingsBucketModalEnums {
  ROOT_TO_TEXT = 'modal.updateSavingsBucketModal'
}
