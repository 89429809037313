import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  EButtonVariant,
  EMessageTypes,
  Message,
  pushToast
} from '@ryan/components';

import { TaskModalInnerWrapped } from '../../../components/TaskActions/TaskModal/TaskModal';
import { IAccount, IEngagementSummary, ITask } from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import { useDSSManager } from '../../../utils/DSS';
import * as FormikUtils from '../../../utils/forms';
import {
  AccountAutocomplete,
  EngagementAutocomplete
} from '../../AutocompleteAjax';
import UploadModalWarning from '../../UploadModalWarning/UploadModalWarning';
import Modal from '../Modal';
import * as TaskModalInterfaces from './TaskModal.interfaces';
import * as TaskModalUtils from './TaskModal.utils';

import './TaskModal.scss';

const TaskModal: React.FC<TaskModalInterfaces.TComponentProps> = props => {
  const ROOT_TO_TEXT = 'modal.taskModal';

  const [isSelectAccount, setIsSelectAccount] = useState(true);
  const [isSelectAccountError, setIsSelectAccountError] = useState(false);
  const [isSelectEngagementError, setIsSelectEngagementError] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [selectedEngagement, setSelectedEngagement] =
    useState<IEngagementSummary | null>(null);
  const [submitError, setSubmitError] = useState<React.ReactNode>(null);
  const { t: getTextToDisplay } = useTranslation();

  const dss = useDSSManager();

  const handleSubmit: TaskModalInterfaces.IHandleSubmit = async (
    task,
    handleSubmitSuccess,
    handleSubmitError,
    setFormikStatus
  ) => {
    try {
      const apiServiceCreateTask = ApiService.createTask(task);
      setFormikStatus({ loading: apiServiceCreateTask });

      const {
        data: { title }
      } = await apiServiceCreateTask;

      handleSubmitSuccess(title);
    } catch (error) {
      handleSubmitError();
    }
  };

  const renderAccountSelect: TaskModalInterfaces.IRenderAccountSelect = ({
    accountAutoCompleteProps,
    accountSelectTitle,
    engagementAutoCompleteProps,
    onSubmit
  }) => (
    <div className="task-modal__account-select">
      <p className="ry-h2">{accountSelectTitle}</p>

      <AccountAutocomplete
        {...accountAutoCompleteProps}
        className="task-modal__account-select__account"
        isOnlyActiveEngagements
      />

      <EngagementAutocomplete
        {...engagementAutoCompleteProps}
        className="task-modal__account-select__project"
        isShowAccountName
      />

      <div className="task-modal__account-select__action">
        <Button
          onClick={onSubmit}
          text="Next"
          variant={EButtonVariant.PRIMARY}
        />
      </div>
    </div>
  );

  const renderCreateTaskHeader: TaskModalInterfaces.IRenderInfoWell = (
    accountName,
    engagementName,
    completeTaskTitle
  ) => (
    <div className="task-modal__create-task-header">
      <p className="ry-h2">{completeTaskTitle}</p>

      <div className="task-modal__create-task-header__info-well">
        <p className="ry-h4">{accountName}</p>
        <p className="ry-h4 task-modal__info-well--project">{engagementName}</p>
      </div>
    </div>
  );

  return (
    <UploadModalWarning dss={dss} onClose={props.onClose}>
      {({ onEscape, warning }) => (
        <Modal
          className="task-modal"
          onClose={onEscape}
          open
          title={getTextToDisplay(`${ROOT_TO_TEXT}.title`)}
        >
          {isSelectAccount ? (
            renderAccountSelect(
              TaskModalUtils.getRenderAccountSelectProps({
                getTextToDisplay,
                isSelectAccountError,
                isSelectEngagementError,
                rootToText: ROOT_TO_TEXT,
                selectedAccount,
                selectedEngagement,
                setSelectedAccount,
                setSelectedEngagement,
                setIsSelectAccount,
                setIsSelectAccountError,
                setIsSelectEngagementError
              })
            )
          ) : (
            <>
              {warning}
              {submitError}
              <FormikUtils.Formik
                initialStatus={{ loading: null }}
                initialValues={TaskModalUtils.getInitialFormikValues()}
                onSubmit={(formValues, formik) => {
                  setSubmitError(null);
                  handleSubmit(
                    TaskModalUtils.mapFormValuesToCreateTask(formValues),
                    title => {
                      pushToast({
                        content: (
                          <Trans
                            i18nKey={`${ROOT_TO_TEXT}.successToast.content`}
                          >
                            <b />
                            {{ title }}
                          </Trans>
                        ),
                        title: getTextToDisplay(
                          `${ROOT_TO_TEXT}.successToast.title`
                        ),
                        type: EMessageTypes.SUCCESS
                      });
                      props.onClose({} as ITask);
                    },
                    () => {
                      setSubmitError(
                        <Message
                          title={getTextToDisplay('serverError.title')}
                          type={EMessageTypes.ERROR}
                        >
                          {getTextToDisplay('serverError.content')}
                        </Message>
                      );
                      formik.setStatus({ loading: null });
                    },
                    formik.setStatus
                  );
                }}
                validationSchema={TaskModalUtils.getValidationSchema(
                  getTextToDisplay
                )}
              >
                {formik => (
                  <TaskModalInnerWrapped
                    dss={dss}
                    engagementGuid={selectedEngagement?.engagementGuid}
                    formik={formik}
                    isTwoStepCreateTask
                    onClose={() => {
                      setIsSelectAccount(true);
                    }}
                    renderCreateTaskHeader={() =>
                      renderCreateTaskHeader(
                        (selectedAccount as IAccount).name,
                        (selectedEngagement as IEngagementSummary)
                          .engagementDisplayNameLong,
                        getTextToDisplay(`${ROOT_TO_TEXT}.completeTaskDetails`)
                      )
                    }
                    updateInitialValues={() => {}}
                  />
                )}
              </FormikUtils.Formik>
            </>
          )}
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default TaskModal;
