import Table from 'components/Table';
import {
  INewUserRequest,
  INewUserRequestSearchParams,
  ITableState,
  IUser,
  NewUserRequestStatus
} from 'interfaces';
import ApiService from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import { formatDate } from 'utils/formatDate';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, EButtonSizes, EButtonVariant, Icon } from '@ryan/components';

import UserNameAndProfile from '../../components/Table/UserNameAndProfile/UserNameAndProfile';
import { formatToUserNameAndProfile } from '../../components/Table/UserNameAndProfile/utils';
import { NewUserContext } from './NewUserContext';

import './ManageTeamPendingNewUserTable.scss';

interface IManageTeamPendingNewUserTableProps
  extends WithTranslation,
    RouteComponentProps {
  isUpdateRequired: boolean | null;
}

interface IManageTeamPendingNewUserTableState extends ITableState {
  users: INewUserRequest[];
}

class ManageTeamPendingNewUserTable extends Component<
  IManageTeamPendingNewUserTableProps,
  IManageTeamPendingNewUserTableState
> {
  readonly state = {
    loading: true,
    page: 1,
    pageSize: 10,
    searchQuery: '',
    users: [],
    totalCount: 0,
    sorted: {
      id: 'FirstName',
      desc: false
    },
    filtered: {
      status: ''
    }
  };

  private columns: any[];

  private _isMounted = false;

  constructor(props: IManageTeamPendingNewUserTableProps) {
    super(props);

    const { t } = props;
    this.columns = [
      {
        id: 'firstName',
        label: t('manageTeam.newUser.columns.name'),
        render: (row: INewUserRequest) => (
          <UserNameAndProfile
            user={formatToUserNameAndProfile({ user: row as unknown as IUser })}
          />
        )
      },
      {
        id: 'email',
        label: t('manageTeam.newUser.columns.email'),
        render: 'email'
      },
      {
        id: 'status',
        label: t('manageTeam.newUser.columns.status'),
        sortable: true,
        render: (row: INewUserRequest) => {
          if (row.statusId === NewUserRequestStatus.Complete) {
            return t('manageTeam.newUser.status.completedOn', {
              date: formatDate(row.activationDate || row.requestedDate)
            });
          } else return t('manageTeam.newUser.status.pending');
        }
      },
      {
        id: 'requestedBy',
        label: t('manageTeam.newUser.columns.requestedBy'),
        render: () => {
          return t('You');
        }
      },
      {
        id: 'requestedDate',
        label: t('manageTeam.newUser.columns.requestedDate'),
        render: (row: INewUserRequest) => formatDate(row.requestedDate),
        sortable: true
      }
    ];
  }

  fetchData(updates?: Partial<IManageTeamPendingNewUserTableState>) {
    this.setState(
      { ...(updates as IManageTeamPendingNewUserTableState), loading: true },
      async () => {
        const { page, searchQuery, sorted, pageSize, filtered } = this.state;

        const params: INewUserRequestSearchParams = {
          pageNumber: page,
          searchTerm: searchQuery,
          sort: getSortParam(sorted),
          itemsPerPage: pageSize,
          status: filtered ? filtered.status : undefined
        };

        try {
          const {
            data: { results, totalResults }
          } = await ApiService.getPendingUserRequests(params);

          this.setState({
            users: results,
            totalCount: totalResults
          });
        } catch {
          pushServerErrorToast();
        } finally {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
  }

  componentDidUpdate(prevProps: IManageTeamPendingNewUserTableProps) {
    if (prevProps.isUpdateRequired === this.props.isUpdateRequired) {
      return;
    }

    if (this.props.isUpdateRequired) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchData({ page: 1, searchQuery });
    }
  );

  onPage = (page: number, pageSize: number) => {
    this.fetchData({ page, pageSize });
  };

  onFilter = (filtered: Record<string, unknown>) => {
    this.fetchData({ filtered, page: 1 });
  };

  onSort = (sorted: any) => {
    this.fetchData({ sorted });
  };

  renderActions = () => {
    const { t } = this.props;
    return (
      <div>
        <NewUserContext.Consumer>
          {onNewUser => (
            <Button
              icon="plus"
              onClick={onNewUser}
              size={EButtonSizes.SMALL}
              text={t('manageTeam.newUser.request')}
              variant={EButtonVariant.TEXT}
            />
          )}
        </NewUserContext.Consumer>
      </div>
    );
  };

  renderEmpty = () => {
    const { t } = this.props;
    const { searchQuery } = this.state;

    return (
      <>
        <div className="table-empty">
          <Icon className="table-empty__icon" name="users" />
          {searchQuery ? (
            <div className="table-empty__content">
              <Trans i18nKey="Search Not Found">
                <b>{{ searchQuery }}</b> could not be found.
              </Trans>
              <br />
              {t('manageTeam.adjustSearch')}
            </div>
          ) : (
            <div className="table-empty__content">
              {t('manageTeam.newUser.nonePending')}
              <br />
              {t('manageTeam.newUser.question')}
            </div>
          )}
          <NewUserContext.Consumer>
            {onNewUser => (
              <Button
                icon="plus"
                onClick={onNewUser}
                text={t('manageTeam.newUser.request')}
                variant={EButtonVariant.PRIMARY}
              />
            )}
          </NewUserContext.Consumer>
        </div>
      </>
    );
  };

  render() {
    const {
      searchQuery,
      page,
      pageSize,
      totalCount,
      users,
      loading,
      sorted,
      filtered
    } = this.state;

    if (
      !loading &&
      totalCount === 0 &&
      searchQuery === '' &&
      filtered.status === ''
    ) {
      this.renderEmpty();
    }

    return (
      <Table<INewUserRequest>
        className="manage-pending-new-user-table"
        columns={this.columns}
        data={users}
        filtered={filtered}
        loading={loading}
        onFilterChange={this.onFilter}
        onPageChange={this.onPage}
        onSearchChange={this.onSearch}
        onSortChange={this.onSort}
        page={page}
        pageSize={pageSize}
        renderActionPlacement={1}
        renderActions={this.renderActions}
        renderEmpty={this.renderEmpty}
        rowId="queueItemGuid"
        searchQuery={searchQuery}
        sorted={sorted}
        title={`${this.props.t('manageTeam.pendingUserTab')} (${totalCount})`}
        totalCount={totalCount}
      />
    );
  }
}

export default withTranslation()(withRouter(ManageTeamPendingNewUserTable));
