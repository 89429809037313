import { WithUser, withUser } from 'contexts/UserContext';
import { ITask, Permission, Status, UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, SplitButton, pushToast } from '@ryan/components';

import EngagementContext from '../../contexts/EngagementContext';
import CompleteTaskModal from './CompleteTaskModal/CompleteTaskModal';
import DeleteTaskModal from './DeleteTaskModal/DeleteTaskModal';
import ReassignTaskModal from './ReassignTaskModal/ReassignTaskModal';
import ReopenTaskModal from './ReopenTaskModal/ReopenTaskModal';
import TaskModal from './TaskModal/TaskModal';

interface ITaskActionsProps extends WithTranslation, WithUser {
  task: ITask;
  size: 'sm' | 'md' | 'lg';
  onUpdate: () => void;
}

interface ITaskActionsState {
  isEditModalOpen: boolean;
  isCompleteModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isReopenModalOpen: boolean;
  isReassignModalOpen: boolean;
}

export class TaskActions extends PureComponent<
  ITaskActionsProps,
  ITaskActionsState
> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  readonly state = {
    isEditModalOpen: false,
    isCompleteModalOpen: false,
    isDeleteModalOpen: false,
    isReopenModalOpen: false,
    isReassignModalOpen: false
  };

  /**
   * Advance the Task's status
   */
  handleAdvanceStatus = () => {
    switch (this.props.task.statusId) {
      case Status.Todo:
        this.handleMarkInProgress();
        break;
      case Status.InProgress:
        this.handleCompleteModal();
        break;
      case Status.Complete:
        break;
    }
  };

  /**
   * Mark the Task as In Progress
   */
  handleMarkInProgress = async () => {
    const { t, task, onUpdate } = this.props;
    try {
      const response = await ApiService.updateTask(task, {
        statusId: Status.InProgress
      });
      const { title } = response.data;
      pushToast({
        type: 'success',
        title: t('taskActions.inProgress.title'),
        content: t('taskActions.inProgress.content', { title })
      });
      onUpdate();
      this.handleRefreshUpdate();
    } catch {
      pushServerErrorToast();
    }
  };

  /**
   * Mark the Task as Complete
   */
  handleCompleteModal = () => {
    this.setState({ isCompleteModalOpen: true });
  };

  handleCompleteModalClose = (updatedTask?: ITask) => {
    const { onUpdate } = this.props;
    if (updatedTask) {
      onUpdate();
      this.handleRefreshUpdate();
    }
    this.setState({ isCompleteModalOpen: false });
  };

  /**
   * Repoen the Task
   */
  handleReopenModal = () => {
    this.setState({ isReopenModalOpen: true });
  };

  handleReopenModalClose = (updatedTask?: ITask) => {
    const { onUpdate } = this.props;
    if (updatedTask) {
      onUpdate();
      this.handleRefreshUpdate();
    }
    this.setState({ isReopenModalOpen: false });
  };

  /**
   * Delete the Task
   */
  handleDeleteModal = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  handleDeleteModalClose = (deleted: boolean) => {
    const { onUpdate } = this.props;

    if (deleted) {
      onUpdate();
    }
    this.setState({ isDeleteModalOpen: false });
  };

  /**
   * Edit the Task
   */
  handleEditModal = () => {
    this.setState({ isEditModalOpen: true });
  };

  handleEditModalClose = (editedTask?: ITask) => {
    const { onUpdate } = this.props;
    if (editedTask) {
      onUpdate();
    }
    this.setState({ isEditModalOpen: false });
  };

  /**
   * Reassign the Task
   */
  handleReassignModal = () => {
    this.setState({ isReassignModalOpen: true });
  };

  handleReassignModalClose = (editedTask?: ITask) => {
    const { onUpdate } = this.props;

    if (editedTask) {
      onUpdate();
      this.handleRefreshUpdate();
    }
    this.setState({ isReassignModalOpen: false });
  };

  handleRefreshUpdate = () => {
    this.context.refreshUpdateDate?.(this.props.task.engagementGuid);
  };

  /**
   * Render the thing.
   */

  render() {
    const { t, isAppReadOnly, task, size, permissionService: ps } = this.props;
    const {
      isCompleteModalOpen,
      isDeleteModalOpen,
      isEditModalOpen,
      isReopenModalOpen,
      isReassignModalOpen
    } = this.state;

    const disabled = task.isEngagementReadOnly || isAppReadOnly;
    const hasEditPermission = ps.hasPermission(Permission.TasksEdit);
    const isCreatedByClient = task.createdByUserType === UserType.Client;
    const isAssignedToClient = task.userType === UserType.Client;

    // Editing a task's definition:
    //   title, description, attachments, due date, milestone, reopen
    const canEditDefintion =
      hasEditPermission &&
      (ps.isRyan() || (ps.isClient() && isCreatedByClient));

    // Editing a task's status:
    //   status, assigned to, reopen
    const canEditStatus =
      hasEditPermission &&
      (ps.isRyan() || (ps.isClient() && isAssignedToClient));

    // If the user does not have permission to edit tasks,
    // or if the task is not owned by them, then we show
    // a disabled button with the current status.
    if (!(canEditDefintion || canEditStatus)) {
      return (
        <Button
          block
          disabled
          size={size}
          text={t(`status.${task.statusId}`)} // always! no onClick!
        />
      );
    }

    // The user can edit the task, so determine which options are available.
    const options = [];
    const isComplete = task.statusId === Status.Complete;

    // Edit
    // Available when task is not yet marked "Complete"
    if ((canEditDefintion || canEditStatus) && !isComplete) {
      options.push({
        label: t('Edit'),
        icon: 'pencil',
        disabled,
        onClick: this.handleEditModal
      });
    }

    // Mark in progress
    // Available when task is marked “Todo”
    if (canEditStatus && task.statusId === Status.Todo) {
      options.push({
        label: t('Mark in Progress'),
        icon: 'status-in-progress',
        disabled,
        onClick: this.handleMarkInProgress
      });
    }

    // Mark Complete
    // Available when task is not yet marked "Complete"
    if (canEditStatus && !isComplete) {
      options.push({
        label: t('Complete'),
        icon: 'approve',
        disabled,
        onClick: this.handleCompleteModal
      });
    }

    // Reopen
    // Available when the task is marked "Complete".
    if ((canEditDefintion || canEditStatus) && isComplete) {
      options.push({
        label: t('Reopen'),
        icon: 'file-refresh',
        disabled,
        onClick: this.handleReopenModal
      });
    }

    // Reassign
    // Available when task is not yet marked "Complete"
    if (canEditStatus && !isComplete) {
      options.push({
        label: t('Reassign'),
        icon: 'user-reassign',
        disabled,
        onClick: this.handleReassignModal
      });
    }

    // Delete
    // Only available for Ryan employees, not clients.
    if (canEditDefintion) {
      options.push({
        label: t('Delete'),
        icon: 'trash',
        negative: true,
        disabled,
        onClick: this.handleDeleteModal
      });
    }

    return (
      <>
        <SplitButton
          block
          disabled={disabled || isComplete || !canEditStatus}
          onClick={this.handleAdvanceStatus}
          options={options}
          size={size}
          text={
            {
              [Status.Todo]: canEditStatus ? t('Mark in Progress') : t('To Do'),
              [Status.InProgress]: canEditStatus
                ? t('Complete')
                : t('In Progress'),
              [Status.Complete]: t('Completed')
            }[task.statusId]
          }
        />
        <TaskModal
          onClose={this.handleEditModalClose}
          open={isEditModalOpen}
          task={task}
        />
        <ReassignTaskModal
          onClose={this.handleReassignModalClose}
          open={isReassignModalOpen}
          task={task}
        />
        <CompleteTaskModal
          onClose={this.handleCompleteModalClose}
          open={isCompleteModalOpen}
          task={task}
        />
        <ReopenTaskModal
          onClose={this.handleReopenModalClose}
          open={isReopenModalOpen}
          task={task}
        />
        <DeleteTaskModal
          onClose={this.handleDeleteModalClose}
          open={isDeleteModalOpen}
          task={task}
        />
      </>
    );
  }
}

export default withTranslation()(withUser(TaskActions));
