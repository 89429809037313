import classNames from 'classnames';
import React from 'react';
import './RadioButton.scss';

interface IRadioButtonGroupProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    'onBlur' | 'onChange' | 'role'
  > {
  defaultValue?: string;
  name?: string;
  value?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;

  // from `formikFieldProps` spread but not currently used
  feedback?: unknown;
  invalid?: unknown;
}

interface IRadioButtonGroupState {
  value?: string | null;
}

export interface IRadioButtonGroupContext {
  name?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const RadioButtonGroupContext = React.createContext<IRadioButtonGroupContext | null>(
  null
);

export class RadioButtonGroup extends React.PureComponent<
  IRadioButtonGroupProps,
  IRadioButtonGroupState
> {
  private controlled: boolean;

  constructor(props: IRadioButtonGroupProps) {
    super(props);

    this.controlled = props.value !== undefined;
    if (!this.controlled) {
      this.state = {
        value: props.defaultValue || null
      };
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const { value } = e.target;

    if (!this.controlled) {
      this.setState({ value });
    }

    onChange?.(e, value);
  };

  render() {
    const {
      children,
      className,
      defaultValue,
      feedback,
      invalid,
      name,
      value: valueProp,
      onBlur,
      onChange,
      ...rest
    } = this.props;
    const value = this.controlled ? valueProp : this.state.value;
    const contextProps = {
      name,
      value,
      onChange: this.handleChange,
      onBlur
    };

    return (
      <div
        className={classNames('radio-button-group', className)}
        role="radiogroup"
        {...rest}
      >
        <RadioButtonGroupContext.Provider value={contextProps}>
          {children}
        </RadioButtonGroupContext.Provider>
      </div>
    );
  }
}
