import { FunctionComponent, useEffect, useRef } from 'react';
import { BreadcrumbEmitter } from './BreadcrumbEmitter';
import IBreadcrumb from './IBreadcrumb';

const Breadcrumb: FunctionComponent<IBreadcrumb> = ({ to, label }) => {
  const prevTo = useRef<string | null>(null);

  useEffect(() => {
    if (prevTo.current === null) {
      BreadcrumbEmitter.emit('add', { to, label });
    } else {
      BreadcrumbEmitter.emit('update', prevTo.current, { to, label });
    }

    prevTo.current = to;
  }, [to, label]);

  /**
   * remove breadcrumb
   * NOTE: isolate in separate effect hook so does not run on label update to
   * handle issue where a link could remove itself prematurely
   * ex. navigating to "Recent Activity" page would cause the engagement
   * breadcrumb to remove itself
   *
   * @see https://dev.azure.com/RyanITApps/ITApplications/_workitems/edit/26531/
   */
  useEffect(() => {
    return () => {
      BreadcrumbEmitter.emit('remove', to);
    };
  }, [to]);

  return null;
};

export default Breadcrumb;
