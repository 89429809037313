import debounce from 'lodash.debounce';

interface ThrottleOptions {
  minimumCharacters?: number;
  delay?: number;
}

const defaultOptions: ThrottleOptions = {
  minimumCharacters: 0,
  delay: 300
};

export function throttle(
  fn: (query: string) => any,
  options?: ThrottleOptions
) {
  const { minimumCharacters, delay } = {
    ...defaultOptions,
    ...options
  };
  const debounced = debounce(fn, delay);

  return function (query: string) {
    if (query.length >= minimumCharacters!) {
      debounced(query);
    }
  };
}
