import EventEmitter from 'events';

import { createContext } from 'react';

import { ICodeNotesDrawerContext } from './utils';

export const CodeNotesDrawerContext = createContext<ICodeNotesDrawerContext>({
  codeNotesDrawerEvents: new EventEmitter(),
  onCodeNotesDrawerOpen: () => {}
});

export default CodeNotesDrawerContext;
