import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import UserInfoCard from 'components/UserInfoCard/UserInfoCard';
import { WithUser, withUser } from 'contexts/UserContext';
import { IUser, UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import { formatDate } from 'utils/formatDate';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import parseISO from 'date-fns/parseISO';
import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { TabManager, TabPanel, Tabs } from '@ryan/components';

import ConfirmationModal from '../../components/Modal/ConfirmationModal/ConfirmationModal';
import UnsavedChangesContext from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import EditUserProjects from './EditUserProjects/EditUserProjects';
import EditUserRoleAndPermissions from './EditUserRoleAndPermissions/EditUserRoleAndPermissions';
import { TGetUserInfoForProfilePage } from './utils/TEditUser';

import './EditUser.scss';

function nullableDate(date: string | null) {
  return date ? parseISO(date) : null;
}

enum EditUserTab {
  Projects = 1,
  RoleAndPermissions = 2
}

interface IEditUserProps
  extends WithUser,
    WithTranslation,
    RouteComponentProps<{ userGuid: string }> {}

interface IEditUserState {
  activeTabKey: EditUserTab | null;
  canActivateUser: boolean;
  canCancelUserDeactivation: boolean;
  canDeactivateUser: boolean;
  canEditUserExecutiveAccess: boolean;
  canEditUserPermissions: boolean;
  canEditUserProjects: boolean;
  canEditUserRole: boolean;
  newUserRequest: TGetUserInfoForProfilePage | null;
  shouldBlockNavigation: boolean;
  tabs: React.ComponentProps<typeof Tabs>['tabs'];
  targetTabKey: EditUserTab | null;
  userToEdit: IUser | null;
}

export class EditUser extends Component<IEditUserProps, IEditUserState> {
  readonly state: IEditUserState = {
    activeTabKey: null,
    canActivateUser: false,
    canCancelUserDeactivation: false,
    canDeactivateUser: false,
    canEditUserExecutiveAccess: false,
    canEditUserPermissions: false,
    canEditUserProjects: false,
    canEditUserRole: false,
    newUserRequest: null,
    shouldBlockNavigation: false,
    tabs: [],
    targetTabKey: null,
    userToEdit: null
  };

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  componentDidMount() {
    this.fetchUserData();
  }

  async fetchUserData() {
    const { t, match, history, permissionService } = this.props;
    const { userGuid: URLPathGuid } = match.params;

    try {
      const [userResponse, nurResponse] = await Promise.all([
        ApiService.getUser(URLPathGuid),
        ApiService.getUserInfoForProfilePage(URLPathGuid)
      ]);
      const userToEdit = userResponse.data;
      const nurDetails = nurResponse.data;

      if (!userToEdit.queueItemGuid && !!nurDetails.queueItemGuid) {
        userToEdit.queueItemGuid = nurDetails.queueItemGuid;
      }

      const newUserRequest = nurResponse.data.newUserRequestStatus;

      const permissions = await Promise.all([
        permissionService.canEditUserRole(userToEdit),
        permissionService.canEditUserPermissions(userToEdit),
        permissionService.canActivateUser(userToEdit),
        permissionService.canDeactivateUser(userToEdit),
        permissionService.canCancelUserDeactivation(userToEdit)
      ]);

      const [
        canEditUserRole,
        canEditUserPermissions,
        canActivateUser,
        canDeactivateUser,
        canCancelUserDeactivation
      ] = permissions;

      const canEditUserProjects =
        permissionService.canEditUserProjects(userToEdit);
      const canEditUserExecutiveAccess =
        permissionService.canEditUserExecutiveAccess(userToEdit);

      if (canEditUserProjects || canEditUserRole) {
        const tabs = [];

        if (canEditUserProjects) {
          tabs.push({
            tabKey: EditUserTab.Projects,
            text: t('editUser.projectAccess')
          });
        }

        if (canEditUserRole) {
          tabs.push({
            tabKey: EditUserTab.RoleAndPermissions,
            text: t('editUser.userRoleAndPermissions')
          });
        }

        this.setState({
          activeTabKey: canEditUserProjects
            ? EditUserTab.Projects
            : EditUserTab.RoleAndPermissions,
          canActivateUser,
          canCancelUserDeactivation,
          canDeactivateUser,
          canEditUserExecutiveAccess,
          canEditUserPermissions,
          canEditUserProjects,
          canEditUserRole,
          newUserRequest,
          tabs,
          userToEdit
        });
      } else {
        history.replace('/app/403');
      }
    } catch (error: any) {
      if (!ApiService.isCancel(error) && error?.response) {
        switch (error.response.status) {
          case 403:
            history.replace('/app/403');
            break;
          case 404:
            history.push('/app/team/manage');
            break;
        }
      } else {
        pushServerErrorToast();
      }
    }
  }

  handleTabChange = (activeTabKey: string | number) => {
    const { isUnsavedChanges } = this.context;

    if (isUnsavedChanges) {
      this.setState({
        shouldBlockNavigation: true,
        targetTabKey: activeTabKey as EditUserTab
      });
    } else {
      this.setState({ activeTabKey: activeTabKey as EditUserTab });
    }
  };

  handleOnDone = () => {
    const { history, match } = this.props;
    const {
      isFromManageTeamNewUserRequests,
      isFromPersonalInformationPage,
      previousLocationPathname
    } = (history?.location?.state as any) || {};
    const { userToEdit } = this.state;

    const { userGuid: URLPathGuid } = match.params;

    if (previousLocationPathname) {
      history.push(previousLocationPathname);
      return;
    }

    if (isFromManageTeamNewUserRequests) {
      history.push('/app/team/manage/newusers');
      return;
    }

    if (isFromPersonalInformationPage) {
      history.push(`/app/personal-information/${URLPathGuid}`);
      return;
    }

    switch (userToEdit!.userTypeId) {
      case UserType.Ryan:
        return history.push('/app/team/manage/ryan');
      case UserType.Client:
        return history.push('/app/team/manage/client');
      case UserType.ClientRepresentativeAppraiser:
      case UserType.ClientRepresentativeAttorney:
      case UserType.TaxAgencyRepresentative:
        return history.push('/app/team/manage/third-party');
      default:
        return history.push('/app/team/manage');
    }
  };

  handleConfirm = () => {
    const { targetTabKey } = this.state;

    this.context.setIsUnsavedChanges(false);
    this.setState({
      shouldBlockNavigation: false,
      activeTabKey: targetTabKey as EditUserTab
    });
  };

  isUserActive(): boolean {
    const { userToEdit } = this.state;

    if (!userToEdit) {
      return false;
    }

    const activateDate = nullableDate(userToEdit.activateDate);
    const deactivateDate = nullableDate(userToEdit.deactivateDate);
    const today = new Date();

    // Is the user active?
    // Is there an activation date and has it been hit?
    // And is there a deactivation date, has it been hit, or is it the
    // activation date more recent?
    return (
      activateDate !== null &&
      activateDate <= today &&
      (deactivateDate === null ||
        today < deactivateDate ||
        deactivateDate < activateDate)
    );
  }

  render() {
    const { match, permissionService: ps, t: getTextToDisplay } = this.props;
    const {
      activeTabKey,
      canActivateUser,
      canCancelUserDeactivation,
      canDeactivateUser,
      canEditUserExecutiveAccess,
      canEditUserPermissions,
      canEditUserRole,
      shouldBlockNavigation,
      tabs,
      userToEdit,
      newUserRequest
    } = this.state;
    const { userGuid: URLPathGuid } = match.params;
    const isDeferredActivation =
      canActivateUser && !this.isUserActive() && !userToEdit?.deactivateDate;

    const lastUpdatedByDisplayName = ps.isUser(
      userToEdit && userToEdit.accessUpdatedBy
    )
      ? getTextToDisplay('You')
      : userToEdit?.accessUpdatedByUserFullName;

    const approvedByDisplayName = ps.isUser(
      newUserRequest?.approvedBy?.userGuid ?? ''
    )
      ? getTextToDisplay('You')
      : newUserRequest?.approvedBy?.fullName;

    return (
      <div className="edit-user-page">
        <DocumentTitle title={getTextToDisplay('editUser.title')} />
        <Breadcrumb label={getTextToDisplay('Team')} to="/app/team" />
        <Breadcrumb
          label={getTextToDisplay('contactInformation.personsProfile', {
            fullName: userToEdit ? userToEdit.fullName : 'User'
          })}
          to={`/app/personal-information/${URLPathGuid}`}
        />
        <Breadcrumb label={getTextToDisplay('editUser.title')} to={match.url} />

        <h1 className="ry-h1">{getTextToDisplay('editUser.title')}</h1>
        <TabManager
          activeTabKey={activeTabKey || 3}
          onTabChange={this.handleTabChange}
        >
          {/* Tabs if multiple accessible */}
          {tabs.length === 2 && <Tabs tabs={tabs} />}

          {userToEdit && (
            <div className="row">
              {shouldBlockNavigation && (
                <ConfirmationModal
                  cancelTextToDisplay={getTextToDisplay(
                    'modal.confirmationModal.noCancel'
                  )}
                  confirmationMessage={getTextToDisplay(
                    'modal.confirmationModal.unsavedMessage'
                  )}
                  isPositive
                  onClose={() =>
                    this.setState({ shouldBlockNavigation: false })
                  }
                  onSubmit={() => this.handleConfirm()}
                  submitTextToDisplay={getTextToDisplay(
                    'modal.confirmationModal.yesLeave'
                  )}
                  title={getTextToDisplay(
                    'modal.confirmationModal.discardChanges'
                  )}
                />
              )}
              <div className="col-lg-4 order-lg-last">
                <UserInfoCard user={userToEdit} />
                <div className="well">
                  {!isDeferredActivation && (
                    <ul className="row labeled-list">
                      <li className="col">
                        <label>{getTextToDisplay('User Since')}</label>
                        {userToEdit.acceptedTermsOfUseDate
                          ? formatDate(userToEdit.acceptedTermsOfUseDate)
                          : '–'}
                      </li>
                      <li className="col">
                        <label>{getTextToDisplay('Last Active')}</label>
                        {userToEdit.loginDate
                          ? formatDate(userToEdit.loginDate, true)
                          : '–'}
                      </li>
                    </ul>
                  )}
                  {userToEdit.userTypeId !== UserType.Ryan && (
                    <ul className="row labeled-list">
                      <li className="col">
                        <label>{getTextToDisplay('Last Updated By')}</label>
                        {userToEdit?.accessUpdatedBy
                          ? lastUpdatedByDisplayName
                          : approvedByDisplayName}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="col-lg-8">
                <TabPanel tabKey={EditUserTab.Projects}>
                  <EditUserProjects
                    onDone={this.handleOnDone}
                    userToEdit={userToEdit}
                  />
                </TabPanel>
                <TabPanel tabKey={EditUserTab.RoleAndPermissions}>
                  <EditUserRoleAndPermissions
                    canActivateUser={canActivateUser}
                    canCancelUserDeactivation={canCancelUserDeactivation}
                    canDeactivateUser={canDeactivateUser}
                    canEditUserExecutiveAccess={canEditUserExecutiveAccess}
                    canEditUserPermissions={canEditUserPermissions}
                    canEditUserRole={canEditUserRole}
                    onDone={this.handleOnDone}
                    userToEdit={userToEdit}
                  />
                </TabPanel>
              </div>
            </div>
          )}
        </TabManager>
      </div>
    );
  }
}

export default withTranslation()(withUser(EditUser));
