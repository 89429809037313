import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ENV from '../../env';
import HelpModalForm from '../HelpModalForm/HelpModalForm';
import { HelpFormEventType } from './utils/HelpFormEventTypeEnum';

import './HelpModalWrapper.scss';

const HelpModalWrapper: FunctionComponent = () => {
  const { t: getTextToDisplay } = useTranslation();

  useEffect(() => {
    document.body.className = `${document.body.className} content-only`.trim();
  }, []);

  const onHandleEvent = (eventType: HelpFormEventType) => {
    const isWithinIframe = window.top !== window.self;

    if (!window.top || !isWithinIframe) return;

    window.top.postMessage(
      JSON.stringify({ type: eventType }),
      ENV.PORTAL_DOMAIN
    );
  };

  const handleSubmit = () => {
    onHandleEvent(HelpFormEventType.Submit);
  };

  const handleCancel = () => {
    onHandleEvent(HelpFormEventType.Cancel);
  };

  return (
    <div className="help-page-form">
      <h1 className="ry-h2 help-page-title">
        {getTextToDisplay('helpModal.title')}
      </h1>
      <HelpModalForm onClose={handleCancel} onSubmit={handleSubmit} />
    </div>
  );
};

export default HelpModalWrapper;
