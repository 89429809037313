export interface LanguageType {
  code: string;
  description: string;
}

export const LanguageTypes: LanguageType[] = [
  // US English
  {
    code: 'en-US',
    description: 'English'
  },

  // Canadian French
  {
    code: 'fr-CA',
    description: 'French (Canadian)'
  }
];
