import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

export interface IDocumentTitle {
  title: string;
}

const DocumentTitle: FunctionComponent<IDocumentTitle> = ({ title }) => (
  <Helmet>
    <title>{`${title} - Ryan`}</title>
  </Helmet>
);

export default DocumentTitle;
