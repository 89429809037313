import Checklocks from 'components/Checklocks';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';

import { Card } from '@ryan/components';

import './RolePermissionsLegend.scss';

interface IRolePermissionsLegendProps {
  t: TFunction;
}

export const RolePermissionsLegend: FunctionComponent<IRolePermissionsLegendProps> =
  ({ t }) => (
    <Card className="role-permissions-legend">
      <ul>
        <li>
          <Checklocks checked={false} onChange={() => {}} value="unchecked" />
          <h3 className="ry-h3">{t('manageRoles.legend.unlockedTitle')}</h3>
          <p>{t('manageRoles.legend.unlockedDescription')}</p>
        </li>
        <li>
          <Checklocks checked onChange={() => {}} value="checked" />
          <h3 className="ry-h3">{t('manageRoles.legend.lockedTitle')}</h3>
          <p>{t('manageRoles.legend.lockedDescription')}</p>
        </li>
      </ul>
    </Card>
  );

export default RolePermissionsLegend;
