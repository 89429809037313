export enum OverflowMenuIconEnums {
  HIDE = 'hide',
  SHOW = 'show',
  TRASH = 'trash',
  USER = 'user',
  USER_CIRCLE = 'user-circle',
  USER_CLOCK = 'user-clock',
  USER_DENY = 'user-deny',
  USER_PENCIL = 'user-pencil'
}
