import { findAccount } from 'contexts/UserContext/findAccount';
import {
  IAccount,
  ICustomViewCreate,
  ICustomViewCreateType,
  ICustomViewFilterCreate,
  ICustomViewFilterOperator,
  ICustomViewFilterType,
  ICustomViewType
} from 'interfaces';
import i18n from 'utils/i18n';

interface Hash<T> {
  [key: string]: T;
}

export enum Checked {
  Off,
  On,
  Indeterminate
}

export interface ICheckedAccounts {
  tree: IAccount[];
  isExecutiveView: boolean;
  checkedSingles: Hash<Checked>;
  checkedParents: Hash<Checked>;
  view: ICustomViewCreate | null;

  isChecked(accountGuid: string, includingSubsidiaries?: boolean): Checked;
  isAllChecked(): boolean;

  toggle(accountGuid: string, includingSubsidiaries: boolean): ICheckedAccounts;
  toggleAll(): ICheckedAccounts;
}

function forEachAccount(
  accounts: IAccount[],
  callback: (account: IAccount) => void
) {
  for (let i = 0; i < accounts.length; i++) {
    callback(accounts[i]);
    forEachAccount(accounts[i].accounts, callback);
  }
}

/**
 * Returns true if accounts exist and all accounts are checked.
 */
function isAllChecked(this: ICheckedAccounts) {
  return (
    this.tree.length > 0 &&
    this.tree
      .map(({ accountGuid, accounts }) => {
        const hasChildren = accounts.length > 0;
        return hasChildren
          ? this.checkedParents[accountGuid]
          : this.checkedSingles[accountGuid];
      })
      .every(checked => checked === Checked.On)
  );
}

function isChecked(
  this: ICheckedAccounts,
  accountGuid: string,
  includingSubsidiaries = false
): Checked {
  return (
    (includingSubsidiaries
      ? this.checkedParents[accountGuid]
      : this.checkedSingles[accountGuid]) || Checked.Off
  );
}

function toggle(
  this: ICheckedAccounts,
  accountGuid: string,
  includingSubsidiaries: boolean
) {
  const checkedSingles = { ...this.checkedSingles };
  const prevChecked = isChecked.call(this, accountGuid, includingSubsidiaries);
  const nextChecked = prevChecked === Checked.On ? Checked.Off : Checked.On;

  checkedSingles[accountGuid] = nextChecked;

  if (includingSubsidiaries) {
    const match = findAccount(this.tree, a => a.accountGuid === accountGuid);
    if (match) {
      const setChecked = (account: IAccount) => {
        checkedSingles[account.accountGuid] = nextChecked;
        account.accounts.forEach(setChecked);
      };

      setChecked(match.element);
    }
  }

  return makeCheckedAccounts(this.tree, checkedSingles, this.isExecutiveView);
}

function toggleAll(this: ICheckedAccounts) {
  const prevChecked = this.isAllChecked();
  const nextChecked = !prevChecked;

  const checkedSingles: Hash<Checked> = {};
  forEachAccount(this.tree, (account: IAccount) => {
    checkedSingles[account.accountGuid] = nextChecked
      ? Checked.On
      : Checked.Off;
  });

  return makeCheckedAccounts(this.tree, checkedSingles, this.isExecutiveView);
}

function makeCheckedParents(tree: IAccount[], checkedSingles: Hash<Checked>) {
  const checkedParents: Hash<Checked> = {};

  function traverse(parent: IAccount): Checked {
    const hasChildren = parent.accounts.length > 0;
    if (hasChildren) {
      const checkedChildren = parent.accounts.map(traverse);

      // Also consider this account as a child if there are projects associated here.
      const parentEngagementCount =
        parent.activeEngagementCount + parent.inactiveEngagementCount;
      if (parentEngagementCount) {
        checkedChildren.push(checkedSingles[parent.accountGuid]);
      }

      checkedParents[parent.accountGuid] = checkedChildren.every(
        c => c === Checked.On
      )
        ? Checked.On
        : checkedChildren.every(c => c === Checked.Off)
        ? Checked.Off
        : Checked.Indeterminate;

      return checkedParents[parent.accountGuid];
    }
    return checkedSingles[parent.accountGuid];
  }

  tree.forEach(traverse);

  return checkedParents;
}

function makeCustomViewFilters(
  tree: IAccount[],
  checkedParents: Hash<Checked>,
  checkedSingles: Hash<Checked>
) {
  const filters: ICustomViewFilterCreate[] = [];

  function makeFilter(account: IAccount, includingSubsidiaries: boolean) {
    return {
      type: ICustomViewFilterType.Account,
      operator: includingSubsidiaries
        ? ICustomViewFilterOperator.EqualsIncludingSubsidiaries
        : ICustomViewFilterOperator.Equals,
      value: account.accountGuid
    };
  }

  function traverse(account: IAccount) {
    const hasChildren = account.accounts.length > 0;
    if (hasChildren) {
      const checked = checkedParents[account.accountGuid];

      if (checked === Checked.On) {
        filters.push(makeFilter(account, true));
      }

      if (checked === Checked.Indeterminate) {
        if (
          account.activeEngagementCount + account.inactiveEngagementCount &&
          checkedSingles[account.accountGuid] === Checked.On
        ) {
          filters.push(makeFilter(account, false));
        }

        account.accounts.forEach(traverse);
      }
    } else {
      const checked = checkedSingles[account.accountGuid];

      if (checked === Checked.On) {
        filters.push(makeFilter(account, false));
      }
    }
  }

  tree.forEach(traverse);

  return filters;
}

function makeCustomView(
  tree: IAccount[],
  checkedSingles: Hash<Checked>,
  checkedParents: Hash<Checked>,
  isExecutiveView: boolean
): ICustomViewCreate | null {
  const filters = makeCustomViewFilters(tree, checkedParents, checkedSingles);

  if (filters.length > 0) {
    let name = i18n.t('n Companies', { count: filters.length });
    let createType = ICustomViewCreateType.AccountMultiple;

    // if there's only one selection, we can consider this an account view.
    if (filters.length === 1) {
      const accountGuid = filters[0].value;
      const match = findAccount(tree, a => a.accountGuid === accountGuid);
      if (match) {
        name = match.element.name;
        createType = ICustomViewCreateType.AccountSingle;
      }
    }

    return {
      name,
      type: ICustomViewType.Dynamic,
      createType,
      isExecutiveView,
      filters,
      engagements: null
    };
  }

  return null;
}

function makeCheckedAccounts(
  tree: IAccount[],
  checkedSingles: Hash<Checked>,
  isExecutiveView: boolean
): ICheckedAccounts {
  const checkedParents = makeCheckedParents(tree, checkedSingles);
  const view = makeCustomView(
    tree,
    checkedSingles,
    checkedParents,
    isExecutiveView
  );
  return {
    tree,
    isExecutiveView,
    checkedSingles,
    checkedParents,
    view,
    isAllChecked,
    isChecked,
    toggle,
    toggleAll
  };
}

export function CheckedAccounts(
  tree: IAccount[],
  isExecutiveView: boolean
): ICheckedAccounts {
  const checkedSingles: Hash<Checked> = {};
  forEachAccount(tree, (account: IAccount) => {
    checkedSingles[account.accountGuid] = Checked.Off;
  });

  return makeCheckedAccounts(tree, checkedSingles, isExecutiveView);
}
