import { IAccount } from 'interfaces';
import makeTreeSearch from 'utils/makeTreeSearch';

export const findAccount = makeTreeSearch<IAccount>(a => a.accounts);

export function findAccountByGuid(accounts: IAccount[], accountGuid: string) {
  const match = findAccount(
    accounts,
    account => account.accountGuid === accountGuid
  );
  if (match) {
    return {
      account: match.element,
      accountHierarchy: match.path.map(account => account.accountGuid)
    };
  }
}
