import { ActivityType } from 'interfaces';

import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';

export const TeamActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    user,
    activity,
    isNotification,
    showAccountName,
    showEngagementName,
    onCloseNotificationDrawer
  } = props;
  const { entityGuid, userInformation } = activity;

  switch (activity.activityTypeId) {
    case ActivityType.UserAddedToEngagement: {
      const _for = `for-${entityGuid === user.userGuid ? 'you' : 'x'}`;
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.LightPurple}
          ctaText={t('activity.team.viewProfile')}
          icon="users"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.team.addedToEngagement.title')}
          to={`/app/personal-information/${entityGuid}`}
        >
          <Trans i18nKey={`activity.team.addedToEngagement.${_for}`}>
            <b />
            {{ userName: userInformation?.fullName || 'unknown user' }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
