import classnames from 'classnames';
import { endOfDay, startOfDay } from 'date-fns';
import ENV from 'env';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { Button, ButtonGroup, EButtonVariant, Icon } from '@ryan/components';

import Empty from '../../../components/Empty';
import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  IEngagement,
  IReportRequest,
  Permission,
  ReportSections,
  UserType
} from '../../../interfaces';
import AmplitudeApiService from '../../../services/AmplitudeApiService';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import { Formik, FormikProps } from '../../../utils/forms';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import scrollTo from '../../../utils/scrollTo';
import switcherDidUpdate from '../../../utils/switcherDidUpdate';
import AudienceSection from './AudienceSection';
import ContentSection from './ContentSection';
import CoverPageSection from './CoverPageSection';
import ProjectsSection from './ProjectsSection';
import TimeframeSection from './TimeframeSection';
import { IReportBuilderFormValues, getSchema } from './schema';
import { ActiveTabEnums } from './utils/ActiveTabEnums';

import './Reporting.scss';

interface IReportingProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps {}

interface IReportingState {
  activeTab: string;
  engagements: IEngagement[];
  formikKey: number;
  hasHistoricalProjects: boolean;
  hasMultiAccounts: boolean;
  hasPublishedEngagements: boolean;
  isMobileReportBuilderContentVisible: boolean;
  loading: Promise<any> | null;
  loadingPublishedEngagements: boolean;
  selectedPanel: ReportSections | null;
}

export class Reporting extends Component<IReportingProps, IReportingState> {
  private _isMounted = false;

  private reportingOptionsCancelToken?: CancelTokenSource;
  private reportRequestCancelToken?: CancelTokenSource;

  private reportingRef = React.createRef<HTMLDivElement>();

  schema = {};

  constructor(props: IReportingProps) {
    super(props);

    const { permissionService, t: getTextToDisplay } = props;
    this.schema = getSchema(getTextToDisplay);
    this.state = {
      activeTab: ActiveTabEnums.ReportBuilder,
      engagements: [],
      formikKey: 1,
      hasHistoricalProjects: false,
      hasMultiAccounts: false,
      hasPublishedEngagements: true,
      isMobileReportBuilderContentVisible: true,
      loading: null,
      loadingPublishedEngagements: permissionService.isRyan(),
      selectedPanel: ReportSections.CoverPage
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchReportOptions();
  }

  componentDidUpdate(prevProps: IReportingProps) {
    // If the active account changed, we need to clear out all
    // previous selections and "start them over".
    if (switcherDidUpdate(prevProps, this.props)) {
      const { history } = this.props;

      // Clear engagement from hash
      this.props.history.push({
        ...history.location,
        hash: ''
      });

      // Update selected panel and remount Formik
      this.setState(({ formikKey }) => ({
        selectedPanel: ReportSections.CoverPage,
        formikKey: formikKey + 1
      }));

      this.fetchReportOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.reportingOptionsCancelToken?.cancel();
    this.reportRequestCancelToken?.cancel();
  }

  calculateDateDelta = (firstDate: Date, secondDate: Date): number => {
    const diff = Math.abs(firstDate.getTime() - secondDate.getTime());
    const dateRangeNumberOfDays = Math.ceil(diff / (1000 * 3600 * 24));

    return dateRangeNumberOfDays;
  };

  getTimeframeType = (type: string) => {
    switch (type) {
      case '1':
        return 'as-of';
      case '2':
        return 'date-range';
      default:
        return 'as-of';
    }
  };

  getAudienceType = (type: string) => {
    switch (type) {
      case '1':
        return 'Myself';
      case '2':
        return 'Client';
      default:
        return 'Myself';
    }
  };

  returnSelectedSections = (values: IReportBuilderFormValues): string[] => {
    const {
      contentLearnings,
      contentProjectOverview,
      contentDataRequests,
      contentTasks,
      contentSavingsSummary
    } = values;

    const sections: string[] = [];

    !!contentSavingsSummary && sections.push('Savings Summary');
    !!contentProjectOverview && sections.push('Project Team');
    !!contentDataRequests && sections.push('Data Requests');
    !!contentTasks && sections.push('Tasks');
    !!contentLearnings && sections.push('Learnings');

    return sections;
  };

  buildAmplitudeReportDetails = (values: IReportBuilderFormValues) => {
    const timeframeType = this.getTimeframeType(values.timeframeType);
    return {
      'executive-summary': values.coverPageExecutiveSummary
        ? values.coverPageExecutiveSummary.length
        : 0,
      'has-custom-logo': values.coverPageIncludeLogo,
      'timeframe-type': timeframeType,
      'as-of-days-changed':
        values.timeframeType === '1' && values.timeframeEndDate
          ? this.calculateDateDelta(values.timeframeEndDate, new Date())
          : null,
      'date-range-number-of-days':
        values.timeframeType === '2' &&
        values.timeframeStartDate &&
        values.timeframeEndDate
          ? this.calculateDateDelta(
              values.timeframeStartDate,
              values.timeframeEndDate
            )
          : null,
      'audience-type': this.getAudienceType(values.audienceType),
      'number-of-projects': values.selectedPublishedEngagements
        ? values.selectedPublishedEngagements.length
        : 0,
      'has-projects-managed-outside-the-ryan.com-platform':
        values.manageHistoricalSavingsEngagements,
      'selection-content-sections': this.returnSelectedSections(values),
      'content-savings-summary-custom-date-enabled':
        values.contentSavingsCustomDate,
      'content-savings-summary-timeframe-type': this.getTimeframeType(
        values.contentSavingsType
      ),
      'content-savings-summary-as-of-days-changed':
        values.contentSavingsType === '1' && values.contentSavingsEndDate
          ? this.calculateDateDelta(values.contentSavingsEndDate, new Date())
          : null,
      'content-savings-summary-date-range-number-of-days':
        values.contentSavingsStartDate && values.contentSavingsEndDate
          ? this.calculateDateDelta(
              values.contentSavingsStartDate,
              values.contentSavingsEndDate
            )
          : null
    };
  };

  fetchReportOptions() {
    const { activeView, permissionService, user } = this.props;

    if (permissionService.isRyan()) {
      this.setState({ loadingPublishedEngagements: true });
    }

    // refresh cancel token
    this.reportingOptionsCancelToken?.cancel();
    this.reportingOptionsCancelToken = ApiService.CancelToken.source();

    ApiService.getReportOptions(
      activeView.customViewGuid,
      this.reportingOptionsCancelToken.token
    )
      .then(response => {
        this.setState({
          hasHistoricalProjects: response.data.hasHistoricalProjects,
          hasMultiAccounts: response.data.hasMultiAccounts,
          hasPublishedEngagements: response.data.hasPublishedEngagements,
          loadingPublishedEngagements: false
        });
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });

    ApiService.getEngagementsForCustomViewByUser(
      activeView.customViewGuid,
      user.profile.userGuid,
      {}
    ).then(response => {
      this.setState({ engagements: response.data.results });
    });
  }

  /**
   * Handle Report Panel Navigation
   */
  handleSelectedPanel = (section: ReportSections, formik: any) => {
    const { selectedPanel } = this.state;
    if (selectedPanel === section) {
      this.setState({ selectedPanel: null });
    } else {
      this.setState({ selectedPanel: section });
      let sectionName = '';
      switch (this.state.selectedPanel) {
        case ReportSections.CoverPage:
          sectionName = 'view-cover-page-section';
          break;
        case ReportSections.Audience:
          sectionName = 'view-audience-section';
          break;
        case ReportSections.Content:
          sectionName = 'view-content-section';
          break;
        case ReportSections.Timeframe:
          sectionName = 'view-timeframe-section';
          break;
        case ReportSections.Projects:
          sectionName = 'view-projects-section';
          break;
        default:
          sectionName = 'undefined-section';
          //we see these and don't understand why, comsole log for nw, then bail out
          console.log(
            'Panel ',
            this.state.selectedPanel,
            ' was clicked, aborting'
          );
          return;
      }
      AmplitudeApiService.logEvent(
        sectionName,
        this.buildAmplitudeReportDetails(formik.values)
      );
    }
  };

  handleMoveNext = (skip = 0, formik: any) => {
    const { selectedPanel } = this.state;
    let sectionName = '';
    switch (selectedPanel) {
      case ReportSections.CoverPage:
        sectionName = 'click-next-cover-page-section';
        break;
      case ReportSections.Audience:
        sectionName = 'click-next-audience-section';
        break;
      case ReportSections.Timeframe:
        sectionName = 'click-next-timeframe-section';
        break;
      case ReportSections.Projects:
        sectionName = 'click-next-projects-section';
        break;
      case ReportSections.Content: //There is no next button on this section, but I have this here to call it out
      default:
        sectionName = 'undefined-section-next';
        //we see these and don't understand why, comsole log for nw, then bail out
        console.log('Panel ', selectedPanel, ' was clicked, aborting');
        return;
    }
    AmplitudeApiService.logEvent(
      sectionName,
      this.buildAmplitudeReportDetails(formik.values)
    );
    this.setState({
      selectedPanel: (selectedPanel || ReportSections.CoverPage) + 1 + skip
    });
  };

  handleResetForm = (formik: FormikProps<any>) => {
    AmplitudeApiService.logEvent(
      'click-clear-report',
      this.buildAmplitudeReportDetails(formik.values)
    );

    formik.handleReset();
    this.setState({ selectedPanel: ReportSections.CoverPage });
  };

  handleSubmit = (values: IReportBuilderFormValues) => {
    const { activeView } = this.props;

    const publishedEngagementGuids = values.selectedPublishedEngagements.map(
      e => e.engagementGuid
    );

    const historicalSavingsEngagementGuids =
      values.selectedHistoricalSavingsEngagements.map(
        e => e.historicalSavingsSummaryGuid
      );

    const request: IReportRequest = {
      customViewGuid: activeView.customViewGuid,

      timezoneOffset: new Date().getTimezoneOffset(),

      /**
       * Description
       */
      executiveSummary: values.coverPageExecutiveSummary,

      /**
       * Logo
       * Selected logo, if defined.
       */
      reportRequestLogoGuid:
        values.coverPageIncludeLogo && values.coverPageLogoSelection
          ? values.coverPageLogoSelection
          : null,

      /**
       * Timeframe
       * End dates are pushed to the end of the day (11:59:59).
       * Start dates are the beginning of the day.
       */
      startDate:
        values.timeframeType === '1'
          ? null
          : startOfDay(values.timeframeStartDate!),
      endDate: endOfDay(values.timeframeEndDate!),

      /**
       * Audience
       */
      onBehalfOfMemberGuid:
        values.audienceType === '1'
          ? null
          : values.audienceOnBehalfOf!.userGuid,

      /**
       * Engagements that consist of both published and historical savings summaries AKA Managed outside of Ryan.com
       */

      selectedEngagements: publishedEngagementGuids.concat(
        historicalSavingsEngagementGuids
      ),

      /**
       * Content
       */
      contentSections: [
        'ProjectOverview',
        'Tasks',
        'DataRequests',
        'Learnings',
        'SavingsSummary'
      ].filter(s => values[`content${s}` as keyof IReportBuilderFormValues]),

      /**
       * Overrides for Savings
       */
      savingsSummaryHasCustomDate: values.contentSavingsCustomDate,
      savingsSummaryStartDate:
        !values.contentSavingsCustomDate || values.contentSavingsType === '1'
          ? null
          : startOfDay(values.contentSavingsStartDate!),
      savingsSummaryEndDate: !values.contentSavingsCustomDate
        ? null
        : endOfDay(values.contentSavingsEndDate!)
    };

    AmplitudeApiService.logEvent(
      'run-report-report-builder',
      this.buildAmplitudeReportDetails(values)
    );

    // refresh cancel token
    this.reportRequestCancelToken?.cancel();
    this.reportRequestCancelToken = ApiService.CancelToken.source();

    const apiPromise = ApiService.createReportRequest(
      request,
      this.reportRequestCancelToken.token
    )
      .then(response => {
        this.setState({ loading: null });
        const reportUrl = `${ENV.API_ROOT}/api/reports/${response.data}`;

        window.location.assign(reportUrl);
      })
      .catch(error => {
        if (this._isMounted) {
          this.setState({ loading: null });
        }
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });

    if (this._isMounted) {
      this.setState({ loading: apiPromise });
    }
  };

  handleMobileTabChange = (clickedTab: string) => {
    const { activeTab } = this.state;

    if (activeTab === clickedTab) {
      this.setState({
        activeTab: '',
        isMobileReportBuilderContentVisible: false
      });
    } else {
      if (clickedTab === ActiveTabEnums.UnpublishedExport) {
        AmplitudeApiService.logEvent('click-unpublished-export', {});
      } else if (clickedTab === ActiveTabEnums.ReportBuilder) {
        AmplitudeApiService.logEvent('click-report-builder', {});
      }
      this.setState({
        activeTab: clickedTab,
        isMobileReportBuilderContentVisible:
          clickedTab === ActiveTabEnums.ReportBuilder
      });
    }

    if (this.reportingRef.current) {
      scrollTo(this.reportingRef.current);
    }
  };

  handleTabChange = (activeTab: string) => {
    this.setState({
      activeTab
    });

    if (this.reportingRef.current) {
      scrollTo(this.reportingRef.current);
    }

    if (activeTab === 'reportBuilder') {
      AmplitudeApiService.logEvent('click-report-builder', {});
    }
  };

  render() {
    const { permissionService } = this.props;

    return (
      <div className="ry-report-container__centered" id="report-container">
        <div className="report-builder" style={{ flexGrow: '1' }}>
          <div className="report-builder-with-tabs__desktop">
            {!permissionService.isThirdParty() &&
            permissionService.hasPermission(Permission.ReportsView) ? (
              <div className="report-builder-with-tabs__desktop__data--only">
                {this.renderReportBuilder()}
              </div>
            ) : (
              <div className="report-builder-with-tabs__desktop__data--only"></div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderMobileContent = (tabs: string[]) => {
    const { t: getTextToDisplay } = this.props;
    const { activeTab, isMobileReportBuilderContentVisible } = this.state;

    return (
      <ul className="report-builder__nav__tabs--mobile">
        {tabs.map(tab => (
          <div key={tab}>
            <li
              className={classnames('report-builder__nav__tabs__tab--mobile', {
                'report-builder__nav__tabs__tab--mobile--active':
                  activeTab === tab
              })}
            >
              <button
                className={classnames(
                  'report-builder__nav__tabs__tab__button',
                  {
                    'report-builder__nav__tabs__tab__button--active--mobile':
                      activeTab === tab
                  }
                )}
                onClick={() => this.handleMobileTabChange(tab)}
              >
                {getTextToDisplay(`reportBuilder.tabs.${tab}`)}
              </button>
              <Icon name={activeTab === tab ? 'chevron-up' : 'chevron-down'} />
            </li>
            {tab === ActiveTabEnums.ReportBuilder &&
              isMobileReportBuilderContentVisible &&
              this.renderReportBuilder()}
          </div>
        ))}
      </ul>
    );
  };

  renderReportBuilder = () => {
    const {
      activeView,
      permissionService,
      t: getTextToDisplay,
      user
    } = this.props;
    const {
      formikKey,
      hasHistoricalProjects,
      hasPublishedEngagements,
      loading,
      loadingPublishedEngagements,
      selectedPanel
    } = this.state;

    if (loadingPublishedEngagements) {
      return (
        <div className="center-icon">
          <Icon className="loading-spin" name="loading" />
        </div>
      );
    }

    if (!hasPublishedEngagements && !hasHistoricalProjects) {
      return (
        <Empty icon="project-hidden">
          {getTextToDisplay('reportBuilder.empty')}
        </Empty>
      );
    }
    return (
      <>
        <h2 className="ry-h2">
          {getTextToDisplay('reportBuilder.tabs.reportBuilder')}
        </h2>
        <Formik<IReportBuilderFormValues>
          initialValues={{
            audienceOnBehalfOf: null,
            audienceType: '1',
            contentDataRequests: permissionService.hasPermission(
              Permission.DataRequestsView
            ),
            contentLearnings: permissionService.hasPermission(
              Permission.LearningsView
            ),
            contentProjectOverview: true,
            contentSavingsCustomDate: false,
            contentSavingsEndDate: null,
            contentSavingsStartDate: null,
            contentSavingsSummary: permissionService.hasPermission(
              Permission.SavingsSummaryView
            ),
            contentSavingsType: '1',
            contentTasks: permissionService.hasPermission(Permission.TasksView),
            coverPageExecutiveSummary: '',
            coverPageIncludeLogo: false,
            coverPageLogoSelection: '',
            timeframeEndDate: new Date(),
            timeframeStartDate: null,
            timeframeType: '1',
            manageHistoricalSavingsEngagements: false,
            selectedHistoricalSavingsEngagements: [],
            selectedPublishedEngagements: []
          }}
          key={formikKey}
          onSubmit={this.handleSubmit}
          validationSchema={this.schema}
        >
          {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <CoverPageSection
                canIncludeLogos={!this.state.hasMultiAccounts}
                customViewGuid={activeView.customViewGuid}
                formik={formik}
                onNext={this.handleMoveNext}
                onToggle={this.handleSelectedPanel}
                selectedPanel={selectedPanel}
              />

              <TimeframeSection
                formik={formik}
                onNext={() =>
                  this.handleMoveNext(
                    user.profile.userTypeId === UserType.Ryan ? 0 : 1,
                    formik
                  )
                }
                onToggle={this.handleSelectedPanel}
                selectedPanel={selectedPanel}
              />

              {user.profile.userTypeId === UserType.Ryan && (
                <AudienceSection
                  customViewGuid={activeView.customViewGuid}
                  formik={formik}
                  onNext={this.handleMoveNext}
                  onToggle={this.handleSelectedPanel}
                  selectedPanel={selectedPanel}
                />
              )}

              <ProjectsSection
                customViewGuid={activeView.customViewGuid}
                formik={formik}
                hasHistoricalProjects={this.state.hasHistoricalProjects}
                onNext={this.handleMoveNext}
                onToggle={this.handleSelectedPanel}
                permissionService={permissionService}
                selectedPanel={selectedPanel}
                userGuid={user.profile.userGuid}
              />

              <ContentSection
                formik={formik}
                onToggle={this.handleSelectedPanel}
                selectedPanel={selectedPanel}
              />

              <div className="report-builder__main-buttons">
                <ButtonGroup>
                  <Button
                    loading={loading}
                    text={getTextToDisplay('reportBuilder.generate')}
                    type="submit"
                    variant={EButtonVariant.PRIMARY}
                  />
                  <Button
                    onClick={() => this.handleResetForm(formik)}
                    text={getTextToDisplay('reportBuilder.clear')}
                    variant={EButtonVariant.SECONDARY}
                  />
                </ButtonGroup>
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  };

  renderTabs = (tabs: string[]) => {
    const { t: getTextToDisplay } = this.props;
    const { activeTab } = this.state;

    return (
      <ul className="report-builder__nav__tabs">
        {tabs.map(tab => (
          <li key={tab}>
            <button
              className={classnames('report-builder__nav__tabs__tab__button', {
                'report-builder__nav__tabs__tab__button--active':
                  activeTab === tab
              })}
              onClick={() => this.handleTabChange(tab)}
            >
              {getTextToDisplay(`reportBuilder.tabs.${tab}`)}
            </button>
          </li>
        ))}
      </ul>
    );
  };
}

export default withTranslation()(withUser(Reporting));
