import { IHelpFormRequest } from 'interfaces';
import ApiBase from './ApiBase';

export async function submitHelpForm(this: ApiBase, data: IHelpFormRequest) {
  return this.server.post(`/api/helpform/submit`, {
    email: data.email,
    name: data.name,
    subject: data.subject,
    message: data.message
  });
}

// Technically for logged in users.
export async function submitHelpFormByUser(
  this: ApiBase,
  data: IHelpFormRequest
) {
  return this.server.post(`/api/helpform/submitbyuser`, {
    subject: data.subject,
    message: data.message
  });
}
