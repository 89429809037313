import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CommonColumns from '../CommonColumns/CommonColumns';
import * as CommonColumnEnums from '../CommonColumns/CommonColumns.enums';
import * as ColumnsUtils from '../columns.utils';
import * as UseSingleProjectDataRequestEnums from './useSingleProjectDataRequestColumns.enums';
import * as UseSingleProjectDataRequestInterfaces from './useSingleProjectDataRequestColumns.interfaces';
import * as UseSingleProjectDataRequestUtils from './useSingleProjectDataRequestColumns.utils';

const useSingleProjectDataRequestColumns = ({
  onDataRequestCommentClick,
  onMarkDataDelivered,
  onSetItemToState
}: UseSingleProjectDataRequestInterfaces.TComponentProps) => {
  const ROOT_TO_SINGLE_PROJECT_DATA_REQUEST =
    'table.columns.singleProjectDataRequest';

  const { t: getTextToDisplay } = useTranslation();

  return useMemo(() => {
    const {
      ACTIONS: COLUMN_ID_ACTIONS,
      ASSIGNED_TO: COLUMN_ID_ASSIGNED_TO,
      COMMENT: COLUMN_ID_COMMENT,
      DATA_TYPE: COLUMN_ID_DATA_TYPE,
      DUE_DATE: COLUMN_ID_DUE_DATE,
      STATUS: COLUMN_ID_STATUS,
      TITLE: COLUMN_ID_TITLE,
      TRANSFERRED_FILES: COLUMN_ID_TRANSFERRED_FILES
    } = UseSingleProjectDataRequestEnums.EColumnId;
    const {
      ACTIONS_FOR_DATA_REQUEST: COLUMN_TYPE_ACTIONS_FOR_DATA_REQUEST,
      ASSIGNED_TO: COLUMN_TYPE_ASSIGNED_TO,
      COMMENT: COLUMN_TYPE_COMMENT,
      DATA_TYPE: COLUMN_TYPE_DATA_TYPE,
      DUE_DATE: COLUMN_TYPE_DUE_DATE,
      STATUS: COLUMN_TYPE_STATUS,
      TITLE_WITH_LINK: COLUMN_TYPE_TITLE_WITH_LINK
    } = CommonColumnEnums.EColumnType;

    const renderCommonColumn: UseSingleProjectDataRequestInterfaces.IRenderCommonColumn =
      props => (
        <CommonColumns
          {...UseSingleProjectDataRequestUtils.getDataForCommonColumn(props)}
        />
      );

    const renderTransferredFiles: UseSingleProjectDataRequestInterfaces.IRenderTransferredFiles =
      ({ transferredFileCount }) => (
        <span className="column-transferred-files">{transferredFileCount}</span>
      );

    const columnsToRenderMap: UseSingleProjectDataRequestInterfaces.TColumnsToRenderMap<UseSingleProjectDataRequestInterfaces.TDataRequest> =
      {
        [COLUMN_ID_ACTIONS]: item =>
          renderCommonColumn({
            callbacks: {
              onMarkDataDelivered: () => {
                onMarkDataDelivered(item);
              },
              onSetItemToState: stateAction => {
                onSetItemToState(stateAction, item);
              }
            },
            columnType: COLUMN_TYPE_ACTIONS_FOR_DATA_REQUEST,
            item
          }),
        [COLUMN_ID_ASSIGNED_TO]: item =>
          renderCommonColumn({ columnType: COLUMN_TYPE_ASSIGNED_TO, item }),
        [COLUMN_ID_COMMENT]: item =>
          renderCommonColumn({
            callbacks: {
              onDataRequestCommentClick: () => {
                onDataRequestCommentClick(
                  UseSingleProjectDataRequestUtils.getDataForDataRequestCommentClick(
                    item
                  )
                );
              }
            },
            columnType: COLUMN_TYPE_COMMENT,
            item
          }),
        [COLUMN_ID_DATA_TYPE]: item =>
          renderCommonColumn({ columnType: COLUMN_TYPE_DATA_TYPE, item }),
        [COLUMN_ID_DUE_DATE]: item =>
          renderCommonColumn({ columnType: COLUMN_TYPE_DUE_DATE, item }),
        [COLUMN_ID_STATUS]: item =>
          renderCommonColumn({ columnType: COLUMN_TYPE_STATUS, item }),
        [COLUMN_ID_TITLE]: item =>
          renderCommonColumn({ columnType: COLUMN_TYPE_TITLE_WITH_LINK, item }),
        [COLUMN_ID_TRANSFERRED_FILES]: renderTransferredFiles
      };

    return ColumnsUtils.columnTemplateMapper({
      columnTemplate:
        UseSingleProjectDataRequestUtils.getColumnTemplate(getTextToDisplay),
      columnsToRenderMap,
      getTextToDisplayCallback: getTextToDisplay,
      rootToText: ROOT_TO_SINGLE_PROJECT_DATA_REQUEST
    });
  }, [
    getTextToDisplay,
    onDataRequestCommentClick,
    onMarkDataDelivered,
    onSetItemToState
  ]);
};

export default useSingleProjectDataRequestColumns;
