import React from 'react';

import { autocompleteHighlight } from '@ryan/components';

import AutocompleteAjax, { IAutocompleteAjaxProps } from './AutocompleteAjax';

import './SystemAutocomplete.scss';

interface ISystemAutocompleteProps<T>
  extends Omit<IAutocompleteAjaxProps<T>, 'getOptionValue' | 'renderOption'> {
  label: string;
  onFetchOptions: (query: string) => Promise<T[]>;
}

const getOptionValue = <T extends { name?: string }>(system: T): string =>
  system?.name || '';

const renderOption = <T extends { name?: string }>(
  system: T,
  { query }: { query: string }
): JSX.Element => (
  <div className="system-autocomplete__option">
    <div className="system-autocomplete__option-text">
      {autocompleteHighlight(getOptionValue(system), query)}
    </div>
  </div>
);

const SystemAutocomplete = <T extends { name?: string }>({
  label,
  onBlur,
  onChange,
  onFetchOptions,
  ...props
}: ISystemAutocompleteProps<T>): React.ReactElement => {
  const handleChange = (option: T | null) => {
    onChange(option);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <AutocompleteAjax<T>
      getOptionValue={getOptionValue}
      label={label}
      onBlur={handleBlur}
      onChange={handleChange}
      onFetchOptions={onFetchOptions}
      renderOption={renderOption}
      {...props}
    />
  );
};

export default SystemAutocomplete;
