import { UserAutocomplete } from 'components/AutocompleteAjax';
import { RadioButton, RadioButtonGroup } from 'components/RadioButton';
import { IReportBuilderSection, ReportSections } from 'interfaces';
import ApiService from 'services/ApiService';
import { formikAutocompleteAjaxProps, formikFieldProps } from 'utils/forms';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { ReportBuilderPanel } from './ReportBuilderPanel';
import { getPanelStatus, panelHasNoErrors } from './reportingHelpers';

interface IAudienceSectionProps extends IReportBuilderSection, WithTranslation {
  customViewGuid: string;
}

export class AudienceSection extends Component<IAudienceSectionProps> {
  fieldsToValidate = ['audienceOnBehalfOf'];

  validateAndSendNext = () => {
    const { formik, onNext } = this.props;
    if (onNext && panelHasNoErrors(this.fieldsToValidate, formik)) {
      onNext(0, formik);
    }
  };

  render() {
    const { t, formik, onToggle, selectedPanel, customViewGuid } = this.props;

    return (
      <ReportBuilderPanel
        formik={formik}
        id={ReportSections.Audience}
        onNext={this.validateAndSendNext}
        onToggle={onToggle}
        selectedSection={selectedPanel}
        status={getPanelStatus(this.fieldsToValidate, formik)}
        title={t('reportBuilder.audience.title')}
      >
        <div className="report-builder__audience">
          <label className="ry-label">
            {t('reportBuilder.audience.helperText')}
          </label>
          <RadioButtonGroup {...formikFieldProps('audienceType', formik)}>
            <RadioButton label={t('reportBuilder.audience.myself')} value="1" />
            <RadioButton label={t('reportBuilder.audience.client')} value="2" />
          </RadioButtonGroup>
          {formik.values.audienceType === '2' && (
            <UserAutocomplete
              {...formikAutocompleteAjaxProps('audienceOnBehalfOf', formik)}
              label={t('reportBuilder.audience.onBehalfOf')}
              onFetchOptions={searchTerm =>
                ApiService.getAudienceWithPermissions(customViewGuid, {
                  searchTerm
                }).then(response => response.data)
              }
            />
          )}
        </div>
      </ReportBuilderPanel>
    );
  }
}

export default withTranslation()(AudienceSection);
