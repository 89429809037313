import AutocompleteAjax from 'components/AutocompleteAjax';
import Modal from 'components/Modal';
import { RadioButton, RadioButtonGroup } from 'components/RadioButton';
import {
  IWorkspace,
  IWorkspaceConnection,
  IWorkspaceRequest,
  WorkspaceSource
} from 'interfaces';
import ApiService from 'services/ApiService';
import { escapeRegexCharacters } from 'utils/escapeRegexCharacters';
import {
  Formik,
  FormikHelpers,
  formikAutocompleteAjaxProps,
  formikFieldProps,
  yup
} from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import { TFunction } from 'i18next';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dropdown,
  EButtonVariant,
  TextInput,
  Textarea,
  autocompleteHighlight,
  pushToast
} from '@ryan/components';
import { IPushToastProps } from '@ryan/components/dist/lib/components/ToastContainer/ToastEmitter';

type WorkspaceModalFormValues = {
  template: string;
  title: string;
  description: string;
  connection: IWorkspaceConnection | null;
  source: WorkspaceSource;
  originalTitle?: string;
};

const defaultValues: WorkspaceModalFormValues = {
  template: '',
  title: '',
  description: '',
  connection: null,
  source: WorkspaceSource.Blank
};

export interface IWorkspaceModalProps {
  engagementGuid: string;
  templates: IWorkspace[] | null;
  connections: IWorkspaceConnection[] | null;
  workspace?: IWorkspace;
  duplicate: boolean;
  onClose: (workspace?: IWorkspace) => void;
}

interface IWorkspaceModalState {
  loading: Promise<any> | null;
}

const descriptionMaxLength = 200;
const titleRegex = /^[a-zA-Z0-9_-]*$/; // Only alphanumeric, hyphens, and underscores
const titleMaxLength = 50;

class WorkspaceModal extends Component<
  IWorkspaceModalProps & WithTranslation,
  IWorkspaceModalState
> {
  private schema: yup.ObjectSchema<{
    title: string;
    description: string;
    connection: IWorkspaceConnection | null;
  }>;

  constructor(props: IWorkspaceModalProps & WithTranslation) {
    super(props);
    const { t } = props;

    this.schema = yup.object({
      source: yup.string(),
      template: yup.string().when('source', {
        is: WorkspaceSource.Template,
        then: yup
          .string()
          .required(t('scheduling.workspaceModal.templateRequired')),
        otherwise: yup.string()
      }),
      title: yup
        .string()
        .required(t('Title_required'))
        .max(
          titleMaxLength,
          t('scheduling.workspaceModal.titleMaxLength', {
            length: titleMaxLength
          })
        )
        .matches(titleRegex, t('scheduling.workspaceModal.titleFormat')),
      description: yup.string().max(
        descriptionMaxLength,
        t('scheduling.workspaceModal.descriptionMaxLength', {
          length: descriptionMaxLength
        })
      ),
      connection: yup
        .object<IWorkspaceConnection>()
        .required(t('scheduling.workspaceModal.databaseRequired'))
        .nullable()
    });

    this.state = {
      loading: null
    };
  }

  getInitialValues = (): WorkspaceModalFormValues => {
    const { connections, workspace, duplicate } = this.props;

    if (workspace) {
      return {
        ...defaultValues,
        title: `${workspace.title}${duplicate ? '_Copy' : ''}`,
        description: workspace.description,
        connection:
          connections?.find(connection => connection.id === workspace.id) ||
          null,
        originalTitle: workspace.title
      };
    }

    return defaultValues;
  };

  getModalTitle = (t: TFunction) => {
    const { workspace, duplicate } = this.props;

    let title: string = t('scheduling.workspaceModal.createTitle');

    if (workspace) {
      if (duplicate) {
        title = t('scheduling.workspaceModal.duplicateTitle');
      } else {
        title = t('scheduling.workspaceModal.editTitle');
      }
    }

    return title;
  };

  getPrimaryButtonText = (t: TFunction) => {
    const { workspace, duplicate } = this.props;

    let text: string = t('Create');

    if (workspace) {
      if (duplicate) {
        text = t('Duplicate');
      } else {
        text = t('Save');
      }
    }

    return text;
  };

  getTemplateOptions = (t: TFunction) => {
    const { templates } = this.props;

    return [
      {
        value: '',
        label: t('scheduling.workspaceModal.selectTemplate'),
        disabled: true
      },
      ...(templates
        ? templates.map(template => ({
            key: template.workspaceGuid,
            value: template.workspaceGuid,
            label: template.title
          }))
        : [])
    ];
  };

  handleFetchConnections = async (
    query: string
  ): Promise<IWorkspaceConnection[]> => {
    const { connections } = this.props;

    if (connections) {
      const regExp = new RegExp(escapeRegexCharacters(query), 'gi');
      return connections.filter(c => c.database && regExp.test(c.database));
    }

    return [];
  };

  handleFormError(error: any, formik: FormikHelpers<WorkspaceModalFormValues>) {
    const { t } = this.props;

    if (error.response?.status) {
      switch (error.response.status) {
        case 400:
          const errors = Array.isArray(error.response.data)
            ? (error.response.data as string[])
            : [];

          if (errors.indexOf('Duplicate Workspace Name') > -1) {
            formik.setFieldError(
              'title',
              t('scheduling.workspaceModal.uniqueTitleError')
            );
          }

          if (errors.indexOf('Invalid Connection Guid') > -1) {
            formik.setFieldError(
              'connection',
              t('scheduling.workspaceModal.invalidConnectionError')
            );
          }

          break;
        case 500:
        default:
          pushServerErrorToast();
      }
    }
  }

  handleCreate = async (
    engagementGuid: string,
    values: IWorkspaceRequest,
    formik: FormikHelpers<WorkspaceModalFormValues>,
    originalTitle?: string
  ) => {
    const { t, duplicate } = this.props;

    let successMessage: IPushToastProps = {
      type: 'success',
      title: t('scheduling.workspaceModal.createSuccessTitle'),
      content: t('scheduling.workspaceModal.createSuccessBody', {
        title: values.name
      })
    };

    if (duplicate) {
      successMessage = {
        type: 'success',
        title: t('scheduling.workspaceModal.duplicateSuccessTitle'),
        content: t('scheduling.workspaceModal.duplicateSuccessBody', {
          title: values.name,
          originalTitle
        })
      };
    }
    const promise = ApiService.createWorkspace(engagementGuid, values)
      .then(response => {
        pushToast(successMessage);
        this.props.onClose(response.data);
      })
      .catch(error => {
        this.handleFormError(error, formik);
      });

    this.setState({ loading: promise });
  };

  handleUpdate = async (
    engagementGuid: string,
    workspaceGuid: string,
    values: IWorkspaceRequest,
    formik: FormikHelpers<WorkspaceModalFormValues>
  ) => {
    const { t } = this.props;

    const promise = ApiService.updateWorkspace(
      engagementGuid,
      workspaceGuid,
      values
    )
      .then(response => {
        pushToast({
          type: 'success',
          title: t('scheduling.workspaceModal.editSuccessTitle'),
          content: t('scheduling.workspaceModal.editSuccessBody', {
            title: values.name
          })
        });
        this.props.onClose(response.data);
      })
      .catch(error => {
        this.handleFormError(error, formik);
      });

    this.setState({ loading: promise });
  };

  handleSubmit = async (
    values: WorkspaceModalFormValues,
    formik: FormikHelpers<WorkspaceModalFormValues>
  ) => {
    const { engagementGuid, workspace, duplicate } = this.props;
    const requestFromValues: IWorkspaceRequest = {
      name: values.title,
      description: values.description,
      engagementGuid,
      id: values.connection?.id || '',
      templateGuid: duplicate ? workspace?.workspaceGuid : values.template
    };

    if (workspace && !duplicate) {
      this.handleUpdate(
        engagementGuid,
        workspace.workspaceGuid,
        requestFromValues,
        formik
      );
    } else {
      this.handleCreate(
        engagementGuid,
        requestFromValues,
        formik,
        values.originalTitle
      );
    }
  };

  resetState() {
    this.setState({
      loading: null
    });
  }

  handleClose = () => {
    this.resetState();
    this.props.onClose();
  };

  renderOption = (
    connection: IWorkspaceConnection | null,
    { query }: { query: string }
  ) => {
    return (
      <div>
        <div className="connection-autocomplete__name">
          {autocompleteHighlight(connection?.database || '', query)}
        </div>
        <div className="connection-autocomplete__server">
          {connection?.server}
        </div>
      </div>
    );
  };

  render() {
    const { t, connections, workspace, duplicate } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        className="workspaceModal"
        onClose={this.handleClose}
        open
        title={this.getModalTitle(t)}
      >
        <Formik<WorkspaceModalFormValues>
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          validationSchema={this.schema}
        >
          {formik => {
            return (
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                {!(duplicate || workspace) && (
                  <div className="workspaceModal__source-picker">
                    <label
                      className="ry-label"
                      id="workspace-modal-source-label"
                    >
                      {t('scheduling.workspaceModal.source')}
                    </label>
                    <RadioButtonGroup
                      aria-labelledby="workspace-modal-source-label"
                      {...formikFieldProps('source', formik)}
                    >
                      <RadioButton
                        label={t('scheduling.workspaceModal.blank')}
                        value={WorkspaceSource.Blank}
                      />
                      <RadioButton
                        label={t('scheduling.workspaceModal.template')}
                        value={WorkspaceSource.Template}
                      />
                    </RadioButtonGroup>
                  </div>
                )}
                {formik.values.source === WorkspaceSource.Template && (
                  <Dropdown
                    {...formikFieldProps('template', formik)}
                    label={t('scheduling.workspaceModal.template')}
                    options={this.getTemplateOptions(t)}
                  />
                )}
                <TextInput
                  {...formikFieldProps('title', formik)}
                  label={t('Title')}
                />
                <Textarea
                  {...formikFieldProps('description', formik)}
                  label={t('Description (Optional)')}
                  maxLength={descriptionMaxLength}
                />
                <AutocompleteAjax<IWorkspaceConnection>
                  {...formikAutocompleteAjaxProps('connection', formik)}
                  disabled={connections === null}
                  getOptionValue={connection => connection?.database || ''}
                  label={t('Database')}
                  onFetchOptions={this.handleFetchConnections}
                  renderOption={this.renderOption}
                />
                <ButtonGroup>
                  <Button
                    disabled={workspace && !formik.dirty && !duplicate}
                    loading={loading}
                    text={this.getPrimaryButtonText(t)}
                    type="submit"
                    variant={EButtonVariant.PRIMARY}
                  />
                  <Button onClick={this.handleClose} text={t('Cancel')} />
                </ButtonGroup>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(WorkspaceModal);
