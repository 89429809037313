import { TFunction } from 'i18next';
import memoizeOne from 'memoize-one';

import { yup } from '../../../../../utils/forms';
import { SavingsCategoryModalEnums } from '../EnumsSavingsCategoryModal';

export const getSavingsCategoryNameSchema = memoizeOne(
  (textToDisplay: TFunction, existingCategoryNames: string[]) => {
    const { CATEGORY_NAME, PROPERTY_POINT_SAVINGS, ROOT_TO_TEXT } =
      SavingsCategoryModalEnums;

    return yup.object({
      [CATEGORY_NAME]: yup
        .string()
        .trim()
        .lowercase()
        .required(textToDisplay(`${ROOT_TO_TEXT}.formik.categoryNameRequired`))
        .max(75, textToDisplay(`${ROOT_TO_TEXT}.formik.categoryNameMaxLength`))
        .test(
          'isReservedPropertyPointSavings',
          textToDisplay(`${ROOT_TO_TEXT}.formik.reservedCategoryName`, {
            categoryName: PROPERTY_POINT_SAVINGS
          }),
          value => value !== PROPERTY_POINT_SAVINGS.toLocaleLowerCase()
        )
        .test(
          'isDuplicate',
          textToDisplay(`${ROOT_TO_TEXT}.formik.duplicateCategoryName`),
          value =>
            !existingCategoryNames.some(c => c.toLocaleLowerCase() === value)
        )
    });
  }
);
