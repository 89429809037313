import { RouteComponentProps, matchPath } from 'react-router-dom';

const ROUTE_BLACKLIST = [
  '/app/team/manage/newusers',
  '/app/team/manage/pendingusers',
  '/app/team/review-new-user',
  '/app/team/edit-user/',
  '/app/impersonate',
  '/app/403',
  '/app/404',
  '/app/500',
  '/app/profile',
  '/app/profile/:userGuid',
  '/app/personal-information/:userGuid',
  '/app/roles',
  '/app/usersearch',
  '/app/featuretoggles',
  '/app/custom-view/create',
  '/app/custom-view/edit/:customViewGuid',
  '/app/worklist'
];

/**
 * Determines if the matched location passed through is on the "black list"
 */
export default function isSwitcherOnRoute(
  location: RouteComponentProps['location']
) {
  return !ROUTE_BLACKLIST.some(
    url => matchPath(location.pathname, url) !== null
  );
}
