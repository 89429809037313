import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { TFunction } from 'i18next';
import { IComment } from 'interfaces';
import { getLocale } from 'utils/i18n';

export interface ICommentsGroupedByDate {
  key: string;
  label: string;
  comments: IComment[];
}

function formatDateLabel(date: Date, t: TFunction, language: string) {
  const diff = differenceInCalendarDays(new Date(), date);
  switch (diff) {
    case 0:
      return t('Today');
    case 1:
      return t('Yesterday');
    default:
      return format(date, 'EEEE, MMMM d', { locale: getLocale(language) });
  }
}

/**
 * Groups a list of comments by creation date.
 */
export default function groupCommentsByDate(
  comments: IComment[],
  t: TFunction,
  language = 'en-US'
): ICommentsGroupedByDate[] {
  const groupedComments = comments.reduce((days, comment) => {
    const date = parseISO(comment.createDate);
    const key = format(date, 'yyyyMMdd');
    if (!days[key]) {
      days[key] = {
        key,
        label: formatDateLabel(date, t, language),
        comments: []
      };
    }
    days[key].comments.push(comment);
    return days;
  }, {} as Record<string, ICommentsGroupedByDate>);

  return Object.keys(groupedComments)
    .sort()
    .map(key => groupedComments[key]);
}
