import { DirectoryItemLinkType, IDirectoryItemLink } from 'interfaces';

export default function getDirectoryItemLinkIcon(link: IDirectoryItemLink) {
  // If the GUID && name is NULL this means that the user does not have access to see it.
  if (!link.linkGuid && !link.name) {
    return 'hide';
  }

  // Continue as normal checking the type!
  switch (link.linkType) {
    case DirectoryItemLinkType.DataRequestAttachment:
    case DirectoryItemLinkType.DataRequestUpload:
    case DirectoryItemLinkType.FileUpload:
      return 'file';
    case DirectoryItemLinkType.TaskAttachment:
      return 'clipboard';
    case DirectoryItemLinkType.SavingsAttachment:
      return 'calculator';
    case DirectoryItemLinkType.LearningAttachment:
      return 'bulb';
    case DirectoryItemLinkType.ProjectLink:
      return 'link';
  }
}
