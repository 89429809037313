import { IDataRequestV2, ITask, Status } from 'interfaces';

import { differenceInDays } from 'date-fns';

/**
 * Returns true if the current date is past the due date. Currently, we only
 * care about the date value, so we will disregard the time and only treat a
 * date as past due if the date value is greater.
 *
 * @todo update this logic if we start incorporating times into due dates
 * @param dueDate The due date.
 * @param currentDate A current date to compare against the due date. Defaults
 *  to the current date if `undefined`.
 */
export function isPastDue(
  dueDate: Date,
  currentDate: Date = new Date()
): boolean {
  return differenceInDays(currentDate, dueDate) >= 1;
}

/**
 * Returns `true` if the data request is past due.
 *
 * @param dataRequest The data request to consider.
 * @param currentDate A current date to compare against the due date. Defaults
 *  to the current date if `undefined`.
 */
export function isDataRequestPastDue(
  dataRequest: IDataRequestV2,
  currentDate?: Date
): boolean {
  return (
    dataRequest.statusId !== Status.Complete &&
    isPastDue(new Date(dataRequest.dueDate), currentDate)
  );
}

/**
 * Returns `true` if the task is past due.
 *
 * @param task The task to consider.
 * @param currentDate A current date to compare against the due date. Defaults
 *  to the current date if `undefined`.
 */
export function isTaskPastDue(task: ITask, currentDate?: Date): boolean {
  return (
    task.statusId !== Status.Complete &&
    isPastDue(new Date(task.dueDate), currentDate)
  );
}
