import React, { FunctionComponent, useState } from 'react';
import { isMobileOnly, isSafari } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { withSize } from 'react-sizeme';
import { useBreadcrumbs } from 'components/Breadcrumbs';
import {
  SwitcherMobile,
  SwitcherProvider,
  isSwitcherOnRoute
} from 'components/Switcher';
import { WithUser, withUser } from 'contexts/UserContext';
import { DebugBanner } from './DebugBanner';
import ImpersonatingBanner from './ImpersonatingBanner';
import Navigation from './Navigation';
import Subnavigation from './Subnavigation';
import SystemBanners from './SystemBanners';
import './AppHeader.scss';

const AppHeaderFixed = withSize({ monitorHeight: true, monitorWidth: false })(
  (() => (
    <div className="app-header__fixed">
      <DebugBanner />
      <ImpersonatingBanner />
      <SystemBanners />
      <Navigation />
    </div>
  )) as FunctionComponent
);

const AppHeader: FunctionComponent<WithUser> = ({
  executiveAccounts,
  user
}) => {
  const [fixedHeight, setFixedHeight] = useState(80);
  const crumbs = useBreadcrumbs();
  const showBreadcrumbs = crumbs.length > 0;
  const location = useLocation();
  const hasAccounts = !!user.accountTree.length || !!executiveAccounts?.length;
  const showAccountSwitcher = hasAccounts && isSwitcherOnRoute(location);

  /**
   * Factor in height of subnavigation to fixed padding buffer if subnavigation
   * is also fixed. Currently fixing subnav on desktop Safari as the browser has
   * trouble with the `react-headroom` package.
   *
   * @see https://github.com/KyleAMathews/react-headroom/issues/195
   * @todo re-enable `react-headroom` on Safari once issue is resolved.
   */
  const [fixedSubnavHeight, setFixedSubnavHeight] = useState(0);
  const isSubnavFixed = !isMobileOnly && isSafari;
  const totalFixedHeaderHeight = fixedHeight + fixedSubnavHeight;

  return (
    <div className="app-header">
      <SwitcherProvider>
        {/**
         * This content is always fixed to the top of the page.
         * It's wrapped with react-sizeme and returns the height of the content.
         */}
        <AppHeaderFixed
          onSize={({ height }) => {
            setFixedHeight(height || 0);
          }}
        />
        {/**
         * This is dynamic padding, sized relative to the fixed content above.
         * This makes sure the main content falls below the variably-sized fixed content.
         */}
        <div
          className="app-header__fixed-space"
          style={{ height: `${totalFixedHeaderHeight}px` }}
        />
        <Subnavigation
          crumbs={crumbs}
          onSize={({ height }) => {
            if (isSubnavFixed && height !== null) {
              setFixedSubnavHeight(height);
            }
          }}
          positionFixed={isSubnavFixed}
          positionTop={fixedHeight}
          showAccountSwitcher={showAccountSwitcher}
          showBreadcrumbs={showBreadcrumbs}
        />
        {/**
         * This is some hacky stuff right here.
         * This overrides sticky Table header positioning.
         */}
        <style>
          {`
          .with-ry-global-header thead tr:first-child .ry-table__th {
            top: ${totalFixedHeaderHeight}px;
          }
          `}
        </style>
        {/**
         * This is the switcher for mobile.
         */}
        {showAccountSwitcher && <SwitcherMobile />}
      </SwitcherProvider>
    </div>
  );
};

export default withUser(AppHeader);
