import { useEffect, useRef, useState } from 'react';
import { IWorkspace } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';

export default function useWorkspaceTemplates(engagementGuid: string) {
  const sourceRef = useRef<CancelTokenSource>();
  const [templates, setTemplates] = useState<IWorkspace[] | null>(null);

  useEffect(() => {
    const fetch = async () => {
      // initialize cancel token
      sourceRef.current = ApiService.CancelToken.source();

      try {
        const response = await ApiService.getWorkspaceTemplates(
          engagementGuid,
          sourceRef.current.token
        );
        setTemplates(response.data);
      } catch {
        // ...
      }
    };

    fetch();

    return () => {
      // cancel previous request
      sourceRef.current?.cancel();
    };
  }, [engagementGuid]);

  return templates;
}
