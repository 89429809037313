import debounce from 'lodash.debounce';

type SearchQueryHandler = (searchQuery: string) => void;

function debouncedSearch(
  changeHandler: SearchQueryHandler,
  requestHandler: SearchQueryHandler
) {
  const debouncedRequestHandler = debounce(requestHandler, 250);

  return function (searchQuery: string) {
    changeHandler(searchQuery);
    debouncedRequestHandler(searchQuery);
  };
}

export default debouncedSearch;
