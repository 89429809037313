import FileDirectory, {
  FileDirectoryHeader,
  NewFolderModal
} from 'components/FileDirectory';
import FilesUploadModal from 'components/FilesUpload/FilesUploadModal';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  EngagementStatus,
  IEngagement,
  IFolder,
  ITableState
} from 'interfaces';
import qs from 'query-string';
import {
  IDirectoryState,
  fetchDirectory,
  setHash,
  updateStateFoldersAndFolderPath
} from 'routes/Project/DirectoryState';
import ApiService from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { Dropdown, IOption } from '@ryan/components';

import Engagements from './Engagements';

import './Files.scss';

const DEFAULT_SORT_ENGAGEMENTS = {
  id: 'engagementDisplayNameShort' as const,
  desc: false
};

export const DEFAULT_SORT_FILES = {
  id: 'createDate' as const,
  desc: true
};

export enum DirectoryItemStatus {
  ALL_FILES = 0,
  ACTIVE,
  ARCHIVED
}

interface IFilesProps extends WithTranslation, WithUser, RouteComponentProps {}

interface IFilesState extends IDirectoryState {
  engagement: IEngagement | null;
  engagements: IEngagement[];
  fileStateFilterGuid: string;
  newFolder: boolean;
  status: DirectoryItemStatus;
  totalArchivedFileCount: number;
  uploadFiles: boolean;
}

export class Files extends Component<IFilesProps, IFilesState> {
  private lastRequest: Promise<any> | null = null;

  // prevent navigating to account root when directly navigating to a location
  // via hash values and only navigate to root when updating via switcher
  private preventNavigateToAccount = false;

  state: IFilesState = {
    // Shared Table State
    filtered: {
      status: [`${EngagementStatus.Active}`]
    },
    loading: false,
    page: 1,
    pageSize: 10,
    searchQuery: '',
    sorted: DEFAULT_SORT_ENGAGEMENTS,
    totalCount: 0,
    // Table 1: Engagements
    engagements: [],

    // Table 2: Directory
    didDirectoryChange: false,
    directoryItems: [],
    directoryItemsBySearch: null,
    engagement: null,
    engagementFolders: null,
    fileStateFilterGuid: 'Active',
    folderPath: [],
    newFolder: false,
    selected: [],
    status: DirectoryItemStatus.ACTIVE,
    totalArchivedFileCount: 0,
    totalFileCount: 0,
    uploadFiles: false
  };

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: IFilesProps) {
    const didViewChange =
      prevProps.activeView.customViewGuid !==
      this.props.activeView.customViewGuid;

    const prevHash = qs.parse(prevProps.location.hash);
    const hash = qs.parse(this.props.location.hash);
    const didHashChange =
      hash.engagementGuid !== prevHash.engagementGuid ||
      hash.folderGuid !== prevHash.folderGuid;

    // If the view changed AND the hash is not clear, then navigating to the root
    // (clearing the hash) will trigger componentDidUpdate again for this.update()
    if (
      !this.preventNavigateToAccount &&
      didViewChange &&
      (hash.engagementGuid !== undefined || hash.folderGuid !== undefined)
    ) {
      this.handleNavigateToAccount();
    } else if (didViewChange || didHashChange) {
      this.setState(
        {
          didDirectoryChange: true
        },
        this.update
      );
    }
  }

  async update(updates?: Partial<IFilesState>) {
    const { location, setActiveAccountForEngagement } = this.props;
    const hash = qs.parse(location.hash);
    const state = { ...this.state, ...updates };
    let request: Promise<Partial<IFilesState>>;

    // URL is source of truth for selected engagement and selected folder!
    try {
      // Do we have a selected engagement?
      if (typeof hash.engagementGuid === 'string') {
        // If the stored engagement is not the same as the selected engagement
        // from the URL, then reset the state and load the engagement.
        // 1. May be mounting with uninitialized state.
        // 2. May have just selected an engagement.
        if (state.engagement?.engagementGuid !== hash.engagementGuid) {
          state.engagement = null;
          state.engagementFolders = null;
          state.folderPath = [];
          state.sorted = DEFAULT_SORT_FILES;
          state.searchQuery = '';
          state.page = 1;
          state.directoryItems = [];
          state.directoryItemsBySearch = null;
          state.totalCount = 0;
          state.totalFileCount = 0;

          const engagement = state.engagements.find(
            e => e.engagementGuid === hash.engagementGuid
          );

          if (engagement) {
            state.engagement = engagement;
          } else {
            const resp = await ApiService.getEngagement(hash.engagementGuid);
            state.engagement = resp.data;

            // If the engagement is not under the active view,
            // then update the switcher.
            setActiveAccountForEngagement(state.engagement);

            // Navigating to an engagement in different account will trigger
            // navigation back to root since `setActiveAccountForEngagement`
            // creates a new account-based view, triggers a view change, and
            // triggers the navigation back to account roow in
            // `componentDidUpdate`. Temporarily pause this functionality when
            // navigating to a new account via hash.
            this.preventNavigateToAccount = true;

            /**
             * @todo Pretty smelly but couldn't come up with a better way to
             * re-enable this that supported all scenarios :(. Update
             * this once a better answer comes along.
             */
            setTimeout(() => {
              this.preventNavigateToAccount = false;
            }, 2000);
          }
        }

        // Guarantee engagementFolders.
        // If folderGuid valid, set folderPath, otherwise, clear folderGuid.
        const isValid = await updateStateFoldersAndFolderPath(
          hash.engagementGuid,
          hash.folderGuid,
          state
        );

        if (!isValid) {
          this.setState(state, this.handleNavigateToRoot);
          return;
        }

        request = fetchDirectory(hash.engagementGuid, {
          ...state,
          engagementFolders: state.engagementFolders!
        });
      } else {
        // No selected engagement, so reset the state.
        // 1. May be mounting with uninitialized state (superfluous).
        // 2. May have just navigated to view.

        // If just navigated to view, then we were navigating files,
        // and we should reset for view change.
        if (state.engagement !== null) {
          state.sorted = DEFAULT_SORT_ENGAGEMENTS;
          state.searchQuery = '';
          state.page = 1;
          state.directoryItems = [];
          state.directoryItemsBySearch = null;
          state.totalCount = 0;
          state.totalFileCount = 0;
        }

        state.engagement = null;
        state.engagementFolders = null;
        state.folderPath = [];

        const statusFromFileStateFilterGuid = (
          DirectoryItemStatus as { [key: string]: any }
        )[state.fileStateFilterGuid.replace(' ', '_').toUpperCase()];

        state.status =
          statusFromFileStateFilterGuid != null
            ? statusFromFileStateFilterGuid
            : DirectoryItemStatus.ACTIVE;

        request = this.fetchEngagements(state);
      }

      // Store reference to last request.
      // Save state changes with loading: true.
      this.lastRequest = request;
      this.setState({ ...state, loading: true });

      // Await response!
      // Save state wth response.
      const respState = await request;
      if (request === this.lastRequest) {
        this.setState(state => ({
          ...state,
          ...respState,
          loading: false,
          selected: [],

          // reset directory change status on completion of request
          didDirectoryChange: false
        }));
      }
    } catch {
      pushServerErrorToast();
      this.setState({ loading: false });
    }
  }

  async fetchEngagements(
    state: Readonly<IFilesState>
  ): Promise<Partial<IFilesState>> {
    const { user, activeView } = this.props;
    const { filtered } = state;

    const { data } = await ApiService.getEngagementsForCustomViewByUser(
      activeView.customViewGuid,
      user.profile.userGuid,
      {
        isActive: filtered!.status.length === 1 ? filtered!.status[0] : '',
        searchTerm: state.searchQuery,
        sort: getSortParam(state.sorted),
        pageNumber: state.page,
        itemsPerPage: state.pageSize
      }
    );

    return {
      engagements: data.results,
      totalCount: data.totalResults
    };
  }

  /**
   * Create a new folder.
   */

  handleFolderCreate = () => {
    this.setState({ newFolder: true });
  };

  handleFolderCreateClose = (folder?: IFolder) => {
    if (folder) {
      this.update({ newFolder: false, engagementFolders: null });
    } else {
      this.setState({ newFolder: false });
    }
  };

  /**
   * Upload Files (Ryan user on client's behalf)
   */

  handleUploadFiles = () => {
    if (this.state.engagement) {
      this.setState({ uploadFiles: true });
    }
  };

  handleUploadFilesClose = (uploadedFiles: boolean) => {
    if (uploadedFiles) {
      this.update({ uploadFiles: false, engagementFolders: null });
    } else {
      this.setState({ uploadFiles: false });
    }
  };

  /**
   * Engagement was selected from the view's engagements,
   * or selected from breadcrumbs.
   */
  handleEngagementSelected = (engagement: IEngagement) => {
    setHash({
      engagementGuid: engagement.engagementGuid,
      folderGuid: undefined
    });
  };

  /**
   * User has selected a view.
   * View/Account > Engagement (Root) > Folder > Folder...
   */
  handleNavigateToAccount = () => {
    setHash({
      engagementGuid: undefined,
      folderGuid: undefined
    });
  };

  /**
   * User has selected the root folder for an engagement.
   * View/Account > Engagement (Root) > Folder > Folder...
   */
  handleNavigateToRoot = () => {
    const { location } = this.props;
    const hash = qs.parse(location.hash);
    setHash({
      engagementGuid: hash.engagementGuid as string | undefined,
      folderGuid: undefined
    });
  };

  /**
   * User has clicked a folder from the Directory.
   * View/Account > Engagement (Root) > Folder > Folder...
   */
  handleNavigateToFolder = (folderGuid: string) => {
    const { location } = this.props;
    const hash = qs.parse(location.hash);
    setHash({
      engagementGuid: hash.engagementGuid as string,
      folderGuid
    });

    // reset search query to clear search results if navigating to new folder
    // via search
    this.setState({
      searchQuery: ''
    });
  };

  /**
   * Table Query
   */

  handleSort = (sorted: any) => {
    this.update({ sorted });
  };

  handleFilter = (filtered: ITableState['filtered']) => {
    this.update({ filtered, page: 1 });
  };

  handlePage = (page: number, pageSize: number) => {
    this.update({ page, pageSize });
  };

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.update({ page: 1, searchQuery });
    }
  );

  handleSelection = (selected: string[]) => {
    this.setState({ selected });
  };

  renderFilterAction = () => {
    const { permissionService, t } = this.props;

    if (permissionService.isThirdParty()) return null;

    const filterValue = this.state.fileStateFilterGuid;

    return (
      <div className="file-filter-dropdown">
        <Dropdown
          name="filterByStatus"
          onChange={e => {
            this.onFilterChange(e.target.value as IOption);
          }}
          options={['Active', 'All Files'].map(value => ({
            label: t(value),
            value
          }))}
          value={filterValue}
        />
      </div>
    );
  };

  onFilterChange = (event: IOption) => {
    this.update({
      fileStateFilterGuid: event as string,
      status: (DirectoryItemStatus as { [key: string]: any })[
        (event as string).replace(' ', '_').toUpperCase()
      ]
    });
  };

  render() {
    const { t, activeView } = this.props;
    const {
      directoryItems,
      directoryItemsBySearch,
      engagement,
      engagementFolders,
      engagements,
      filtered,
      folderPath,
      loading,
      newFolder,
      page,
      pageSize,
      searchQuery,
      selected,
      sorted,
      status,
      totalArchivedFileCount,
      totalCount,
      totalFileCount,
      uploadFiles
    } = this.state;
    const totalFileCountForDirectory =
      status === DirectoryItemStatus.ALL_FILES
        ? totalFileCount
        : totalFileCount - totalArchivedFileCount;

    return (
      <div className="files-tab">
        <FileDirectoryHeader
          engagement={engagement}
          folderPath={folderPath}
          isTransferredFilesView={false}
          loading={loading}
          onDataRequests={false}
          onFolderCreate={this.handleFolderCreate}
          onNavigateToAccount={this.handleNavigateToAccount}
          onNavigateToFolder={this.handleNavigateToFolder}
          onNavigateToRoot={this.handleNavigateToRoot}
          onSearch={this.handleSearch}
          onUploadFile={this.handleUploadFiles}
          renderAction={this.renderFilterAction}
          searchQuery={searchQuery}
          t={t}
          title={t('Uploaded Files')}
          view={activeView}
        />

        {!engagement ? (
          <Engagements
            engagements={engagements}
            filtered={filtered}
            loading={loading}
            onEngagementSelected={this.handleEngagementSelected}
            onFilter={this.handleFilter}
            onPageChange={this.handlePage}
            onSortChange={this.handleSort}
            page={page}
            pageSize={pageSize}
            searchQuery={searchQuery}
            sorted={sorted}
            totalCount={totalCount}
          />
        ) : (
          <>
            <FileDirectory
              directoryItems={directoryItems}
              directoryItemsBySearch={directoryItemsBySearch}
              engagement={engagement}
              engagementFolders={engagementFolders}
              fileStatusFilter={status}
              filtered={filtered}
              folderPath={folderPath}
              loading={loading}
              onFilter={this.handleFilter}
              onNavigateToFolder={this.handleNavigateToFolder}
              onPage={this.handlePage}
              onRefresh={() => {
                this.update({ engagementFolders: null }).then(() => {
                  const { directoryItems, page, pageSize } = this.state;

                  // decrement current page if last item on a page greater than
                  // 1 has been deleted
                  if (directoryItems.length === 0 && page > 1) {
                    this.handlePage(page - 1, pageSize);
                  }
                });
              }}
              onSelection={this.handleSelection}
              onSort={this.handleSort}
              page={page}
              pageSize={pageSize}
              searchQuery={searchQuery}
              selected={selected!}
              showFileTooltips={false}
              sorted={sorted}
              t={t}
              toggleLoadingState={(status: boolean) =>
                this.setState({ loading: status })
              }
              totalCount={totalCount}
              totalFileCount={
                totalFileCountForDirectory > 0 ? totalFileCountForDirectory : 0
              }
            />

            <FilesUploadModal
              defaultFolder={folderPath[folderPath.length - 1] || null}
              engagement={engagement}
              onClose={this.handleUploadFilesClose}
              open={uploadFiles}
            />

            {newFolder && (
              <NewFolderModal
                defaultFolder={folderPath[folderPath.length - 1] || null}
                engagement={engagement}
                folders={engagementFolders}
                onClose={this.handleFolderCreateClose}
                rootName={engagement.engagementDisplayNameShort}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(withUser(Files));
