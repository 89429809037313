import Modal from 'components/Modal';
import { IFile } from 'interfaces';
import { Formik, formikFieldProps, yup } from 'utils/forms';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  TextInput
} from '@ryan/components';

export interface IFileRenameModalProps extends WithTranslation {
  file: IFile | null;
  loading: Promise<any> | null;
  onSubmit: (friendlyName: string) => void;
  onCancel: () => void;
}

const maxLengthName = 255;

class FileRenameModal extends Component<IFileRenameModalProps> {
  private schema = yup.object({
    friendlyName: yup
      .string()
      .max(
        maxLengthName,
        this.props.t('file.renameModal.fileName.maxLength', {
          length: maxLengthName
        })
      )
      .required(this.props.t('file.renameModal.fileName.required'))
  });

  handleSubmit = (values: { friendlyName: string }) => {
    const { onSubmit } = this.props;
    const { friendlyName } = values;
    onSubmit(friendlyName);
  };

  render() {
    const { t, file, loading, onCancel } = this.props;

    if (file) {
      return (
        <Modal onClose={onCancel} open title={t('file.renameModal.title')}>
          <Formik
            initialValues={{ friendlyName: file.displayName }}
            onSubmit={this.handleSubmit}
            validationSchema={this.schema}
          >
            {formik => (
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <TextInput
                  {...formikFieldProps('friendlyName', formik)}
                  helperText={t('file.renameModal.fileName.helperText', {
                    originalName: file.documentName
                  })}
                  label={t('file.renameModal.fileName.label')}
                />
                <ButtonGroup>
                  <Button
                    loading={loading}
                    text={t('Save')}
                    type="submit"
                    variant={EButtonVariant.PRIMARY}
                  />
                  <Button
                    disabled={loading !== null}
                    onClick={onCancel}
                    text={t('Cancel')}
                    variant={EButtonVariant.SECONDARY}
                  />
                </ButtonGroup>
              </form>
            )}
          </Formik>
        </Modal>
      );
    }

    return null;
  }
}

export default withTranslation()(FileRenameModal);
