import { withContext } from 'utils/withContext';
import {
  IMilestoneDrawerContext,
  MilestoneDrawerContext
} from './MilestoneDrawerContext';

export const MilestoneDrawerConsumer = MilestoneDrawerContext.Consumer;

export type WithMilestoneDrawer = IMilestoneDrawerContext;
export const withMilestoneDrawer = withContext(
  MilestoneDrawerContext,
  'withMilestoneDrawer'
);
