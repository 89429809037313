import { IFormatEngagementUserToProjectSnapshotOverview } from '../IProjectSnapshotOverview';

export const formatEngagementUserToProjectSnapshotOverview: IFormatEngagementUserToProjectSnapshotOverview =
  engagementUser => {
    return engagementUser
      ? {
          avatarUrl: engagementUser.avatarUrl || '',
          firstName: engagementUser.firstName || '',
          fullName: engagementUser.fullName || '',
          hasDxpAccess: engagementUser.hasDxpAccess || false,
          lastName: engagementUser.lastName || '',
          userAvatarDocumentGuid: engagementUser.userAvatarDocumentGuid || '',
          userGuid: engagementUser.userGuid
        }
      : null;
  };
