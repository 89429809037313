import Modal from 'components/Modal';
import { IButtonLoadingState } from 'interfaces';

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { IHelpModalProps } from '../../interfaces/IHelpModalProps';
import HelpModalForm from '../../routes/HelpModalForm/HelpModalForm';

class HelpModal extends Component<IHelpModalProps, IButtonLoadingState> {
  render() {
    const { t, open, onClose } = this.props;
    return (
      <Modal onClose={onClose} open={open} title={t('helpModal.title')}>
        <HelpModalForm {...this.props} />
      </Modal>
    );
  }
}

export default withTranslation()(HelpModal);
