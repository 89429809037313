import SwitchWithFragments from 'components/SwitchWithFragments/SwitchWithFragments';
import PathHistoryContext, {
  IPathHistoryContext
} from 'contexts/PathHistoryContext/PathHistoryContext';
import { useUser } from 'contexts/UserContext';
import { Permission } from 'interfaces';
// routes
import AllRecentActivity from 'routes/AllRecentActivity/AllRecentActivity';
import CustomView from 'routes/CustomView';
import Dashboard from 'routes/Dashboard/Dashboard';
import DataAndFiles from 'routes/DataAndFiles/DataAndFiles';
import DataRequest from 'routes/DataRequest/DataRequest';
import EditTeam from 'routes/EditTeam/EditTeam';
import EditUser from 'routes/EditUser/EditUser';
import Error403 from 'routes/ErrorPages/Error403';
import Error404 from 'routes/ErrorPages/Error404';
import Error500 from 'routes/ErrorPages/Error500';
import Impersonate from 'routes/Impersonate/Impersonate';
import ManageTeam from 'routes/ManageTeam/ManageTeam';
import ManageTeamRoles from 'routes/ManageTeamRoles/ManageTeamRoles';
import ManageTeamSearch from 'routes/ManageTeamSearch/ManageTeamSearch';
import PersonalInformationPage from 'routes/Profile/PersonalInformationPage';
import ProfilePage from 'routes/Profile/ProfilePage';
import Project from 'routes/Project/Project';
import Projects from 'routes/Projects/Projects';
import Team from 'routes/Team/Team';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, useHistory } from 'react-router-dom';

import { Icon } from '@ryan/components';

import SavingsSummary from '../../components/SavingsSummary/SavingsSummary';
import UpdateUserAccess from '../../components/UpdateUserAccess/UpdateUserAccess';
import FileUploadEventWrapper from '../../routes/FileUploadEventWrapper/FileUploadEventWrapper';
import HelpModalWrapper from '../../routes/HelpModalWrapper/HelpModalWrapper';
import Worklist from '../../routes/Worklist/Worklist';

/**
 * A router for the main app pages. Requires user-authentication.
 */
const AppRouter: React.FC = () => {
  const [pathHistory, setPathHistory] = useState<string[]>([]);
  const { t: getTextToDisplay } = useTranslation();
  const {
    executiveAccounts,
    isAppReadOnly,
    permissionService: ps,
    user: { accountTree }
  } = useUser();

  // check if user has executive accounts if they have no personal accounts
  const hasAccounts: boolean =
    !!accountTree.length || !!executiveAccounts?.length;

  const history = useHistory();

  const addPathToHistory = (path: string) => {
    setPathHistory(prevHistory => [...prevHistory, path]);
  };

  const context: IPathHistoryContext = {
    addPath: addPathToHistory,
    pathHistory: pathHistory
  };

  useEffect(() => {
    history.listen(location => {
      setPathHistory(prevHistory => [...prevHistory, location.pathname]);
    });
  }, [history]);

  return (
    <PathHistoryContext.Provider value={context}>
      <SwitchWithFragments>
        <Route component={FileUploadEventWrapper} path="/app/adhoc-uploads" />
        <Route component={HelpModalWrapper} path="/app/adhoc-help-page" />
        <Route
          path="/app/worklist"
          render={() =>
            ps.isThirdParty() ? <Redirect to="/app/403" /> : <Worklist />
          }
        />
        {/**
         * Profile and Personal Information Page are both available regardless
         * of whether the user has accounts.
         */}
        <Route component={ProfilePage} path="/app/profile/:userGuid?" />
        <Route
          component={PersonalInformationPage}
          path="/app/personal-information/:userGuid"
        />

        {/**
         * Impersonate
         */}
        <Route
          path="/app/impersonate"
          render={() =>
            ps.hasPermission(Permission.Impersonate) ? (
              <Impersonate />
            ) : (
              <Redirect to="/app/403" />
            )
          }
        />

        {/**
         * Super Admin
         */}
        <Route
          path="/app/roles"
          render={props =>
            ps.isSuperAdmin() ? (
              <ManageTeamRoles {...props} />
            ) : (
              <Redirect to="/app/403" />
            )
          }
        />

        <Route
          path="/app/usersearch"
          render={props =>
            ps.isSuperAdmin() ? (
              <ManageTeamSearch {...props} />
            ) : (
              <Redirect to="/app/403" />
            )
          }
        />

        {/**
         * Edit User (guarded internally)
         * Note: Render route independent of whether user has accounts or not,
         * but do not support in read-only mode
         */}
        <Route
          path="/app/team/edit-user/:userGuid"
          render={props => <EditUser {...props} />}
        />

        {/**
         * If user has no accounts, show a message.
         */}
        {!hasAccounts && (
          <Route
            render={() => (
              <div className="app-layout__empty">
                <div className="app-layout__empty--content">
                  <Icon name="office" />
                  <p>{getTextToDisplay('appLayout.empty.view')}</p>
                  <p>
                    {ps.isRyan()
                      ? getTextToDisplay('appLayout.empty.ryan')
                      : getTextToDisplay('appLayout.empty.client')}
                  </p>
                </div>
              </div>
            )}
          />
        )}

        {/**
         * These routes are hidden if user does not have any accounts.
         */}
        {hasAccounts && (
          <>
            {/**
             * Custom Views
             */}
            <Route component={CustomView} path="/app/custom-view" />

            {/**
             * Projects
             */}
            <Route
              path="/app/projects/all-activity"
              render={() =>
                ps.hasPermission(Permission.ActivityRead) ? (
                  <AllRecentActivity />
                ) : (
                  <Redirect to="/app/403" />
                )
              }
            />
            <Route component={Projects} path="/app/projects" />

            {/**
             * Project
             */}
            <Route
              path="/app/project/:engagementGuid/team/edit"
              render={props =>
                ps.isBig4OrExecutiveAssistant() && !isAppReadOnly ? (
                  <EditTeam {...props} />
                ) : (
                  <Redirect to="/app/403" />
                )
              }
            />
            <Route
              path="/app/project/:engagementGuid/all-activity"
              render={() =>
                ps.hasPermission(Permission.ActivityRead) ? (
                  <AllRecentActivity />
                ) : (
                  <Redirect to="/app/403" />
                )
              }
            />
            <Route
              path="/app/project/:engagementGuid/savings-summary-entry/:action"
              render={props =>
                ps.hasPermission(Permission.SavingsSummaryEdit) &&
                !isAppReadOnly ? (
                  <SavingsSummary />
                ) : (
                  <Redirect to="/app/403" />
                )
              }
            />
            <Route component={Project} path="/app/project/:engagementGuid" />

            {/**
             * Data And Files
             */}
            <Route
              path="/app/data-and-files/all-activity"
              render={() =>
                ps.hasPermission(Permission.ActivityRead) ? (
                  <AllRecentActivity />
                ) : (
                  <Redirect to="/app/403" />
                )
              }
            />
            <Route component={DataAndFiles} path="/app/data-and-files" />

            {/**
             * Data Request
             */}
            <Route
              path="/app/data-request/:queueItemGuid"
              render={() =>
                ps.hasPermission(Permission.DataRequestsView) ? (
                  <DataRequest />
                ) : (
                  <Redirect to="/app/403" />
                )
              }
            />

            {/**
             * Team
             */}
            <Route
              path="/app/team/review-new-user/:userRequestGuid?"
              render={props => <UpdateUserAccess {...props} />}
            />
            <Route
              path="/app/team/manage"
              render={() =>
                ps.canViewManageTeams() ? (
                  <ManageTeam />
                ) : (
                  <Redirect to="/app/403" />
                )
              }
            />
            <Route component={Team} path="/app/team" />

            {/**
             * Errors
             */}
            <Route component={Error403} path="/app/403" />
            <Route component={Error404} path="/app/404" />
            <Route component={Error500} path="/app/500" />

            {/**
             * Dashboard
             */}
            <Route component={Dashboard} exact path="/app" />
            <Redirect to="/app/404" />
          </>
        )}
      </SwitchWithFragments>
    </PathHistoryContext.Provider>
  );
};

export default AppRouter;
