import Modal from 'components/Modal';
import { ICustomViewSummary } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  pushToast
} from '@ryan/components';

interface CustomViewDeleteModalProps {
  customView: ICustomViewSummary;
  open?: boolean;
  onClose?: (wasDeleted?: boolean) => void;
}

const CustomViewDeleteModal: React.FC<CustomViewDeleteModalProps> = ({
  onClose,
  open = false,
  customView
}) => {
  const { t } = useTranslation();
  const [submitPromise, setSubmitPromise] = useState<Promise<any> | null>(null);

  const handleDelete: React.MouseEventHandler = async () => {
    let wasDeleted = false;

    try {
      const responsePromise = ApiService.deleteView(customView.customViewGuid);
      setSubmitPromise(responsePromise);
      await responsePromise;
      wasDeleted = true;
      pushToast({
        type: 'success',
        title: t('customView.deleteModal.success.title', {
          name: customView.name
        })
      });
    } catch (error) {
      pushServerErrorToast();
    } finally {
      setSubmitPromise(null);
      if (onClose) onClose(wasDeleted);
    }
  };

  return (
    <Modal
      className="switcher-custom-views__delete-modal"
      open={open}
      title={t('customView.deleteModal.title', { name: customView.name })}
      {...(onClose ? { onClose: () => onClose() } : {})}
    >
      <p>{t('customView.deleteModal.content')}</p>
      <ButtonGroup>
        <Button
          loading={submitPromise}
          negative
          onClick={handleDelete}
          text={t('Yes, Delete')}
        />
        <Button
          disabled={Boolean(submitPromise)}
          text={t('No, Cancel')}
          variant={EButtonVariant.SECONDARY}
          {...(onClose ? { onClick: () => onClose() } : {})}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default CustomViewDeleteModal;
