import { subMonths } from 'date-fns';

import SavingsFilterType from './SavingsFilterType';

const KEY = 'SAVINGS_SUMMARY_CARD_DATE_RANGE';
const getStamp = () => new Date().toDateString();

/**
 * Get the stored
 */
export function getDateRange(): [Date | null, Date | null, SavingsFilterType] {
  if ('localStorage' in window) {
    const json = window.localStorage.getItem(KEY);
    if (json) {
      try {
        const { stamp, startDate, endDate, filterType } = JSON.parse(json);
        if (stamp === getStamp()) {
          return [
            startDate ? new Date(startDate) : null,
            endDate ? new Date(endDate) : null,
            filterType
          ];
        }
      } catch (error) {
        // swallow and fall to defaults
      }
    }
  }

  // Default to last month.
  const today = new Date();
  return [subMonths(today, 1), today, SavingsFilterType.AsOf];
}

export function setDateRange(
  startDate: Date | null,
  endDate: Date | null,
  filterType: SavingsFilterType
) {
  if ('localStorage' in window) {
    window.localStorage.setItem(
      KEY,
      JSON.stringify({ stamp: getStamp(), startDate, endDate, filterType })
    );
  }
}
