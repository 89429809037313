import React, { FunctionComponent } from 'react';
import './CommentSkeleton.scss';

const CommentSkeleton: FunctionComponent = () => (
  <div className="comment comment-skeleton">
    <div className="ry-skeleton sk-avatar" />
    <div className="comment__content">
      <div className="comment__meta">
        <span className="ry-skeleton" /> <span className="ry-skeleton" />
      </div>
      <div className="ry-skeleton" />
    </div>
  </div>
);

export default CommentSkeleton;
