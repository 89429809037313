import { Comments, NewComment } from 'components/Comments';
import Drawer from 'components/Drawer';
import { IComment, IEngagement, ILearning } from 'interfaces';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { MentionsValue } from '@ryan/components';

interface ILearningCommentsDrawerProps extends WithTranslation {
  t: TFunction;
  engagement: IEngagement | null;
  learning: ILearning | null;

  comments: IComment[] | null;
  commentsLoading: boolean;

  canComment: boolean;

  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;
  onEditComment: (comment: IComment) => void;
  onEditCommentChange: (commentText: MentionsValue) => void;
  onEditCommentSave: () => void;
  onEditCommentCancel: () => void;

  onDeleteComment: (deleteComment: IComment) => void;

  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
  onNewCommentChange: (commentText: MentionsValue) => void;
  onNewCommentSave: () => void;

  onClose: () => void;
}

export const LearningCommentsDrawer: FunctionComponent<ILearningCommentsDrawerProps> =
  props => {
    const {
      t,
      engagement,
      learning,
      comments,
      commentsLoading,

      canComment,

      editComment,
      editCommentText,
      editCommentLoading,
      onEditComment,
      onEditCommentChange,
      onEditCommentSave,
      onEditCommentCancel,

      onDeleteComment,

      newCommentText,
      newCommentLoading,
      onNewCommentChange,
      onNewCommentSave,

      onClose
    } = props;

    if (learning) {
      return (
        <Drawer
          className="data-request-drawer"
          onClose={onClose}
          open={learning !== undefined}
        >
          <Drawer.Header>
            {engagement && (
              // @todo skeleton
              <h4 className="ry-h4">{engagement.engagementDisplayNameShort}</h4>
            )}

            <h3 className="ry-h3">{learning.title}</h3>
          </Drawer.Header>
          <Drawer.Body>
            <Comments
              canComment={canComment}
              comments={comments}
              editComment={editComment}
              editCommentLoading={editCommentLoading}
              editCommentText={editCommentText}
              engagementGuid={learning.engagementGuid}
              loading={commentsLoading}
              onDelete={onDeleteComment}
              onEdit={onEditComment}
              onEditCancel={onEditCommentCancel}
              onEditChange={onEditCommentChange}
              onEditSave={onEditCommentSave}
            />
          </Drawer.Body>
          {canComment && (
            <Drawer.Footer>
              <NewComment
                commentText={newCommentText}
                engagementGuid={learning.engagementGuid}
                loading={newCommentLoading}
                onChange={onNewCommentChange}
                onSave={onNewCommentSave}
                t={t}
              />
            </Drawer.Footer>
          )}
        </Drawer>
      );
    }

    return null;
  };

export default withTranslation()(LearningCommentsDrawer);
