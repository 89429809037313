import React, { FunctionComponent } from 'react';

interface ToggleButtonProps {
  buttonText: string;
  disabled?: boolean;
  isSelected: boolean;
  onClick: () => void;
}

const FilterToggleButton: FunctionComponent<ToggleButtonProps> = ({
  buttonText,
  disabled,
  isSelected,
  onClick
}) => {
  return (
    <button
      className={`link-filter-button ${isSelected ? 'selected' : ''}`}
      disabled={disabled}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {buttonText}
    </button>
  );
};

export default FilterToggleButton;
