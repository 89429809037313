import React from 'react';

import * as WorklistMineInterfaces from '../../../../../routes/Worklist/WorklistMine/WorklistMine.interfaces';
import { ApiFailSafeEnums } from '../../../../../utils/enums/ApiFailSafeEnums';
import { formatDate } from '../../../../../utils/formatDate';
import * as WorklistUtils from '../Worklist.utils';
import * as DataRequestExpandedRowInterfaces from './DataRequestExpandedRow.interfaces';
import DataRequestOverview from './DataRequestOverview/DataRequestOverview';
import DataRequestStatus from './DataRequestStatus/DataRequestStatus';

import './DataRequestExpandedRow.scss';

export const mapToDataRequestExpandedRow: DataRequestExpandedRowInterfaces.IMapToDataRequestExpandedRow =
  ({
    assignedTo,
    attachments,
    commentCount,
    createdBy,
    createdDate,
    description,
    dueDate,
    engagementDisplayNameShort,
    engagementGuid,
    followerCount,
    isEngagementReadOnly,
    isFollowing,
    queueItemGuid,
    statusId,
    title,
    ...rest
  }) => {
    const {
      dateRanges,
      dataSpecs,
      documentTypes,
      engagementDisplayNameLong,
      erpSystem,
      isMarkedAsDataDelivered,
      jurisdictions,
      subStatusId,
      subTypeName,
      otherDocumentType,
      otherErpSystem,
      otherTaxEngine,
      propertyTaxDocumentTypes,
      propertyTaxSiteClasses,
      taxEngine,
      transferredFileCount,
      typeGuid
    } = rest as WorklistMineInterfaces.TWorklistDataRequestItem;

    // TODO: Refactored to use dataType instead of subTypeName. subTypeName to be retired.
    // TODO: completedData is not configured on worklist yet and trying to formatDate on undefined will throw error
    const { completedDate = ApiFailSafeEnums.DATE, dataType } = rest as any;

    return {
      engagementGuid,
      overview: {
        attachments,
        commentCount,
        dataSpecs,
        description,
        detailsByType: {
          dateRanges: dateRanges.map(({ dateEnd, dateStart }) =>
            dateEnd === dateStart
              ? `${formatDate(dateStart)}`
              : `${formatDate(dateStart)} - ${formatDate(dateEnd)}`
          ),
          documentTypes: documentTypes.map(({ name }) => name),
          jurisdictions: jurisdictions.map(
            ({ jurisdictionGuid }) => jurisdictionGuid
          ),
          typeGuid,
          erpSystem: erpSystem,
          otherErpSystem: otherErpSystem,
          taxEngine: taxEngine,
          otherTaxEngine: otherTaxEngine,
          otherDocumentType: otherDocumentType,
          propertyTaxDocumentTypes: propertyTaxDocumentTypes,
          propertyTaxSiteClasses: propertyTaxSiteClasses
        },
        engagementDisplayNameLong,
        engagementDisplayNameShort,
        isEngagementReadOnly,
        subTypeName: typeof subTypeName === 'string' ? subTypeName : dataType,
        title
      },
      queueItemGuid,
      status: {
        assignedToDetails: {
          name: assignedTo.name,
          userGuid: assignedTo.userGuid
        },
        completedDate: formatDate(completedDate),
        createdByDetails: {
          name: createdBy.name,
          userGuid: createdBy.userGuid
        },
        dueDate: formatDate(dueDate),
        followerCount,
        isFollowing,
        requestedDate: formatDate(createdDate),
        statusId,
        subStatusId: (isMarkedAsDataDelivered && subStatusId) || null,
        transferredFileCount
      }
    };
  };

const DataRequestExpandedRow = ({
  dataRequestDetails,
  isDisabled,
  onOpenDataRequestCommentDrawer,
  onOpenDataRequestHistoryDrawer,
  onOpenFollowersModal,
  setIsLoading,
  setIsUpdatingFollowStatus
}: DataRequestExpandedRowInterfaces.TDataRequestExpandedRowProps) => (
  <div className="data-request-expanded-row">
    <DataRequestOverview
      dataRequestDetails={{
        ...dataRequestDetails.overview,
        engagementGuid: dataRequestDetails.engagementGuid
      }}
      isDisabled={isDisabled}
      onOpenDataRequestCommentDrawer={onOpenDataRequestCommentDrawer}
      onOpenDataRequestHistoryDrawer={onOpenDataRequestHistoryDrawer}
    />

    <DataRequestStatus
      dataRequestDetails={dataRequestDetails.status}
      isDisabled={isDisabled}
      onOpenFollowersModal={onOpenFollowersModal}
      onToggleFollow={() => {
        WorklistUtils.handleToggleFollow({
          engagementGuid: dataRequestDetails.engagementGuid,
          isFollowing: dataRequestDetails.status.isFollowing,
          queueItemGuid: dataRequestDetails.queueItemGuid,
          setIsLoading,
          setIsUpdatingFollowStatus
        });
      }}
    />
  </div>
);

export default DataRequestExpandedRow;
