/**
 * Creates an array of {count} items, where each item
 * is the result of the {of} function.
 *
 * @example
 * // returns
 * // [ <div key={0}>Repeat</div> ,
 * //   <div key={1}>Repeat</div> ,
 * //   <div key={2}>Repeat</div> ]
 * numberOf(3, i => (
 *   <div key={i}>
 *     Repeat
 *   </div>
 * ));
 */
export function numberOf(
  count: number,
  of: (i: number) => React.ReactNode
): React.ReactNode[] {
  const nodes = [];

  for (let i = 0; i < count; i++) {
    nodes.push(of(i));
  }

  return nodes;
}
