import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Icon } from '@ryan/components';
import './Empty.scss';

export interface IEmptyProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: string;
}

const Empty: FunctionComponent<IEmptyProps> = ({
  icon = 'null',
  children,
  className,
  ...rest
}) => (
  <div className={classNames('empty', className)} {...rest}>
    <Icon className="empty__icon" name={icon} />
    <div className="empty__content">{children}</div>
  </div>
);

export default Empty;
