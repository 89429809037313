import { ActivityType, IInvoiceSummary } from 'interfaces';

import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';

export const InvoiceActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    activity,
    isNotification,
    showAccountName,
    showEngagementName,
    onCloseNotificationDrawer
  } = props;
  const { accountGuid, engagementGuid } = activity;
  const { invoiceNumber } = activity.snapShot.entitySummary as IInvoiceSummary;

  switch (activity.activityTypeId) {
    case ActivityType.InvoiceAvailable:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.invoice.viewInvoice')}
          icon="invoice-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.invoice.available.title')}
          to={`/app/data-and-files/invoices${
            accountGuid ? `?account=${accountGuid}` : ''
          }`}
        >
          <Trans i18nKey="activity.invoice.available.content">
            <b />
            {{ invoiceNumber }}
          </Trans>{' '}
        </AbstractActivity>
      );

    case ActivityType.InvoicePastDue:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Red}
          ctaText={t('activity.invoice.viewInvoice')}
          icon="invoice-warning"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.invoice.pastDue.title')}
          to={`/app/data-and-files/invoices${
            accountGuid && engagementGuid
              ? `#accountGuid=${accountGuid}&engagementGuid=${engagementGuid}`
              : ''
          }`}
        >
          <Trans i18nKey="activity.invoice.pastDue.content">
            <b />
            {{ invoiceNumber }}
          </Trans>{' '}
        </AbstractActivity>
      );

    case ActivityType.InvoicePayment:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.invoice.viewInvoice')}
          icon="invoice-check"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.invoice.payment.title')}
          to={`/app/data-and-files/invoices${
            accountGuid && engagementGuid
              ? `#accountGuid=${accountGuid}&engagementGuid=${engagementGuid}`
              : ''
          }`}
        >
          <Trans i18nKey="activity.invoice.payment.content">
            <b />
            {{ invoiceNumber }}
          </Trans>{' '}
        </AbstractActivity>
      );

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
