import React, { FunctionComponent } from 'react';

import { Icon } from '@ryan/components';

import './Checklocks.scss';

export interface IChecklocksProps {
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checklocks: FunctionComponent<IChecklocksProps> = props => {
  const { value, checked, onChange } = props;
  return (
    <label className="checklocks">
      <input
        checked={checked}
        className="checklocks__control"
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <div className="checklocks__box">
        <Icon name={checked ? 'lock' : 'unlock'} />
      </div>
    </label>
  );
};

export default Checklocks;
