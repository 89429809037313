import Empty from 'components/Empty';
import Attachments from 'components/FileAttachments/Attachments';
import FollowersModal from 'components/FollowersModal/FollowersModal';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import Table from 'components/Table';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import TaskActions from 'components/TaskActions/TaskActions';
import TaskModal from 'components/TaskActions/TaskModal/TaskModal';
import { TaskDrawerConsumer } from 'contexts/TaskDrawerContext';
import { WithUser, withUser } from 'contexts/UserContext';
import { EventEmitter } from 'events';
import {
  IFile,
  IPagedDataResponse,
  ITableState,
  ITask,
  Permission,
  Status,
  TaskStatusGroup,
  UserType
} from 'interfaces/';
import ApiService from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import { formatDate } from 'utils/formatDate';
import getCommentButtonProps from 'utils/getCommentButtonProps';
import getSortParam from 'utils/getSortParm';
import { isTaskPastDue } from 'utils/isPastDue';
import isTableFilterActive from 'utils/isTableFilterActive';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import { AxiosResponse, CancelTokenSource } from 'axios';
import classnames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Button,
  Dropdown,
  EButtonSizes,
  EButtonVariant,
  IOption,
  ITableColumn,
  Icon,
  Tooltip,
  makeTableCheckboxFilter
} from '@ryan/components';

import './TaskTable.scss';

const sortByCreateDate = (attachments: IFile[]) => {
  return attachments
    .map(a => a)
    .sort((a, b) => {
      const aDate = new Date(a.uploadedDate).getTime();
      const bDate = new Date(b.uploadedDate).getTime();
      return aDate - bDate;
    });
};

interface ITaskTableState extends ITableState {
  tasks: ITask[];
  userType: UserType | null;
  assignedToUserGuid: string | null;
  isEditModalOpen: ITask | null;
  isFollowersModalOpen: boolean;
  followersEngagementGuid: string;
  followersQueueItemGuid: string;
}

interface ITaskTableProps extends WithUser, WithTranslation {
  customViewGuid?: string;
  engagementGuid?: string;
  renderActions?: () => JSX.Element;
  updateEmitter?: EventEmitter;
}

class TaskTable extends Component<ITaskTableProps, ITaskTableState> {
  private _isMounted = false;
  private getCustomViewTasksCancelToken?: CancelTokenSource;
  private getEngagementTasksCancelToken?: CancelTokenSource;
  private addWatcherToQueueCancelToken?: CancelTokenSource;

  private columns: ITableColumn<ITask>[];
  private filterOptions: IOption[] = [];
  private lastRequest: Promise<any> | null = null;

  constructor(props: any) {
    super(props);
    const { engagementGuid, permissionService, t } = this.props;

    this.state = {
      expanded: {},
      loading: false,
      page: 1,
      pageSize: 10,
      totalCount: 0,
      searchQuery: '',
      filtered: {
        status: []
      },
      sorted: {
        id: 'dueDate',
        desc: false
      },
      tasks: [],
      userType: null,
      assignedToUserGuid: null,
      isEditModalOpen: null,
      isFollowersModalOpen: false,
      followersEngagementGuid: '',
      followersQueueItemGuid: ''
    };

    this.columns = [
      {
        id: 'title',
        label: t('taskTable.columns.title'),
        sortable: true,
        render: (row: ITask) => {
          const { engagementGuid } = this.props;
          return (
            <div className="bs">
              <b>{row.title}</b>
              {!engagementGuid && <small>{row.accountName}</small>}
            </div>
          );
        }
      },
      engagementGuid &&
      permissionService.hasPermission(Permission.TimelinesView)
        ? {
            id: 'engagementMilestoneTitle',
            label: t('taskTable.columns.milestone'),
            sortable: true,
            render: (row: ITask) =>
              row.engagementMilestoneGuid ? row.engagementMilestoneTitle : '–'
          }
        : {
            id: 'engagementDisplayNameShort',
            label: t('taskTable.columns.project'),
            sortable: true,
            render: (row: ITask) => (
              <Link
                className="table-link bs"
                to={`/app/project/${row.engagementGuid}`}
              >
                {row.engagementDisplayNameShort}
                {permissionService.isRyan() && (
                  <small>{row.engagementId}</small>
                )}
              </Link>
            )
          },
      {
        id: 'status',
        label: t('taskTable.columns.status'),
        sortable: false,
        filter: makeTableCheckboxFilter([
          {
            value: Status.Todo.toString(),
            label: t('To Do')
          },
          {
            value: Status.InProgress.toString(),
            label: t('In Progress')
          },
          {
            value: Status.Complete.toString(),
            label: t('Complete')
          }
        ]),
        filterActive: (value: string[]) => value.length > 0,
        render: (row: any) => <StatusIcon status={row.statusId} />
      },
      {
        id: 'assignedToName',
        label: t('taskTable.columns.assignedTo'),
        sortable: true,
        render: (row: any) => {
          const {
            t,
            user: {
              profile: { userGuid }
            }
          } = this.props;
          return row.assignedToUserGuid === userGuid
            ? t('You')
            : row.assignedToName;
        }
      },
      {
        id: 'dueDate',
        label: t('taskTable.columns.dueDate'),
        sortable: true,
        render: (row: ITask) => (
          <span className="duedate">{formatDate(row.dueDate)}</span>
        )
      },
      {
        id: 'comment',
        label: '',
        align: 'center',
        render: (row: ITask) => {
          const { isAppReadOnly } = this.props;
          const { text, icon } = getCommentButtonProps(
            t,
            permissionService.hasPermission(Permission.TasksContribute),
            row,
            isAppReadOnly
          );
          return (
            <Tooltip
              content={text}
              placement="top"
              renderTarget={({ open, ref, ...props }) => (
                <TaskDrawerConsumer>
                  {({ onTaskDrawerOpen }) => (
                    <Button
                      {...props}
                      ariaLabel={text}
                      className="button-margins"
                      icon={icon}
                      innerRef={ref}
                      onClick={() => onTaskDrawerOpen(row)}
                      size={EButtonSizes.SMALL}
                      variant={EButtonVariant.TEXT}
                    />
                  )}
                </TaskDrawerConsumer>
              )}
            />
          );
        }
      },
      {
        id: 'action',
        label: '',
        align: 'center',
        render: (row: ITask) => (
          <div className="button-margins">
            <TaskActions
              onUpdate={this.handleUpdate}
              size={EButtonSizes.SMALL}
              task={row}
            />
          </div>
        )
      }
    ];

    // master filter dropdown options
    const filterOptions: IOption[] = [
      {
        label: t('taskTable.filterButton.all'),
        value: '0'
      },
      {
        label: t('taskTable.filterButton.mine'),
        value: this.props.user.profile.userGuid
      },
      {
        label: t('taskTable.filterButton.teams'),
        value: permissionService.isRyan() ? '1' : '2'
      },
      {
        label: permissionService.isRyan()
          ? t('taskTable.filterButton.clients')
          : t('taskTable.filterButton.ryans'),
        value: permissionService.isRyan() ? '2' : '1'
      }
    ];

    this.filterOptions = filterOptions;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchTasks();
    const { updateEmitter } = this.props;
    if (updateEmitter) {
      updateEmitter.on('update', this.fetchTasks);
    }
  }

  componentDidUpdate(prevProps: ITaskTableProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchTasks({ page: 1 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.getCustomViewTasksCancelToken?.cancel();
    this.getEngagementTasksCancelToken?.cancel();
    this.addWatcherToQueueCancelToken?.cancel();

    const { updateEmitter } = this.props;
    if (updateEmitter) {
      updateEmitter.off('update', this.fetchTasks);
    }
  }

  getAssignedFilterValue() {
    const { assignedToUserGuid, userType } = this.state;
    return assignedToUserGuid || (userType && userType.toString()) || '0';
  }

  getRowClassName = (row: ITask) => (isTaskPastDue(row) ? 'pastdue' : '');

  fetchTasks = (updates?: Partial<ITaskTableState>) => {
    const { customViewGuid, engagementGuid } = this.props;
    if (this._isMounted) {
      this.setState({ ...(updates as any), loading: true }, () => {
        const {
          searchQuery,
          filtered,
          sorted,
          page,
          pageSize,
          userType,
          assignedToUserGuid
        } = this.state;

        const params: any = {
          searchTerm: searchQuery,
          status: filtered!.status.join(','),
          sort: getSortParam(sorted),
          pageNumber: page,
          itemsPerPage: pageSize,
          userType: userType,
          assignedToUserGuid: assignedToUserGuid
        };

        let request:
          | Promise<AxiosResponse<IPagedDataResponse<ITask>>>
          | undefined;

        // Determine whether or not it's a single project or all projects.
        if (customViewGuid) {
          this.getCustomViewTasksCancelToken?.cancel();
          this.getCustomViewTasksCancelToken = ApiService.CancelToken.source();

          this.lastRequest = request = ApiService.getCustomViewTasks(
            customViewGuid,
            params,
            this.getCustomViewTasksCancelToken.token
          );
        } else if (engagementGuid) {
          this.getEngagementTasksCancelToken?.cancel();
          this.getEngagementTasksCancelToken = ApiService.CancelToken.source();

          this.lastRequest = request = ApiService.getEngagementTasks(
            engagementGuid,
            params,
            this.getEngagementTasksCancelToken.token
          );
        }

        if (request) {
          request
            .then(response => {
              if (request === this.lastRequest) {
                this.setState({
                  tasks: response.data.results,
                  loading: false,
                  totalCount: response.data.totalResults
                });
              }
            })
            .catch(error => {
              if (!ApiService.isCancel(error)) {
                pushServerErrorToast();
              }
            });
        }
      });
    }
  };

  handleFilterChange = (value: any) => {
    const {
      user: {
        profile: { userGuid, userTypeId }
      }
    } = this.props;

    switch (value) {
      case '0':
        this.setState({
          page: 1,
          userType: null,
          assignedToUserGuid: null
        });
        break;

      case '1':
      case '2':
        this.setState({
          page: 1,
          userType: value === '1' ? UserType.Ryan : UserType.Client,
          assignedToUserGuid: null
        });
        break;

      default:
        this.setState({
          page: 1,
          userType: userTypeId,
          assignedToUserGuid: userGuid
        });
        break;
    }
    this.fetchTasks({});
  };

  handleEditModalClose = (task?: ITask) => {
    this.setState({ isEditModalOpen: null });

    if (task) {
      this.fetchTasks();
    }
  };

  handleToggleExpansion = (expanded: boolean, _row: ITask, rowId: string) => {
    this.setState(state => ({
      expanded: { ...state.expanded, [rowId]: expanded }
    }));
  };

  handleSort = (sorted: any) => {
    this.fetchTasks({ sorted });
  };

  handleFilter = (filtered: any) => {
    this.fetchTasks({ filtered, page: 1 });
  };

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchTasks({ page: 1, searchQuery });
    }
  );

  handlePage = (page: number, pageSize: number) => {
    this.fetchTasks({ page, pageSize });
  };

  handleUpdate = () => {
    this.fetchTasks();
  };

  handleFollowersModalClose() {
    this.setState({ isFollowersModalOpen: false });
  }

  handleFollowersModalOpen(engagementGuid: string, queueItemGuid: string) {
    this.setState({
      followersEngagementGuid: engagementGuid,
      followersQueueItemGuid: queueItemGuid,
      isFollowersModalOpen: true
    });
  }

  handleFollowersUpdate() {
    const { isFollowersModalOpen } = this.state;
    this.handleUpdate();
    if (isFollowersModalOpen) this.handleFollowersModalClose();
  }

  async handleFollowToggle(
    engagementGuid: string,
    queueItemGuid: string,
    isCurrentUserWatching: boolean
  ) {
    try {
      this.addWatcherToQueueCancelToken?.cancel();
      this.addWatcherToQueueCancelToken = ApiService.CancelToken.source();

      await ApiService.addWatcherToQueue(
        engagementGuid,
        !isCurrentUserWatching,
        queueItemGuid,
        this.addWatcherToQueueCancelToken.token
      );

      this.handleUpdate();
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    }
  }

  render() {
    const {
      t,
      user: {
        profile: { userTypeId }
      }
    } = this.props;

    const {
      expanded,
      searchQuery,
      sorted,
      tasks,
      page,
      pageSize,
      totalCount,
      filtered,
      loading,
      isEditModalOpen,
      isFollowersModalOpen,
      followersEngagementGuid,
      followersQueueItemGuid
    } = this.state;

    return (
      <>
        <Table<ITask, string>
          className="task-table"
          columns={this.columns}
          data={tasks}
          expanded={expanded}
          filtered={filtered}
          groupBy="statusGroupId"
          groupByClassName={(statusGroupId: number) =>
            TaskStatusGroup[statusGroupId]
          }
          loading={loading}
          onFilterChange={this.handleFilter}
          onPageChange={this.handlePage}
          onSearchChange={this.handleSearch}
          onSortChange={this.handleSort}
          onToggleExpansion={this.handleToggleExpansion}
          page={page}
          pageSize={pageSize}
          renderActions={this.renderActions}
          renderEmpty={() => (
            <TableEmpty
              filterActive={() =>
                this.getAssignedFilterValue() !== '0' ||
                isTableFilterActive(this.columns, filtered)()
              }
              searchQuery={searchQuery}
            >
              <Empty icon="clipboard">{t('taskTable.empty')}</Empty>
            </TableEmpty>
          )}
          renderExpandedRow={this.renderExpandedRow}
          renderGroupHeader={(statusGroupId: number) =>
            t(`task.status.${statusGroupId}`)
          }
          rowClassName={this.getRowClassName}
          rowId="queueItemGuid"
          searchQuery={searchQuery}
          sorted={sorted}
          title={t('Tasks')}
          totalCount={totalCount}
        />
        <TaskModal
          onClose={this.handleEditModalClose}
          open={isEditModalOpen !== null}
          task={isEditModalOpen || undefined}
        />
        <FollowersModal
          engagementGuid={followersEngagementGuid}
          instructions={t('followersModal.instructions', {
            context: 'task'
          })}
          onClose={() => this.handleFollowersModalClose()}
          onUpdate={() => this.handleFollowersUpdate()}
          open={isFollowersModalOpen}
          queueItemGuid={followersQueueItemGuid}
          title={t('Followers', { context: 'task' })}
          userTypeId={userTypeId}
        />
      </>
    );
  }

  renderActions = () => {
    const { renderActions } = this.props;
    const filterValue = this.getAssignedFilterValue();
    return (
      <div className="task-table__header-actions">
        <div className="filter">
          <Dropdown
            name="filterBy"
            onChange={e => {
              this.handleFilterChange(e.target.value as IOption);
            }}
            options={this.filterOptions}
            value={filterValue}
          />
        </div>
        {renderActions && renderActions()}
      </div>
    );
  };

  renderExpandedRow = (task: ITask) => {
    const {
      activeView,
      isAppReadOnly,
      permissionService,
      t: getTextToDisplay,
      user: {
        profile: { userGuid }
      }
    } = this.props;

    return (
      <div
        className={classnames(`task-table__expanded`, {
          'task-table__expanded--pastdue': isTaskPastDue(task)
        })}
      >
        <div className="row">
          <div className="col-6">
            <h4 className="ry-h4">{task.engagementDisplayNameShort}</h4>
            <h3 className="ry-h3">{task.title}</h3>
            <p className="white-space-pre-line">{task.description}</p>
            <Attachments
              attachments={sortByCreateDate(task.attachments)}
              disableDownload={task.isUserGhosted || activeView.isExecutiveView}
              engagementGuid={task.engagementGuid}
            />
            <hr className="task-table__expanded-separator" />
            <div className="task-table__expanded-actions">
              <TaskDrawerConsumer>
                {({ onTaskDrawerOpen }) => (
                  <Button
                    {...getCommentButtonProps(
                      getTextToDisplay,
                      permissionService.hasPermission(
                        Permission.TasksContribute
                      ),
                      task,
                      isAppReadOnly
                    )}
                    onClick={() => onTaskDrawerOpen(task)}
                    size={EButtonSizes.SMALL}
                    variant={EButtonVariant.TEXT}
                  />
                )}
              </TaskDrawerConsumer>
            </div>
          </div>
          <div className="col-1" />
          <div className="col-5">
            <div className="well task-table__expanded-status-info">
              <StatusIcon status={task.statusId} />
              <hr />
              <ul className="labeled-list row">
                <li className="col-6">
                  <label>{getTextToDisplay('Created By')}</label>
                  {task.createdBy === userGuid
                    ? getTextToDisplay('You')
                    : task.createdByName}
                </li>
                <li className="col-6">
                  <label>{getTextToDisplay('Creation Date')}</label>
                  {formatDate(task.createDate)}
                </li>
                {!task.isEngagementReadOnly && !isAppReadOnly && (
                  <li className="col-6">
                    <label>{getTextToDisplay('Followers')}</label>
                    <div className="task-table__expanded-followers">
                      <Button
                        onClick={() =>
                          this.handleFollowToggle(
                            task.engagementGuid,
                            task.queueItemGuid,
                            task.isCurrentUserWatching
                          )
                        }
                        variant={EButtonVariant.LINK}
                      >
                        {getTextToDisplay(
                          task.isCurrentUserWatching ? 'Unfollow' : 'Follow'
                        )}
                      </Button>
                      <span className="task-table__expanded-followers__separator">
                        &#8226;
                      </span>
                      <Button
                        onClick={() =>
                          this.handleFollowersModalOpen(
                            task.engagementGuid,
                            task.queueItemGuid
                          )
                        }
                        variant={EButtonVariant.LINK}
                      >
                        {`${getTextToDisplay('Followers')} (${
                          task.watcherCount
                        })`}
                      </Button>
                    </div>
                  </li>
                )}
                {task.statusId === Status.Complete && task.completedDate && (
                  <li className="col-6">
                    <label>
                      {getTextToDisplay('dataRequest.columns.completionDate')}
                    </label>
                    {formatDate(task.completedDate)}
                  </li>
                )}
              </ul>
            </div>

            {permissionService.hasPermission(Permission.TimelinesView) ? (
              <div className="task-table__expanded-milestone-link">
                {task.engagementMilestoneTitle ? (
                  <>
                    <Icon name="link" />{' '}
                    {getTextToDisplay('taskTable.milestones.linkedTo')}
                    &nbsp;
                    <Link
                      className="ry-link"
                      to={`/app/project/${task.engagementGuid}/overview/milestones/${task.engagementMilestoneGuid}`}
                    >
                      {task.engagementMilestoneTitle}
                    </Link>
                  </>
                ) : (
                  // Show "Link to Milestone" if user can edit
                  // and task's engagement is not readonly
                  permissionService.hasPermission(Permission.TasksEdit) &&
                  (permissionService.isRyan() ||
                    (permissionService.isClient() &&
                      task.createdByUserType === UserType.Client)) &&
                  !task.isEngagementReadOnly &&
                  !isAppReadOnly && (
                    <>
                      <Icon name="link" />
                      <Button
                        onClick={() => this.setState({ isEditModalOpen: task })}
                        variant={EButtonVariant.LINK}
                      >
                        {getTextToDisplay('taskTable.milestones.linkTo')}
                      </Button>
                    </>
                  )
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(withUser(TaskTable));
