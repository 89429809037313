import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  INotificationPreference as Pref,
  INotificationPreferenceGroup as PrefGroup
} from '../../interfaces';
import ApiService from '../../services/ApiService';

export default function useNotificationPreferences() {
  const { t } = useTranslation();
  const [prefs, setPrefs] = useState<PrefGroup[] | null>(null);
  const [savingPrefs, setSavingPrefs] = useState<Promise<any> | null>(null);

  function updatePref(fn: (pg: PrefGroup, p: Pref) => Pref) {
    if (prefs) {
      setPrefs(
        prefs.map(pg => ({
          ...pg,
          userPreferences: pg.userPreferences.map(p => fn(pg, p))
        }))
      );
    }
  }

  function makeOnGroupChecked(prop: 'sendEmail') {
    return function (checked: boolean, prefGroup: PrefGroup) {
      updatePref((pg: PrefGroup, p: Pref) => ({
        ...p,
        [prop]: pg.entityTypeId === prefGroup.entityTypeId ? checked : p[prop]
      }));
    };
  }

  function makeOnPrefChecked(prop: 'sendEmail') {
    return function (checked: boolean, pref: Pref) {
      updatePref((pg: PrefGroup, p: Pref) => ({
        ...p,
        [prop]:
          p.notificationPreferenceGuid === pref.notificationPreferenceGuid
            ? checked
            : p[prop]
      }));
    };
  }

  function onSavePrefs(callback: () => void) {
    if (prefs) {
      setSavingPrefs(
        ApiService.saveNotificationPreferences(prefs)
          .then(() => callback())
          .finally(() => setSavingPrefs(null))
      );
    }
  }

  useEffect(() => {
    ApiService.getNotificationPreferences().then(response => {
      setPrefs(
        response.data
          .map(pg => ({
            ...pg,
            label: t(
              `profile.notificationPreferences.label.${pg.entityTypeId}`
            ),
            userPreferences: pg.userPreferences.sort(
              (a, b) => a.order - b.order
            )
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    });
  }, [t]);

  return {
    prefs,
    savingPrefs,
    onGroupEmailChecked: makeOnGroupChecked('sendEmail'),
    onPrefEmailChecked: makeOnPrefChecked('sendEmail'),
    onSavePrefs
  };
}
