import classnames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import DataRequestStatusSummaryCard from '../../components/DataRequestStatusSummaryCard/DataRequestStatusSummaryCard';
import DocumentTitle from '../../components/DocumentTitle';
import DataRequestModal from '../../components/Modal/DataRequestModal/DataRequestModal';
import ReportingCard from '../../components/ReportingCard/ReportingCard';
import SavingsSummaryCard from '../../components/SavingsSummaryCard/SavingsSummaryCard';
import TaskDataVizCard from '../../components/TaskDataViz/TaskDataVizCard';
import TimelinesCard from '../../components/TimelinesCard/TimelinesCard';
import UserInfoCard from '../../components/UserInfoCard/UserInfoCard';
import { WithUser, withUser } from '../../contexts/UserContext';
import {
  IDataRequestsCountByStatus,
  IEngagement,
  IProjectsCount,
  IUser,
  Permission
} from '../../interfaces';
import ApiService from '../../services/ApiService';
import { logger } from '../../services/logger';
import switcherDidUpdate from '../../utils/switcherDidUpdate';
import LastUpdatedCard from './LastUpdatedCard/LastUpdatedCard';
import ProjectsOverviewCard from './ProjectsOverviewCard/ProjectsOverviewCard';

import './Dashboard.scss';

interface IDashboardProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps {
  // ...
}

interface IDashboardState {
  clientPrincipal: IUser | null;
  clientManager: IUser | null;
  dataRequestStatusSummary: IDataRequestsCountByStatus | null;
  projectsOverviewCount: IProjectsCount[] | null;
  recentProjects: IEngagement[] | null;
  isDataRequestModalOpen: boolean;
  hideSavings: boolean;
  engagements: IEngagement[] | null;
}

class Dashboard extends Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props);

    this.state = {
      clientPrincipal: null,
      clientManager: null,
      dataRequestStatusSummary: null,
      projectsOverviewCount: null,
      recentProjects: null,
      isDataRequestModalOpen: false,
      // If we don't have permission, hide savings.
      // If we do have permission, don't hide, and the Savings card will update us.
      hideSavings: !props.permissionService.hasPermission(
        Permission.SavingsSummaryView
      ),
      engagements: null
    };
  }

  private mounted = true;

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: IDashboardProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchData();
      this.setState({ hideSavings: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData() {
    const { activeView } = this.props;
    this.setState({
      clientManager: null,
      clientPrincipal: null,
      dataRequestStatusSummary: null,
      projectsOverviewCount: null,
      recentProjects: null,
      engagements: null
    });

    const { userGuid } = this.props.user.profile;
    const { accountTree } = this.props.user;

    // Fetch CP and CM for "account views"
    if (activeView.accountView) {
      const { accountGuid, clientPrincipalGuid, clientManagerGuid } =
        activeView.accountView.account;

      if (clientPrincipalGuid) {
        ApiService.getUser(clientPrincipalGuid)
          .then(response => {
            if (this.mounted) {
              this.setState({ clientPrincipal: response.data });
            }
          })
          .catch(error => {
            logger.error(
              `error fetching CP with GUID "${clientPrincipalGuid}" for account "${accountGuid}" - ${error}`
            );
          });
      }

      if (clientManagerGuid) {
        ApiService.getUser(clientManagerGuid)
          .then(response => {
            if (this.mounted) {
              this.setState({ clientManager: response.data });
            }
          })
          .catch(error => {
            logger.error(
              `error fetching CM with GUID "${clientManagerGuid}" for account "${accountGuid}" - ${error}`
            );
          });
      }

      if (userGuid) {
        ApiService.getEngagementsForCustomViewByUser(
          activeView.customViewGuid,
          userGuid,
          {}
        )
          .then(response => {
            if (this.mounted) {
              this.setState({ engagements: response.data.results });
            }
          })
          .catch(error => {
            logger.error(
              `error fetching engagements for custom view "${activeView.customViewGuid} for user ${userGuid}"`
            );
          });
      }
    } else if (activeView.customViewGuid && accountTree.length > 1) {
      // fetch data for multiple accounts selected
      ApiService.getEngagementsForCustomViewByUser(
        activeView.customViewGuid,
        userGuid,
        {}
      )
        .then(response => {
          if (this.mounted) {
            this.setState({ engagements: response.data.results });
          }
        })
        .catch(error => {
          logger.error(
            `error fetching engagements for custom view "${activeView.customViewGuid} for user ${userGuid}"`
          );
        });
    }

    // Fetch Data Request Status Summary
    this.fetchdataRequestStatusSummary();

    // Fetch Projects Overview Info
    ApiService.getProjectsOverviewRollup(activeView.customViewGuid).then(
      response => {
        if (this.mounted) {
          this.setState({ projectsOverviewCount: response.data });
        }
      }
    );

    // Fetch Last Updated Projects List
    ApiService.getLastUpdatedProjects(activeView.customViewGuid).then(
      response => {
        if (this.mounted) {
          this.setState({ recentProjects: response.data });
        }
      }
    );
  }

  async fetchdataRequestStatusSummary() {
    const { activeView, permissionService: ps } = this.props;
    if (ps.hasPermission(Permission.DataRequestsView)) {
      const response = await ApiService.getCustomViewDataRequestCount(
        activeView.customViewGuid
      );
      if (this.mounted) {
        this.setState({ dataRequestStatusSummary: response.data });
      }
    }
  }

  handleCreateDataRequestClose = (dataRequest?: any) => {
    if (dataRequest) {
      this.fetchdataRequestStatusSummary();
    }
    this.setState({ isDataRequestModalOpen: false });
  };

  handleSavingsEmpty = () => {
    const { permissionService: ps } = this.props;
    if (ps.isClient()) {
      this.setState({ hideSavings: true });
    }
  };

  render() {
    const {
      t,
      activeView: { name, customViewGuid },
      permissionService
    } = this.props;

    const {
      clientManager,
      clientPrincipal,
      dataRequestStatusSummary,
      isDataRequestModalOpen,
      projectsOverviewCount,
      recentProjects,
      hideSavings,
      engagements
    } = this.state;

    const isUserReadOnlyForCurrentAccount: boolean = engagements
      ? engagements.filter(engagement => !engagement.isUserGhosted).length === 0
      : true;

    return (
      <div className="dashboard">
        <DocumentTitle title={t('dashboard.title')} />

        <h4 className="ry-h4 d-md-none">{name}</h4>
        <div className="row">
          {/* Left */}
          <div className="col-12 col-lg-8">
            <div className="row">
              {permissionService.hasPermission(Permission.SavingsSummaryView) &&
                !hideSavings && (
                  <div className="col-12 col-md-6 col-card">
                    <SavingsSummaryCard
                      customViewGuid={customViewGuid}
                      onSavingsEmpty={this.handleSavingsEmpty}
                    />
                  </div>
                )}
              <div
                className={classnames('col-12 col-card', {
                  'col-md-6': !hideSavings
                })}
              >
                <ProjectsOverviewCard values={projectsOverviewCount} />
              </div>
            </div>
            {permissionService.hasPermission(Permission.TimelinesView) && (
              <div className="row">
                <div className="col-12">
                  <TimelinesCard customViewGuid={customViewGuid} />
                </div>
              </div>
            )}
            <div className="row">
              {clientPrincipal && (
                <div className="col-12 col-md-6 col-card">
                  <UserInfoCard
                    cardTitle={t('Client Principal')}
                    user={clientPrincipal}
                  />
                </div>
              )}
              {clientManager && (
                <div className="col-12 col-md-6 col-card">
                  <UserInfoCard
                    cardTitle={t('Client Manager')}
                    user={clientManager}
                  />
                </div>
              )}
            </div>
          </div>
          {/* Right */}
          <div className="col-12 col-lg-4">
            <div className="row">
              <div className="col-md-6 col-lg-12">
                {!permissionService.isThirdParty() &&
                  (recentProjects === null || recentProjects.length > 0) && (
                    <LastUpdatedCard engagements={recentProjects} />
                  )}
                {permissionService.hasPermission(
                  Permission.DataRequestsView
                ) && (
                  <DataRequestStatusSummaryCard
                    isEngagementReadOnly={isUserReadOnlyForCurrentAccount}
                    onCreateClick={() => {
                      if (
                        permissionService.hasPermission(
                          Permission.DataRequestsEdit
                        )
                      ) {
                        this.setState({ isDataRequestModalOpen: true });
                      }
                    }}
                    values={dataRequestStatusSummary}
                  />
                )}
              </div>

              <div className="col-md-6 col-lg-12">
                {permissionService.hasPermission(Permission.TasksView) && (
                  <TaskDataVizCard
                    customViewGuid={customViewGuid}
                    isEngagementReadOnly={isUserReadOnlyForCurrentAccount}
                  />
                )}

                {permissionService.hasPermission(Permission.ReportsView) && (
                  <ReportingCard engagements={this.state.engagements || []} />
                )}
              </div>
            </div>
          </div>
        </div>

        {isDataRequestModalOpen &&
          permissionService.hasPermission(Permission.DataRequestsEdit) && (
            <DataRequestModal onClose={this.handleCreateDataRequestClose} />
          )}
      </div>
    );
  }
}

export default withTranslation()(withUser(Dashboard));
