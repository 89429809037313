import { CancelToken } from 'axios';
import { IComment, IMilestone, IMilestoneRequest } from 'interfaces';
import { transformResponse } from 'utils/transformResponse';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';
import ApiBase from './ApiBase';

/**
 * GET a list of milestones attached to an engagement
 */
export async function getMilestones(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IMilestone[]>(
    `/api/engagements/${engagementGuid}/milestones`,
    { cancelToken, transformResponse }
  );
}

/**
 * CREATE a milestone
 * @param engagementGuid: Guid of the engagement/project user is on
 * @param milestone: The milestone you're updating
 */
export async function createMilestone(
  this: ApiBase,
  milestone: IMilestoneRequest,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  return unwrapCommandResponse<IMilestone>(
    this.server.post(
      `/api/engagements/${engagementGuid}/milestones`,
      {
        status: milestone.statusId,
        title: milestone.title,
        detail: milestone.detail,
        startDate: milestone.startDate,
        endDate: milestone.endDate
      },
      { cancelToken }
    )
  );
}

/**
 * UPDATE a milestone
 * @param engagementGuid: Guid of the engagement/project user is on
 * @param engagementMilestoneGuid: Guid of the milestone to be deleted
 * @param milestone: The milestone you're updating
 */
export async function updateMilestone(
  this: ApiBase,
  milestone: IMilestoneRequest,
  engagementGuid: string,
  engagementMilestoneGuid: string,
  cancelToken?: CancelToken
) {
  return unwrapCommandResponse<IMilestone>(
    this.server.put(
      `/api/engagements/${engagementGuid}/milestones/${engagementMilestoneGuid}`,
      {
        status: milestone.statusId,
        title: milestone.title,
        detail: milestone.detail,
        startDate: milestone.startDate,
        endDate: milestone.endDate
      },
      { cancelToken }
    )
  );
}

/**
 * DELETE a milestone
 * @param engagementGuid: Guid of the engagement/project user is on
 * @param engagementMilestoneGuid: Guid of the milestone to be deleted
 */
export async function deleteMilestone(
  this: ApiBase,
  engagementGuid: string,
  engagementMilestoneGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.delete(
    `/api/engagements/${engagementGuid}/milestones/${engagementMilestoneGuid}`,
    { cancelToken }
  );
}

//
//
// Milestone Comments
//
//

/**
 * GET a Milestone's Comments
 */
export async function getMilestoneComments(
  this: ApiBase,
  engagementGuid: string,
  engagementMilestoneGuid: string
) {
  return this.server.get(
    `/api/engagements/${engagementGuid}/milestones/${engagementMilestoneGuid}/comments`
  );
}
/**
 * CREATE a Milestone Comment
 */
export async function createMilestoneComment(
  this: ApiBase,
  engagementGuid: string,
  engagementMilestoneGuid: string,
  commentText: string
) {
  return unwrapCommandResponse<IComment>(
    this.server.post(
      `/api/engagements/${engagementGuid}/milestones/${engagementMilestoneGuid}/comments`,
      { commentText }
    )
  );
}
/**
 * UPDATE a Milestone Comment
 */
export async function updateMilestoneComment(
  this: ApiBase,
  engagementGuid: string,
  engagementMilestoneGuid: string,
  commentGuid: string,
  commentText: string
) {
  return unwrapCommandResponse<IComment>(
    this.server.put(
      `/api/engagements/${engagementGuid}/milestones/${engagementMilestoneGuid}/comments/${commentGuid}`,
      { commentText }
    )
  );
}

/**
 * DELETE a Milestone Comment
 */
export async function deleteMilestoneComment(
  this: ApiBase,
  engagementGuid: string,
  engagementMilestoneGuid: string,
  commentGuid: string
) {
  return unwrapCommandResponse<IComment>(
    this.server.delete(
      `/api/engagements/${engagementGuid}/milestones/${engagementMilestoneGuid}/comments/${commentGuid}`
    )
  );
}
