import { useEffect, useMemo } from 'react';
import { useUser } from 'contexts/UserContext';
import { useStateMounted } from 'hooks';
import { IAccount } from 'interfaces';

export type AccountHookResponse = {
  accounts: IAccount[];
  loading: boolean;
};

export default function useAccounts(
  useExecutiveAccounts: boolean
): AccountHookResponse {
  const {
    user: { accountTree: standardAccounts },
    executiveAccounts,
    loadExectiveAccounts
  } = useUser();
  const [loading, setLoading] = useStateMounted(false);

  useEffect(() => {
    (async function () {
      if (useExecutiveAccounts && executiveAccounts === null && !loading) {
        try {
          setLoading(true);
          await loadExectiveAccounts();
        } catch {
          // ...
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [
    executiveAccounts,
    loading,
    useExecutiveAccounts,
    loadExectiveAccounts,
    setLoading
  ]);

  const hookResponse: AccountHookResponse = useMemo(
    () => ({
      accounts: useExecutiveAccounts
        ? executiveAccounts || []
        : standardAccounts,
      loading
    }),
    [executiveAccounts, loading, standardAccounts, useExecutiveAccounts]
  );

  return hookResponse;
}
