import DOMPurify from 'dompurify';
import { ITermsOfUse, TermsOfUseType } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import UserService from 'services/UserService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Checkbox,
  EButtonSizes,
  EButtonVariant
} from '@ryan/components';

import './TermsOfUse.scss';

const TermsOfUse: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [terms, setTerms] = useState<ITermsOfUse>({
    acceptedVersion: null,
    content: '',
    version: 0
  });
  const [viewedAllTerms, setViewedAllTerms] = useState(false);
  const sourceRef = useRef<CancelTokenSource>();
  const needsToAcceptLatest: boolean =
    terms.acceptedVersion !== null && terms.version > terms.acceptedVersion;

  /**
   * Callback fired on Terms of Use acceptance. Accepts latest terms and
   * redirects user to app home.
   */
  const handleContinue = async () => {
    // refresh cancel token
    sourceRef.current?.cancel();
    sourceRef.current = ApiService.CancelToken.source();

    try {
      await ApiService.acceptTermsOfUse(terms.version, sourceRef.current.token);
      history.push('/app');
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    }
  };

  /**
   * Callback fired on terms of use scroll. Enables terms of use acceptance
   * checkbox once the entire terms have been viewed.
   */
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = e.currentTarget;

    if (scrollTop + clientHeight + 5 >= scrollHeight) {
      setViewedAllTerms(true);
    }
  };

  // fetch latest terms of use
  useEffect(() => {
    const fetchTerms = async () => {
      // refresh cancel token
      sourceRef.current = ApiService.CancelToken.source();

      try {
        const response = await ApiService.getTermsOfUse(
          TermsOfUseType.TermsOfUse,
          sourceRef.current.token
        );

        // sanitize HTML content from response
        const sanitizedContent = DOMPurify.sanitize(response.data.content, {
          // support "target" attribute on anchors
          ADD_ATTR: ['target']
        });
        setTerms({
          ...response.data,
          content: sanitizedContent
        });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      }
    };

    fetchTerms();

    return () => {
      // cancel any ongoing requests
      sourceRef.current?.cancel();
    };
  }, [t]);

  return (
    <div className="terms-of-use">
      <h1>
        {needsToAcceptLatest
          ? t('termsOfUse.updatedTermsTitle')
          : t('termsOfUse.title')}
      </h1>
      <p>
        {needsToAcceptLatest
          ? t('termsOfUse.updatedTermsHelperText')
          : t('termsOfUse.helperText')}
      </p>
      <div
        className="terms-of-use__terms"
        dangerouslySetInnerHTML={{ __html: terms.content }}
        onScroll={handleScroll}
      />
      <Checkbox
        checked={acceptedTerms}
        disabled={!viewedAllTerms}
        label={t('termsOfUse.checkboxLabel')}
        onChange={e => setAcceptedTerms(e.target.checked)}
        value="acceptedTerms"
      />
      <ButtonGroup>
        <Button
          onClick={UserService.logout}
          size={EButtonSizes.LARGE}
          variant={EButtonVariant.SECONDARY}
        >
          {t('Back')}
        </Button>
        <Button
          disabled={!acceptedTerms}
          onClick={handleContinue}
          size={EButtonSizes.LARGE}
          variant={EButtonVariant.PRIMARY}
        >
          {t('Continue')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default TermsOfUse;
