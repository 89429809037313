import { WithDownload, withDownload } from 'contexts/DownloadContext';
import { IFile } from 'interfaces';

import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant, Tooltip } from '@ryan/components';

export interface IAttachmentLinkProps {
  engagementGuid: string | null;
  file: IFile;
  fileName?: ReactNode;
}

const AttachmentLink: FunctionComponent<
  IAttachmentLinkProps & WithDownload
> = ({
  file,
  fileName = file.displayName,
  engagementGuid,
  onDownloadFiles
}) => {
  const { t } = useTranslation();

  // ensure file is ready before rendering download link (large files need
  // chunks joined before can be downloaded)
  if (!file.isReady) {
    return (
      <Tooltip
        content={t('file.processingTooltip')}
        placement="top-start"
        renderTarget={({ open, ref, ...props }) => (
          <>
            <Button
              aria-expanded={open}
              aria-haspopup="true"
              innerRef={ref}
              variant={EButtonVariant.LINK}
              {...props}
            >
              {fileName}
            </Button>
            <small
              style={{
                display: 'block',
                fontStyle: 'italic'
              }}
            >
              {t('file.processing')}
            </small>
          </>
        )}
      />
    );
  }

  // use download provider to download file so we can know when we need to zip
  // large files
  return (
    <Button
      onClick={() => onDownloadFiles([file], engagementGuid || undefined)}
      variant={EButtonVariant.LINK}
    >
      {fileName}
    </Button>
  );
};

export default withDownload(AttachmentLink);
