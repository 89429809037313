import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon } from '@ryan/components';
import { formatCurrency } from 'utils/formatCurrency';

export interface ICurrencyProps extends WithTranslation {
  className?: string;

  // An ISO 4217 currency code.
  // https://www.iso.org/iso-4217-currency-codes.html
  currencyCode: string | null;

  // The number to format.
  value: number;

  includeCents?: boolean;
  includeSymbol?: boolean;
  includePositiveSigning?: boolean;
  includeCreditFormat?: boolean;

  /**
   * Adds an arrow indicating a positive or negative value. Placed on the right
   * by default, but can be placed on the left via `left`.
   */
  includeArrow?: boolean | 'left';
}

const Currency: FunctionComponent<ICurrencyProps> = props => {
  const {
    i18n: { language },
    className: classNameProp,
    currencyCode,
    value,
    includeCents,
    includeSymbol,
    includePositiveSigning,
    includeCreditFormat,
    includeArrow
  } = props;

  const className = classnames('currency', classNameProp);

  let formatted = formatCurrency(value, language, currencyCode, includeCents);

  // $100 to 100
  if (!includeSymbol || (includePositiveSigning && !includeArrow)) {
    formatted = formatted.replace(/[^-,.\d]/g, '');
  }

  // includeArrow ignores includePositiveSigning,
  // and removes the default negative signing
  // 100 to ↑100, -100 to ↓100
  if (includeArrow) {
    const left = includeArrow === 'left';

    // remove negative signing
    formatted = formatted.replace(/-/g, '');

    return (
      <span className={className}>
        {!left && formatted}
        <Icon
          className={classnames('arrow', {
            'arrow-up': value > 0,
            'arrow-down': value < 0
          })}
          name={value === 0 ? 'ascend' : value > 0 ? 'arrow-up' : 'arrow-down'}
        />
        {left && formatted}
      </span>
    );
  }

  // $100 to +$100
  if (includePositiveSigning && value > 0) {
    formatted = `+${formatted}`;
  }

  // -$100 to ($100)
  if (includeCreditFormat && formatted.includes('-')) {
    formatted = `(${formatted.replace(/-/g, '')})`;
  }

  return <span className={className}>{formatted}</span>;
};

Currency.defaultProps = {
  includeCents: true,
  includeSymbol: true,
  includePositiveSigning: false,
  includeCreditFormat: false,
  includeArrow: false
};

export default withTranslation()(Currency);
