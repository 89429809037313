import { Placement } from '@popperjs/core';

import React, { FunctionComponent } from 'react';

import { Icon, Tooltip } from '@ryan/components';

import './TooltipList.scss';

interface ITooltipListProps {
  placement?: Placement;
  list: {
    label: string;
    value: string | number;
  }[];
  ariaLabel?: string;
  style?: Record<string, unknown>;
}

export const TooltipList: FunctionComponent<ITooltipListProps> = props => {
  const { placement, list, ariaLabel, style } = props;

  return (
    <Tooltip
      className="tooltip-list"
      content={
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <label>{item.label}</label>
              {item.value}
            </li>
          ))}
        </ul>
      }
      placement={placement || 'top'}
      renderTarget={({ open, ...props }) => (
        <button
          aria-expanded={open}
          aria-haspopup="true"
          aria-label={ariaLabel}
          className="tooltip-list-target"
          style={style}
          {...props}
        >
          <Icon name="information" />
        </button>
      )}
    />
  );
};

export default TooltipList;
