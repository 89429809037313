import { IGetFolderAndFileActionStatusTitle } from './IGetFolderAndFileActionStatusTitle';
import { pluralKey } from './pluralKey';

export const getFolderAndFileActionStatusTitle: IGetFolderAndFileActionStatusTitle =
  ({
    basePathToTitle,
    deletedFilesCount,
    deletedFoldersCount,
    files,
    isNestedArchivedFilesExcluded = false
  }) => {
    const isDeleteActionType = basePathToTitle.includes('batchDeleteModal');

    let fileCount =
      isDeleteActionType && deletedFilesCount !== undefined
        ? deletedFilesCount
        : isNestedArchivedFilesExcluded
        ? files.files.length
        : files.filesCount;

    let folderCount =
      isDeleteActionType && deletedFoldersCount !== undefined
        ? deletedFoldersCount
        : files.folders.length;

    if (!isDeleteActionType) {
      files.folders.forEach(folder => {
        if (isNestedArchivedFilesExcluded) {
          fileCount += folder.totalFileCount - folder.archivedFileCount!;
        }

        folderCount += (folder as any).totalSubFolderCount;
        folderCount -= (folder as any).archivedSubFolderCount;
      });
    }

    const tFile = pluralKey('file', fileCount);
    const tFolder = pluralKey('folder', folderCount);

    return [
      `${basePathToTitle}.${tFile}${tFolder}`,
      {
        fileCount,
        fileName: files.files[0]?.displayName || '',
        folderCount
      }
    ];
  };
