import i18n from 'utils/i18n';

/**
 * Returns the currency symbol for a given currency code.
 *
 * @deprecated
 */
export function getCurrencySymbol(currency: string): string {
  try {
    return (0)
      .toLocaleString(i18n.language, { style: 'currency', currency })
      .replace(/\d+([,.]\d+)?/g, '') // strip amount from currency string
      .trim();
  } catch {
    return '';
  }
}
