import {
  ICountry,
  IJurisdiction,
  IPracticeArea,
  ISubdivision
} from 'interfaces';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

export async function getCountries(this: ApiBase, cancelToken?: CancelToken) {
  return this.server.get<ICountry[]>('/api/countries', { cancelToken });
}

export async function getCountrySubdivisions(
  this: ApiBase,
  isoCountryNumericCode: string
) {
  return this.server.get<ISubdivision[]>(
    `api/countries/${isoCountryNumericCode}/subdivisions`
  );
}

export async function getJurisdictions(this: ApiBase, searchTerm: string) {
  return this.server.get<IJurisdiction[]>(`/api/jurisdictions/autocomplete`, {
    params: { searchTerm }
  });
}

export async function getAllPracticeAreas(
  this: ApiBase,
  cancelToken?: CancelToken
) {
  return this.server.get<IPracticeArea[]>('/api/practiceareas', {
    cancelToken
  });
}
