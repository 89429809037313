import classnames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Button,
  Card,
  EButtonSizes,
  EButtonVariant,
  Icon
} from '@ryan/components';

import {
  WithAmplitude,
  withAmplitude
} from '../../contexts/AmplitudeContext/AmplitudeConsumer';
import { DownloadConsumer } from '../../contexts/DownloadContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import {
  IEngagement,
  IInvoice,
  IInvoicesSearch,
  ITableState
} from '../../interfaces';
import ApiService, { CancelTokenSource } from '../../services/ApiService';
import { amplitudeEventDetail } from '../../utils/amplitudeUtils/amplitudeUtils';
import { formatDate } from '../../utils/formatDate';
import getSortParam from '../../utils/getSortParm';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import Currency from '../Currency/Currency';
import Empty from '../Empty';

import './InvoiceCard.scss';

export interface IInvoiceCardProps
  extends WithAmplitude,
    WithUser,
    WithTranslation,
    RouteComponentProps {
  engagement: IEngagement;
}

interface IInvoiceCardState extends ITableState {
  invoices: IInvoice[];
}

class InvoiceCard extends Component<IInvoiceCardProps, IInvoiceCardState> {
  private _isMounted = false;

  private source?: CancelTokenSource;

  readonly state = {
    expanded: {},
    loading: false,
    invoices: [],
    page: 1,
    pageSize: 2,
    totalCount: 0,
    searchQuery: '',
    sorted: {
      id: 'invoiceDate',
      desc: false
    },
    filtered: {
      status: ''
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchInvoices();
  }

  componentWillUnmount() {
    this.source?.cancel();
    this._isMounted = false;
  }

  fetchInvoices = (updates: Partial<IInvoiceCardState> = {}) => {
    const { engagement } = this.props;

    this.setState(
      {
        ...(updates as IInvoiceCardState),
        loading: true
      },
      async () => {
        const { page, pageSize, sorted } = this.state;
        const params: IInvoicesSearch = {
          itemsPerPage: pageSize,
          pageNumber: page,
          sort: getSortParam(sorted)
        };

        // refresh cancel token
        this.source?.cancel();
        this.source = ApiService.CancelToken.source();

        try {
          const response = await ApiService.getInvoicesByEngagement(
            engagement.engagementGuid,
            params,
            this.source.token
          );
          this.setState({
            invoices: response.data.results,
            totalCount: response.data.totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast(`could not fetch invoices - ${error}`);
          }
        } finally {
          if (this._isMounted) {
            this.setState({
              loading: false
            });
          }
        }
      }
    );
  };

  render() {
    const {
      activeView: { isExecutiveView },
      engagement,
      isAppReadOnly,
      t: getTextToDisplay
    } = this.props;
    const { loading, totalCount, invoices } = this.state;

    const engagementInvoices: IInvoice[] = invoices;
    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;
    const numberOfAvailableInvoices = totalCount >= 2 ? 2 : totalCount;

    return (
      <Card
        className={classnames('invoice-card', {
          'invoice-card--included': totalCount !== 0
        })}
        role="region"
        title={
          totalCount === 0
            ? getTextToDisplay('dataAndFiles.tabs.invoices')
            : getTextToDisplay('dataAndFiles.tabs.invoicesWithCount', {
                numberOfAvailableInvoices,
                totalCount
              })
        }
      >
        <Button
          className="invoice-card__view-all"
          onClick={() => {
            this.props.initializeEventToTrack({
              eventName: amplitudeEventDetail.ryanInvoices.eventName,
              eventProperty:
                amplitudeEventDetail.ryanInvoices.viewPropertyOptions
                  .singleProjectFiles
            });

            this.props.history.push('/app/data-and-files/invoices');
          }}
          size={EButtonSizes.SMALL}
          text={getTextToDisplay('View All')}
          variant={EButtonVariant.TEXT}
        />
        {loading ? (
          <Icon className="loading-spin" name="loading" />
        ) : !engagementInvoices.length ? (
          <Empty icon="invoice">{getTextToDisplay('invoices.empty')}</Empty>
        ) : (
          <div className="row">
            {engagementInvoices.map(invoice => (
              <div
                className="col-12 col-md-6 col-lg-12"
                key={invoice.invoiceId}
              >
                <Card
                  className={classnames(
                    'invoice-card__card',
                    `invoice-card__card--${invoice.status.toLocaleLowerCase()}`
                  )}
                  title={invoice.name}
                >
                  <div className="invoice-card__card-content">
                    <div className="row">
                      <div className="col-sm-6 invoice-card__card-left">
                        <ul className="labeled-list">
                          <li>
                            <label>
                              {getTextToDisplay('invoices.columns.date')}
                            </label>
                            {formatDate(invoice.invoiceDate)}
                          </li>
                          <li>
                            <label>
                              {getTextToDisplay(
                                'invoices.columns.paymentTerms'
                              )}
                            </label>
                            {invoice.paymentTerms ? invoice.paymentTerms : '–'}
                          </li>
                          <li>
                            <label>
                              {getTextToDisplay(
                                'invoices.columns.invoiceNumber'
                              )}
                            </label>
                            {invoice.invoiceNumber}
                          </li>
                          {invoice.replaceInvoices && (
                            <li>
                              <label>
                                {getTextToDisplay(
                                  'invoices.columns.replacesInvoiceNumber'
                                )}
                              </label>
                              {invoice.replaceInvoices}
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="col-sm-6">
                        <div className="well">
                          <ul className="labeled-list">
                            <li>
                              <label>
                                {getTextToDisplay('invoices.columns.status')}
                              </label>
                              <span className="invoice-card__card-status">
                                {getTextToDisplay(
                                  `invoices.status.${invoice.statusId}`
                                )}
                              </span>
                            </li>
                            <li>
                              <label>
                                {getTextToDisplay(
                                  'invoices.columns.invoiceAmount'
                                )}
                              </label>
                              <Currency
                                className="invoice-card__card-amount"
                                currencyCode={invoice.isoCurrencyCode}
                                value={invoice.amountDue}
                              />
                            </li>
                            <li>
                              <label>
                                {getTextToDisplay('invoices.columns.balance')}
                              </label>
                              <Currency
                                className="invoice-card__card-balance"
                                currencyCode={invoice.isoCurrencyCode}
                                value={invoice.balance}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-card__card-download">
                    <DownloadConsumer>
                      {({ onDownloadInvoice }) => (
                        <Button
                          disabled={
                            isReadOnlyByUserState || engagement.isUserGhosted
                          }
                          icon="download"
                          onClick={() => {
                            const { triggerAmplitudeEvent } = this.props;

                            triggerAmplitudeEvent({
                              amplitudeEventAction:
                                amplitudeEventDetail.ryanInvoices
                                  .downloadEventName,
                              amplitudeEventLocation:
                                '/app/data-and-files/invoices',
                              amplitudeEventName:
                                amplitudeEventDetail.ryanInvoices.eventName,
                              amplitudeEventProperty:
                                amplitudeEventDetail.ryanInvoices
                                  .downloadPropertyOptions.singleProjectFiles
                            });

                            onDownloadInvoice(invoice);
                          }}
                          size={EButtonSizes.SMALL}
                          text={getTextToDisplay('Download')}
                          variant={EButtonVariant.TEXT}
                        />
                      )}
                    </DownloadConsumer>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        )}
      </Card>
    );
  }
}

export default withRouter(
  withAmplitude(withUser(withTranslation()(InvoiceCard)))
);
