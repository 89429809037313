import ENV from 'env';
import { FileType } from 'interfaces';

export default function getDocumentDownloadUrl(
  documentGuid: string,
  engagementGuid: string | null,
  fileType: FileType
): string {
  if (
    !engagementGuid &&
    (fileType === FileType.Contract || fileType === FileType.Invoice)
  ) {
    throw new Error(
      `invalid engagement GUID "${engagementGuid}" for file type "${fileType}"`
    );
  }

  switch (fileType) {
    case FileType.Invoice:
      return `${ENV.API_ROOT}/api/engagements/${engagementGuid}/invoices/${documentGuid}/download`;
    case FileType.Contract:
      return `${ENV.API_ROOT}/api/engagements/${engagementGuid}/contract/${documentGuid}/download`;
    case FileType.ZipFile:
      return `${ENV.API_ROOT}/api/zipRequest/${documentGuid}/download`;
    default:
      return engagementGuid
        ? `${ENV.API_ROOT}/api/engagements/${engagementGuid}/document/${documentGuid}/download`
        : `${ENV.API_ROOT}/api/document/${documentGuid}/download`;
  }
}
