import { Status } from './Status';

export enum MilestoneType {
  Kickoff = 1,
  Interim,
  End
}
export interface IMilestoneFormValues {
  title: string;
  detail: string;
  statusId: string;
  startDate: Date | null;
  endDate: Date | null;
}

export interface IMilestoneRequest {
  detail: string;
  endDate: Date | null;
  milestoneTypeId: MilestoneType;
  startDate: Date | null;
  status?: string;
  statusId: Status;
  title: string;
}

export interface IMilestone extends IMilestoneRequest {
  //

  // Account
  accountGuid: string;
  parentAccountGuid: string;
  ultimateParentAccountGuid: string;

  // Engagement
  engagementGuid: string;
  engagementDisplayNameLong: string;
  engagementDisplayNameShort: string;
  isEngagementReadOnly: boolean; // !isEngagementActive || isUserGhosted
  isEngagementActive: boolean;
  isUserGhosted: boolean;

  // Milestone
  engagementMilestoneGuid: string;
  startDate: Date;
  endDate: Date;

  // sourceApplicationGuid: string;
  isEditable: boolean; // is editable if not owned by external source

  updatedBy: string;
  updatedByEmail: string;
  updatedByName: string;
  updatedImpersonatedByEmail: string;
  updatedImpersonatedByName: string;

  commentsCount: number;
}
