import { IUserIdentity } from 'interfaces';
import ApiService from 'services/ApiService';
import { EXECUTIVE_VIEW_STORAGE_KEY } from 'services/ApiService/storageKeys';
import { eraseCookie } from 'utils/cookies';
import {
  clearImpersonation,
  setImpersonation
} from 'utils/getImpersonatedUserGuid';

import { stopHubConnection } from './SignalR';
import { getUserManager } from './UserManager';

/**
 * Reloads the app by refreshing the page and navigating to the homepage.
 */
function reload() {
  window.location.assign('/app');
}

/**
 * Clears all stored user state in the application and tears down active
 * subscriptions.
 */
async function teardown() {
  // stop active SignalR connection
  stopHubConnection();

  // clear local user state
  eraseCookie('Auth');
  eraseCookie('isExecutiveView');
  clearImpersonation();
  window.sessionStorage.removeItem(EXECUTIVE_VIEW_STORAGE_KEY);

  // remove advanced project filter and worklist filters keys
  const sessionStorageKeysToRemove = Object.keys(window.sessionStorage).filter(
    key =>
      key.includes('WORKLIST_FILTERS_SET') || key.includes('DXP_PROJECT_FILTER')
  );

  sessionStorageKeysToRemove.forEach(key => {
    window.sessionStorage.removeItem(key);
  });

  await getUserManager().clearStaleState();
}

/**
 * Logout the current user externally via the authentication server. Calls OIDC
 * `signoutRedirect()`.
 *
 * For distinction between `removeUser()` and `signoutRedirect()`:
 * @see https://github.com/maxmantz/redux-oidc/issues/134#issuecomment-472380722
 */
export async function logout() {
  await teardown();

  // logout via authentication server (calls `removeUser()` internally)
  const userManager = getUserManager();
  const user = await userManager.getUser();
  await userManager.signoutRedirect({
    id_token_hint: user?.id_token
  });
}

/**
 * Remove all user data from the client. Calls OIDC `removeUser()`.
 */
export async function removeUser() {
  await teardown();
  await getUserManager().removeUser();
}

type SetUserType = (user: IUserIdentity) => void;

/**
 * Reloads the app with the impersonated user's profile.
 */
export async function impersonate(
  impersonateUserGuid: string,
  setUser: SetUserType
) {
  const impersonatedUserIdentity = (
    await ApiService.startImpersonationSession(impersonateUserGuid)
  ).data;
  reload();
  setTimeout(async function () {
    await teardown();
    setImpersonation(impersonateUserGuid);
    setUser(impersonatedUserIdentity);
  }, 500);
}
/**
 * Stops user impersonation and reloads the app.
 */
export async function stopImpersonating(setUser: SetUserType) {
  const realUserIdentity = (await ApiService.stopImpersonationSession()).data;
  reload();
  setTimeout(async function () {
    await teardown();
    setUser(realUserIdentity);
  }, 500);
}

const UserService = {
  impersonate,
  logout,
  removeUser,
  stopImpersonating
};

export default UserService;
