import ManageAttachments from 'components/ManageAttachments';
import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import EngagementContext from 'contexts/EngagementContext';
import { IAttachmentUpdates, ILearning } from 'interfaces';
import ApiService from 'services/ApiService';
import { DSSManager } from 'utils/DSS';
import { Formik, formikFieldProps, yup } from 'utils/forms';

import React, { PureComponent } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonVariant,
  Message,
  TextInput,
  Textarea,
  pushToast
} from '@ryan/components';

interface ILearningModalValues {
  title: string;
  description: string;
  attachments: IAttachmentUpdates;
}

interface ILearningModalProps extends WithTranslation {
  engagementGuid: string;
  engagementName: string;
  learning?: ILearning;
  open: boolean;
  onClose: (learning: ILearning | null) => void;
}

interface ILearningModalState {
  submitPromise: Promise<any> | null;
  error?: React.ReactNode;
}

const maxLengthDescription = 250;
const maxLengthTitle = 50;

export class LearningModal extends PureComponent<
  ILearningModalProps,
  ILearningModalState
> {
  private _isMounted = false;

  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  private dss = new DSSManager({ onChange: () => this.forceUpdate() });

  private schema: yup.ObjectSchema<ILearningModalValues>;

  constructor(props: ILearningModalProps) {
    super(props);

    const { t } = props;

    this.schema = yup.object<ILearningModalValues>({
      title: yup
        .string()
        .trim()
        .required(t('learning.modal.fields.title.required'))
        .max(
          maxLengthTitle,
          t('learning.modal.fields.title.maxLength', { count: maxLengthTitle })
        ),
      description: yup
        .string()
        .required(t('learning.modal.fields.description.required'))
        .max(
          maxLengthDescription,
          t('learning.modal.fields.description.maxLength', {
            count: maxLengthDescription
          })
        ),
      attachments: yup.object<IAttachmentUpdates>()
    });

    this.state = {
      submitPromise: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  close(learning: ILearning | null) {
    this.setState({ error: null }, () => {
      this.dss.clearUploads();
      this.props.onClose(learning);
    });
  }

  handleClose = () => {
    // "disable" close if request is active
    if (this.state.submitPromise === null) {
      this.close(null);
    }
  };

  handleSubmit = async (values: ILearningModalValues) => {
    const { t, engagementGuid, learning } = this.props;
    const isNew = typeof learning === 'undefined';
    const promise = isNew
      ? ApiService.createLearning(engagementGuid, values)
      : ApiService.updateLearning(
          engagementGuid,
          learning!.learningGuid,
          values
        );

    // store promise for button's loading animation, clear error
    this.setState({ submitPromise: promise, error: null });

    try {
      const { data: updatedLearning } = await promise;
      const { title } = updatedLearning;

      // success toast
      pushToast({
        type: 'success',
        title: t(
          isNew
            ? 'learning.modal.new.success.title'
            : 'learning.modal.edit.success.title'
        ),
        content: (
          <Trans
            i18nKey={
              isNew
                ? 'learning.modal.new.success.content'
                : 'learning.modal.edit.success.content'
            }
          >
            <b>{{ title }}</b> has been successfully saved.
          </Trans>
        )
      });
      this.context.refreshUpdateDate?.(engagementGuid);

      // close with changes
      this.close(updatedLearning);
    } catch {
      this.setState({
        error: (
          <Message title={t('serverError.title')} type="error">
            {t('serverError.content')}
          </Message>
        )
      });
    } finally {
      if (this._isMounted) {
        this.setState({ submitPromise: null });
      }
    }
  };

  render() {
    const { t, learning, open, engagementGuid, engagementName } = this.props;
    const { submitPromise, error } = this.state;
    const isNew = typeof learning === 'undefined';

    return (
      <UploadModalWarning dss={this.dss} onClose={this.handleClose}>
        {({ dss, warning, onEscape, onCancel }) => (
          <Modal
            onClose={onEscape}
            open={open}
            title={t(
              isNew ? 'learning.modal.new.title' : 'learning.modal.edit.title'
            )}
          >
            {warning}
            {error}
            <div>
              <div className="learning-modal__form">
                <Formik
                  initialValues={{
                    title: learning?.title || '',
                    description: learning?.description || '',
                    attachments: {
                      addUploaded: [],
                      addExisting: [],
                      deleteAttachments: []
                    }
                  }}
                  onSubmit={this.handleSubmit}
                  validationSchema={this.schema}
                >
                  {formik => (
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                      <TextInput
                        {...formikFieldProps('title', formik)}
                        label={t('learning.modal.fields.title.label')}
                      />
                      <Textarea
                        {...formikFieldProps('description', formik)}
                        label={t('learning.modal.fields.description.label')}
                        maxLength={maxLengthDescription}
                      />
                      <ManageAttachments
                        attachments={learning?.attachments || []}
                        dss={dss}
                        engagementGuid={engagementGuid}
                        engagementName={
                          learning?.engagementDisplayNameShort || engagementName
                        }
                        multiple
                        onChange={updates =>
                          formik.setFieldValue('attachments', updates)
                        }
                        value={formik.values.attachments}
                      />
                      <ButtonGroup>
                        <Button
                          disabled={!formik.dirty}
                          loading={dss.getUploadingPromise() || submitPromise}
                          text={t(isNew ? 'Create' : 'Save')}
                          type="submit"
                          variant={EButtonVariant.PRIMARY}
                        />
                        <Button
                          disabled={
                            (dss.getUploadingPromise() || submitPromise) !==
                            null
                          }
                          onClick={onCancel}
                          text={t('Cancel')}
                        />
                      </ButtonGroup>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </Modal>
        )}
      </UploadModalWarning>
    );
  }
}

export default withTranslation()(LearningModal);
