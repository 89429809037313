import { TFunction } from 'i18next';
import { UserType } from 'interfaces';

const thirdParties = [
  UserType.ClientRepresentativeAppraiser,
  UserType.ClientRepresentativeAttorney,
  UserType.TaxAgencyRepresentative
];

export function formatThirdPartyVisibility(
  t: TFunction,
  visibleToUserTypes: number
) {
  return thirdParties
    .filter(userType => (userType & visibleToUserTypes) > 0)
    .map(userType => t(`userTypes.${userType}`, { context: 'plural' }))
    .join(', ');
}
