import { MentionsValue } from '@ryan/components';

import { IEngagementSummary, IUserSummary } from '../../../interfaces';
import * as FormEnums from '../../../utils/enums/FormEnums';
import * as FormikUtils from '../../../utils/forms';
import * as TaskModalInterfaces from './TaskModal.interfaces';

export const getInitialFormikValues: TaskModalInterfaces.IGetInitialFormikValues =
  () => ({
    assignedToUser: null,
    attachments: { addExisting: [], addUploaded: [], deleteAttachments: [] },
    comment: new MentionsValue(),
    description: '',
    dueDate: null,
    engagement: null,
    engagementMilestoneGuid: '',
    isCurrentUserWatching: true,
    title: ''
  });

export const getRenderAccountSelectProps: TaskModalInterfaces.IGetRenderAccountSelectProps =
  ({
    getTextToDisplay,
    isSelectAccountError,
    isSelectEngagementError,
    rootToText,
    selectedAccount,
    selectedEngagement,
    setSelectedAccount,
    setSelectedEngagement,
    setIsSelectAccount,
    setIsSelectAccountError,
    setIsSelectEngagementError
  }) => ({
    accountAutoCompleteProps: {
      feedback: getTextToDisplay(`${rootToText}.accountSelect.accountRequired`),
      invalid: isSelectAccountError,
      label: getTextToDisplay(`${rootToText}.accountSelect.selectAccount`),
      onChange: account => {
        setSelectedAccount(account);
        setSelectedEngagement(null);
        setIsSelectAccountError(false);
        setIsSelectEngagementError(false);
      },
      value: selectedAccount
    },
    accountSelectTitle: getTextToDisplay(
      `${rootToText}.selectAccountAndProject`
    ),
    engagementAutoCompleteProps: {
      accountGuid: selectedAccount?.accountGuid || null,
      disabled: !Boolean(selectedAccount),
      feedback: getTextToDisplay(
        `${rootToText}.accountSelect.engagementRequired`
      ),
      invalid: Boolean(selectedAccount) && isSelectEngagementError,
      label: getTextToDisplay(`${rootToText}.accountSelect.selectProject`),
      onChange: engagement => {
        setSelectedEngagement(engagement);
        setIsSelectEngagementError(false);
      },
      value: selectedEngagement
    },
    onSubmit: () => {
      if (!selectedAccount || !selectedEngagement) {
        if (!selectedAccount) {
          setIsSelectAccountError(true);
        }

        if (!selectedEngagement) {
          setIsSelectEngagementError(true);
        }
        return;
      } else {
        setIsSelectAccount(false);
      }
    }
  });

export const getValidationSchema: TaskModalInterfaces.IGetValidationSchema =
  getTextToDisplay => {
    const ROOT_TO_TEXT = 'task.modal.fields';

    return FormikUtils.yup.object({
      assignedToUser: FormikUtils.yup
        .object()
        .nullable()
        .required(
          getTextToDisplay(`${ROOT_TO_TEXT}.assignedToUserGuid.required`)
        ),
      comment: FormikUtils.yup.mixed().validateCommentLength(
        FormEnums.EFormValidation.MAC_COMMENT_LENGTH,
        getTextToDisplay(`${ROOT_TO_TEXT}.comment.maxLength`, {
          length: FormEnums.EFormValidation.MAC_COMMENT_LENGTH
        })
      ),
      description: FormikUtils.yup.string().max(
        FormEnums.EFormValidation.MAX_DESCRIPTION_LENGTH,
        getTextToDisplay(`${ROOT_TO_TEXT}.description.maxLength`, {
          length: FormEnums.EFormValidation.MAX_DESCRIPTION_LENGTH
        })
      ),
      dueDate: FormikUtils.yup
        .date()
        .nullable()
        .required(getTextToDisplay(`${ROOT_TO_TEXT}.dueDate.required`)),
      // .min(
      //   minDueDate(task),
      //   task
      //     ? t('task.modal.fields.dueDate.minCreated')
      //     : t('task.modal.fields.dueDate.minToday')
      // ),
      engagement: FormikUtils.yup
        .object()
        .nullable()
        .required(getTextToDisplay(`${ROOT_TO_TEXT}.engagementGuid.required`)),
      engagementMilestoneGuid: FormikUtils.yup.string(),
      title: FormikUtils.yup
        .string()
        .trim()
        .required(getTextToDisplay(`${ROOT_TO_TEXT}.title.required`))
        .max(
          FormEnums.EFormValidation.MAX_TITLE_LENGTH,
          getTextToDisplay(`${ROOT_TO_TEXT}.title.maxLength`, {
            length: FormEnums.EFormValidation.MAX_TITLE_LENGTH
          })
        ),
      isCurrentUserWatching: FormikUtils.yup.boolean()
    });
  };

export const mapFormValuesToCreateTask: TaskModalInterfaces.IMapFormValuesToCreateTask =
  formValues => ({
    assignedToUserGuid: (formValues.assignedToUser as IUserSummary).userGuid,
    attachments: formValues.attachments,
    comment: formValues.comment.toJSON().text,
    description: formValues.description,
    dueDate: (formValues.dueDate as Date).toISOString(),
    engagementGuid: (formValues.engagement as IEngagementSummary)
      .engagementGuid,
    engagementMilestoneGuid: formValues.engagementMilestoneGuid || null,
    fromMilestones: formValues.engagementMilestoneGuid !== '',
    isCurrentUserWatching: formValues.isCurrentUserWatching,
    title: formValues.title
  });
