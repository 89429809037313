import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ENV from 'env';
import { getUserManager } from 'services/UserManager';
import UserService from 'services/UserService';

/**
 * Renders the logout page. Logs user out if user is authenticated.
 */
const Logout: React.FC = () => {
  const { t } = useTranslation();

  // use presence of user session to determine user authentication status
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [sessionHasExpired, setSessionHasExpired] = useState(false);

  // logout on mount if user is authenticated
  useEffect(() => {
    (async () => {
      const user = await getUserManager().getUser();

      if (user) {
        // remove local user session state only if user has expired as
        // expiration generally occurs when user is offline and trying to
        // logout via the auth server will result in an error when performing
        // the redirect; otherwise use standard signout redirect via `logout()`
        // (this will also clear local user session state)
        // NOTE: tried a navigator.onLine check before logging from auth server
        // and still seemed to have trouble with network access from wake
        if (user.expired) {
          setSessionHasExpired(true);
          await UserService.removeUser();
        } else {
          await UserService.logout();
        }
      }

      setIsLoggedIn(false);
    })();
  }, []);

  return (
    <div>
      <p>
        {isLoggedIn ? (
          t('logout.inProgress')
        ) : (
          <>
            {t(sessionHasExpired ? 'logout.expired' : 'logout.success')}{' '}
            <a className="ry-link" href="/">
              {t(
                'logout.logBackIn',
                sessionHasExpired
                  ? { context: 'expired', ryanPlatform: ENV.RYAN_PLATFORM }
                  : {}
              )}
            </a>
          </>
        )}
      </p>
    </div>
  );
};

export default Logout;
