const getHeaderForNURStatus = (
  groupByStatusForTable: string
): {
  headerBackgroundColor: string;
  headerTextColor: string;
  headerText: string;
} => {
  switch (groupByStatusForTable) {
    case 'Pending':
      return {
        headerBackgroundColor: '#f8f8fa',
        headerTextColor: '#2b2c36',
        headerText: 'Pending'
      };
    case 'Action Required':
      return {
        headerBackgroundColor: 'rgba(204, 41, 54, 0.05)',
        headerTextColor: '#cc2936',
        headerText: 'Action Required'
      };
    case 'Completed':
    default:
      return {
        headerBackgroundColor: 'rgba(33, 126, 56, 0.05)',
        headerTextColor: '#217e38',
        headerText: 'Approved'
      };
  }
};

export default getHeaderForNURStatus;
