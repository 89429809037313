import {
  ActivityType,
  ContractFileStatus,
  IActivity,
  IContractSummary,
  Modified
} from 'interfaces';
import getContractFileName from 'utils/getContractFileName';

import { TFunction } from 'i18next';
import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';

export const ContractActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    user,
    showAccountName,
    showEngagementName,
    isNotification,
    onCloseNotificationDrawer
  } = props;

  /**
   * To handle updates to the computed contract name, we do some
   * mutation to the modifiedInformation.
   */
  const activity = transformModifiedContractName(t, props.activity);
  const { accountGuid, createdBy, createdByName, engagementGuid } = activity;
  const entitySummary = activity.snapShot.entitySummary as IContractSummary;
  const title = getContractFileName(t, entitySummary);
  const by = `by-${user.userGuid === createdBy ? 'you' : 'x'}`;

  switch (activity.activityTypeId) {
    /**
     * Available (posted for Ryan users)
     */
    case ActivityType.ContractAvailable:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.contract.viewContracts')}
          icon="contract-search"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.contract.available.title')}
          to={`/app/data-and-files/contracts${
            accountGuid && engagementGuid
              ? `#accountGuid=${accountGuid}&engagementGuid=${engagementGuid}`
              : ''
          }`}
        >
          {t('activity.contract.available.content')}
        </AbstractActivity>
      );

    /**
     * Edited
     */
    case ActivityType.ContractModified:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.contract.viewContracts')}
          icon="contract-pencil"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.contract.edited.title')}
          to={`/app/data-and-files/contracts${
            accountGuid && engagementGuid
              ? `#accountGuid=${accountGuid}&engagementGuid=${engagementGuid}`
              : ''
          }`}
        >
          <Trans i18nKey={`activity.contract.edited.${by}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'ContractName',
                label: t('Name'),
                type: 'string' as const
              },
              {
                key: 'Status',
                label: t('Status'),
                type: (value: ContractFileStatus) =>
                  ({
                    [ContractFileStatus.Published]: t('Published'),
                    [ContractFileStatus.Unpublished]: t('Unpublished')
                  }[value])
              },
              {
                key: 'ExecutedDate',
                label: t('Executed Date'),
                type: 'date' as const
              }
            ]}
          />
        </AbstractActivity>
      );

    /**
     * Published (posted for Client users)
     */
    case ActivityType.ContractPublished:
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('activity.contract.viewContracts')}
          icon="contract-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.contract.published.title')}
          to={`/app/data-and-files/contracts${
            accountGuid ? `?account=${accountGuid}` : ''
          }`}
        >
          <Trans i18nKey="activity.contract.published.content">
            <b />
            {{ title }}
          </Trans>{' '}
        </AbstractActivity>
      );

    default:
      return <UnrecognizedActivity {...props} />;
  }
};

function transformModifiedContractName(
  t: TFunction,
  activity: IActivity
): IActivity {
  const { modifiedInformation, entitySummary } = activity.snapShot;
  if (modifiedInformation !== null && modifiedInformation.length) {
    let modifiedType: Modified | undefined;
    let modifiedName: Modified | undefined;

    for (let i = 0; i < modifiedInformation.length; i++) {
      switch (modifiedInformation[i].key) {
        case 'Type':
          modifiedType = modifiedInformation[i];
          break;
        case 'Name':
          modifiedName = modifiedInformation[i];
          break;
      }
    }

    if (modifiedType || modifiedName) {
      const { name: currentName, typeId: currentTypeId } =
        entitySummary as IContractSummary;

      return {
        ...activity,
        snapShot: {
          ...activity.snapShot,
          modifiedInformation: [
            {
              key: 'ContractName',
              oldValue: getContractFileName(t, {
                typeId: modifiedType ? modifiedType.oldValue : currentTypeId,
                name: modifiedName ? modifiedName.oldValue : currentName
              }),
              newValue: getContractFileName(t, {
                typeId: modifiedType ? modifiedType.newValue : currentTypeId,
                name: modifiedName ? modifiedName.newValue : currentName
              })
            },
            ...modifiedInformation
          ]
        }
      };
    }
  }

  return activity;
}
