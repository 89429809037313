import { NewUserRequestStatusEnums } from '../../../../../utils/enums/NewUserRequestStatusEnums';
import { OverflowMenuIconEnums } from '../EnumsOverflowMenu';
import {
  IGetEditUserAccessOptions,
  IGetOverflowOptionForNonRyanUser,
  IGetOverflowOptionsForRyanUser
} from '../IOverflowMenu';
import { TOverflowOptionAttributes } from '../TOverflowMenu';

const overflowOptionMapper = {
  editUserAccess: (onClickCallback: () => void) => {
    return {
      icon: OverflowMenuIconEnums.USER_PENCIL,
      label: 'manageTeam.overflowMenu.editUserAccess',
      onClick: onClickCallback
    };
  },
  grantUserAccess: (onClickCallback: () => void): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.USER_PENCIL,
      label: 'manageTeam.overflowMenu.grantUserAccess',
      onClick: onClickCallback
    };
  },
  hideFromClient: (onClickCallback: () => void): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.HIDE,
      label: 'manageTeam.overflowMenu.hideFromClient',
      onClick: onClickCallback
    };
  },
  requestUserAccess: (
    onClickCallback: () => void
  ): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.USER,
      label: 'manageTeam.overflowMenu.requestUserAccess',
      onClick: onClickCallback
    };
  },
  removeFromProject: (
    onClickCallback: () => void
  ): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.TRASH,
      label: 'manageTeam.overflowMenu.removeFromProject',
      negative: true,
      onClick: onClickCallback
    };
  },
  showToClient: (onClickCallback: () => void): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.SHOW,
      label: 'manageTeam.overflowMenu.showToClient',
      onClick: onClickCallback
    };
  },
  userAccessPending: (
    onClickCallback: () => void
  ): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.USER_CLOCK,
      label: 'manageTeam.overflowMenu.userAccessPending',
      onClick: onClickCallback
    };
  },
  userAccessDenied: (
    onClickCallback: () => void
  ): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.USER_DENY,
      label: 'manageTeam.overflowMenu.userAccessDenied',
      onClick: onClickCallback
    };
  },
  viewProfile: (onClickCallback: () => void): TOverflowOptionAttributes => {
    return {
      icon: OverflowMenuIconEnums.USER_CIRCLE,
      label: 'manageTeam.overflowMenu.viewProfile',
      onClick: onClickCallback
    };
  }
};

export const getEditUserAccessOptions: IGetEditUserAccessOptions = (
  onClickCallback,
  textToDisplay
) => {
  const overflowMenuOption =
    overflowOptionMapper.editUserAccess(onClickCallback);

  return {
    ...overflowMenuOption,
    label: textToDisplay(overflowMenuOption.label)
  };
};

export const getOverflowOptionForNonRyanUser: IGetOverflowOptionForNonRyanUser =
  ({
    editUserAccessCallback,
    isActiveUserWithEditAccess,
    isActiveUserWithNewUserApprovalAccess,
    isDeferredActivation,
    newUserRequestStatus,
    openModalCallback,
    textToDisplay,
    viewUserProfileCallback
  }) => {
    let overflowMenuOption: TOverflowOptionAttributes = {
      icon: '',
      label: '',
      onClick: undefined
    };

    switch (newUserRequestStatus) {
      case NewUserRequestStatusEnums.APPROVED:
        if (isActiveUserWithEditAccess) {
          overflowMenuOption = overflowOptionMapper.editUserAccess(
            editUserAccessCallback
          );
        } else if (isDeferredActivation) {
          overflowMenuOption =
            overflowOptionMapper.userAccessPending(openModalCallback);
        } else if (viewUserProfileCallback) {
          overflowMenuOption = overflowOptionMapper.viewProfile(
            viewUserProfileCallback
          );
        }
        break;
      case NewUserRequestStatusEnums.DENIED:
        overflowMenuOption =
          overflowOptionMapper.userAccessDenied(openModalCallback);
        break;
      case NewUserRequestStatusEnums.NULL:
        overflowMenuOption = isActiveUserWithNewUserApprovalAccess
          ? overflowOptionMapper.grantUserAccess(openModalCallback)
          : overflowOptionMapper.requestUserAccess(openModalCallback);
        break;
      case NewUserRequestStatusEnums.PENDING:
        overflowMenuOption = isActiveUserWithNewUserApprovalAccess
          ? overflowOptionMapper.grantUserAccess(openModalCallback)
          : overflowOptionMapper.userAccessPending(openModalCallback);
        break;
    }

    const { icon, label, onClick } = overflowMenuOption;

    return icon && label && onClick
      ? {
          icon,
          label: textToDisplay(label),
          onClick
        }
      : null;
  };

export const getOverflowOptionsForRyanUser: IGetOverflowOptionsForRyanUser = ({
  isUserAssociationEditable,
  isUserBigFour,
  isGhostingDisabled,
  isUserGhosted,
  isActiveUserBigFourOrExecutiveAssistant,
  openModalCallback,
  textToDisplay,
  toggleGhostingCallback
}) => {
  const overflowOptions: TOverflowOptionAttributes[] = [];

  if (!isUserBigFour && !isGhostingDisabled) {
    const overflowMenuOption = isUserGhosted
      ? overflowOptionMapper.showToClient(toggleGhostingCallback)
      : overflowOptionMapper.hideFromClient(toggleGhostingCallback);

    overflowOptions.push({
      ...overflowMenuOption,
      label: textToDisplay(overflowMenuOption.label)
    });
  }

  if (
    (isActiveUserBigFourOrExecutiveAssistant && !isUserBigFour) ||
    isUserAssociationEditable
  ) {
    const overflowMenuOption =
      overflowOptionMapper.removeFromProject(openModalCallback);

    overflowOptions.push({
      ...overflowMenuOption,
      label: textToDisplay(overflowMenuOption.label)
    });
  }

  return overflowOptions;
};
