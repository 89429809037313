export enum UserType {
  Client = 1 << 1, //2
  ClientRepresentativeAppraiser = 1 << 3, //8
  ClientRepresentativeAttorney = 1 << 4, //16
  Ryan = 1 << 0, //1
  SystemUser = 1 << 2, //4
  TaxAgencyRepresentative = 1 << 5, //32

  ThirdParty = UserType.ClientRepresentativeAppraiser |
    UserType.ClientRepresentativeAttorney |
    UserType.TaxAgencyRepresentative
}
