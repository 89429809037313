import { useCallback, useMemo, useState } from 'react';

export interface TableQuery {
  searchQuery: string;
  sorted: {
    id: string;
    desc: boolean;
  } | null;
  filtered: { [key: string]: any };
  page: number;
  pageSize: number;
}

export type TableQueryHookResponse = {
  query: TableQuery;
  onFilterChange: (filtered: TableQuery['filtered']) => void;
  onPageChange: (
    page: TableQuery['page'],
    pageSize: TableQuery['pageSize']
  ) => void;
  onSearchChange: (searchQuery: TableQuery['searchQuery']) => void;
  onSortChange: (sorted: TableQuery['sorted']) => void;
  update: (updates: Partial<TableQuery>) => void;
};

const defaultState: TableQuery = {
  searchQuery: '',
  sorted: null,
  filtered: {},
  page: 1,
  pageSize: 10
};

function useTableQuery(
  initialState: Partial<TableQuery>
): TableQueryHookResponse {
  const [query, setQuery] = useState({
    ...defaultState,
    ...initialState
  });

  const update: TableQueryHookResponse['update'] = useCallback(updates => {
    setQuery(query => ({ ...query, ...updates }));
  }, []);

  const onFilterChange: TableQueryHookResponse['onFilterChange'] = useCallback(
    filtered => {
      update({ filtered, page: 1 });
    },
    [update]
  );

  const onPageChange: TableQueryHookResponse['onPageChange'] = useCallback(
    (page, pageSize) => {
      update({ page, pageSize });
    },
    [update]
  );

  const onSearchChange: TableQueryHookResponse['onSearchChange'] = useCallback(
    searchQuery => {
      update({ searchQuery, page: 1 });
    },
    [update]
  );

  const onSortChange: TableQueryHookResponse['onSortChange'] = useCallback(
    sorted => {
      update({ sorted });
    },
    [update]
  );

  const hookResponse = useMemo<TableQueryHookResponse>(
    () => ({
      query,
      onFilterChange,
      onPageChange,
      onSearchChange,
      onSortChange,
      update
    }),
    [query, onFilterChange, onPageChange, onSearchChange, onSortChange, update]
  );

  return hookResponse;
}

export default useTableQuery;
