import camelCase from 'lodash/camelCase';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { withUser } from '../../contexts/UserContext';
import { TDateRange } from '../../types';
import { DataRequestTypeGuidEnum } from '../../utils/enums/DataRequestsEnums';
import { formatRecords } from '../../utils/formatRecords';
import Attachments from '../FileAttachments/Attachments';
import { IDataRequestFieldsProps } from './utils';

import './DataRequestFields.scss';

export const DataRequestFields: IDataRequestFieldsProps = ({
  activeView,
  dataRequest,
  handleDateRangesModalOpen,
  handleDocumentTypesModalOpen,
  handleJurisdictionsModalOpen,
  handleSiteClassesModalOpen,
  handlePropertyTaxDocumentTypesModalOpen
}) => {
  const { t: getTextToDisplay } = useTranslation();

  const isDocumentImagesType =
    dataRequest.dataRequestTypeGuid === DataRequestTypeGuidEnum.DOCUMENT_IMAGES;
  const isERPType =
    dataRequest.dataRequestTypeGuid === DataRequestTypeGuidEnum.ERP_DATA;
  const isSiteInformationType =
    dataRequest.dataRequestTypeGuid ===
    DataRequestTypeGuidEnum.SITE_INFORMATION;

  const showFieldDataSpecs =
    !isERPType &&
    dataRequest.dataRequestTypeGuid !==
      DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART;

  const showFieldJurisdictions =
    !isERPType &&
    !isDocumentImagesType &&
    dataRequest.dataRequestTypeGuid !==
      DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART &&
    !!(dataRequest.jurisdictions && dataRequest.jurisdictions.length > 0);

  return (
    <div className="data-request-content">
      <h4 className="ry-h4">{dataRequest.engagementDisplayNameShort}</h4>
      {!dataRequest.isAdHoc ? (
        <h3 className="ry-h3">{dataRequest.title}</h3>
      ) : null}
      {dataRequest.dataRequestTypeName && !dataRequest.isAdHoc && (
        <h4 className="data-request-content__data-request-type">
          {getTextToDisplay(
            `dataRequest.dataTypes.${camelCase(
              dataRequest.dataRequestType?.name.replaceAll(' ', '')
            )}`
          )}
        </h4>
      )}
      <div className="data-request-content__data-request-fields">
        {isDocumentImagesType && dataRequest.documentTypes && (
          <div className="data-request-content__data-request-fields__field">
            <div className="ry-label">
              {getTextToDisplay('dataRequest.dataFields.documentTypes')}
            </div>
            <div className="data-request-content__data-request-fields__field__value-array">
              {formatRecords(
                dataRequest.documentTypes.map(documentType =>
                  documentType.name === 'Other'
                    ? (dataRequest.otherDocumentType as string)
                    : documentType.name
                ),
                handleDocumentTypesModalOpen
              )}
            </div>
          </div>
        )}
        {isERPType && dataRequest.erpSystem && (
          <div className="data-request-content__data-request-fields__field">
            <div className="ry-label">
              {getTextToDisplay('dataRequest.dataFields.erpSystem')}
            </div>
            <div className="data-request-content__data-request-fields__field__value">
              {dataRequest.otherErpSystem || dataRequest.erpSystem.name || '-'}
            </div>
          </div>
        )}
        {isERPType && dataRequest.taxEngine && (
          <div className="data-request-content__data-request-fields__field">
            <div className="ry-label">
              {getTextToDisplay('dataRequest.dataFields.taxEngine')}
            </div>
            <div className="data-request-content__data-request-fields__field__value">
              {dataRequest.otherTaxEngine || dataRequest.taxEngine.name || '-'}
            </div>
          </div>
        )}
        {isSiteInformationType && dataRequest.propertyTaxSiteClasses && (
          <div className="data-request-content__data-request-fields__field">
            <div className="ry-label">
              {getTextToDisplay(
                'dataRequest.dataFields.propertyTaxSiteClasses'
              )}
            </div>
            <div className="data-request-content__data-request-fields__field__value-array">
              {formatRecords(
                dataRequest.propertyTaxSiteClasses.map(
                  siteClass => siteClass.siteClassName
                ),
                handleSiteClassesModalOpen
              )}
            </div>
          </div>
        )}
        {isSiteInformationType && dataRequest.propertyTaxDocumentTypes && (
          <div className="data-request-content__data-request-fields__field">
            <div className="ry-label">
              {getTextToDisplay('dataRequest.dataFields.documentTypes')}
            </div>
            <div className="data-request-content__data-request-fields__field__value-array">
              {formatRecords(
                dataRequest.propertyTaxDocumentTypes.map(
                  documentType => documentType.documentTypeName
                ),
                handlePropertyTaxDocumentTypesModalOpen
              )}
            </div>
          </div>
        )}
        {dataRequest.dateRange && (
          <div className="data-request-content__data-request-fields__field">
            <div className="ry-label">
              {getTextToDisplay('dataRequest.dataFields.dateRanges')}
            </div>
            <div className="data-request-content__data-request-fields__field__value-array">
              {formatRecords(
                dataRequest.dateRange as unknown as TDateRange[],
                handleDateRangesModalOpen
              )}
            </div>
          </div>
        )}
        {showFieldJurisdictions && dataRequest.jurisdictions && (
          <div className="data-request-content__data-request-fields__field">
            <div className="ry-label">
              {getTextToDisplay('dataRequest.dataFields.jurisdictions')}
            </div>
            <div className="data-request-content__data-request-fields__field__value-array">
              {formatRecords(
                dataRequest.jurisdictions.map(jurisdiction => {
                  if ('jurisdictionGuid' in jurisdiction) {
                    return getTextToDisplay(
                      `jurisdictions.${jurisdiction.jurisdictionGuid}.name`
                    ) as string;
                  }
                  return jurisdiction.name;
                }),
                handleJurisdictionsModalOpen
              )}
            </div>
          </div>
        )}
      </div>
      {showFieldDataSpecs && dataRequest.dataSpecs && (
        <>
          <label className="ry-label">{getTextToDisplay('Data Specs')}</label>
          <p className="white-space-pre-line">{dataRequest.dataSpecs}</p>
        </>
      )}
      {dataRequest.description && (
        <>
          <label className="ry-label">{getTextToDisplay('Description')}</label>
          <p className="white-space-pre-line">{dataRequest.description}</p>
        </>
      )}
      {isERPType && dataRequest.fileOrigin && (
        <>
          <label className="ry-label">
            {getTextToDisplay('dataRequest.dataFields.fileOrigin')}
          </label>
          <p className="white-space-pre-line">{dataRequest.fileOrigin}</p>
        </>
      )}
      {dataRequest.attachments?.length ? (
        <Attachments
          attachments={dataRequest.attachments}
          disableDownload={
            dataRequest.isUserGhosted || activeView.isExecutiveView
          }
          engagementGuid={dataRequest.engagementGuid}
        />
      ) : null}
    </div>
  );
};

export default withUser(DataRequestFields);
