import Empty from 'components/Empty';
import Table from 'components/Table';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import { useStateMounted } from 'hooks';
import {
  INewUserRequestProjectPreviewParams,
  INewUserRequestProjectPreviewResponse
} from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { ITableColumn } from '@ryan/components';

import './NewUserProjectPreviewTable.scss';

/**
 * Empty state of project preview table.
 */
const EmptyPreviewTable: React.FC<{
  children?: React.ReactNode;
  searchQuery?: string;
}> = ({ children, searchQuery }) => (
  <TableEmpty searchQuery={searchQuery}>
    <Empty icon="venn-diagram">{children}</Empty>
  </TableEmpty>
);

export type INewUserProjectPreviewTableProps = {
  queueItemGuid?: string;
  userGuid?: string;
};

const NewUserProjectPreviewTable: React.FC<
  INewUserProjectPreviewTableProps
> = ({ queueItemGuid, userGuid }) => {
  const { t } = useTranslation();
  const previewSourceRef = useRef<CancelTokenSource>();
  const [previewEngagements, setPreviewEngagements] = useState<
    INewUserRequestProjectPreviewResponse[]
  >([]);
  const [previewLoading, setPreviewLoading] = useStateMounted(false);
  const [previewPage, setPreviewPage] = useState(1);
  const [previewPageSize, setPreviewPageSize] = useState(5);
  const [previewSearch, setPreviewSearch] = useState('');
  const [previewSort, setPreviewSort] = useState({
    id: 'engagementNameLong',
    desc: false
  });
  const [previewTotal, setPreviewTotal] = useState(0);

  // preview table column configuration
  const previewColumns = useMemo(
    (): ITableColumn<INewUserRequestProjectPreviewResponse>[] => [
      {
        id: 'engagementNameLong',
        label: t('Project'),
        render: row => (
          <>
            <b>{row.engagementNameLong}</b>
            <br />
            <small>{row.accountName}</small>
          </>
        ),
        sortable: true
      },
      {
        id: 'engagementId',
        label: t('Code'),
        render: row => (
          <span className="ry-table__td__engagement-id">
            {row.engagementId}
          </span>
        ),
        sortable: true
      },
      {
        id: 'emFirstName',
        label: t('Engagement Manager'),
        render: row => `${row.emFirstName} ${row.emLastName}`,
        sortable: true
      },
      {
        id: 'status',
        label: t('Status'),
        render: row => {
          const isActive = row.status === 'Active';

          return (
            <div className="new-user-project-preview-table__pills">
              <div className={`pill ${isActive ? 'active' : 'inactive'}`}>
                {t(isActive ? 'Active' : 'Inactive')}
              </div>
            </div>
          );
        },
        sortable: true
      }
    ],
    [t]
  );

  /**
   * Fetches new preview results on click of new page or change in items per
   * page.
   */
  const handlePreviewPageChange = useCallback(
    (page: number, pageSize: number) => {
      setPreviewPage(page);
      setPreviewPageSize(pageSize);
    },
    []
  );

  /**
   * Fetches filtered results based on a search term.
   */
  const handlePreviewSearch = useCallback((searchTerm: string) => {
    setPreviewSearch(searchTerm);
    setPreviewPage(1);
  }, []);

  /**
   * Fetches sorted results.
   */
  const handlePreviewSort = useCallback(
    (sorted: { id: string; desc: boolean } | null) => {
      if (sorted) {
        setPreviewSort(sorted);
      }
    },
    []
  );

  /**
   * Fetches a list of engagements associated with the new user request.
   */
  const fetchPreview = useCallback(async () => {
    const requestPagedDataParams: INewUserRequestProjectPreviewParams = {
      itemsPerPage: previewPageSize,
      pageNumber: previewPage,
      queueItemGuid,
      searchTerm: previewSearch,
      sort: getSortParam(previewSort),
      userGuid
    };

    try {
      setPreviewLoading(true);
      const {
        data: { results, totalResults }
      } = await ApiService.getNewUserProjectPreview(
        requestPagedDataParams,
        previewSourceRef.current?.token
      );

      if (!results) {
        return;
      }
      setPreviewEngagements(results);
      setPreviewTotal(totalResults);

      // reset page number if no results
      if (results.length === 0 && previewPage !== 1) {
        setPreviewPage(1);
      }
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      setPreviewLoading(false);
    }
  }, [
    previewPage,
    previewPageSize,
    previewSearch,
    previewSort,
    queueItemGuid,
    userGuid,
    setPreviewLoading
  ]);

  // fetch engagement preview table
  useEffect(() => {
    // (re)initialize request cancel token
    previewSourceRef.current = ApiService.CancelToken.source();

    fetchPreview();

    // cancel any queued fetch or ongoing requests on unmount
    return () => {
      previewSourceRef.current?.cancel();
    };
  }, [fetchPreview]);

  return (
    <Table<INewUserRequestProjectPreviewResponse>
      className="new-user-project-preview-table"
      columns={previewColumns}
      data={previewEngagements}
      loading={previewLoading}
      onPageChange={handlePreviewPageChange}
      onSearchChange={handlePreviewSearch}
      onSortChange={handlePreviewSort}
      page={previewPage}
      pageSize={previewPageSize}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      renderEmpty={() => (
        <EmptyPreviewTable searchQuery={previewSearch}>
          {t('manageTeam.empty.projectPreview')}
        </EmptyPreviewTable>
      )}
      rowId="engagementId"
      searchQuery={previewSearch}
      sorted={previewSort}
      title={`${t('customView.preview.header')}${
        previewTotal ? ` (${previewTotal})` : ''
      }`}
      totalCount={previewTotal}
    />
  );
};

export default NewUserProjectPreviewTable;
