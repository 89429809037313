import { IEngagement } from 'interfaces';

import ENV from 'env';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant, Modal } from '@ryan/components';

import './ProjectDetailsConfirmPublishModal.scss';

export interface IProjectDetailsConfirmPublishModalProps {
  engagement: IEngagement;
  onClose: (shouldPublish?: boolean) => void;
}

const ProjectDetailsConfirmPublishModal: React.FC<
  IProjectDetailsConfirmPublishModalProps
> = ({ engagement, onClose }) => {
  const { t: textToDisplay } = useTranslation();
  const ROOT_TO_TEXT = 'modal.projectPublishConfirmationModal';
  return (
    <Modal
      className="update-savings-bucket-modal"
      onClose={onClose}
      open
      title={
        (
          <Trans i18nKey={`${ROOT_TO_TEXT}.title`}>
            <span className="update-savings-bucket-modal__title" />
            {{ engagementName: engagement.engagementDisplayNameShort }}
          </Trans>
        ) as unknown as string
      }
    >
      <p className="description">
        {textToDisplay(`${ROOT_TO_TEXT}.description`)}
      </p>
      <ButtonGroup>
        <Button
          onClick={() => {
            window.open(ENV.SALESFORCE_CONTACT_TAB_URL, '_blank');
          }}
          text={textToDisplay(`${ROOT_TO_TEXT}.goToSalesforce`)}
        />
        <Button
          onClick={() => onClose(true)}
          text={textToDisplay(`${ROOT_TO_TEXT}.proceedToPublish`)}
          variant={EButtonVariant.PRIMARY}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default ProjectDetailsConfirmPublishModal;
