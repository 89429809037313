import React from 'react';
import { pushToast } from '@ryan/components';
import { logger } from 'services/logger';
import i18n from 'utils/i18n';

/**
 * Pushes a generic toast message indicating a server error.
 *
 * @param error An optional Error or description of the error that occurred to
 *  be logged and displayed to the user.
 */
export default function pushServerErrorToast(error?: Error | string) {
  const errorMessage: string | undefined =
    error instanceof Error ? error.message : error;
  pushToast({
    content: (
      <>
        {i18n.t('serverError.content')}
        {errorMessage && (
          <code
            style={{
              display: 'block',
              fontSize: '.75rem',
              marginTop: '.5rem'
            }}
          >
            {errorMessage}
          </code>
        )}
      </>
    ),
    title: i18n.t('serverError.title'),
    type: 'error'
  });

  // send error to logging service
  if (errorMessage) {
    logger.error(`[server] ${errorMessage}`);
  }
}
