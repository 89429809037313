import {
  AccountAutocomplete,
  CountryAutocomplete,
  JurisdictionAutocomplete,
  PracticeAutocompleteHistoricalSavings,
  ServiceAutocompleteHistoricalSavings
} from 'components/AutocompleteAjax';
import Datepicker from 'components/Datepicker';
import Modal from 'components/Modal';
import { useUser } from 'contexts/UserContext';
import { useStateMounted } from 'hooks';
import {
  IHistoricalSavingsForm,
  IHistoricalSavingsFormValidated,
  IHistoricalSavingsSummary
} from 'interfaces';
import { IAccount } from 'interfaces';
import ApiService from 'services/ApiService';
import {
  formikAutocompleteAjaxProps,
  formikAutocompleteProps,
  formikDatepickerProps,
  formikFieldProps,
  formikNumberInputProps,
  useFormik,
  yup
} from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import ENV from 'env';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dropdown,
  EButtonSizes,
  EButtonVariant,
  Radio,
  RadioGroup,
  TextInput,
  pushToast
} from '@ryan/components';

import HistoricalSavingsEngagementName, {
  getHistoricalSavingsEngagementName
} from './HistoricalSavingsEngagementName';
import currencies from './currencies';

import './HistoricalSavingsModal.scss';

const r = { context: 'required' };

interface Props {
  savings?: IHistoricalSavingsSummary;
  onClose: (wasSubmitted?: boolean) => void;
  availableAccounts?: IAccount[];
}

const HistoricalSavingsModal: FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { getAccountByGuid } = useUser();
  const { savings, onClose } = props;
  const [savePromise, setSavePromise] = useStateMounted<Promise<any> | null>(
    null
  );
  const initialValues = useMemo((): IHistoricalSavingsForm => {
    // If editing an existing savings, set new initial values.
    if (savings) {
      return {
        account: null,
        accountStatus: savings.accountStatusId === 0 ? '0' : '1',
        inactiveAccountName: savings.inactiveAccountName,
        practiceName: savings.practiceName,
        serviceTypeName: savings.serviceTypeName,
        jField: savings.jurisdiction,
        cField: savings.country,
        asOfDate: savings.asOfDate,
        periodEndDate: savings.periodEndDate,
        periodStartDate: savings.periodStartDate,
        isoCurrencyCode: savings.isoCurrencyCode,
        totalSavings: savings.totalSavings.toString(),
        availableAccounts: props.availableAccounts
      };
    }

    // Default empty form
    return {
      account: null,
      accountStatus: '1',
      inactiveAccountName: '',
      practiceName: '',
      serviceTypeName: '',
      jField: null,
      cField: null,
      asOfDate: null,
      periodStartDate: null,
      periodEndDate: null,
      isoCurrencyCode: '',
      totalSavings: 0,
      availableAccounts: undefined
    };
  }, [savings, props.availableAccounts]);

  const formik = useFormik<IHistoricalSavingsForm>({
    validationSchema: yup.object({
      account: yup.object().nullable().required(t('Account', r)),
      accountStatus: yup.string(),
      inactiveAccountName: yup
        .string()
        .when('accountStatus', (value: string, schema: yup.StringSchema) =>
          value === '0'
            ? schema.required(
                t('savings.historical.inactiveAccountNameRequired')
              )
            : schema
        ),
      practiceName: yup.string().required(t('Practice', r)),
      serviceTypeName: yup.string().required(t('Service Type', r)),
      jField: yup.object().nullable().required(t('Jurisdiction', r)),
      cField: yup.object().nullable().required(t('Country', r)),
      asOfDate: yup.date().nullable().required(t('As of Date', r)),
      periodStartDate: yup
        .date()
        .nullable()
        .required(t('savings.historical.periodStartRequired'))
        .test(
          'max',
          t('savings.historical.periodStartMax'),
          function (start: Date) {
            const end: Date | null = this.resolve(yup.ref('periodEndDate'));
            return end === null || start <= end;
          }
        ),
      periodEndDate: yup
        .date()
        .nullable()
        .required(t('savings.historical.periodEndRequired'))
        .test(
          'min',
          t('savings.historical.periodEndMin'),
          function (end: Date) {
            const start: Date | null = this.resolve(yup.ref('periodStartDate'));
            return start === null || start <= end;
          }
        ),
      isoCurrencyCode: yup.string().required(t('Currency', r)),
      totalSavings: yup.number()
    }),
    initialValues,
    enableReinitialize: true,
    onSubmit: async values => {
      const { cField, jField, ...valuesPartial } = values;
      const validatedValues = {
        ...valuesPartial,
        country: cField!,
        jurisdiction: jField!
      } as IHistoricalSavingsFormValidated;
      const responsePromise = !savings
        ? ApiService.createHistoricalSavings(validatedValues)
        : ApiService.updateHistoricalSavings(
            savings.historicalSavingsSummaryGuid,
            validatedValues
          );
      try {
        setSavePromise(responsePromise);
        await responsePromise;
        pushToast({
          type: 'success',
          title: t('savings.historical.toastTitle'),
          content: t('savings.historical.toastContent', {
            name: getHistoricalSavingsEngagementName(values)
          })
        });
        onClose(true);
      } catch (e) {
        pushServerErrorToast();
        onClose();
      } finally {
        setSavePromise(null);
      }
    }
  });

  // set `formik.setFieldValue` in ref to prevent excessive calls to effect hook
  const setFieldValueRef = useRef(formik.setFieldValue);
  const setResetFormRef = useRef(formik.resetForm);
  const setFormValuesRef = useRef(formik.values);

  const isActive = formik.values.accountStatus === '1';

  // update current reference to `formik.resetForm`, `formik.values`
  useEffect(() => {
    setResetFormRef.current = formik.resetForm;
    setFormValuesRef.current = formik.values;
  }, [formik.resetForm, formik.values]);

  const resetFormikForm = useCallback(
    (matchAccount: Pick<IAccount, 'accountGuid' | 'name'>) => {
      /* NOTE: Test should be added to check for invocation of resetForm in future. Currently, existing downstream tests test the side effects of this
        invocation and serve as a proxy for a direct test. Due to the complicated nature of testing this, these indirect tests are favored at the moment.
    */
      setResetFormRef.current({
        values: {
          ...setFormValuesRef.current,
          account: matchAccount
        }
      });
    },
    []
  );

  // update current reference to `formik.setFieldValue`
  useEffect(() => {
    setFieldValueRef.current = formik.setFieldValue;
  }, [formik.setFieldValue]);

  // update account field value if editing a historical savings entry
  useEffect(() => {
    (async function () {
      if (savings) {
        const { account } = await getAccountByGuid(savings.accountGuid);
        if (account) {
          resetFormikForm({
            accountGuid: account.accountGuid,
            name: account.name
          });
        } else {
          pushServerErrorToast();
          onClose();
        }
      }
    })();
  }, [resetFormikForm, savings, getAccountByGuid, onClose]);

  return (
    <Modal
      className="historical-savings-modal"
      onClose={() => onClose()}
      open
      title={t(!savings ? 'Add Project Savings' : 'Edit Project Savings')}
    >
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <p>
          {t('savings.historical.description', {
            ryanPlatform: ENV.RYAN_PLATFORM
          })}
        </p>

        <h3 className="ry-h3">{t('Project Details')}</h3>

        {/* Account Status */}
        <RadioGroup
          {...formikFieldProps('accountStatus', formik)}
          label={t('Account Status')}
        >
          <Radio
            label={t('savings.historical.accountStatusActive')}
            value="1"
          />
          <Radio
            label={t('savings.historical.accountStatusInactive')}
            value="0"
          />
        </RadioGroup>

        {/* Inactive Account Name */}
        {!isActive && (
          <TextInput
            {...formikFieldProps('inactiveAccountName', formik)}
            helperText={t('savings.historical.inactiveAccountNameHelper')}
            label={t('savings.historical.inactiveAccountName')}
          />
        )}

        {/* Account */}
        <AccountAutocomplete
          // {...formikAutocompleteAjaxProps('account', formik)}
          {...formikAutocompleteAjaxProps(
            'account',
            formik,
            (name, formik, ...args) => {
              const [account] = args;
              formik.setFieldValue(
                name,
                account
                  ? {
                      accountGuid: account?.accountGuid,
                      name: account?.name
                    }
                  : null
              );
            }
          )}
          availableAccounts={props.availableAccounts}
          label={
            isActive ? t('Account') : t('savings.historical.activeAccount')
          }
          showRelevantAccounts
        />

        {/* Practice */}
        <PracticeAutocompleteHistoricalSavings
          {...formikAutocompleteProps('practiceName', formik)}
          helperText={t('savings.historical.practiceHelper')}
        />

        {/* Service Type */}
        <ServiceAutocompleteHistoricalSavings
          {...formikAutocompleteProps('serviceTypeName', formik)}
          helperText={t('savings.historical.serviceHelper')}
        />

        <div className="row">
          <div className="col-12 col-md-6">
            {/* Jurisdiction */}
            <JurisdictionAutocomplete
              {...formikAutocompleteAjaxProps('jField', formik)}
              label={t('Jurisdiction')}
            />
          </div>
          <div className="col-12 col-md-6">
            {/* Country */}
            <CountryAutocomplete
              {...formikAutocompleteAjaxProps('cField', formik)}
              label={t('Country')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {/* Period Start Date */}
            <Datepicker
              {...formikDatepickerProps('periodStartDate', formik)}
              helperText={t('savings.historical.periodStartHelper')}
              label={t('savings.historical.periodStart')}
              maxDate={formik.values.periodEndDate || undefined}
            />
          </div>
          <div className="col-12 col-md-6">
            {/* Perioud End Date */}
            <Datepicker
              {...formikDatepickerProps('periodEndDate', formik)}
              helperText={t('savings.historical.periodEndHelper')}
              label={t('savings.historical.periodEnd')}
              minDate={formik.values.periodStartDate || undefined}
            />
          </div>
        </div>

        {/* Engagement Name */}
        <div className="well">
          <label className="ry-label">{t('Project Display Name')}</label>
          <HistoricalSavingsEngagementName values={formik.values} />
        </div>

        <h3 className="ry-h3">{t('Savings')}</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            {/* Currency */}
            <Dropdown
              {...formikFieldProps('isoCurrencyCode', formik)}
              label={t('Currency')}
              options={[
                { label: `- ${t('Select')} -`, value: '' },
                ...currencies.map(({ isoCode }) => ({
                  label: `${isoCode} - ${t(`currency.${isoCode}`)}`,
                  value: isoCode
                }))
              ]}
            />
          </div>
          <div className="col-12 col-md-6">
            {/* As of Date */}
            <Datepicker
              {...formikDatepickerProps('asOfDate', formik)}
              label={t('As of Date')}
              maxDate={new Date()}
            />
          </div>
        </div>

        {/* Savings */}
        <TextInput
          {...formikNumberInputProps('totalSavings', formik)}
          helperText={t('savings.historical.savingsReceivedHelper')}
          label={t('Savings Received')}
        />

        <ButtonGroup>
          <Button
            disabled={!formik.dirty}
            loading={savePromise}
            size={EButtonSizes.LARGE}
            text={t(savings ? 'Update' : 'Add')}
            type="submit"
            variant={EButtonVariant.PRIMARY}
          />
          <Button
            disabled={Boolean(savePromise)}
            onClick={() => onClose()}
            size={EButtonSizes.LARGE}
            text={t('Cancel')}
            variant={EButtonVariant.SECONDARY}
          />
        </ButtonGroup>
      </form>
    </Modal>
  );
};

export default HistoricalSavingsModal;
