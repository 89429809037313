import { TWorklistTaskItem } from '../WorklistMine/WorklistMine.interfaces';
import { IMapAssignmentToVariousTaskModals } from './TaskModals.interfaces';

export const mapAssignmentToCompleteTask: IMapAssignmentToVariousTaskModals = ({
  assignedTo,
  description,
  dueDate,
  engagementGuid,
  isFollowing,
  queueItemGuid,
  statusId,
  title,
  ...rest
}) => {
  const { engagementMilestoneGuid } = rest as TWorklistTaskItem;

  return {
    assignedToUserGuid: assignedTo.userGuid,
    description,
    dueDate: new Date(dueDate),
    engagementGuid,
    engagementMilestoneGuid,
    isCurrentUserWatching: isFollowing,
    queueItemGuid,
    statusId,
    title
  };
};

export const mapAssignmentToDeleteTask: IMapAssignmentToVariousTaskModals = ({
  engagementGuid,
  queueItemGuid,
  title
}) => ({ engagementGuid, queueItemGuid, title });

export const mapAssignmentToEditTask: IMapAssignmentToVariousTaskModals = ({
  assignedTo,
  attachments,
  createdBy,
  description,
  dueDate,
  engagementDisplayNameShort,
  engagementGuid,
  isFollowing,
  queueItemGuid,
  statusId,
  title,
  ...rest
}) => {
  const { engagementMilestoneGuid } = rest as TWorklistTaskItem;

  return {
    assignedToUserGuid: assignedTo.userGuid,
    attachments,
    createdByUserType: createdBy.userType,
    description,
    dueDate: new Date(dueDate),
    engagementDisplayNameShort,
    engagementGuid,
    engagementMilestoneGuid,
    isCurrentUserWatching: isFollowing,
    queueItemGuid,
    statusId,
    title
  };
};

export const mapAssignmentToReasignTask: IMapAssignmentToVariousTaskModals = ({
  assignedTo,
  description,
  dueDate,
  engagementDisplayNameShort,
  engagementGuid,
  isFollowing,
  queueItemGuid,
  statusId,
  title,
  ...rest
}) => {
  const { engagementMilestoneGuid } = rest as TWorklistTaskItem;

  return {
    assignedToName: assignedTo.name,
    assignedToUserGuid: assignedTo.userGuid,
    description,
    dueDate: new Date(dueDate),
    engagementDisplayNameShort,
    engagementGuid,
    engagementMilestoneGuid,
    isCurrentUserWatching: isFollowing,
    queueItemGuid,
    statusId,
    title
  };
};
