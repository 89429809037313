import { ISummaryPerCurrency } from 'interfaces';
import { SavingsSummaryBucket } from 'routes/SavingsSummaryEntry/SavingsSummaryBucket';

import classnames from 'classnames';
import { TFunction } from 'i18next';
import React, { Component } from 'react';

import { SavingsSummaryEnums } from '../../utils/enums/SavingsSummaryEnums';
import BarChart from '../BarChart/BarChart';
import Currency from '../Currency/Currency';
import InfoTooltip from '../InfoTooltip';
import SavingsFilterType from './SavingsFilterType';

import './SavingsSummaryDataViz.scss';

function calculatePercentage(amount: number, total: number) {
  return parseFloat(((amount / total) * 100).toFixed(2));
}

interface ISavingsSummaryDataVizProps {
  t: TFunction;
  savingsSummary: ISummaryPerCurrency;
  filterType: SavingsFilterType;
}

export class SavingsSummaryDataViz extends Component<ISavingsSummaryDataVizProps> {
  isAsOfMode() {
    return this.props.filterType === SavingsFilterType.AsOf;
  }

  isChangesMode() {
    return this.props.filterType === SavingsFilterType.Range;
  }

  render() {
    const {
      t,
      savingsSummary: { currencyCode, totals }
    } = this.props;

    const potentialPercentage = calculatePercentage(
      totals.potential,
      totals.summary
    );

    const submittedPercentage = calculatePercentage(
      totals.submitted,
      totals.summary
    );

    const approvedPercentage = calculatePercentage(
      totals.approved,
      totals.summary
    );

    const receivedPercentage = calculatePercentage(
      totals.received,
      totals.summary
    );

    return (
      <div className="savings-summary-data-viz">
        {/* The TOTAL bar graph section */}
        <div className="savings-summary-data-viz__total">
          <p className="savings-summary-data-viz__label">{t('Total')} *</p>
          <div className="savings-summary-data-viz__total-row">
            <Currency
              className="ry-number-1"
              currencyCode={currencyCode}
              includeArrow={this.isChangesMode()}
              includeCents={false}
              value={totals.summary}
            />
          </div>

          {/* @todo "Segmented Progress Bar" could be abstracted */}
          <div
            className={classnames('bar-graph', {
              'bar-graph--increase': totals.summary > 0,
              'bar-graph--decrease': totals.summary < 0
            })}
          >
            {this.isAsOfMode() && (
              <BarChart
                barChartDataPoints={[
                  {
                    keyName: SavingsSummaryEnums.POTENTIAL,
                    style: {
                      backgroundColor: SavingsSummaryEnums.POTENTIAL_COLOR
                    },
                    value: totals.potential
                  },
                  {
                    keyName: SavingsSummaryEnums.SUBMITTED,
                    style: {
                      backgroundColor: SavingsSummaryEnums.SUBMITTED_COLOR
                    },
                    value: totals.submitted
                  },
                  {
                    keyName: SavingsSummaryEnums.APPROVED,
                    style: {
                      backgroundColor: SavingsSummaryEnums.APPROVED_COLOR
                    },
                    value: totals.approved
                  },
                  {
                    keyName: SavingsSummaryEnums.RECEIVED,
                    style: {
                      backgroundColor: SavingsSummaryEnums.RECEIVED_COLOR
                    },
                    value: totals.received
                  }
                ]}
              />
            )}
          </div>
          <div className="savings-summary-data-viz__footnote">
            <p className="savings-summary-data-viz__text">
              *{t('savingsSummaryWidget.mayIncludeEstimates')}
            </p>
            <InfoTooltip>{t('savings.tooltips.total')}</InfoTooltip>
          </div>
        </div>

        {this.renderBucket(
          SavingsSummaryBucket.Potential,
          t('savings.potential'),
          t('savings.tooltips.potential'),
          totals.potential,
          potentialPercentage,
          0
        )}

        {this.renderBucket(
          SavingsSummaryBucket.Submitted,
          t('savings.submitted'),
          t('savings.tooltips.submitted'),
          totals.submitted,
          submittedPercentage,
          potentialPercentage
        )}

        {this.renderBucket(
          SavingsSummaryBucket.Approved,
          t('savings.approved'),
          t('savings.tooltips.approved'),
          totals.approved,
          approvedPercentage,
          potentialPercentage + submittedPercentage
        )}

        {this.renderBucket(
          SavingsSummaryBucket.Received,
          t('savings.received'),
          t('savings.tooltips.received'),
          totals.received,
          receivedPercentage,
          potentialPercentage + submittedPercentage + approvedPercentage
        )}
      </div>
    );
  }

  renderBucket(
    bucket: SavingsSummaryBucket,
    label: string,
    tooltip: string,
    total: number,
    percentage: number,
    percentageOffset: number
  ) {
    const {
      savingsSummary: { currencyCode }
    } = this.props;

    const isAsOfMode = this.isAsOfMode();
    const isChangesMode = this.isChangesMode();

    return (
      <div className="savings-summary-data-viz__breakdown">
        <div className="savings-summary-data-viz__breakdown-header">
          {isAsOfMode && (
            <div
              className="savings-summary-data-viz__breakdown-marker"
              data-bucket={bucket}
            />
          )}
          <div className="savings-summary-data-viz__label">{label}</div>
        </div>
        <div className="savings-summary-data-viz__breakdown-amount-percent">
          <div className="ry-number-2">
            <Currency
              currencyCode={currencyCode}
              includeArrow={isChangesMode}
              includeCents={false}
              includeSymbol={false}
              value={total}
            />
          </div>
          {isAsOfMode && !isNaN(percentage) && (
            <p className="savings-summary-data-viz__text">{percentage}%</p>
          )}
          <InfoTooltip>{tooltip}</InfoTooltip>
        </div>
        <div
          className={classnames({
            'bar-graph': true,
            'bar-graph--changes-increase': isChangesMode && total > 0,
            'bar-graph--changes-decrease': isChangesMode && total < 0,
            'bar-graph--changes-neutral': isChangesMode && total === 0
          })}
        >
          {/* TODO: Identify how to ustilize BarChart component */}
          {isAsOfMode && (
            <div
              className="bar-graph__section"
              data-bucket={bucket}
              style={{ width: `${percentage}%`, left: `${percentageOffset}%` }}
            />
          )}
        </div>
      </div>
    );
  }
}
export default SavingsSummaryDataViz;
