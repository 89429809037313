import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import { useUser } from '../../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IEngagementSummary,
  IFolderTree,
  UserType
} from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import * as FolderValidationUtils from '../../../utils/folderValidation/folderValidation.utils';
import { EngagementAutocomplete } from '../../AutocompleteAjax';
import Modal from '../../Modal';
import SelectFolder from '../../SelectFolder/SelectFolder';
import { FileBatch } from '../utils/FileBatch';
import { RYAN_INTERNAL } from '../utils/FileDirectoryEnums';

interface IFileLinkModalProps {
  accountGuid: string;
  engagementGuid: string;
  files: FileBatch | null;
  loading: Promise<any> | null;
  onSubmit: (engagement: IEngagementSummary, folder: FolderSelection) => any;
  onCancel: () => void;
}

interface IFileLinkModalState {
  engagement: IEngagementSummary | null;
  folders: IFolderTree[] | null;
  folder: FolderSelection;
}

export const FileLinkModal: FunctionComponent<IFileLinkModalProps> = props => {
  const { accountGuid, engagementGuid, files, loading, onSubmit, onCancel } =
    props;
  const [selectFolderError, setSelectFolderError] = useState<string | null>(
    null
  );
  const [state, setState] = useState<IFileLinkModalState>({
    engagement: null,
    folders: null,
    folder: null
  });
  const { engagement, folders, folder } = state;
  const { t: getTextToDisplay } = useTranslation();
  const { isFeatureToggled } = useUser();

  const isInternalFilesFunctionalityVisible = isFeatureToggled(
    Feature.InternalFiles
  );

  // When an engagement is selected, load it's folders.
  function onEngagementsChange(engagement: IEngagementSummary | null) {
    setState({ engagement, folders: null, folder: null });
    if (engagement) {
      ApiService.getEngagementFolders(engagement.engagementGuid).then(
        ({ data: folders }) => {
          if (isInternalFilesFunctionalityVisible) {
            // if any selected file or folder is internal, display Ryan Internal folder and select it
            if (isAnyContentInternal) {
              const ryanInternalTree = folders.filter(
                f => f.folderName === RYAN_INTERNAL
              );
              setState({
                engagement,
                folders: ryanInternalTree,
                folder: ryanInternalTree[0]
              });
              // otherwise display engagement root folder
            } else {
              setState({ engagement, folders, folder: null });
            }
          } else {
            setState({ engagement, folders, folder: null });
          }
        }
      );
    }
  }

  function onFolderChange(folder: FolderSelection) {
    if (selectFolderError) {
      setSelectFolderError(null);
    }

    if (isInternalFilesFunctionalityVisible) {
      // if linking internal files prevent navigation to engagement root
      if (isAnyContentInternal && folder === null) {
        return;
      }
    }

    const errorPath = FolderValidationUtils.validateFolderInput(
      folder,
      state.folders
    ) as string;

    if (Boolean(errorPath)) {
      setSelectFolderError(getTextToDisplay(errorPath));
    }

    setState(state => ({ ...state, folder }));
  }

  async function onSave() {
    if (engagement) {
      const folderNameError = await onSubmit(engagement, folder);

      if (folderNameError) {
        setSelectFolderError(folderNameError);
      }
    }
  }

  const isAnyContentInternal =
    files?.files.some(file => file.visibleToUserTypes === UserType.Ryan) ||
    files?.folders.some(
      folder => folder.folderVisibleToUserTypes === UserType.Ryan
    );

  if (files) {
    let activeFileCount = files.files.filter(
      file => !Boolean(file.archiveDate)
    ).length;
    files.folders.forEach(
      ({ archiveDate, archivedFileCount, totalFileCount }) => {
        if (!Boolean(archiveDate)) {
          activeFileCount += totalFileCount - (archivedFileCount || 0);
        }
      }
    );
    const isContentInternal =
      files.files.every(file => file.visibleToUserTypes === UserType.Ryan) &&
      files.folders.every(
        folder => folder.folderVisibleToUserTypes === UserType.Ryan
      );

    const foldersWithoutInternal = (folders || []).filter(
      folder => folder.folderName !== RYAN_INTERNAL
    );

    return (
      <Modal
        onClose={onCancel}
        open
        title={getTextToDisplay('file.linkModal.title', {
          count: activeFileCount
        })}
      >
        <p>{getTextToDisplay('file.linkModal.onlyActiveFiles')}</p>
        <form autoComplete="off">
          <EngagementAutocomplete
            accountGuid={accountGuid}
            label={getTextToDisplay('file.linkModal.projectLabel')}
            onChange={onEngagementsChange}
            transformResponse={engagements =>
              engagements.filter(e => e.engagementGuid !== engagementGuid)
            }
            value={engagement}
          />
          {
            <SelectFolder
              disabled={!folders}
              feedback={selectFolderError}
              folders={
                !isInternalFilesFunctionalityVisible
                  ? foldersWithoutInternal
                  : folders || []
              }
              invalid={selectFolderError !== null}
              isInternalOnly={isContentInternal}
              moveFileCount={files.filesCount}
              onChange={onFolderChange}
              rootName={engagement ? engagement.engagementDisplayNameShort : ''}
              value={folder}
            />
          }
          <ButtonGroup>
            <Button
              disabled={selectFolderError !== null || engagement === null}
              loading={loading}
              onClick={onSave}
              text={getTextToDisplay('file.linkModal.submit', {
                count: files.filesCount
              })}
              variant={EButtonVariant.PRIMARY}
            />
            <Button
              disabled={loading !== null}
              onClick={onCancel}
              text={getTextToDisplay('Cancel')}
              variant={EButtonVariant.SECONDARY}
            />
          </ButtonGroup>
        </form>
      </Modal>
    );
  }

  return null;
};

export default FileLinkModal;
