import { IReportBuilderFormValues } from 'routes/Projects/Reporting/schema';

import { FormikProps } from 'formik';

export enum ReportSections {
  CoverPage,
  Timeframe,
  Audience,
  Projects,
  Content
}

export enum ReportSectionStatus {
  Normal,
  Error,
  Complete
}

export interface IReportBuilderSection {
  selectedPanel: ReportSections | null;
  onToggle: (section: ReportSections, formik: any) => void;
  onNext?: (skip: number, formik: any) => void;
  formik: FormikProps<IReportBuilderFormValues>;
}
