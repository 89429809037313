import { useUser } from 'contexts/UserContext';
import { IActivity } from 'interfaces';
import { formatDate } from 'utils/formatDate';
import { getLocale } from 'utils/i18n';

import { formatDistance, parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Activity from '../Activity';
import Empty from '../Empty';

import './ActivityFeed.scss';

export const formatRelative = (date: Date, language = 'en-US') =>
  formatDistance(date, new Date(), {
    addSuffix: true,
    locale: getLocale(language)
  })
    // remove 'about'
    .replace(/^about /, '');

export interface IActivityFeedProps {
  activities: IActivity[];
  showAccountName?: boolean;
  showEngagementName?: boolean;
}

const ActivityFeed: FunctionComponent<IActivityFeedProps> = ({
  activities,
  showAccountName,
  showEngagementName
}) => {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const {
    user: { profile }
  } = useUser();

  useEffect(() => {
    const removeURLAnchor = () => {
      history.replace(history.location.pathname, { shallow: true });
    };

    removeURLAnchor();
  }, [history]);

  return activities.length === 0 ? (
    <Empty icon="time">{t('activity.none')}</Empty>
  ) : (
    <table className="activity-feed">
      <tbody>
        {activities.map(activity => {
          const date = parseISO(activity.createDate);
          return (
            <tr key={activity.activityFeedItemGuid}>
              <td className="activity-feed__dt">
                <div className="activity-feed__dt-relative">
                  {formatRelative(date, i18n.language)}
                </div>
                <div>{formatDate(date.toISOString())}</div>
                <div>
                  {date.toLocaleTimeString(undefined, {
                    timeStyle: 'short'
                  } as any)}
                </div>
              </td>
              <td className="activity-feed__activity">
                <Activity
                  activity={activity}
                  isNotification={false}
                  showAccountName={showAccountName}
                  showEngagementName={showEngagementName}
                  t={t}
                  user={profile}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ActivityFeed;
