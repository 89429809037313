import { useAppReadOnly } from 'contexts/UserContext';
import { DSSDocument, IAttachmentUpdates, IFile } from 'interfaces';
import { useDSSManager } from 'utils/DSS';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import Attachments from './Attachments';
import FileAttachModal from './FileAttachModal';

import './FileAttachments.scss';

export interface IFileAttachmentsProps {
  // a list of existing attachments to display
  attachments?: IFile[];

  // attach files button text
  buttonText?: string;

  // adds a download link for files, only applies to IFile file types
  allowFileDownload?: boolean;

  // an engagement to search for files within - required attaching existing
  // files
  engagementGuid: string | null;

  // an engagement name for use when attaching existing files - required for
  // attaching existing files
  engagementName?: string;

  // a title for the attach files modal
  modalTitle?: string;

  // called update to attachments list
  onAttachmentUpdate?: (attachments: IAttachmentUpdates) => void;
}

/**
 * Displays a list of file attachments and can optionally support adding new
 * attachments or removing existing attachments from an item.
 */
const FileAttachments: React.FC<IFileAttachmentsProps> = ({
  allowFileDownload,
  attachments: alreadyAttachedFiles,
  buttonText,
  engagementGuid,
  engagementName,
  modalTitle,
  onAttachmentUpdate
}) => {
  const { t } = useTranslation();
  const isAppReadOnly = useAppReadOnly();

  /**
   * Hook gives us an instance, and forces re-render when DSSManager is updated.
   * Like how class components use
   * `new DSSManager({ onChange: () => this.forceUpdate() })`
   *
   * @todo DSSManager is not idomatic and should be refactored.
   */
  const dss = useDSSManager();

  // maintain attachments data to be returned to parent components
  const [currentAttachments, setCurrentAttachments] =
    useState<IAttachmentUpdates>({
      addUploaded: [],
      addExisting: [],
      deleteAttachments: []
    });

  // list of document guids to exclude from file search results
  const [excludeList, setExcludeList] = useState<string[]>([]);

  // controls visibility of modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  // list of existing files to attach in `IFile` format for rendering
  const [newExisting, setNewExisting] = useState<IFile[]>([]);

  // list of attachments to display to end user; hide existing files marked
  // for deletion
  const attachmentsToDisplay: (DSSDocument | IFile)[] = useMemo(
    () =>
      // filter out duplicates and attachments to be deleted from the list of
      // all attachments
      [
        ...(alreadyAttachedFiles || []),
        ...currentAttachments.addUploaded,
        ...newExisting
      ].reduce<(DSSDocument | IFile)[]>(
        (toDisplay, file) =>
          currentAttachments.deleteAttachments.indexOf(file.documentGuid) ===
            -1 &&
          !toDisplay.some(
            fileToDisplay => fileToDisplay.documentGuid === file.documentGuid
          )
            ? [...toDisplay, file]
            : toDisplay,
        []
      ),
    [alreadyAttachedFiles, currentAttachments, newExisting]
  );

  // allow updating of attachments if associated with an engagement
  const withEditing = !!(engagementGuid && engagementName);

  /**
   * Handles display of attachments from file attach modal. Attached files
   * may be newly uploaded or selected from a list of already uploaded files.
   */
  const onAttach = (
    attachments: IAttachmentUpdates,
    existingFilesToAttach: IFile[]
  ) => {
    // update reference list of existing files, don't add a file that is already
    // present in list
    setNewExisting(prevNewExisting => [
      ...prevNewExisting,
      ...existingFilesToAttach.filter(
        file =>
          !prevNewExisting.some(
            alreadyExists => alreadyExists.documentGuid === file.documentGuid
          )
      )
    ]);
    setCurrentAttachments(prevAttachments => {
      const {
        addUploaded: prevAddUploaded,
        addExisting: prevAddExisting,
        deleteAttachments: prevDeleteAttachments
      } = prevAttachments;

      // only add existing files that are not already attached or marked to be
      // attached
      const newAddExisting = existingFilesToAttach
        .filter(
          newFile =>
            (!alreadyAttachedFiles ||
              !alreadyAttachedFiles.some(
                alreadyAttached =>
                  alreadyAttached.documentGuid === newFile.documentGuid
              )) &&
            prevAddExisting.indexOf(newFile.documentGuid) === -1
        )
        .map(file => file.documentGuid);

      // if existing file to attach has previously been marked for deletion,
      // then unmark
      const deleteAttachments = prevDeleteAttachments.filter(
        documentGuid =>
          !existingFilesToAttach.some(
            file => file.documentGuid === documentGuid
          )
      );

      return {
        ...prevAttachments,
        addUploaded: [...prevAddUploaded, ...attachments.addUploaded],
        addExisting: [...prevAddExisting, ...newAddExisting],
        deleteAttachments
      };
    });
  };

  /**
   * Removes selected file from list of attachments.
   */
  const onDelete = (fileToDelete: DSSDocument | IFile) => {
    const { documentGuid: guidToDelete } = fileToDelete;

    setCurrentAttachments(prevAttachments => {
      const { addExisting, addUploaded, deleteAttachments } = prevAttachments;

      // if file already attached and not already marked for deletion, mark file
      // to be deleted
      if (
        deleteAttachments.indexOf(guidToDelete) === -1 &&
        Array.isArray(alreadyAttachedFiles) &&
        alreadyAttachedFiles.some(file => file.documentGuid === guidToDelete)
      ) {
        deleteAttachments.push(guidToDelete);
      }

      return {
        ...prevAttachments,
        addUploaded: addUploaded.filter(
          file => file.documentGuid !== guidToDelete
        ),
        addExisting: addExisting.filter(
          documentGuid => documentGuid !== guidToDelete
        ),
        deleteAttachments
      };
    });
  };

  /**
   * Toggles display of file attach modal.
   */
  const toggleFileAttachModal = () => {
    dss.clearUploads();
    setModalIsOpen(!modalIsOpen);
  };

  // call `onAttachmentUpdate` prop method on `attachment` update to allow
  // parent to respond to attachment changes
  useEffect(() => {
    const { addExisting, deleteAttachments } = currentAttachments;

    // align visual list of existing files to be attached with `attachments`;
    // updates attachments list on delete of existing attachment
    setNewExisting(prevNewExisting =>
      prevNewExisting.filter(
        file => addExisting.indexOf(file.documentGuid) > -1
      )
    );

    // update list of document guids to exclude from file search results
    setExcludeList([
      // exclude already attached files that are not marked for deletion
      ...(Array.isArray(alreadyAttachedFiles)
        ? alreadyAttachedFiles
            .filter(file => deleteAttachments.indexOf(file.documentGuid) === -1)
            .map(file => file.documentGuid)
        : []),

      // exclude files already marked to be attached
      ...addExisting
    ]);

    if (typeof onAttachmentUpdate === 'function') {
      onAttachmentUpdate(currentAttachments);
    }
  }, [alreadyAttachedFiles, currentAttachments, onAttachmentUpdate]);

  return (
    <div className="file-attachments-container">
      {attachmentsToDisplay.length > 0 && (
        <Attachments
          attachments={attachmentsToDisplay}
          disableDownload={!allowFileDownload}
          engagementGuid={engagementGuid}
          onDelete={withEditing ? onDelete : undefined}
        />
      )}
      {withEditing && !isAppReadOnly && (
        <>
          <button
            className="file-attach-button"
            onClick={toggleFileAttachModal}
          >
            <Icon name="file-plus" />
            {buttonText || t('Attach', { context: 'files' })}
          </button>
          <FileAttachModal
            dss={dss}
            engagementGuid={engagementGuid}
            engagementName={engagementName!}
            excludeFromSearchResults={excludeList}
            modalTitle={modalTitle}
            onAttach={onAttach}
            onClose={toggleFileAttachModal}
            open={modalIsOpen}
          />
        </>
      )}
    </div>
  );
};

export default FileAttachments;
