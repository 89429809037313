import React, { useContext } from 'react';
import { IEngagement } from 'interfaces';

export interface IEngagementContext {
  engagement: IEngagement | null;

  /**
   * Refreshes all engagement data.
   */
  refreshEngagement: () => void;

  /**
   * Proactively updates the engagement update date to today. To be called after
   * an action occurs that we expect would update the last updated date. We will
   * assume the update has happened in the backend and avoid a full refresh of
   * the engagement.
   *
   * @param engagementGuid Can be passed to ensure that the current engagement
   *   in context matches the supplied GUID before refreshing the update date.
   */
  refreshUpdateDate: (engagementGuid?: string) => void;
}

// NOTE: using Partial type as EngagementContext may not be available if
// component using context is rendered outside of the Project page
const EngagementContext = React.createContext<Partial<IEngagementContext>>({});

export default EngagementContext;

export const useEngagement = () => useContext(EngagementContext);
