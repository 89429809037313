import Table from 'components/Table';
import { useStateMounted, useUser } from 'hooks';
import {
  INewUserRequest,
  INewUserRequestSearchParams,
  ITableState,
  IUser,
  NewUserRequestStatus,
  UserType
} from 'interfaces';
import getHeaderForNURStatus from 'routes/ManageTeam/utils/getHeaderForNURStatus';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import { formatDate } from 'utils/formatDate';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import standardizeUSDate from 'utils/standardizeUSDate';
import useDebounce from 'utils/useDebounce';

import classnames from 'classnames';
import ENV from 'env';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  EButtonSizes,
  EButtonVariant,
  ITableColumn,
  Icon,
  Tooltip
} from '@ryan/components';

import UserNameAndProfile from '../../components/Table/UserNameAndProfile/UserNameAndProfile';
import { formatToUserNameAndProfile } from '../../components/Table/UserNameAndProfile/utils';
import NewUserButton from './NewUserButton';

import './ManageTeamNewUserTable.scss';

export interface IManageTeamNewUserTableProps {
  isUpdateRequired: boolean | null;
}

const ManageTeamNewUserTable: React.FC<IManageTeamNewUserTableProps> = ({
  isUpdateRequired
}) => {
  const { t } = useTranslation();
  const {
    permissionService: ps,
    user: {
      profile: { userTypeId }
    }
  } = useUser();
  const sourceRef = useRef<CancelTokenSource>();

  // table related state
  const [filtered, setFiltered] = useState<ITableState['filtered']>({
    status: ''
  });
  const [loading, setLoading] = useStateMounted(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const [sorted, setSorted] = useState<ITableState['sorted']>({
    id: 'FirstName',
    desc: false
  });
  const [totalCount, setTotalCount] = useState(0);

  // new user request data
  const [users, setUsers] = useState<INewUserRequest[]>([]);

  const isActiveUserSuperAdmin = ps.isSuperAdmin();

  /**
   * Fetches data for the new user request table.
   */
  const fetchData = useCallback(async () => {
    const params: INewUserRequestSearchParams = {
      itemsPerPage: pageSize,
      pageNumber: page,
      searchTerm: debouncedSearchQuery,
      sort: getSortParam(sorted),
      status: filtered ? filtered.status : undefined
    };
    setLoading(true);

    // reinitialize cancel token
    sourceRef.current?.cancel();
    sourceRef.current = ApiService.CancelToken.source();

    try {
      const {
        data: { results, totalResults }
      } = isUpdateRequired
        ? await ApiService.getNewUserRequests(params)
        : await ApiService.getNewUserRequests(params, sourceRef.current.token);

      // update state
      setUsers(results);
      setTotalCount(totalResults);
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      setLoading(false);
    }
  }, [
    debouncedSearchQuery,
    filtered,
    isUpdateRequired,
    page,
    pageSize,
    sorted,
    setLoading
  ]);

  // table callback methods
  const onFilter = useCallback((filtered: Record<string, unknown>) => {
    setFiltered(filtered);
    setPage(1);
  }, []);

  const onPage = useCallback((page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }, []);

  const onSearch = useMemo(
    () =>
      debouncedSearch(
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
        },
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
          setPage(1);
        }
      ),
    []
  );

  const onSort = useCallback((sorted: any) => {
    setSorted(sorted);
  }, []);

  /**
   * Renders the table header actions.
   */
  const renderActions = useCallback(
    () =>
      userTypeId !== UserType.Ryan && (
        <div data-qid="manage-new-user__request">
          <NewUserButton
            size={EButtonSizes.SMALL}
            variant={EButtonVariant.TEXT}
          />
        </div>
      ),
    [userTypeId]
  );

  /**
   * Renders the table empty state.
   */
  const renderEmpty = useCallback(() => {
    const isUserTypeRyan = userTypeId === UserType.Ryan;

    return (
      <div className="table-empty" data-qid="manage-new-user__request">
        <Icon className="table-empty__icon" name="users" />
        {debouncedSearchQuery ? (
          <div className="table-empty__content">
            <Trans i18nKey="Search Not Found">
              <b>{{ searchQuery: debouncedSearchQuery }}</b> could not be found.
            </Trans>
            <br />
            {t('manageTeam.adjustSearch')}
          </div>
        ) : (
          <div className="table-empty__content">
            {t('manageTeam.newUser.nonePending')}

            {!isUserTypeRyan && (
              <React.Fragment>
                <br />
                {t('manageTeam.newUser.question')}
              </React.Fragment>
            )}
          </div>
        )}

        {!isUserTypeRyan && <NewUserButton variant={EButtonVariant.PRIMARY} />}
      </div>
    );
  }, [debouncedSearchQuery, t, userTypeId]);

  /**
   * Renders the individual row actions.
   */
  const renderRowAction = useCallback(
    ({
      canCurrentUserGrantAccess: isActiveUserWithNewUserApprovalAccess,
      queueItemGuid: userQueueItemGuid,
      statusId: userStatusId,
      userGuid
    }: INewUserRequest) => {
      if (!isActiveUserSuperAdmin && !isActiveUserWithNewUserApprovalAccess) {
        return null;
      }

      switch (userStatusId) {
        case NewUserRequestStatus.Pending:
        case NewUserRequestStatus.PendingMultipleEmails:
          return <div className="pill">{t('Pending')}</div>;
        case NewUserRequestStatus.ReviewEngagements:
          return (
            <Link
              className={classnames({
                'manage-new-user-table__review-link': true,
                'manage-new-user-table__review-link--disabled': !ps.isRyan()
              })}
              to={{
                pathname: ps.isRyan()
                  ? `/app/team/review-new-user/${userQueueItemGuid}`
                  : '#'
              }}
            >
              {t('manageTeam.newUser.review')}
            </Link>
          );
        case NewUserRequestStatus.ReviewDxpRole:
          return (
            <Link
              className="manage-new-user-table__review-link"
              to={`/app/team/review-new-user/${userQueueItemGuid}`}
            >
              {t('manageTeam.newUser.review')}
            </Link>
          );
        case NewUserRequestStatus.Complete:
          return (
            <Link
              className="manage-new-user-table__review-link"
              to={{
                pathname: `/app/team/edit-user/${userGuid}`,
                state: {
                  isFromManageTeamNewUserRequests: true
                }
              }}
            >
              {t('manageTeam.newUser.review')}
            </Link>
          );
        default:
          return null;
      }
    },
    [isActiveUserSuperAdmin, ps, t]
  );

  // column configuration of the new user request table
  const columns = useMemo<ITableColumn<INewUserRequest>[]>(
    () => [
      {
        id: 'firstNameAndEmail',
        label: t('manageTeam.columns.nameAndEmail'),
        render: row => (
          <UserNameAndProfile
            user={formatToUserNameAndProfile({
              additionalInfoKey: 'email',
              user: row as unknown as IUser
            })}
          />
        )
      },
      {
        id: 'userType',
        label: t('manageTeam.columns.userType'),
        render: row =>
          row.userType === UserType.Client ? (
            t('Client')
          ) : (
            <div>
              {`${t('Third Party')} – ${t(`userTypes.${row.userType}`)}`}
              <Tooltip
                content={
                  <Trans i18nKey="manageTeam.dataSharingContent">
                    {{ requestedBy: row.requestedBy }}
                    {{ name: `${row.firstName} ${row.lastName}` }}
                  </Trans>
                }
                placement="top"
                renderTarget={({ open, ...props }) => (
                  <div
                    aria-expanded={open}
                    aria-haspopup="true"
                    className="manage-new-user-table__tooltip"
                    {...props}
                  >
                    <div>
                      <Icon name="warning-outline" />
                      {t('manageTeam.dataSharing')}
                    </div>
                  </div>
                )}
              />
            </div>
          )
      },
      {
        id: 'accountName',
        label: t('manageTeam.newUser.columns.company'),
        render: row =>
          row.userType === UserType.Client ? (
            row.accountName
          ) : (
            <div>
              <div>{row.accountName}</div>
              <div>
                {t('manageTeam.newUser.clientAccount', {
                  clientAccount: row.clientAccountName
                })}
              </div>
            </div>
          )
      },
      {
        id: 'status',
        label: t('manageTeam.newUser.columns.status'),
        sortable: true,
        render: row => {
          switch (row.statusId) {
            case NewUserRequestStatus.Pending:
              return t('manageTeam.newUser.status.pending');
            case NewUserRequestStatus.ReviewDxpRole:
              return t(
                'manageTeam.newUser.status.reviewDxpRole',
                isActiveUserSuperAdmin || row.canCurrentUserGrantAccess
                  ? { ryanPlatform: ENV.RYAN_PLATFORM }
                  : { context: 'readonly' }
              );
            case NewUserRequestStatus.ReviewEngagements:
              return t('manageTeam.newUser.status.reviewEngagements');
            case NewUserRequestStatus.Complete: {
              return t('manageTeam.newUser.status.completedOn', {
                date: formatDate(
                  row.activationDate
                    ? standardizeUSDate(row.activationDate)
                    : row.requestedDate
                )
              });
            }
            case NewUserRequestStatus.PendingMultipleEmails:
              return t('manageTeam.newUser.status.pendingMultipleEmails');
          }
        }
      },
      {
        id: 'requestedBy',
        label: t('manageTeam.newUser.columns.requestedBy'),
        render: row => (
          <div className="no-wrap">
            {ps.isUser(row.createdBy) ? t('You') : row.requestedBy}
            {row.requestedByUserType === UserType.Ryan && (
              <Icon name="ryan-seal" />
            )}
          </div>
        )
      },
      {
        id: 'requestedDate',
        label: t('manageTeam.newUser.columns.requestedDate'),
        render: row => formatDate(row.requestedDate),
        sortable: true
      },
      {
        id: 'actions',
        label: '',
        align: 'center',
        render: renderRowAction
      }
    ],
    [isActiveUserSuperAdmin, ps, renderRowAction, t]
  );

  // fetch new user request data on mount, will execute on table related state
  // updates ie page, pageSize, etc via `fetchData` callback update
  useEffect(() => {
    if (isUpdateRequired === null || isUpdateRequired) {
      fetchData();
    }

    return () => {
      // cancel ongoing requests
      sourceRef.current?.cancel();
    };
  }, [fetchData, isUpdateRequired]);

  if (
    !loading &&
    totalCount === 0 &&
    debouncedSearchQuery === '' &&
    filtered?.status === ''
  ) {
    return renderEmpty();
  }

  return (
    <Table<INewUserRequest>
      className="manage-new-user-table"
      columns={columns}
      data={users}
      filtered={filtered}
      groupBy="groupByStatusForTable"
      loading={loading}
      onFilterChange={onFilter}
      onPageChange={onPage}
      onSearchChange={onSearch}
      onSortChange={onSort}
      page={page}
      pageSize={pageSize}
      renderActionPlacement={1}
      renderActions={renderActions}
      renderEmpty={renderEmpty}
      renderGroupHeader={(groupByStatusForTable: string) => {
        const { headerBackgroundColor, headerTextColor, headerText } =
          getHeaderForNURStatus(groupByStatusForTable);
        return (
          <div
            style={{
              backgroundColor: headerBackgroundColor,
              color: headerTextColor,
              padding: '0.5rem 3.5rem'
            }}
          >
            {headerText.toUpperCase()}
          </div>
        );
      }}
      rowId="queueItemGuid"
      searchQuery={searchQuery}
      sorted={sorted}
      title={`${t('manageTeam.newUserRequestTab')} (${totalCount})`}
      totalCount={totalCount}
    />
  );
};

export default ManageTeamNewUserTable;
