import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, SplitButton } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../../../../hooks';
import { Permission, Status, UserType } from '../../../../../../interfaces';
import { StateActionEnums } from '../../../../../../routes/Worklist/WorklistMine/WorklistMine.utils';
import { IconEnums } from '../../../../../../utils/enums/IconEnums';
import { TTaskActionsProps } from './TaskActions.interfaces';

const TaskActions = ({
  createdByUserType,
  isEngagementReadOnly,
  onMarkTaskInProgress,
  onSetWorklistItemToState,
  statusId,
  assignedToUserType
}: TTaskActionsProps) => {
  const ROOT_TO_TEXT = 'table.columns.worklist.actionsByEntityType.task';

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const { permissionService } = useUser();

  const defaultProps = { block: true, size: EButtonSizes.SMALL };

  const isCreatedByClient = createdByUserType === UserType.Client;
  const isAssignedToClient = assignedToUserType === UserType.Client;
  const isCurrentUserClient = permissionService.isClient();
  const isDisabledSplitButtonOptions = isAppReadOnly || isEngagementReadOnly;

  let isSplitButton = true;
  let isPrimaryDisabled = false;
  let buttonText = '';

  if (isCurrentUserClient) {
    if (!isCreatedByClient && !isAssignedToClient) {
      isPrimaryDisabled = true;
      isSplitButton = false;

      buttonText = getTextToDisplay(
        `${ROOT_TO_TEXT}.${Object.values(Status)[statusId - 1]}`
      );
    } else if (isCreatedByClient && !isAssignedToClient) {
      isPrimaryDisabled = true;
      buttonText = getTextToDisplay(
        `${ROOT_TO_TEXT}.${Object.values(Status)[statusId - 1]}`
      );
    }
  }

  const splitButtonOptions = [
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.PENCIL,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.edit`),
      onClick: () => {
        onSetWorklistItemToState(StateActionEnums.TO_EDIT);
      }
    },
    ...(statusId === Status.Todo &&
    !(isCurrentUserClient && !isAssignedToClient)
      ? [
          {
            disabled: isDisabledSplitButtonOptions,
            icon: IconEnums.APPROVE,
            label: getTextToDisplay(`${ROOT_TO_TEXT}.complete`),
            onClick: () => {
              onSetWorklistItemToState(StateActionEnums.TO_COMPLETE);
            }
          }
        ]
      : []),
    ...(isCurrentUserClient && !isAssignedToClient
      ? []
      : [
          {
            disabled: isDisabledSplitButtonOptions,
            icon: IconEnums.USER_REASSIGN,
            label: getTextToDisplay(`${ROOT_TO_TEXT}.reassign`),
            onClick: () => {
              onSetWorklistItemToState(StateActionEnums.TO_REASSIGN);
            }
          }
        ]),
    ...(isCurrentUserClient && !isCreatedByClient
      ? []
      : [
          {
            disabled: isDisabledSplitButtonOptions,
            icon: IconEnums.TRASH,
            label: getTextToDisplay(`${ROOT_TO_TEXT}.delete`),
            negative: true,
            onClick: () => {
              onSetWorklistItemToState(StateActionEnums.TO_DELETE);
            }
          }
        ])
  ];

  return permissionService.hasPermission(Permission.TasksEdit) &&
    isSplitButton ? (
    <SplitButton
      {...defaultProps}
      disabled={isPrimaryDisabled}
      onClick={
        statusId === Status.Todo
          ? onMarkTaskInProgress
          : () => {
              onSetWorklistItemToState(StateActionEnums.TO_COMPLETE);
            }
      }
      options={splitButtonOptions}
      size={EButtonSizes.SMALL}
    >
      {buttonText.length > 0
        ? buttonText
        : getTextToDisplay(
            `${ROOT_TO_TEXT}.mark${Object.values(Status)[statusId]}`
          )}
    </SplitButton>
  ) : (
    <Button {...defaultProps} disabled>
      {buttonText.length > 0
        ? buttonText
        : getTextToDisplay(
            `${ROOT_TO_TEXT}.${Object.values(Status)[statusId - 1]}`
          )}
    </Button>
  );
};

export default TaskActions;
