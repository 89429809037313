import { useContext } from 'react';

import { withContext } from '../../utils/withContext';
import { CodeNotesDrawerContext } from './CodeNotesDrawerContext';
import { ICodeNotesDrawerContext } from './utils';

export const CodeNotesConsumer = CodeNotesDrawerContext.Consumer;

// HOC
export type WithCodeNotes = ICodeNotesDrawerContext;
export const withCodeNotes = withContext(
  CodeNotesDrawerContext,
  'withCodeNotes'
);

// hook
export const useCodeNotes = () => useContext(CodeNotesDrawerContext);
