import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { ActivityType, IDataRequestSummary, Status } from '../../../interfaces';
import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';
import { findModifiedByKey } from '../findModifiedByKey';
import { CommentActivity } from './CommentActivity';

export const DataRequestActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    user,
    activity,
    isNotification,
    showAccountName,
    showEngagementName,
    onCloseNotificationDrawer
  } = props;

  const {
    entityGuid,
    createdBy,
    createdByName,
    snapShot: { entitySummary, count, onBehalfOfUserName, onBehalfOfUserGuid },
    userInformation
  } = activity;

  const { title, userGuid, dataRequestType } =
    entitySummary as IDataRequestSummary;

  // Context helper.
  function you(userGuid: string) {
    return user.userGuid === userGuid ? 'you' : 'x';
  }

  switch (activity.activityTypeId) {
    //

    /**
     * Created
     */
    case ActivityType.DataRequestCreated: {
      const tBy = you(createdBy);
      const tAssigned = you(userGuid);
      const tKey = `activity.dataRequest.created.by-${tBy}-assigned-${tAssigned}`;
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View Data Request')}
          icon="file-plus"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.created.title')}
          to={`/app/data-request/${entityGuid}`}
        >
          <Trans i18nKey={tKey}>
            <b />
            {{ title }}
            {{ createdByName }}
            {{ assignedToName: userInformation.fullName }}
            {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    /**
     * Reassigned
     */
    case ActivityType.DataRequestAssignedToUser: {
      try {
        const { newValue: newAssignedTo } = findModifiedByKey(
          activity,
          'AssignedTo'
        );
        const newAssignedToObject = JSON.parse(newAssignedTo);

        const tBy = you(createdBy);
        const tAssigned = you(newAssignedToObject.MemberGuid);
        const tKey = `activity.dataRequest.reassigned.by-${tBy}-assigned-${tAssigned}`;

        return (
          <AbstractActivity
            activity={activity}
            color={ActivityColor.Blue}
            ctaText={t('View Data Request')}
            icon="file-user"
            isNotification={isNotification}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            showAccountName={showAccountName}
            showEngagementName={showEngagementName}
            title={t('activity.dataRequest.reassigned.title')}
            to={`/app/data-request/${entityGuid}`}
          >
            <Trans i18nKey={tKey}>
              <b />
              {{ title }}
              {{ assignedToName: newAssignedToObject.FullName }}
              {{ createdByName }}
              {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
            </Trans>{' '}
            <ActivityModified
              activity={activity}
              cases={[
                {
                  key: 'AssignedTo',
                  label: t('Assigned To'),
                  type: (value: string) => JSON.parse(value)?.FullName
                }
              ]}
            />
          </AbstractActivity>
        );
      } catch {
        return null;
      }
    }

    /**
     * Edited
     */
    case ActivityType.DataRequestModified: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View Data Request')}
          icon="file-pencil"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.edited.title')}
          to={`/app/data-request/${entityGuid}`}
        >
          <Trans i18nKey={`activity.dataRequest.edited.by-${you(createdBy)}`}>
            <b />
            {{ title }}
            {{ createdByName }}
            {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Title',
                label: t('Title'),
                type: 'string' as const
              },
              {
                key: 'StatusWithSubStatus',
                label: t('Status'),
                type: 'string' as const
              },
              {
                key: 'Description',
                label: t('Description'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'DataSpecs',
                label: t('Data Specs'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'Jurisdictions',
                label: t('dataRequest.dataFields.jurisdictions'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'DateRange',
                label: t('dataRequest.dataFields.dateRanges'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'ErpSystem',
                label: t('dataRequest.dataFields.erpSystem'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'TaxEngine',
                label: t('dataRequest.dataFields.taxEngine'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'DocumentTypes',
                label: t('dataRequest.dataFields.documentTypes'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'PropertyTaxSiteClasses',
                label: t('dataRequest.dataFields.propertyTaxSiteClasses'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'PropertyTaxDocumentTypes',
                label: t('dataRequest.dataFields.documentTypes'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'Attachments',
                label: t('Attachments'),
                type: (value: string) => (
                  <ul>
                    {value
                      .split(',')
                      ?.map((filename, i) =>
                        filename !== null && filename !== '""' ? (
                          <li key={i}>{filename}</li>
                        ) : (
                          ''
                        )
                      )}
                  </ul>
                )
              },
              {
                key: 'DueDate',
                label: t('Due Date'),
                type: 'date' as const
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Completed
     */
    case ActivityType.DataRequestStatusComplete: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Green}
          ctaText={t('View Data Request')}
          icon="file-check"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.completed.title')}
          to={`/app/data-request/${entityGuid}`}
        >
          <Trans
            i18nKey={`activity.dataRequest.completed.by-${you(createdBy)}`}
          >
            <b />
            {{ title }}
            {{ createdByName }}
            {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Status',
                label: t('Status'),
                type: (status: Status) => t(`status.${status}`)
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Past Due
     */
    case ActivityType.DataRequestIsPastDue: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Red}
          ctaText={t('View Data Request')}
          icon="file-warning"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.pastDue.title')}
          to={`/app/data-request/${entityGuid}`}
        >
          <Trans
            i18nKey={`activity.dataRequest.pastDue.assigned-${you(userGuid)}`}
          >
            <b />
            {{ title }}
            {{ assignedToName: userInformation.fullName }}
            {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    /**
     * Deleted
     */
    case ActivityType.DataRequestDeleted: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          icon="file-delete"
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.deleted.title')}
        >
          <Trans i18nKey={`activity.dataRequest.deleted.by-${you(createdBy)}`}>
            <b />
            {{ title }}
            {{ createdByName }}
            {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
          </Trans>
        </AbstractActivity>
      );
    }

    /**
     * Files Uploaded
     */
    case ActivityType.FileUploadedToDataRequest:
    case ActivityType.FileUploadedOnBehalfOfUserToDataRequest: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View Data Request')}
          icon="data-file-upload"
          isNotification={isNotification}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.filesUploaded.title')}
          to={`/app/data-request/${entityGuid}`}
        >
          <Trans
            count={count}
            i18nKey={
              // by-x
              // by-x-behalf-x
              // by-x-behalf-you
              // by-you
              // by-you-behalf-x
              // by-you-behalf-you
              `activity.dataRequest.filesUploaded.by-${you(createdBy)}${
                onBehalfOfUserGuid ? `-behalf-${you(onBehalfOfUserGuid)}` : ''
              }`
            }
          >
            <b />
            {{ title }}
            {{ createdByName }}
            {{ behalfOfName: onBehalfOfUserName }}
            {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    case ActivityType.DataRequestCommentedOn: {
      return (
        <CommentActivity
          {...props}
          editedComment={false}
          link={`/app/data-request/${entityGuid}/#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.dataRequest.commented.by-${you(createdBy)}${
                mentionedYou ? '-mentioned-you' : ''
              }`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
              {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    case ActivityType.DataRequestCommentModified: {
      return (
        <CommentActivity
          {...props}
          editedComment
          link={`/app/data-request/${entityGuid}/#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.dataRequest.commentEdited.by-${you(
                createdBy
              )}${mentionedYou ? '-mentioned-you' : ''}`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
              {{ type: dataRequestType ? `(${t(dataRequestType)})` : '' }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
