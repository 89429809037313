import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EMessageTypes, Table, pushToast } from '@ryan/components';

import { useDataRequestDrawer } from '../../contexts/DataRequestDrawerContext';
import { IDataRequestV2 } from '../../interfaces';
import { TWorklistItem } from '../../routes/Worklist/WorklistMine/WorklistMine.interfaces';
import * as WorklistOpenUtils from '../../routes/Worklist/WorklistMine/WorklistMine.utils';
import ApiService from '../../services/ApiService';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import DataRequestModals from '../Modal/DataRequestModals/DataRequestModals';
import * as DataRequestModalsEnums from '../Modal/DataRequestModals/DataRequestModals.enums';
import useSingleProjectDataRequestColumns from '../Table/Columns/SingleProjectDataRequests/useSingleProjectDataRequestColumns';
import * as UseSingleProjectDataRequestColumnsInterfaces from '../Table/Columns/SingleProjectDataRequests/useSingleProjectDataRequestColumns.interfaces';

// NOTE: Table Refactor for Single Project Data Requests.
// TODO: After Test/Validation
//      - WorklistOpenUtils.StateActionEnums should be moved/renamed
//      - IDataRequest should change to UseSingleProjectDataRequestColumnsInterfaces.TDataRequest
//      - Assignment prop and type in DataRequestModals should be update
const SingleProjectDataRequests = (props: any) => {
  const ROOT_TO_TEXT = 'table.columns.singleProjectDataRequest';

  const { onHandleRefresh, ...tableProps } = props;

  const { onDataRequestDrawerOpenComments } = useDataRequestDrawer();
  const [itemToOpenInModal, setItemToOpenInModal] =
    useState<UseSingleProjectDataRequestColumnsInterfaces.TDataRequest | null>(
      null
    );
  const [modalAction, setModalAction] =
    useState<DataRequestModalsEnums.EAction | null>(null);
  const { t: getTextToDisplay } = useTranslation();

  const modalActionsMap = {
    [WorklistOpenUtils.StateActionEnums.TO_ASSIGN_DATA_UPLOAD]:
      DataRequestModalsEnums.EAction.ASSIGN_DATA_UPLOAD,
    [WorklistOpenUtils.StateActionEnums.TO_COMPLETE]:
      DataRequestModalsEnums.EAction.COMPLETE,
    [WorklistOpenUtils.StateActionEnums.TO_DELETE]:
      DataRequestModalsEnums.EAction.DELETE,
    [WorklistOpenUtils.StateActionEnums.TO_EDIT]:
      DataRequestModalsEnums.EAction.EDIT,
    [WorklistOpenUtils.StateActionEnums.TO_REOPEN]:
      DataRequestModalsEnums.EAction.REOPEN
  };

  const handleMarkDataDelivered = async (
    item: UseSingleProjectDataRequestColumnsInterfaces.TDataRequest
  ) => {
    const { queueItemGuid, statusId } = item;

    try {
      const { data } = await ApiService.markDataRequestDataDelivered({
        markAsDataDelivered: true,
        queueItemGuid,
        status: statusId,
        subStatus: SubStatusEnums.DataDelivered
      });

      pushToast({
        content: getTextToDisplay(
          `${ROOT_TO_TEXT}.markDataDeliveredSuccessToastContent`,
          { title: data.title }
        ),
        type: EMessageTypes.SUCCESS
      });

      onHandleRefresh();
    } catch {
      pushServerErrorToast();
    }
  };

  return (
    <>
      <Table<IDataRequestV2>
        {...tableProps}
        columns={useSingleProjectDataRequestColumns({
          onDataRequestCommentClick: formattedItem => {
            onDataRequestDrawerOpenComments(formattedItem as IDataRequestV2);
          },
          onMarkDataDelivered: handleMarkDataDelivered,
          onSetItemToState: (stateAction, item) => {
            setItemToOpenInModal(item);
            setModalAction(
              modalActionsMap[stateAction as keyof typeof modalActionsMap]
            );
          }
        })}
      />

      {itemToOpenInModal && modalAction && (
        <DataRequestModals
          assignment={itemToOpenInModal as unknown as TWorklistItem}
          modalAction={modalAction}
          onClose={item => {
            if (item) {
              onHandleRefresh();
            }

            setItemToOpenInModal(null);
            setModalAction(null);
          }}
        />
      )}
    </>
  );
};

export default SingleProjectDataRequests;
