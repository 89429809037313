import { ITableColumn } from '@ryan/components/dist/lib/components/Table';

export default function isTableFilterActive<T = any>(
  columns: ITableColumn<T>[],
  filtered?: { [key: string]: any }
) {
  return function () {
    return (
      filtered !== undefined &&
      columns.some(
        column =>
          filtered[column.id] !== undefined &&
          column.filterActive !== undefined &&
          column.filterActive(filtered[column.id])
      )
    );
  };
}
