import ExecutiveAccessControl, {
  ExecutiveAccess
} from 'components/ExecutiveAccessControl';
import NewUserProjectPreviewTable from 'components/NewUserProjectPreviewTable';
import NowOrLater from 'components/NowOrLater';
import RoleAndPermissions from 'components/RoleAndPermissions/RoleAndPermissions';
import SelectLanguage from 'components/SelectLanguage';
import { WithUser, withUser } from 'contexts/UserContext';
import { IRole, IUser, IUserPermission, UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import { formatDate } from 'utils/formatDate';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import standardizeUSDate from 'utils/standardizeUSDate';

import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import ENV from 'env';
import React, { ChangeEvent, Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Checkbox,
  EButtonSizes,
  EButtonVariant,
  pushToast
} from '@ryan/components';

import DeactivateUserModal from '../../../components/Modal/DeactivateUserModal/DeactivateUserModal';
import DenyUserModal from '../../../components/Modal/DenyUserModal/DenyUserModal';
import UnsavedChangesContext from '../../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import * as GetUserInfoUtil from '../../../utils/getUserInfo/getUserInfo';
import CancelUserDeactivationModal from './CancelUserDeactivationModal';

import './EditUserRoleAndPermissions.scss';

function nullableDate(date: string | null) {
  return date ? parseISO(date) : null;
}

interface Props extends WithTranslation, WithUser {
  userToEdit: IUser;
  canEditUserRole: boolean;
  canEditUserPermissions: boolean;
  canActivateUser: boolean;
  canDeactivateUser: boolean;
  canCancelUserDeactivation: boolean;
  canEditUserExecutiveAccess: boolean;
  onDone: () => void;
}

interface State {
  activeUserPermissions: IUserPermission[];
  activateNow: boolean;
  activateLaterDate: Date | null;
  cancelDeactivationModalOpen: boolean;
  customPermissions: IUserPermission[];
  customPermissionsSaved: IUserPermission[];
  deactivateModalOpen: boolean;
  executiveAccess: ExecutiveAccess;
  executiveAccessSaved: ExecutiveAccess;
  initialActivateNow: boolean;
  hasFinishedEditing: boolean;
  isDenyModalOpen: boolean;
  isReviewed: boolean;
  languagePreference: string;
  minimumNewUserRequestDate: Date | null;
  roles: IRole[] | null;
  savingPromise: ReturnType<typeof ApiService.userAccessSave> | null;
  selectedRoleGuid: string;
  selectedRoleGuidSaved: string;
}

export class EditUserRoleAndPermissions extends Component<Props, State> {
  private _isMounted = false;

  readonly state: State = {
    activeUserPermissions: [],
    activateNow: true,
    activateLaterDate: null,
    cancelDeactivationModalOpen: false,
    customPermissions: [],
    customPermissionsSaved: [],
    deactivateModalOpen: false,
    executiveAccess: { enabled: false, selections: [] },
    executiveAccessSaved: { enabled: false, selections: [] },
    initialActivateNow: true,
    isDenyModalOpen: false,
    hasFinishedEditing: false,
    isReviewed: false,
    languagePreference: '',
    minimumNewUserRequestDate: null,
    roles: null,
    savingPromise: null,
    selectedRoleGuid: '',
    selectedRoleGuidSaved: ''
  };

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  componentDidUpdate() {
    const {
      activateNow,
      activateLaterDate,
      customPermissions,
      initialActivateNow,
      hasFinishedEditing,
      languagePreference,
      isReviewed,
      selectedRoleGuid,
      selectedRoleGuidSaved,
      customPermissionsSaved
    } = this.state;
    const { userToEdit } = this.props;
    const isActive = this.isUserActive();
    const isUnsavedChanges = isActive
      ? this.isUserAccessSame(isActive)
      : !(activateNow || activateLaterDate !== null) ||
        !isReviewed ||
        this.isUserAccessSame(isActive);
    const hasChangedRoleGuid = selectedRoleGuid !== selectedRoleGuidSaved;
    const hasChangedActivation = initialActivateNow
      ? !activateNow
      : activateNow;
    const hasChangedCustomPermissions =
      customPermissions !== customPermissionsSaved;
    const hasChangedLanguagePreference =
      userToEdit.languagePreference?.toLowerCase() !==
      languagePreference.toLowerCase();
    const hasChangedForm =
      hasChangedCustomPermissions ||
      hasChangedActivation ||
      hasChangedRoleGuid ||
      hasChangedLanguagePreference;

    this.context.setIsUnsavedChanges(
      (hasChangedForm && !hasFinishedEditing) ||
        (!isUnsavedChanges && !hasFinishedEditing)
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.configureUserActivationDate();
    this.configureUserLanguagePreference();
    this.fetch();
  }

  componentWillUnmount() {
    this.context.setIsUnsavedChanges(false);
    this._isMounted = false;
  }

  configureUserActivationDate() {
    const { userToEdit } = this.props;
    const {
      activateDate,
      minimumNewUserRequestDate: minimumNewUserRequestDateStr
    } = userToEdit;
    const today = new Date();
    const parsedActivateDate = nullableDate(activateDate);
    const isFutureActivationDate =
      parsedActivateDate && parsedActivateDate > today;

    // the initial value of the "activate later" date field; defaults to
    // tomorrow if set activation date is not in the future
    let activateLaterDate: Date = isFutureActivationDate
      ? standardizeUSDate(parsedActivateDate!)
      : startOfDay(addDays(today, 1));

    // the account's minimum allowed new user activation date
    const minimumNewUserRequestDate: Date | null = minimumNewUserRequestDateStr
      ? standardizeUSDate(minimumNewUserRequestDateStr)
      : null;

    // update "activate later" date if it is prior to the account's minimum
    // allowed activation date
    if (
      minimumNewUserRequestDate &&
      activateLaterDate < minimumNewUserRequestDate
    ) {
      activateLaterDate = minimumNewUserRequestDate;
    }

    const isActiveNow =
      !isFutureActivationDate &&
      (!minimumNewUserRequestDate || minimumNewUserRequestDate < today);

    this.setState({
      // default to activating now if activation date and the account's minimum
      // allowed activation date are not in the future
      activateNow: isActiveNow,
      activateLaterDate,
      initialActivateNow: isActiveNow,
      minimumNewUserRequestDate
    });
  }

  configureUserLanguagePreference() {
    this.setState({
      // HACK: languagePreference should not be undefined. Need to update interfaces
      languagePreference: this.props.userToEdit.languagePreference || ''
    });
  }

  async fetch() {
    const { userToEdit, canEditUserExecutiveAccess } = this.props;

    try {
      let selectedRoleGuid = '';
      let customPermissions: IUserPermission[] = [];

      // Fetch all the roles and the user's custom permissions.
      // You may think... if the user cannot customize permissions, then why fetch them?
      // It's because saving requires the permissions to be passed back.
      const [{ data: roles }, { data: userPermissions }] = await Promise.all([
        ApiService.getRolesForUserType(userToEdit.userTypeId),
        ApiService.getUserPermissions(userToEdit.userGuid)
      ]);

      if (userToEdit.roleGuid) {
        // Find the user's current role in our list of roles.
        const role = roles.find(r => r.roleGuid === userToEdit.roleGuid);
        if (role) {
          // Can safely set the roleGuid now that we've confirmed the role
          // is available in our list of options. I don't know why the
          // user's role would be missing... but defensive programming.
          selectedRoleGuid = userToEdit.roleGuid;

          // For each of the role's permissions, identify user permissions
          // that are "custom" (those that differ from the role's default permissions).
          customPermissions = role.rolePermissions.reduce(
            (customPermissions, rolePermission) => {
              /**
               * This is super confusing. We are building a list of OVERRIDES for the user.
               * @var role.rolePermissions has ALL permissions applicable to the role.
               * @var userPermissions has ONLY GRANTED permissions for the user.
               */
              const userPermission = userPermissions.find(
                p => p.permissionGuid === rolePermission.permissionGuid
              );

              // If we find a user permission that the role does not have,
              // then it's an override.
              if (
                userPermission &&
                userPermission.isGranted !== rolePermission.isGranted
              ) {
                customPermissions.push(userPermission);
              }

              // If we find a role permission that the user does not have,
              // then it's an override.
              if (!userPermission && rolePermission.isGranted) {
                customPermissions.push({ ...rolePermission, isGranted: true });
              }

              return customPermissions;
            },
            [] as IUserPermission[]
          );
        }
      }

      let executiveAccess = this.state.executiveAccess;

      if (canEditUserExecutiveAccess) {
        const response = await ApiService.getUserExecutiveAccess(
          userToEdit.userGuid
        );
        const userExecutiveAccess = response.data.items;
        executiveAccess = {
          enabled: userExecutiveAccess.some(x => x.isSelected),
          selections: userExecutiveAccess
        };
      }

      this.setState({
        activeUserPermissions: userPermissions,
        roles,
        selectedRoleGuid,
        customPermissions,
        executiveAccess,
        /**
         * Cache the new custom permissions or role
         */
        selectedRoleGuidSaved: selectedRoleGuid,
        customPermissionsSaved: customPermissions,
        executiveAccessSaved: executiveAccess
      });
    } catch (error) {
      pushServerErrorToast();
    }
  }

  handleCancel = () => {
    this.props.onDone();
  };

  handleSave = async (isDeferredActivation: boolean) => {
    const {
      t,
      userToEdit: { fullName: userName, queueItemGuid, userGuid },
      onDone
    } = this.props;
    const {
      activateLaterDate,
      activateNow,
      customPermissions,
      executiveAccess,
      isReviewed,
      languagePreference,
      selectedRoleGuid
    } = this.state;

    if (
      executiveAccess.enabled &&
      executiveAccess.selections.every(x => x.isSelected === false)
    ) {
      pushToast({
        type: 'error',
        title: t(
          'editUser.errorToast.saveExistingUserNoLegalEntitiesSelected',
          { userName }
        )
      });
      return;
    }

    try {
      const baseRequestData = {
        activateDate: this.isUserActive()
          ? null
          : activateNow
          ? new Date()
          : activateLaterDate,
        languagePreference,
        permissions: customPermissions.map(p => ({ ...p, userGuid })),
        roleGuid: selectedRoleGuid
      };

      const savingPromise =
        isDeferredActivation && activateNow
          ? ApiService.completeNewUserRequest(queueItemGuid as string, {
              ...baseRequestData,
              activateDate: baseRequestData.activateDate as Date, // HACK: Date || null is not compatibale
              isDeferredToImmediateActivation: true
            })
          : ApiService.userAccessSave({
              ...baseRequestData,
              executiveAccess: executiveAccess.enabled
                ? { items: executiveAccess.selections, userGuid }
                : null,
              isReviewed,
              userGuid
            });

      this.setState({ savingPromise });
      await savingPromise;

      const isRoleChanged =
        selectedRoleGuid !== this.state.selectedRoleGuidSaved;

      // update cached values
      this.setState({
        customPermissionsSaved: customPermissions,
        selectedRoleGuidSaved: selectedRoleGuid
      });

      const isUserActive = this.isUserActive();
      const roles = this.state.roles || [];

      // generate correct success toast message based on user activation
      // status and date
      let content =
        isRoleChanged && isUserActive
          ? t('editUser.successToast.clientSecurityRoleEdited.content', {
              roleName:
                roles.find(r => r.roleGuid === this.state.selectedRoleGuid)
                  ?.roleName || '',
              userName
            })
          : t('editUser.successToast.saveExistingUser', { userName });
      let title =
        isRoleChanged && isUserActive
          ? t('editUser.successToast.clientSecurityRoleEdited.title')
          : t('editUser.successToast.title');

      if (!this.isUserActive()) {
        const { userToEdit } = this.props;
        const activateDate = nullableDate(userToEdit.activateDate);
        const deactivateDate = nullableDate(userToEdit.deactivateDate);
        const isUserReactivation =
          activateDate &&
          deactivateDate &&
          deactivateDate < new Date() &&
          (deactivateDate > activateDate || activateNow);

        if (activateNow) {
          if (isUserReactivation) {
            content = t('editUser.successToast.reactivateUser', { userName });
            title = t('editUser.successToast.reactivatedTitle');
          } else {
            content = t('editUser.successToast.saveNewUser', {
              ryanPlatform: ENV.RYAN_PLATFORM,
              userName
            });
          }
        } else if (activateLaterDate) {
          content = t('editUser.successToast.saveNewUser', {
            activateDate: formatDate(activateLaterDate),
            context: 'deferred',
            ryanPlatform: ENV.RYAN_PLATFORM,
            userName
          });

          const titleText = isUserReactivation
            ? 'editUser.successToast.reactivatedTitle'
            : 'editUser.successToast.title';
          title = t(titleText, { context: 'deferred' });
        }
      }

      pushToast({
        type: 'success',
        title,
        content
      });
      this.context.setIsUnsavedChanges(false);
      this.setState({
        hasFinishedEditing: true
      });
      onDone();
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushToast({
          type: 'error',
          title: t('editUser.errorToast.saveExistingUser', { userName })
        });
      }
    } finally {
      if (this._isMounted) {
        this.setState({
          savingPromise: null
        });
      }
    }
  };

  isUserActive() {
    const { userToEdit } = this.props;
    const activateDate = nullableDate(userToEdit.activateDate);
    const deactivateDate = nullableDate(userToEdit.deactivateDate);
    const today = new Date();

    // Is the user active?
    // Is there an activation date and has it been hit?
    // And is there a deactivation date, has it been hit, or is it the activation date more recent?
    return (
      activateDate !== null &&
      activateDate <= today &&
      (deactivateDate === null ||
        today < deactivateDate ||
        deactivateDate < activateDate)
    );
  }

  isUserAccessSame(isActive: boolean) {
    const {
      activateNow,
      activateLaterDate,
      customPermissions,
      customPermissionsSaved,
      executiveAccess,
      executiveAccessSaved,
      languagePreference,
      selectedRoleGuid,
      selectedRoleGuidSaved
    } = this.state;
    const { userToEdit } = this.props;
    const laterDate = activateLaterDate
      ? startOfDay(activateLaterDate).toISOString()
      : null;
    const activateDate = userToEdit.activateDate
      ? startOfDay(new Date(userToEdit.activateDate)).toISOString()
      : null;

    // Permissions toString
    function pts(permissions: IUserPermission[]) {
      return permissions
        .map(p => `${p.permissionGuid}:${p.isGranted}`)
        .sort()
        .join();
    }

    // ExecutiveAccess toString
    function eats(executiveAccess: ExecutiveAccess) {
      return executiveAccess.selections
        .map(t => `${t.toggleGuid}:${t.isSelected}`)
        .sort()
        .join();
    }

    return isActive
      ? selectedRoleGuid === selectedRoleGuidSaved &&
          pts(customPermissions) === pts(customPermissionsSaved) &&
          eats(executiveAccess) === eats(executiveAccessSaved)
      : !activateNow &&
          laterDate === activateDate &&
          selectedRoleGuid === selectedRoleGuidSaved &&
          pts(customPermissions) === pts(customPermissionsSaved) &&
          languagePreference === userToEdit.languagePreference;
  }

  render() {
    const { t } = this.props;
    const { roles } = this.state;

    if (roles === null) {
      return `${t('Loading')}...`;
    }

    const isActive = this.isUserActive();

    return (
      <div className="edit-user-role-and-permissions-page">
        {this.renderActivateAndEdit(isActive)}
        {this.renderDeactivate(isActive)}
      </div>
    );
  }

  /**
   * Activate, Language Selection, Role, Permissions, Deny
   */

  handleActivateChange = (now: boolean, laterDate: Date | null) => {
    this.setState({
      activateNow: now,
      activateLaterDate: laterDate
    });
  };

  handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      languagePreference: e.target.value
    });
  };

  handleRoleAndPermissionsChange = (
    selectedRoleGuid: string,
    customPermissions: IUserPermission[]
  ) => {
    this.setState({
      selectedRoleGuid,
      customPermissions
    });
  };

  handleReviewChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isReviewed: e.target.checked
    });
  };

  renderActivateAndEdit(isActive: boolean) {
    const {
      t,
      userToEdit,
      canActivateUser,
      canEditUserRole,
      canEditUserPermissions,
      canEditUserExecutiveAccess,
      onDone
    } = this.props;
    const {
      activateLaterDate,
      activateNow,
      customPermissions,
      executiveAccess,
      isDenyModalOpen,
      isReviewed,
      languagePreference,
      minimumNewUserRequestDate,
      roles,
      savingPromise,
      selectedRoleGuid
    } = this.state;

    const showReactivate = canActivateUser && !isActive;
    const activateDate = nullableDate(userToEdit.activateDate);
    const deactivateDate = nullableDate(userToEdit.deactivateDate);
    const isDeferredActivation = showReactivate && !deactivateDate;
    const isThirdParty = (userToEdit.userTypeId & UserType.ThirdParty) > 0;

    return (
      <>
        {/* Reactivate */}
        {showReactivate && (
          <section>
            <h2 className="ry-h2">
              {t(
                !deactivateDate
                  ? 'editUser.activateUser'
                  : 'editUser.reactivate'
              )}
            </h2>
            <p className="deactivate-info">
              {deactivateDate &&
                (activateDate && activateDate > deactivateDate ? (
                  <Trans i18nKey="editUser.deactivatedOnButScheduled">
                    <b />
                    {{ deactivateDate: formatDate(deactivateDate) }}
                    {{
                      activateDate: formatDate(standardizeUSDate(activateDate))
                    }}
                  </Trans>
                ) : (
                  <Trans i18nKey="editUser.deactivatedOn">
                    <b />
                    {{ deactivateDate: formatDate(deactivateDate) }}
                  </Trans>
                ))}{' '}
              {!deactivateDate && activateDate ? (
                <Trans i18nKey={'editUser.requestedActivation'}>
                  <b>
                    {{
                      date: formatDate(standardizeUSDate(activateDate))
                    }}
                  </b>
                </Trans>
              ) : (
                t('editUser.reactivateNow')
              )}
            </p>
            <NowOrLater
              laterDate={activateLaterDate}
              laterLabel={t('editUser.activateLater')}
              now={activateNow}
              nowLabel={t('editUser.activateNow')}
              onChange={this.handleActivateChange}
              {...(minimumNewUserRequestDate &&
              minimumNewUserRequestDate > new Date()
                ? {
                    laterErrorText: t(
                      'newUserModal.validation.activationDate',
                      {
                        minDate: formatDate(minimumNewUserRequestDate)
                      }
                    ),
                    laterHelperText: t(
                      'newUserModal.form.activationDateHelper'
                    ),
                    laterMinDate: minimumNewUserRequestDate
                  }
                : {})}
            />
          </section>
        )}

        {/* show language selector if viewing a deffered NUR activation */}
        {isDeferredActivation && (
          <section className="edit-user-role-and-permissions-page__language">
            <SelectLanguage
              label={t('editUser.languageSelector')}
              onChange={this.handleLanguageChange}
              value={languagePreference}
            />
          </section>
        )}

        {/* show project preview if viewing a deferred NUR activation */}
        {isDeferredActivation && (
          <NewUserProjectPreviewTable userGuid={userToEdit.userGuid} />
        )}

        {/* Edit Access (Role, Permissions, Executive Access */}
        {canEditUserRole && (
          <section className="edit-user-role-and-permissions-page__edit">
            <h2 className="ry-h2">{t('editUser.roleAndPermissions')}</h2>

            {/* Roles and Permissions */}
            <RoleAndPermissions
              activeUserPermissions={this.state.activeUserPermissions}
              canEditUserPermissions={canEditUserPermissions}
              customPermissions={customPermissions}
              isThirdPartyUser={isThirdParty}
              onChange={this.handleRoleAndPermissionsChange}
              roles={roles}
              selectedRoleGuid={selectedRoleGuid}
            />

            {/* Executive Access */}
            {canEditUserExecutiveAccess && (
              <ExecutiveAccessControl
                onChange={executiveAccess => {
                  this.setState({ executiveAccess });
                }}
                value={executiveAccess}
              />
            )}

            {/**
             * Admin must check the box that says
             * "I have reviewed these changes..."
             * when reactivating a user
             */}
            {showReactivate && (
              <div className="edit-user-role-and-permissions-page__checkbox">
                <Checkbox
                  checked={isReviewed}
                  label={
                    isThirdParty
                      ? t('editUser.reviewed', {
                          context: 'nur-thirdparty',
                          name: `${userToEdit.firstName} ${userToEdit.lastName}`
                        })
                      : t('editUser.reviewed')
                  }
                  onChange={this.handleReviewChange}
                  value="isReviewed"
                />
              </div>
            )}

            {/* Save & Cancel */}
            <ButtonGroup>
              <Button
                onClick={this.handleCancel}
                size={EButtonSizes.LARGE}
                text={t('Cancel')}
              />
              <Button
                disabled={
                  savingPromise !== null ||
                  (isActive
                    ? // when user is active
                      // disabled if no changes
                      this.isUserAccessSame(isActive)
                    : // when user is inactive (and we are reactivating)
                      // disabled if missing an activation date
                      // or if they have not checked the "reviewed" checkbox
                      !(activateNow || activateLaterDate !== null) ||
                      !isReviewed ||
                      this.isUserAccessSame(isActive))
                }
                loading={savingPromise}
                onClick={() => this.handleSave(isDeferredActivation)}
                size={EButtonSizes.LARGE}
                text={t('editUser.save')}
                variant={EButtonVariant.PRIMARY}
              />
            </ButtonGroup>

            <hr />

            {isDeferredActivation && (
              <section className="edit-user-role-and-permissions-page__deny-access">
                <h2 className="ry-h2">{t('editUser.deny.header')}</h2>
                <p>{t('editUser.deny.content')}</p>

                <ButtonGroup>
                  <Button
                    negative
                    onClick={() => {
                      this.setState({ isDenyModalOpen: true });
                    }}
                    text={t('editUser.deny.deny')}
                  />
                </ButtonGroup>

                {isDenyModalOpen && (
                  <DenyUserModal
                    guids={{
                      queueItemGuid: userToEdit.queueItemGuid!,
                      userGuid: userToEdit.userGuid
                    }}
                    onClose={() => {
                      this.setState({ isDenyModalOpen: false });
                    }}
                    onDenyRequest={() => {
                      this.setState({
                        isDenyModalOpen: false,
                        hasFinishedEditing: true
                      });
                      this.context.setIsUnsavedChanges(false);
                      onDone();
                    }}
                    userFullName={GetUserInfoUtil.getFullName(
                      userToEdit as IUser
                    )}
                  />
                )}
              </section>
            )}
          </section>
        )}
      </>
    );
  }

  /**
   *  Deactivate
   */

  handleDeactivateModalOpen = () => {
    this.setState({ deactivateModalOpen: true });
  };

  handleDeactivateModalClose = (isDeactivated = false) => {
    this.setState({ deactivateModalOpen: false });

    if (isDeactivated) {
      this.props.onDone();
    }
  };

  handleCancelDeactivationModalOpen = () => {
    this.setState({ cancelDeactivationModalOpen: true });
  };

  handleCancelDeactivationModalClose = () => {
    this.setState({ cancelDeactivationModalOpen: false });
  };

  handleCancelDeactivationRequest = () => {
    this.handleCancelDeactivationModalClose();
    this.props.onDone();
  };

  renderDeactivate(isActive: boolean) {
    const { t, userToEdit, canDeactivateUser, canCancelUserDeactivation } =
      this.props;
    const { deactivateModalOpen, cancelDeactivationModalOpen } = this.state;

    // do not render if user cannot deactivate
    if (!canDeactivateUser) {
      return null;
    }

    if (!isActive) {
      if (userToEdit.deactivateDate) {
        return null;
      }

      // render NUR deny CTA if user has not yet been activated
      /**
       * @todo Currently is no way to cancel a deferred NUR activation once
       * approved. To be completed in User Story 32406.
       */
      return null;
    }

    const today = new Date();
    const deactivateDate = nullableDate(userToEdit.deactivateDate);
    const isScheduluedForDeactivation =
      deactivateDate && deactivateDate > today;

    return (
      <section>
        <hr />
        <div className="deactivate-modal" data-qid="edit-user__deactivate">
          <h2 className="ry-h2">{t('editUser.deactivateUser')}</h2>
          <p>
            {isScheduluedForDeactivation ? (
              <Trans i18nKey="editUser.deactivateInFuture">
                <b />
                {{ date: formatDate(userToEdit.deactivateDate!) }}
              </Trans>
            ) : (
              t('editUser.deactivateNowOrLater')
            )}
          </p>
          <div className="deactivate-modal__buttons">
            <ButtonGroup>
              <div>
                <Button
                  negative
                  onClick={this.handleDeactivateModalOpen}
                  text={t('editUser.deactivate')}
                />
              </div>
              <div>
                {canCancelUserDeactivation && isScheduluedForDeactivation && (
                  <Button
                    onClick={this.handleCancelDeactivationModalOpen}
                    text={t('editUser.cancelDeactivation')}
                    variant={EButtonVariant.SECONDARY}
                  />
                )}
              </div>
            </ButtonGroup>
          </div>
        </div>

        {deactivateModalOpen && (
          <DeactivateUserModal
            deactivateTimestamp={userToEdit.deactivateDate}
            hasDeferredDeactivationOption
            onClose={this.handleDeactivateModalClose}
            userFullName={GetUserInfoUtil.getFullName(userToEdit)}
            userGuid={userToEdit.userGuid}
          />
        )}
        <CancelUserDeactivationModal
          onCanceUserDeactivationRequest={this.handleCancelDeactivationRequest}
          onClose={this.handleCancelDeactivationModalClose}
          open={cancelDeactivationModalOpen}
          user={userToEdit}
        />
      </section>
    );
  }
}

export default withTranslation()(withUser(EditUserRoleAndPermissions));
