import EngagementContext from 'contexts/EngagementContext';
import {
  MilestoneDrawerConsumer,
  WithMilestoneDrawer,
  withMilestoneDrawer
} from 'contexts/MilestoneDrawerContext';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  IEngagement,
  IMilestone,
  IMilestoneRequest,
  IPagedDataResponse,
  ITask,
  MilestoneType,
  Permission as P,
  Status
} from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import history from 'services/history';
import getCommentButtonProps from 'utils/getCommentButtonProps';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import scrollTo from 'utils/scrollTo';

import { addMonths, startOfYear } from 'date-fns';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { SizeMeProps, WithSizeProps, withSize } from 'react-sizeme';

import {
  Button,
  Card,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  Icon,
  Toggle,
  Toggles,
  pushToast
} from '@ryan/components';

import Empty from '../Empty';
import ConfirmationModal from '../Modal/ConfirmationModal/ConfirmationModal';
import { SCOLLING_CALENDAR_MIN_WIDTH } from '../ScrollingCalendar/ScrollingCalendar';
import TaskModal from '../TaskActions/TaskModal/TaskModal';
import Milestone from './Milestone';
import MilestoneDates from './MilestoneDates';
import MilestoneForm from './MilestoneForm';
import MilestoneTasks from './MilestoneTasks';
import MilestonesTimeline from './MilestonesTimeline';
import sortMilestones from './sortMilestones';

import './Milestones.scss';

export interface IMilestonesProps extends WithSizeProps {
  engagement: IEngagement;
  onEngagementUpdated: () => void;
  onEmptyMilestoneEditProjectDetails: () => void;
}

type IMilestonesHOCProps = IMilestonesProps &
  SizeMeProps &
  WithMilestoneDrawer &
  RouteComponentProps<{
    engagementGuid: string;
    engagementMilestoneGuid?: string;
  }> &
  WithUser &
  WithTranslation;

enum Layout {
  Default,
  Timeline
}

interface IMilestonesState {
  milestones: IMilestone[];
  milestonesLoading: boolean;
  activeMilestoneGuid: string | null;
  newMilestone: IMilestoneRequest | null; // trumps active milestone
  promptDeleteMilestone: boolean; // show confirmation modal to delete active milestone
  submitPromise: Promise<any> | null; // for default layout, the active or new milestone
  layout: Layout;
  timelineStartDate: Date;
  tasks: { [key: string]: IPagedDataResponse<ITask> | undefined };
  promptNewTask: boolean;
}

export class Milestones extends Component<
  IMilestonesHOCProps,
  IMilestonesState
> {
  private _isMounted = false;

  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  // axios cancel token source
  private source?: CancelTokenSource;

  // axios cancel token source for task requests
  private sourceTasks?: CancelTokenSource;

  readonly state: IMilestonesState = {
    milestones: [],
    milestonesLoading: true,
    activeMilestoneGuid: null,
    newMilestone: null,
    promptDeleteMilestone: false,
    submitPromise: null,
    layout: Layout.Default,
    timelineStartDate: startOfYear(new Date()),
    tasks: {},
    promptNewTask: false
  };

  componentDidMount() {
    const { milestoneDrawerEvents } = this.props;
    this._isMounted = true;

    // Fetch milestones.
    // If milestone in url, set as active.
    // Otherwise, choose default active.
    this.fetchMilestones({}, () => {
      const { engagementMilestoneGuid } = this.props.match.params;
      const { milestones } = this.state;

      if (engagementMilestoneGuid) {
        this.handleLinkedMilestone(engagementMilestoneGuid);
      } else if (milestones.length > 0) {
        const activeMilestone =
          milestones.find(m => m.statusId === Status.InProgress) ||
          milestones.find(m => m.statusId === Status.Todo);

        if (activeMilestone) {
          this.setActiveMilestone(activeMilestone.engagementMilestoneGuid);
        }
      }
    });

    // Listen for comment changes.
    milestoneDrawerEvents.addListener('commentAdded', this.handleCommentAdded);
    milestoneDrawerEvents.addListener(
      'commentEdited',
      this.handleCommentEdited
    );
    milestoneDrawerEvents.addListener(
      'commentRemoved',
      this.handleCommentRemoved
    );
  }

  componentDidUpdate(prevProps: IMilestonesHOCProps) {
    // The engagement was updated.
    // Perhaps the Kickoff or Estimated End date was changed?
    const prevEngagement = prevProps.engagement;
    const { engagement } = this.props;

    if (
      engagement !== prevEngagement &&
      (engagement.alternateBeginDate !== prevEngagement.alternateBeginDate ||
        engagement.projectedEndDate !== prevEngagement.projectedEndDate)
    ) {
      this.fetchMilestones();
    }

    const prevMilestoneGuid = prevProps.match.params.engagementMilestoneGuid;
    const prevHash = prevProps.location.hash;
    const milestoneGuid = this.props.match.params.engagementMilestoneGuid;
    const { hash } = this.props.location;

    if (
      milestoneGuid !== undefined &&
      (milestoneGuid !== prevMilestoneGuid ||
        (hash !== prevHash && hash === '#comments'))
    ) {
      this.handleLinkedMilestone(milestoneGuid);
    }
  }

  componentWillUnmount() {
    const { milestoneDrawerEvents } = this.props;
    this._isMounted = false;

    // cancel ongoing requests
    this.source?.cancel('cancelling previous Milestones request');
    this.sourceTasks?.cancel('cancelling previous Milestones tasks request');

    // Unsubscribe from comment changes.
    milestoneDrawerEvents.removeListener(
      'commentAdded',
      this.handleCommentAdded
    );
    milestoneDrawerEvents.removeListener(
      'commentEdited',
      this.handleCommentEdited
    );
    milestoneDrawerEvents.removeListener(
      'commentRemoved',
      this.handleCommentRemoved
    );
  }

  handleCommentAdded = (eventData: any) => {
    this.fetchMilestones();
    this.context.refreshUpdateDate?.(eventData?.engagementGuid);
  };

  handleCommentEdited = (eventData: any) => {
    this.context.refreshUpdateDate?.(eventData?.engagementGuid);
  };

  handleCommentRemoved = (eventData: any) => {
    this.fetchMilestones();
  };

  handleLinkedMilestone(engagementMilestoneGuid: string) {
    const { t, engagement, location, onMilestoneDrawerOpen } = this.props;
    const linkedMilestone = this.state.milestones.find(
      m => m.engagementMilestoneGuid === engagementMilestoneGuid
    );

    if (linkedMilestone) {
      this.setActiveMilestone(engagementMilestoneGuid, true);

      if (location.hash === '#comments') {
        onMilestoneDrawerOpen(linkedMilestone);
      }
    } else {
      pushToast({
        type: 'warning',
        title: t(
          !engagement.alternateBeginDate || !engagement.projectedEndDate
            ? 'milestones.linkedMilestoneNeedsEngagementDates'
            : 'milestones.linkedMilestoneHasBeenDeleted'
        )
      });
    }
  }

  fetchMilestones = (
    updates: Partial<IMilestonesState> = {},
    onDone?: () => void
  ) => {
    const { engagement } = this.props;

    this.setState({
      milestonesLoading: true,
      ...(updates as any)
    });

    // refresh cancel token
    this.source?.cancel('cancelling previous Milestones request');
    this.source = ApiService.CancelToken.source();

    ApiService.getMilestones(engagement.engagementGuid, this.source.token)
      .then(response => {
        this.setState(
          {
            milestonesLoading: false,
            milestones: sortMilestones(response.data)
          },
          onDone
        );
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });
  };

  getKickoffDate() {
    const { milestones } = this.state;
    const kickoff = milestones.find(
      m => m.milestoneTypeId === MilestoneType.Kickoff
    );
    if (kickoff) {
      return kickoff.startDate;
    }
    return null;
  }

  getProjectedEndDate() {
    const { milestones } = this.state;
    const projectedEnd = milestones.find(
      m => m.milestoneTypeId === MilestoneType.End
    );
    if (projectedEnd) {
      return projectedEnd.endDate;
    }
    return null;
  }

  getActiveMilestone() {
    const { milestones, activeMilestoneGuid } = this.state;

    if (activeMilestoneGuid) {
      return milestones.find(
        m => m.engagementMilestoneGuid === activeMilestoneGuid
      );
    }
  }

  setActiveMilestone(
    activeMilestoneGuid: string | null,
    shouldScrollTo?: boolean
  ) {
    const { tasks } = this.state;

    // If we haven't loaded this milestone's tasks already, do that now.
    if (activeMilestoneGuid && !tasks[activeMilestoneGuid]) {
      this.fetchTasks(activeMilestoneGuid);
    }

    if (this._isMounted) {
      this.setState({ activeMilestoneGuid });
    }

    if (shouldScrollTo) {
      const {
        engagement: { engagementGuid }
      } = this.props;
      scrollTo(`[data-id="${activeMilestoneGuid}"]`);

      // reroute to base project route (milestone links should render the same
      // route component) so clicking on a milestone activity link while on this
      // route navigates back to milestone
      history.replace(`/app/project/${engagementGuid}/overview`);
    }
  }

  fetchTasks(engagementMilestoneGuid: string) {
    const { permissionService: ps, engagement } = this.props;

    if (ps.hasPermission(P.TasksView)) {
      // refresh cancel token
      this.sourceTasks?.cancel('cancelling previous Milestones tasks request');
      this.sourceTasks = ApiService.CancelToken.source();

      ApiService.getEngagementTasks(
        engagement.engagementGuid,
        {
          pageNumber: 1,
          itemsPerPage: 5,
          sort: 'createDate-',
          engagementMilestoneGuid
        },
        this.sourceTasks.token
      )
        .then(response => {
          this.setState(({ tasks }) => ({
            tasks: {
              ...tasks,
              [engagementMilestoneGuid]: response.data
            }
          }));
        })
        .catch(error => {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        });
    }
  }

  /**
   * Toggle Active Milestone
   */

  handleToggleActive = (engagementMilestoneGuid: string) => {
    const { activeMilestoneGuid, newMilestone, submitPromise } = this.state;

    // Disabled if a creating a new milestone or saving a milestone.
    if (newMilestone === null && submitPromise === null) {
      // If selected milestone is already open, close it. Otherwise, open it.
      this.setActiveMilestone(
        activeMilestoneGuid === engagementMilestoneGuid
          ? null
          : engagementMilestoneGuid
      );
    }
  };

  /**
   * Create or Update a Milestone
   */

  handleNewMilestone = () => {
    this.setState({
      // Reset active state
      activeMilestoneGuid: null,
      submitPromise: null,
      // Create a new, empty Milestone
      newMilestone: {
        title: '',
        detail: '',
        statusId: Status.Todo,
        milestoneTypeId: MilestoneType.Interim,
        startDate: null,
        endDate: null
      }
    });
  };

  handleNewMilestoneCancel = () => {
    if (this.state.submitPromise === null) {
      this.setState({ newMilestone: null });
    }
  };

  handleSaveMilestone = (milestone: IMilestone | IMilestoneRequest) => {
    const { t, engagement, onEngagementUpdated } = this.props;

    const isNew = !('engagementMilestoneGuid' in milestone);

    // cancel any previous requests and save new token for current request
    this.source?.cancel('cancelling previous Milestones request');
    this.source = ApiService.CancelToken.source();

    const submitPromise =
      'engagementMilestoneGuid' in milestone
        ? ApiService.updateMilestone(
            milestone,
            engagement.engagementGuid,
            milestone.engagementMilestoneGuid,
            this.source.token
          )
        : ApiService.createMilestone(
            milestone,
            engagement.engagementGuid,
            this.source.token
          );

    this.setState({ submitPromise });
    submitPromise
      .then(({ data: updatedMilestone }) => {
        // toast
        pushToast({
          type: 'success',
          title: t(
            isNew
              ? 'milestones.addMilestone.successToast.title'
              : 'milestones.editMilestone.successToast.title'
          ),
          content: t(
            isNew
              ? 'milestones.addMilestone.successToast.content'
              : 'milestones.editMilestone.successToast.content',
            { title: updatedMilestone.title }
          )
        });

        // fetch the latest milestones
        this.fetchMilestones({ newMilestone: null }, () => {
          this.setActiveMilestone(
            updatedMilestone.engagementMilestoneGuid,
            true
          );

          // refresh the Engagement if kickoff or projected end was updated
          onEngagementUpdated();
        });
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      })
      .finally(() => {
        if (this._isMounted) {
          this.setState({ submitPromise: null });
        }
      });
  };

  /**
   * Delete a Milestone
   */

  handleDeleteMilestoneSubmit = async () => {
    const { t, engagement } = this.props;
    const activeMilestone = this.getActiveMilestone();

    this.source = ApiService.CancelToken.source();

    await ApiService.deleteMilestone(
      engagement.engagementGuid,
      activeMilestone!.engagementMilestoneGuid,
      this.source.token
    );

    pushToast({
      content: t(
        'milestones.deleteMilestoneConfirmationModal.successToast.content',
        {
          title: activeMilestone!.title
        }
      ),
      title: t(
        'milestones.deleteMilestoneConfirmationModal.successToast.title'
      ),
      type: EMessageTypes.SUCCESS
    });

    this.fetchMilestones({ activeMilestoneGuid: null });
  };

  /**
   * Create Task
   */

  handleNewTaskPrompt = () => {
    this.setState({ promptNewTask: true });
  };

  handleNewTaskCancel = (newTask?: ITask) => {
    const { activeMilestoneGuid } = this.state;

    if (newTask && activeMilestoneGuid) {
      this.fetchTasks(activeMilestoneGuid);
    }

    this.setState({ promptNewTask: false });
  };

  /**
   * Navigate Timeline
   */

  handleBackOneMonth = () => {
    this.setState({
      timelineStartDate: addMonths(this.state.timelineStartDate, -1)
    });
  };

  handleForwardOneMonth = () => {
    this.setState({
      timelineStartDate: addMonths(this.state.timelineStartDate, 1)
    });
  };

  /**
   * Render
   */

  render() {
    const {
      size: { width },
      permissionService: ps,
      isAppReadOnly,
      engagement,
      onEmptyMilestoneEditProjectDetails,
      t
    } = this.props;
    const {
      milestones,
      milestonesLoading,
      activeMilestoneGuid,
      promptNewTask,
      promptDeleteMilestone
    } = this.state;
    const canEditEngagement = ps.hasPermission(P.TimelinesEdit);
    const belowMinWidth = width !== null && width < SCOLLING_CALENDAR_MIN_WIDTH;
    const layout = belowMinWidth ? Layout.Default : this.state.layout;
    const isEmpty = milestones.length === 0;

    return (
      <div className="milestones" id="Milestones">
        <Card role="region" title={t('Milestones')}>
          {/**
           * Only show layout options if min width reached.
           */}
          {!belowMinWidth && !isEmpty && (
            <Toggles>
              <Toggle
                active={layout === Layout.Timeline}
                icon="milestone-timeline"
                onClick={() => {
                  this.setState({ layout: Layout.Timeline });
                }}
              />
              <Toggle
                active={layout === Layout.Default}
                icon="milestone"
                onClick={() => {
                  this.setState({ layout: Layout.Default });
                }}
              />
            </Toggles>
          )}

          {milestonesLoading && isEmpty ? (
            <div className="milestones__loading">
              <Icon className="loading-spin" name="loading" />
            </div>
          ) : isEmpty ? (
            <Empty icon="milestone">
              {t('milestones.emptyState')}
              {canEditEngagement && (
                <Button
                  className="milestones__empty-milestone-button"
                  disabled={engagement.isReadOnly || isAppReadOnly}
                  onClick={onEmptyMilestoneEditProjectDetails}
                  text={t('milestones.editProjectDetails')}
                  variant={EButtonVariant.PRIMARY}
                />
              )}
            </Empty>
          ) : (
            <>
              {layout === Layout.Default && this.renderDefault()}
              {layout === Layout.Timeline && this.renderTimeline()}
            </>
          )}
        </Card>

        {promptDeleteMilestone && (
          <ConfirmationModal
            confirmationMessage={t(
              'milestones.deleteMilestoneConfirmationModal.description'
            )}
            onClose={() => {
              this.setState({ promptDeleteMilestone: false });
            }}
            onSubmit={this.handleDeleteMilestoneSubmit}
            title={t('milestones.deleteMilestoneConfirmationModal.title')}
          />
        )}

        {/**
         * CREATE TASK
         * Can only create tasks on saved milestones.
         */}
        {activeMilestoneGuid && (
          <TaskModal
            engagementGuid={engagement.engagementGuid}
            engagementMilestoneGuid={activeMilestoneGuid}
            onClose={this.handleNewTaskCancel}
            open={activeMilestoneGuid !== null && promptNewTask}
          />
        )}
      </div>
    );
  }

  renderDefault() {
    const { t, isAppReadOnly, permissionService: ps, engagement } = this.props;
    const {
      milestones,
      activeMilestoneGuid,
      newMilestone,
      submitPromise,
      tasks
    } = this.state;
    const canEditMilestones = ps.hasPermission(P.TimelinesEdit);
    const canCommentMilestones = ps.hasPermission(P.TimelinesContribute);
    const kickoffDate = this.getKickoffDate();
    const projectedEndDate = this.getProjectedEndDate();

    const milestoneNodes = milestones.map(milestone => (
      <Milestone
        canCommentMilestones={canCommentMilestones}
        canEditMilestones={canEditMilestones}
        engagementKickoffDate={kickoffDate}
        engagementProjectedEndDate={projectedEndDate}
        isActive={activeMilestoneGuid === milestone.engagementMilestoneGuid}
        key={milestone.engagementMilestoneGuid}
        milestone={milestone}
        milestones={milestones}
        onCreateTask={this.handleNewTaskPrompt}
        onDelete={() => {
          this.setState({ promptDeleteMilestone: true });
        }}
        onSubmit={this.handleSaveMilestone}
        onToggleActive={() =>
          this.handleToggleActive(milestone.engagementMilestoneGuid)
        }
        role="listitem"
        submitPromise={submitPromise}
        tasks={tasks[milestone.engagementMilestoneGuid] || null}
      />
    ));

    /**
     * If user has permission to edit engagements, add New Milestone button.
     * Disabled if engagement is inactive, or if already creating a new milestone,
     * or if saving a milestone.
     */
    if (canEditMilestones) {
      milestoneNodes.splice(
        milestoneNodes.length - 1,
        0,
        <button
          className="milestones__new-milestone-button"
          disabled={
            engagement.isReadOnly ||
            isAppReadOnly ||
            newMilestone !== null ||
            submitPromise !== null
          }
          key="new-milestone-button"
          onClick={this.handleNewMilestone}
        >
          <Icon name="add" />
          {t('milestones.addMilestone.title')}
        </button>
      );
    }

    /**
     * If creating a new Milestone, render the form after the New Milestone button.
     */
    if (newMilestone) {
      milestoneNodes.splice(
        milestoneNodes.length - 1,
        0,
        <MilestoneForm
          engagementKickoffDate={kickoffDate}
          engagementProjectedEndDate={projectedEndDate}
          key="new-milestone"
          milestone={newMilestone}
          milestones={milestones}
          onCancel={this.handleNewMilestoneCancel}
          onCreateTask={this.handleNewTaskPrompt}
          onSubmit={this.handleSaveMilestone}
          submitPromise={submitPromise}
          tasks={null}
        />
      );
    }

    return (
      <div className="milestones__container" role="list">
        {milestoneNodes}
      </div>
    );
  }

  renderTimeline() {
    const { t, isAppReadOnly, permissionService: ps } = this.props;
    const { milestones, activeMilestoneGuid, timelineStartDate, tasks } =
      this.state;
    const activeMilestone = this.getActiveMilestone();
    return (
      <>
        <MilestonesTimeline
          activeMilestoneGuid={activeMilestoneGuid}
          milestones={milestones}
          months={12}
          onBackOneMonth={this.handleBackOneMonth}
          onForwardOneMonth={this.handleForwardOneMonth}
          onToggleActive={this.handleToggleActive}
          startDate={timelineStartDate}
          t={t}
        />
        {activeMilestone && (
          <div className="milestones__active-milestone-timeline">
            <div className="row">
              <div className="col-lg-6">
                <h3>{activeMilestone.title}</h3>
                <MilestoneDates milestone={activeMilestone} />
                <p className="white-space-pre-line">{activeMilestone.detail}</p>
                <MilestoneDrawerConsumer>
                  {({ onMilestoneDrawerOpen }) => (
                    <Button
                      {...getCommentButtonProps(
                        t,
                        ps.hasPermission(P.TimelinesContribute),
                        activeMilestone,
                        isAppReadOnly
                      )}
                      onClick={() => {
                        if (activeMilestone) {
                          onMilestoneDrawerOpen(activeMilestone);
                        }
                      }}
                      size={EButtonSizes.SMALL}
                      variant={EButtonVariant.TEXT}
                    />
                  )}
                </MilestoneDrawerConsumer>
              </div>
              <div className="col-lg-6">
                <MilestoneTasks
                  milestone={activeMilestone}
                  onCreateTask={this.handleNewTaskPrompt}
                  tasks={tasks[activeMilestone.engagementMilestoneGuid] || null}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(
  withUser(withRouter(withMilestoneDrawer(withSize()(Milestones))))
);
