import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, Modal } from '@ryan/components';

import { IDeleteSavingsCategoryModalProps } from './utils';

const DeleteSavingsCategoryModal: React.FC<IDeleteSavingsCategoryModalProps> =
  ({ categoryName, isExistingCategory, onClose, onSubmit }) => {
    const { t: textToDisplay } = useTranslation();

    const ROOT_TO_TEXT = 'modal.deleteSavingsCategoryModal';

    return (
      <Modal
        onClose={onClose}
        open
        title={textToDisplay(`${ROOT_TO_TEXT}.title`)}
      >
        <p>
          {textToDisplay(`${ROOT_TO_TEXT}.deleteCategoryConfirmation`, {
            categoryName
          })}

          {isExistingCategory &&
            ` ${textToDisplay(`${ROOT_TO_TEXT}.savingsAmountDropNotice`)}`}
        </p>

        <ButtonGroup>
          <Button
            onClick={onClose}
            text={textToDisplay(`${ROOT_TO_TEXT}.noCancel`)}
          />
          <Button
            negative
            onClick={onSubmit}
            text={textToDisplay(`${ROOT_TO_TEXT}.yesDelete`)}
          />
        </ButtonGroup>
      </Modal>
    );
  };

export default DeleteSavingsCategoryModal;
