import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import DocumentTitle from 'components/DocumentTitle';
import NewUserRequestModal from 'components/NewUserRequestModal';
import isSwitcherOnRoute from 'components/Switcher/isSwitcherOnRoute';
import Tabs from 'components/Tabs/Tabs';
import { WithUser, withUser } from 'contexts/UserContext';
import { Feature } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import ManageTeamNewUserTable from './ManageTeamNewUserTable';
import ManageTeamPendingNewUserTable from './ManageTeamPendingNewUserTable';
import ManageTeamUsers from './ManageTeamUsers';
import NewUserContext from './NewUserContext';

interface IManageTeamProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps {
  // ...
}

interface IManageTeamState {
  isUpdateRequired: boolean | null;
  newUserCount: number;
  newUserModal: boolean;
  pendingUserCount: number;
  showNURTab: boolean;
  showPendingUsersTab: boolean;
}

/**
 * Manage Team is accessible to:
 * • BIG4 Ryan users
 * • Ryan users with "Team Manager" permission
 * • Client users with "Client Admin" permission
 */
export class ManageTeam extends Component<IManageTeamProps, IManageTeamState> {
  private sourceNUR?: CancelTokenSource;

  private sourcePendingUsers?: CancelTokenSource;

  readonly state: IManageTeamState = {
    isUpdateRequired: null,
    newUserCount: 0,
    newUserModal: false,
    pendingUserCount: 0,
    showNURTab:
      this.props.permissionService.canRequestNewUser() &&
      this.props.permissionService.isRyan(),
    showPendingUsersTab:
      this.props.permissionService.canRequestNewUser() &&
      !this.props.permissionService.isRyan()
  };

  componentDidMount() {
    this.fetchNewUserCount();
  }

  componentDidUpdate(prevProps: IManageTeamProps) {
    const { permissionService } = this.props;
    const { permissionService: prevPermissionService } = prevProps;

    if (permissionService !== prevPermissionService) {
      this.setState({
        showNURTab:
          permissionService.canRequestNewUser() && permissionService.isRyan(),
        showPendingUsersTab:
          permissionService.canRequestNewUser() && !permissionService.isRyan()
      });
    }
  }

  componentWillUnmount() {
    // cancel any ongoing requests
    this.sourceNUR?.cancel();
    this.sourcePendingUsers?.cancel();
  }

  fetchNewUserCount() {
    const { showNURTab, showPendingUsersTab } = this.state;

    // Get the count for all new user requests
    if (showNURTab) {
      // refresh request cancel token
      this.sourceNUR?.cancel();
      this.sourceNUR = ApiService.CancelToken.source();
      ApiService.getNewUserRequests({ itemsPerPage: 1 }, this.sourceNUR.token)
        .then(response => {
          this.setState({ newUserCount: response.data.totalResults });
        })
        .catch(() => {});
    }

    // Get the count for pending user requests.
    if (showPendingUsersTab) {
      // refresh request cancel token
      this.sourcePendingUsers?.cancel();
      this.sourcePendingUsers = ApiService.CancelToken.source();
      ApiService.getPendingUserRequests(
        { itemsPerPage: 1 },
        this.sourcePendingUsers.token
      )
        .then(response => {
          this.setState({ pendingUserCount: response.data.totalResults });
        })
        .catch(() => {});
    }
  }

  handleNewUserModalOpen = () => {
    this.setState({
      newUserModal: true
    });
  };

  handleNewUserModalRequest = () => {
    // HACK: Force update in lower components. Refactor This process
    this.setState({ isUpdateRequired: true });
    this.fetchNewUserCount();
    this.setState({ isUpdateRequired: false });
  };

  handleNewUserModalClose = () => {
    this.setState({
      newUserModal: false
    });
  };

  render() {
    const {
      t,
      activeView,
      location,
      permissionService: ps,
      isFeatureToggled
    } = this.props;
    const {
      isUpdateRequired,
      newUserModal,
      newUserCount,
      pendingUserCount,
      showNURTab,
      showPendingUsersTab
    } = this.state;
    const tabs = [
      // all users (with access to this page) can see the Client tab
      {
        label: t(ps.isClient() ? 'My Team' : 'Client Team'),
        path: '/app/team/manage/client',
        enabled: ps.canEditUsersOnSameAccount()
      },
      // only Ryan users see the Ryan tab
      {
        label: t('My Team'),
        path: '/app/team/manage/ryan',
        enabled: ps.isRyan() && ps.canEditUsersOnSameAccount()
      },
      // only Ryan users see the Third Party tab
      {
        label: t('Third Party'),
        path: '/app/team/manage/third-party',
        enabled: ps.isRyan() && ps.canEditUsersOnSameAccount()
      },
      // only Ryan users that can create new user requests can see new
      // user requests
      {
        label: `${t('manageTeam.newUserRequestTab')} (${newUserCount})`,
        path: '/app/team/manage/newusers',
        enabled: showNURTab
      },
      // non-Ryan users that can request new users see the Pending Users
      // tab
      {
        label: `${t('manageTeam.pendingUserTab')} (${pendingUserCount})`,
        path: '/app/team/manage/pendingusers',
        enabled: showPendingUsersTab
      }
    ];

    return (
      <div className="manage-team-page">
        <DocumentTitle title={t('manageTeam.title')} />
        <Breadcrumb label={t('team.title')} to="/app/team" />
        <Breadcrumb label={t('manageTeam.title')} to="/app/team/manage" />

        <h4 className="ry-h4">
          {
            // if account switcher is active on this route, then we show current account
            isSwitcherOnRoute(location) ? activeView.name : t('All Accounts')
          }
        </h4>
        <h1 className="ry-h1">{t('manageTeam.title')}</h1>

        {isFeatureToggled(Feature.LeftNav) ? '' : <Tabs tabs={tabs} />}

        <NewUserContext.Provider value={this.handleNewUserModalOpen}>
          <Switch>
            {showNURTab && (
              <Route
                path="/app/team/manage/newusers"
                render={() => (
                  <ManageTeamNewUserTable isUpdateRequired={isUpdateRequired} />
                )}
              />
            )}
            {showPendingUsersTab && (
              <Route
                path="/app/team/manage/pendingusers"
                render={routerProps => (
                  <ManageTeamPendingNewUserTable
                    isUpdateRequired={isUpdateRequired}
                    {...routerProps}
                  />
                )}
              />
            )}
            <Route
              component={ManageTeamUsers}
              path="/app/team/manage/:userType"
            />
            {isFeatureToggled(Feature.LeftNav) ? (
              ''
            ) : (
              <Redirect
                to={`/app/team/manage/${ps.isThirdParty() ? 'ryan' : 'client'}`}
              />
            )}
          </Switch>
        </NewUserContext.Provider>

        <NewUserRequestModal
          onClose={this.handleNewUserModalClose}
          onNewUserRequest={this.handleNewUserModalRequest}
          open={newUserModal}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(withUser(ManageTeam)));
