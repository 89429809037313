import { useCallback, useEffect, useRef } from 'react';

/**
 * Returns the mounted state of a component. Uses a `ref` to track state so does
 * not rerender component on update.
 */
export default function useIsMounted(): () => boolean {
  const isMountedRef = useRef(false);
  const isMounted = useCallback((): boolean => isMountedRef.current, []);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMounted;
}
