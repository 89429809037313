import React from 'react';
import { useTranslation } from 'react-i18next';

import DocumentTitle from '../../components/DocumentTitle';
import TabsAndRoutes from '../../components/Tabs/TabsAndRoutes';
import { useUser } from '../../contexts/UserContext';
import { Feature } from '../../interfaces';
import WorklistMine from './WorklistMine/WorklistMine';

const Worklist = () => {
  const { permissionService, isFeatureToggled } = useUser();
  const { t: getTextToDisplay } = useTranslation();

  const ROOT_TO_TEXT = 'worklistPage';

  const isRyanUser = permissionService.isRyan();

  const tabs = [
    {
      label: getTextToDisplay(`${ROOT_TO_TEXT}.worklistTabs.mine`),
      path: '/app/worklist/mine',
      component: WorklistMine,
      enabled: true
    },
    {
      label: getTextToDisplay(`${ROOT_TO_TEXT}.worklistTabs.myTeam`),
      path: '/app/worklist/my-team',
      component: WorklistMine,
      enabled: true
    },
    {
      label: isRyanUser
        ? getTextToDisplay(`${ROOT_TO_TEXT}.worklistTabs.client`)
        : getTextToDisplay(`${ROOT_TO_TEXT}.worklistTabs.ryan`),
      path: `/app/worklist/${isRyanUser ? 'client' : 'ryan'}`,
      component: WorklistMine,
      enabled: true
    },
    {
      label: getTextToDisplay(`${ROOT_TO_TEXT}.worklistTabs.all`),
      path: '/app/worklist/all',
      component: WorklistMine,
      enabled: true
    }
  ];

  return (
    <div className="worklist">
      <DocumentTitle title={getTextToDisplay(`${ROOT_TO_TEXT}.title`)} />
      <h1 className="ry-h1">{getTextToDisplay(`${ROOT_TO_TEXT}.title`)}</h1>
      {/* V2 */}
      <TabsAndRoutes
        hasTabsInLeftNavigation={isFeatureToggled(Feature.LeftNav)}
        tabs={tabs}
      />
    </div>
  );
};

export default Worklist;
