import { IAccount, ICustomViewSummary } from 'interfaces';
import { stringReplaceJsx } from 'utils/stringReplaceJsx';

import classnames from 'classnames';
import { TFunction } from 'i18next';
import React, { ComponentProps, FunctionComponent } from 'react';

import { Checkbox, Icon } from '@ryan/components';

import { Checked, ICheckedAccounts } from '../CheckedAccounts';
import { ISwitcherContext } from '../SwitcherContext';
import SwitcherCounts from '../SwitcherCounts/SwitcherCounts';
import useLazyRender from './useLazyRender';

import './SwitcherOptions.scss';

// the number of accounts to apply to the switcher account list per trigger of
// infinite scroll
const ACCOUNTS_PER_SUBSET = 20;

interface ISwitcherOptionsProps {
  t: TFunction;
  activeView: ICustomViewSummary;

  // accounts to render
  // NOTE: large lists of accounts will be broken down and displayed lazily
  // in sets based on `ACCOUNTS_PER_SUBSET`
  accounts: IAccount[];

  // optional - parent account to render for exclude-subsidiaries option
  parentAccount?: IAccount;

  // depth - 1 is start
  level: number;

  // current search query string for highlighting
  searchQuery: ISwitcherContext['searchQuery'];

  // expanded hash
  expanded: ISwitcherContext['expanded'];

  accountSelection: ICheckedAccounts | null;
  onAccountChecked: ISwitcherContext['onFilterCompanyToggle'];

  onAccountToggle: ISwitcherContext['onAccountToggle'];
  onAccountSelect: ISwitcherContext['onAccountSelect'];

  /**
   * A ref for the switcher's containing element. Used as the Intersection
   * Observer root element to lazy load the account list.
   */
  containerRef?: React.RefObject<Element>;
}

const SwitcherOptionsCheckbox: FunctionComponent<{
  accountGuid: string;
  checked: Checked;
  onChange: ComponentProps<typeof Checkbox>['onChange'];
}> = ({ accountGuid, checked, onChange }) => (
  <Checkbox
    checked={checked !== Checked.Off}
    indeterminate={checked === Checked.Indeterminate}
    onChange={onChange}
    value={accountGuid}
    variant={checked === Checked.Indeterminate ? 'secondary' : undefined}
  />
);

export const SwitcherOptions: FunctionComponent<ISwitcherOptionsProps> = ({
  t,
  accounts: accountsAll,
  parentAccount,
  level,
  activeView,
  searchQuery,
  expanded,
  onAccountToggle,
  onAccountSelect,

  accountSelection,
  onAccountChecked,
  containerRef
}) => {
  const { subset: accounts, infiniteScrollTargetRef } = useLazyRender<IAccount>(
    accountsAll,
    ACCOUNTS_PER_SUBSET,
    containerRef
  );

  return (
    <ul
      className={classnames({
        'account-switcher-options': true,
        'account-switcher-options--with-checkboxes': accountSelection !== null
      })}
    >
      {/**
       * Account - Excluding Subsidiaries
       * Rendered when the `parentAccount` has projects associated to it directly.
       */}
      {parentAccount &&
        parentAccount.activeEngagementCount +
          parentAccount.inactiveEngagementCount >
          0 && (
          <li
            className={classnames({
              'account-switcher-options__account': true,
              'account-switcher-options__account--active':
                accountSelection === null &&
                activeView.accountView &&
                activeView.accountView.account.accountGuid ===
                  parentAccount.accountGuid &&
                activeView.accountView.includeSubsidiaries === false
            })}
            data-level-color={level - 1}
            data-level-indent={level}
          >
            <div className="account-switcher-options__account-target">
              {/* Checkbox */}
              {accountSelection !== null && (
                <SwitcherOptionsCheckbox
                  accountGuid={parentAccount.accountGuid}
                  checked={accountSelection.isChecked(
                    parentAccount.accountGuid
                  )}
                  onChange={() =>
                    onAccountChecked(parentAccount.accountGuid, false)
                  }
                />
              )}

              {/* Bullet */}
              <span className="account-switcher-options__bullet" />

              {/* Select Account */}
              <button
                className="account-switcher-options__account-link"
                onClick={
                  accountSelection
                    ? () => onAccountToggle(parentAccount)
                    : () => onAccountSelect(parentAccount, false)
                }
              >
                <span className="account-switcher-options__account-link-line">
                  {parentAccount.name}
                </span>
                <SwitcherCounts
                  activeCount={parentAccount?.activeEngagementCount ?? 0}
                  inactiveCount={parentAccount?.inactiveEngagementCount ?? 0}
                  level={level - 1}
                />
              </button>
            </div>
          </li>
        )}

      {/* Child Accounts */}
      {accounts.map(account => {
        const {
          accountGuid,
          activeEngagementCount,
          inactiveEngagementCount,
          activeEngagementCountRollup,
          inactiveEngagementCountRollup
        } = account;

        // highlight the account name if we are searching
        const accountName = searchQuery
          ? stringReplaceJsx(account.name, searchQuery, (match, i) => (
              <b key={i}>{match}</b>
            ))
          : account.name;
        const hasChildAccounts = account.accounts.length > 0;
        const isActive =
          accountSelection === null &&
          accountGuid === activeView.accountView?.account.accountGuid;
        const totalRollup =
          activeEngagementCountRollup + inactiveEngagementCountRollup;
        const totalCount = activeEngagementCount + inactiveEngagementCount;

        const hasChildren = totalRollup > totalCount;
        const isExpanded =
          hasChildren && (expanded[accountGuid] || searchQuery);

        const iconName = `chevron-${isExpanded ? 'up' : 'down'}`;

        return (
          <li
            className={classnames({
              'account-switcher-options__account': true,
              'account-switcher-options__account--active': isActive
            })}
            data-level-color={level}
            data-level-indent={level}
            key={accountGuid}
          >
            <div className="account-switcher-options__account-target">
              {/* Checkbox */}
              {accountSelection !== null && (
                <SwitcherOptionsCheckbox
                  accountGuid={accountGuid}
                  checked={accountSelection.isChecked(accountGuid, hasChildren)}
                  onChange={() => onAccountChecked(accountGuid, hasChildren)}
                />
              )}

              {/* Toggle Account or Bullet */}
              {hasChildren ? (
                <button
                  aria-label={t(
                    isExpanded
                      ? 'switcher.toggleAccountClose'
                      : 'switcher.toggleAccountOpen',
                    { account: accountName }
                  )}
                  className="account-switcher-options__toggle"
                  disabled={!!searchQuery}
                  onClick={event => {
                    event.nativeEvent.stopImmediatePropagation();
                    onAccountToggle(account);
                  }}
                  type="button"
                >
                  <Icon key={iconName} name={iconName} />
                </button>
              ) : (
                <span className="account-switcher-options__bullet" />
              )}

              {/* Select Account */}
              <button
                className="account-switcher-options__account-link"
                onClick={
                  accountSelection
                    ? () => onAccountToggle(account)
                    : () => onAccountSelect(account, hasChildAccounts)
                }
              >
                <span className="account-switcher-options__account-link-line">
                  {accountName}{' '}
                  {isExpanded && totalCount > 0 ? (
                    <small>({t('Including Subsidiaries')})</small>
                  ) : null}
                </span>
                <SwitcherCounts
                  activeCount={account?.activeEngagementCountRollup ?? 0}
                  inactiveCount={account?.inactiveEngagementCountRollup ?? 0}
                  level={level}
                />
              </button>
            </div>

            {/* Sub Accounts */}
            {isExpanded && (
              <SwitcherOptions
                accounts={account.accounts}
                accountSelection={accountSelection}
                activeView={activeView}
                expanded={expanded}
                level={level + 1}
                onAccountChecked={onAccountChecked}
                onAccountSelect={onAccountSelect}
                onAccountToggle={onAccountToggle}
                parentAccount={account}
                searchQuery={searchQuery}
                t={t}
              />
            )}
          </li>
        );
      })}

      {/* infinite scroll target that will trigger addition of more accounts */}
      {accounts.length < accountsAll.length && (
        <li ref={infiniteScrollTargetRef} />
      )}
    </ul>
  );
};

export default SwitcherOptions;
