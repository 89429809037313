import React, { FunctionComponent } from 'react';
import { AbstractActivity, ActivityColor } from './AbstractActivity';
import { IActivityProps } from './IActivityProps';
import './UnrecognizedActivity.scss';

export const UnrecognizedActivity: FunctionComponent<IActivityProps> = ({
  activity
}) => (
  <AbstractActivity
    activity={activity}
    className="unrecognized-activity"
    color={ActivityColor.Gray}
    icon="help"
  >
    Unrecognized activity.
  </AbstractActivity>
);
