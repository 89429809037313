import TaskModal from 'components/TaskActions/TaskModal/TaskModal';
import TaskTable from 'components/TaskTable/TaskTable';
import { WithTaskDrawer, withTaskDrawer } from 'contexts/TaskDrawerContext';
import { WithUser, withUser } from 'contexts/UserContext';
import { EventEmitter } from 'events';
import { ITask, Permission } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  EButtonSizes,
  EButtonVariant,
  pushToast
} from '@ryan/components';

import './Tasks.scss';

interface ITasksState {
  isNewTaskModalOpen: boolean;
  createButtonDisabled: boolean;
}

class Tasks extends Component<
  WithTranslation & WithUser & WithTaskDrawer,
  ITasksState
> {
  private updateEmitter = new EventEmitter();

  readonly state = {
    isNewTaskModalOpen: false,
    createButtonDisabled: true
  };

  componentDidMount() {
    this.props.taskDrawerEvents.addListener(
      'commentAdded',
      this.updateTaskTable
    );
    this.props.taskDrawerEvents.addListener(
      'commentRemoved',
      this.updateTaskTable
    );
    this.checkIfUserHasActiveProjects();
  }

  componentWillUnmount() {
    this.props.taskDrawerEvents.removeListener(
      'commentAdded',
      this.updateTaskTable
    );
    this.props.taskDrawerEvents.removeListener(
      'commentRemoved',
      this.updateTaskTable
    );
  }

  checkIfUserHasActiveProjects() {
    const { customViewGuid } = this.props.activeView;
    const { userGuid } = this.props.user.profile;
    const { t } = this.props;

    if (customViewGuid && userGuid) {
      ApiService.getEngagementsForCustomViewByUser(customViewGuid, userGuid, {})
        .then(({ data }) => {
          const results = data.results;
          const isUserGhosted =
            results.filter(item => !item.isUserGhosted).length === 0;
          this.setState({ createButtonDisabled: isUserGhosted });
        })
        .catch(error => {
          if (error?.response?.status === 410) {
            pushToast({
              type: 'error',
              title: t('serverError.title'),
              content: t('serverError.content')
            });
          }
        });
    }
  }

  updateTaskTable = () => {
    this.updateEmitter.emit('update');
  };

  handleNewTask = () => {
    const { permissionService: ps } = this.props;
    if (ps.hasPermission(Permission.TasksEdit)) {
      this.setState({ isNewTaskModalOpen: true });
    }
  };

  handleNewTaskClose = (task?: ITask) => {
    this.setState({ isNewTaskModalOpen: false });
    if (task) {
      this.updateTaskTable();
    }
  };

  renderActions = () => {
    const { isAppReadOnly, permissionService: ps, t } = this.props;
    const { createButtonDisabled } = this.state;
    return (
      <>
        {ps.hasPermission(Permission.TasksEdit) && (
          <Button
            disabled={isAppReadOnly || createButtonDisabled}
            icon="plus"
            onClick={this.handleNewTask}
            size={EButtonSizes.SMALL}
            text={t('New Task')}
            variant={EButtonVariant.TEXT}
          />
        )}
      </>
    );
  };

  render() {
    const { isNewTaskModalOpen } = this.state;

    return (
      <div className="projects-tasks-page">
        <TaskTable
          customViewGuid={this.props.activeView.customViewGuid}
          renderActions={this.renderActions}
          updateEmitter={this.updateEmitter}
        />

        <TaskModal
          onClose={this.handleNewTaskClose}
          open={isNewTaskModalOpen}
        />
      </div>
    );
  }
}

export default withTranslation()(withUser(withTaskDrawer(Tasks)));
