import { useAppReadOnly, useUser } from 'hooks';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonSizes, EButtonVariant } from '@ryan/components';

import { Permission } from '../../../../../../interfaces';
import { DataRequestTypeGuidEnum } from '../../../../../../utils/enums/DataRequestsEnums';
import { IconEnums } from '../../../../../../utils/enums/IconEnums';
import Attachments from '../../../../../FileAttachments/Attachments';
import { getCommentButtonTextPath } from '../../Worklist.utils';
import { TDataRequestOverviewProps } from './DataRequestOverview.interfaces';
import DataRequestOverviewDetailsByType from './DataRequestOverviewDetailsByType/DataRequestOverviewDetailsByType';

import './DataRequestOverview.scss';

const DataRequestOverview = ({
  dataRequestDetails,
  isDisabled,
  onOpenDataRequestCommentDrawer,
  onOpenDataRequestHistoryDrawer
}: TDataRequestOverviewProps) => {
  const ROOT_TO_TEXT =
    'table.expandedRow.worklist.dataRequestExpandedRow.overview';
  const {
    attachments,
    commentCount,
    description,
    dataSpecs,
    engagementDisplayNameLong,
    engagementDisplayNameShort,
    engagementGuid,
    isEngagementReadOnly,
    subTypeName,
    title
  } = dataRequestDetails;

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const { activeView, permissionService } = useUser();

  const hasComments = commentCount > 0;
  const isReadOnlyByPermissions = !permissionService.hasPermission(
    Permission.DataRequestsContribute
  );
  const isReadOnlyByState = isAppReadOnly || isEngagementReadOnly;
  return (
    <div className="data-request-overview">
      <h2>{engagementDisplayNameShort}</h2>
      <h3>{title}</h3>

      {subTypeName && (
        <h3 className="data-request-overview__type">
          {getTextToDisplay(subTypeName)}
        </h3>
      )}

      {dataRequestDetails.detailsByType.typeGuid && (
        <DataRequestOverviewDetailsByType
          dataRequestDetailsByType={{
            ...dataRequestDetails.detailsByType,
            engagementDisplayNameLong,
            title,
            typeGuid: dataRequestDetails.detailsByType
              .typeGuid as DataRequestTypeGuidEnum,
            erpSystem: dataRequestDetails.detailsByType.erpSystem,
            taxEngine: dataRequestDetails.detailsByType.taxEngine,
            otherErpSystem: dataRequestDetails.detailsByType.otherErpSystem,
            otherTaxEngine: dataRequestDetails.detailsByType.otherTaxEngine,
            propertyTaxDocumentTypes:
              dataRequestDetails.detailsByType.propertyTaxDocumentTypes,
            propertyTaxSiteClasses:
              dataRequestDetails.detailsByType.propertyTaxSiteClasses
          }}
        />
      )}

      {dataSpecs && (
        <>
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.dataSpecs`)}</h4>
          <p className="white-space-pre-line">{dataSpecs}</p>
        </>
      )}

      {description && (
        <>
          <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.description`)}</h4>
          <p className="white-space-pre-line">{description}</p>
        </>
      )}

      {attachments.length > 0 && (
        <Attachments
          attachments={attachments}
          disableDownload={isDisabled || activeView.isExecutiveView}
          engagementGuid={engagementGuid}
        />
      )}

      <div className="data-request-overview__actions">
        <Button
          disabled={isDisabled}
          icon={IconEnums.TIME}
          onClick={onOpenDataRequestHistoryDrawer}
          size={EButtonSizes.SMALL}
          variant={EButtonVariant.TEXT}
        >
          {getTextToDisplay(`${ROOT_TO_TEXT}.viewHistory`)}
        </Button>

        <Button
          disabled={isDisabled}
          icon={hasComments ? IconEnums.COMMENT_ALERT : IconEnums.COMMENT}
          onClick={onOpenDataRequestCommentDrawer}
          size={EButtonSizes.SMALL}
          variant={EButtonVariant.TEXT}
        >
          {getTextToDisplay(
            `${ROOT_TO_TEXT}.${getCommentButtonTextPath(
              isReadOnlyByState || isReadOnlyByPermissions,
              hasComments
            )}`,
            { count: commentCount }
          )}
        </Button>
      </div>
    </div>
  );
};

export default DataRequestOverview;
