import classnames from 'classnames';
import * as React from 'react';

import { fallbackIds } from '@ryan/components/dist/lib/internal/fallback-id';

import {
  IRadioButtonGroupContext,
  RadioButtonGroupContext
} from './RadioButtonGroup';

export interface IRadioButtonProps {
  id?: string;
  name?: string;
  label?: string;
  value: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export class RadioButton extends React.PureComponent<IRadioButtonProps> {
  static contextType: React.Context<IRadioButtonGroupContext | null> =
    RadioButtonGroupContext;

  private ids = fallbackIds();

  render() {
    const { label, value } = this.props;
    let { name, checked, defaultChecked, onChange, onBlur } = this.props;

    if (this.context) {
      ({ name, onChange, onBlur } = this.context);
      defaultChecked = undefined;
      checked = this.context.value === value;
    }

    const id = this.ids('id');

    return (
      <div
        className={classnames({
          'radio-button': true,
          'radio-button--active': checked
        })}
      >
        <input
          checked={checked}
          className="radio-button__control"
          defaultChecked={defaultChecked}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="radio"
          value={value}
        />
        <label className="radio-button__label" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  }
}
