/**
 * Removes sensitive data from a URL search string.
 *
 * @param url The URL to scrub.
 */
export function scrubSensitiveDataFromURL(url: string): string {
  let scrubbedUrl: string = url;

  // remove auth
  scrubbedUrl = scrubbedUrl.replace(/auth=[^&#]*/i, 'auth=REDACTED');
  scrubbedUrl = scrubbedUrl.replace(
    /access_token=[^&#]*/i,
    'access_token=REDACTED'
  );

  // remove email
  scrubbedUrl = scrubbedUrl.replace(/email=[^&#]*/i, 'email=REDACTED');

  return scrubbedUrl;
}
