import {
  ICurrencyCarousel,
  IHandleCurrencyChange
} from './CurrencyCarousel.interfaces';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EButtonVariant } from '@ryan/components';

import AmplitudeApiService from '../../../services/AmplitudeApiService';
import {
  AmplitudeActionType,
  getAmplitudeLocation
} from '../../../utils/amplitudeUtils/amplitudeUtils';

import './CurrencyCarousel.scss';

const CurrencyCarousel: ICurrencyCarousel = ({
  currencySummaries,
  onCurrencyChange
}) => {
  const ROOT_TO_TEXT = 'currencyCarousel';
  const { t: getTextToDisplay } = useTranslation();

  const listRef = useRef<HTMLUListElement | null>(null);
  const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);

  const handleCurrencyChangeCallbacks = {
    onCurrencyChange,
    setSelectedCurrencyIndex
  };

  const scrollToSelectedCurrency = useCallback(
    (index: number) => {
      const listElement = listRef.current as HTMLUListElement;
      const { clientWidth } = listElement.children[index] as HTMLLIElement;

      listElement.scroll({
        behavior: 'smooth',
        left: clientWidth / 2 + clientWidth * index
      });
    },
    [listRef]
  );

  useEffect(() => {
    scrollToSelectedCurrency(selectedCurrencyIndex);
  }, [scrollToSelectedCurrency, selectedCurrencyIndex]);

  const handleCurrencyChange: IHandleCurrencyChange = (
    currencyIndex,
    callbacks
  ) => {
    const AMPLITUDE_COMPONENT_NAME = 'savings-summary-currency-carousel';
    const amplitudeLocation = getAmplitudeLocation();

    if (amplitudeLocation === 'dashboard') {
      AmplitudeApiService.logEvent(
        `${AmplitudeActionType.CLICK}-${AMPLITUDE_COMPONENT_NAME}-${amplitudeLocation}`,
        {
          'savings-currency-view': callbacks.getCurrencyCode
            ? callbacks.getCurrencyCode(currencyIndex)
            : 'Arrow Scroll'
        }
      );
    }

    callbacks.setSelectedCurrencyIndex(currencyIndex);
    callbacks.onCurrencyChange(currencyIndex);
  };

  return (
    <div className="currency-carousel">
      <div className="currency-carousel__overlay" />
      <Button
        aria-label={getTextToDisplay(`${ROOT_TO_TEXT}.previous`)}
        icon="chevron-left"
        onClick={() => {
          handleCurrencyChange(
            selectedCurrencyIndex === 0
              ? currencySummaries.length - 1
              : selectedCurrencyIndex - 1,
            handleCurrencyChangeCallbacks
          );
        }}
        variant={EButtonVariant.TEXT}
      />
      <ul ref={listRef}>
        {currencySummaries.map(({ currencyCode }, index) => (
          <li key={currencyCode}>
            <Button
              onClick={() => {
                handleCurrencyChange(index, {
                  ...handleCurrencyChangeCallbacks,
                  getCurrencyCode: (currencyIndex: number) =>
                    currencySummaries[currencyIndex].currencyCode
                });
              }}
              variant={EButtonVariant.TEXT}
            >
              {currencyCode}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        aria-label={getTextToDisplay(`${ROOT_TO_TEXT}.next`)}
        icon="chevron-right"
        onClick={() => {
          handleCurrencyChange(
            selectedCurrencyIndex === currencySummaries.length - 1
              ? 0
              : selectedCurrencyIndex + 1,
            handleCurrencyChangeCallbacks
          );
        }}
        variant={EButtonVariant.TEXT}
      />
    </div>
  );
};

export default CurrencyCarousel;
